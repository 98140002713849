import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { default as NextLink } from 'next/link';
import React from 'react';
import Highlighted from '../../utils/Highlighted/Highlighted';
import useStyles from './LinkBlock.styles';
const LinkBlock = (props) => {
    const { label, url, tabIndex, nofollow, noFollow, className, onClick, newWindow, highlightTextMatch } = props;
    const { classes } = useStyles();
    const LinkContents = React.forwardRef(({ onClick, href }, ref) => (_jsx("a", Object.assign({ href: href, className: clsx(className, classes.linkBlock), onClick: onClick, ref: ref }, (tabIndex && { tabIndex }), ((nofollow || noFollow) && { rel: 'nofollow' }), (newWindow && { target: '_blank' }), { children: _jsx(Highlighted, { text: label, highlight: highlightTextMatch }) }))));
    LinkContents.displayName = 'LinkContents';
    return (_jsx(_Fragment, { children: url ? (_jsx(NextLink, { href: url, passHref: true, legacyBehavior: true, children: _jsx(LinkContents, {}) })) : (_jsx("button", Object.assign({}, (tabIndex && { tabIndex }), { className: clsx(className, classes.linkBlock), onClick: onClick, children: _jsx("span", { children: label }) }))) }));
};
export default LinkBlock;
