import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { CheckoutErrors } from '../../common/props';
import Alert from '../Alert/Alert';
import Link from '../Link/Link';
import useStyles from './Checkout.styles';
const CheckoutErrorAlert = ({ errorType, unavailableItems }) => {
    const { classes } = useStyles();
    const message = useMemo(() => {
        if (!!(unavailableItems === null || unavailableItems === void 0 ? void 0 : unavailableItems.length)) {
            return (_jsxs(_Fragment, { children: [_jsx("p", { children: "An item in your basket is no longer available on the selected date - please choose another date or remove the item from your basket to proceed to checkout" }), _jsx("ul", { children: unavailableItems.map((item) => (_jsx("li", { className: classes.errorMessageItem, children: _jsx(Link, { className: classes.errorMessageItemLink, url: `/basket#${item.lineItemId}`, label: item.name }) }, item.lineItemId))) })] }));
        }
        if (errorType === CheckoutErrors.DELIVERY_DATE_PASSED) {
            return (_jsxs(_Fragment, { children: [_jsx("p", { children: "We can no longer complete your order because your delivery date has passed." }), _jsx("p", { children: _jsx(Link, { url: "/basket", label: "Update your order by selecting a new delivery date." }) })] }));
        }
        if (errorType === CheckoutErrors.MEMBER_UNAVAILABLE || errorType === CheckoutErrors.PRODUCT_UNAVAILABLE) {
            return (_jsxs(_Fragment, { children: [_jsx("p", { children: "We can no longer complete your order as items in your basket are no longer available." }), _jsx("p", { children: _jsx(Link, { url: "/basket", label: "Return to the basket to update your order." }) })] }));
        }
    }, [classes.errorMessageItem, classes.errorMessageItemLink, errorType, unavailableItems]);
    return _jsx(_Fragment, { children: message ? _jsx(Alert, { isFromCheckout: true, message: message, type: "alertError" }) : _jsx(_Fragment, {}) });
};
export default CheckoutErrorAlert;
