import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './FloristUsp.styles';
import ContentBlock from '../ContentBlock/ContentBlock';
const FloristUsp = (props) => {
    const { image, description } = props;
    const { classes } = useStyles();
    return (_jsx(ContentBlock, { classNames: {
            imageContent: classes.imageContent,
            messageContent: classes.messageContent,
            container: classes.floristContent,
        }, image: image, description: description, maxImageWidth: 248 }));
};
export default FloristUsp;
