import { jsx as _jsx } from "react/jsx-runtime";
import { Formik, Form } from 'formik';
import Modal from '../Modal/Modal';
import useStyles from './AddReminderModal.styles';
import AddEditRemindersForm from '../AddEditRemindersForm/AddEditRemindersForm';
import { validationConfig, initialValues } from '../AddEditRemindersForm/utils';
const AddReminderModal = (props) => {
    const { addNewReminder, setReminderStatusInfo, isAddReminderOpen, setAddReminderOpen, setHasScrolled } = props;
    const { classes } = useStyles();
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema: validationConfig,
        onSubmit: (values) => {
            addNewReminder(values)
                .then((response) => {
                if (!(response === null || response === void 0 ? void 0 : response.errors)) {
                    setReminderStatusInfo({
                        reminderStatus: 'alertSuccess',
                        reminderStatusMessage: 'Your reminder has been successfully set.',
                    });
                }
            })
                .catch((error) => {
                const isDuplicate = (error === null || error === void 0 ? void 0 : error.message) === 'Occasion already exists in the Customer Occasion Reminder list';
                setReminderStatusInfo({
                    reminderStatus: isDuplicate ? 'alertInfo' : 'alertError',
                    reminderStatusMessage: isDuplicate ? 'This reminder was already saved.' : error.message,
                });
            })
                .finally(() => {
                setAddReminderOpen(false);
                window.scrollTo(0, 0);
                if (setHasScrolled)
                    setHasScrolled(false);
            });
        },
    };
    return (_jsx(Modal, { open: isAddReminderOpen, setOpen: setAddReminderOpen, onClose: () => {
            if (setHasScrolled)
                setHasScrolled(false);
        }, keepMounted: false, title: 'Add a reminder', disablePortal: false, className: classes.addEditOccasionsModal, maxWidth: "md", children: _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => {
                return (_jsx(Form, { children: _jsx(AddEditRemindersForm, { isSubmitting: isSubmitting }) }));
            } })) }));
};
export default AddReminderModal;
