import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useStyles from './DayPicker.styles';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import SelectionButton from '../SelectionButton/SelectionButton';
import DatePicker from '../DatePicker/DatePicker';
import { convertDateFormat, filterSelectedDates, SiteContext } from '../../utils/common';
const ProductAvailabileDayPicker = ({ days, setSelectedDate, selectedDate: initialSelectedDate, isCalendarPicked, renderSelectedDayDetails, getDateDetails, handleMonthChange, calendarLoading, isSaveOnClose, currentStep, showProductAvailableCalendar, initialFirstDate, isFuneralBasket, oldCalendar, }) => {
    var _a;
    const { classes } = useStyles();
    const availableDays = useRef([]);
    const [firstThreeDays, setFirstThreeDays] = useState([]);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const selectedDate = initialSelectedDate ? convertDateFormat(initialSelectedDate) : new Date();
    const { calendarDates, isFlyingFlowers = false, deliverySettings } = useContext(SiteContext);
    const { availableSundays, bankHolidays } = calendarDates || {};
    const checkIfDateCardSelectedAndSetDate = (dateVal, isCalendarPicked) => {
        setSelectedDate(dateVal, isCalendarPicked);
    };
    const filterDate = useCallback((date) => {
        if (isFuneralBasket) {
            return !(days === null || days === void 0 ? void 0 : days.some((entry) => isSameDay(convertDateFormat(entry.date), date)));
        }
        const isSameDayCheck = !(days === null || days === void 0 ? void 0 : days.some((entry) => isSameDay(convertDateFormat(entry.date), date)));
        return (filterSelectedDates(date, isFlyingFlowers, bankHolidays, availableSundays, deliverySettings) || isSameDayCheck);
    }, [availableSundays, bankHolidays, days, deliverySettings, isFlyingFlowers, isFuneralBasket]);
    useEffect(() => {
        if (showProductAvailableCalendar)
            setCalendarOpen(true);
    }, [showProductAvailableCalendar, days]);
    const validateInitialThreeDays = useCallback((index) => {
        if (index >= days.length || availableDays.current.length >= 3) {
            setFirstThreeDays(availableDays.current);
            return;
        }
        const dateObj = days[index];
        if (dateObj) {
            const { date } = dateObj;
            const unavailableDay = filterDate(new Date(date));
            if (!unavailableDay) {
                availableDays.current.push(dateObj);
            }
        }
        const updateIndex = ++index;
        validateInitialThreeDays(updateIndex);
    }, [days, filterDate]);
    useEffect(() => {
        if (days === null || days === void 0 ? void 0 : days.length) {
            validateInitialThreeDays(0);
        }
    }, [days, validateInitialThreeDays]);
    const handleMoreClick = useCallback(() => {
        setTimeout(() => setCalendarOpen(true), 50); // Slight delay to allow UI updates
    }, []);
    return (_jsxs(_Fragment, { children: [!calendarOpen && (_jsxs("div", { className: classes.dayPicker, children: [firstThreeDays.map((entry, i) => {
                        const date = convertDateFormat(entry.date);
                        const formattedDay = format(date, 'EEEE');
                        const formattedDate = format(date, 'do MMM');
                        return (_jsx(SelectionButton, { title: isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : formattedDay, onClick: () => checkIfDateCardSelectedAndSetDate(entry.date, false), supplementaryDate: formattedDate, selected: selectedDate && !isCalendarPicked && isSameDay(date, new Date(selectedDate)) }, formattedDate));
                    }), _jsx(SelectionButton, Object.assign({ title: "More" }, (selectedDate &&
                        isCalendarPicked && {
                        supplementaryDate: format(new Date(selectedDate), 'do MMM'),
                    }), { icon: "calendar_today", selected: selectedDate && isCalendarPicked, onClick: handleMoreClick }))] })), _jsx(DatePicker, { datePickerTitle: "Choose a delivery date", isOpen: calendarOpen, setOpen: setCalendarOpen, minDate: (days === null || days === void 0 ? void 0 : days.length) ? convertDateFormat((_a = days[0]) === null || _a === void 0 ? void 0 : _a.date) : new Date(), value: selectedDate ? new Date(selectedDate) : undefined, setValue: (date) => setSelectedDate(format(date, 'yyyy-MM-dd'), true), filterDate: filterDate, renderSelectedDayDetails: renderSelectedDayDetails, handleMonthChange: handleMonthChange, getDateDetails: getDateDetails, calendarLoading: calendarLoading, hideInput: true, isSaveOnClose: true, noModal: false, showProductAvailableCalendar: showProductAvailableCalendar, initialFirstDate: initialFirstDate, oldCalendar: oldCalendar })] }));
};
export default ProductAvailabileDayPicker;
