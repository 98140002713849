import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    box: {
        border: `1px solid ${colors.grey.grey3}`,
        padding: `${spacing.medium} ${spacing.small}`,
    },
    other: { paddingBottom: spacing.medium },
    header: Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : undefined }, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.medium_bold)), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.xl_bold)), theme.typographySpacing.xl_bold_lg) }),
    disableIframe: {
        '& iframe': { pointerEvents: 'none' },
    },
}));
export default useStyles;
