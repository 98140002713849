import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import useStyles from './GuidedNavigation.styles';
import PCCModalWrapper from '../PCCModalWrapper/PCCModalWrapper';
import { useBackgroundClass } from '../../styles/jss/utils';
import clsx from 'clsx';
const GuidedNavigation = (props) => {
    const { title, basicInitiator, isOpenControlled, setOpenControlled, headingTag, countriesList, clickPostCodeChecker, trackPostCodeCheckerDate, trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion, submitPostCodeCheckerComplete, trackEnterManualAddressClick, backgroundColor, } = props;
    const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);
    const { getFormattedAddress, isFlyingFlowers } = useContext(SiteContext);
    const setOpen = setOpenControlled !== null && setOpenControlled !== void 0 ? setOpenControlled : setIsOpenUncontrolled;
    const isOpen = setOpenControlled ? !!isOpenControlled : isOpenUncontrolled;
    const [showInternationalLink, setShowInternationalLink] = useState(false);
    const { classes } = useStyles();
    // Need to get back: GREY is no longer a background colour that is supported, mapping to LIGHT_GREY but currently would display as WHITE
    const { bgClass } = useBackgroundClass(backgroundColor === 'GREY' ? 'LIGHT_GREY' : backgroundColor);
    const HeadingTag = (headingTag ? headingTag.toLowerCase() : 'h3');
    return !getFormattedAddress ? null : (_jsxs(_Fragment, { children: [_jsx(PCCModalWrapper, { isOpen: isOpen, setOpen: setOpen, setShowInternationalLink: setShowInternationalLink, showInternationalLink: showInternationalLink, countriesList: countriesList, trackPostCodeCheckerDate: trackPostCodeCheckerDate, trackPostCodeCheckerAddress: trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress: trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion: trackPostCodeCheckerOccasion, submitPostCodeCheckerComplete: submitPostCodeCheckerComplete, trackEnterManualAddressClick: trackEnterManualAddressClick }), !setOpenControlled && (_jsx(_Fragment, { children: basicInitiator ? (_jsx("button", { className: classes.basicButton, onClick: () => setOpen(true), children: "Start new search" })) : (_jsx("div", { className: clsx(classes.navigationGuidedContainer, bgClass), children: _jsxs("div", { className: classes.navigationInnerContainer, children: [title && _jsx(HeadingTag, { className: classes.title, children: title }), _jsx("div", { className: classes.navigationGuidedButton, children: _jsx(Button, { variant: isFlyingFlowers ? 'primary' : 'tertiary', icon: "calendar_today", title: "Choose your delivery date", thin: true, className: classes.chooseDate, onClick: () => {
                                        setShowInternationalLink(false);
                                        setOpen(true);
                                        clickPostCodeChecker === null || clickPostCodeChecker === void 0 ? void 0 : clickPostCodeChecker();
                                    } }) })] }) })) }))] }));
};
export default GuidedNavigation;
