import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    sortedList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridRowGap: spacing.small,
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: spacing.medium,
            gridRowGap: spacing.medium,
        },
        '& li': Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, display: 'flex', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({ padding: `26px ${spacing.medium} !important`, background: colors.grey.grey2 }, theme.typography.medium_regular), theme.typographySpacing.none), { textAlign: 'center', alignItems: 'center', justifyContent: 'center', '& a': {
                    color: colors.supplementary.black,
                    textDecoration: 'underline',
                } }) }),
    },
}));
export default useStyles;
