import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import useStyles from './EditBillingAddressForm.styles';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { errorMessages, inputValidationRegex } from '../../../utils/common';
import Link from '../../Link/Link';
import { getCountryFromAlpha2Code, SiteContext } from '../../../utils/common';
const validationSchema = Yup.object().shape({
    addressLine1: Yup.string()
        .required(errorMessages().required)
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    addressLine2: Yup.string().matches(inputValidationRegex, errorMessages().unsupportedCharacters),
});
const EditBillingAddress = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { addressLine1, addressLine2, addressLine3, city, county, country, postalCode, toggleManualAddress, onCancel, onSubmit, } = props;
    const initialValues = {
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        county,
        country,
        postalCode,
    };
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit,
    };
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Billing Address" }), _jsx("p", { children: "This is used as your default billing address in checkout." }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting, values }) => {
                    var _a;
                    return (_jsxs(Form, { children: [_jsx("div", { className: classes.input, children: _jsx(TextField, { name: "addressLine1", label: "Address Line 1", placeholder: "Enter the first line of the address", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "addressLine2", label: "Address Line 2", placeholder: "Enter the second line of the address", fullWidth: true }) }), _jsxs("div", { className: classes.addressContainer, children: [_jsx("div", { className: classes.addressLabel, children: "Town/City" }), _jsx("div", { className: classes.addressValue, children: values.city })] }), _jsxs("div", { className: classes.addressContainer, children: [_jsx("div", { className: classes.addressLabel, children: "County" }), _jsx("div", { className: classes.addressValue, children: values.county })] }), _jsxs("div", { className: classes.addressContainer, children: [_jsx("div", { className: classes.addressLabel, children: "Country" }), _jsx("div", { className: classes.addressValue, children: values.country && ((_a = getCountryFromAlpha2Code(values.country)) === null || _a === void 0 ? void 0 : _a.name) })] }), _jsx("div", { className: classes.searchAddressContiner, children: _jsx(Link, { label: "Search for a different address", className: classes.searchAddressLink, onClick: () => toggleManualAddress(true) }) }), _jsxs("div", { className: classes.buttons, children: [_jsx("div", { className: classes.saveButton, children: _jsx(Button, { variant: "secondary", thin: true, fullWidth: true, title: "Save billing address", isSubmit: true, icon: isFlyingFlowers ? 'check' : undefined, disabled: isSubmitting }) }), _jsx(Button, { variant: "tertiary", thin: true, fullWidth: true, title: "Cancel - discard changes", icon: isFlyingFlowers ? 'close' : undefined, onClick: onCancel })] })] }));
                } }))] }));
};
export default EditBillingAddress;
