import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import useStyles from './DestopZoomInImage.styles';
import { Magnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers';
import { processImageUrl } from '../../../utils/image';
import { Box } from '@mui/material';
const DesktopZoomInImage = ({ image, isMediaCarousal, heading }) => {
    const { classes } = useStyles();
    const [isZoomIn, setZoomIn] = useState(false);
    const imageURL = image.url ? processImageUrl(image.url) : '';
    const imageText = isMediaCarousal ? heading : image === null || image === void 0 ? void 0 : image.label;
    return (_jsx("button", { className: clsx(classes.container, isMediaCarousal && classes.mediaCarousalContainer), onClick: (e) => {
            e.preventDefault();
            setZoomIn(!isZoomIn);
        }, onKeyDown: () => { }, tabIndex: 0, children: _jsxs("div", { className: clsx(isMediaCarousal && classes.mediaCarousalSubContainer), children: [_jsx(Box, { className: clsx(isMediaCarousal ? classes.desktopMediaImage : classes.image), children: _jsx(Magnifier, { imageSrc: imageURL, imageAlt: image.altText, mouseActivation: MOUSE_ACTIVATION.CLICK, touchActivation: TOUCH_ACTIVATION.TAP, dragToMove: false, className: isZoomIn ? classes.zoomOut : classes.zoomIn }) }), !!imageText && (_jsx("div", { className: clsx(isMediaCarousal ? classes.imageHeading : classes.productName), children: imageText }))] }) }));
};
export default DesktopZoomInImage;
