import FFHomeHeroBannerUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/FFHomeHeroBanner/FFHomeHeroBanner'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { useLoaded } from '@interflora/ui-components'

type Props = UIProps

const FFHomeHeroBanner = (props: Props) => {
  const analytics = useContext(AnalyticsContext)

  const homeHeroCTAnalytics = (category?: string) => {
    analytics.homeHeroBanner(category)
  }

  useLoaded(props)

  return <FFHomeHeroBannerUI {...props} homeHeroCTAnalytics={homeHeroCTAnalytics} />
}

export default FFHomeHeroBanner
