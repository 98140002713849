import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    recentOrderContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridColumnGap: '0',
        gridRowGap: spacing.xl,
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridColumnGap: spacing.medium,
        },
    },
}));
export default useStyles;
