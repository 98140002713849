import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '../Link/Link';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './ExplanatorySteps.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const ExplanatorySteps = (props) => {
    const { heading, message, link, items, headingTag = 'H2' } = props; // To make sure we handle any nulls from graphQL
    const HeadingTag = headingTag === null || headingTag === void 0 ? void 0 : headingTag.toLowerCase();
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs("div", { className: classes.container, children: [heading && _jsx(HeadingTag, { className: classes.heading, children: heading }), message && _jsx("p", { className: classes.message, children: message }), (items === null || items === void 0 ? void 0 : items.length) && (_jsx("ol", { type: "1", className: classes.list, children: items.map((item) => {
                    const ItemHeadingTag = (item.headingTag || 'H3').toLowerCase();
                    return (_jsxs("li", { className: classes.item, children: [_jsx(ItemHeadingTag, { className: classes.itemHeading, children: item.heading }), _jsx(RawHtml, { className: classes.itemContent, content: item.content })] }, item.heading));
                }) })), link && (_jsx("div", { className: classes.linkContainer, children: _jsx(Link, Object.assign({}, link, { variant: 'secondary',
                    button: true,
                    thin: true,
                    className: classes.link })) }))] }));
};
export default ExplanatorySteps;
