import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {},
    dataPolicy: {
        '& h4': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { marginTop: `${spacing.small} !important` }),
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { marginBottom: `${spacing.small} !important` }),
    },
    titleText: Object.assign(Object.assign({ [theme.breakpoints.down('xl')]: Object.assign({}, (theme.isFlyingFlowers ? '' : theme.typography.small_bold)) }, theme.typography.small_regular), { marginBottom: `${spacing.small} !important` }),
    error: { marginBottom: spacing.small },
    input: { marginBottom: spacing.small },
    marketing: {
        marginBottom: theme.isFlyingFlowers ? spacing.large : spacing.small,
    },
    termsText: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '0 !important', color: theme.isFlyingFlowers ? '' : `${colors.grey.grey6} !important` }),
    link: Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }),
    optionsLink: {
        textDecoration: 'underline',
        color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5,
    },
    linkDisplay: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5,
        display: 'contents !important',
    },
    marketingPreferences: {
        '& .MuiCheckbox-root': {
            padding: theme.isFlyingFlowers ? `0px ${spacing.xs} ${spacing.xs} ${spacing.xs}` : '0px 12px 12px 12px',
        },
        '& .MuiFormControlLabel-label': {
            paddingTop: theme.isFlyingFlowers ? '10px !important' : '3px !important',
            marginBottom: `${spacing.small} !important`,
            marginLeft: '0px !important',
        },
    },
    buttons: {
        marginBottom: spacing.medium,
        marginTop: spacing.large,
    },
}));
export default useStyles;
