import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import MyAccountReminder from '../MyAccountReminder/MyAccountReminder';
import AddRemindersModal from '../AddReminderModal/AddReminderModal';
const MyAccountReminders = (props) => {
    const { addNewReminder, remindersTotalCount, reminders } = props;
    const [isAddReminderOpen, setAddReminderOpen] = useState(false);
    const [addReminderStatusInfo, setReminderStatusInfo] = useState();
    useEffect(() => {
        if (addReminderStatusInfo === null || addReminderStatusInfo === void 0 ? void 0 : addReminderStatusInfo.reminderStatus) {
            /** creating custom event to send save reminder status messages to MyAccountWelcomeTitle component*/
            const reminderMessage = new CustomEvent('add-reminder-message', {
                detail: addReminderStatusInfo,
            });
            document === null || document === void 0 ? void 0 : document.dispatchEvent(reminderMessage);
        }
    }, [addReminderStatusInfo]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(MyAccountReminder, { openAddRemindersModal: setAddReminderOpen, remindersList: reminders, remindersTotalCount: remindersTotalCount }) }), _jsx(AddRemindersModal, { isAddReminderOpen: isAddReminderOpen, setAddReminderOpen: setAddReminderOpen, addNewReminder: addNewReminder, setReminderStatusInfo: setReminderStatusInfo })] }));
};
export default MyAccountReminders;
