import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { jsonLdScriptProps } from 'react-schemaorg';
import GenericContent from '../GenericContent/GenericContent';
import useGenericStyles from '../GenericContent/GenericContent.styles';
import Link from '../Link/Link';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import useStyles from './HomeVideoBanner.styles';
import Head from 'next/head';
import { Box } from '@mui/material';
const HomeVideoBanner = (props) => {
    const links = props.links;
    const { classes } = useStyles();
    const { classes: genericClasses } = useGenericStyles({});
    const { videoSchemaSEO, headingColor = 'BLACK' } = props;
    const heading = typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line, index) => (_jsx(Box, { component: 'span', className: clsx(classes.headingLine), children: line.trim() }, index)))
        : props.heading;
    return (_jsxs(_Fragment, { children: [!!videoSchemaSEO ? (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps(JSON.parse(videoSchemaSEO)))) })) : null, _jsx(GenericContent, Object.assign({}, props, { classNames: classes, font: "SOHNE", headingLevel: "NONE", heading: heading, headingColor: headingColor, linkStyle: 'BUTTON', fullWidthImage: true, contentPosition: "CENTER", mobileContentPosition: "BELOW", minHeight: "LARGE", showTrustpilotWidget: props.showTrustpilotWidget, trustpilotBackground: props.trustpilotBackground, trustPilotDarknessLevel: props.trustPilotDarknessLevel, isFromHomeVideoBanner: true })), links && (_jsx(Box, { className: clsx(genericClasses.linkContainer, classes.outsideLinkContainer), children: links.map((link, index) => (_jsx(Link, Object.assign({ button: true, variant: "primary", thin: true, className: clsx(genericClasses.link, genericClasses.linkButton, classes.link) }, link), index))) })), props.showTrustpilotWidget && (_jsx(Box, { className: clsx(classes.outsideShowTrustpilotWidget), children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) }))] }));
};
export default HomeVideoBanner;
