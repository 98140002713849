import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import ContentLinkItem from '../ContentLinkItem/ContentLinkItem';
import GenericGalleryGrid from '../GenericGalleryGrid/GenericGalleryGrid';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './ContentLinkGallery.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const ContentLinkGallery = (props) => {
    const { title, description, textAlignment, contentLinkItems } = props;
    const { classes } = useStyles();
    const breakpoints = {
        extraSmall: 12,
        small: 12,
        medium: 6,
        large: 4,
    };
    useLoaded(props);
    return (_jsxs("div", { className: classes.contetLinkGalleryContainer, children: [_jsxs("div", { className: clsx(textAlignment === 'CENTERED' && classes.textCentered), children: [title && _jsx("h2", { className: classes.title, children: title }), _jsx(RawHtml, { className: classes.description, content: description })] }), !!(contentLinkItems === null || contentLinkItems === void 0 ? void 0 : contentLinkItems.length) && (_jsx(GenericGalleryGrid, { ItemComponent: ContentLinkItem, items: contentLinkItems, breakpoints: breakpoints, className: classes.contentGalleryGrid }))] }));
};
export default ContentLinkGallery;
