import { jsx as _jsx } from "react/jsx-runtime";
import { default as _uniqBy } from 'lodash/uniqBy';
import { useContext } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { filterAttributeNameMapping, guidedNavigationFilters, SiteContext } from '../../utils/common';
import FilterChip from '../FilterChip/FilterChip';
import useStyles from './FilterCurrentRefinements.styles';
const FilterCurrentRefinements = (props) => {
    const { priceRanges } = props;
    const { items } = useCurrentRefinements();
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    // Remove guided navigation filters
    const filteredItems = _uniqBy(items === null || items === void 0 ? void 0 : items.filter((item) => !guidedNavigationFilters.includes(item.attribute)), 'attribute');
    // Convert refinement for price attribute with one or two refinement values into a single refinement value
    // with a refine function that removes all values and uses the original label from priceRanges
    const createPriceItem = (item) => {
        const refinementValues = item.refinements.map((refinement) => refinement.value);
        const priceRange = priceRanges.find((priceRange) => refinementValues.every((value) => value === priceRange.start || value === priceRange.end));
        return Object.assign(Object.assign({}, item), { refinements: [Object.assign(Object.assign({}, item.refinements[0]), { label: `${item.label}: ${priceRange === null || priceRange === void 0 ? void 0 : priceRange.label}` })], refine: () => {
                item.refinements.forEach((refinement) => item.refine(refinement));
            } });
    };
    const updateItems = filteredItems.reduce((acc, item) => [...acc, item.attribute !== 'price' ? item : createPriceItem(item)], []);
    return (_jsx("div", { className: classes.filterCurrentRefinements, children: (updateItems === null || updateItems === void 0 ? void 0 : updateItems.length) ? (_jsx("ul", { className: classes.filterCurrentRefinements, children: updateItems.map((item) => {
                const attributeLabel = filterAttributeNameMapping(isFlyingFlowers).get(item.attribute);
                return item.refinements.map((refinement) => (_jsx("li", { children: _jsx(FilterChip, { label: refinement.label === 'true'
                            ? `${attributeLabel}: Yes`
                            : refinement.label === 'false'
                                ? `${attributeLabel}: No`
                                : refinement.label, onDelete: () => item.refine(refinement) }) }, `${item.attribute}-${refinement.label}`)));
            }) })) : (_jsx("span", { className: classes.noFilterText, children: "Active filters will be shown here" })) }));
};
export default FilterCurrentRefinements;
