import Script from 'next/script'
import React from 'react'

/*
 * DO NOT CHANGE THIS CODE WITHOUT GOOD REASON!
 *
 * The functions exported here are responsible for rendering out the
 * Google Tag Manager tags as defined in the following page:
 * https://developers.google.com/tag-manager/quickstart
 *
 * The container ID is supplied by the build process and is specific
 * to each environment.
 *
 * Updated to allow setting initial data layer values from static props
 */

export function renderGtmTags(initialDataLayer = {}) {
  const gtmId = getGtmId()
  return (
    gtmId && (
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          // Define dataLayer and the gtag function
              window.dataLayer = window.dataLayer || [];
              // Push initial data layer values
              window.dataLayer.push(${JSON.stringify(initialDataLayer)});
              function gtag(){dataLayer.push(arguments);}
              // Default ad_storage to 'denied'.
              gtag('consent', 'default', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                wait_for_update: 2000, // 2 seconds
              });
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');`,
        }}
      />
    )
  )
}

export function renderGtmNoScriptTag() {
  const gtmId = getGtmId()
  return (
    !!gtmId && (
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe
          src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
          ></iframe>`,
        }}
      />
    )
  )
}

/**
 * Get the Google Tag Manager ID. This should always be available in the
 * sever-side code as `process.env.GTM_ID`, but may also be required when
 * events are fired from the client-side code, where it can be found in
 * `process.env.NEXT_PUBLIC_GTM_ID`
 */
export function getGtmId() {
  return process.env.GTM_ID || process.env.NEXT_PUBLIC_GTM_ID || ''
}
