import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
import { MAX_CARD_WIDTH } from '../../styles/jss/utils';
const useStyles = makeStyles()((theme) => ({
    productCard: {
        width: '100%',
        maxWidth: `${MAX_CARD_WIDTH}px`,
        margin: '0 auto',
        marginBottom: spacing.medium,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        '& .react-multi-carousel-dot-list': {
            position: 'initial',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
        '& span > span': {
            borderRadius: 0,
            lineHeight: 'inherit',
        },
    },
    productGrid: {
        flexGrow: 1,
        [theme.breakpoints.down('xl')]: {
            justifyContent: 'center',
        },
    },
    gridItem: {
        width: '100%',
    },
    list: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.between('sm', 'xl')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: spacing.medium,
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: spacing.medium,
        },
    },
    topSection: {},
    contentSection: {
        padding: spacing.large,
    },
    cardTitle: {
        margin: `${spacing.small} 0`,
    },
    cardMetrics: {
        marginBottom: spacing.xs,
    },
    cardPrice: {
        marginBottom: 0,
        marginTop: spacing.small,
    },
}));
export default useStyles;
