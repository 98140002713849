import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    image: {
        width: '64px',
        height: '64px',
        margin: spacing.small,
        [theme.breakpoints.up('md')]: {
            width: '108px',
            height: '108px',
        },
    },
    message: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none),
    country: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.none) }),
    box: {
        display: 'flex',
        width: '100%',
        maxWidth: '335px',
        border: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.32)',
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            width: '384px',
            margin: `0 12px ${spacing.small}`,
        },
        '&:hover': {
            backgroundColor: colors.grey.grey1,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.32)',
            cursor: 'pointer',
        },
        '&:focus': {
            boxShadow: '0px 0px 2px #008be9, 0px 2px 2px rgba(0, 0, 0, 0.32)',
        },
    },
    messageContent: {
        marginTop: '19px',
        marginLeft: spacing.xs,
    },
}));
export default useStyles;
