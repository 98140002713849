import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    dayPicker: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing.large,
    },
}));
export default useStyles;
