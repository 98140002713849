var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import AddressLookup from '../../AddressLookup/AddressLookup';
import useStyles from './PCCDeliveryAddress.styles';
import { addressFields, errorMessages, getCountryFromAlpha2Code, SiteContext, inputValidationRegex, } from '../../../utils/common';
import { FormikContext } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import Link from '../../Link/Link';
export const validationAddressSchema = Yup.object({
    address: Yup.string()
        .min(4, errorMessages(4).minChars)
        .required(errorMessages().required)
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    addressLine1: Yup.string()
        .required('Please enter address line 1')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    addressLine2: Yup.string().matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    city: Yup.string()
        .required('Please enter a city')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    county: Yup.string().when('isManualAddress', {
        is: true,
        then: Yup.string()
            .required('Please enter a county')
            .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    }),
    postalCode: Yup.string()
        .when('shouldValidatePostCode', {
        is: true,
        then: Yup.string().required('Please enter a postcode'),
    })
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    country: Yup.string().required('Please select a country'),
    isManualAddress: Yup.boolean(),
});
const PCCDeliveryAddress = (props) => {
    var _a;
    const { currentStep, step, isOpen, setCurrentStep, setIsShowDayPicker, showInternationalLink, setShowInternationalLink, trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress, trackEnterManualAddressClick, locationInternationalDelivery, } = props;
    const { classes } = useStyles();
    const formikContext = useContext(FormikContext);
    const { getFormattedAddress, countryCode, shorthand, isFlyingFlowers } = useContext(SiteContext);
    const labelText = isFlyingFlowers ? 'Search for address' : 'Delivery address';
    const placeholderText = useMemo(() => {
        if (shorthand === 'ie')
            return 'Try the eircode or street name';
        else if (isFlyingFlowers)
            return 'Enter delivery postcode';
        else
            return 'Type postcode or full address';
    }, [isFlyingFlowers, shorthand]);
    const setManualAddressChosen = (isManualAddress) => {
        if (formikContext) {
            formikContext.setFieldValue('isManualAddress', isManualAddress);
            if (isManualAddress)
                trackEnterManualAddressClick === null || trackEnterManualAddressClick === void 0 ? void 0 : trackEnterManualAddressClick('PCC');
        }
    };
    let addressResponse;
    const countryDetails = getCountryFromAlpha2Code(countryCode);
    const handleAddressFormatCall = (suggestion, format) => __awaiter(void 0, void 0, void 0, function* () {
        if (!getFormattedAddress) {
            throw new Error('Format address callback not found in context');
        }
        else {
            const { data, error } = yield getFormattedAddress({
                input: {
                    suggestion,
                    format,
                    countryCode,
                },
            });
            if (error) {
                throw new Error(`Address formatting returned an error response: ${error}`);
            }
            else if (!data) {
                throw new Error('No results returned from address formatting');
            }
            addressResponse = data.formatAddress;
            return data.formatAddress;
        }
    });
    const handleStep = () => {
        var _a, _b;
        if (formikContext.values.date && !formikContext.values.occasion)
            setCurrentStep(3);
        else if (!formikContext.values.date)
            setCurrentStep(1);
        else
            (_a = document === null || document === void 0 ? void 0 : document.querySelector('textarea')) === null || _a === void 0 ? void 0 : _a.blur();
        if ((_b = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _b === void 0 ? void 0 : _b.isManualAddress) {
            trackPostCodeCheckerManualAddress === null || trackPostCodeCheckerManualAddress === void 0 ? void 0 : trackPostCodeCheckerManualAddress();
        }
        else {
            trackPostCodeCheckerAddress === null || trackPostCodeCheckerAddress === void 0 ? void 0 : trackPostCodeCheckerAddress(addressResponse === null || addressResponse === void 0 ? void 0 : addressResponse.postalCode, addressResponse === null || addressResponse === void 0 ? void 0 : addressResponse.city, addressResponse === null || addressResponse === void 0 ? void 0 : addressResponse.county);
        }
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isManualAddress', false);
        setIsShowDayPicker(true);
    };
    const handleShowInternationalLink = () => {
        if (setShowInternationalLink) {
            setShowInternationalLink(true);
        }
    };
    return (_jsx(_Fragment, { children: currentStep === step ? (_jsxs("div", { className: clsx(classes.pccAddressContainer, ((_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.isManualAddress) ? classes.manualAddressContainer : '', showInternationalLink ? classes.internationalContainer : ''), children: [_jsx(AddressLookup, { name: "address", label: labelText, placeholder: placeholderText, addressFields: addressFields, setManualAddressChosen: setManualAddressChosen, handleAddressFormatCall: handleAddressFormatCall, required: true, fullWidth: true, hideRequiredOptional: true, provideManualAddress: true, className: clsx(isOpen && classes.autoCompleteClass), boundariesElement: "scrollParent", countryCode: countryDetails === null || countryDetails === void 0 ? void 0 : countryDetails.codeAlpha3, countryList: [countryDetails], isFromPCC: true, setCurrentStep: handleStep }), !isFlyingFlowers ? (_jsx("div", { className: classes.internationalDelivery, children: _jsx(Link, { className: classes.internationalDeliveryLink, icon: "", label: "Do you want international delivery?", onClick: () => {
                            handleShowInternationalLink();
                            locationInternationalDelivery === null || locationInternationalDelivery === void 0 ? void 0 : locationInternationalDelivery('PCC');
                        } }) })) : ('')] })) : null }));
};
export default PCCDeliveryAddress;
