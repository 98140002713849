import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    tags: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginBottom: spacing.small,
    },
    adjustTagSpacing: {
        marginBottom: spacing.xs,
        justifyContent: theme.isFlyingFlowers ? 'center' : 'initial',
    },
    tag: Object.assign(Object.assign({ backgroundColor: colors.grey.grey1, color: colors.grey.grey6 }, theme.typography.small_regular), { marginRight: spacing.xs }),
    tagSmall: {
        [theme.breakpoints.down('sm')]: Object.assign({}, theme.typography.xxs_regular),
    },
    activeLinkItem: {
        backgroundColor: theme.isFlyingFlowers
            ? `${colors.lazyLime[300]} !important`
            : `${colors.primary.gold1} !important`,
    },
}));
export default useStyles;
