import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    recentOrderCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '50%',
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { color: colors.supplementary.black }),
    recentOrder: {
        flex: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flex: '50%',
        },
    },
    noOrderTitle: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: '13px', color: colors.supplementary.black }),
    noOrderDescription: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.xl_regular_lg), { color: colors.grey.grey6 }),
}));
export default useStyles;
