import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, fontSizes, spacing } from '../../styles/jss/variables';
export const minHeights = {
    SMALL: { xs: 335, sm: 300, md: 300, lg: 300, xl: 300 },
    MEDIUM: { xs: 335, sm: 300, md: 436, lg: 512, xl: 512 },
    LARGE: { xs: 335, sm: 320, md: 476, lg: 600, xl: 824 },
    BLOG: { xs: 376, sm: 400, md: 500, lg: 500, xl: 512 },
};
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        // For full width image to position against
        position: 'relative',
        display: 'flex',
        // Mobile - image below content
        flexDirection: 'column-reverse',
        placeContent: 'center',
        overflow: 'hidden',
        '& video': {
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            objectFit: 'cover',
        },
    },
    displayNone: {
        display: 'intial',
        position: 'relative',
        flexDirection: 'column-reverse',
        placeContent: 'center',
        overflow: 'hidden',
        '& video': {
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            objectFit: 'cover',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    positionLeft: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom right',
            },
        },
    },
    positionRight: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            [`& .${classes.content}`]: {
                justifyContent: 'right',
            },
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom left',
            },
        },
    },
    positionCenter: {
        [theme.breakpoints.up('sm')]: {
            [`& .${classes.imageSpacer}`]: {
                display: 'none',
            },
            [`& .${classes.content}`]: {
                width: '100%',
            },
        },
    },
    mobilePositionOver: {
        [theme.breakpoints.down('sm')]: {
            [`& .${classes.image}`]: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
            },
            [`& .${classes.ffImage}`]: {
                position: 'relative',
            },
        },
    },
    mobilePositionBottom: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    ffMobileheight: {
        [theme.breakpoints.down('sm')]: {
            minHeight: '640px !important',
            placeContent: 'start !important',
        },
    },
    titleBaseline: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    galleryTitleBaseline: {
        minHeight: '388px !important',
        height: '388px !important',
        [theme.breakpoints.down('xl')]: {
            placeContent: 'start',
        },
    },
    galleryTitle: {
        minHeight: '444px !important',
        height: '444px !important',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'start',
        },
    },
    minHeightSmall: {
        [`&, & .${classes.image}`]: {
            minHeight: minHeights.SMALL.xs,
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.SMALL.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.SMALL.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.SMALL.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.SMALL.xl,
            },
        },
        '&, & video': {
            minHeight: '212px',
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.SMALL.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.SMALL.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.SMALL.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.SMALL.xl,
            },
        },
    },
    // Based on Banner - m-promo_fullwidth_slim_text-on-image
    minHeightMedium: {
        [`&, & .${classes.image}`]: {
            minHeight: minHeights.MEDIUM.xs,
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.MEDIUM.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.MEDIUM.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.MEDIUM.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.MEDIUM.xl,
            },
        },
    },
    // Based on Home Hero Banner - m-promo_fullwidth_large
    minHeightLarge: {
        [`&, & .${classes.image}`]: {
            minHeight: minHeights.LARGE.xs,
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.LARGE.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.LARGE.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.LARGE.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.LARGE.xl,
            },
        },
        [`& .${classes.videoContainer}`]: {
            minHeight: '335px',
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.LARGE.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.LARGE.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.LARGE.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.LARGE.xl,
            },
        },
    },
    // Based on Home Hero Banner - m-promo_fullwidth_large
    minHeightBlog: {
        [`&, & .${classes.image}`]: {
            minHeight: minHeights.BLOG.xs,
            [theme.breakpoints.up('sm')]: {
                minHeight: minHeights.BLOG.sm,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: minHeights.BLOG.md,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: minHeights.BLOG.lg,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: minHeights.BLOG.xl,
            },
        },
    },
    ffImage: {
        '& img': {
            objectFit: 'cover',
        },
    },
    image: {
        // default on mobile the image is full width and positioned above or below the content
        width: '100%',
        position: 'relative',
        '& img': {
            // img fills the relative container (image or container) using absolute and cover
            objectFit: 'cover',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
        // Make square on mobile
        paddingTop: '100%',
        [theme.breakpoints.up('xs')]: {
            // Use min heights on tablet up
            paddingTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            // default on desktop is for image to be 50% wide unless fullImage
            width: '50%',
        },
        // backup  image min height if other min heights not used
        minHeight: '200px',
        [`&.${classes.ffImage}`]: {
            paddingTop: 0,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: 'auto',
            },
            '& img': {
                // flying flowers image is contained and positioned at the bottom
                // objectFit: 'contain',
                objectPosition: 'bottom',
                [theme.breakpoints.down('sm')]: {
                    position: 'relative',
                    height: 'auto',
                    width: '100%',
                    maxHeight: '335px',
                },
            },
        },
        [`&.${classes.fullWidth}`]: {
            [theme.breakpoints.up('sm')]: {
                // desktop image to be full width and it to fill container
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
            },
        },
    },
    ffImage1: {
        '& img': {
            [theme.breakpoints.down('sm')]: {
                maxHeight: '388px',
            },
        },
    },
    ffImage2: {
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: '640px',
            },
        },
    },
    ffContentInner: {
        marginBottom: `${spacing.large} !important`,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'initial',
            marginBottom: spacing.xxl,
            display: 'initial',
        },
    },
    videoContainer: {
        // default on mobile the image is full width and positioned above or below the content
        width: '100%',
        position: 'relative',
        // Make square on mobile
        paddingTop: '100%',
        [theme.breakpoints.up('xs')]: {
            // Use min heights on tablet up
            paddingTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            // default on desktop is for image to be 50% wide unless fullImage
            width: '50%',
        },
        // backup  image min height if other min heights not used
        minHeight: '200px',
    },
    squareImage: {
        [theme.breakpoints.up('xs')]: {
            // Use padding top to make image square
            paddingTop: '100% !important',
        },
        [theme.breakpoints.up('sm')]: {
            // Use padding top to make image square
            paddingTop: '50% !important',
        },
    },
    fullWidth: {
        [theme.breakpoints.up('sm')]: {
            // desktop image to be full width and it to fill container
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
    },
    imageSpacer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: '50%',
        },
    },
    alignLeft: {
        textAlign: 'initial',
        alignSelf: 'baseline !important',
    },
    alignCenter: {
        textAlign: 'center',
    },
    content: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
        },
    },
    heroImageContentMobile: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
    },
    textLeft: {
        textAlign: 'left',
    },
    textRight: {
        textAlign: 'right',
    },
    textCenter: {
        textAlign: 'center',
    },
    // Needed for Content Feature
    contentInner: {
        width: '100%',
        margin: spacing.large,
        [theme.breakpoints.between('sm', 'lg')]: {
            marginBottom: '56px',
        },
        [theme.breakpoints.up('md')]: {
            margin: spacing.xxl,
            alignSelf: 'center',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '128px',
            alignSelf: 'center',
        },
        '& > :last-child': {
            marginBottom: '0px',
        },
    },
    black: {
        color: `${colors.supplementary.black} !important`,
    },
    white: {
        color: `${colors.supplementary.white} !important`,
    },
    deliveryPassInnerContent: {
        margin: '32px',
        alignSelf: 'center',
    },
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.xxl), [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.xxxl) }),
    // Based on Hero Banner
    subheading: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.large_bold_lg), [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.large_bold), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    subheadingColor: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.large_bold_lg), [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.large_bold), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), [theme.breakpoints.down('sm')]: {
            color: colors.supplementary.white,
            margin: '0px 16px 0px 16px',
        } }),
    // Based on Hero Banner
    message: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.medium_regular), [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.large_regular) }),
    fullWidthMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.medium_regular), [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.large_regular), [theme.breakpoints.down('sm')]: {
            color: colors.supplementary.white,
            margin: '0px 16px 24px 16px',
            paddingTop: `${spacing.xxs} !important`,
        } }),
    linkContainer: { display: 'inline-flex', flexDirection: 'column' },
    heroLinkContainer: {
        display: 'inline',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-block',
        },
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
    },
    linkText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(colors.primary.gold4)), { color: colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    linkButton: {},
    // Needed for Content Feature
    contentWithQuotationMarks: {
        [`& .${classes.contentInner}`]: {
            marginTop: '48px',
            marginBottom: '48px',
            [theme.breakpoints.up('md')]: {
                marginTop: '116px',
                marginBottom: '116px',
            },
        },
    },
    // Quotation Marks from Content Feature
    quotationMark: {
        color: 'inherit',
        '& .material-icons': {
            fontSize: `${fontSizes.medium} !important`,
            [theme.breakpoints.up('md')]: {
                fontSize: `${fontSizes.xxxl} !important`,
            },
        },
    },
    quotationMarkStart: {
        transform: 'scaleX(-1)',
        top: '30px',
        left: '32px',
        [theme.breakpoints.up('md')]: { top: '61px', left: '73px' },
    },
    quotationMarkEnd: {
        right: '26px',
        bottom: '24px',
        [theme.breakpoints.up('md')]: {
            right: '64px',
            bottom: '69px',
        },
    },
    ffMessage: {
        display: 'none',
        '@media(orientation: landscape)': {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            color: colors.supplementary.white,
            display: 'block',
        },
    },
    ffVideoCenter: {
        [theme.breakpoints.up('sm')]: {
            minHeight: 'initial !important',
        },
    },
    ffVideoContentInner: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'initial',
        },
    },
    overlayTrustpilotWidget: {
        minWidth: '306px',
        minHeight: '60px',
        display: 'none',
        padding: '18px',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            position: 'absolute',
            top: spacing.medium,
            right: spacing.medium,
            zIndex: 11,
        },
        '& div': {
            '& iframe': {},
        },
    },
    lightTrustpilotBackground: {
        backgroundColor: 'transparent',
    },
    darkTrustpilotBackground: {
        backgroundColor: `${colors.background.WHITE} !important`,
    },
    imageContain: {},
    imageCover: {
        '& img': {
            objectFit: 'cover', //Need to get back: !important
        },
    },
    ffImagePoistion: {
        objectPosition: 'bottom',
        '& img': {
            objectFit: 'contain !important', // Important was added as clsx function is not applying this classname in the correct order
        },
    },
    adjustMessage: {
        marginBottom: 0,
        [theme.breakpoints.up('sm')]: {
            marginBottom: spacing.large,
            textAlign: 'left',
        },
    },
    removeMarginBottom: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: spacing.medium,
            marginTop: spacing.small,
            marginLeft: '20px',
            marginRight: '20px',
        },
    },
    linkContentContainer: {
        display: 'inline-flex !important',
    },
    adjustLeftMargin: {
        marginLeft: `${spacing.large} !important`,
        [theme.breakpoints.up('sm')]: {
            marginLeft: `${spacing.xxl} !important`,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '128px',
        },
    },
    adjustRightMarin: {
        marginRight: `${spacing.large} !important`,
        [theme.breakpoints.up('sm')]: {
            marginRight: `${spacing.xxl} !important`,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '128px',
        },
    },
    adjustLinkContainer: {
        width: '100%',
    },
    darknessLinksHeightAdjust: {
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: (_params === null || _params === void 0 ? void 0 : _params.linksCount) === 1 ? '388px !important' : '444px !important',
                minHeight: (_params === null || _params === void 0 ? void 0 : _params.linksCount) === 1 ? '388px !important' : '444px !important',
            },
        },
    },
    adjustFFImageContainer: {
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: (_params === null || _params === void 0 ? void 0 : _params.linksCount) === 1 ? '268px !important' : '330px !important',
                maxHeight: (_params === null || _params === void 0 ? void 0 : _params.linksCount) === 1 ? '268px !important' : '330px !important',
            },
        },
    },
    citation: Object.assign(Object.assign({}, theme.typographySpacing.none), { fontStyle: 'italic' }),
    contentFeatureQuoteContainer: {
        position: 'relative',
    },
    openQuote: {
        transform: 'rotate(180deg)',
    },
    closeQuote: {
        width: '100%',
        flexDirection: 'initial',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    contentFeatureQuote: {
        '& .material-icons': {
            fontSize: `${fontSizes.xl} !important`,
            [theme.breakpoints.up('md')]: {
                fontSize: `${fontSizes.xxl} !important`,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.isFlyingFlowers ? `58px !important` : `${fontSizes.xxxxl}!important`,
            },
        },
    },
    contentFeatureTitle: {
        marginBottom: '4px !important',
        [theme.breakpoints.up('xl')]: {
            marginBottom: '10px !important',
        },
    },
    citationMessage: {
        marginBottom: '20px !important',
    },
    squareVideoContainer: {
        paddingTop: '100%',
        '& > div': {
            position: 'unset',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '50%',
        },
    },
    headingColor: {
        color: (_params === null || _params === void 0 ? void 0 : _params.headingColor)
            ? `${colors.genericContentHeading[_params === null || _params === void 0 ? void 0 : _params.headingColor]} !important`
            : '',
    },
    galleryContent: {
        padding: `${spacing.small}`,
        [theme.breakpoints.up('sm')]: {
            padding: `${spacing.small} ${spacing.medium} ${spacing.small} ${spacing.xl} !important`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${spacing.small} ${spacing.medium} ${spacing.small} ${spacing.xxl} !important`,
        },
    },
    fontSohne: {},
    noBottomMargin: Object.assign({}, theme.typographySpacing.none),
}));
export default useStyles;
