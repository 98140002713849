import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Button from '../Button/Button';
import useStyles from './AddIDPToBasket.styles';
const IDPModalContent = ({ sku, hasDeliveryPassInAccount, setIsOpen, viewBasket, addDeliveryPassToCart, activeDeliveryPassType, }) => {
    const { classes } = useStyles();
    const router = useRouter();
    let content;
    if (hasDeliveryPassInAccount) {
        /** user has already deliveryPass in his account */
        content = (_jsxs("div", { children: [_jsx("div", { className: classes.heading, children: "You've already got an active delivery pass" }), _jsx("div", { className: classes.message, children: "Visit your account for more details" }), _jsxs("div", { className: classes.modalActionContainer, children: [_jsx(Button, { isSubmit: true, title: "View My Account", variant: "secondary", thin: true, onClick: () => router.push('/account') }), _jsx(Button, { onClick: () => setIsOpen(false), title: "Cancel", variant: "tertiary", thin: true })] })] }));
    }
    else if (viewBasket) {
        /** platinum deliveryPass exists in basket */
        const isPlatinumInBasket = activeDeliveryPassType === null || activeDeliveryPassType === void 0 ? void 0 : activeDeliveryPassType.includes('PLATINUM');
        content = (_jsxs("div", { children: [_jsxs("div", { className: clsx(classes.heading, classes.viewBasketHeading), children: ["You\u2019ve already got an Interflora ", isPlatinumInBasket ? 'Platinum' : 'Gold', " delivery pass in your basket"] }), isPlatinumInBasket && sku.includes('GOLD') ? (_jsx("div", { className: classes.message, children: "Do you want to amend your order?" })) : null, _jsxs("div", { className: classes.modalActionContainer, children: [_jsx(Button, { isSubmit: true, title: "View basket", variant: "secondary", thin: true, onClick: () => router.push('/basket') }), _jsx(Button, { onClick: () => setIsOpen(false), title: "Cancel", variant: "tertiary", thin: true })] })] }));
    }
    else {
        /** if user has gold in his basket and adding platinum */
        content = (_jsxs("div", { children: [_jsx("div", { className: classes.heading, children: "You\u2019ve already got a Gold delivery pass in your basket" }), _jsx("div", { className: classes.message, children: "Do you want to upgrade to a Platinum delivery pass and enjoy Unlimited FREE next day and same day delivery for 12 months?" }), _jsxs("div", { className: classes.modalActionContainer, children: [_jsx(Button, { isSubmit: true, title: "Upgrade to platinum", variant: "secondary", thin: true, onClick: () => addDeliveryPassToCart(sku, true) }), _jsx(Button, { onClick: () => setIsOpen(false), title: "Cancel", variant: "tertiary", thin: true })] })] }));
    }
    return content;
};
export default IDPModalContent;
