import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import clsx from 'clsx';
import useStyles from './PaymentTypeIcons.styles';
import { paymentTypeLogos } from '../../common/data/menuItems.data';
import { SiteContext } from '../../utils/common';
import Image from 'next/image';
const PaymentTypeIcons = ({ isFromCheckoutFooter }) => {
    const { classes } = useStyles();
    const { isKlarnaEnabled } = useContext(SiteContext);
    const modifiedPaymentLogos = !isKlarnaEnabled
        ? paymentTypeLogos.filter((paymentType) => paymentType.type !== 'Klarna')
        : paymentTypeLogos;
    return (_jsx("div", { className: paymentTypeLogos.length > 6 && isFromCheckoutFooter ? classes.iconCheckout : classes.iconFooter, children: _jsx("ul", { className: clsx(classes.cards, isFromCheckoutFooter ? classes.checkoutFooterCard : classes.footerCard), children: modifiedPaymentLogos === null || modifiedPaymentLogos === void 0 ? void 0 : modifiedPaymentLogos.map((card) => (_jsx("li", { children: _jsx(Image, { src: card.url, alt: card.type, loading: "lazy", width: 58, height: 40 }) }, `${card.type}`))) }) }));
};
export default PaymentTypeIcons;
