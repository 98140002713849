var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useRef, useEffect } from 'react';
import useStyles from './NewsletterSignUpForm.styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { errorMessages, isCookieAccepted, inputValidationRegex } from '../../../utils/common';
import { processImageUrl } from '../../../utils/image';
import Alert from '../../Alert/Alert';
import Button from '../../Button/Button';
import Image from '../../Image/Image';
import RawHtml from '../../RawHtml/RawHtml';
import TextField from '../../TextField/TextField';
import { SiteContext } from '../../../utils/common';
import Link from '../../Link/Link';
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(50, errorMessages(50).maxChars)
        .required('Please enter a first name')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    lastName: Yup.string()
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(40, errorMessages(40).maxChars)
        .required('Please enter a last name')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    email: Yup.string()
        .trim()
        .required(errorMessages().required)
        .email()
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
});
const NewsletterSignUpForm = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { signUpLink, privacyPolicyText, imageOnSignUpForm, bodyOnSignUpForm, onSubmit } = props;
    const [errorMessage, setErrorMessage] = useState(null);
    const containerRef = useRef(null);
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setErrorMessage('');
                const isPerformanceCookieAccepted = isCookieAccepted();
                if ((values === null || values === void 0 ? void 0 : values.email) && window.ometria && isPerformanceCookieAccepted) {
                    window.ometria.identify(values === null || values === void 0 ? void 0 : values.email);
                }
                yield onSubmit(values);
            }
            catch (error) {
                const foundDuplicateError = (error === null || error === void 0 ? void 0 : error.message.includes('Found Duplicate Record')) ? (_jsxs("p", { children: ["There\u2019s already an account registered with this email address. Try", ' ', _jsx(Link, { url: "/sign-in", label: "Signing in" }), " instead."] })) : ('Please try again.');
                setErrorMessage(foundDuplicateError);
            }
        }),
    };
    useEffect(() => {
        var _a;
        if (errorMessage && (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current)) {
            (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errorMessage]);
    return (_jsxs("div", { className: classes.newsletterSignUpContainer, children: [_jsxs("div", { className: classes.signUpForm, ref: containerRef, children: [_jsx(RawHtml, { className: classes.message, content: bodyOnSignUpForm }), errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsxs(Form, { children: [_jsx("div", { className: classes.input, children: _jsx(TextField, { name: "firstName", label: "First name", placeholder: "e.g. John", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "lastName", label: "Last name", placeholder: "e.g. Smith", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "email", label: "Email address", placeholder: "e.g. john.smith@domain.co.uk", fullWidth: true, required: true }) }), _jsx("div", { className: classes.buttons, children: _jsx(Button, { variant: isFlyingFlowers ? 'secondaryDark' : 'primary', isSubmit: true, disabled: isSubmitting, title: signUpLink.label }) }), _jsx(RawHtml, { className: classes.privacyPolicyText, content: privacyPolicyText })] })) }))] }), (imageOnSignUpForm === null || imageOnSignUpForm === void 0 ? void 0 : imageOnSignUpForm.url) && (_jsx("div", { className: classes.signUpFormImage, children: _jsx("div", { className: classes.imgContainer, children: _jsx(Image, { className: classes.image, imageUrl: processImageUrl(imageOnSignUpForm === null || imageOnSignUpForm === void 0 ? void 0 : imageOnSignUpForm.url, { w: 440, aspect: '2:3' }, ['$poi-square$']), altText: (imageOnSignUpForm === null || imageOnSignUpForm === void 0 ? void 0 : imageOnSignUpForm.altText) || '', width: 500, height: 500 }) }) }))] }));
};
export default NewsletterSignUpForm;
