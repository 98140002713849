import { makeStyles } from 'tss-react/mui';
import { addPadding, link, removePaddingWithMargin } from '../../styles/jss/utils';
import { colors, fontWeights, spacing, typographyMontserrat, typographySohne } from '../../styles/jss/variables';
import { expandLinkStyles } from '../BasketViewDetails/BasketViewDetails.styles';
const useStyles = makeStyles()((theme, _params, classes) => ({
    alertTimer: {
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xxl,
        },
    },
    error: {
        marginBottom: spacing.small,
        marginRight: spacing.super,
    },
    container: {
        '& h4': Object.assign(Object.assign({}, theme.typographySpacing.small_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
        [theme.breakpoints.up('md')]: {
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
            [`& .${classes.price}`]: Object.assign({}, theme.typography.xl_bold),
            [`& .${classes.promoText}`]: Object.assign({}, theme.typography.medium_bold),
        },
        [theme.breakpoints.up('xl')]: {
            [`& .${classes.steps}`]: {
                marginRight: '296px',
            },
        },
    },
    secure: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { fontWeight: fontWeights.regular, color: colors.grey.grey6, display: 'none', [theme.breakpoints.up('md')]: {
            display: 'block',
        } }),
    heading: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.medium_regular)), theme.typographySpacing.xl_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxxl : theme.typography.xl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xxxl_lg : theme.typographySpacing.xl_bold_lg)), { fontWeight: fontWeights.regular }), color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, textTransform: theme.isFlyingFlowers ? 'uppercase' : undefined }),
    box: { [theme.breakpoints.up('md')]: { maxWidth: '656px', width: `calc(50% - ${spacing.small})` } },
    steps: {
        marginBottom: spacing.xl,
    },
    active: Object.assign(Object.assign(Object.assign({ borderTop: `${theme.isFlyingFlowers ? 'none' : `1px solid ${colors.supplementary.black}`}` }, removePaddingWithMargin), addPadding), { paddingTop: spacing.large, paddingBottom: spacing.large, marginBottom: spacing.xl, [theme.breakpoints.up('md')]: {
            border: `${theme.isFlyingFlowers ? 'none' : `1px solid ${colors.supplementary.black}`}`,
            marginLeft: `0 !important`,
            marginRight: `0 !important`,
            padding: spacing.xl,
            marginBottom: spacing.large,
            backgroundColor: `${theme.isFlyingFlowers ? colors.grey.grey1 : undefined}`,
        } }),
    orderSummaryContainer: {
        borderTop: `1px solid ${colors.supplementary.black}`,
        marginLeft: `-${spacing.small}`,
        marginRight: `-${spacing.small}`,
        paddingTop: spacing.xl,
        [theme.breakpoints.up('md')]: {
            border: '0',
            maxWidth: '656px',
            paddingTop: '0',
            width: `calc(50% - ${spacing.small})`,
        },
    },
    activeOrderSummaryContainer: {
        marginBottom: `${spacing.large}`,
    },
    activeOrderSummary: {
        border: `1px solid ${colors.supplementary.black}`,
        marginBottom: `${spacing.large}`,
        paddingRight: spacing.large,
        paddingLeft: spacing.large,
        [theme.breakpoints.up('md')]: {
            paddingRight: spacing.xl,
            paddingLeft: spacing.xl,
        },
        ['&.orderDetails']: {
            borderTopColor: colors.grey.grey2,
            paddingTop: spacing.small,
            paddingBottom: 0,
            [theme.breakpoints.up('md')]: {
                paddingTop: spacing.medium,
                paddingBottom: 0,
            },
        },
    },
    orderSummary: Object.assign(Object.assign({ margin: `0 ${spacing.small}` }, addPadding), { paddingBottom: 0, [theme.breakpoints.up('md')]: {
            border: 'none',
            padding: 0,
        } }),
    attached: {
        marginBottom: 0,
        paddingBottom: spacing.large,
        '&:not(:last-child)': {
            borderBottom: 'none',
        },
    },
    stepTitle: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: spacing.medium, color: theme.isFlyingFlowers ? colors.black[700] : '', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), overflowWrap: 'anywhere' }),
    subTitle: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: spacing.large, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : 'rgba(0, 0, 0, 0.87)' }) }),
    next: {
        marginTop: spacing.xl,
        '& button .MuiButton-startIcon': {
            color: theme.isFlyingFlowers ? colors.plushBlush[400] : '',
        },
    },
    pending: {
        backgroundColor: colors.grey.grey2,
        color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey5,
        marginBottom: spacing.small,
        borderRadius: theme.isFlyingFlowers ? '8px' : undefined,
        border: `1px solid ${theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey5}`,
        padding: theme.isFlyingFlowers ? `7px ${spacing.medium}` : `6px ${spacing.medium}`,
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers ? `33px ${spacing.xl} 35px ${spacing.xl}` : `6px ${spacing.medium}`,
        },
        '& span': Object.assign({}, theme.typography.large_bold),
    },
    completed: {
        border: `2px solid ${theme.statusColors.success.dark}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.isFlyingFlowers ? `${spacing.xxs} ${spacing.medium}` : `6px ${spacing.medium}`,
        borderRadius: theme.isFlyingFlowers ? '8px' : undefined,
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.small,
            padding: theme.isFlyingFlowers ? `33px ${spacing.xl} 35px ${spacing.xl}` : `6px ${spacing.medium}`,
        },
        '& > span:first-child': Object.assign({}, theme.typography.large_bold),
        [`& + .${classes.completed}`]: {
            marginTop: `-${spacing.small}`,
            [theme.breakpoints.up('md')]: { marginTop: 0 },
        },
    },
    tick: {
        color: `${theme.statusColors.success.dark} !important`,
        '& .MuiIcon-root': {
            fontSize: '1.75rem',
        },
    },
    labelTitle: {
        marginBottom: spacing.medium,
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
    },
    contactPreferences: {
        border: theme.isFlyingFlowers ? '' : `1px solid ${colors.grey.grey4}`,
        marginTop: spacing.small,
        padding: theme.isFlyingFlowers ? spacing.medium : spacing.large,
        marginBottom: spacing.xl,
        backgroundColor: theme.isFlyingFlowers ? colors.grey.grey1 : colors.supplementary.white,
        [theme.breakpoints.up('md')]: {
            backgroundColor: colors.supplementary.white,
        },
    },
    contactPreferencesTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.supplementary.black }),
    contactPreferencesOnline: {
        marginBottom: spacing.xs,
    },
    contactPreferencesPost: {
        marginBottom: spacing.medium,
    },
    link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, display: 'inline !important', textAlign: 'left' }),
    linkDisplay: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5,
        display: 'contents !important',
    },
    imageContainer: {
        maxWidth: '286px',
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            maxWidth: '200px',
            marginBottom: spacing.large,
        },
    },
    priceContainer: {
        marginBottom: spacing.medium,
    },
    price: Object.assign(Object.assign({}, theme.typography.medium_bold), { paddingRight: spacing.xs }),
    promoText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), (theme.isFlyingFlowers ? theme.typographySpacing.small_bold_lg : undefined)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.secondary.lavender }),
    productName: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, textTransform: theme.isFlyingFlowers ? 'upperCase' : 'initial' }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    variants: Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), '& > span:not(:last-child):after': {
            content: '"|"',
            marginLeft: spacing.xs,
            marginRight: spacing.xs,
        } }),
    finishingTouches: {
        marginTop: spacing.large,
        '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.large_bold_lg), { color: colors.supplementary.black }),
    },
    finishingTouchContainer: {
        border: `1px solid ${colors.grey.grey3}`,
        padding: spacing.small,
        '&:not(:last-of-type)': {
            marginBottom: spacing.small,
        },
        [theme.breakpoints.up('md')]: {
            border: '0',
            boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 8%)',
        },
    },
    highlight: {},
    addressList: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        } }),
    definitionList: {
        '& > li': {
            marginBottom: spacing.xs,
            overflowWrap: 'anywhere',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '&:last-child': {
                marginBottom: '0',
            },
        },
        '& div': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { overflowWrap: 'anywhere', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
        [`& .${classes.highlight}`]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.small_bold)), { minWidth: 0, textAlign: 'left', [theme.breakpoints.up('lg')]: {
                minWidth: '200px',
            } }),
    },
    totalOrderAmount: {
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right !important',
        },
    },
    adjacent: {
        '& > li': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    fullSpacing: {
        paddingBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xl,
        },
    },
    spinnerContainer: {
        position: 'relative',
        padding: theme.isFlyingFlowers ? '0 10px' : 0,
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    spinner: { position: 'absolute', top: 'calc(50% - 20px)', left: ' calc(50% - 20px)' },
    input: { marginBottom: spacing.small },
    inputSpacingLarge: { marginBottom: spacing.large },
    tickboxSection: {
        input: { marginBottom: spacing.small },
        marginBottom: spacing.large,
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    actionGroup: {
        margin: `${spacing.medium} 0`,
        '& button:first-child': {
            marginRight: spacing.xs,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            '& button': {
                width: '50%',
            },
        },
    },
    linkText: Object.assign(Object.assign({}, theme.typography.medium_regular), { border: 'none !important', boxShadow: 'none !important', '& span': Object.assign({ fontWeight: theme.isFlyingFlowers ? '400 !important' : '300 !important', color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold5 }, (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), '& :hover': {
            backgroundColor: 'transparent !important',
            textDecoration: 'underline !important',
        } }),
    modalDialogContainer: {
        position: 'relative',
    },
    label: {},
    clearMessageLink: {
        position: 'absolute',
        backgroundColor: 'transparent !important',
        top: '28px !important',
        right: '80px !important',
        [theme.breakpoints.down('md')]: Object.assign({ top: '44px !important', padding: `0 ${spacing.xs} !important` }, theme.typography.small_bold),
        [theme.breakpoints.up('md')]: {
            top: '30px !important',
            right: '135px !important',
        },
        '& span': {
            [theme.breakpoints.down('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), { padding: '0 !important' }),
        },
    },
    characterCount: Object.assign(Object.assign({ position: 'absolute', marginLeft: spacing.small }, theme.typography.small_bold), { top: '123px', right: '36px', [theme.breakpoints.up('md')]: Object.assign({ top: '125px', right: '54px' }, theme.typography.large_regular) }),
    cardMessageTitle: {
        '& label': Object.assign(Object.assign({ marginTop: '5px' }, theme.typography.small_bold), { paddingLeft: spacing.xxs, [theme.breakpoints.up('md')]: Object.assign({ paddingLeft: spacing.small }, theme.typography.large_bold) }),
        '& textarea': Object.assign(Object.assign({}, theme.typography.small_regular), { height: '200px !important', paddingLeft: `${spacing.xxs} !important`, [theme.breakpoints.up('md')]: Object.assign({ paddingLeft: `${spacing.small} !important` }, theme.typography.medium_regular) }),
    },
    occasionButtonGroup: {
        width: '100%',
        display: 'flex',
        marginTop: spacing.small,
        '& button': {
            width: '100%',
        },
        '& button:first-child': {
            marginRight: spacing.small,
        },
    },
    updateCheckoutForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    occasionSelect: {
        marginTop: spacing.medium,
    },
    cardModalTitle: {
        '& h3': {
            width: '65%',
        },
        [theme.breakpoints.up('md')]: {
            '& h3': {
                width: '100%',
            },
        },
    },
    deliveryPassImageContainer: {
        maxWidth: '480px',
    },
    cardMessage: {
        wordBreak: 'break-word',
        border: 'none !important',
        '&:hover': {
            border: 'none !important',
            cursor: 'auto',
        },
    },
    termsText: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '0 !important', color: `${colors.grey.grey6} !important` }),
    showOptionsTitle: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '16px !important', color: `#4F4F4F !important`, marginTop: '16px !important' }),
    privacyTitle: {},
    preferencesText: {
        marginBottom: '0 !important',
        color: `${colors.grey.grey6} !important`,
        marginLeft: '8px',
    },
    optionPreferences: {
        marginTop: '20px !important',
        paddingBottom: '6px !important',
    },
    whiteBackground: {
        background: `${colors.supplementary.white} !important`,
    },
    addressListItems: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
    },
    errorMessageItem: Object.assign(Object.assign(Object.assign({ display: 'list-item', listStyleType: 'disc', marginLeft: spacing.small }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.none) }),
    errorMessageItemLink: Object.assign({ color: colors.supplementary.black }, link()),
    choosePayment: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: spacing.small, color: colors.supplementary.black }),
    paymentContainer: {
        backgroundColor: colors.supplementary.white,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '1px',
        width: 'auto',
        height: 'auto',
        padding: spacing.small,
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.medium,
        },
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .MuiAccordionDetails-root': {
            display: 'block',
            padding: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'initial',
        },
    },
    activePaymentMethod: {
        border: `1px solid ${colors.supplementary.black}`,
    },
    iconContainer: {
        '& :first-child': {
            marginRight: '21px',
            [theme.breakpoints.up('md')]: {
                marginRight: '38px',
            },
        },
    },
    paypalDescription: {
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.grey.grey6 }),
        paddingBottom: '10px',
        marginTop: '18px',
        [theme.breakpoints.up('sm')]: {
            marginTop: spacing.xs,
            paddingLeft: spacing.small,
            marginLeft: spacing.medium,
        },
    },
    paypalButton: {
        display: 'block',
        width: '100%',
        '@media (orientation: landscape)': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: spacing.medium,
        },
        '& img': {
            width: '100%',
            height: '44px',
            backgroundColor: 'none',
        },
        '& a': {
            display: 'flex',
            backgroundColor: 'none',
            [theme.breakpoints.up('sm')]: {
                width: '300px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: spacing.medium,
        },
    },
    accordianRoot: {
        boxShadow: 'none',
        '& .MuiAccordionSummary-content': {
            margin: `0`,
        },
        '& .Mui-expanded': {
            minHeight: 'auto',
        },
    },
    summaryRoot: {
        '& > *': {
            cursor: 'initial',
            pointerEvents: 'none',
        },
        padding: '0 !important',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            '& > *': {
                cursor: 'pointer',
                pointerEvents: 'auto',
            },
        },
        '& .MuiFormControlLabel-label': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { color: colors.grey.grey6, marginBottom: '0px' }),
    },
    privacyInformation: {
        '& h4': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { marginTop: `16px !important`, marginBottom: `16px !important`, [theme.breakpoints.down('xl')]: {
                marginBottom: `8px !important`,
            } }),
    },
    detailsRoot: {
        padding: 0,
        display: 'block',
    },
    disbaleIframePointerEvents: {
        pointerEvents: 'none',
    },
    enableIframePointerEvents: {
        pointerEvents: 'auto',
    },
    marketingPreferences: {
        '& .MuiCheckbox-root': {
            paddingRight: '12px',
            paddingBottom: spacing.xs,
            paddingTop: 0,
        },
        '& .MuiFormControlLabel-label': {
            paddingTop: 0,
            marginBottom: '6px',
            marginLeft: '8px',
        },
    },
    displayIframe: {
        display: 'block',
    },
    hideIframe: {
        display: 'none',
    },
    klarnaIconContainer: {
        width: '60px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '72px',
            height: '32px',
        },
        '& img': {
            width: '100%',
            height: '24.19px',
            [theme.breakpoints.up('md')]: {
                width: '100%',
                height: '32px',
            },
        },
    },
    showKlarnaPayment: {
        display: 'none',
    },
    paymentHeading: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
    klarnaPayments: {
        border: `1px solid ${colors.grey.grey3}`,
        padding: spacing.small,
        '&:not(:last-child)': {
            borderBottom: 'none',
        },
        '& .MuiFormControlLabel-label': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { color: colors.grey.grey6 }),
        '& .MuiCheckbox-root': {
            padding: 0,
            paddingRight: '12px',
            marginLeft: '0 !important',
        },
    },
    klarnaPaymentContainer: {
        paddingBottom: '12px',
    },
    klarnaPayButton: {
        paddingRight: 0,
        paddingTop: 0,
        [theme.breakpoints.up('md')]: {
            paddingTop: '12px',
        },
    },
    klarnaPayDescription: {
        paddingRight: 0,
        paddingBottom: spacing.xs,
        [theme.breakpoints.up('md')]: {
            paddingRight: '72px',
            paddingBottom: '13px',
        },
    },
    klarnaError: {
        marginRight: 0,
        marginBottom: spacing.large,
        maxHeight: 'initial',
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xxl,
            padding: '11px 8px 9px 8px',
            maxHeight: '44px',
        },
        '& > :last-child': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { padding: '0 !important' }),
    },
    linkWrapper: {
        '& img': {
            height: '44px !important',
        },
    },
    checkPreferences: {},
    checkboxDescription: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? typographyMontserrat.xxs_bold : typographySohne.xxs_bold)), { color: colors.grey.grey6, marginTop: '0px', marginBottom: '0px !important' }),
    expandLink: Object.assign(Object.assign({}, expandLinkStyles(theme)), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.medium }) }),
    collectionInfo: {
        marginBottom: spacing.medium,
        '& p': {
            margin: 0,
        },
    },
    collectionInfoText: {
        marginBottom: 0,
        '& p': {
            margin: 0,
        },
    },
    collectionStepTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : '', overflowWrap: 'anywhere', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg) }),
    collectionContainer: {
        marginBottom: spacing.medium,
    },
    skeletonHeight: {
        height: '140px',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: '116px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '92px',
        },
        [theme.breakpoints.between('md', 1176)]: {
            height: '172px',
        },
    },
}));
export default useStyles;
