import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SiteContext } from '../../utils/common';
import Alert from '../Alert/Alert';
import ReminderCardGallery from '../ReminderCardGallery/ReminderCardGallery';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './RemindersListing.styles';
import AddRemindersModal from '../AddReminderModal/AddReminderModal';
import RemindersAdvancedListing from '../RemindersAdvancedListing/RemindersAdvancedListing';
const RemindersListing = (props) => {
    const { addNewReminder, editReminder, deleteReminder, isAdvancedListing, availableMonths, reminders, setPage, page, totalReminders, isLastPage, monthCount, setMonth, setLoadAll, currentActiveMonth, } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const [addReminderStatusInfo, setReminderStatusInfo] = useState();
    const [isAddReminderOpen, setAddReminderOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    return (_jsxs("div", { className: classes.container, children: [(addReminderStatusInfo === null || addReminderStatusInfo === void 0 ? void 0 : addReminderStatusInfo.reminderStatus) && (_jsx(Alert, { type: addReminderStatusInfo.reminderStatus, message: _jsx("span", { children: addReminderStatusInfo.reminderStatusMessage }), className: classes.alertMessage })), _jsx("h1", { children: "Reminders" }), _jsx("div", { className: classes.remindersContainer, children: _jsx("div", { className: classes.reminderMessage, children: isFlyingFlowers ? (_jsx("p", { children: "Keep track of the reminders you\u2019ve set for those extra special occasions." })) : !!(reminders === null || reminders === void 0 ? void 0 : reminders.length) && !isFlyingFlowers ? (_jsx("p", { children: "Keep track of the reminders you\u2019ve set for special occasions." })) : (_jsxs(_Fragment, { children: [_jsx("p", { children: "You haven't set up any reminders yet, but now's your chance." }), _jsx("p", { children: "They're really quick to set up and a great way to make sure you never miss out on delighting someone on their special day. How about starting by adding a birthday?" })] })) }) }), _jsx("div", { className: classes.buttonContainer, children: _jsx(RoundButton, { ariaLabel: "Add new occasion reminder", icon: "add", label: "Add new occasion reminder", onClick: () => {
                        setAddReminderOpen(true);
                        setHasScrolled && setHasScrolled(false);
                    }, labelAlign: isFlyingFlowers ? 'bottom' : 'side', className: classes.addOccasionButton }) }), _jsx(AddRemindersModal, { isAddReminderOpen: isAddReminderOpen, setAddReminderOpen: setAddReminderOpen, addNewReminder: addNewReminder, setReminderStatusInfo: setReminderStatusInfo, setHasScrolled: setHasScrolled }), _jsxs("div", { children: [_jsxs("h3", { className: classes.title, children: ["Your saved reminders ", !!(reminders === null || reminders === void 0 ? void 0 : reminders.length) ? `(${totalReminders !== null && totalReminders !== void 0 ? totalReminders : reminders.length})` : ''] }), !isAdvancedListing ? (_jsx(ReminderCardGallery, { reminders: reminders, deleteReminder: deleteReminder, editReminder: editReminder, setReminderStatusInfo: setReminderStatusInfo })) : (_jsx(RemindersAdvancedListing, { availableMonths: availableMonths, reminders: Object.assign({ reminders }, { deleteReminder, setReminderStatusInfo, editReminder }), setPage: setPage, page: page, isLastPage: isLastPage, monthCount: monthCount, setMonth: setMonth, setLoadAll: setLoadAll, currentActiveMonth: currentActiveMonth, setHasScrolled: setHasScrolled, hasScrolled: hasScrolled }))] })] }));
};
export default RemindersListing;
