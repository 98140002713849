import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    deliveryMessageText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { paddingTop: 0, paddingBottom: 0 }),
    container: {
        alignItems: 'start',
        whiteSpace: 'pre-wrap',
        paddingLeft: '3px',
        marginBottom: spacing.xs,
        display: 'flex',
        '&:last-child': {
            marginBottom: spacing.large,
        },
    },
    defaultImageContainer: {
        alignItems: 'center',
        '& span:first-of-type': {
            width: '20px !important',
            marginRight: '10px !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative !important',
        },
    },
    icon: {
        marginTop: '0px',
        marginRight: '10px',
        height: '20px',
        width: '20px',
        marginLeft: '3px',
        color: theme.isFlyingFlowers ? colors.background.FF_LAZY_LIME : colors.primary.gold4,
    },
    image: {
        marginTop: spacing.xxs,
        marginRight: '10px',
        height: '20px',
        width: '20px',
    },
}));
export default useStyles;
