import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    description: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    body: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.grey.grey1,
        padding: `0 ${spacing.xs} !important`,
        [theme.breakpoints.up('md')]: {
            padding: `0 ${spacing.small} !important`,
        },
    },
    image: {
        marginRight: spacing.xs,
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.xxs,
        },
    },
    boxContainer: {
        display: 'flex',
        '& > span': {
            height: '160px !important',
            width: '160px !important',
            [theme.breakpoints.up('md')]: {
                width: '224px !important',
                height: '224px !important',
            },
        },
        [`& .${classes.image}, & .${classes.body}`]: {
            width: '160px',
            height: '160px',
            [theme.breakpoints.up('md')]: {
                width: '224px',
                height: '224px',
            },
        },
    },
}));
export default useStyles;
