import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from 'react-loading-skeleton';
import useStyles from './SkeletonProductCard.styles';
import { Grid } from '@mui/material';
const SkeletonProductCard = (props) => {
    var _a, _b;
    const { breakpoints, count = 12 } = props;
    const { extraSmall = 12, small = 6, medium = 4, large = 3 } = breakpoints || {};
    const { classes } = useStyles();
    return (_jsx(Grid, { item: true, className: classes.gridItem, children: _jsx("ul", { className: classes.list, children: (_b = (_a = Array(count)) === null || _a === void 0 ? void 0 : _a.fill(count)) === null || _b === void 0 ? void 0 : _b.map((_, index) => (_jsxs("li", { className: classes.productCard, children: [_jsx("div", { className: classes.topSection, children: _jsx(Skeleton, { height: 344 }) }), _jsxs("div", { className: classes.contentSection, children: [_jsx(Skeleton, { height: 28, width: `100%` }), _jsx("h4", { className: classes.cardTitle, children: _jsx(Skeleton, { height: 68, width: `100%` }) }), _jsx("div", { className: classes.cardMetrics, children: _jsx(Skeleton, { width: `100%`, height: 28 }) }), _jsx("div", { className: classes.cardMetrics, children: _jsx(Skeleton, { width: `100%`, height: 28 }) }), _jsx("p", { className: classes.cardPrice, children: _jsx(Skeleton, { width: `100%`, height: 52 }) })] })] }, index))) }) }, "name"));
};
export default SkeletonProductCard;
