import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
import { addImportant } from '../../utils/common';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: spacing.medium,
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers ? spacing.xl : `${spacing.large} ${spacing.xl}`,
        },
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm) }),
    bodyText: {
        '& h1, & h2, & h3, & h4, & p': Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: `${spacing.small} !important`, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, addImportant(theme.typography.medium_regular)), { marginBottom: theme.isFlyingFlowers ? `${spacing.large} !important` : `${spacing.medium} !important` }) }),
    },
}));
export default useStyles;
