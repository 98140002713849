import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './ChildLocationsGallery.styles';
import SortedLinksGallery from '../SortedLinksGallery/SortedLinksGallery';
import { useLoaded } from '../MainContent/useComponentLoading';
const ChildLocationsGallery = (props) => {
    const { title, links } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h3", { className: classes.title, children: title }), links && _jsx(SortedLinksGallery, { links: links, className: classes.links })] }));
};
export default ChildLocationsGallery;
