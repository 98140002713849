import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import useStyles from './NewsLetter.styles';
import clsx from 'clsx';
import Modal from '../Modal/Modal';
import NewsletterSignUp from '../NewsletterSignUp/NewsletterSignUp';
import RichText from '../RichText/RichText';
import { newsLetterBackgroundClass } from '../../styles/jss/utils';
const NewsLetter = (props) => {
    const { title, body, backgroundColor } = props;
    const { bgClass } = newsLetterBackgroundClass(backgroundColor);
    const { newsletterSignUp, signUp } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const [isOpen, setOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: isOpen, setOpen: setOpen, onClose: () => setOpen(false), title: newsletterSignUp === null || newsletterSignUp === void 0 ? void 0 : newsletterSignUp.title, maxWidth: "md", children: _jsx(NewsletterSignUp, { newsletterSignUp: newsletterSignUp, signUp: signUp, setOpen: setOpen }) }), _jsxs("div", { className: clsx(classes.container, bgClass), children: [title && _jsx("h2", { className: classes.title, children: title }), _jsx(RichText, { content: body, className: classes.bodyText }), _jsx(Button, { variant: isFlyingFlowers ? 'secondary' : 'primary', title: 'Sign me up', onClick: () => setOpen(true) })] })] }));
};
export default NewsLetter;
