import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign({ color: colors.supplementary.black }, theme.typography.large_bold), { marginBottom: '18px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: '28px' }) }),
    expandableContentContainer: {},
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
    expandableContainer: {},
    expandableHeader: {
        width: '100%',
        userSelect: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 20px',
        borderTop: `1px solid ${colors.grey.grey4}`,
        [`.${classes.expandableContainer}:last-child &.${classes.collapsed}`]: {
            borderBottom: `1px solid ${colors.grey.grey4}`,
        },
        [theme.breakpoints.up('md')]: {},
    },
    button: {
        padding: spacing.xs,
        border: `1px solid ${colors.grey.grey7}`,
        borderRadius: '50%',
        '& .material-icons': { color: colors.grey.grey7, fontSize: '14px' },
    },
    expanded: { backgroundColor: colors.grey.grey1 },
    collapsed: {},
    expandableTitle: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: 0, padding: '0 !important' }),
    expandableItems: {},
    expandableItemContent: {
        marginBottom: spacing.large,
        '&:first-child': {
            marginTop: spacing.medium,
            [theme.breakpoints.up('md')]: {
                marginTop: spacing.large,
            },
        },
        // '& button': {
        //   width: '100% !important',
        // },
    },
}));
export default useStyles;
