import { makeStyles } from 'tss-react/mui';
import { formStyles } from '../../styles/jss/form';
const useStyles = makeStyles()((theme) => {
    const styles = formStyles(theme);
    return {
        container: styles.container,
        box: styles.box,
        contactHeading: {
            textAlign: theme.isFlyingFlowers ? 'center' : 'unset',
        },
        error: styles.error,
    };
});
export default useStyles;
