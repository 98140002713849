import { makeStyles } from 'tss-react/mui';
import { colors, fontSizes } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    headingColor: {
        color: (_params === null || _params === void 0 ? void 0 : _params.headingColor) ? `${colors.genericContentHeading[_params === null || _params === void 0 ? void 0 : _params.headingColor]}` : '',
    },
    noBottomMargin: Object.assign({}, theme.typographySpacing.none),
    contentFeatureQuoteContainer: {
        position: 'relative',
    },
    contentFeatureTitle: {
        marginBottom: '4px',
        [theme.breakpoints.up('xl')]: {
            marginBottom: '10px',
        },
    },
    quotationMark: {
        color: 'inherit',
        '& .material-icons': {
            fontSize: fontSizes.medium,
            [theme.breakpoints.up('md')]: {
                fontSize: fontSizes.xxxl,
            },
        },
    },
    openQuote: {
        transform: 'rotate(180deg)',
    },
    closeQuote: {
        width: '100%',
        flexDirection: 'initial',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    contentFeatureQuote: {
        '& .material-icons': {
            fontSize: fontSizes.xl,
            [theme.breakpoints.up('md')]: {
                fontSize: fontSizes.xxl,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.isFlyingFlowers ? '58px' : fontSizes.xxxxl,
            },
        },
    },
}));
export default useStyles;
