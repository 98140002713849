import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditName from '../EditName/EditName';
import EditPhoneNumber from '../EditPhoneNumber/EditPhoneNumber';
import EditBillingAddress from '../EditBillingAddress/EditBillingAddress';
import EditEmailAddress from '../EditEmailAddress/EditEmailAddress';
import PersonalDetailsView from '../PersonalDetailsView/PersonalDetailsView';
const PersonalDetails = (props) => {
    const { firstName, lastName, address, contactNumber, updateName, email, updateContactNumber, updateBillingAddress, displayComponent, setDisplayComponent, updateEmailAddress, success, setSuccessMessage, trackEnterManualAddressClick, } = props;
    const handleDisplayComponent = (component) => {
        setSuccessMessage && component === 'PersonalDetails' && setSuccessMessage('');
        setDisplayComponent(component);
    };
    return (_jsxs("div", { children: [displayComponent === 'PersonalDetails' && (_jsx(PersonalDetailsView, { onClick: handleDisplayComponent, firstName: firstName, lastName: lastName, address: address, contactNumber: contactNumber, email: email, success: success })), displayComponent === 'EditName' && (_jsx(EditName, { firstName: firstName, lastName: lastName, onSubmit: updateName, onCancel: () => handleDisplayComponent('PersonalDetails') })), displayComponent === 'EditPhoneNumber' && (_jsx(EditPhoneNumber, { phoneNumber: contactNumber, onSubmit: updateContactNumber, onCancel: () => handleDisplayComponent('PersonalDetails') })), displayComponent === 'EditBillingAddress' && (_jsx(EditBillingAddress, { addressLine1: address.addressLine1, addressLine2: address.addressLine2, addressLine3: address.addressLine3, city: address.city, county: address.county, postalCode: address.postalCode, country: address.country, onSubmit: updateBillingAddress, onCancel: () => handleDisplayComponent('PersonalDetails'), trackEnterManualAddressClick: trackEnterManualAddressClick })), displayComponent === 'EditEmail' && (_jsx(EditEmailAddress, { email: email, onSubmit: updateEmailAddress, onCancel: () => handleDisplayComponent('PersonalDetails') }))] }));
};
export default PersonalDetails;
