import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './RichText.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const RichText = (props) => {
    const { content, className } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return _jsx(RawHtml, { className: clsx(classes.richText, className), content: content });
};
export default RichText;
