import ContentFeatureUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/ContentFeature/ContentFeature'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { useLoaded } from '@interflora/ui-components'

type Props = UIProps

const ContentFeature = (props: Props) => {
  const analytics = useContext(AnalyticsContext)

  const contentFeatureAnalytics = (linkText?: string) => {
    analytics.contentFeatureLinkTextAnalytics(linkText)
  }

  useLoaded(props)

  return <ContentFeatureUI {...props} contentFeatureAnalytics={contentFeatureAnalytics} />
}

export default ContentFeature
