import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Image from '../../Image/Image';
import { processImageUrl } from '../../../utils/image';
import { useTheme } from '@mui/material';
import { GetBreakpoints } from '../../GenericContent/GenericContent';
import useStyles from './ImageContainer.styles';
import clsx from 'clsx';
const ContentFeatureImage = (props) => {
    var _a, _b;
    const theme = useTheme();
    const { classes } = useStyles();
    const { image, mobileImage, isPriority, classNames, squareImage } = props;
    const { breakpoints } = GetBreakpoints({ squareImage });
    const getAspectRatio = () => {
        if (squareImage) {
            return '1:1';
        }
        return '2:1';
    };
    const getImageUrl = (image) => {
        return processImageUrl(image.url, {
            w: theme.breakpoints.values.xl / 2,
            aspect: getAspectRatio(),
        }, ['$poi-square$']);
    };
    const getImages = (image) => {
        return image.url
            ? breakpoints.map(({ breakpoint, query, w, aspect }) => ({
                breakpoint,
                query,
                filename: processImageUrl(image.url, { w, aspect }, ['$poi-square$']),
            }))
            : undefined;
    };
    return (_jsxs(_Fragment, { children: [(image === null || image === void 0 ? void 0 : image.url) && (_jsx(Image, { className: clsx(classes.image, squareImage && classes.squareImage, classNames === null || classNames === void 0 ? void 0 : classNames.image, classes.desktopImageContainer, !(mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) && classes.displayDesktopImage), imageUrl: (image === null || image === void 0 ? void 0 : image.url) ? getImageUrl(image) : '', altText: (_a = image === null || image === void 0 ? void 0 : image.altText) !== null && _a !== void 0 ? _a : '', images: (image === null || image === void 0 ? void 0 : image.url) ? getImages(image) : undefined, priority: isPriority, loading: "lazy" })), (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) && (_jsx(Image, { className: classes &&
                    clsx(classes === null || classes === void 0 ? void 0 : classes.image, squareImage && (classes === null || classes === void 0 ? void 0 : classes.squareImage), classNames === null || classNames === void 0 ? void 0 : classNames.image, classes === null || classes === void 0 ? void 0 : classes.mobileImageContainer), imageUrl: (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? getImageUrl(mobileImage) : '', altText: (_b = mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.altText) !== null && _b !== void 0 ? _b : '', images: (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? getImages(mobileImage) : undefined, priority: isPriority, loading: "lazy" }))] }));
};
export default ContentFeatureImage;
