import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    section: { marginBottom: spacing.large },
    content: {
        '&, & p, & ul li, & ol li': {
            textAlign: 'left !important',
            [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_regular : theme.typography.medium_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.small_regular_sm : theme.typographySpacing.none)),
        },
        '& p': {
            marginBottom: spacing.small,
            [theme.breakpoints.up('md')]: {
                marginBottom: spacing.xl,
            },
        },
        '& h2': Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: spacing.large }) }),
        '& h3': Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.medium }) }),
        '& h4': Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: spacing.xs, marginTop: '0px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.medium_bold)), { marginBottom: spacing.small }) }),
        '& a': Object.assign(Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
        giftDetails: {
            '& li': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular) }),
        },
    },
    giftDescription: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    noGiftDetail: {
        marginTop: '26px',
        [theme.breakpoints.only('sm')]: {
            marginTop: spacing.large,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 'initial',
        },
    },
    productSkuText: Object.assign(Object.assign({}, theme.typography.small_regular), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, paddingBottom: spacing.large, margin: '0px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { padding: `0 0 ${spacing.xl} 0` }) }),
}));
export default useStyles;
