import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import useStyles from './ReminderCard.styles';
const ReminderDeleteItem = (props) => {
    const { handleRemoveItem, handleCancel, disableButton } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    return (_jsxs(_Fragment, { children: [!isFlyingFlowers && _jsx("h3", { className: classes.deleteTitle, children: "Delete reminder" }), _jsxs("div", { className: classes.deleteContainer, children: [isFlyingFlowers && _jsx("h3", { className: classes.deleteTitle, children: "Delete reminder" }), _jsx("div", { className: classes.deleteSubText, children: "Are you sure you want to delete this reminder?" }), _jsx("div", { className: classes.deleteConfirmationText, children: "Once it's deleted, it's gone for good." }), _jsxs("div", { className: classes.deleteButtonContainer, children: [_jsx(Button, { className: classes.button, variant: isFlyingFlowers ? 'primary' : 'secondary', title: "Yes - delete reminder", fullWidth: true, thinOnMobile: true, onClick: handleRemoveItem, disabled: disableButton }), _jsx(Button, { className: classes.button, variant: "tertiary", title: "No - do not delete", fullWidth: true, thinOnMobile: true, onClick: handleCancel, disabled: disableButton })] })] })] }));
};
export default ReminderDeleteItem;
