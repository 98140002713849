import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './FloristSpecialismContentGallery.styles';
import FloristSpecialismContent from '../FloristSpecialismContent/FloristSpecialismContent';
import { useLoaded } from '../MainContent/useComponentLoading';
const FloristSpecialismContentGallery = (props) => {
    const { title, description, floristSpecialisms } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h2", { className: classes.heading, children: title }), description && _jsx("div", { className: classes.description, children: description }), !!(floristSpecialisms === null || floristSpecialisms === void 0 ? void 0 : floristSpecialisms.length) && (_jsx("div", { className: classes.container, children: floristSpecialisms === null || floristSpecialisms === void 0 ? void 0 : floristSpecialisms.map((floritSpecialism, index) => (_jsx(FloristSpecialismContent, Object.assign({}, floritSpecialism), index))) }))] }));
};
export default FloristSpecialismContentGallery;
