import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import useStyles from './ExpandableContainer.styles';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Collapse } from '@mui/material';
const ExpandableItemContent = ({ title, components, renderComponents, anchorKey }) => {
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState(false);
    const router = useRouter();
    const accordianRef = useRef(null);
    // On change hash or route, expand the container item if hash in url
    useEffect(() => {
        const handleHashChange = (url) => {
            (url === null || url === void 0 ? void 0 : url.includes(`#${anchorKey}`)) && setExpanded(true);
        };
        anchorKey && (router === null || router === void 0 ? void 0 : router.events.on('hashChangeComplete', handleHashChange));
        anchorKey && (router === null || router === void 0 ? void 0 : router.events.on('routeChangeComplete', handleHashChange));
        return () => {
            anchorKey && (router === null || router === void 0 ? void 0 : router.events.off('hashChangeComplete', handleHashChange));
            anchorKey && (router === null || router === void 0 ? void 0 : router.events.on('routeChangeComplete', handleHashChange));
        };
    }, [router === null || router === void 0 ? void 0 : router.events, anchorKey]);
    // On page load, expand if hash in url
    useEffect(() => {
        var _a, _b;
        if (!!anchorKey && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hash) === `#${anchorKey}`) {
            setExpanded(true);
            (_b = accordianRef === null || accordianRef === void 0 ? void 0 : accordianRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
        }
    }, [anchorKey]);
    return (_jsxs("div", { id: anchorKey, className: classes.expandableContainer, ref: accordianRef, children: [_jsx("div", { children: _jsxs("button", { onClick: () => setExpanded((prev) => !prev), className: clsx(classes.expandableHeader, expanded ? classes.expanded : classes.collapsed), children: [_jsx("h3", { className: classes.expandableTitle, children: title }), _jsx(Icon, { icon: expanded ? 'close' : 'expand_more', ariaLabel: expanded ? 'close item' : 'expand item', className: classes.button })] }) }), _jsx(Collapse, { in: expanded, className: classes.expandableItems, children: _jsx("div", { className: classes.expandableItemContent, children: !!(components === null || components === void 0 ? void 0 : components.length) && renderComponents(components) }) }, title)] }));
};
const ExpandableContentContainer = (props) => {
    const { title, expandableItemsList } = props;
    const { renderComponents } = props;
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: classes.expandableContentContainer, children: [title && _jsx("h2", { className: classes.heading, children: title }), !!(expandableItemsList === null || expandableItemsList === void 0 ? void 0 : expandableItemsList.length) &&
                    expandableItemsList.map((expandableItem, index) => {
                        var _a;
                        return !!((_a = expandableItem === null || expandableItem === void 0 ? void 0 : expandableItem.components) === null || _a === void 0 ? void 0 : _a.length) && (_createElement(ExpandableItemContent, Object.assign({ renderComponents: renderComponents }, expandableItem, { key: `${expandableItem.title}_${index}` })));
                    })] }) }));
};
export default ExpandableContentContainer;
