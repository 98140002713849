import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {},
    loadMoreButton: {
        marginTop: spacing.small,
        display: 'flex',
        justifyContent: 'center',
    },
}));
export default useStyles;
