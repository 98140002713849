import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckCircle from '../CheckCircle/CheckCircle';
import Icon from '../Icon/Icon';
import useStyles from './RemindersSummary.styles';
const ReminderSummaryCard = (props) => {
    const { occasion, firstName, lastName, icon, date, remindersList, setRemindersList } = props;
    const { classes } = useStyles();
    const [isChecked, setIsChecked] = useState(true);
    useEffect(() => {
        const occasionIndex = occasion &&
            remindersList.findIndex((reminder) => {
                var _a;
                return ((_a = reminder === null || reminder === void 0 ? void 0 : reminder.occasion) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (occasion === null || occasion === void 0 ? void 0 : occasion.toLowerCase()) &&
                    (reminder === null || reminder === void 0 ? void 0 : reminder.firstName) === firstName &&
                    (reminder === null || reminder === void 0 ? void 0 : reminder.lastName) === lastName &&
                    (reminder === null || reminder === void 0 ? void 0 : reminder.date) === date;
            });
        if (isChecked && occasionIndex === -1) {
            setRemindersList([
                ...remindersList,
                {
                    occasion: occasion !== null && occasion !== void 0 ? occasion : '',
                    firstName: firstName !== null && firstName !== void 0 ? firstName : '',
                    lastName: lastName !== null && lastName !== void 0 ? lastName : '',
                    date: date !== null && date !== void 0 ? date : '',
                },
            ]); // insertings reminders into the list
        }
        else if (!isChecked && occasionIndex !== undefined && occasionIndex !== -1) {
            setRemindersList(remindersList.filter((reminder) => { var _a; return ((_a = reminder === null || reminder === void 0 ? void 0 : reminder.occasion) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== (occasion === null || occasion === void 0 ? void 0 : occasion.toLowerCase()); })); // remove the reminder from the list
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRemindersList, isChecked, occasion, remindersList === null || remindersList === void 0 ? void 0 : remindersList.length]);
    return (_jsxs(Card, { className: classes.card, children: [_jsx("div", { className: classes.reminderCheckbox, children: _jsx(CheckCircle, { name: `reminder_${occasion}`, type: "checkbox", label: "", onChange: () => setIsChecked(!isChecked), checked: isChecked }) }), _jsxs("div", { className: classes.reminderSection, children: [icon && _jsx(Icon, { className: classes.icon, icon: icon, fontSize: "large" }), _jsxs("div", { className: classes.reminderName, children: [_jsx("p", { children: occasion }), _jsxs("p", { children: [firstName, " ", lastName] })] })] })] }, `reminder_${occasion}`));
};
export default ReminderSummaryCard;
