import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, params) => ({
    experimentSkeleton: Object.assign(Object.assign({}, params.css), { [theme.breakpoints.down('xs')]: {
            height: params.xs, // Handle height for xs breakpoint
        }, [theme.breakpoints.between('xs', 'sm')]: {
            height: params.sm,
        }, [theme.breakpoints.between('sm', 'md')]: {
            height: params.md,
        }, [theme.breakpoints.between('md', 'lg')]: {
            height: params.lg,
        }, [theme.breakpoints.between('lg', 'xl')]: {
            height: params.xl,
        }, [theme.breakpoints.up('xl')]: {
            height: params.xl, // Handle height for xl and above
        } }),
}));
export default useStyles;
