import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useState, useContext } from 'react';
import * as Yup from 'yup';
import { errorMessages } from '../../utils/common';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import Link from '../Link/Link';
import PasswordField from '../PasswordField/PasswordField';
import TextField from '../TextField/TextField';
import useStyles from './SignInForm.styles';
import { SiteContext } from '../../utils/common';
export const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(errorMessages().required).email(),
    password: Yup.string().required(errorMessages().required),
});
export const initialValues = {
    email: '',
    password: '',
};
const SignInForm = (props) => {
    const { onSubmit, saveUserJourneyAnalytics } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const handleLoginSubmission = (isSuccessful, email, error) => {
        setErrorMessage(error !== null && error !== void 0 ? error : '');
        saveUserJourneyAnalytics === null || saveUserJourneyAnalytics === void 0 ? void 0 : saveUserJourneyAnalytics({ journeyType: 'LOGIN', isSuccessful, email });
    };
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => onSubmit(values)
            .then(() => {
            handleLoginSubmission(true, values === null || values === void 0 ? void 0 : values.email);
        })
            .catch((error) => {
            handleLoginSubmission(false, undefined, error.message);
        }),
    };
    return (_jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsx(Form, { children: _jsxs("div", { className: clsx(classes.container), children: [errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "email", label: "Email address", placeholder: "e.g. john.smith@domain.co.uk", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(PasswordField, { name: "password", label: "Your password", placeholder: "e.g. YourPas5word", fullWidth: true, required: true }) }), _jsx(Link, { className: classes.forgottenLink, url: "/forgot-your-password", label: "Forgot your password?" }), _jsx("div", { className: classes.buttons, children: _jsx(Button, { variant: "secondaryDark", thin: true, fullWidth: true, title: isFlyingFlowers ? 'Sign into account' : 'Sign in', icon: isFlyingFlowers ? 'input' : undefined, isSubmit: true, disabled: isSubmitting }) })] }) })) })));
};
export default SignInForm;
