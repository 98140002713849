import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { processImageUrl } from '../../../utils/image';
import useStyles from './MobileZoomInImage.styles';
import Image from 'next/image';
const MobileZoomInImage = ({ image, isMediaCarousal, heading }) => {
    const { classes } = useStyles();
    const imageURL = image.url ? processImageUrl(image.url) : '';
    const imageText = isMediaCarousal ? heading : image.label;
    return (_jsx("div", { className: clsx(classes.container, isMediaCarousal && classes.mobileMediaContainer), children: _jsxs("div", { className: clsx(isMediaCarousal && classes.mobileMediaSubContainer), children: [_jsx("div", { className: clsx(isMediaCarousal ? classes.mobileMediaImageContainer : classes.mobileImageContainer), children: _jsx(TransformWrapper, { centerOnInit: true, centerZoomedOut: true, minScale: 1.15, children: _jsx(TransformComponent, { contentClass: classes.transformContentClass, wrapperClass: classes.wrapperClass, children: _jsx(Image, { src: imageURL, alt: image.altText, width: 500, height: 500 }) }) }) }), !!imageText && (_jsx("div", { className: clsx(isMediaCarousal ? classes.imageHeading : classes.productName), children: imageText }))] }) }));
};
export default MobileZoomInImage;
