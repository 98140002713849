import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import LinkGallery from '../LinkGallery/LinkGallery';
import RawHtml from '../RawHtml/RawHtml';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './MyAccountHelp.styles';
export const AMPLIENCE_KEY = 'component/my-account-help';
const MyAccountHelp = (props) => {
    const { title1, title2, body1, body2, linkGallery } = props;
    const { headingTag1 = 'H2', headingTag2 = 'H2' } = props;
    const { customerSupportEmail } = useContext(SiteContext);
    const { classes } = useStyles();
    const HeadingTag1 = headingTag1.toLowerCase();
    const HeadingTag2 = headingTag2.toLowerCase();
    return (_jsxs("div", { "data-testid": "MyAccountHelp-1", "data-type": "MyAccountHelp", className: classes.container, children: [!!title1 && _jsx(HeadingTag1, { className: classes.title, children: title1 }), !!body1 && _jsx(RawHtml, { className: classes.body, content: body1 }), _jsxs("div", { className: classes.content, children: [_jsx(RoundButton, { icon: "email", ariaLabel: "Email", label: "Email", onClick: () => window.open(`mailto:${customerSupportEmail}`), classNames: { container: classes.roundButton, label: classes.roundButtonLabel } }), _jsxs("div", { className: "idz_btn_fix", children: [_jsx(RoundButton, { id: "contactidzfonline", icon: "textsms", ariaLabel: "Live chat", label: "Live chat", style: { display: 'none' }, onClick: () => { }, className: "contactidzfonline", classNames: { container: classes.roundButton, label: classes.roundButtonLabel } }), _jsx(RoundButton, { id: "contactidzfoffline", icon: "textsms", ariaLabel: "Live chat unavailable", label: "Live chat unavailable", style: { display: 'none' }, onClick: () => { }, className: "contactidzfoffline", classNames: { container: classes.roundButton, label: classes.roundButtonLabel }, disabled: true }), _jsx(RoundButton, { id: "contactidzfbusy", icon: "textsms", ariaLabel: "Live chat unavailable", label: "Live chat unavailable", style: { display: 'none' }, onClick: () => { }, className: "contactidzfbusy", classNames: { container: classes.roundButton, label: classes.roundButtonLabel }, disabled: true })] })] }), !!title2 && _jsx(HeadingTag2, { className: classes.title, children: title2 }), !!body2 && _jsx(RawHtml, { className: classes.body, content: body2 }), !!linkGallery && _jsx(LinkGallery, Object.assign({}, linkGallery))] }));
};
export default MyAccountHelp;
