import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    facetSelection: {
        height: '100%',
    },
    facetContainer: {
        height: '100%',
        position: 'relative',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    filterListContainer: {
        marginBottom: spacing.medium,
    },
    globalFilterActions: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginBottom: spacing.small,
        [`& .${classes.clearFilters}`]: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_regular : undefined)),
    },
    facets: {
        marginBottom: spacing.small,
    },
    resultCount: Object.assign({}, theme.typography.small_bold),
    clearFilters: Object.assign({}, theme.typography.small_regular),
    closeButton: {
        position: 'fixed',
        bottom: spacing.medium,
        zIndex: 5,
        width: 'calc(100% - 50px)',
    },
    hidden: {
        display: 'none',
    },
    activeFilterItem: {
        height: 'calc(100vh - 15px)',
    },
    modal: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        '& .MuiDialog-paper': {
            padding: theme.isFlyingFlowers ? spacing.medium : `${spacing.medium} ${spacing.small}`,
        },
    },
}));
export default useStyles;
