import { makeStyles } from 'tss-react/mui';
import { removePaddingWithMargin } from '../../styles/jss/utils';
import { spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, (theme.isFlyingFlowers ? theme.typography.xl_bold : theme.typography.medium_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_bold_sm : undefined)), { padding: '0 !important', marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
    message: {
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { padding: '0 !important', marginBottom: `${spacing.large} !important`, color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_lg), { marginBottom: `${spacing.xl} !important` }) }),
    },
    images: {
        marginBottom: spacing.medium,
        [theme.breakpoints.down('sm')]: Object.assign({}, removePaddingWithMargin),
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
    },
    multipleImages: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        [`& .${classes.imageContainer}`]: {
            paddingTop: '100%',
            [theme.breakpoints.up('sm')]: {
                paddingTop: 'calc(50% - 12px)',
                width: 'calc(50% - 12px)',
            },
            '&:not(:last-child)': {
                marginBottom: spacing.medium,
                [theme.breakpoints.up('sm')]: {
                    marginBottom: '0px',
                },
            },
        },
    },
    imageContainer: {
        paddingTop: '50%',
        position: 'relative',
    },
    image: {
        position: 'absolute',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    imagePositionTop: {
        [`& .${classes.message}`]: {
            '& p': {
                marginBottom: 0,
            },
            order: 2,
        },
        [`& .${classes.images}`]: {
            order: 1,
        },
    },
    imagePositionBottom: {
        [`& .${classes.message}`]: {
            order: 1,
        },
        [`& .${classes.images}`]: {
            order: 2,
            marginBottom: 0,
        },
    },
}));
export default useStyles;
