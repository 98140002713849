import { cardData1, cardData2, idpCardData, spotlightProductCardData1 } from './products.data';
export const menuItems = [
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'Occasions',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: '',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Anniversary Flowers',
                        url: '/category/anniversary-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Apology Flowers',
                        url: '/category/occasion-flowers/sorry',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Baby Flowers',
                        url: '/category/occasion-flowers/new-baby',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Birthday Flowers',
                        url: '/category/birthday-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/occasion-flowers/congratulations',
                        label: 'Congratulations Flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Friendship Flowers',
                        url: '/category/occasion-flowers/friendship',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Get Well Flowers',
                        url: '/category/occasion-flowers/get-well-soon',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Good Luck Flowers',
                        url: '/category/occasion-flowers/good-luck',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'New Home Flowers',
                        url: '/category/occasion-flowers/new-home/',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Retirement Flowers',
                        url: '/category/occasion-flowers/retirement/',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Romantic Flowers',
                        url: '/category/occasion-flowers/romantic',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/occasion-flowers/surprise',
                        label: 'Surprise Flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Thank You Flowers',
                        url: '/category/occasion-flowers/thank-you/',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Thinking of You Flowers',
                        url: '/category/occasion-flowers/thinking-of-you',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: "Valentine's Day Flowers",
                        url: '/category/valentines-flowers',
                    },
                ],
            },
        ],
        products: [idpCardData, cardData1],
        spotlightProducts: [spotlightProductCardData1],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'Funeral & Sympathy',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Funeral Flowers',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'All Funeral Flowers',
                        url: '/category/funeral-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Casket Sprays',
                        url: '/category/funeral-flowers/casket-tributes',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Hearts and Cushions',
                        url: '/category/funeral-flowers/hearts-and-cushions',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Posies and Baskets',
                        url: '/category/funeral-flowers/posies-and-baskets',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Special Tributes',
                        url: '/category/funeral-flowers/tributes',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Sprays and Sheaves',
                        url: '/category/funeral-flowers/sprays-and-sheaves',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Wreaths',
                        url: '/category/funeral-flowers/wreaths',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Funeral Messages',
                        url: '/page/funeral/funeral-flower-messages/',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Sympathy Flowers',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/sympathy-flowers',
                        label: 'Sympathy Flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Sympathy Messages',
                        url: '/page/sympathy-messages',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'International Orders',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/international/australia',
                        label: 'Australia',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'France',
                        url: '/category/international/france',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Ireland',
                        url: '/category/international/ireland',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Spain',
                        url: '/category/international/spain',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'USA',
                        url: '/category/international/usa',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Rest of the World',
                        url: '/category/international',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Here to Help',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Choosing Your Tribute',
                        url: '/page/funeral',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Condolence Etiquette',
                        url: '/page/how-to-express-condolence',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Funeral Contact Us',
                        url: '/page/funeral-contact-us',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'The Meaning of Flowers',
                        url: '/page/flower-meanings',
                    },
                ],
            },
        ],
        spotlightProducts: [spotlightProductCardData1],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'Flowers',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Floral Gifts',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'All Flowers & Bouquets',
                        url: '/category/flower-arrangements/',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Next Day Flowers',
                        url: '/category/next-day-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Same Day Flowers',
                        url: '/category/same-day-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Lily Free Bouquets',
                        url: '/category/flower-arrangements/lily-free-bouquets',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Flowers in a Vase',
                        url: '/category/flower-arrangements/flowers-in-a-vase',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Spring Flowers',
                        url: '/category/flower-arrangements/spring-flowers',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: true,
                    label: 'Interflora for Business',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Flowers for Business',
                        url: '/page/corporate-gifts/',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Subscription Flowers',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Subscription Flowers',
                        url: '/category/flower-arrangements/subscription-flowers',
                    },
                ],
            },
        ],
        spotlightProducts: [spotlightProductCardData1],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'Plants & Gifts',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Plants',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Plant Delivery',
                        url: '/category/plants',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Plants for Business',
                        url: '/page/corporate-gifts',
                    },
                ],
            },
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Gifts',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'All Gifts',
                        url: '/category/gifts',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Hampers',
                        url: '/category/gifts/hampers/',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Gifts for Business',
                        url: '/page/corporate-gifts/',
                    },
                ],
            },
        ],
        products: [cardData1, cardData2],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'International',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: '',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/international/australia',
                        label: 'Australia',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Belgium',
                        url: '/category/international/belgium',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/international/canada',
                        label: 'Canada',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'France',
                        url: '/category/international/france',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Germany',
                        url: '/category/international/germany',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Ireland',
                        url: '/category/international/ireland',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Italy',
                        url: '/category/international/italy',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Netherlands',
                        url: '/category/international/netherlands',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'New Zealand',
                        url: '/category/international/new-zealand',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Norway',
                        url: '/category/international/norway',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Portugal',
                        url: '/category/international/portugal',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'South Africa',
                        url: '/category/international/south-africa',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Spain',
                        url: '/category/international/spain',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Sweden',
                        url: '/category/international/sweden',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Switzerland',
                        url: '/category/international/switzerland',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'USA',
                        url: '/category/international/usa',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'All Countries',
                        url: '/category/international',
                    },
                ],
            },
        ],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: 'Same Day',
            url: '/category/same-day-flowers',
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: true,
                    label: '',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: 'Same Day Flowers',
                        url: '/category/same-day-flowers',
                    },
                ],
            },
        ],
        products: [cardData1, cardData2],
    },
    {
        link: {
            nofollow: false,
            newWindow: false,
            highlight: false,
            label: "Valentine's Day",
        },
        linkGroups: [
            {
                titleLink: {
                    nofollow: false,
                    newWindow: false,
                    highlight: false,
                    label: 'Valentines Flowers',
                },
                links: [
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        label: "Valentine's Day Flowers",
                        url: '/category/valentines-flowers',
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/category/valentines-flowers/valentines-gifts',
                        label: "Valentine's Day Gifts",
                    },
                    {
                        nofollow: false,
                        newWindow: false,
                        highlight: false,
                        url: '/page/valentines',
                        label: "Valentine's Day Inspiration",
                    },
                ],
            },
        ],
        spotlightProducts: [spotlightProductCardData1],
    },
];
export const myAccountMenuItems = [
    {
        icon: 'receipt',
        title: 'Order history',
        description: 'You can check all your purchases and track their status from here.',
        url: '/account/order-history',
    },
    {
        icon: 'book',
        title: 'Address book',
        description: 'You can view and edit your address book from here.',
        url: '/account/address-book',
    },
    {
        icon: 'calendar_today',
        title: 'Reminders',
        description: 'Make sure you never forget a special occasion by setting a reminder.',
        url: '/account/reminders',
    },
    {
        icon: 'assignment_ind',
        title: 'Personal details',
        description: 'You can view and edit your personal details at any time here.',
        url: '/account/personal-details',
    },
    {
        icon: 'vpn_key',
        title: 'Change password',
        description: 'If you wish to change the password to your account you can do so here.',
        url: '/account/change-password',
    },
    {
        icon: 'textsms',
        title: 'Communication preferences',
        description: 'You can change how you would like to hear from us here.',
        url: '/account/communications-preferences',
    },
];
export const paymentTypeLogos = [
    {
        id: 1,
        url: '/images/cards/visa.svg',
        type: 'Visa',
    },
    {
        id: 2,
        url: '/images/cards/amex.svg',
        type: 'AMEX',
    },
    {
        id: 3,
        url: '/images/cards/mastercard.svg',
        type: 'Mastercard',
    },
    {
        id: 4,
        url: '/images/cards/paypal.svg',
        type: 'PayPal',
    },
    {
        id: 5,
        url: '/images/cards/applepay.svg',
        type: 'ApplePay',
    },
    {
        id: 6,
        url: '/images/cards/gpay.svg',
        type: 'GooglePay',
    },
    {
        id: 7,
        url: '/images/cards/klarna.svg',
        type: 'Klarna',
    },
];
export const quickLinks = [
    {
        nofollow: false,
        label: 'All Flowers',
        url: '/occasion',
        icon: 'filter_vintage',
    },
    {
        nofollow: true,
        label: 'Mother’s Day Flowers',
        url: '/anniversary',
        icon: 'face',
    },
    {
        nofollow: true,
        label: 'Anniversary Flowers',
        url: '/anniversary',
        icon: 'cake',
    },
    {
        nofollow: true,
        label: 'Congratulations Flowers',
        url: '/anniversary',
        icon: 'local_bar',
    },
];
