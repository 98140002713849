import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { getAttribute, priceFormatPartial, SiteContext } from '../../utils/common';
import ProductFoldMessage from '../ProductFoldMessage/ProductFoldMessage';
import SecondaryBadge from '../SecondaryBadge/SecondaryBadge';
import useStyles from './ProductDetailSummary.styles';
import KlarnaPlacement from '../KlarnaPlacement/KlarnaPlacement';
import Icon from '../Icon/Icon';
import PDPTimedContentBulletPoints from '../PDPTimedContentBulletPoints/PDPTimedContentBulletPoints';
import { useFeatureFlag, useFeatureFlagExperiment } from '../../utils/useExperiment';
import { flag_bullets_on_funeral_products } from '../../utils/featureFlags';
const ProductDetailSummary = (props) => {
    var _a, _b, _c, _d;
    const { isFlyingFlowers, isKlarnaEnabled, shorthand } = useContext(SiteContext);
    const { classes } = useStyles();
    const { product, variant, saveKlarnaLearnMoreAnalytics, timedContentInformation } = props;
    const funeralBulletsResponseFromLaunchDarkly = useFeatureFlag(flag_bullets_on_funeral_products.key);
    const isFuneralBulletsTestRunning = Object.keys(funeralBulletsResponseFromLaunchDarkly || {}).length > 0;
    const newBulletsFromLaunchDarkly = (funeralBulletsResponseFromLaunchDarkly === null || funeralBulletsResponseFromLaunchDarkly === void 0 ? void 0 : funeralBulletsResponseFromLaunchDarkly.new_bullets) &&
        typeof funeralBulletsResponseFromLaunchDarkly.new_bullets === 'object'
        ? funeralBulletsResponseFromLaunchDarkly.new_bullets
        : {};
    const bulletsVersionFromLaunchDarkly = useFeatureFlagExperiment(flag_bullets_on_funeral_products.key, product.isFuneral);
    const bulletsVersion = isFuneralBulletsTestRunning
        ? bulletsVersionFromLaunchDarkly
        : flag_bullets_on_funeral_products.original;
    const price = priceFormatPartial(variant.price);
    const promotionalText = getAttribute('promotionalText', product.attributes);
    const productTypeIdentifiers = getAttribute('productTypeIdentifiers', product.attributes);
    const wasPrice = shorthand === 'ie' ? getAttribute('wasPriceIE', variant === null || variant === void 0 ? void 0 : variant.attributes) : getAttribute('wasPrice', variant === null || variant === void 0 ? void 0 : variant.attributes);
    const breakdownWasPrice = wasPrice === null || wasPrice === void 0 ? void 0 : wasPrice.split(',');
    const productMessaging = bulletsVersion === flag_bullets_on_funeral_products.variation
        ? Object.values(newBulletsFromLaunchDarkly)
        : (_a = getAttribute('keyProductMessaging', product.attributes)) === null || _a === void 0 ? void 0 : _a.slice(0, 5);
    const klarnaDataClientId = typeof window === 'undefined' ? process.env.KLARNA_DATA_CLIENT_ID : process.env.NEXT_PUBLIC_KLARNA_DATA_CLIENT_ID;
    const firstDeliveryMessage = getAttribute('firstDeliveryMessage', variant.attributes);
    const lastDeliveryMessage = getAttribute('lastDeliveryMessage', variant.attributes);
    return (_jsxs("div", { className: clsx(props.className, classes.summary), children: [((_b = product === null || product === void 0 ? void 0 : product.images[0]) === null || _b === void 0 ? void 0 : _b.secondaryBadge) && (_jsx("div", { className: classes.secondaryBadge, children: _jsx(SecondaryBadge, { secondaryBadge: (_c = product === null || product === void 0 ? void 0 : product.images[0]) === null || _c === void 0 ? void 0 : _c.secondaryBadge }) })), _jsx("h1", { className: classes.productName, children: product.name }), _jsxs("p", { className: clsx(classes.pricing, breakdownWasPrice ? classes.noMargin : ''), children: [_jsxs("span", { className: classes.price, children: [price, " "] }), promotionalText && _jsx("span", { className: classes.promoText, children: promotionalText })] }), wasPrice && isFlyingFlowers ? (_jsx("p", { className: classes.wasPrice, children: wasPrice })) : (breakdownWasPrice && (_jsxs("div", { className: classes.allWasPrice, children: [_jsx("span", { className: classes.strikeThroughPrice, children: breakdownWasPrice[0] }), "\u00A0", _jsx("span", { children: breakdownWasPrice[1] })] }))), klarnaDataClientId && isKlarnaEnabled && ((_d = variant === null || variant === void 0 ? void 0 : variant.price) === null || _d === void 0 ? void 0 : _d.centAmount) ? (_jsx(KlarnaPlacement, { price: variant.price.centAmount, saveKlarnaLearnMoreAnalytics: saveKlarnaLearnMoreAnalytics })) : null, bulletsVersion && (_jsxs(_Fragment, { children: [!!(productMessaging === null || productMessaging === void 0 ? void 0 : productMessaging.length) &&
                        productMessaging.map((productMessage, index) => (_createElement(ProductFoldMessage, Object.assign({}, productMessage, { key: index })))), bulletsVersion !== flag_bullets_on_funeral_products.variation && (_jsxs(_Fragment, { children: [!!(timedContentInformation === null || timedContentInformation === void 0 ? void 0 : timedContentInformation.length) &&
                                timedContentInformation.map((timedContent, index) => {
                                    var _a;
                                    return (_createElement(PDPTimedContentBulletPoints, Object.assign({}, timedContent, { key: `${index}${(_a = timedContent === null || timedContent === void 0 ? void 0 : timedContent.messages) === null || _a === void 0 ? void 0 : _a[0]}` })));
                                }), firstDeliveryMessage && (_jsxs("div", { className: classes.container, children: [_jsx(Icon, { icon: "local_shipping", className: classes.icon }), _jsx("div", { className: classes.deliveryMessageText, children: firstDeliveryMessage })] })), lastDeliveryMessage && (_jsxs("div", { className: classes.container, children: [_jsx(Icon, { icon: "local_shipping", className: classes.icon }), _jsx("div", { className: classes.deliveryMessageText, children: lastDeliveryMessage })] }))] }))] }))] }));
};
export default ProductDetailSummary;
