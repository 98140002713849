import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Link from '../Link/Link';
import useStyles from './GiftUnavailableModal.styles';
import { SiteContext } from '../../utils/common';
const GiftUnavailableModal = ({ open, setOpen, changeDeliveryDate, changeAddress, closeUnavailableModal, noProductAvalible, hideChooseDateOptn, }) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const contactUsLink = isFlyingFlowers ? '/page/contact-us' : '/page/contact';
    const unavailableContent = (_jsxs("div", { className: classes.container, children: [_jsx("p", { children: "Unfortunately, this particular gift is not available for delivery to your chosen address." }), !!noProductAvalible || !!hideChooseDateOptn ? null : (_jsxs(_Fragment, { children: [_jsx("p", { children: "To search for all gifts available to your chosen address, simply tell us the delivery date and we\u2019ll show you all available gifts:" }), _jsx(Button, { title: "Choose delivery date", variant: "primary", thin: true, fullWidth: true, onClick: () => (changeDeliveryDate ? changeDeliveryDate() : {}) }), _jsx("p", { children: "or alternatively" })] })), _jsx(Button, { title: "Try another address", variant: "tertiary", thin: true, fullWidth: true, onClick: () => changeAddress() }), _jsxs("p", { children: ["If you continue to experience problems placing your order, please", ' ', _jsx(Link, { label: "contact us", url: contactUsLink }), "."] })] }));
    return (_jsx(Modal, { title: "Gift not available", open: open, setOpen: setOpen, onClose: closeUnavailableModal, children: unavailableContent }));
};
export default GiftUnavailableModal;
