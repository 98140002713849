import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    cards: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            flexWrap: 'wrap',
        },
        '& li': {
            display: 'inline-flex',
            width: '46.67px',
            height: '32px',
            [theme.breakpoints.up('md')]: {
                width: '58.33px',
                height: '40px',
            },
        },
        '& li img': {
            lineHeight: 0,
            borderRadius: '6px',
            border: `1px solid ${colors.grey.grey3}`,
            width: '100%',
            height: '32px',
            [theme.breakpoints.up('md')]: {
                height: '40px',
            },
        },
    },
    checkoutFooterCard: {
        marginBottom: spacing.large,
        gap: '12px',
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            gap: '16px',
        },
    },
    footerCard: {
        gap: '16px',
        [theme.breakpoints.up('md')]: {
            marginTop: spacing.xl,
            marginBottom: 0,
        },
    },
    iconFooter: {
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            margin: '0 auto',
        },
    },
    iconCheckout: {
        [theme.breakpoints.down('xs')]: {
            width: '70%',
            margin: '0 auto',
        },
    },
}));
export default useStyles;
