import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FormikContext } from 'formik';
import Button from '../Button/Button';
import CheckCircle from '../CheckCircle/CheckCircle';
import TextField from '../TextField/TextField';
import { errorMessages, SiteContext, inputValidationRegex } from '../../utils/common';
import useStyles from '../ProductDetailPurchase/ProductDetailPurchase.styles';
export const validationSchema = Yup.object().shape({
    updatePhone: Yup.string()
        .trim()
        .when('isLoggedInWithoutPhone', {
        is: (isLoggedInWithoutPhone) => !!isLoggedInWithoutPhone,
        then: Yup.string()
            .required(errorMessages().required)
            .matches(/^[+]?[0-9 ]*$/, errorMessages().phone)
            .min(4, errorMessages(4).minChars),
    }),
    updateAddressLine1: Yup.string().when('isAddressLine1NotAvailable', {
        is: (isAddressLine1NotAvailable) => !isAddressLine1NotAvailable,
        then: Yup.string()
            .required('Please enter address line 1')
            .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    }),
});
export const initialValues = {
    updatePhone: '',
    updateAddressLine1: '',
    isLoggedInWithoutPhone: false,
    isAddressLine1NotAvailable: false,
};
const MissingContactInfoFields = (props) => {
    var _a;
    const { classes } = useStyles();
    const { isInternational, isMissingPhoneNumber, isMissingAddressLine1 } = props;
    const { getCustomer, isFlyingFlowers } = useContext(SiteContext);
    const formikContext = useContext(FormikContext);
    const customer = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer;
    const [addressLine1NotAvailableChecked, setAddressLine1NotAvailableChecked] = useState(false);
    // The param isLoggedInWithoutPhone in the validation schema should be a fomikContext value. So setting it.
    useEffect(() => {
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isLoggedInWithoutPhone', !!(customer === null || customer === void 0 ? void 0 : customer.id) && !!isMissingPhoneNumber);
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isAddressLine1NotAvailable', !!(customer === null || customer === void 0 ? void 0 : customer.id) && !isMissingAddressLine1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);
    useEffect(() => {
        const value = addressLine1NotAvailableChecked ? '-' : '';
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('updateAddressLine1', value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLine1NotAvailableChecked]);
    const toggleAddressLine1NotAvailable = () => setAddressLine1NotAvailableChecked(!addressLine1NotAvailableChecked);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: clsx(classes.input), children: [isMissingAddressLine1 && (_jsxs(_Fragment, { children: [_jsx("div", { className: addressLine1NotAvailableChecked ? classes.disabledAddressLine : '', children: _jsx(TextField, { name: "updateAddressLine1", label: 'Address Line 1', placeholder: "", required: true, fullWidth: true, trimOnBlur: true, hideRequiredOptional: true, inputProps: { maxLength: 80 }, disabled: addressLine1NotAvailableChecked }) }), _jsx(CheckCircle, { name: "isAddressLine1NotAvailable", type: "checkbox", label: "This information is not available", controlAlignment: "top", className: classes.addressLineCheckBox, onClick: toggleAddressLine1NotAvailable })] })), isMissingPhoneNumber && (_jsxs(_Fragment, { children: [_jsx(TextField, { name: "updatePhone", label: 'Contact’s phone number', placeholder: "", required: true, fullWidth: true, trimOnBlur: true, hideRequiredOptional: true, inputProps: { maxLength: 50 } }), _jsx("p", { className: clsx(classes.caption, (customer === null || customer === void 0 ? void 0 : customer.id) && classes.phoneCaption), children: (customer === null || customer === void 0 ? void 0 : customer.id)
                                    ? isInternational
                                        ? 'Our international florists require a valid local telephone number so that they can successfully deliver your order'
                                        : 'We will only use this to contact them when a delivery is attempted.'
                                    : isInternational
                                        ? 'Our international florists require a valid local telephone number so that they can successfully deliver your order'
                                        : 'We will only use this phone number if there is an issue with your delivery' })] }))] }), _jsx(Button, { variant: isFlyingFlowers ? 'secondaryDark' : 'primary', fullWidth: true, title: isMissingPhoneNumber ? 'Save contact details' : 'Save', isSubmit: true })] }));
};
export default MissingContactInfoFields;
