import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_CARD_HEIGHT = 332;
const MAX_CARD_WIDTH = '520px';
const CARD_MARGIN = '12px';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.xl_bold)), { marginBottom: '35px', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_lg : undefined)), { textAlign: 'center' }) }),
    button: {
        textAlign: 'center',
        marginTop: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginTop: spacing.xl,
        },
    },
    slider: {
        display: 'flex',
        flexDirection: 'row',
    },
    carousel: {
        margin: '0 auto',
        padding: '3px',
        marginBottom: spacing.large,
    },
    carouselItem: {
        margin: 0,
        display: 'flex',
        '& > div': {
            margin: `0 ${CARD_MARGIN}`,
        },
    },
    'custom-dot-list-style': {
        position: 'relative',
        top: '35px',
        '& li:last-child button': {
            marginRight: '0',
        },
    },
    carouselContainer: {
        margin: '0 auto',
        width: '100%',
        maxWidth: `calc(${MAX_CARD_WIDTH} + ${CARD_MARGIN})`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 2)`,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 3)`,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 3)`,
        },
    },
    carouselDots: {
        '& .react-multi-carousel-dot button': {
            width: '12px',
            height: '12px',
            marginRight: spacing.xs,
            borderWidth: '1px',
        },
    },
    gridGalleryContainer: {
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(1,1fr)',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3,1fr)',
        },
        '& .MuiPaper-rounded': {
            borderRadius: '0px !important',
            boxShadow: theme.isFlyingFlowers
                ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
                : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        },
    },
    blogArticleCardContainer: {
        border: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey3}`,
        borderRadius: '0px !important',
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
    },
}));
export default useStyles;
