import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { color: colors.supplementary.white, marginTop: '20px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({ marginTop: 'initial' }, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), [theme.breakpoints.up('lg')]: Object.assign({ marginTop: 'initial' }, theme.typography.xxl), [theme.breakpoints.up('xl')]: Object.assign({ marginTop: 'initial' }, theme.typography.xxxl) }),
    subheading: { color: colors.supplementary.white },
    message: Object.assign(Object.assign(Object.assign({ color: colors.supplementary.white }, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: spacing.xxl, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { marginBottom: spacing.xl }) }),
    linkContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        maxWidth: '700px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row !important',
            width: '700px !important',
            margin: '0 auto',
            maxWidth: 'initial',
            justifyContent: 'center',
            [`& .${classes.link}`]: {
                width: '50%',
                margin: 0,
                '& button': { width: '100%' },
                '&:not(:last-child)': {
                    marginBottom: '0',
                    marginRight: spacing.medium,
                },
            },
        },
    },
    outsideLinkContainer: {
        marginTop: spacing.large,
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    container: {
        [theme.breakpoints.down('xl')]: {
            minHeight: (params === null || params === void 0 ? void 0 : params.height) || '384px',
            height: (params === null || params === void 0 ? void 0 : params.height) || '384px',
            '@media(orientation: portrait)': {
                placeContent: 'unset',
            },
        },
    },
    contentInner: {
        [theme.breakpoints.down('xl')]: {
            '@media(orientation:landscape)': {
                width: '70%',
                margin: `${spacing.large} auto`,
            },
            marginBottom: spacing.small,
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
            margin: `${spacing.xxl} auto`,
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
            margin: `${spacing.xxl} auto`,
        },
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
    },
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginLeft: '20px',
        marginRight: '20px',
        padding: '10px',
        justifyContent: 'center',
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    carousel: {
        margin: '0 auto',
        padding: `3px 3px 0 3px`,
        '& .react-multi-carousel-list': {
            marginBottom: spacing.small,
            [theme.breakpoints.up(1920)]: {
                marginBottom: spacing.medium,
            },
        },
        '& .react-multi-carousel-track': {},
        '& .react-multi-carousel-dot button': {
            width: '12px',
            height: '12px',
            marginRight: '12px',
            borderWidth: '1px',
            backgroundColor: colors.grey.grey3,
        },
        '& .react-multi-carousel-dot--active button': {
            backgroundColor: colors.grey.grey6,
        },
    },
    carouselItem: {
        margin: 0,
    },
    'custom-dot-list-style': {
        position: 'relative',
        top: '35px',
        '& li:last-child button': {
            marginRight: '0',
        },
    },
    carouselContainer: {
        width: '100%',
    },
    leftButton: {
        [theme.breakpoints.down('xl')]: {
            left: spacing.small,
        },
        [theme.breakpoints.up('sm')]: {
            left: spacing.small,
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            '@media(orientation: portrait)': {
                left: spacing.small,
            },
        },
    },
    rightButton: {
        right: spacing.xxs,
        [theme.breakpoints.up('lg')]: {
            right: '12px !important',
        },
    },
    customButtonGroup: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        top: '41%',
        '@media (orientation: landscape)': {
            top: '46%',
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            top: '47%',
            display: 'flex',
            '@media (orientation: landscape)': {
                top: '45% !important',
                display: 'flex',
            },
        },
        [theme.breakpoints.up('md')]: {
            top: '47%',
            display: 'flex',
        },
        [`& .${classes.leftButton}`]: {
            left: spacing.xxs,
            [theme.breakpoints.up('lg')]: {
                left: '12px',
            },
            position: 'absolute',
            '& button': {
                width: '40px',
                height: '40px',
                // '& .MuiFab-label': {
                //   width: '40px',
                //   height: '40px',
                // },
            },
        },
        [`& .${classes.rightButton}`]: {
            right: spacing.xxs,
            [theme.breakpoints.up('lg')]: {
                right: '12px !important',
            },
            position: 'absolute',
            '& button': {
                width: '40px',
                height: '40px',
                // '& .MuiFab-label': {
                //   width: '40px',
                //   height: '40px',
                // },
            },
        },
        // '& .MuiIcon-root': {
        //   height: '40px !important',
        //   width: '40px !important',
        // },
    },
    truspilotContainer: {
        marginBottom: spacing.medium,
        [theme.breakpoints.up('sm')]: {
            marginBottom: spacing.small,
        },
    },
    carouselDotList: {
        pointerEvents: 'none',
    },
    slider: {},
}));
export default useStyles;
