var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import NextLink from 'next/link';
import formatDate from 'date-fns/format';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import clsx from 'clsx';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import useStyles from './ReminderCard.styles';
import { differenceInDays } from 'date-fns';
import ReminderDeleteItem from './ReminderDeleteItem';
import Modal from '../Modal/Modal';
import { useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import EditRemindersModal from '../EditReminderModal/EditReminderModal';
const ReminderCard = (props) => {
    var _a, _b, _c;
    const { reminder, showActions = true, setReminderStatusInfo, deleteReminder, editReminder, setHasScrolled } = props;
    const { date, occasion: occasionName, firstName, lastName, id } = reminder;
    const { classes } = useStyles();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditReminderOpen, setEditReminderOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { isFlyingFlowers, occasions } = useContext(SiteContext);
    const theme = useTheme();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const siteOccasion = occasions === null || occasions === void 0 ? void 0 : occasions.find((occasion) => occasion.label.toLowerCase() === (occasionName === null || occasionName === void 0 ? void 0 : occasionName.toLowerCase()));
    const occasionURL = (_a = siteOccasion === null || siteOccasion === void 0 ? void 0 : siteOccasion.occasionReminders) === null || _a === void 0 ? void 0 : _a.occasionCategoryUrl;
    const occasionIcon = (_b = siteOccasion === null || siteOccasion === void 0 ? void 0 : siteOccasion.occasionReminders) === null || _b === void 0 ? void 0 : _b.occasionIcon;
    const categoryLinkTitle = (_c = siteOccasion === null || siteOccasion === void 0 ? void 0 : siteOccasion.occasionReminders) === null || _c === void 0 ? void 0 : _c.categoryLinkTitle;
    const dateDifferenceInWeeks = date ? differenceInWeeks(new Date(date), new Date()) : 0;
    const isPastdate = !!date && differenceInDays(new Date(date), new Date()) < 0;
    const isCurrentMonth = date ? dateDifferenceInWeeks < 4 && dateDifferenceInWeeks >= 0 && !isPastdate : false; // differenceInWeeks range start from zero Ex: current week returns zero
    const name = `${firstName} ${lastName}`;
    const handleRemoveItem = () => __awaiter(void 0, void 0, void 0, function* () {
        setReminderStatusInfo && setReminderStatusInfo({});
        setIsButtonDisabled(true);
        deleteReminder &&
            id &&
            deleteReminder(id)
                .then(() => {
                setReminderStatusInfo &&
                    setReminderStatusInfo({
                        reminderStatus: 'alertSuccess',
                        reminderStatusMessage: 'Your reminder has been successfully deleted.',
                    });
            })
                .catch((error) => {
                setReminderStatusInfo &&
                    setReminderStatusInfo({
                        reminderStatus: 'alertError',
                        reminderStatusMessage: error.message,
                    });
            })
                .finally(() => {
                setIsDeleteModalOpen(false);
                setIsButtonDisabled(false);
                window.scrollTo(0, 0);
                setHasScrolled && setHasScrolled(false);
            });
    });
    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
        setHasScrolled && setHasScrolled(false);
    };
    const Wrapper = (props) => isCurrentMonth && occasionURL && !showActions ? (_jsx(NextLink, { href: occasionURL, passHref: true, legacyBehavior: true, children: _jsx("a", { className: classes.linkWrapper, children: props.children }) })) : (_jsx(React.Fragment, { children: props.children }));
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: isDeleteModalOpen, setOpen: setIsDeleteModalOpen, onClose: () => setHasScrolled && setHasScrolled(false), keepMounted: false, disablePortal: false, fullScreen: isMobile, fullWidth: true, maxWidth: "sm", children: _jsx(ReminderDeleteItem, { handleRemoveItem: handleRemoveItem, handleCancel: handleDeleteModalClose, disableButton: isButtonDisabled }) }), _jsx(EditRemindersModal, { reminder: reminder, isEditReminderOpen: isEditReminderOpen, editReminder: editReminder, setEditReminderOpen: setEditReminderOpen, setReminderStatusInfo: setReminderStatusInfo, setHasScrolled: setHasScrolled }), _jsx(Wrapper, { children: _jsx("div", { className: clsx(classes.container, isCurrentMonth && !showActions ? classes.currentMonth : ''), children: _jsxs("div", { className: classes.cardContainer, children: [_jsx("div", { className: classes.iconContainer, children: !!occasionIcon && _jsx(Icon, { icon: occasionIcon, fontSize: "large", className: classes.icon }) }), _jsxs("div", { className: classes.cardBodyContainer, children: [occasionName && _jsx("h3", { className: classes.occasionTitle, children: occasionName }), _jsxs("div", { className: clsx(classes.contactContainer, !showActions
                                            ? isCurrentMonth
                                                ? classes.currentMonthContactContainer
                                                : classes.noActionsContactContainer
                                            : ''), children: [!!name && _jsx("p", { className: classes.contactName, children: name }), !!date && (_jsx("p", { className: clsx(isCurrentMonth && !showActions ? classes.currentOccasionDate : classes.contactName), children: formatDate(new Date(date), 'dd/MM/yyyy') }))] }), showActions && (_jsxs("div", { className: classes.actionContainer, children: [_jsx(Link, { className: classes.deleteAction,
                                                label: 'Delete',
                                                onClick: () => {
                                                    setHasScrolled && setHasScrolled(false);
                                                    setReminderStatusInfo && setReminderStatusInfo({});
                                                    setIsDeleteModalOpen(true);
                                                },
                                                highlight: false,
                                                noFollow: false,
                                                newWindow: true }), _jsx(Button, { title: "Edit", variant: "tertiaryDark", thin: true, icon: isFlyingFlowers ? 'mode_edit' : undefined, className: classes.editAction, onClick: () => {
                                                    setEditReminderOpen(true);
                                                    setHasScrolled && setHasScrolled(false);
                                                } })] })), !showActions && isCurrentMonth && occasionURL && (_jsx(Link, { url: occasionURL, className: classes.linkTitle, label: categoryLinkTitle !== null && categoryLinkTitle !== void 0 ? categoryLinkTitle : '' }))] })] }) }) })] }));
};
export default ReminderCard;
