import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from '../Image/Image';
import useStyles from './ProductFoldMessage.styles';
import clsx from 'clsx';
const ProductFoldMessage = (props) => {
    var _a;
    const { message, image, classNames } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.container, children: [image && (_jsx(Image, { imageUrl: image.url, altText: (_a = image === null || image === void 0 ? void 0 : image.altText) !== null && _a !== void 0 ? _a : '', className: classes.image, height: 20, width: 20 })), message && _jsx("div", { className: clsx(classes.text, classNames === null || classNames === void 0 ? void 0 : classNames.text), children: message })] }));
};
export default ProductFoldMessage;
