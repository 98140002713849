import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme, Dialog as ImportedDialog, DialogContent } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './Modal.styles';
import _debounce from 'lodash/debounce';
const SCROLL_DEBOUNCE_DELAY_MS = 100;
const Modal = ({ open, children, title, setOpen, keepMounted, onClose, onEnter, fullWidth, fullScreen, maxWidth, disablePortal, noPadding, container, className, isFromImageGallery, isStickyHeader, customStickyBtnClass, isCalendarOpen, isFromPccOccasion, isMobileDatePicker, isFromCategory, isMediaGallery, }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery({ query: `(max-width: ${theme.breakpoints.values.md}px)` });
    const currentScrollPosition = useRef();
    const titleRef = useRef(null);
    const [isSticky, setSticky] = useState(false);
    useEffect(() => {
        if (open && isTablet && !isStickyHeader) {
            currentScrollPosition.current = window.scrollY;
            window.document.body.classList.add('modalOpen');
        }
        return () => {
            if (isTablet && window.document.body.classList.contains('modalOpen')) {
                window.document.body.classList.remove('modalOpen');
                window.scrollTo({ top: currentScrollPosition.current });
            }
        };
    }, [open, isTablet, isStickyHeader]);
    const handleClose = () => {
        setOpen(false);
        !!onClose && onClose();
        if (isTablet) {
            window.document.body.classList.remove('modalOpen');
            window.scrollTo({ top: currentScrollPosition.current });
        }
    };
    useEffect(() => {
        var _a;
        if (isFromPccOccasion && titleRef) {
            (_a = titleRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [isFromPccOccasion]);
    useEffect(() => {
        if (isStickyHeader && open) {
            setTimeout(() => {
                var _a;
                const sticky = 20;
                const handleScroll = _debounce(() => {
                    var _a;
                    const header = ((_a = document.querySelector('#bodyContainer')) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0;
                    setSticky(header > sticky);
                }, SCROLL_DEBOUNCE_DELAY_MS);
                (_a = document.querySelector('#bodyContainer')) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', handleScroll);
                return () => {
                    var _a;
                    (_a = document.querySelector('#bodyContainer')) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', handleScroll);
                };
            }, 10);
        }
    }, [isStickyHeader, open]);
    const renderTitle = () => {
        if (isStickyHeader) {
            return title;
        }
        if (title) {
            return isFromCategory ? _jsx("div", { className: classes.headerTitle, children: title }) : _jsx("h3", { children: title });
        }
        return null;
    };
    return (_jsxs(ImportedDialog, { className: clsx(className !== null && className !== void 0 ? className : '', noPadding && classes.noPadding), open: open, disablePortal: disablePortal, keepMounted: keepMounted, onClose: handleClose, onTransitionEnter: onEnter, fullWidth: fullWidth, fullScreen: fullScreen, maxWidth: maxWidth, disableAutoFocus: true, scroll: "paper", PaperProps: {
            className: clsx(classes.modal, isStickyHeader ? classes.stickyHeaderContainer : '', isFromPccOccasion ? classes.pccModalContainer : ''),
        }, container: container, children: [_jsxs("header", { ref: titleRef, className: clsx(title ? classes.header : classes.noTitleHeader, isFromImageGallery ? classes.imageGalleryHeader : '', isStickyHeader ? classes.stickyHeader : '', !!isMobileDatePicker ? classes.pccDatepicker : '', isSticky ? classes.stickyHeaderBorder : '', isMediaGallery ? classes.mediaGalleryHeader : ''), children: [renderTitle(), _jsx(RoundButton, { icon: "close", ariaLabel: "Close", onClick: handleClose, className: clsx(isStickyHeader ? classes.stickyButtonContainer : '', customStickyBtnClass ? classes[customStickyBtnClass] : '') })] }), _jsx(DialogContent, { className: clsx(classes.contentContainer, isStickyHeader && !isCalendarOpen ? classes.stickyContentContainer : ''), id: "bodyContainer", children: children })] }));
};
export default Modal;
