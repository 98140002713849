import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
// import { SpotlightBannerBackgroundColor } from '../../styles/jss/utils'
export const MAX_CARD_WIDTH = 260;
export const cardWidths = {
    CARD: { xs: 304, sm: 304, md: 464, xl: 552 },
};
export const doubleCardImageWidths = [304, 464, 552];
export const singleCardImageWidths = [140, 220, 260];
const useStyles = makeStyles()((theme, params) => ({
    productCard: {
        maxWidth: '140px',
        width: '140px',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& a': {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
            padding: 0,
            width: '220px',
            maxWidth: `${MAX_CARD_WIDTH}px`,
        },
        [theme.breakpoints.up('xl')]: {
            width: `${MAX_CARD_WIDTH}px`,
            maxWidth: `${MAX_CARD_WIDTH}px`,
        },
    },
    doubleColumnCard: {
        width: '100%',
        maxWidth: `${cardWidths.CARD.xs}px`,
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& a': {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
            maxWidth: `${cardWidths.CARD.md}px`,
            marginBottom: 0,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: `${cardWidths.CARD.xl}px`,
        },
    },
    imageContainer: {
        '& > div > div': {
            height: '140px',
            maxHeight: '140px',
            [theme.breakpoints.up('md')]: {
                height: '220px',
                maxHeight: '220px',
            },
            [theme.breakpoints.up('xl')]: {
                height: '260px',
                maxHeight: '260px',
            },
            '& img': {
                height: '100%',
            },
            '&::after': {
                padding: 'initial',
            },
        },
    },
    promoText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { padding: '0 !important', wordWrap: 'break-word' }),
    topSectionContainer: {
        padding: `15px 12px 12px 12px`,
    },
    removeMarginForPromoText: {
        marginBottom: 0,
    },
    simpleGalleryLink: Object.assign(Object.assign(Object.assign({ '& a': {
            '& span': {
            // color: (props: { backgroundColor?: keyof typeof colors.spotlightBannerBackround }) =>
            //   props?.backgroundColor?.includes('FF_GREY') && theme.isFlyingFlowers ? colors.wildExotic[500] : 'inherit',
            },
        } }, theme.typography.small_bold), theme.typographySpacing.small_bold_sm), { padding: '0 !important', overflowWrap: 'anywhere', wordBreak: 'break-word', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_sm), '&:not(:last-child)': {
            marginBottom: spacing.small,
        } }),
    singleCardSimpleGallaryLink: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_sm),
    singleTopSectionContainer: {
        padding: theme.isFlyingFlowers ? '12px 12px 12px 12px' : `${spacing.xs} ${spacing.xs} ${spacing.xs} ${spacing.xs}`,
        [theme.breakpoints.up('md')]: {
            padding: '12px 12px 12px 12px',
        },
    },
}));
export default useStyles;
