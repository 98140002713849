import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import RoundButton from '../../RoundButton/RoundButton';
import useStyles from './CarouselDots.styles';
import { SiteContext } from '../../../utils/common';
import clsx from 'clsx';
const CarouselDots = ({ index = 0, onClick = () => { }, active, itemsLength, nextSlide, prevSlide, className, disableDotsClick, }) => {
    const firstIndex = index === 0 ? true : false;
    const lastIndex = index === itemsLength ? true : false;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: _jsxs("ul", { className: clsx(classes.contentCenter, className !== null && className !== void 0 ? className : ''), children: [firstIndex && (_jsx("li", { className: classes.prevArrow, children: _jsx(RoundButton, { ariaLabel: isFlyingFlowers ? 'arrow back' : 'chevron left arrow', icon: isFlyingFlowers ? 'arrow_back' : 'chevron_left', onClick: prevSlide }) })), _jsx("li", { "data-index": index, className: clsx(`react-multi-carousel-dot ${active ? 'react-multi-carousel-dot--active' : ''}`, disableDotsClick ? classes.dotList : ''), children: _jsx("button", { "aria-label": `Go to slide ${index + 1}`, onClick: onClick }) }, index), lastIndex && (_jsx("li", { children: _jsx(RoundButton, { ariaLabel: isFlyingFlowers ? 'arrow_forward' : 'chevron right arrow', icon: isFlyingFlowers ? 'arrow_forward' : 'chevron_right', onClick: nextSlide }) }))] }) }));
};
export default CarouselDots;
