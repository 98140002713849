import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import connectAutocomplete from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import _debounce from 'lodash/debounce';
import { default as _uniqBy } from 'lodash/uniqBy';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, useConnector } from 'react-instantsearch';
import { getRecentSearches, saveRecentSearches } from '../../utils/algoliaRecentSearches';
import { SiteContext } from '../../utils/common';
import SearchAutoComplete from '../SearchAutoComplete/SearchAutoComplete';
import { useSearchNBHitsContext, useSearchUpdate } from '../SearchContext/SearchContext';
import InitialSearchView from './InitialSearch/InitialSearchView';
import useStyles from './SearchOverlay.styles';
import SearchResults from './SearchResults/SearchResults';
function useAutocomplete(props) {
    return useConnector(connectAutocomplete, props);
}
const SearchOverlay = ({ showSearch, suggestedSearches, handleSuggestedSearchOrPreviousSearch, searchDefaultProducts, searchTermAnalytics, noSearchOverlayResultAnalytics, }) => {
    var _a;
    const { indices: hits, refine } = useAutocomplete();
    const { classes } = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [inspirations, setInspirations] = useState([]);
    const [recentSearches, setRecentSearches] = useState([]);
    const [totalInspirations, setTotalInspirations] = useState(0);
    const [totalCategories, setTotalCategories] = useState(0);
    const setQuery = useSearchUpdate();
    const { nbHits } = useSearchNBHitsContext();
    const previousValue = useRef('');
    const { getCustomer } = (_a = useContext(SiteContext)) !== null && _a !== void 0 ? _a : {};
    const { keywords, categories: suggestedCategories } = suggestedSearches !== null && suggestedSearches !== void 0 ? suggestedSearches : {};
    const showInitialView = ((recentSearches === null || recentSearches === void 0 ? void 0 : recentSearches.length) || (keywords === null || keywords === void 0 ? void 0 : keywords.length) || (suggestedCategories === null || suggestedCategories === void 0 ? void 0 : suggestedCategories.length) || (searchDefaultProducts === null || searchDefaultProducts === void 0 ? void 0 : searchDefaultProducts.length)) &&
        !(inputValue === null || inputValue === void 0 ? void 0 : inputValue.length);
    const showSearchResults = !!hits.length && inputValue.length > 2;
    const noResultsFound = inputValue.length > 0 && !products.length && !categories.length && !inspirations.length;
    const submitSearch = (e) => {
        e.preventDefault();
        if (inputValue.length > 0) {
            if (products.length || categories.length || inspirations.length)
                saveRecentSearches(inputValue);
            setQuery(inputValue);
            searchTermAnalytics === null || searchTermAnalytics === void 0 ? void 0 : searchTermAnalytics(inputValue);
            triggerHotJarEvents(inputValue);
        }
    };
    const triggerHotJarEvents = (searchTerm) => {
        var _a, _b;
        if (window === null || window === void 0 ? void 0 : window.hj) {
            // added search term attribute in hotjar
            window === null || window === void 0 ? void 0 : window.hj('event', 'searchTerm');
            window === null || window === void 0 ? void 0 : window.hj('identify', (_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id, {
                searchTerm,
            });
        }
    };
    useEffect(() => {
        const message = `Sorry, but there are no results for your search ${inputValue}`;
        if (noResultsFound && noSearchOverlayResultAnalytics) {
            noSearchOverlayResultAnalytics === null || noSearchOverlayResultAnalytics === void 0 ? void 0 : noSearchOverlayResultAnalytics(message);
        }
    }, [inputValue, noResultsFound, noSearchOverlayResultAnalytics]);
    useEffect(() => {
        if (previousValue.current !== inputValue) {
            previousValue.current = inputValue;
            refine(inputValue);
        }
    }, [inputValue, refine]);
    const debouncedSaveValue = useMemo(() => _debounce((query) => {
        if (inputValue.length > 2 && (products.length || categories.length))
            saveRecentSearches(query);
    }, 1500), [inputValue.length, products.length, categories.length]);
    useEffect(() => {
        if (!showSearch) {
            if (inputValue.length > 2 && (products.length || categories.length || inspirations.length)) {
                saveRecentSearches(inputValue);
            }
        }
    }, [categories.length, inputValue, inspirations.length, products, showSearch]);
    useEffect(() => {
        if (showSearch) {
            setInputValue('');
        }
    }, [showSearch]);
    useEffect(() => {
        var _a, _b, _c;
        if (hits.length > 1) {
            setProducts(hits[0].results.hits.map((hit) => (Object.assign(Object.assign({}, hit.data), { objectId: hit.objectID, position: hit.__position, queryID: hit.__queryID, queryType: 'Autocomplete', indexName: hits[0].results.index }))));
            setCategories(_uniqBy(hits[1].results.hits, 'url').slice(0, 6));
            // Need to get back: This assumes 3rd results is inspirations but this is failing in stories such as Header / Search Overlay
            if ((_b = (_a = hits === null || hits === void 0 ? void 0 : hits[2]) === null || _a === void 0 ? void 0 : _a.results.hits) === null || _b === void 0 ? void 0 : _b.length) {
                const inspirationHits = hits[2].results.hits
                    .map((hit) => {
                    var _a, _b, _c, _d;
                    return ({
                        url: (_b = (_a = hit === null || hit === void 0 ? void 0 : hit.data) === null || _a === void 0 ? void 0 : _a.link) === null || _b === void 0 ? void 0 : _b.url,
                        name: (_d = (_c = hit === null || hit === void 0 ? void 0 : hit.data) === null || _c === void 0 ? void 0 : _c.link) === null || _d === void 0 ? void 0 : _d.label,
                        id: hit === null || hit === void 0 ? void 0 : hit.__queryID,
                        position: hit === null || hit === void 0 ? void 0 : hit.__position,
                        queryId: hit === null || hit === void 0 ? void 0 : hit.__queryID,
                    });
                })
                    .filter((hit) => Boolean(hit.name));
                setInspirations((_c = _uniqBy(inspirationHits, 'url')) === null || _c === void 0 ? void 0 : _c.slice(0, 6));
            }
            else
                setInspirations([]);
            debouncedSaveValue(inputValue);
        }
    }, [debouncedSaveValue, hits, inputValue]);
    useEffect(() => {
        if (!inputValue && showSearch) {
            const recentSearchesInStorage = getRecentSearches();
            setRecentSearches(recentSearchesInStorage);
        }
    }, [showSearch, inputValue]);
    useEffect(() => {
        return () => {
            debouncedSaveValue.cancel();
        };
    }, [debouncedSaveValue]);
    useEffect(() => {
        nbHits.forEach((nbHit) => {
            const inspirationKey = Object.keys(nbHit).find((key) => key.includes('inspiration'));
            const categoryKey = Object.keys(nbHit).find((key) => key.includes('category'));
            const inspirationCount = inspirationKey ? nbHit[inspirationKey] : 0;
            const categoryCount = categoryKey ? nbHit[categoryKey] : 0;
            if (categoryCount)
                setTotalCategories(categoryCount);
            if (inspirationCount)
                setTotalInspirations(inspirationCount);
        });
    }, [nbHits]);
    const handleKeywordAndPreviousSearch = (e, searchTerm) => {
        e.preventDefault();
        handleSuggestedSearchOrPreviousSearch === null || handleSuggestedSearchOrPreviousSearch === void 0 ? void 0 : handleSuggestedSearchOrPreviousSearch(searchTerm);
    };
    const handleViewAllButtonClick = (activeSearchTab) => {
        sessionStorage.setItem('activeSearchTab', activeSearchTab);
    };
    // SearchAutoComplete must be rendered after hydration as if it's SSR then it
    const [isAfterHydration, setIsAfterHydration] = useState(false);
    useEffect(() => {
        setIsAfterHydration(true);
    }, []);
    return (_jsxs("div", { className: classes.searchOverlay, children: [_jsx("div", { className: clsx(classes.searchOverlayInner, classes.searchFieldWrapper, noResultsFound ? classes.noresultsfoundcontainer : ''), children: _jsx("div", { className: classes.search, children: _jsxs("form", { onSubmit: submitSearch, children: [_jsx(Configure, { clickAnalytics: true, ruleContexts: ['search'] }), isAfterHydration && (_jsx(SearchAutoComplete, { name: "search", label: "search", unboxed: true, onChange: setInputValue, value: inputValue, placeholder: "Search keyword or category", showSearch: showSearch }))] }) }) }), showInitialView || noResultsFound ? (_jsx(InitialSearchView, { noResultsFound: noResultsFound, inputValue: inputValue, keywords: keywords, categories: suggestedCategories, recentSearches: recentSearches, searchDefaultProducts: searchDefaultProducts, handleKeywordAndPreviousSearch: handleKeywordAndPreviousSearch })) : null, showSearchResults && !noResultsFound ? (_jsx(SearchResults, { inspirations: inspirations, categories: categories, products: products, inputValue: inputValue, totalInspirations: totalInspirations, totalCategories: totalCategories, handleViewAllButtonClick: handleViewAllButtonClick })) : null] }));
};
export default SearchOverlay;
