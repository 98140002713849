import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    heading: Object.assign(Object.assign({ color: colors.supplementary.black }, theme.typography.large_bold), { marginBottom: '18px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: '28px' }) }),
    description: {
        display: 'none',
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { display: 'block', marginBottom: spacing.xl }),
    },
    container: {
        gridTemplateColumns: 'repeat(1, minmax(200px, 1fr))',
        display: 'grid',
        gridGap: spacing.small,
        [theme.breakpoints.up('md')]: {
            gridGap: spacing.medium,
            gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))',
        },
    },
}));
export default useStyles;
