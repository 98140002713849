import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../../styles/jss/variables';
import { link } from '../../../styles/jss/utils';
const useStyles = makeStyles()((theme) => ({
    linkContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        '& a': {
            whiteSpace: 'pre-wrap',
        },
    },
    adjustLinkContainer: {
        width: '100%',
    },
    link: {
        whiteSpace: 'pre-wrap',
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
    },
    linkText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(colors.primary.gold4)), { color: colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
}));
export default useStyles;
