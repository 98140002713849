import { AuthAnalyticProps, SaveUserJourneyAnalyticsSubmit } from '@interflora/ui-components/build/common/props'
import AnalyticsContext from 'context/AnalyticsContext'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { isCookieAccepted } from '@interflora/ui-components/build/utils/common'

/** Custom hook for saving the user journey analytics. */
export const useSaveAuthAnalytics = () => {
  const analytics = useContext(AnalyticsContext)
  const router = useRouter()
  const pageUrl = router?.asPath?.split('?')[0]
  const isPerformanceCookieAccepted = isCookieAccepted()
  const saveUserJourneyAnalytics: SaveUserJourneyAnalyticsSubmit<AuthAnalyticProps> = (values) => {
    const { isSuccessful = false, journeyType, email } = values
    switch (journeyType) {
      case 'GUEST':
        analytics.continueAsGuest()
        break
      case 'LOGIN':
        analytics.signInJourney(isSuccessful, pageUrl)
        if (email && window.ometria && isPerformanceCookieAccepted) {
          window.ometria.identify(email)
        }
        break
      case 'REGISTER':
        analytics.createAnAccount(isSuccessful)
        if (email && window.ometria && isPerformanceCookieAccepted) {
          window.ometria.identify(email)
        }
        break
      default:
        break
    }
  }
  return { saveUserJourneyAnalytics }
}
