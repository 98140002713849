import { removeSessionItems } from '@interflora/ui-components/build/utils/common'
import { useEffect } from 'react'
import { getRenderGraph } from 'utils/getRenderGraph'
import Page from '../components/Page/Page'

const Index = (renderGraph: any) => {
  useEffect(() => {
    removeSessionItems(['isFromPLP', 'breadcrumb'])
  })
  return <Page renderGraph={renderGraph} />
}

export default Index

export const getStaticProps = () => getRenderGraph('/')
