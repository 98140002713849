import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
import { MAX_CARD_WIDTH } from '../../styles/jss/utils';
const useStyles = makeStyles()((theme, _params, classes) => ({
    imageContent: {},
    messageContent: {},
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { paddingTop: 0 }) }),
    message: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    container: {
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        [`& .${classes.messageContent} , & .${classes.imageContent}`]: {
            width: '100% !important',
            height: '100%  !important',
            maxWidth: `${MAX_CARD_WIDTH}`,
            maxHeight: `${MAX_CARD_WIDTH}`,
            minWidth: '224px !important',
            minHeight: '224px !important',
        },
        [`& .${classes.messageContent}`]: {
            maxHeight: 'none',
            backgroundColor: 'unset',
            alignItems: 'inherit',
            flexDirection: 'column',
            marginTop: '18px',
            padding: '0 !important',
            [theme.breakpoints.up('md')]: {
                padding: `0 ${spacing.medium} !important`,
                maxWidth: '520px',
                marginTop: 0,
            },
        },
    },
}));
export default useStyles;
