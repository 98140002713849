import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    text: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { paddingTop: 0 }),
    container: {
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        paddingLeft: '3px',
        marginBottom: spacing.xs,
        display: 'flex',
        '& span:first-of-type': {
            width: '20px !important',
            marginRight: '10px !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative !important',
        },
        '&:last-child': {
            marginBottom: spacing.large,
        },
    },
    image: {
        marginTop: spacing.xxs,
        marginRight: '10px',
        height: '20px',
        width: '20px',
        alignSelf: 'flex-start',
    },
}));
export default useStyles;
