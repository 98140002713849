import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {},
    input: { marginBottom: spacing.small },
    error: { marginBottom: spacing.small },
    forgottenLink: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)),
    buttons: {},
}));
export default useStyles;
