import { makeStyles } from 'tss-react/mui';
import { inputStyles } from '../../styles/jss/inputs';
import { colors, spacing } from './../../styles/jss/variables';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign({}, inputStyles(theme)), { searchAutoComplete: {
        '& input': {
            [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular),
        },
        '& .MuiFormControl-root': {
            verticalAlign: 'middle',
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'inherit',
            marginRight: '40px',
            [theme.breakpoints.up('md')]: {
                marginRight: '60px',
            },
        },
    }, searchTextField: {
        padding: `${spacing.small} 0`,
        display: 'block',
        [theme.breakpoints.up('md')]: {
            padding: `${spacing.small} ${spacing.large}`,
        },
    }, endAdornmentContainer: {
        width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            width: '200px',
        },
        '& svg': {
            fontSize: '28px',
            [theme.breakpoints.up('md')]: {
                fontSize: '38px',
            },
        },
    }, clearbutton: {
        '& svg': {
            color: theme.isFlyingFlowers ? colors.black[600] : colors.supplementary.black,
        },
    }, searchbutton: {
        '& svg': {
            color: theme.isFlyingFlowers ? colors.wildExotic[400] : colors.supplementary.black,
        },
    }, iconButton: {
        padding: `6px!important`,
        top: '-2px',
    } })));
export default useStyles;
