import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from './Alert.styles';
import Icon from '../Icon/Icon';
import { useLoaded } from '../MainContent/useComponentLoading';
const icons = { alertInfo: 'error', alertError: 'error', alertSuccess: 'check_circle', alertTimer: 'timer' };
const Alert = (props) => {
    const { type, message, className, isFromCheckout } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs("div", { className: clsx(className, classes.container, type ? classes[type] : '', isFromCheckout ? classes.errorMessage : ''), children: [type && _jsx(Icon, { icon: icons[type], className: classes.icon, fontSize: "inherit" }), _jsx("span", { className: classes.message, children: message })] }));
};
export default Alert;
