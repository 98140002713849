import { makeStyles } from 'tss-react/mui';
import { removePaddingWithMargin } from '../../styles/jss/utils';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    root: {},
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '60% 1fr',
            gridTemplateRows: 'auto auto 1fr',
            gridTemplateAreas: '"images summary" "images purchase" "content purchase"',
            gridGap: theme.spacing(3),
        },
    },
    images: {
        gridArea: 'images',
        maxWidth: 928,
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: { margin: 0 },
        [theme.breakpoints.down('sm')]: Object.assign({}, removePaddingWithMargin),
    },
    summary: {
        gridArea: 'summary',
        [theme.breakpoints.up('md')]: { maxWidth: 520 },
    },
    purchase: { gridArea: 'purchase', [theme.breakpoints.up('md')]: { maxWidth: 520 } },
    content: { gridArea: 'content' },
    errorContainer: {
        [theme.breakpoints.down('xl')]: {
            maxHeight: '132px',
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: '108px',
        },
    },
    errorMessageText: {
        margin: 0,
        marginBottom: '12px',
        [theme.breakpoints.down('xl')]: Object.assign({}, theme.typography.small_regular),
    },
    modalHeading: Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.medium_bold), { fontWeight: fontWeights.regular, display: 'flex', '& span': {
            margin: 0,
            '&:first-child': {
                marginRight: spacing.xs,
            },
        }, [theme.breakpoints.up('md')]: {
            display: 'none',
        } }),
    modal: {
        [theme.breakpoints.down('xl')]: {
            '& .MuiDialog-paperFullScreen': { height: '100%' },
            '& .MuiDialogContent-root': {
                overflowY: 'hidden',
                height: '100%',
            },
            '& form': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
        },
        '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
                width: '100%',
                maxWidth: '1336px',
                maxHeight: '90vh',
                left: 0,
            },
        },
        '& .MuiAutocomplete-listbox': {
            maxHeight: '25vh',
        },
    },
    errorMessageItemLink: {
        textDecoration: 'underline',
        textAlign: 'left',
        [theme.breakpoints.down('xl')]: Object.assign({}, theme.typography.small_regular),
    },
    error: {},
}));
export default useStyles;
