import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.xl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_sm : theme.typographySpacing.xl_bold_lg)), { textAlign: 'center', [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, theme.typography.xl_regular), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_lg : theme.typographySpacing.xl_regular_sm)) }),
    blogFeatureArticleGallery: {
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
}));
export default useStyles;
