import { jsx as _jsx } from "react/jsx-runtime";
import { Formik, Form } from 'formik';
import Modal from '../Modal/Modal';
import useStyles from './EditReminderModal.styles';
import AddEditRemindersForm from '../AddEditRemindersForm/AddEditRemindersForm';
import { validationConfig } from '../AddEditRemindersForm/utils';
const EditReminderModal = (props) => {
    const { reminder, editReminder, setReminderStatusInfo, isEditReminderOpen, setEditReminderOpen, setHasScrolled } = props;
    const { id, firstName, lastName, occasion, date } = reminder;
    const [, month, day] = date.split('-');
    const initialValues = {
        firstName,
        lastName,
        occasion,
        day,
        month,
        id,
    };
    const { classes } = useStyles();
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema: validationConfig,
        onSubmit: (values) => {
            editReminder &&
                editReminder(values)
                    .then((response) => {
                    if (!response.errors) {
                        setReminderStatusInfo &&
                            setReminderStatusInfo({
                                reminderStatus: 'alertSuccess',
                                reminderStatusMessage: 'Your reminder has been successfully saved.',
                            });
                    }
                })
                    .catch((error) => {
                    const isDuplicate = (error === null || error === void 0 ? void 0 : error.message) === 'Occasion already exists in the Customer Occasion Reminder list';
                    setReminderStatusInfo &&
                        setReminderStatusInfo({
                            reminderStatus: isDuplicate ? 'alertInfo' : 'alertError',
                            reminderStatusMessage: isDuplicate ? 'This reminder was already saved.' : error.message,
                        });
                })
                    .finally(() => {
                    setEditReminderOpen(false);
                    window.scrollTo(0, 0);
                    setHasScrolled && setHasScrolled(false);
                });
        },
    };
    return (_jsx(Modal, { open: isEditReminderOpen, setOpen: setEditReminderOpen, onClose: () => setHasScrolled && setHasScrolled(false), keepMounted: false, title: 'Edit reminder', disablePortal: false, className: classes.addEditOccasionsModal, maxWidth: "md", children: _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => {
                return (_jsx(Form, { children: _jsx(AddEditRemindersForm, { isEdit: true, occasion: occasion, isSubmitting: isSubmitting }) }));
            } })) }));
};
export default EditReminderModal;
