import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { ucFirst } from '../../utils/common';
import useStyles from './Icon.styles';
import Image from '../Image/Image';
import { Box, Icon as MaterialIcon } from '@mui/material';
export const SVG_ICONS = {
    australia: 'flags/AUSTRALIA.svg',
    austria: 'flags/AUSTRIA.svg',
    belgium: 'flags/BELGIUM.svg',
    brazil: 'flags/BRAZIL.svg',
    canada: 'flags/CANADA.svg',
    china: 'flags/CHINA.svg',
    cyprus: 'flags/CYPRUS.svg',
    denmark: 'flags/DENMARK.svg',
    finland: 'flags/FINLAND.svg',
    france: 'flags/FRANCE.svg',
    germany: 'flags/GERMANY.svg',
    globe: 'flags/GLOBE.svg',
    greece: 'flags/GREECE.svg',
    holland: 'flags/HOLLAND.svg',
    'hong-kong': 'flags/HONG-KONG.svg',
    hungary: 'flags/HUNGARY.svg',
    india: 'flags/INDIA.svg',
    ireland: 'flags/IRELAND.svg',
    israel: 'flags/ISRAEL.svg',
    italy: 'flags/ITALY.svg',
    japan: 'flags/JAPAN.svg',
    malaysia: 'flags/MALAYSIA.svg',
    mexico: 'flags/MEXICO.svg',
    'new-zealand': 'flags/NEW-ZEALAND.svg',
    norway: 'flags/NORWAY.svg',
    poland: 'flags/POLAND.svg',
    portugal: 'flags/PORTUGAL.svg',
    romania: 'flags/ROMANIA.svg',
    russia: 'flags/RUSSIA.svg',
    singapore: 'flags/SINGAPORE.svg',
    'south-africa': 'flags/SOUTH-AFRICA.svg',
    spain: 'flags/SPAIN.svg',
    sweden: 'flags/SWEDEN.svg',
    switzerland: 'flags/SWITZERLAND.svg',
    turkey: 'flags/TURKEY.svg',
    uae: 'flags/UAE.svg',
    uk: 'flags/UK.svg',
    usa: 'flags/USA.svg',
};
const Icon = (props) => {
    var _a;
    const { label, className, fontSize, onClick, ariaLabel, roundel } = props;
    const icon = (_a = props.icon) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    const { classes } = useStyles();
    const svg = SVG_ICONS[icon];
    const titleIcon = ucFirst(icon);
    const Contents = () => (_jsxs(_Fragment, { children: [svg ? (_jsx(Image, { imageUrl: `/images/icons/${svg}`, altText: `${titleIcon} Flag Icon`, className: clsx(fontSize && classes[fontSize], roundel && classes.roundel, roundel && classes[roundel]), height: 24, width: 24, loading: "lazy" })) : (_jsx(MaterialIcon, Object.assign({}, (fontSize && { fontSize }), { className: clsx(roundel && classes.roundel, roundel && classes[roundel], fontSize && classes[fontSize]), children: icon }))), label && _jsx("div", { children: label })] }));
    const commonProps = {
        className: clsx(classes.iconContainer, className !== null && className !== void 0 ? className : ''),
        'aria-label': ariaLabel || 'icon',
    };
    return (_jsx(_Fragment, { children: !!onClick ? (_jsx(Box, Object.assign({ component: 'button', type: "button", onClick: onClick, style: { cursor: 'pointer' } }, commonProps, { children: _jsx(Contents, {}) }))) : (_jsx(Box, Object.assign({ component: 'span' }, commonProps, { children: _jsx(Contents, {}) }))) }));
};
export default Icon;
