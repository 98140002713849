import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.large_bold_lg), [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.large_bold) }),
    message: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { textAlign: 'left' }), (!theme.isFlyingFlowers && {
        paddingTop: '0px!important',
        paddingBottom: '0px!important',
        [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold),
    })), { [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.large_bold) }),
    container: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'stretch',
        },
        [`&, & .${classes.image}, & .${classes.content}`]: {
            [theme.breakpoints.up('sm')]: {
                minHeight: '314px',
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '310px',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '395px',
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: '528px',
            },
        },
    },
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            paddingTop: '50%',
        },
        [theme.breakpoints.up('md')]: {
            width: '66%',
            // Aspect ratio 2:1 when only 1 image
            paddingTop: '33%',
        },
    },
    image: {
        width: '100%',
        height: '100%',
        minHeight: '200px',
        objectFit: 'cover',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        position: 'relative',
        textAlign: 'center',
        // For 1 image
        padding: spacing.medium,
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            padding: spacing.large,
        },
        [theme.breakpoints.up('md')]: {
            width: '33%',
        },
        [theme.breakpoints.up('xl')]: {
            padding: spacing.xl,
        },
    },
    textLeft: {
        textAlign: 'left',
    },
    textRight: {
        textAlign: 'right',
    },
    // For no images
    fullWidth: {
        [`& .${classes.content}`]: {
            width: '100%',
        },
    },
    // For 2 images
    thirdsWidth: {
        [`& .${classes.content}`]: {
            [theme.breakpoints.up('sm')]: {
                // Left image is hidden - right is 50%
                width: '50%',
            },
            [theme.breakpoints.up('md')]: {
                // Both images shown at 33%
                width: '34%',
            },
        },
        [`& .${classes.imageContainer}`]: {
            [theme.breakpoints.up('sm')]: {
                // Left image is hidden - right is 50%
                width: '50%',
                paddingTop: '50%',
            },
            [theme.breakpoints.up('md')]: {
                // Both images shown at 33%
                width: '33%',
                paddingTop: '33%',
            },
        },
        [`& .${classes.imageContainer}:first-child`]: {
            [theme.breakpoints.up('sm')]: {
                // Hide left image
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                // Show left image
                display: 'block',
            },
        },
    },
    contentInner: {
        width: '100%',
        alignSelf: 'center',
    },
    positionTop: {
        alignSelf: 'flex-start',
    },
    positionCenter: {
        alignSelf: 'center',
    },
    positionBottom: {
        alignSelf: 'flex-end',
    },
}));
export default useStyles;
