import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    selectionButton: {
        minHeight: '108px',
        minWidth: '80px',
        [theme.breakpoints.up('lg')]: {
            minWidth: '112px',
        },
        border: `1px solid ${colors.grey.grey3}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& span': {
            display: 'block',
            '$ .material-icons': {
                color: '#000',
            },
        },
        padding: `12px ${spacing.xs} 12px ${spacing.xs}`,
        '&:hover': {
            BackgroundColor: colors.grey.grey1,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            cursor: 'pointer',
            borderColor: colors.grey.grey6,
        },
        '&:focus': {
            BackgroundColor: colors.grey.grey1,
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
        [`&.${classes.selected}`]: {
            backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
            borderColor: theme.isFlyingFlowers ? theme.statusColors.success.dark : colors.supplementary.black,
            '&:hover': {
                boxShadow: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
            },
        },
    },
    selected: {
        backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
        borderColor: theme.isFlyingFlowers ? theme.statusColors.success.dark : colors.supplementary.black,
    },
    variantLabel: {
        minHeight: '56px',
        [theme.breakpoints.up('lg')]: {
            minHeight: '100px',
        },
        alignItems: 'center',
        justifyContent: 'center',
    },
    label: Object.assign(Object.assign({}, theme.typography.xxs_regular), { [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.small_regular) }),
    title: Object.assign(Object.assign({ display: 'block' }, theme.typography.xxs_bold), { marginBottom: spacing.xs, color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: '2px' }), paddingTop: 0, paddingBottom: 0 }),
    supplementary: Object.assign(Object.assign({}, theme.typography.xxs_regular), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
    blockedDate: {},
    noTitle: {
        verticalAlign: 'middle',
        justifyContent: 'center !important',
    },
    pccTitle: {
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
        marginBottom: '20px',
    },
}));
export default useStyles;
