import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import clsx from 'clsx';
const SplitContentBySlash = (props) => {
    const { content, classNames } = props;
    if (typeof content !== 'string' || !content.trim())
        return null;
    return (_jsx(_Fragment, { children: content
            .replace(/<\/?p>/g, '')
            .split('/')
            .map((line, index) => (_jsx(Box, { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.headingLine, classNames === null || classNames === void 0 ? void 0 : classNames.headingColor), component: "span", children: line.trim() }, `${line}_${index}`))) }));
};
export default SplitContentBySlash;
