import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import Link from '../Link/Link';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './ExpandingText.styles';
export const ExpandingText = (props) => {
    const { className, labelTruncated, labelExpanded, maxHeight, text, isCatalogInfo } = props;
    const { classes } = useStyles();
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [isExpandable, setIsExpandable] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const checkExpandable = useCallback(() => {
        !isExpanded &&
            contentRef.current &&
            containerRef.current &&
            setIsExpandable(contentRef.current.scrollHeight > containerRef.current.offsetHeight);
    }, [isExpanded]);
    useEffect(() => {
        checkExpandable();
        window.addEventListener('resize', checkExpandable);
        return function () {
            window.removeEventListener('resize', checkExpandable);
        };
    }, [checkExpandable, text]);
    return (_jsxs("div", { className: clsx(className), children: [_jsx("div", { ref: containerRef, className: clsx(isExpanded ? classes.contentExpanded : classes.contentHidden, isExpandable && !isExpanded && classes.contentGradient, isCatalogInfo ? classes.removeFadding : undefined), style: { maxHeight }, children: _jsx(RawHtml, { content: text, ref: contentRef, className: classes.content }) }), (isExpandable || isExpanded) && (_jsx(Link, { className: classes.link, onClick: () => setIsExpanded((prev) => !prev), label: isExpanded ? labelExpanded : labelTruncated }))] }));
};
