import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { useBackgroundClass } from '../../styles/jss/utils';
import { processImageUrl } from '../../utils/image';
import Image from '../Image/Image';
import useStyles from './ContentStory.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
import { Box } from '@mui/material';
import { SiteContext } from '../../utils/common';
const ContentStory = (props) => {
    var _a;
    const { heading, message, backgroundColor, imageBefore, imageAfter, headingTag, mobileImageBefore, mobileImageAfter, textContentPosition, } = props; // Handle nulls from GraphQL
    const { classes } = useStyles();
    const { bgClass, rgbColor } = useBackgroundClass(backgroundColor);
    const thirds = !!imageBefore && !!imageAfter;
    const fullWidth = !imageBefore && !imageAfter;
    const widthClass = thirds ? classes.thirdsWidth : fullWidth ? classes.fullWidth : undefined;
    const HeadingTag = headingTag ? headingTag.toLowerCase() : 'h3';
    const { isFlyingFlowers } = useContext(SiteContext);
    const attributes = {
        w: 820, // default - replaced for responsive widths
        aspect: thirds ? '1:1' : '2:1',
        bg: rgbColor,
    };
    const imageWidths = thirds ? [375, 540, 720] : [375, 540, 720, 820, 1100];
    const sizes = thirds
        ? [
            { breakpoint: 'sm', query: 'max-width', width: '100vw' },
            { breakpoint: 'md', query: 'max-width', width: '50vw' },
            { width: '540px' },
        ]
        : [
            { breakpoint: 'sm', query: 'max-width', width: '100vw' },
            { breakpoint: 'md', query: 'max-width', width: '50vw' },
            { breakpoint: 'lg', query: 'max-width', width: '66vw' },
            { width: '1100px' },
        ];
    useLoaded(props);
    const contentImage = (image, mobileImage, defaultAltText) => {
        var _a, _b, _c, _d;
        return (_jsxs("div", { className: classes.imageContainer, children: [_jsx(Box, { sx: {
                        display: {
                            zero: !(mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? 'block' : 'none',
                            sm: !(mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? 'block' : 'none',
                            md: 'block',
                        },
                    }, className: "desktopImageContainer", children: _jsx(Image, { className: classes.image, imageUrl: (image === null || image === void 0 ? void 0 : image.url) ? processImageUrl(image.url, attributes, ['$poi-square$']) : '', sizes: sizes, imageWidths: imageWidths, altText: (_b = (_a = image === null || image === void 0 ? void 0 : image.altText) !== null && _a !== void 0 ? _a : defaultAltText) !== null && _b !== void 0 ? _b : '', draggable: false, loading: "lazy" }) }), _jsx(Box, { sx: {
                        display: { zero: (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? 'block' : 'none', sm: (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? 'block' : 'none', md: 'none' },
                    }, className: "mobileImageContainer", children: _jsx(Image, { className: classes.image, imageUrl: (mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.url) ? processImageUrl(mobileImage.url, attributes, ['$poi-square$']) : '', sizes: sizes, imageWidths: imageWidths, altText: (_d = (_c = mobileImage === null || mobileImage === void 0 ? void 0 : mobileImage.altText) !== null && _c !== void 0 ? _c : defaultAltText) !== null && _d !== void 0 ? _d : '', draggable: false, loading: "lazy" }) })] }));
    };
    const textContentPositionClasses = {
        BOTTOM: classes.positionBottom,
        TOP: classes.positionTop,
        CENTER: classes.positionCenter,
    };
    const textContentPositionClass = (_a = textContentPositionClasses[textContentPosition === null || textContentPosition === void 0 ? void 0 : textContentPosition.toUpperCase()]) !== null && _a !== void 0 ? _a : classes.positionCenter;
    return (_jsxs(_Fragment, { children: [heading && _jsx(HeadingTag, { className: classes.heading, children: heading }), _jsxs("div", { className: clsx(classes.container, widthClass), children: [contentImage(imageBefore, mobileImageBefore), _jsx("div", { className: clsx(classes.content, classes.textLeft, bgClass), children: _jsx(Box, { className: clsx(classes.contentInner, !isFlyingFlowers ? textContentPositionClass : ''), children: message && _jsx("div", { className: classes.message, children: message }) }) }), contentImage(imageAfter, mobileImageAfter)] })] }));
};
export default ContentStory;
