import { makeStyles } from 'tss-react/mui';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    mobileModal: {
        '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
        },
    },
    '& .MuiButton-label': {
        whiteSpace: 'nowrap',
    },
    mobileDatePicker: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    datePicker: {
        position: 'relative',
        marginTop: '20px',
        overflowY: 'auto',
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Chrome/Safari/Opera */,
        '-khtml-user-select': 'none' /* Konqueror */,
        '-moz-user-select': 'none' /* Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none' /* Non-prefixed version, currently */,
        [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: '950px',
            margin: '0 auto',
            height: 'initial',
        },
    },
    modal: {
        '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
                width: '80%',
                maxWidth: '1336px',
                padding: spacing.medium,
            },
            [theme.breakpoints.up('lg')]: {
                padding: spacing.large,
            },
        },
    },
    navigationContainer: {
        position: 'relative',
        width: '100%',
    },
    navigation: {
        position: 'absolute',
        top: '25px',
    },
    nextMonthGroup: {
        right: '0',
    },
    calendarContainer: {
        marginTop: '20px',
        [theme.breakpoints.up('md')]: {
            marginTop: '0',
            '&:first-child': {
                justifySelf: 'start',
            },
            '&:nth-child(2)': {
                justifySelf: 'end',
            },
        },
    },
    monthName: Object.assign(Object.assign({}, theme.typography.small_bold), { width: 'fit-content', margin: `0 auto ${spacing.medium} auto` }),
    pccMonthName: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { [theme.breakpoints.down('xs')]: {
            margin: `0 auto 20px auto`,
            width: '404px',
            textAlign: 'center',
            marginBottom: spacing.small,
        } }),
    calendarHead: {
        borderBottom: `1px solid ${colors.grey.grey4}`,
    },
    dayNameContainer: {
        height: '30px',
        maxWidth: 'fit-content',
        width: '100%',
        margin: '10px auto 0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 54px)',
        gridTemplateRows: '30px',
        [theme.breakpoints.up('md')]: {
            width: '400px',
            justifyContent: 'center',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(7, 50px)',
        },
    },
    calendarBody: {
        flex: 1,
        borderBottom: `1px solid ${colors.grey.grey4}`,
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            margin: '20px auto 0 auto',
            overflowY: 'initial',
            borderBottom: 'none',
            maxWidth: '900px',
        },
    },
    pccCalrendarBody: {
        [theme.breakpoints.down('xs')]: {
            '@media(orientation: landscape)': {
                maxHeight: '100px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
        },
    },
    monthsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    pccMonthContainer: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
    calendarActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    singleButton: {},
    calendarActions: {
        paddingBottom: spacing.xxs,
        width: '100%',
        maxWidth: theme.isFlyingFlowers ? '410px' : '382px',
        display: 'flex',
        margin: `${spacing.small} auto ${spacing.small} auto`,
        justifyContent: 'center',
        '& button': {
            width: `calc(50% - ${spacing.small})`,
            marginRight: spacing.small,
            [theme.breakpoints.up('md')]: {
                marginRight: spacing.xs,
                width: `calc(50% - 30px)`,
            },
            '&:last-child': {
                marginRight: '0px',
            },
        },
        [theme.breakpoints.up('md')]: {
            margin: '0',
            width: '420px',
            maxWidth: '100%',
            justifyContent: theme.isFlyingFlowers ? undefined : 'flex-end',
        },
        [`&.${classes.singleButton}`]: {
            maxWidth: '360px',
            display: 'block',
            '& button': {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                paddingRight: spacing.xxs,
                maxWidth: '350px',
                width: '80%',
            },
        },
    },
    dayName: Object.assign({ height: '30px', textAlign: 'center', color: colors.grey.grey6 }, theme.typography.xxs_regular),
    monthDaysContainer: {
        margin: `0 auto 20px auto`,
        height: '270px',
        maxWidth: 'fit-content',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 54px)',
        gridTemplateRows: 'auto',
        [theme.breakpoints.up('md')]: {
            marginBottom: '0',
            justifyContent: 'center',
            maxWidth: '375px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(7, 50px)',
            marginBottom: '0',
        },
    },
    pccMonthsDaysContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '250px',
        },
    },
    pccCalendarContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
        },
    },
    disabled: {
        color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey4,
    },
    selected: {
        border: theme.isFlyingFlowers ? `1px solid ${colors.black[600]} !important` : undefined,
        background: theme.isFlyingFlowers ? colors.lazyLime[400] : colors.primary.gold4,
        color: theme.isFlyingFlowers ? `${colors.black[700]} !important` : colors.supplementary.black,
    },
    currentDay: {},
    day: Object.assign(Object.assign({ justifySelf: 'center', textAlign: 'center', width: '40px', height: '40px', display: 'block', borderRadius: theme.isFlyingFlowers ? 'unset' : '100%', border: '1px solid transparent' }, theme.typography.small_regular), { lineHeight: '35px', color: theme.isFlyingFlowers ? colors.black[600] : colors.supplementary.black, [`&.${classes.disabled}`]: {
            pointerEvents: 'none',
            background: colors.grey.grey1,
        }, '&:hover': {
            cursor: 'pointer',
            border: `1px solid ${colors.supplementary.black}`,
        }, [`&.${classes.currentDay}`]: {
            fontWeight: fontWeights.bold,
        } }),
    dayDetailsContainer: {
        backgroundColor: colors.grey.grey1,
        borderBottom: `1px solid ${colors.grey.grey4}`,
        padding: '10px 0px',
        gridArea: 'dateDetails',
        [theme.breakpoints.up('md')]: {
            border: 'none',
            marginBottom: '30px',
            padding: '10px 20px',
        },
    },
    dayDetails: {
        margin: '0px auto',
        padding: '0px 10px',
        width: '100%',
        maxWidth: '382px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            maxWidth: 'unset',
            padding: 0,
            margin: 0,
        },
        '& span': Object.assign({ display: 'block', color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }, theme.typography.xxs_bold),
        '& ul': {
            marginTop: spacing.xxs,
        },
        '& li': Object.assign({}, theme.typography.xxs_regular),
    },
    skeletonContainer: {
        width: '378px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            width: '350px',
        },
    },
    buttonCenter: {
        justifyContent: 'center !important',
    },
    pccDatePicker: {
        maxWidth: 'initial',
    },
}));
export default useStyles;
