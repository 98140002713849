import { makeStyles } from 'tss-react/mui';
import { formStyles } from '../../styles/jss/form';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign({}, formStyles(theme)), { profileSection: {
        marginBottom: `${spacing.small} !important`,
        lineHeight: '1.43rem',
    }, alertMessage: {
        marginBottom: spacing.large,
    } })));
export default useStyles;
