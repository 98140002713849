import { spacing } from '../../../styles/jss/variables';
export const productCardStyle = (theme) => ({
    showAllButton: {
        display: 'block',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            width: 'auto',
            maxWidth: '335px',
        },
        '& a': {
            width: '335px',
        },
    },
    productCard: {
        display: 'flex',
        minWidth: theme.isFlyingFlowers ? `160px` : '140px',
        padding: '0 !important',
        maxWidth: theme.isFlyingFlowers ? '160px' : '140px',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(33% - 24px)',
            maxWidth: theme.isFlyingFlowers ? '248px' : '260px',
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.isFlyingFlowers ? '248px' : '260px',
            maxWidth: theme.isFlyingFlowers ? '248px' : '260px',
        },
    },
    lastProduct: {
        marginTop: theme.isFlyingFlowers ? '2px' : '1px',
        marginBottom: spacing.medium,
        [theme.breakpoints.up(476)]: {
            marginTop: theme.isFlyingFlowers ? undefined : 'initial ',
            marginBottom: 'initial',
        },
        [theme.breakpoints.up(536)]: {
            marginTop: theme.isFlyingFlowers ? 'initial' : undefined,
            marginBottom: 'initial',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 'initial !important',
            marginBottom: 'initial',
        },
    },
});
