import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    styleVariantModal: {
        '& .MuiPaper-root': {
            '& header': {
                marginBottom: spacing.xs,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                },
                '& h3': {
                    paddingLeft: 0,
                    [theme.breakpoints.up('md')]: Object.assign(Object.assign({ paddingLeft: '73px', margin: '10px 0 32px 0 !important' }, theme.typography.large_regular), theme.typographySpacing.large_regular_sm),
                },
                '& > div': {
                    position: 'relative',
                    top: '-10px',
                },
            },
            [theme.breakpoints.up('lg')]: {
                padding: '32px 40px 0 40px',
                maxWidth: '1064px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0px 73px 54px 73px !important',
                    width: '100%',
                },
            },
        },
    },
    container: {
        flexDirection: 'column',
        display: 'flex',
        gap: spacing.large,
        maxHeight: '888px',
        [theme.breakpoints.up('md')]: {
            gap: spacing.medium,
        },
    },
    image: {
        width: '335px',
        height: '188px',
        [theme.breakpoints.up('md')]: {
            width: '305px',
            height: '171px',
        },
    },
    information: {
        flexDirection: 'column',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            gap: '48px',
        },
    },
    bodyContainer: {
        '& > :first-child': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { color: colors.supplementary.black, marginTop: '16px', padding: '2px 0 !important', [theme.breakpoints.up('md')]: {
                marginTop: '0',
            } }),
    },
    description: {
        '& p, & li p, & h1, & h2, & h3, & h4': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: colors.grey.grey6 }),
    },
    modal: {},
}));
export default useStyles;
