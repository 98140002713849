import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, _params) => ({
    image: {
        width: '100%',
        position: 'relative',
        '& img': {
            objectFit: 'cover',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
        paddingTop: '100%',
        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        minHeight: '200px',
    },
    fullWidth: {
        [theme.breakpoints.up('sm')]: {
            // desktop image to be full width and it to fill container
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
    },
    desktopImageContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    mobileImageContainer: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    displayDesktopImage: {
        display: 'block',
    },
    squareImage: {
        [theme.breakpoints.up('xs')]: {
            // Use padding top to make image square
            paddingTop: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            // Use padding top to make image square
            paddingTop: '50%',
        },
    },
}));
export default useStyles;
