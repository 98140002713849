import { removeSessionItems } from '../../utils/common';
export const prepareCategorySchemaAndAppendToHead = (products, searchResults) => {
    const itemsListArr = products.map((product, index) => {
        var _a, _b, _c;
        const price = (_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.formatted;
        const priceWithoutCurrency = price.slice(1, price.length);
        const itemData = {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@type': 'Product',
                image: (_b = product === null || product === void 0 ? void 0 : product.images[0]) === null || _b === void 0 ? void 0 : _b.url,
                url: product === null || product === void 0 ? void 0 : product.url,
                name: product === null || product === void 0 ? void 0 : product.name,
                offers: {
                    '@type': 'Offer',
                    price: priceWithoutCurrency,
                    priceCurrency: (_c = product === null || product === void 0 ? void 0 : product.price) === null || _c === void 0 ? void 0 : _c.currencyCode,
                },
            },
        };
        return itemData;
    });
    const jsonLD = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'CollectionPage',
        mainEntity: {
            '@type': 'ItemList',
            url: window.location.href,
            numberOfItems: searchResults === null || searchResults === void 0 ? void 0 : searchResults.nbHits,
            itemListElement: itemsListArr,
        },
    });
    return JSON.parse(jsonLD);
};
const handleRouteChange = () => {
    // Cleaning up the current category schema if any
    const scriptElement = document.getElementById('categorySchema');
    if (scriptElement)
        scriptElement.remove();
};
const handleScroll = (skuDocumentId, products, setIsLoadAll, setScrollPoistion) => {
    var _a;
    const lastProductSku = (_a = products[products.length - 1]) === null || _a === void 0 ? void 0 : _a.sku;
    const sku = sessionStorage.getItem('productScrollIntoView');
    const lastProductSkuId = sku && lastProductSku && document.getElementById(lastProductSku);
    if (lastProductSkuId && sku) {
        const isLoadAllFromStorage = sessionStorage.getItem('loadAll');
        const documentId = document.getElementById(sku);
        skuDocumentId.current = documentId ? sku : '';
        setTimeout(() => {
            var _a;
            (_a = document.getElementById(sku)) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
            isLoadAllFromStorage && setIsLoadAll(true);
            !!skuDocumentId.current && setScrollPoistion(true);
            removeSessionItems('isFromPDP');
        }, 100);
    }
};
const removeSessionStorage = (setIsLoadAll) => {
    if (!sessionStorage.getItem('isFromPDP')) {
        const hasToScroll = sessionStorage.getItem('sessionFilters');
        const removeSessionList = [
            'isFromPDP',
            'productScrollIntoView',
            'loadAll',
            'sessionFilters',
            'sessionCurrentPageNo',
        ];
        if (typeof window !== 'undefined' && hasToScroll && hasToScroll !== '{}') {
            setIsLoadAll(false);
            window.scrollTo(0, 0);
            removeSessionItems(removeSessionList);
        }
    }
};
