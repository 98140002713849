import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from './MegaNavMobile.styles';
import SpotlightNavigationCard from '../SpotlightNavigationCard/SpotlightNavigationCard';
const MobileSpotlightDoubleNavigationCard = (props) => {
    const { classes } = useStyles();
    const { spotlightDoubleBanner, hasCombinationSpotlightCards, isAllSingleSpotlightCards } = props;
    return (_jsx("ul", { className: clsx(classes.spotlightProductCards, classes.combinationCardContainer), children: spotlightDoubleBanner.map((spotlightProduct) => (_jsx("li", { className: clsx(classes.spotLightproductCard, spotlightProduct.columnWidth === '2' ? classes.doubleSpotLightCard : classes.singleSpotLightCard, hasCombinationSpotlightCards && spotlightProduct.columnWidth === '1'
                ? classes.combinationSingleCardCard
                : '', hasCombinationSpotlightCards && spotlightProduct.columnWidth === '2'
                ? classes.combinationDoubleCardCard
                : '', isAllSingleSpotlightCards ? classes.twoSingleSpotLightCard : ''), children: _jsx(SpotlightNavigationCard, Object.assign({}, spotlightProduct)) }, `${spotlightProduct.image.url}`))) }));
};
export default MobileSpotlightDoubleNavigationCard;
