var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import useStyles from './FloristSearch.styles';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { errorMessages, inputValidationRegex, SiteContext } from '../../utils/common';
import Alert from '../Alert/Alert';
import { useLoaded } from '../MainContent/useComponentLoading';
const validationSchema = Yup.object().shape({
    postcode: Yup.string()
        .required(errorMessages().required)
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
});
const FloristSearch = (props) => {
    const { classes } = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const { onSubmit } = props;
    const { shorthand } = useContext(SiteContext);
    const initialValues = {
        postcode: '',
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setErrorMessage('');
                yield onSubmit(values);
            }
            catch (error) {
                setErrorMessage(error.message);
            }
        }),
    };
    useLoaded(props);
    return (_jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsxs(Form, { className: classes.searchForm, children: [errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx("div", { className: classes.container, children: _jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.floristSearchContainer, children: [_jsx("div", { className: classes.floristSearchInputContainer, children: _jsx(TextField, { name: "postcode", "aria-label": `Enter town or ${shorthand === 'ie' ? 'eircode' : 'postcode'}`, placeholder: `Enter town or ${shorthand === 'ie' ? 'eircode' : 'postcode'}`, fullWidth: true, className: classes.floristSearchInput }) }), _jsx("div", { className: classes.searchButton, children: _jsx(Button, { variant: "primary", fullWidth: true, title: "Search", isSubmit: true, disabled: isSubmitting }) })] }) }) })] })) })));
};
export default FloristSearch;
