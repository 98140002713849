import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './FloristUspGallery.styles';
import FloristUsp from '../FloristUsp/FloristUsp';
import { useLoaded } from '../MainContent/useComponentLoading';
const FloristUspGallery = (props) => {
    const { title, description, floristUsps } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h2", { className: classes.title, children: title }), description && _jsx("div", { className: classes.description, children: description }), !!(floristUsps === null || floristUsps === void 0 ? void 0 : floristUsps.length) && (_jsx("div", { className: classes.container, children: floristUsps === null || floristUsps === void 0 ? void 0 : floristUsps.map((floristUsp, index) => _jsx(FloristUsp, Object.assign({}, floristUsp), `${floristUsp}_${index}`)) }))] }));
};
export default FloristUspGallery;
