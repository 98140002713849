import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    dayPicker: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        marginBottom: spacing.large,
        gap: '5px',
        [theme.breakpoints.up('sm')]: {
            gap: '16px',
        },
        '& button': {
            borderRadius: theme.isFlyingFlowers ? spacing.xs : spacing.xxs,
            border: theme.isFlyingFlowers ? `1px solid ${colors.black[700]}` : `1px solid ${colors.grey.grey3}`,
            // '&:not(:last-child)': {
            //   marginRight: '5px',
            // },
            minWidth: 'initial',
            width: '100%',
            [theme.breakpoints.between('sm', 'md')]: {
                minWidth: 'initial',
                width: '100%',
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: theme.isFlyingFlowers ? '254px' : '248px',
                maxWidth: theme.isFlyingFlowers ? '254px' : '248px',
                height: theme.isFlyingFlowers ? '108px' : '104px',
                justifyContent: 'initial',
                boxShadow: theme.isFlyingFlowers
                    ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
                    : 'unset',
                // '&:not(:last-child)': {
                //   marginRight: theme.isFlyingFlowers ? spacing.small : spacing.medium,
                // },
            },
        },
    },
    pccDatePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        width: 'initial',
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            width: 'initial',
            margin: '0 82px 84px 82px',
            padding: '0 !important',
            position: 'initial',
            top: 'initial',
            zIndex: 'initial',
            overflow: 'initial',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 144px 84px 144px',
            width: '80%',
            padding: '0 !important',
            position: 'initial',
            top: 'initial',
            zIndex: 'initial',
            overflow: 'initial',
        },
    },
}));
export default useStyles;
