import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    simpleGalleryLink: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, [theme.breakpoints.down('md')]: {
            '&:not(:last-child):after': { content: "'|'", margin: '0 12px' },
        }, [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginTop: spacing.large, '&:first-child': {
                marginTop: 0,
            } }) }),
    linkTitle: Object.assign(Object.assign({}, theme.typography.large_bold), { [theme.breakpoints.only('xs')]: {
            marginBottom: 0,
        } }),
    container: Object.assign(Object.assign({ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }, hideScrollbars), { [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
        } }),
}));
export default useStyles;
