import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    linkContent: Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, '&:not(:last-child):after': { content: "'|'", margin: '0 12px' } }),
    relatedLinks: Object.assign(Object.assign({}, theme.typography.small_regular), { flex: '0 0 auto', [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.medium_regular) }),
    relatedTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.only('xs')]: {
            marginBottom: spacing.xxs,
        } }),
    linkContainer: Object.assign(Object.assign({ display: 'flex', whiteSpace: 'nowrap', marginBottom: spacing.xl, overflowX: 'auto' }, hideScrollbars), { [theme.breakpoints.up('xs')]: {
            flexFlow: 'row wrap',
        } }),
}));
export default useStyles;
