import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        width: '96px',
    },
    logo: {
        position: 'relative',
        background: theme.isFlyingFlowers ? 'none' : colors.supplementary.black,
        // Makes the element square
        width: '100%',
        paddingTop: theme.isFlyingFlowers ? undefined : '100%',
        display: 'flex',
        transition: 'padding 0.2s ease-in',
        // paddingBottom: theme.isFlyingFlowers ? '16px' : '0px',
        '& img': {
            position: theme.isFlyingFlowers ? undefined : 'absolute',
            display: 'inline-block',
            alignSelf: 'flex-end',
            width: '100%',
            height: 'auto',
            padding: theme.isFlyingFlowers ? '0px' : '0 10% 11.1% 10%',
            transition: 'padding 0.2s ease-in',
        },
    },
    band: {
        display: theme.isFlyingFlowers ? 'none' : 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        paddingTop: '11.1%',
        backgroundColor: colors.primary.gold4,
        transition: 'padding 0.2s ease-in',
    },
    condensed: {
        paddingTop: theme.isFlyingFlowers ? undefined : '65.7%',
        [`& .${classes.band}`]: {
            paddingTop: '7.3%',
        },
    },
}));
export default useStyles;
