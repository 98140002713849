import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params) => ({
    container: Object.assign(Object.assign({}, (!theme.isFlyingFlowers && {
        backgroundColor: `${colors.background[params === null || params === void 0 ? void 0 : params.backgroundTextColor]}`,
    })), { margin: '0 auto', position: 'relative', display: 'flex', textAlign: theme.isFlyingFlowers ? 'center' : 'left', flexDirection: 'column', width: '100%', boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : undefined }),
    imageContainer: {
        position: 'relative',
        paddingTop: '56%',
    },
    noImage: { [theme.breakpoints.down('sm')]: { display: 'none' } },
    image: {
        objectFit: 'cover',
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    textContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: spacing.medium,
        [theme.breakpoints.up('md')]: {
            padding: spacing.large,
        },
    },
    titleFF: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.medium_bold_lg), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    titleUK: Object.assign(Object.assign(Object.assign({}, (!theme.isFlyingFlowers && {
        color: `${colors.genericContentHeading[params === null || params === void 0 ? void 0 : params.headingColor]}`,
    })), theme.typography.large_bold), theme.typographySpacing.large_bold_sm),
    headingLine: {
        alignSelf: 'flex-start',
        display: 'block',
    },
    message: Object.assign(Object.assign(Object.assign({ flexGrow: 1 }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers && theme.typographySpacing.small_regular_lg)), [theme.breakpoints.up('xl')]: Object.assign({}, (theme.isFlyingFlowers && theme.typographySpacing.small_regular_sm)) }),
    linkContainer: { display: theme.isFlyingFlowers ? 'inline-flex' : '', flexDirection: 'column' },
    link: Object.assign(Object.assign({}, (theme.isFlyingFlowers && {
        width: '100%',
        display: 'flex',
    })), { '&:last-child': {
            whiteSpace: 'pre-wrap',
            marginBottom: 0,
            display: theme.isFlyingFlowers ? '' : 'block',
        }, '& button': {
            flexGrow: 1,
        } }),
    linkText: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    linkButton: {},
}));
export default useStyles;
