import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { FormikContext } from 'formik';
import { Box } from '@mui/material';
import clsx from 'clsx';
import useStyles from '../Checkout.styles';
import Button from '../../Button/Button';
import TextField from '../../TextField/TextField';
import { SiteContext, calculateTextLength } from '../../../utils/common';
import Alert from '../../Alert/Alert';
const EditCardMessageModal = (props) => {
    const { cardMessage, messageLength, errorMessage, setCardMessage, setMessageLength, handleClose, isSubmitting } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const formikContext = useContext(FormikContext);
    const maxLength = isFlyingFlowers ? 150 : 255;
    const handleCardMessage = (event) => {
        updateCardMessage(event.target.value);
    };
    const updateCardMessage = (msg) => {
        const charLength = calculateTextLength(msg);
        if (charLength <= maxLength) {
            setCardMessage(msg);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched('cardMessage', true, true);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('cardMessage', msg);
        }
        else
            return;
        setMessageLength(charLength);
    };
    return (_jsxs(_Fragment, { children: [cardMessage && messageLength && messageLength > 0 && (_jsxs(_Fragment, { children: [errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx(Box, { sx: { display: { zero: 'none', md: 'block' } }, children: _jsx(Button, { title: "Clear message", variant: "tertiary", className: clsx(classes.linkText, classes.clearMessageLink), onClick: () => updateCardMessage('') }) }), _jsx(Box, { sx: { display: { zero: 'block', md: 'none' } }, children: _jsx(Button, { title: "Clear", variant: "tertiary", className: clsx(classes.linkText, classes.clearMessageLink), onClick: () => updateCardMessage('') }) })] })), _jsxs("div", { children: [cardMessage && messageLength && messageLength > 0 && (_jsxs("p", { className: classes.characterCount, children: [messageLength, " / ", maxLength] })), _jsx(TextField, { name: "cardMessage", label: "Card message", placeholder: "Write here...", value: cardMessage, multiline: true, fullWidth: true, inputProps: { maxLength }, trimOnBlur: true, hideRequiredOptional: true, onChange: handleCardMessage, rows: 7, className: classes.cardMessageTitle })] }), _jsx("div", { className: classes.footer, children: _jsxs("div", { className: classes.actionGroup, children: [_jsx(Button, { onClick: handleClose, title: "Cancel", variant: "tertiary", thin: true, icon: isFlyingFlowers ? 'close' : '' }), _jsx(Button, { isSubmit: true, disabled: isSubmitting, title: "Save message", variant: "primary", thin: true, icon: isFlyingFlowers ? 'check' : '' })] }) })] }));
};
export default EditCardMessageModal;
