import { PDP_MADE_TO_ORDER } from '../../utils/common';
export const masterVariantData = {
    isMaster: true,
    sku: 'ARR02VSM',
    attributes: [
        { name: 'variantColor', value: 'Surprise Me' },
        { name: 'wasPrice', value: 'Was £65.00,Now £60.00' },
        { name: 'firstDeliveryMessage', value: 'First Delivery Date 19, September' },
        { name: 'lastDeliveryMessage', value: 'Last Delivery Date 23, October' },
    ],
    price: {
        currencyCode: 'GBP',
        centAmount: 6000,
        fractionDigits: 2,
        formatted: '£60.00',
    },
    images: [
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT1-1',
            altText: 'Image 1 of 3 of Hand-tied bouquet made with the finest flowers',
            label: 'Each design is one of a kind',
            badge: {
                image: {
                    url: 'https://cdn.media.amplience.net/i/interflora/HT1-1',
                    altText: '',
                },
                line1: 'FREE',
                variant: 'STANDARD',
            },
            secondaryBadge: {
                title: 'MOTHER`s DAY',
                variant: 'FF_WHILD_EXOTIC',
            },
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT8-1',
            altText: 'Image 2 of 3 of Hand-tied bouquet made with the finest flowers',
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT6-1',
            altText: 'Image 3 of 3 of Hand-tied bouquet made with the finest flowers',
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT6-1',
            altText: 'Image 3 of 3 of Hand-tied bouquet made with the finest flowers',
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT6-1',
            altText: 'Image 3 of 3 of Hand-tied bouquet made with the finest flowers',
        },
    ],
};
const masterVariantDataWithOutOfStockProduct = {
    isMaster: true,
    sku: 'ARR02VSM',
    attributes: [{ name: 'variantColor', value: 'Surprise Me' }],
    price: {
        currencyCode: 'GBP',
        centAmount: 6000,
        fractionDigits: 2,
        formatted: '£60.00',
    },
    images: [
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT1-1',
            altText: 'Image 1 of 3 of Hand-tied bouquet made with the finest flowers',
            label: 'Each design is one of a kind',
            badge: {
                image: {
                    url: '',
                    altText: '',
                },
                line1: 'Sold Out',
                variant: 'DEEP_ROSE',
            },
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT8-1',
            altText: 'Image 2 of 3 of Hand-tied bouquet made with the finest flowers',
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT6-1',
            altText: 'Image 3 of 3 of Hand-tied bouquet made with the finest flowers',
        },
    ],
};
const masterVariantDataWithOutOfStockProductFF = {
    isMaster: true,
    sku: 'ARR02VSM',
    attributes: [{ name: 'variantColor', value: 'Surprise Me' }],
    price: {
        currencyCode: 'GBP',
        centAmount: 6000,
        fractionDigits: 2,
        formatted: '£60.00',
    },
    images: [
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT1-1',
            altText: 'Image 1 of 3 of Hand-tied bouquet made with the finest flowers',
            label: 'Each design is one of a kind',
            badge: {
                image: {
                    url: '',
                    altText: '',
                },
                line1: 'Sold Out',
                variant: 'FF_GREY',
            },
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT8-1',
            altText: 'Image 2 of 3 of Hand-tied bouquet made with the finest flowers',
        },
        {
            url: 'https://cdn.media.amplience.net/i/interflora/HT6-1',
            altText: 'Image 3 of 3 of Hand-tied bouquet made with the finest flowers',
        },
    ],
};
const salesBadgeImageArray = [
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11271RS-s1',
        altText: '',
        ffSalesBadge: {
            contentLine1: 'Lore',
            contentLine2: 'lore',
            backgroundColor: 'FF_PLUSH_BLUSH',
            largeFontCheck: 'contentLine1',
        },
    },
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11271RS-s1',
        altText: '',
        ffSalesBadge: {
            contentLine1: 'Lore',
            contentLine2: 'lore',
            backgroundColor: 'FF_LAZY_LIME',
            largeFontCheck: 'contentLine2',
        },
    },
];
const imageArray = [
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11141RS-s1',
        altText: 'Lovely flowers',
        badge: {
            image: {
                url: 'https://i8.amplience.net/i/gradientedge/W11141RS-s1',
                altText: '',
            },
            altText: 'BBC Logo',
            line2: '5% donation',
        },
        secondaryBadge: {
            title: 'SECENTED',
            variant: 'GOLD',
        },
    },
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11271RS-s1',
        altText: '',
        secondaryBadge: {
            title: 'Christmas Flowers ABC',
            variant: 'GOLD',
        },
    },
    { url: 'https://i8.amplience.net/i/gradientedge/W11271RS-s1', altText: 'Amazing' },
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11151RS-s1',
        altText: '',
        badge: {
            image: {
                url: '',
                altText: '',
            },
            variant: 'DEEP_ROSE',
            altText: '',
            line1: 'Sold Out',
        },
        secondaryBadge: {
            title: 'NEW FOR MOTHER’S DAY',
            variant: 'GOLD',
        },
    },
    {
        url: 'https://i8.amplience.net/i/gradientedge/W11151RS-s1',
        altText: '',
        badge: {
            image: {
                url: '',
                altText: '',
            },
            variant: 'FF_GREY',
            altText: '',
            line1: 'Sold Out',
        },
        secondaryBadge: {
            title: 'NEW FOR MOTHER’S DAY',
            variant: 'FF_WHILD_EXOTIC',
        },
    },
];
export const cardData1 = {
    images: imageArray,
    url: 'https://www.interflora.co.uk/product',
    name: 'A lovely bouquet of fresh flowers',
    price: {
        currencyCode: 'GBP',
        centAmount: 3099,
        fractionDigits: 2,
        formatted: '£30.99',
    },
    description: 'test description1',
    slug: '/',
    variantColor: ['Neutrals', 'Brights', 'Pastels'],
    variantSize: [],
    uniqueServicePropositions: ['One of a kind', 'Hand tie'],
    categoryNames: ['All Flowers', 'Winter Florers'],
    categoryUsps: ['Chosen and created for you by a local florist', 'Chosen and created for you by a local florist1'],
    firstDeliveryMessage: 'First Delivery Date 19, September',
    lastDeliveryMessage: 'Last Delivery Date 23, October',
    wasPrice: 'Was £35.00, Now £30.99',
};
export const cardData2 = {
    images: imageArray,
    url: 'https://www.interflora.co.uk/product',
    name: 'Test product',
    price: {
        currencyCode: 'GBP',
        centAmount: 13099,
        fractionDigits: 2,
        formatted: '£130.99',
    },
    description: 'test description2',
    slug: '/',
    variantColor: ['Neutrals', 'Brights', 'Pastels'],
    variantSize: [],
    uniqueServicePropositions: ['One of a kind', 'Hand tie'],
    categoryNames: ['All Flowers', 'Winter Florers'],
    categoryUsps: ['Chosen and created for you by a local florist', 'Chosen and created for you by a local florist1'],
};
export const cardData3 = {
    images: imageArray,
    url: 'https://www.interflora.co.uk/product',
    name: 'Another lovely bouquet of fresh flowers',
    price: {
        currencyCode: 'GBP',
        centAmount: 300,
        fractionDigits: 2,
        formatted: '£3.00',
    },
    description: 'test description3',
    slug: '/',
    variantColor: ['Neutrals', 'Brights', 'Pastels'],
    variantSize: [],
    uniqueServicePropositions: ['One of a kind', 'Hand tie'],
    categoryNames: ['All Flowers', 'Winter Florers'],
    categoryUsps: ['Chosen and created for you by a local florist', 'Chosen and created for you by a local florist1'],
};
export const cardData4 = {
    images: [imageArray[0]],
    url: 'https://www.interflora.co.uk/product',
    name: 'Interflora Gold',
    price: {
        currencyCode: 'GBP',
        centAmount: 1200,
        fractionDigits: 2,
        formatted: '£12.00',
    },
    description: 'Free standard delivery (worth £7) for a year + £7 discount on any other delivery service.',
    slug: '/',
    variantColor: [],
    variantSize: [],
    uniqueServicePropositions: [],
    sku: 'INTERFLORAGOLD',
    categoryNames: [],
    categoryUsps: [],
};
export const cardData5 = {
    images: [imageArray[0]],
    url: 'https://www.interflora.co.uk/product',
    name: 'Interflora Platinum',
    price: {
        currencyCode: 'GBP',
        centAmount: 2200,
        fractionDigits: 2,
        formatted: '£22.00',
    },
    description: 'Free delivery on all delivery services for a year - including same day, international and 3-hour delivery services (worth up to £14).',
    slug: '/',
    variantColor: [],
    variantSize: [],
    uniqueServicePropositions: [],
    sku: 'INTERFLORAPLATINUM',
    categoryNames: [],
    categoryUsps: [],
};
export const cardData6 = {
    images: imageArray.slice(0, 1),
    id: 'c8c672af-7015-4d06-8d77-bc18b970e53c',
    sku: '1234',
    url: 'https://www.interflora.co.uk/product',
    name: 'Product Name Lorem Ipsum Dolor Sit Amet Consectetur',
    price: {
        currencyCode: 'GBP',
        centAmount: 13099,
        fractionDigits: 2,
        formatted: '£130.99',
    },
    description: '<p>If they enjoy chocolates, this will make the perfect addition to their gift. Surprise them with a stylish black box full of delightful bites! Box of 11 chocolates comprising of Dark, Milk and White chocolates.</p>',
    variants: [
        {
            isMaster: true,
            attributes: [
                { name: 'contains', value: ['Another thing', 'Something'] },
                { name: 'mayContain', value: ['And also this', 'May contain this'] },
                {
                    name: 'suitableFor',
                    value: ['Contains Alcohol - Yes', 'Suitable For Vegetarians - Yes', 'Suitable For Vegans - No '],
                },
                { name: 'allergenURL', value: 'https://google.com' },
            ],
        },
    ],
    categoryUsps: [],
};
export const cardData7 = Object.assign(Object.assign({}, cardData6), { sku: '4567', images: imageArray });
export const cardData8 = Object.assign(Object.assign({}, cardData1), { images: salesBadgeImageArray });
export const outOfStockProductData = {
    images: [imageArray[3]],
    url: 'https://www.interflora.co.uk/product',
    name: 'A lovely bouquet of fresh flowers',
    price: {
        currencyCode: 'GBP',
        centAmount: 2200,
        fractionDigits: 2,
        formatted: '£22.00',
    },
    description: 'Free delivery on all delivery services for a year - including same day, international and 3-hour delivery services (worth up to £14).',
    slug: '/',
    variantColor: [],
    variantSize: [],
    uniqueServicePropositions: [],
    sku: 'INTERFLORAPLATINUM',
    categoryNames: [],
    categoryUsps: [],
};
export const outOfStockProductDataFF = {
    images: [imageArray[4]],
    url: 'https://www.interflora.co.uk/product',
    name: 'A lovely bouquet of fresh flowers',
    price: {
        currencyCode: 'GBP',
        centAmount: 2200,
        fractionDigits: 2,
        formatted: '£22.00',
    },
    description: 'Free delivery on all delivery services for a year - including same day, international and 3-hour delivery services (worth up to £14).',
    slug: '/',
    variantColor: [],
    variantSize: [],
    uniqueServicePropositions: [],
    sku: 'INTERFLORAPLATINUM',
    categoryNames: [],
    categoryUsps: [],
};
export const productData = Array.from({ length: 3 }, () => cardData1).concat(Array.from({ length: 7 }, () => cardData2));
export const ProductDeliveryPassData = [cardData4, cardData5];
export const productDetailProductData = {
    id: 'c8c672af-7015-4d06-8d77-bc18b970e53c',
    name: 'Arrangement made with the finest flowers',
    url: '/product/arr02',
    price: masterVariantData.price,
    images: masterVariantData.images,
    promotionalText: 'Free Delivery',
    variants: [
        masterVariantData,
        {
            isMaster: false,
            sku: 'ARR02VB',
            price: {
                currencyCode: 'GBP',
                centAmount: 7000,
                fractionDigits: 2,
                formatted: '£70.00',
            },
            images: [
                Object.assign(Object.assign({}, masterVariantData.images[1]), { badge: {
                        image: {
                            url: '/images/badges/placeholder.svg',
                            altText: '',
                        },
                        line1: 'New in',
                    }, secondaryBadge: {
                        title: 'SCENTED',
                        variant: 'FF_CHARCOL',
                    } }),
                masterVariantData.images[2],
            ],
            attributes: [
                { name: 'variantColor', value: 'Brights' },
                { name: 'openPrice', value: true },
            ],
        },
        {
            isMaster: false,
            sku: 'ARR02VP',
            price: masterVariantData.price,
            images: [masterVariantData.images[1]],
            attributes: [{ name: 'variantColor', value: 'Pastels' }],
        },
        {
            isMaster: false,
            sku: 'ARR02VN',
            price: masterVariantData.price,
            images: [masterVariantData.images[0]],
            attributes: [{ name: 'variantColor', value: 'Neutrals' }],
        },
    ],
    attributes: [
        { name: 'daysAvailable', value: ['Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'] },
        { name: 'promotionalText', value: 'Vase Included' },
        { name: 'productTypeIdentifiers', value: ['Scented'] },
        { name: 'sameDay', value: true },
        { name: 'isFloristChoice', value: true },
        {
            name: 'keyProductMessaging',
            value: [
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
            ],
        },
    ],
};
const priceAttr = { price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 6000, formatted: '£60.00' }) };
const addAttributesClassic = Object.assign({ attributes: [
        ...productDetailProductData.variants[0].attributes,
        { name: 'variantSize', value: 'Small' },
        { name: 'variantStyle', value: 'Classic' },
    ] }, priceAttr);
const addAttributesTrending = Object.assign({ attributes: [
        ...productDetailProductData.variants[0].attributes,
        { name: 'variantSize', value: 'Small' },
        { name: 'variantStyle', value: 'Trending' },
    ] }, priceAttr);
export const productDetailProductDataWithStyleVariants = Object.assign(Object.assign({}, productDetailProductData), { variants: [
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), addAttributesClassic),
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), addAttributesTrending),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), addAttributesClassic),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), addAttributesTrending),
        Object.assign(Object.assign({}, productDetailProductData.variants[2]), addAttributesClassic),
        Object.assign(Object.assign({}, productDetailProductData.variants[2]), addAttributesTrending),
    ] });
export const productDetailOutOfStockProductData = {
    id: 'c8c672af-7015-4d06-8d77-bc18b970e53c',
    name: 'Arrangement made with the finest flowers',
    url: '/product/arr02',
    price: masterVariantData.price,
    images: masterVariantData.images,
    variants: [
        masterVariantDataWithOutOfStockProduct,
        {
            isMaster: false,
            sku: 'ARR02VB',
            price: {
                currencyCode: 'GBP',
                centAmount: 7000,
                fractionDigits: 2,
                formatted: '£70.00',
            },
            images: [
                Object.assign(Object.assign({}, masterVariantData.images[1]), { badge: {
                        image: {
                            url: '',
                            altText: '',
                        },
                        line1: 'Sold Out',
                        variant: 'DEEP_ROSE',
                    } }),
                masterVariantData.images[2],
            ],
            attributes: [{ name: 'variantColor', value: 'Brights' }],
        },
        {
            isMaster: false,
            sku: 'ARR02VP',
            price: masterVariantData.price,
            images: [masterVariantData.images[1]],
            attributes: [{ name: 'variantColor', value: 'Pastels' }],
        },
        {
            isMaster: false,
            sku: 'ARR02VN',
            price: masterVariantData.price,
            images: [masterVariantData.images[0]],
            attributes: [{ name: 'variantColor', value: 'Neutrals' }],
        },
    ],
    attributes: [
        { name: 'daysAvailable', value: ['Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'] },
        { name: 'promotionalText', value: 'Vase Included' },
        { name: 'productTypeIdentifiers', value: ['Scented'] },
        { name: 'sameDay', value: true },
    ],
    isProductOutOfStock: true,
};
export const productDetailOutOfStockProductDataFF = {
    id: 'c8c672af-7015-4d06-8d77-bc18b970e53c',
    name: 'Arrangement made with the finest flowers',
    url: '/product/arr02',
    price: masterVariantData.price,
    images: masterVariantData.images,
    variants: [
        masterVariantDataWithOutOfStockProductFF,
        {
            isMaster: false,
            sku: 'ARR02VB',
            price: {
                currencyCode: 'GBP',
                centAmount: 7000,
                fractionDigits: 2,
                formatted: '£70.00',
            },
            images: [
                Object.assign(Object.assign({}, masterVariantData.images[1]), { badge: {
                        image: {
                            url: '',
                            altText: '',
                        },
                        line1: 'Sold Out',
                        variant: 'FF_GREY',
                    } }),
                masterVariantData.images[2],
            ],
            attributes: [{ name: 'variantColor', value: 'Brights' }],
        },
        {
            isMaster: false,
            sku: 'ARR02VP',
            price: masterVariantData.price,
            images: [masterVariantData.images[1]],
            attributes: [{ name: 'variantColor', value: 'Pastels' }],
        },
        {
            isMaster: false,
            sku: 'ARR02VN',
            price: masterVariantData.price,
            images: [masterVariantData.images[0]],
            attributes: [{ name: 'variantColor', value: 'Neutrals' }],
        },
    ],
    attributes: [
        { name: 'daysAvailable', value: ['Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'] },
        { name: 'promotionalText', value: 'Vase Included' },
        { name: 'productTypeIdentifiers', value: ['Scented'] },
        { name: 'sameDay', value: true },
    ],
    isProductOutOfStock: true,
};
export const productFuneralDetailData = Object.assign(Object.assign({}, productDetailProductData), { isFuneral: true, variants: [
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), { attributes: [
                ...productDetailProductData.variants[0].attributes,
                { name: 'variantSize', value: 'Small' },
                { name: 'openPrice', value: true },
            ], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 6000, formatted: '£60.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), { attributes: [...productDetailProductData.variants[0].attributes, { name: 'variantSize', value: 'Medium' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 8000, formatted: '£80.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), { attributes: [...productDetailProductData.variants[0].attributes, { name: 'variantSize', value: 'Large' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 10000, formatted: '£100.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[0]), { attributes: [
                ...productDetailProductData.variants[0].attributes,
                { name: 'variantSize', value: PDP_MADE_TO_ORDER },
            ], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 0, formatted: '£0.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), { attributes: [...productDetailProductData.variants[1].attributes, { name: 'variantSize', value: 'Small' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 6000, formatted: '£60.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), { attributes: [...productDetailProductData.variants[1].attributes, { name: 'variantSize', value: 'Medium' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 8000, formatted: '£80.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), { attributes: [...productDetailProductData.variants[1].attributes, { name: 'variantSize', value: 'Large' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 10000, formatted: '£100.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[1]), { attributes: [
                ...productDetailProductData.variants[1].attributes,
                { name: 'variantSize', value: PDP_MADE_TO_ORDER },
            ], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 2000, formatted: '£20.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[2]), { attributes: [...productDetailProductData.variants[2].attributes, { name: 'variantSize', value: 'Only One Size' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 6000, formatted: '£60.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[3]), { attributes: [...productDetailProductData.variants[3].attributes, { name: 'variantSize', value: 'Small' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 3000, formatted: '£30.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[3]), { attributes: [...productDetailProductData.variants[3].attributes, { name: 'variantSize', value: 'Medium' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 4000, formatted: '£40.00' }) }),
        Object.assign(Object.assign({}, productDetailProductData.variants[3]), { attributes: [...productDetailProductData.variants[3].attributes, { name: 'variantSize', value: 'Large' }], price: Object.assign(Object.assign({}, masterVariantData.price), { centAmount: 5000, formatted: '£50.00' }) }),
    ] });
export const updatedDetailData = {
    id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
    key: 'VASE01',
    slug: 'vase01',
    url: '/product/vase01',
    name: 'Vase made with the finest flowers',
    description: "<strong>Each and every design is one-of-a-kind, created by an artisan florist using the freshest stems available on the day so, whilst your gift will look different to the one pictured, it'll still be just as lovely.</strong> <br/> <br/> <u>About this gift:</u> <ul> <li>Hand-tied flowers perfectly pre-arranged and delivered in a stylish vase</li> <li>Arrives ready to display for minimal fuss and maximum impact</li> <li>Includes a beautiful vase they can use time and time again</li> <li>Contactless delivery for your peace of mind</li> </ul><ol> <li>Hand-tied flowers perfectly pre-arranged and delivered in a stylish vase</li> <li>Arrives ready to display for minimal fuss and maximum impact</li> <li>Includes a beautiful vase they can use time and time again</li> <li>Contactless delivery for your peace of mind</li> </ol> <br/> Good to know... <br /> This gift may contain lilies. Not your cup of tea? Take a look at our <u><a href='/category/lily-free-bouquets'>lily free gifts.</a></u>",
    metaDescription: "<strong>Each and every design is one-of-a-kind, created by an artisan florist using the freshest stems available on the day so, whilst your gift will look different to the one pictured, it�ll still be just as lovely.</strong> <br/> <br/> <u>About this gift:</u> <ul> <li>Hand-tied flowers perfectly pre-arranged and delivered in a stylish vase</li> <li>Arrives ready to display for minimal fuss and maximum impact</li> <li>Includes a beautiful vase they can use time and time again</li> <li>Contactless delivery for your peace of mind</li> </ul> <br/> Good to know... <br /> This gift may contain lilies. Not your cup of tea? Take a look at our <u><a href='/category/lily-free-bouquets'>lily free gifts.</a></u>",
    variants: [
        masterVariantData,
        {
            sku: 'VASE1',
            isMaster: true,
            name: 'Vase made with the finest flowers',
            price: {
                currencyCode: 'GBP',
                centAmount: 4000,
                fractionDigits: 2,
                formatted: '£40.00',
            },
            images: [
                {
                    // __typename: 'ProductImage',
                    url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                    altText: 'Each design is one-of-a-kind',
                    label: 'Each design is one-of-a-kind',
                    secondaryBadge: {
                        title: 'NEW FOR MOTHER’S DAY',
                        variant: 'GOLD',
                    },
                },
                {
                    // __typename: 'ProductImage',
                    url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                    altText: 'Image 2 of 3 of Vase made with the finest flowers',
                    label: '',
                    secondaryBadge: {
                        title: 'SECENTED',
                        variant: 'DARK_GREY',
                    },
                },
                {
                    // __typename: 'ProductImage',
                    url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                    altText: 'Lorem ipsum dolor sit amet, consectetur',
                    label: 'Lorem ipsum dolor sit amet, consectetur',
                },
            ],
            attributes: [
                { name: 'variantColor', value: 'Blue' },
                {
                    name: 'occasions',
                    value: [
                        'Good Luck',
                        'New Home',
                        'New Baby',
                        'Retirement,Surprise',
                        'Get Well Soon',
                        "I'm Sorry",
                        'Congratulations',
                        'Thank You',
                        'Romance',
                        'Anniversary',
                        'Birthday',
                    ],
                },
                { name: 'flowerName', value: ['Petunia', 'Lily'] },
                { name: 'flowerColor', value: [] },
                { name: 'contains', value: ['Another thing', 'Something'] },
                { name: 'mayContain', value: ['And also this', 'May contain this'] },
                {
                    name: 'suitableFor',
                    value: ['Contains Alcohol - Yes', 'Suitable For Vegetarians - Yes', 'Suitable For Vegans - No '],
                },
                { name: 'allergenURL', value: 'https://google.com' },
                {
                    name: 'giftContents',
                    value: [
                        {
                            image: {
                                url: 'https://cdn.media.amplience.net/i/interflora/HT1-6',
                                altText: 'HT1-6',
                            },
                            description: 'This is a test description for the hand crafted gift content item',
                        },
                        {
                            image: {
                                url: 'https://cdn.media.amplience.net/i/interflora/MD-gifts-feature-hub',
                                altText: 'MD-gifts-feature-hub',
                            },
                            description: 'This is our promise',
                        },
                        {
                            image: {
                                url: 'https://cdn.media.amplience.net/i/interflora/INTERFLORA060121-0136-hub',
                                altText: 'INTERFLORA060121-0136-hub',
                            },
                            description: 'Hand delivered by our florists',
                        },
                        {
                            image: {
                                url: 'https://cdn.media.amplience.net/i/interflora/acorn_cottage_(1)',
                                altText: 'acorn_cottage_(1)',
                            },
                            description: 'Trust Us with your bouquet creation and delivery',
                        },
                    ],
                },
            ],
        },
        {
            // __typename: 'ProductVariant',
            // id: '2',
            // key: 'VASE1B',
            sku: 'VASE1B',
            isMaster: false,
            name: 'Variant B for this product',
            price: {
                // __typename: 'Price',
                currencyCode: 'GBP',
                centAmount: 3400,
                fractionDigits: 2,
                formatted: '£34.00',
            },
            images: [
                {
                    // __typename: 'ProductImage',
                    url: 'https://cdn.media.amplience.net/i/interflora/INTERFLORA060121-0434-hub',
                    altText: 'Second Variant',
                    label: 'Second Variant',
                    badge: {
                        image: {
                            url: 'https://cdn.media.amplience.net/i/interflora/placeholder',
                            altText: '',
                        },
                        line2: 'Endorsed by',
                        altText: 'Very Important Person',
                        variant: 'LAVENDER_LARGE',
                    },
                },
            ],
            attributes: [
                { name: 'variantSize', value: 'Large' },
                { name: 'variantColor', value: 'Red' },
                {
                    name: 'occasions',
                    value: ['Christmas', 'Valentines', 'Birthdays'],
                },
                { name: 'flowerName', value: ['Lilac', 'Rose'] },
                { name: 'flowerColor', value: ['Blue', 'Yellow'] },
                { name: 'contains', value: ['Another thing', 'Something'] },
                { name: 'mayContain', value: ['And also this', 'May contain this'] },
                {
                    name: 'suitableFor',
                    value: ['Vegans', 'Vegetarians'],
                },
                { name: 'allergenURL', value: 'https://google.com' },
                {
                    name: 'giftContents',
                    value: '<p>Other</p>',
                },
            ],
        },
    ],
    price: {
        // __typename: 'Price',
        currencyCode: 'GBP',
        centAmount: 4000,
        fractionDigits: 2,
        formatted: '£40.00',
    },
    images: [
        {
            // __typename: 'ProductImage',
            url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
            altText: 'Each design is one-of-a-kind',
            label: 'Each design is one-of-a-kind',
            secondaryBadge: {
                title: 'NEW FOR MOTHER’S DAY',
                variant: 'GOLD',
            },
        },
        {
            // __typename: 'ProductImage',
            url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
            altText: 'Image 2 of 3 of Vase made with the finest flowers',
            label: '',
            secondaryBadge: {
                title: 'NEW FOR MOTHER’S DAY',
                variant: 'FF_COBALT',
            },
        },
        {
            // __typename: 'ProductImage',
            url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
            altText: 'Lorem ipsum dolor sit amet, consectetur',
            label: 'Lorem ipsum dolor sit amet, consectetur',
        },
    ],
    attributes: [
        { name: 'sameDay', value: true },
        { name: 'daysAvailable', value: [] },
        { name: 'deliveryTypes', value: [] },
        { name: 'promotionalText', value: 'Vase Included' },
        { name: 'containsAlcohol', value: false },
        { name: 'productTypeIdentifiers', value: ['Floral'] },
        { name: 'arrangementType', value: '' },
        { name: 'isFloristChoice', value: true },
        {
            name: 'giftDetails',
            value: [
                'Ut lorem ipsum cursus molestie mauris. - find out more',
                'Malesuada tempus neque donec.',
                'Quis massa amet diam id consectetur.',
                'Egestas proin nunc nec diam arcu.',
            ],
        },
        {
            name: 'keyProductMessaging',
            value: [
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
                {
                    image: { url: 'https://interflora.a.bigcontent.io/v1/static/test-logo.svg', altText: 'test' },
                    message: 'Maximum 32 characters (advised)',
                },
            ],
        },
    ],
    wasPrice: 'Was £## Now £##',
};
export const idpProduct = {
    id: 'daf34411-5bad-4fb0-9e7b-1c5348c0c073',
    key: 'INTERFLORAGOLD0',
    slug: 'interflora-gold-interfloragold0',
    sku: 'INTERFLORAGOLD',
    typeKey: 'digital',
    url: '/product/interflora-gold-interfloragold0',
    name: 'Interflora Gold',
    description: "<p><span style=\"font-size: 11.0pt; font-family: 'Calibri',sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: EN-GB; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;\">Unlimited free next day delivery.<em><strong></strong></em><strong></strong></span></p>",
    metaDescription: '',
    price: {
        currencyCode: 'GBP',
        centAmount: 1200,
        fractionDigits: 2,
        formatted: '£12.00',
    },
    variants: [
        {
            sku: 'INTERFLORAGOLD',
            key: 'INTERFLORAGOLD',
            name: 'Interflora Gold',
            isMaster: true,
            price: {
                currencyCode: 'GBP',
                centAmount: 1200,
                fractionDigits: 2,
                formatted: '£12.00',
            },
            images: [
                {
                    url: 'https://media.interflora.co.uk/i/interflora/INTERFLORAGOLD-1.jpg',
                    label: '',
                    altText: 'Image 1 of 1 of Interflora Gold',
                },
            ],
            attributes: [
                {
                    name: 'name',
                    value: 'Interflora Gold',
                },
                {
                    name: 'orderFrom',
                    value: '2013-12-01',
                },
                {
                    name: 'orderTo',
                    value: '2050-12-31',
                },
                {
                    name: 'rotation',
                    value: '108',
                },
                {
                    name: 'specialIndicator',
                    value: 'GOL',
                },
                {
                    name: 'irelandSku',
                    value: 'INTERFLORAGOLDIE',
                },
            ],
        },
    ],
    categoryNames: ['Occasion Flowers', 'Just Because Flowers'],
    attributes: updatedDetailData.attributes,
    images: [
        {
            url: 'https://media.interflora.co.uk/i/interflora/INTERFLORAGOLD-1.jpg',
            label: '',
            altText: 'Image 1 of 1 of Interflora Gold',
        },
    ],
    isInternational: false,
    isOverseas: false,
    wasPrice: '',
    isProductOutOfStock: false,
};
export const productListingData = [
    {
        sku: 'H64671MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        wasPrice: 'Was £55.00, Now £40.00',
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'Christmas Flowers ABC',
                    variant: 'GOLD',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: idpProduct.sku,
        name: idpProduct.name,
        url: idpProduct.url,
        price: idpProduct.price,
        images: idpProduct.images,
        description: 'test description',
        slug: idpProduct.slug,
        variantColor: [],
        variantSize: [],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: [],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'H64801MS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers2',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
                secondaryBadge: {
                    title: 'secnted',
                    variant: 'DARK_GREY',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'ARRN3T',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'C15331PS',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'C15331PX',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'Test1',
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 4000,
            fractionDigits: 2,
            formatted: '£40.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
    {
        sku: 'test10',
        name: 'Another vase',
        url: '/product2',
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 3400,
            fractionDigits: 2,
            formatted: '£34.00',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/INTERFLORA060121-0434-hub',
                altText: 'Second Vase',
                label: 'Second Vase',
                badge: {
                    image: {
                        url: '/images/badges/placeholder.svg',
                        altText: '',
                    },
                    line2: 'As seen on',
                    altText: 'BBC Logo',
                },
                secondaryBadge: {
                    title: 'NEW FOR MOTHER’S DAY',
                    variant: 'DARK_GREY',
                },
            },
        ],
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
    },
];
export const funeralDetailData = Object.assign(Object.assign({}, updatedDetailData), { variants: [...updatedDetailData.variants], isFuneral: true, description: `
    <h3>Approximate dimensions:</h3>
    <h4>Standard - 15 stems</h4>
    <p>Height 20cm x Width 30cm</p>
    <h4>Large - 15 stems</h4>
    <p>Height 25cm x Width 35cm</p>
    <h4>Extra Large - 15 stems</h4>
    <p>Height 25cm x Width 40cm</p>
  `, attributes: [
        { name: 'sameDay', value: true },
        { name: 'daysAvailable', value: [] },
        { name: 'deliveryTypes', value: [] },
        { name: 'promotionalText', value: '' },
        { name: 'containsAlcohol', value: false },
        { name: 'productTypeIdentifiers', value: ['Floral'] },
        { name: 'arrangementType', value: '' },
        {
            name: 'giftDetails',
            value: [
                'Includes large-headed roses, freesias, lisianthus and spray chrysanthemums',
                'A classic selection presented in a posy design',
                'Hand-crafted by an artisan florist',
                'Free delivery by a local florist at a time you choose',
                'Kind-to-the-planet packaging',
                'Personalise this tribute - add a personal message and the florist will hand-write it on a card with the tribute',
                'The florist will liase with the chosen Funeral Director to make sure the tribute arrives at the right time',
            ],
        },
    ] });
export const flyingFlowersProductListingData = [
    {
        id: '6f180851-22e2-4150-ab72-9e77500fc1e7',
        url: '/product/vase01',
        name: 'Vase made with the finest flowers',
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 2599,
            fractionDigits: 2,
            formatted: '£25.99',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                altText: 'Each design is one-of-a-kind',
                label: 'Each design is one-of-a-kind',
                badge: {
                    image: {
                        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
                        altText: '',
                    },
                    line1: 'Limited',
                    line2: 'Availability',
                },
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-3',
                altText: 'Image 2 of 3 of Vase made with the finest flowers',
                label: '',
            },
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/HT1-2',
                altText: 'Lorem ipsum dolor sit amet, consectetur',
                label: 'Lorem ipsum dolor sit amet, consectetur',
            },
        ],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
        wasPrice: 'Save £5 Was £30.99',
        isProductOutOfStock: true,
    },
    {
        name: 'Another vase',
        url: '/product2',
        price: {
            // __typename: 'Price',
            currencyCode: 'GBP',
            centAmount: 3599,
            fractionDigits: 2,
            formatted: '£35.99',
        },
        images: [
            {
                // __typename: 'ProductImage',
                url: 'https://cdn.media.amplience.net/i/interflora/INTERFLORA060121-0434-hub',
                altText: 'Second Vase',
                label: 'Second Vase',
                badge: {
                    image: {
                        url: '/images/badges/placeholder.svg',
                        altText: '',
                    },
                    line2: 'As seen on',
                    altText: 'BBC Logo',
                },
            },
        ],
        description: 'test description',
        slug: '/',
        variantColor: ['Neutrals', 'Brights', 'Pastels'],
        variantSize: [],
        uniqueServicePropositions: ['One of a kind', 'Hand tie'],
        categoryNames: ['All Flowers', 'Winter Florers'],
        categoryUsps: ['Chosen and created for you by a local florist'],
        wasPrice: 'Save £5 Was £40.99',
        isProductOutOfStock: true,
    },
];
export const spotlightProductCardData1 = {
    image: {
        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
        altText: '',
    },
    promoText: 'Lorem ipsum dolor sit amet, consecte loremipsd',
    // cardLink: '/category/flower-arrangements/lily-free-bouquets',
    backgroundColor: 'RED',
    links: [
        { label: 'Link1', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link2', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link3', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link4', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
    ],
    columnWidth: '1',
};
export const spotlightProductCardData2 = {
    image: {
        url: 'https://cdn.media.amplience.net/i/interflora/VASE1-1',
        altText: '',
    },
    promoText: 'Lorem ipsum dolor sit amet, consecte',
    cardLink: '/category/flower-arrangements/lily-free-bouquets',
    backgroundColor: 'RED',
    links: [
        { label: 'Link1', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link2', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link3', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
        { label: 'Link4', url: '/my-link', highlight: false, newWindow: true, noFollow: true },
    ],
    columnWidth: '2',
};
export const idpCardData = {
    images: idpProduct.images,
    url: 'https://www.interflora.co.uk/product',
    name: idpProduct.name,
    price: idpProduct.price,
    description: 'Free delivery on all delivery services for a year - including same day, international and 3-hour delivery services (worth up to £14).',
    slug: idpProduct.slug,
    variantColor: [],
    variantSize: [],
    uniqueServicePropositions: [],
    sku: idpProduct.sku,
    categoryNames: [],
    categoryUsps: [],
};
