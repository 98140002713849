import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import NextImage from 'next/image'
import NextImage from 'next/image';
import { useTheme } from '@mui/material';
// We need to provide the images with blured url to tackle CLS
const shimmer = (w, h) => `
    <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
      </defs>
      <rect width="${w}" height="${h}" fill="#eee" />
      <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
      <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    </svg>`;
const toBase64 = (str) => typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);
const ResponsiveImage = (props) => {
    var _a, _b, _c;
    const { imageUrl, altText, className, width, height, draggable = false, onClick, layout, priority, loading } = props;
    const theme = useTheme();
    const sizes = (_a = props.sizes) === null || _a === void 0 ? void 0 : _a.map((size) => size.query && size.breakpoint
        ? `(${size.query}: ${theme.breakpoints.values[size.breakpoint]}px) ${size.width}`
        : `${size.width}`).join(', ');
    return (_jsx(NextImage, Object.assign({ src: imageUrl, alt: altText, sizes: sizes, width: width, height: height, className: className, onClick: onClick, draggable: draggable, unoptimized: true, priority: priority }, (priority && { fetchPriority: 'high' }), { placeholder: 'blur', blurDataURL: `data:image/svg+xml;base64,${toBase64(shimmer(parseInt((_b = width === null || width === void 0 ? void 0 : width.toString()) !== null && _b !== void 0 ? _b : '100%', 10), parseInt((_c = height === null || height === void 0 ? void 0 : height.toString()) !== null && _c !== void 0 ? _c : '100%', 10)))}`, style: {
            maxWidth: width && '100%',
            height: height && 'inherit',
        }, fill: !(width && height), loading: loading })));
};
const ArtDirectedImage = (props) => {
    var _a, _b;
    const { imageUrl, images, altText, className, width, height, draggable = false, onClick, loading, priority } = props;
    const theme = useTheme();
    return (_jsx("button", { className: className, onClick: onClick, children: _jsxs("picture", { children: [images === null || images === void 0 ? void 0 : images.map((image) => (_jsx("source", { media: image.query && image.breakpoint && `(${image.query}: ${theme.breakpoints.values[image.breakpoint]}px)`, srcSet: image.filename, sizes: image.sizes }, `${image.filename}-${image.query}-${image.breakpoint}`))), _jsx(NextImage, { src: imageUrl, alt: altText, draggable: draggable, priority: priority, unoptimized: true, width: width, height: height, placeholder: 'blur', blurDataURL: `data:image/svg+xml;base64,${toBase64(shimmer(parseInt((_a = width === null || width === void 0 ? void 0 : width.toString()) !== null && _a !== void 0 ? _a : '100%', 10), parseInt((_b = height === null || height === void 0 ? void 0 : height.toString()) !== null && _b !== void 0 ? _b : '100%', 10)))}`, fill: !(width && height), loading: loading })] }) }));
};
/** Use either imageUrl, imageWidths and sizes for a responsive image with different widths,
 * or images for an art directed image (wrapped in picture), with different urls at different breakpoints. */
const Image = (props) => { var _a; return ((_a = props.images) === null || _a === void 0 ? void 0 : _a.length) ? _jsx(ArtDirectedImage, Object.assign({}, props)) : _jsx(ResponsiveImage, Object.assign({}, props)); };
export default Image;
