import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    viewBasketHeading: Object.assign(Object.assign({}, theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.large_bold_lg) }),
    message: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_lg) }),
    idpAddToBasketButton: {
        marginBottom: spacing.xl,
        [theme.breakpoints.up('md')]: {
            maxWidth: '520px',
            maxHeight: '44px',
        },
    },
    modalActionContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& button:first-child': {
            marginBottom: spacing.small,
        },
    },
    idpModal: {
        '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
                padding: `${spacing.xl} 40px`,
                maxWidth: '792px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0 96px 0 96px !important',
                },
            },
        },
        '& header': Object.assign(Object.assign({ '& h3': {
                marginBottom: '0px',
            } }, theme.typography.medium_regular), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({ marginLeft: '96px' }, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    },
}));
export default useStyles;
