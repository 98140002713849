import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { processImageUrl } from '../../utils/image';
import Image from '../Image/Image';
import useStyles from './ProductBadge.styles';
const ProductBadge = (props) => {
    var _a;
    const { variant, image, line1, line2, altText, small, icon } = props;
    const { classes } = useStyles();
    const variantClass = variant === null || variant === void 0 ? void 0 : variant.toUpperCase();
    const showLine2 = !(image === null || image === void 0 ? void 0 : image.url) ? line1 && line2 : !!line2;
    return (_jsxs("div", { className: clsx(classes.container, small && classes.containerSmall, variantClass && classes[variantClass]), children: [icon && (_jsx(Image, { imageUrl: icon.url, altText: (_a = icon === null || icon === void 0 ? void 0 : icon.altText) !== null && _a !== void 0 ? _a : '', className: classes.icon, width: '20', height: '19' })), line1 && _jsx("div", { className: classes.textLine1, children: line1 }), showLine2 && _jsx("div", { className: classes.textLine2, children: line2 }), image && image.url && (_jsx(Image, { imageUrl: image.url.startsWith('http') ? processImageUrl(image.url, { w: small ? 96 : 208 }) : image.url, altText: altText || 'Logo', className: classes.image, width: small ? '96' : '208', height: small ? '96' : '208', loading: "lazy" }))] }));
};
export default ProductBadge;
