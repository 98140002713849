import { makeStyles } from 'tss-react/mui';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        display: 'flex',
        padding: '11px 8px 9px 9px',
        marginRight: spacing.large,
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            padding: `14px 8px 14px 9px`,
            marginRight: spacing.medium,
        },
        [theme.breakpoints.down('xl')]: Object.assign({ overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: '340px', marginRight: 0 }, hideScrollbars),
    },
    trustpilotContainer: {
        backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
        display: 'flex',
        justifyContent: 'center',
        padding: `10px ${spacing.xs}`,
    },
    carousel: {
        margin: '0 auto',
        width: '92vw',
        [theme.breakpoints.up('sm')]: {
            width: '442px',
        },
        [theme.breakpoints.up('md')]: {
            width: '532px',
        },
        '& .react-multi-carousel-track': {
            height: '40px',
        },
        '& .react-multi-carousel-dot button': {
            width: '12px',
            height: '12px',
            marginRight: '12px',
            borderWidth: '1px',
            backgroundColor: colors.grey.grey3,
        },
        '& .react-multi-carousel-list': {
            height: '40px',
        },
        '& .react-multi-carousel-dot--active button': {
            backgroundColor: colors.grey.grey6,
        },
    },
    carouselGallery: {
        backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
    },
    carouselItem: {
        margin: 0,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    carouselContainer: {
        width: '100%',
    },
    leftButton: {
        left: 0,
    },
    rightButton: {
        right: 0,
    },
    customButtonGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        top: '50%',
        [`& .${classes.leftButton}, & .${classes.rightButton}`]: {
            backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
            position: 'absolute',
            '& button': {
                width: '24px',
                height: '24px',
                minHeight: '24px',
                backgroundColor: 'unset',
                transition: 'unset',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                    backgroundColor: 'unset',
                },
                '&.MuiFab-root': {
                    width: '24px',
                    height: '24px',
                    border: 0,
                },
                '& .MuiIcon-root': {
                    color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
                    width: 'unset',
                    height: 'unset',
                    fontSize: '24px !important',
                },
            },
        },
    },
    slider: {},
}));
export default useStyles;
