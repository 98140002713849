import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import clsx from 'clsx';
import Video from '../../Video/Video';
import useStyles from './VideoContainer.styles';
const ContentFeatureVideo = (props) => {
    const { classes } = useStyles();
    return (_jsx(Box, { className: clsx(classes.videoContainer, classes.squareVideoContainer), children: _jsx(Video, Object.assign({ video: Object.assign({}, props.video) }, props)) }));
};
export default ContentFeatureVideo;
