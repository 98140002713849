import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { default as NextLink } from 'next/link';
import useStyles from './MyAccountCards.styles';
import { myAccountMenuItems } from '../../common/data/menuItems.data';
import Icon from '../Icon/Icon';
import { useLoaded } from '../MainContent/useComponentLoading';
const MyAccountCards = (props) => {
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [_jsx("h2", { className: classes.yourAccountTitle, children: "Your account" }), _jsx("div", { className: classes.personalCardContainer, children: myAccountMenuItems
                    ? myAccountMenuItems.map((menuItem) => (_jsx("div", { className: classes.container, children: _jsx(NextLink, { href: menuItem.url, passHref: true, legacyBehavior: true, children: _jsxs("a", { children: [_jsx(Icon, { icon: menuItem.icon, className: classes.icon }), _jsx("h3", { className: classes.title, children: menuItem.title }), _jsx("p", { className: classes.body, children: menuItem.description })] }) }) }, menuItem.title)))
                    : null })] }));
};
export default MyAccountCards;
