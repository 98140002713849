var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState, useMemo } from 'react';
import EditContactAddressForm, { validationSchema as EditContactAddressValidationSchema, } from './EditContactAddressForm/EditContactAddressForm';
import AddressSearchForm, { validationSchema as AddressSearchValidationSchema, } from '../EditBillingAddress/AddressSearchForm/AddressSearchForm';
import useStyles from './EditContactAddress.style';
import { Form, Formik } from 'formik';
import { SiteContext } from '../../utils/common';
const EditContactAddress = (props) => {
    const [isManualAddress, setIsManualAddress] = useState(false);
    const { classes } = useStyles();
    const { countryCode, isFlyingFlowers } = useContext(SiteContext);
    const [hasFormError, setHasFormError] = useState(false);
    const { onSubmit, onCancel, firstName = '', lastName = '', phone = '', deliveryInstructionOption = '', deliveryInstructionNote = '', isDefaultAddress = false, addressLine1 = '', addressLine2 = '', addressLine3 = '', postalCode = '', country = '', city = '', county = '', trackEnterManualAddressClick, } = props;
    const isPhoneValidate = !!isFlyingFlowers;
    const showEditContactAddress = !isManualAddress && addressLine1;
    const initialValues = {
        firstName: firstName || '',
        lastName: lastName || '',
        phone: phone !== null && phone !== void 0 ? phone : '',
        addressSelection: '',
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
        addressLine3: addressLine3 || '',
        city: city || '',
        county: county || '',
        postalCode: postalCode !== null && postalCode !== void 0 ? postalCode : '',
        country: country !== null && country !== void 0 ? country : countryCode,
        isManualAddress: false,
        defaultAddress: isDefaultAddress,
        shouldValidatePhone: isPhoneValidate,
        deliveryInstructionOption: deliveryInstructionOption || '',
        deliveryInstructionNote: deliveryInstructionNote || '',
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setHasFormError(false);
        const response = yield onSubmit(values);
        if (response.error) {
            setHasFormError(true);
        }
    });
    const validationSchema = useMemo(() => (showEditContactAddress ? EditContactAddressValidationSchema : AddressSearchValidationSchema), [showEditContactAddress]);
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { className: classes.contactHeading, children: "Edit Contact" }), _jsx("div", { className: classes.box, children: _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsx(Form, { children: showEditContactAddress ? (_jsx(EditContactAddressForm, { onCancel: onCancel, toggleManualAddress: setIsManualAddress, isSubmitting: isSubmitting, isDefaultAddress: isDefaultAddress })) : (_jsx(AddressSearchForm, { onCancel: onCancel, isNewAddress: !addressLine1, hasFormError: hasFormError, isSubmitting: isSubmitting, isContactAddress: true, trackEnterManualAddressClick: trackEnterManualAddressClick })) })) })) })] }));
};
export default EditContactAddress;
