import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../Button/Button';
import useStyles from './AddressRemoveItems.styles';
const AddressRemoveItems = (props) => {
    const { address, handleRemoveItem, handleCancel } = props;
    const { firstName, lastName } = address;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsx("h3", { className: classes.title, children: "Remove address" }), _jsxs("p", { className: classes.itemText, children: ["Are you sure you want to remove ", firstName, "\u00A0", lastName, " from your address book?"] }), _jsx("p", { className: classes.confirmationText, children: "This action cannot be undone." }), _jsxs("div", { className: classes.buttonContainer, children: [_jsx(Button, { className: classes.button, variant: "secondary", title: "Yes - remove this address", fullWidth: true, thinOnMobile: true, onClick: handleRemoveItem }), _jsx(Button, { className: classes.button, variant: "tertiary", title: "No - do not delete", fullWidth: true, thinOnMobile: true, onClick: handleCancel })] })] }));
};
export default AddressRemoveItems;
