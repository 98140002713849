export const videoSource = [
    {
        birtrate: '1998',
        quality: 'high',
        videoType: 'video/mp4',
        deviceWidth: 'min-device-width',
        urlExtension: '/mp4_720p',
    },
    {
        birtrate: '1910',
        quality: 'high',
        videoType: 'video/webm',
        deviceWidth: 'min-device-width',
        urlExtension: '/webm_720p',
    },
    {
        birtrate: '586',
        quality: 'medium',
        videoType: 'video/webm',
        deviceWidth: 'max-device-width',
        urlExtension: '/webm_480p',
    },
    {
        birtrate: '606',
        quality: 'medium',
        videoType: 'video/mp4',
        deviceWidth: 'min-device-width',
        urlExtension: '/mp4_480p',
    },
    {
        birtrate: '326',
        quality: 'Low',
        videoType: 'video/mp4',
        deviceWidth: 'max-device-width',
        urlExtension: '/mp4_240p',
    },
];
