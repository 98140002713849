import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline, ThemeProvider } from '@mui/material';
import createSiteTheme from '../../styles/jss/theme';
const ThemeWrapper = (props) => {
    const { site, children } = props;
    const isFlyingFlowers = (site || process.env.NEXT_PUBLIC_SITE_KEY || process.env.SITE_KEY) === 'flyingflowers-co-uk';
    const theme = createSiteTheme(isFlyingFlowers);
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), children] }));
};
export default ThemeWrapper;
