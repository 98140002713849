import { makeStyles } from 'tss-react/mui';
import { carouselStyles } from '../../styles/jss/carousel';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => (Object.assign(Object.assign({ container: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    }, imageContainer: {
        height: '188px',
        [theme.breakpoints.up('sm')]: {
            height: '428px !important',
        },
        [theme.breakpoints.up('md')]: {
            height: '448px !important',
        },
        [theme.breakpoints.up('xl')]: {
            height: '620px !important',
        },
    }, defaultImage: {
        objectFit: 'cover',
        width: '100% !important',
        height: '188px !important',
        [theme.breakpoints.up('sm')]: {
            height: '428px !important',
        },
        [theme.breakpoints.up('md')]: {
            height: '448px !important',
        },
        [theme.breakpoints.up('xl')]: {
            height: '620px !important',
        },
    }, imageCardContainer: {
        width: '100%',
        margin: '0 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            width: '86%',
        },
    }, slider: {
        display: 'flex',
        flexDirection: 'row',
    }, carousel: {
        margin: '0 auto',
        padding: `0`,
    }, carouselItem: {
        margin: 0,
        display: 'flex',
    }, videoContainer: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
        },
        minHeight: '200px',
        width: '100%',
        position: 'relative',
        '& video': {
            cursor: 'pointer',
            height: '188px',
            display: 'block',
            objectFit: 'cover',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                height: '428px',
            },
            [theme.breakpoints.up('md')]: {
                height: '448px',
            },
            [theme.breakpoints.up('xl')]: {
                height: '620px',
            },
        },
    }, videoSubContainer: {
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '86%',
        },
    } }, carouselStyles(theme, true)), { imageTitle: Object.assign(Object.assign({ backgroundColor: colors.grey.grey1, color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, textAlign: theme.isFlyingFlowers ? 'center' : 'left', width: '100%', padding: theme.isFlyingFlowers ? '8px 16px !important' : '12px 16px !important' }, theme.typography.small_bold), theme.typographySpacing.none), icon: {
        fontSize: `100px`,
    }, controlsContainer: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '37%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.up('xs')]: {
            top: '40%',
        },
        [theme.breakpoints.up('sm')]: {
            top: '45%',
        },
        [`& .${classes.icon}`]: {
            fontSize: '5rem',
            color: colors.supplementary.white,
            cursor: 'pointer',
            marginRight: 0,
            '& span': {
                fontSize: 'inherit',
            },
        },
    } })));
export default useStyles;
