import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    welcomeText: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.xl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : undefined)), { marginBottom: theme.isFlyingFlowers ? undefined : '36px', color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_bold : undefined)), theme.typographySpacing.xl_bold_lg) }),
    signUpForm: {
        flex: '100%',
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            flex: '50%',
            marginRight: '81px',
        },
    },
    message: Object.assign(Object.assign({}, theme.typography.small_regular), { '& p': Object.assign(Object.assign({}, theme.typographySpacing.xl_bold_lg), { color: colors.supplementary.black, '&:last-child': Object.assign({}, theme.typographySpacing.none) }), '& ul': {
            '& li': {
                color: colors.grey.grey6,
                marginBottom: '10px',
                listStyle: 'disc',
            },
        }, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    signUpFormImage: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            flex: '50%',
            display: 'block',
        },
    },
    imgContainer: {
        width: '100%',
        marginBottom: spacing.xxs,
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '100%',
        },
    },
    newsletterSignUpContainer: {
        display: 'flex',
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    buttons: {
        marginTop: '52px',
    },
    saveButton: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '320px',
        },
    },
    error: { marginBottom: spacing.small },
}));
export default useStyles;
