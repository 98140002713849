const MAX_STORAGE_LIMIT = 5;
export const LOCAL_STORAGE_KEY = 'autocomplete_recent_searches';
export const getRecentSearches = () => {
    const recentSearches = localStorage.getItem(LOCAL_STORAGE_KEY);
    const parsedRecentSearches = recentSearches ? JSON.parse(recentSearches) : [];
    return parsedRecentSearches;
};
export const checkSearchValueAlreadyExists = (currenSearchValue) => {
    let previousSearches = getRecentSearches();
    if (!(previousSearches === null || previousSearches === void 0 ? void 0 : previousSearches.length))
        return false;
    return !!(previousSearches === null || previousSearches === void 0 ? void 0 : previousSearches.find((previousSearch) => previousSearch.toLowerCase() === currenSearchValue.toLowerCase()));
};
export const saveRecentSearches = (newSearchValue) => {
    const removeSpecialCharacters = newSearchValue.replace(/[^A-Za-z]+/g, ''); // to check only special characters or only spaces
    if (!(removeSpecialCharacters === null || removeSpecialCharacters === void 0 ? void 0 : removeSpecialCharacters.length))
        return;
    let previousSearches = getRecentSearches();
    if (!!(previousSearches === null || previousSearches === void 0 ? void 0 : previousSearches.length)) {
        const isSearchValueAlreadyExists = checkSearchValueAlreadyExists(newSearchValue);
        if (isSearchValueAlreadyExists)
            return;
        if (previousSearches.length === MAX_STORAGE_LIMIT) {
            previousSearches.splice(MAX_STORAGE_LIMIT - 1);
        }
    }
    previousSearches.unshift(newSearchValue);
    !!(previousSearches === null || previousSearches === void 0 ? void 0 : previousSearches.length) && localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(previousSearches));
};
