import { jsx as _jsx } from "react/jsx-runtime";
import _get from 'lodash/get';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
const PreloadedComponent = (props) => {
    const { componentId, componentKey, Component, ComponentProps } = props;
    const siteContext = useContext(SiteContext);
    const component = _get(siteContext.preloadedComponents, componentId || componentKey || '');
    return (component === null || component === void 0 ? void 0 : component.data) ? (_jsx(Component, Object.assign({}, component === null || component === void 0 ? void 0 : component.data, ComponentProps))) : (_jsx("p", { children: "The information you requested could not be retrieved. Please try again later." }));
};
export default PreloadedComponent;
