import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Script from 'next/script';
import React, { useContext, useState } from 'react';
import { SiteContext } from '../../utils/common';
import useStyles from './TrustpilotWidget.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
export const TEMPLATES = {
    STARTER: { templateId: '5613c9cde69ddc09340c6beb', props: {} },
    MINI_CAROUSEL: { templateId: '539ad0ffdec7e10e686debd7', props: { 'data-style-height': '350px' } },
    MICRO_COMBO: { templateId: '5419b6ffb0d04a076446a9af', props: { 'data-style-height': '20px' } },
    CAROUSEL: { templateId: '53aa8912dec7e10d38f59f36', props: { 'data-style-height': '140px' } },
    MICRO_TRUSTSCORE: {
        templateId: '5419b637fa0340045cd0c936',
        props: { 'data-style-height': '20px', 'data-style-width': '250px' },
    },
    MICRO_STAR: {
        templateId: '5419b732fbfb950b10de65e5',
        props: { 'data-style-height': '24px', 'data-style-width': '270px' },
    },
};
const TrustpilotWidget = (props) => {
    const { heading, template, noPointer, headingTag = 'H2' } = props;
    const { trustpilotBusinessUnitId, locale } = useContext(SiteContext);
    const { classes } = useStyles();
    const HeadingTag = headingTag === null || headingTag === void 0 ? void 0 : headingTag.toLowerCase();
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    const [safeToRender, setSafeToRender] = useState(false);
    React.useEffect(() => {
        setSafeToRender(true);
    }, []);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (safeToRender && window.Trustpilot) {
            try {
                window.Trustpilot.loadFromElement(ref.current, true);
            }
            catch (error) { }
        }
    }, [safeToRender]);
    const templateDetails = template && TEMPLATES[template];
    useLoaded(props);
    return templateDetails && trustpilotBusinessUnitId ? (_jsxs(_Fragment, { children: [_jsx(Script, { id: "trustpilot", strategy: "afterInteractive", src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js", async: true }), heading && _jsx(HeadingTag, { className: classes.header, children: heading }), safeToRender && (_jsx("div", Object.assign({ ref: ref, className: clsx('trustpilot-widget', template === 'CAROUSEL'
                    ? classes.box
                    : template !== 'MINI_CAROUSEL' && template !== 'MICRO_TRUSTSCORE' && template !== 'MICRO_STAR'
                        ? classes.other
                        : noPointer
                            ? classes.disableIframe
                            : null), id: "trust-pilot-id", "data-locale": locale || 'en-GB', "data-template-id": templateDetails.templateId, "data-businessunit-id": trustpilotBusinessUnitId, "data-theme": "light", "data-style-width": "100%", "data-style-height": "100%", "data-stars": "5", "data-review-languages": "en" }, templateDetails.props)))] })) : null;
};
export default TrustpilotWidget;
