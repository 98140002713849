import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { textAlign: 'center', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, paddingTop: 0, paddingBottom: 0, textTransform: 'initial', [theme.breakpoints.up('xl')]: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { paddingTop: 0, paddingBottom: 0 }) }),
    productPageHeading: {
        textAlign: 'left',
    },
    subheading: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { textAlign: theme.isFlyingFlowers ? 'center' : 'left' }),
}));
export default useStyles;
