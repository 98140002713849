import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()(() => ({
    container: {},
    input: {
        marginBottom: spacing.small,
    },
    error: { marginBottom: spacing.small },
    buttons: { display: 'flex', '& button:not(:last-child)': { marginRight: spacing.small } },
}));
export default useStyles;
