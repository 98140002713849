import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import FilterButton from '../FilterButton/FilterButton';
import FilterClearRefinements from '../FilterClearRefinements/FilterClearRefinements';
import useStyles from './FilterList.styles';
const FilterList = ({ filters, setFiltersClosed, totalResultCount, setFacetListOpen, sortBy, selectedFilter, setSelectedFilter, }) => {
    var _a;
    const { classes } = useStyles();
    const filterAttribute = filters === null || filters === void 0 ? void 0 : filters.find((filter) => filter.attributeName !== 'sortBy');
    const { attributeName: filterAttributeName, isOpen: filterIsOpen } = filterAttribute || filters[0];
    const filterButtonTitle = !!(selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.length) ? 'Filter' : 'Show filters';
    const sortAttributeIsOpen = ((_a = filters === null || filters === void 0 ? void 0 : filters.find((filter) => filter.attributeName === 'sortBy')) === null || _a === void 0 ? void 0 : _a.isOpen) || false;
    const filterText = !!(selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.length) ? classes.filterText : undefined;
    return (_jsxs("div", { className: classes.filterList, children: [_jsxs("div", { className: classes.filterAction, children: [_jsxs("span", { className: classes.itemCount, children: [totalResultCount, " items"] }), _jsx(FilterClearRefinements, { onClick: setFiltersClosed, setSelectedFilter: setSelectedFilter })] }), _jsx("div", { className: classes.listContainer, children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(FilterButton, { title: `Sort by: ${sortBy}`, onClick: () => setFacetListOpen('sortBy'), open: sortAttributeIsOpen, name: 'sortBy' }) }), _jsx("li", { children: _jsx(FilterButton, { title: filterButtonTitle, onClick: () => setFacetListOpen(filterAttributeName), open: filterIsOpen, name: filterAttributeName, icon: "tune", className: filterText }) })] }) })] }));
};
export default FilterList;
