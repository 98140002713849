import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { SiteContext } from '../../utils/common';
import Icon from '../Icon/Icon';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './FaqItem.styles';
const FaqItem = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { id, question, answer, onlyLinks, anchorKey } = props;
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const accordianRef = useRef(null);
    // On page load, scrollIntoView if hash in url
    useEffect(() => {
        var _a, _b;
        if (!!anchorKey && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hash) === `#${anchorKey}`) {
            (_b = accordianRef === null || accordianRef === void 0 ? void 0 : accordianRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
            setOpen(true);
        }
    }, [anchorKey]);
    const showAnswer = (id) => {
        const element = document.getElementById(`Q-${id}`);
        element === null || element === void 0 ? void 0 : element.click();
    };
    const icon = open && !isFlyingFlowers
        ? 'expand_less'
        : !open && !isFlyingFlowers
            ? 'chevron_right'
            : open && isFlyingFlowers
                ? 'close'
                : 'expand_more';
    return onlyLinks ? (_jsx("div", { children: id && (_jsx("a", { onClick: () => showAnswer(id), className: classes.accordianLink, href: `#Q-${id}`, target: "_self", children: question })) })) : question && answer ? (_jsxs(Accordion, { className: classes.accordian, expanded: open, ref: accordianRef, children: [_jsx(AccordionSummary, { id: `Q-${id}`, onClick: () => setOpen((prev) => !prev), classes: {
                    root: classes.accordianSummaryRoot,
                    content: classes.accordianSummaryContent,
                }, expandIcon: _jsx(Icon, { icon: icon, className: clsx(open ? classes.accordianIconOpen : classes.accordianIconClosed, isFlyingFlowers && classes.flyingFlowersIcon) }), "aria-controls": `panel${id}-content`, children: _jsx("h3", { children: question }) }), _jsx(AccordionDetails, { children: _jsx(RawHtml, { className: classes.accordianAnswer, content: answer }) })] }, id)) : null;
};
export default FaqItem;
