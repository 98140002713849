import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()(() => ({
    container: {
        padding: '5px',
        maxWidth: '100%',
        textAlign: 'center',
    },
    brandUspImage: {
        background: 'url(/images/usps/banner_img.svg) no-repeat',
        backgroundSize: 'contain',
        color: colors.supplementary.black,
        textTransform: 'uppercase',
        display: 'inline-block',
        height: '40px',
        minWidth: '205px',
        padding: '8px 16px',
    },
    brandTitle: {
        fontWeight: 500,
        letterSpacing: '2px',
        fontSize: '0.85rem',
        lineHeight: '1rem',
    },
}));
export default useStyles;
