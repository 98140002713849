import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { videoSource } from '../../common/data/videoSourceTypes.data';
import Icon from '../Icon/Icon';
import useStyles from './Video.styles';
import { useMediaQuery } from 'react-responsive';
import { useLoaded } from '../MainContent/useComponentLoading';
import { useTheme } from '@mui/material';
const Video = (props) => {
    const { video, videoSchemaSEO } = props;
    const { autoPlay, loop, playButton, controls, playSound, url } = video;
    const { classes } = useStyles();
    const videoRef = useRef(null);
    const theme = useTheme();
    const [isVideoPlay, setIsPlayVideo] = useState(autoPlay);
    const [isIosMobile, setIsIosMobile] = useState(true);
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const iosDevice = typeof window !== 'undefined' ? /iPhone/.test(navigator.userAgent) : false;
    const handleVideoPlay = () => {
        var _a, _b;
        !isVideoPlay ? (_a = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _a === void 0 ? void 0 : _a.play() : (_b = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _b === void 0 ? void 0 : _b.pause();
        setIsPlayVideo(true);
    };
    useEffect(() => {
        const handleIsGalleryModalChange = (event) => {
            if (event.type === 'isGalleryModalOpen') {
                setIsGalleryModalOpen(sessionStorage.getItem('isGalleryModalOpen') === 'true');
            }
        };
        window.addEventListener('isGalleryModalOpen', handleIsGalleryModalChange);
        return () => {
            window.removeEventListener('isGalleryModalOpen', handleIsGalleryModalChange);
        };
    }, []);
    const handleClick = (event) => {
        var _a, _b;
        event.preventDefault();
        !isVideoPlay ? (_a = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _a === void 0 ? void 0 : _a.play() : (_b = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _b === void 0 ? void 0 : _b.pause();
        setIsPlayVideo(!isVideoPlay);
    };
    useEffect(() => {
        if (isGalleryModalOpen && autoPlay && !!(videoRef === null || videoRef === void 0 ? void 0 : videoRef.current)) {
            videoRef.current.pause();
            setIsPlayVideo(false);
        }
    }, [autoPlay, isGalleryModalOpen]);
    useEffect(() => {
        setIsIosMobile(isMobileDevice && iosDevice);
    }, [isMobileDevice, iosDevice]);
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.container, children: [_jsx("video", { id: "video", controls: controls !== null && controls !== void 0 ? controls : false, muted: !playSound, autoPlay: autoPlay !== null && autoPlay !== void 0 ? autoPlay : false, loop: loop !== null && loop !== void 0 ? loop : false, ref: videoRef, onClick: handleClick, disablePictureInPicture: true, controlsList: 'nodownload noplaybackrate', playsInline: true, children: videoSource.map((video) => (_jsx("source", { "data-quality-label": video.quality, "data-bitrate": video.birtrate, src: isIosMobile ? `${url}${video.urlExtension}#t=1.75` : `${url}${video.urlExtension}`, type: video.videoType, media: `screen and (${video.deviceWidth}:801px)` }, `${url}${video.urlExtension}`))) }), !isVideoPlay && playButton && (_jsx("div", { className: classes.controlsContainer, children: _jsx(Icon, { icon: "play_circle_outline", className: classes.icon, onClick: handleVideoPlay }) }))] }, url), videoSchemaSEO ? (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps(JSON.parse(videoSchemaSEO)))) })) : null] }));
};
export default Video;
