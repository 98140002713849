import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import React from 'react';
import ImportedCarousel from 'react-multi-carousel';
import useStyles from './Carousel.styles';
import { Box } from '@mui/material';
const Carousel = React.forwardRef((props, ref) => {
    const { classes } = useStyles();
    return (_jsx(Box, { className: clsx(classes.carousel, props.carouselContainerClass), children: _jsx(ImportedCarousel, Object.assign({}, props, { ref: ref })) }));
});
Carousel.displayName = 'Carousel';
export default Carousel;
