import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: spacing.medium }) }),
    description: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_lg) }),
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.between('md', 'xl')]: {
            flexFlow: 'row wrap',
            alignItems: 'unset',
        },
        [theme.breakpoints.up('xl')]: {
            flexFlow: 'row',
            alignItems: 'unset',
        },
    },
}));
export default useStyles;
