var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useStyles from './ChangePassword.styles';
import { errorMessages, validateAllErrorsForSchema, SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import PasswordField, { passwordValidationSchema } from '../PasswordField/PasswordField';
import clsx from 'clsx';
import Alert from '../Alert/Alert';
const ChangePassword = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { onSubmit, onCancel } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const initialValues = {
        currentPassword: '',
        newPassword: '',
    };
    const formikConfig = {
        initialValues,
        validate: validateAllErrorsForSchema(Yup.object().shape({
            currentPassword: Yup.string().required(errorMessages().required),
            newPassword: passwordValidationSchema,
        })),
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setErrorMessage('');
                yield onSubmit(values);
            }
            catch (error) {
                setErrorMessage(error.message);
            }
        }),
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { children: "Change password" }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsx(Form, { children: _jsxs("div", { className: clsx(classes.box, classes.contentPadding), children: [errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx("div", { className: classes.input, children: _jsx(PasswordField, { id: "currentPassword", name: "currentPassword", label: "Current password", placeholder: "e.g. YourPas5word", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(PasswordField, { id: "newPassword", name: "newPassword", label: "New password", placeholder: "e.g. YourPas5word", fullWidth: true, required: true, newPassword: true }) }), _jsxs("div", { className: classes.buttons, children: [_jsx(Button, { variant: "secondary", thin: true, fullWidth: true, title: "Save new password", className: classes.saveButton, isSubmit: true, disabled: isSubmitting, icon: isFlyingFlowers ? 'check' : undefined }), _jsx(Button, { variant: "tertiary", onClick: onCancel, thin: true, icon: isFlyingFlowers ? 'close' : undefined, fullWidth: true, title: "Cancel - discard changes" })] })] }) })) }))] }));
};
export default ChangePassword;
