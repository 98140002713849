import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params, classes) => ({
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginLeft: '20px',
        marginRight: '20px',
        padding: '10px',
        justifyContent: 'center',
        marginBottom: spacing.medium,
        marginTop: '14px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    ffLinkContainer: {
        left: 'unset',
        right: 'unset',
        marginLeft: 0,
        marginRight: 0,
        width: 'unset',
    },
    carousel: {
        margin: '0 auto',
        padding: `3px 3px 0 3px`,
        position: 'relative',
        '& .react-multi-carousel-dot-list ': {
            position: 'absolute',
            zIndex: 9,
            bottom: (params === null || params === void 0 ? void 0 : params.hasMessage) ? 0 : '-28px',
            [theme.breakpoints.up('sm')]: {
                position: 'absolute',
            },
            '& button': {
                height: '12px !important',
                width: '12px !important',
                marginRight: '12px !important',
            },
        },
        '& .react-multi-carousel-list': {
            zIndex: 9,
            [theme.breakpoints.up('md')]: {
                marginBottom: spacing.xs,
                marginTop: 0,
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '12px',
                marginTop: 0,
            },
        },
        '& .react-multi-carousel-track': {},
        '& .react-multi-carousel-dot button': {
            width: '12px',
            height: '12px',
            marginRight: '12px',
            borderWidth: '1px',
            backgroundColor: colors.grey.grey3,
        },
        '& .react-multi-carousel-dot--active button': {
            backgroundColor: colors.grey.grey6,
        },
    },
    carouselItem: {
        margin: 0,
    },
    'custom-dot-list-style': {
        position: 'relative',
        top: '35px',
        '& li:last-child button': {
            marginRight: '0',
        },
    },
    carouselContainer: {
        width: '100%',
    },
    leftButton: {
        [theme.breakpoints.down('xl')]: {
            left: spacing.small,
        },
        [theme.breakpoints.up('sm')]: {
            left: spacing.small,
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            '@media(orientation: portrait)': {
                left: spacing.small,
            },
        },
    },
    rightButton: {
        right: spacing.xxs,
        [theme.breakpoints.up('lg')]: {
            right: '12px !important',
        },
    },
    customButtonGroup: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        top: '41%',
        '@media (orientation: landscape)': {
            top: (params === null || params === void 0 ? void 0 : params.height) || '46%',
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            top: (params === null || params === void 0 ? void 0 : params.height) || 'calc(50% - 84px)',
            display: 'flex',
        },
        [theme.breakpoints.up('md')]: {
            top: (params === null || params === void 0 ? void 0 : params.height) || 'calc(50% - 86px)',
            display: 'flex',
        },
        [theme.breakpoints.up('lg')]: {
            top: (params === null || params === void 0 ? void 0 : params.height) || 'calc(50% - 78px)',
        },
        [theme.breakpoints.up('xl')]: {
            top: (params === null || params === void 0 ? void 0 : params.height) || '44%',
        },
        [`& .${classes.leftButton}`]: {
            left: spacing.xxs,
            [theme.breakpoints.up('lg')]: {
                left: '12px',
            },
            position: 'absolute',
            '& button': {
                width: '40px',
                height: '40px',
                // '& .MuiIcon-root': {
                //   width: '40px',
                //   height: '40px',
                // },
            },
        },
        [`& .${classes.rightButton}`]: {
            right: spacing.xxs,
            [theme.breakpoints.up('lg')]: {
                right: '12px !important',
            },
            position: 'absolute',
            '& button': {
                width: '40px',
                height: '40px',
                // '& .MuiFab-label': {
                //   width: '40px',
                //   height: '40px',
                // },
            },
        },
        // '& .MuiIcon-root': {
        //   height: '40px',
        //   width: '40px',
        // },
    },
    truspilotContainer: {
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        },
    },
    linksHeight: {
        [theme.breakpoints.down('xl')]: {
            minHeight: (params === null || params === void 0 ? void 0 : params.linksCount) === 1 ? '44px' : (params === null || params === void 0 ? void 0 : params.linksCount) === 2 ? '104px' : 'initial',
        },
    },
    adjustLinkContainerHeight: {
        bottom: '156px !important',
        [theme.breakpoints.up('sm')]: {
            bottom: 'initial',
        },
    },
    adjustMessage: {
        marginTop: `${spacing.xl} !important`,
        [theme.breakpoints.up('md')]: {
            marginTop: 'initial',
            paddingTop: 'initial',
        },
    },
    removeMarginForMessage: {
        [theme.breakpoints.down('xl')]: {
            marginTop: `0 !important`,
        },
    },
    showOnGallery: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    carouselDotList: {
        pointerEvents: 'none',
    },
    slider: {},
    centerHeading: {},
}));
export default useStyles;
