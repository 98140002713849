import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useFeatureFlag } from '../../utils/useExperiment';
import { enforce_component_load_sequence } from '../../utils/featureFlags';
export const useComponentLoading = (componentType) => {
    const { isReady } = useRouter();
    const enforceComponentLoadSequence = useFeatureFlag(enforce_component_load_sequence.key) || false;
    // hide components below until component reports back that it's been loaded, unless unknown component or not client side rendered
    const [isLoading, setIsLoading] = useState(enforceComponentLoadSequence && componentType !== 'unknown' && isReady);
    const handleLoaded = useCallback(() => {
        setIsLoading(false);
    }, []);
    // Detect if component does not complete loading
    useEffect(() => {
        const timeout = isLoading &&
            setTimeout(() => {
                console.error('Component', componentType, 'was not loaded in 5 seconds');
                setIsLoading(false);
            }, 5000);
        return timeout ? () => clearTimeout(timeout) : undefined;
    }, [componentType, isLoading]);
    return [isLoading, handleLoaded];
};
export const useLoaded = ({ onLoaded }, isLoaded = true) => {
    useEffect(() => {
        isLoaded && (onLoaded === null || onLoaded === void 0 ? void 0 : onLoaded());
    }, [isLoaded, onLoaded]);
};
