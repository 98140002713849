import { makeStyles } from 'tss-react/mui';
import { colors, spacing, statusColorsFlyingFlowers, statusColorsInterflora } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    modal: {
        '& .MuiDialog-paper': {
            padding: '20px',
            [theme.breakpoints.up('md')]: {
                width: '100%',
                maxWidth: '796px',
                maxHeight: '92vh',
                left: 0,
                padding: 'initial',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '1009px',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '1336px',
            },
        },
        '& header': {
            alignItems: 'center',
            '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({ paddingLeft: '136px' }, theme.typography.large_regular), { marginTop: spacing.small }) }),
            '& > div': {
                [theme.breakpoints.up('md')]: {
                    paddingRight: spacing.large,
                    paddingTop: spacing.small,
                },
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: spacing.medium,
            },
        },
    },
    content: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { marginBottom: spacing.small, color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: {
            paddingLeft: '136px',
            paddingRight: '136px',
            marginBottom: spacing.medium,
        }, '& span': Object.assign({}, theme.typography.small_bold), [theme.breakpoints.down('xl')]: {
            '-webkit-text-size-adjust': '100%',
        } }),
    productGrid: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: spacing.medium,
        '& a': {
            maxWidth: '160px',
            [theme.breakpoints.up('lg')]: {
                maxWidth: '248px',
            },
        },
        '& > :not(:last-child)': {
            marginRight: spacing.small,
            [theme.breakpoints.up('lg')]: {
                marginRight: '44px',
            },
        },
        '& .MuiPaper-root': {
            padding: 'initial',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
            paddingLeft: '136px',
            paddingRight: '136px',
        },
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            flexDirection: 'row',
            '& > :first-child': {
                marginRight: theme.isFlyingFlowers ? spacing.small : spacing.medium,
                marginBottom: 'initial',
            },
            marginBottom: spacing.medium,
        },
    },
    cancelButton: {
        '&.MuiButtonBase-root.MuiButton-root': {
            [theme.breakpoints.down('xl')]: {
                paddingLeft: theme.isFlyingFlowers ? '19px !important' : 29,
                paddingRight: theme.isFlyingFlowers ? '22px !important' : 29,
            },
        },
    },
    viewAllButton: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: 'initial',
        },
    },
    noProducts: {
        marginBottom: spacing.xxl,
    },
    singleProduct: {
        display: 'flex',
        marginBottom: spacing.medium,
        '& .MuiPaper-root': {
            padding: 'initial',
        },
        '& .MuiCard-root': {
            width: '160px',
            margin: '0 auto',
            [theme.breakpoints.up('md')]: {
                width: '248px',
            },
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
            paddingLeft: '136px',
            paddingRight: '136px',
        },
    },
    alertContent: {
        marginBottom: spacing.medium,
        border: theme.isFlyingFlowers
            ? `2px solid ${statusColorsFlyingFlowers.warning.base} !important`
            : `1px solid ${statusColorsInterflora.warning.dark} !important`,
        [theme.breakpoints.up('md')]: {
            marginLeft: '136px',
            marginRight: '136px',
            marginBottom: spacing.medium,
        },
        '& .MuiIcon-root': {
            fontSize: '20px',
            color: theme.isFlyingFlowers ? statusColorsFlyingFlowers.warning.base : statusColorsInterflora.warning.dark,
        },
        '& > :first-child': {
            paddingTop: '3px',
        },
    },
    alertMessage: Object.assign(Object.assign({}, theme.typography.small_regular), { paddingTop: 0 }),
    buttonWidth: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: theme.isFlyingFlowers ? '352px' : '335px',
        },
    },
    productName: {},
}));
export default useStyles;
