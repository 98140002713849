import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    inputSpacingLarge: { marginBottom: spacing.large },
    container: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
        paddingBottom: spacing.xl,
        '& h4': Object.assign(Object.assign({}, theme.typography.xxs_bold), { margin: 0, marginBottom: spacing.xs }),
        '& p:not(:last-child)': Object.assign(Object.assign({}, theme.typography.xxs_regular), { margin: 0, marginBottom: spacing.small }),
    },
    instructionsDesktop: Object.assign(Object.assign({ [theme.breakpoints.down('md')]: {
            display: 'none',
        } }, theme.typography.small_regular), { marginBottom: spacing.xxs }),
    revealContainer: {
        marginTop: spacing.small,
    },
    textarea: {
        '& .MuiInputBase-multiline': {
            alignItems: 'flex-start',
            minHeight: '100px',
            paddingRight: spacing.xxxl,
        },
    },
    input: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xxs,
        },
    },
    characterCount: Object.assign(Object.assign(Object.assign({ position: 'absolute', marginLeft: spacing.small }, theme.typography.xxs_regular), theme.typographySpacing.none), { top: '95px', right: '11px', [theme.breakpoints.up('md')]: {
            right: '9px',
        } }),
    addressContiner: {
        paddingBottom: spacing.medium,
        [`& .${classes.instructionsDesktop}`]: Object.assign(Object.assign({ display: 'block' }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
    },
    instruction: {},
}));
export default useStyles;
