import { useQuery } from '@apollo/client'
import { CartCount, Customer } from '@interflora/ui-components/build/common/props'
import MainContent from '@interflora/ui-components/build/components/MainContent/MainContent'
import { SiteContext } from '@interflora/ui-components/build/utils/common'
import axios from 'axios'
// import { useFlags } from 'launchdarkly-react-client-sdk'
import { getSiteKey, useImperativeQuery } from 'lib/apolloClient'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { renderBingTagging } from 'utils/bingTagging'
import { renderOneTrustTags } from 'utils/oneTrust'
import AnalyticsContext from '../../context/AnalyticsContext'
import {
  FORMAT_ADDRESS,
  GET_ADDRESS_COUNTIES,
  GET_ADDRESS_TOWNS,
  GET_CUSTOMER,
  GET_TOTAL_CONSIGNMENT_COUNT,
} from '../../graphql/queries'
import { Analytics } from '../../lib/Analytics'
import { renderComponents } from '../../utils/components'
import { getGtmId, renderGtmTags } from '../../utils/gtm'
import { faviconAppleObjects, faviconObjects } from 'common/types'
import { renderDebugbearScript } from '../../utils/debugbearScript'
import { enforce_convert_sync_mode } from '@interflora/ui-components/build/utils/featureFlags'
import { renderIAdvizeScript } from '../../utils/iAdvize'

const analytics = new Analytics({ gtmId: getGtmId() })

/**
 * Check if build number has been updated on server, false if there is an error retrieving the file
 */
const updatedBuildNum = async (buildNum: string) => {
  try {
    const response = await axios.get('/_meta/build.json')
    return buildNum !== response.data?.ssgBuildNum
  } catch (ex) {
    return false
  }
}

interface Props {
  renderGraph: any
  disableGtm?: boolean
  disableOneTrust?: boolean
  children: React.ReactChild
  onIsSignedIn?: (customer: Customer) => void
  onIsSignedOut?: () => void
  disableCustomerCache?: boolean
  redirectUrl?: string
  disableDebugbear?: boolean
  disableIAdvize?: boolean
}

const Page: React.FunctionComponent<any> = (props: Props) => {
  const router = useRouter()
  const { page, site, subject, preloadedComponents, urlInfo, redirectUrl, featureFlags } = props.renderGraph
  const { disableGtm, disableOneTrust, disableDebugbear = false, disableIAdvize } = props
  const [addressCounties, setAddressCounties] = useState([])
  const getFormattedAddress = useImperativeQuery(FORMAT_ADDRESS)
  const getAddressTowns = useImperativeQuery(GET_ADDRESS_TOWNS)
  const getAddressCounties = useImperativeQuery(GET_ADDRESS_COUNTIES)
  const lastCheckedBuild = useRef(Date.now())
  const reloadingPage = useRef(false)
  const { publicRuntimeConfig } = getConfig()
  //const { isFlyingFlowers } = useContext(SiteContext)

  const amplienceDamDomain = new URL(site.imageUrlPrefix)
  amplienceDamDomain.pathname = ''
  const preconnectDomains = [
    amplienceDamDomain.toString(),
    'https://interflora.a.bigcontent.io',
    'https://cdn.cookielaw.org',
    'https://www.googletagmanager.com',
  ]

  useQuery(GET_ADDRESS_COUNTIES, {
    variables: { input: { country: site.countryCode } },
    onCompleted: (data) => {
      setAddressCounties(data.getAddressCounties)
    },
  })

  // Feature flags: required for targeting specific users
  // const ldClient = useLDClient()

  const sendUserLoginStatusAnalytics = (loginStatus: string) => {
    const userStatus = sessionStorage.getItem('userStatus')
    if (!userStatus) {
      analytics.userLoginStatusAnalytics(loginStatus)
      sessionStorage.setItem('userStatus', loginStatus)
    }
  }

  const getCustomer = useQuery<{ customer: Customer }>(GET_CUSTOMER, {
    onCompleted: (data) => {
      if (data?.customer) {
        props.onIsSignedIn && props.onIsSignedIn(data.customer)
        analytics.setUserDetails(data.customer.customerNumber, data.customer.email)
        sendUserLoginStatusAnalytics('signed-in')
        // Feature flags: required for targeting specific users
        // ldClient?.identify(
        //   {
        //     kind: 'user',
        //     key: data.customer.customerNumber,
        //     name: [data.customer.firstName, data.customer.lastName].join(' '),
        //     email: data.customer.email,
        //   },
        //   null,
        //   () => { console.log('Updated feature flags for logged in user') }
        // )
      } else {
        props.onIsSignedOut && props.onIsSignedOut()
        analytics.setUserDetails(null, null)
        sendUserLoginStatusAnalytics('signed-out')
      }
    },
    onError: () => {
      props.onIsSignedOut && props.onIsSignedOut()
      analytics.setUserDetails(null, null)
    },
    fetchPolicy: props.disableCustomerCache ? 'network-only' : 'cache-first',
    ssr: false,
  })

  const getConsignmentCount = useQuery<{ cart: CartCount }>(GET_TOTAL_CONSIGNMENT_COUNT, {
    fetchPolicy: 'network-only',
    ssr: false,
  })

  analytics.currency = props.renderGraph.currency
  analytics.subject = subject

  /*
   * When the route changes, we need to issue a 'page_view' event
   * to Google Analytics. The 'routeChangeComplete' fires after the
   * data for the page has loaded, and after the <title> tag value
   * has been changed, meaning that we're able to access the latest
   * value for the document title. We fire the `page_view` event,
   * which is a standard enhanced measurement event:
   * https://support.google.com/analytics/answer/9216061
   */
  useEffect(() => {
    const handlePageChangeStart = async (url: string) => {
      reloadingPage.current = false
      !disableGtm && analytics.bufferEvents()

      // Check if we need to recheck the build number after TTL seconds
      const checkBuildNum = Date.now() - lastCheckedBuild.current > publicRuntimeConfig.refreshTtlSecs * 1000

      // Only recheck if we're not in the checkout flow
      if (checkBuildNum && !url?.includes('/checkout')) {
        if (await updatedBuildNum(publicRuntimeConfig.buildNum)) {
          // Redirect to the url and cancel the analytic events
          reloadingPage.current = true
          window.location.assign(url)
        } else {
          // Update the time build was last checked
          lastCheckedBuild.current = Date.now()
        }
      }
    }
    const handlePageChangeComplete = () => {
      if (!disableGtm && !reloadingPage.current) {
        analytics.viewPage({
          page_title: document.title,
        })
        analytics.flushEvents()
      }
    }

    router.events.on('routeChangeStart', handlePageChangeStart)
    router.events.on('routeChangeComplete', handlePageChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handlePageChangeStart)
      router.events.off('routeChangeComplete', handlePageChangeComplete)
    }
  }, [disableGtm, router.events, publicRuntimeConfig.buildNum, publicRuntimeConfig.refreshTtlSecs])

  const convertGTMID =
    typeof window === 'undefined' ? process.env.GTM_CONVERT_ID : process.env.NEXT_PUBLIC_GTM_CONVERT_ID

  // Check client side ABTesting is enabled and add it to server side feature flags for passing to the SiteContext
  // const { aBTestingClient } = useFlags()
  // const isABTestingEnabled = !!featureFlags?.['a-b-testing'] && aBTestingClient
  const convertSyncModeFlag = featureFlags?.[enforce_convert_sync_mode.key]

  const isConvertSyncModeEnabled =
    (convertSyncModeFlag &&
      typeof convertSyncModeFlag === 'object' &&
      Object.keys(convertSyncModeFlag).length > 0 &&
      (convertSyncModeFlag?.RegExs || []).some((pattern) => new RegExp(pattern)?.test(router.asPath))) ||
    false

  const isABTestingEnabled = !!featureFlags?.['a-b-testing']
  const clientFeatureFlags = useMemo(
    () => ({ ...featureFlags, isABTestingEnabled, convertTagMode: isConvertSyncModeEnabled }),
    [featureFlags, isABTestingEnabled, isConvertSyncModeEnabled]
  )
  // taking the value from subject as "includeProductInSitemap" boolean only required for product pages
  const includeProductInSitemap = subject?.product?.includeProductInSitemap

  const displayCanonicalTag = (pageInfo: any) => {
    let displayCanonical = false
    if (
      pageInfo?.seo?.robotsControl?.length === 0 ||
      pageInfo?.seo?.robotsControl?.includes('noindex') === false ||
      pageInfo?.seo?.noIndex === false
    ) {
      displayCanonical = true
    }
    return displayCanonical
  }
  return (
    <>
      {redirectUrl ? (
        <>
          <Head>
            <meta httpEquiv="refresh" content={`0;URL='${redirectUrl}'`} />
          </Head>
          <main>
            {!props.children && (
              <>
                {renderComponents(page.components.header)}
                {renderComponents(page.components.footer)}
              </>
            )}
            {props.children}
          </main>
        </>
      ) : (
        <>
          <Head>
            {preconnectDomains?.map((domain) => (
              <link rel="preconnect" href={domain} key={domain.replace('https://', '')} />
            ))}
            {/* Added script env specific */}
            {site.shorthand !== 'ie' && (
              <script
                type="text/javascript"
                async={!isConvertSyncModeEnabled}
                defer={!isConvertSyncModeEnabled}
                src={`https://cdn-4.convertexperiments.com/js/${convertGTMID}.js`}
              />
            )}

            {site.shorthand !== 'ff' &&
              faviconObjects?.map((logo) =>
                logo.logoName ? (
                  <link
                    key={`${logo.logoName}${logo.sizes}`}
                    rel={'icon'}
                    type="image/png"
                    sizes={logo.sizes}
                    href={`https://media.interflora.co.uk/i/interflora/${logo.logoName}`}
                  />
                ) : (
                  ''
                )
              )}
            {site.shorthand !== 'ff' &&
              faviconAppleObjects?.map((applelogo) =>
                applelogo.logoName ? (
                  <link
                    key={`${applelogo.logoName}${applelogo.sizes}`}
                    rel={'apple-touch-icon'}
                    type="image/png"
                    sizes={applelogo.sizes}
                    href={`https://media.interflora.co.uk/i/interflora/${applelogo.logoName}`}
                  />
                ) : (
                  ''
                )
              )}

            {site.shorthand === 'ff' && (
              <>
                <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
                <link rel="icon" type="image/png" href="/images/favicon.ico" />
              </>
            )}

            {page?.seo?.title && <title>{page.seo.title}</title>}
            {page?.seo?.metaDescription && <meta name="description" content={page.seo.metaDescription} />}
            {page?.seo?.lastModified && <meta name="lastModified" content={page.seo.lastModified} hidden />}
            {includeProductInSitemap === false && <meta name="includeProductInSitemap" content="false" hidden />}
            {!!page.seo?.robotsControl?.length && <meta name="robots" content={page.seo?.robotsControl.join(', ')} />}
            {!!redirectUrl && <meta httpEquiv="refresh" content={`0;URL='${redirectUrl}'`} />}
            {displayCanonicalTag(page) && urlInfo?.canonical && <link rel="canonical" href={urlInfo.canonical} />}
            {urlInfo?.alternate?.map((alternateUrl) => (
              <link
                key={alternateUrl.hreflang}
                rel="alternate"
                hrefLang={alternateUrl.hreflang}
                href={alternateUrl.href}
              />
            ))}
          </Head>
          {!disableOneTrust && renderOneTrustTags()}

          {!disableGtm && renderGtmTags({ isABTestingEnabled })}
          {site.shorthand === 'ff' && renderBingTagging()}
          {!disableDebugbear && renderDebugbearScript()}
          {!disableIAdvize && renderIAdvizeScript(site.shorthand)}
          <main>
            <AnalyticsContext.Provider value={analytics}>
              <SiteContext.Provider
                value={{
                  ...site,
                  site: getSiteKey(),
                  getCustomer,
                  addressCounties,
                  getFormattedAddress,
                  preloadedComponents,
                  getAddressTowns,
                  getAddressCounties,
                  isFlyingFlowers: site.shorthand === 'ff',
                  subject,
                  getConsignmentCount,
                  featureFlags: clientFeatureFlags,
                }}
              >
                {!props.children && (
                  <>
                    {renderComponents(page.components.header)}
                    <MainContent centreContent={page.centreContent}>
                      {renderComponents(page.components.body)}
                    </MainContent>
                    {renderComponents(page.components.footer)}
                  </>
                )}
                {props.children}
              </SiteContext.Provider>
            </AnalyticsContext.Provider>
          </main>
        </>
      )}
    </>
  )
}

export default Page
