import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import useStyles from './NewsletterSignUpConfirmation.styles';
import { processImageUrl } from '../../../utils/image';
import Image from '../../Image/Image';
import Link from '../../Link/Link';
import RawHtml from '../../RawHtml/RawHtml';
import { SiteContext } from '../../../utils/common';
const NewsletterSignUpConfirmation = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { bodyOnSignUpConfirmation, imageOnSignUpConfirmation, linkOnSignUpConfirmation, firstName, setOpen } = props;
    return (_jsxs("div", { className: classes.newsletterSignUpContainer, children: [_jsxs("div", { className: classes.signUpForm, children: [_jsxs("h3", { className: classes.welcomeText, children: ["Thanks ", firstName, "!"] }), _jsx(RawHtml, { className: classes.message, content: bodyOnSignUpConfirmation }), _jsx("div", { className: classes.buttons, children: _jsx(Link, Object.assign({ button: true, variant: isFlyingFlowers ? 'secondaryDark' : 'primary' }, linkOnSignUpConfirmation, { onClick: () => setOpen(false) })) })] }), (imageOnSignUpConfirmation === null || imageOnSignUpConfirmation === void 0 ? void 0 : imageOnSignUpConfirmation.url) && (_jsx("div", { className: classes.signUpFormImage, children: _jsx("div", { className: classes.imgContainer, children: _jsx(Image, { className: classes.image, imageUrl: processImageUrl(imageOnSignUpConfirmation === null || imageOnSignUpConfirmation === void 0 ? void 0 : imageOnSignUpConfirmation.url, { w: 440, aspect: '2:3' }, ['$poi-square$']), altText: (imageOnSignUpConfirmation === null || imageOnSignUpConfirmation === void 0 ? void 0 : imageOnSignUpConfirmation.altText) || '', width: 500, height: 500 }) }) }))] }));
};
export default NewsletterSignUpConfirmation;
