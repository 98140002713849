import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import GiftCardContent from '../GiftCardContent/GiftCardContent';
import useStyles from './GiftCardContentGallery.styles';
const GiftCardContentGallery = (props) => {
    const { giftContents, isFuneral } = props;
    const { classes } = useStyles();
    const title = isFuneral ? 'More about this tribute:' : 'More about this gift:';
    return (_jsx("div", { children: !!(giftContents === null || giftContents === void 0 ? void 0 : giftContents.length) && (_jsxs(_Fragment, { children: [_jsx("h2", { className: classes.heading, children: title }), _jsx("div", { className: classes.container, children: giftContents.map((item, index) => (_jsx(GiftCardContent, Object.assign({}, item), index))) })] })) }));
};
export default GiftCardContentGallery;
