import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, fontWeights, spacing, statusColorsInterflora } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, { isClickAndCollect }) => ({
    container: {
        backgroundColor: colors.grey.grey1,
        '& p': {
            marginBottom: 0,
        },
    },
    orderSummary: {
        padding: `${spacing.xl} ${spacing.medium} 36px ${spacing.medium}`,
        borderBottom: `1px solid ${colors.grey.grey3}`,
        [theme.breakpoints.up('md')]: {
            padding: `50px ${spacing.xl} 34px ${spacing.xl}`,
        },
    },
    orderTrackingLink: Object.assign(Object.assign({}, theme.typography.small_regular), { display: 'inline', color: colors.primary.gold5, textDecoration: 'underline', marginLeft: 8, [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            display: 'block',
        } }),
    summaryDetails: {
        padding: `30px ${spacing.medium}`,
        [theme.breakpoints.up('md')]: {
            padding: `${spacing.large} ${spacing.xl}`,
        },
    },
    orderSummaryHeading: Object.assign(Object.assign({}, theme.typography.large_bold), { fontWeight: fontWeights.bold, color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    orderNumber: Object.assign(Object.assign({ color: colors.primary.gold4, textDecoration: 'underline' }, theme.typography.medium_bold), { fontWeight: fontWeights.regular, textAlign: 'left', [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            textAlign: 'right',
        } }),
    orderStatusHeading: Object.assign(Object.assign({ color: colors.supplementary.black }, theme.typography.medium_bold), { fontWeight: fontWeights.bold }),
    orderTrackerStepInActive: Object.assign(Object.assign({}, theme.typography.small_bold), { fontWeight: fontWeights.bold, color: colors.grey.grey3, paddingTop: 0, paddingBottom: 0, display: 'inline', [theme.breakpoints.up('md')]: {
            display: 'block',
        } }),
    orderTrackerStepActive: Object.assign(Object.assign({}, theme.typography.small_bold), { fontWeight: fontWeights.bold, color: colors.supplementary.black, paddingTop: 0, paddingBottom: 0, display: 'inline', [theme.breakpoints.up('md')]: {
            display: 'block',
        } }),
    orderSummaryElements: {
        flexDirection: 'column',
        display: 'flex',
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: spacing.large,
        },
    },
    orderTrackerstepper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        top: '-10px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            width: '100%',
            margin: '0 auto',
            top: 'initial',
            gridTemplateColumns: isClickAndCollect ? 'repeat(4, minmax(0, 1fr))' : 'repeat(3, minmax(0, 1fr))',
            display: 'grid',
        },
    },
    orderTrackerStep: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        padding: '12px',
        paddingLeft: 0,
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            width: 'initial',
            padding: '14px',
            textAlign: 'center',
            alignItems: 'initial',
            '& :first-child': {
                paddingLeft: 0,
            },
        },
    },
    orderTrackerIcon: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px',
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xs,
        },
    },
    orderLabel: Object.assign(Object.assign({ margin: 0 }, theme.typography.medium_bold), { fontWeight: fontWeights.bold, color: colors.supplementary.black }),
    orderCreatedOn: Object.assign(Object.assign({}, theme.typography.medium_bold), { fontWeight: fontWeights.regular, color: colors.grey.grey6, marginBottom: 0, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), { fontWeight: fontWeights.regular, color: colors.supplementary.black }) }),
    orderTotal: Object.assign(Object.assign({ marginBottom: 0 }, theme.typography.medium_bold), { fontWeight: fontWeights.bold, color: colors.supplementary.black }),
    iconSize: {
        background: colors.grey.grey1,
        '& .MuiIcon-root': {
            fontSize: '1.5rem',
        },
    },
    activeIconColor: {
        color: `${statusColorsInterflora.success.base}`,
    },
    inActiveIconColor: {
        color: `${colors.grey.grey3} !important`,
    },
    failureStatusIconColor: {
        color: statusColorsInterflora.error.base,
        transform: 'rotate(180deg)',
    },
    completedStep: {
        color: `${colors.supplementary.black} !important`,
    },
    orderTrackerContainer: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.medium,
        },
    },
    statusBorder: {
        top: isClickAndCollect ? '15px' : '14px',
        left: '11px',
        height: isClickAndCollect ? 'calc(100% - 52px)' : 'calc(100% - 60px)',
        borderLeft: `1px dashed ${colors.grey.grey8}`,
        [theme.breakpoints.up('md')]: {
            top: '25px',
            left: isClickAndCollect ? '82px' : '100px',
            width: isClickAndCollect ? '72%' : '66%',
            borderTop: `1px dashed ${colors.grey.grey8}`,
            borderLeft: 0,
        },
        borderBlockStart: 'inherit',
        position: 'absolute',
    },
    deliveryPassProduct: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: {
            flexBasis: '50%',
        } }),
    deliverPassOrderNumber: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.none), { color: colors.primary.gold4, textDecoration: 'underline', [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        } }),
    deliveryPass: {
        display: 'flex',
        padding: `${spacing.xl} ${spacing.medium} 36px ${spacing.medium}`,
        color: colors.supplementary.black,
        borderTop: `1px solid ${colors.grey.grey3}`,
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            padding: `22px ${spacing.xl} ${spacing.xl}`,
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
    },
    navigationLink: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, textAlign: 'left' }),
    infoIcon: {
        transform: 'rotate(180deg)',
        color: statusColorsInterflora.error.base,
    },
}));
export default useStyles;
