import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from '../SearchOverlay.styles';
import { SearchOptionsList } from '../../../common/props';
import SearchLinkBlocks from './SearchLinkBlocks';
import SearchProducts from '../SearchProducts/SearchProducts';
const SearchResults = (props) => {
    const { classes } = useStyles({ isDynamicProducts: true });
    const { categories, inspirations, products, inputValue, totalCategories, totalInspirations, handleViewAllButtonClick, } = props;
    const showCategoryOrInspiration = categories.length || inspirations.length;
    const showViewAllButton = products.length > 3;
    return (_jsx("div", { className: clsx(classes.searchOverlayInner, classes.searchOverlayWrapper, classes.recentSearchOverlay), children: _jsxs("div", { className: clsx(products.length ? classes.suggestedSearchWithProductsContianer : classes.suggestedSearchContainer), children: [showCategoryOrInspiration ? (_jsxs("div", { className: classes.suggested, children: [_jsx("h3", { children: "Are you looking for" }), categories.length ? (_jsx("div", { children: _jsx(SearchLinkBlocks, { links: categories, inputValue: inputValue, linkTitle: SearchOptionsList.CATEGORIES, isProductsExists: !!products.length, totalCount: totalCategories }) })) : null, inspirations.length ? (_jsx("div", { children: _jsx(SearchLinkBlocks, { links: inspirations, inputValue: inputValue, linkTitle: SearchOptionsList.INSPIRATIONS, isProductsExists: !!products.length, totalCount: totalInspirations, handleViewAllButtonClick: handleViewAllButtonClick }) })) : null] })) : (_jsx("div", {})), (products === null || products === void 0 ? void 0 : products.length) ? (_jsx("div", { className: classes.productsContainer, children: _jsx(SearchProducts, { productsList: products.slice(0, 3), showViewAllButton: showViewAllButton, searchTerm: inputValue, handleViewAllButtonClick: handleViewAllButtonClick }) })) : null] }) }));
};
export default SearchResults;
