var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { RawHtml } from '..';
import GenericContent from '../GenericContent/GenericContent';
import useGenericStyles from '../GenericContent/GenericContent.styles';
import useStyles from './FFHomeHeroBanner.styles';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import { Box, useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Link from '../Link/Link';
const FFHomeHeroBanner = (props) => {
    const { message } = props, genericContentProps = __rest(props, ["message"]);
    const { links, headingColor = 'COBALT', contentPosition = 'LEFT', isFullWidthImage = false, headingTag = 'H1', isFFHomeHeroImage = true, homeHeroCTAnalytics, } = props;
    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme();
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const { classes } = useStyles();
    const { classes: genericClasses } = useGenericStyles({});
    const heading = typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line, index) => (_jsx(Box, { className: clsx(classes.headingLine, headingColor && classes[headingColor], contentPosition === 'CENTER' ? classes.centerHeading : ''), component: 'span', children: line.trim() }, index)))
        : props.heading;
    useEffect(() => {
        setIsMobile(isMobileDevice);
    }, [isMobileDevice]);
    return (_jsxs(_Fragment, { children: [_jsx(GenericContent, Object.assign({ isFromHomeHeroGallery: isMobile }, genericContentProps, { heading: heading, classNames: classes, headingLevel: "NONE", linkStyle: "BUTTON", textAlignment: "LEFT", contentPosition: props.contentPosition, mobileContentPosition: "OVER", minHeight: "LARGE", isFFHeroImage: true, fullWidthImage: true, isFFHomeHeroImage: isFFHomeHeroImage, showTrustpilotWidget: props.showTrustpilotWidget, trustPilotDarknessLevel: props.trustPilotDarknessLevel, trustpilotBackground: props.trustpilotBackground, isFullWidthImage: isFullWidthImage, isSubMessageTitleExists: !!props.subMessageTitle || !!props.subMessage, linksCount: links === null || links === void 0 ? void 0 : links.length, headingTag: headingTag, isFromFFHomeHeroBanner: true, isMobileDevice: isMobile, homeHeroCTAnalytics: homeHeroCTAnalytics })), links && isMobile && (_jsx(Box, { className: clsx(classes.linkContainer, genericClasses.linkContainer, classes.outsideLinkContainer), children: links.map((link, index) => (_jsx(Link, Object.assign({ button: true, variant: 'primary', thin: true, className: clsx(genericClasses.link, genericClasses.linkButton, classes.link) }, link, { homeHeroCTAnalytics: homeHeroCTAnalytics }), index))) })), props.showTrustpilotWidget && (_jsx("div", { className: classes.outsideShowTrustpilotWidget, children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) })), _jsx(RawHtml, { className: clsx(classes.message), content: message })] }));
};
export default FFHomeHeroBanner;
