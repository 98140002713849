import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useRef } from 'react';
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
import useStyles from './ProductListing.styles';
import { SiteContext } from '../../utils/common';
import ProductGrid from '../ProductGrid/ProductGrid';
import SearchShowMore from '../SearchShowMore/SearchShowMore';
import ProductListingFilter from '../ProductListingFilter/ProductListingFilter';
import { prepareCategorySchemaAndAppendToHead } from './utils';
import { useInstantSearch } from 'react-instantsearch';
import clsx from 'clsx';
import SkeletonProductListing from '../SkeletonProductListing/SkeletonProductListing';
const EmptyMessage = () => {
    const { results, indexUiState, status } = useInstantSearch();
    const isSearchLoaded = status === 'idle' && !!indexUiState.configure;
    // Data is only ready when indexUiState is not null
    return isSearchLoaded && !results.hits.length ? (_jsx("p", { children: "Sorry there are no products that match the selected filters" })) : null;
};
const ProductListing = (props) => {
    var _a;
    const { show = true, products, sortConfig, categoryName, spotlightBanner, facetDetails, sendSpotlightBannerAnalytics, onProductClick, showSkeleton, setShowSkeleton, pageNo, } = props;
    const { results: searchResults } = useInstantSearch();
    const { classes } = useStyles();
    const { currency } = useContext(SiteContext);
    const initialProducts = useRef(products);
    const { featureFlags } = useContext(SiteContext);
    // A category-schema-seo feature flag configured in LaunchDarkly
    const categorySchemaSeo = (featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags['category-schema-seo']) || false;
    return (_jsxs("div", { className: clsx(show ? classes.show : classes.hide, classes.productListing), children: [showSkeleton && _jsx(SkeletonProductListing, { productCount: 12 }), _jsx(ProductListingFilter, { sortConfig: sortConfig, currency: currency, facetDetails: facetDetails }), (products === null || products === void 0 ? void 0 : products.length) ? (_jsxs(_Fragment, { children: [_jsx(ProductGrid, { products: products, className: classes.productGrid, cardDisplayType: "VERBOSE", spotlightBanner: spotlightBanner, sendSpotlightBannerAnalytics: sendSpotlightBannerAnalytics, totalResultsCount: searchResults === null || searchResults === void 0 ? void 0 : searchResults.nbHits, onProductClick: onProductClick, setShowSkeleton: setShowSkeleton, showSkeleton: showSkeleton }), _jsx("div", { className: classes.additional, children: _jsx(SearchShowMore, { showViewedCount: true, pageNo: pageNo, isFromCategory: true }) })] })) : null, _jsx(EmptyMessage, {}), categorySchemaSeo && categoryName && ((_a = initialProducts.current) === null || _a === void 0 ? void 0 : _a.length) === 0 && products.length > 0 && (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps(prepareCategorySchemaAndAppendToHead(products, searchResults)))) }))] }));
};
export default ProductListing;
