import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    savedAddresses: {
        display: 'flex',
        gridTemplateColumns: 'unset',
        gridRowGap: 'unset',
        gridColumnGap: 0,
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: spacing.medium,
        },
    },
    addressCardContainer: {},
    addressesSection: {},
    savedAddressTitle: {},
    noAddress: {},
    body: {},
}));
export default useStyles;
