import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import useStyles from './DeliveryUnavailableModal.styles';
import clsx from 'clsx';
import { calculateSecondaryBadgeOfSimpleCard, handleSecondaryBadgeHeight, SiteContext } from '../../utils/common';
import ProductCard from '../ProductCard/ProductCard';
import Alert from '../Alert/Alert';
const DeliveryUnavailableModal = (props) => {
    const { open, setOpen, productName, products, changeDeliveryDate, changeAddress, viewAllAvailableGifts, handleOnClose, totalProductsCount, handleBasketLineItem, isFromBasketUpdate, deliveryDate, } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const theme = useTheme();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const isLargeDesktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.lg}px)` });
    const [productsList, setProductsList] = useState(products);
    const [device, setDevice] = useState();
    const tablet = useMediaQuery({ query: `(max-width: ${theme.breakpoints.values.md}px)` });
    const desktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const hideViewAllButton = isMobile ? totalProductsCount <= 2 : totalProductsCount <= 3;
    const handleBasketProductClick = (url) => {
        handleBasketLineItem && deliveryDate && handleBasketLineItem(url, deliveryDate);
    };
    useEffect(() => {
        const { device } = handleSecondaryBadgeHeight(desktop, tablet);
        if (device)
            setDevice(device);
        const resize = () => {
            const { device } = handleSecondaryBadgeHeight(desktop, tablet);
            if (device)
                setDevice(device);
        };
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [desktop, tablet, isLargeDesktop, isLargerViewport]);
    useEffect(() => {
        let productsData = products;
        if (!isLargerViewport && (productsData === null || productsData === void 0 ? void 0 : productsData.length)) {
            productsData = productsData.slice(0, 2);
        }
        const hasProductBadge = productsData === null || productsData === void 0 ? void 0 : productsData.some((product) => { var _a; return !!((_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge); });
        let heightOfBadge = 0;
        if (hasProductBadge && device) {
            productsData === null || productsData === void 0 ? void 0 : productsData.forEach((product) => {
                var _a, _b, _c;
                const secondaryBadgeTitleLength = (_c = (_b = (_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge) === null || _b === void 0 ? void 0 : _b.title) === null || _c === void 0 ? void 0 : _c.split(' ').join('').length;
                if (secondaryBadgeTitleLength && secondaryBadgeTitleLength > heightOfBadge) {
                    heightOfBadge = secondaryBadgeTitleLength;
                }
            });
            heightOfBadge = calculateSecondaryBadgeOfSimpleCard(device, heightOfBadge);
            productsData = productsData === null || productsData === void 0 ? void 0 : productsData.map((product) => (Object.assign(Object.assign({}, product), { hasProductBadge,
                heightOfBadge })));
        }
        setProductsList(productsData);
    }, [desktop, device, isLargerViewport, products, tablet]);
    const RenderActions = (_jsx(_Fragment, { children: totalProductsCount ? (_jsxs(_Fragment, { children: [!isFromBasketUpdate ? (_jsx(Button, { title: "Cancel - Choose another date", variant: "tertiary", thin: true, onClick: changeDeliveryDate, icon: isFlyingFlowers ? 'close' : undefined, className: clsx(classes.buttonWidth, classes.cancelButton) })) : null, !hideViewAllButton ? (_jsx(Button, { className: clsx(classes.viewAllButton, !isFromBasketUpdate ? classes.buttonWidth : ''), title: "View all available gifts", variant: "primary", thin: true, onClick: viewAllAvailableGifts, icon: isFlyingFlowers ? 'check' : undefined })) : null] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { title: "Change delivery address", variant: "tertiary", thin: true, onClick: changeAddress }), _jsx(Button, { className: classes.viewAllButton, title: "Choose a different date", variant: "primary", thin: true, onClick: changeDeliveryDate })] })) }));
    let productContent;
    let modalTitle;
    if (!(productsList === null || productsList === void 0 ? void 0 : productsList.length)) {
        modalTitle = 'Delivery unavailable';
        productContent =
            'Unfortunately, we are unable to deliver any gifts to your requirements. Please change your delivery date or address to see available gifts.';
    }
    else if (isFlyingFlowers) {
        modalTitle = 'Ah, we’ve hit a snag';
        productContent = `We’re really sorry but we can’t get <span>${productName}</span> to that address on that date. How about one of these instead?`;
    }
    else {
        modalTitle = 'We can’t quite do that';
        productContent = `We're really sorry, but we can’t deliver <span>${productName}</span> to that address on that day. How about one of these instead?`;
    }
    const ModalContent = (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx(classes.content, !(productsList === null || productsList === void 0 ? void 0 : productsList.length) ? classes.noProducts : ''), dangerouslySetInnerHTML: { __html: productContent } }), isFromBasketUpdate && !!(productsList === null || productsList === void 0 ? void 0 : productsList.length) ? (_jsx(Alert, { type: "alertInfo", message: _jsxs("div", { className: classes.alertMessage, children: ["Selecting one of the products below will remove the", ' ', _jsx("span", { className: classes.productName, children: productName }), " from your basket."] }), className: classes.alertContent })) : null, (productsList === null || productsList === void 0 ? void 0 : productsList.length) ? (_jsx("div", { className: clsx(productsList.length === 1 ? classes.singleProduct : classes.productGrid), children: productsList === null || productsList === void 0 ? void 0 : productsList.map((product, index) => (_jsx(ProductCard, { product: product, imageCarousel: true, cardDisplayType: "SIMPLE", index: index, isFromBasket: isFromBasketUpdate, handleBasketLineItem: handleBasketProductClick, isFromUnavailableSuggest: true }, `unavailable_suggestion_product_${product.sku}`))) })) : null, _jsx("div", { className: classes.actionsContainer, children: RenderActions })] }));
    return (_jsx(Modal, { title: modalTitle, open: open, setOpen: setOpen, maxWidth: isLargeDesktop ? 'lg' : 'md', fullScreen: isMobile, fullWidth: true, className: classes.modal, onClose: handleOnClose, children: ModalContent }));
};
export default DeliveryUnavailableModal;
