var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { FormikContext, useField } from 'formik';
import React, { useContext } from 'react';
import { showValidationMeta } from '../../utils/common';
import Icon from '../Icon/Icon';
import useStyles from './Select.styles';
import { FormControl, MenuItem, Select as MuiSelect } from '@mui/material';
const SelectWrapper = (props) => {
    // check if formik is available
    return !!useContext(FormikContext) ? _jsx(FormikSelect, Object.assign({}, props)) : _jsx(Select, Object.assign({}, props));
};
const FormikSelect = (props) => {
    const [field, meta] = useField(props.name);
    const validation = showValidationMeta(meta);
    return _jsx(Select, Object.assign({}, field, props, validation));
};
const Select = (props) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const containerRef = React.useRef(null);
    // separate props that are not passed through to text input field
    const { className, completed, fullWidth, label, onClick, required, state, errorMessage, items, placeholder = 'Please select', hideRequiredOptional, autoFocus } = props, selectInputProps = __rest(props, ["className", "completed", "fullWidth", "label", "onClick", "required", "state", "errorMessage", "items", "placeholder", "hideRequiredOptional", "autoFocus"]);
    const { id = props.name, name, value, disabled } = props;
    const handleContainerClick = () => {
        if (!open && !disabled) {
            setOpen((prev) => !prev);
        }
    };
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const renderSelectedValue = (value) => {
        const selectedItem = items === null || items === void 0 ? void 0 : items.find((item) => typeof value === 'string' ? item.value.toLowerCase() === value.toLowerCase() : item.value === value);
        if (!value) {
            return _jsx("span", { className: classes.placeholder, children: placeholder });
        }
        return _jsx(_Fragment, { children: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label });
    };
    return (_jsxs("div", { ref: containerRef, className: clsx(classes.textFieldWrapper, fullWidth && classes.fullWidth), children: [_jsxs("div", { className: clsx(classes.textField, className !== null && className !== void 0 ? className : '', state === 'error' && classes.error, state === 'success' && classes.success, disabled && classes.disabled, (open || value) && classes.touched), role: "button", onClick: handleContainerClick, tabIndex: 0, onKeyDown: (e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        handleContainerClick();
                    }
                }, children: [_jsxs(FormControl, { className: classes.formControl, children: [_jsxs("label", { className: classes.label, htmlFor: id || name, children: [`${label} `, hideRequiredOptional ? '' : required ? '(Required)' : '(Optional)'] }), _jsx(MuiSelect, Object.assign({}, selectInputProps, { id: id || name, disableUnderline: true, displayEmpty: true, autoFocus: autoFocus || open, open: open, onClose: handleClose, onOpen: handleOpen, className: classes.muiRoot, renderValue: renderSelectedValue, classes: { icon: classes.muiIcon, select: classes.muiSelectRoot }, MenuProps: {
                                    anchorEl: containerRef.current,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    // Need to get back: getContentAnchorEl: null,
                                    classes: { list: classes.menuList }, // These styles were implemented to address compatibility issues between React 18 and Material UI version 4. It's anticipated that these adjustments may become redundant upon upgrading to Material UI version 5.
                                }, variant: "standard", children: items === null || items === void 0 ? void 0 : items.map((item, index) => (_jsx(MenuItem, { className: classes.menuListItem, value: item.value, children: item.label }, `${index}${item.label}`))) }))] }), _jsx(Icon, { icon: open ? 'clear' : 'arrow_drop_down', fontSize: "inherit" })] }), errorMessage && _jsx("span", { className: classes.errorMessage, children: errorMessage })] }));
};
export default SelectWrapper;
