import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ProductImage from '../ProductImage/ProductImage';
import useStyles from './CheckoutFinishingTouchItem.styles';
const MAX_IMAGE_WIDTH = 72;
const CheckoutFinishingTouchItem = (props) => {
    var _a, _b;
    const { lineItem } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsx(ProductImage, { classNames: { container: classes.imageContainer }, altText: (_a = lineItem === null || lineItem === void 0 ? void 0 : lineItem.images[0]) === null || _a === void 0 ? void 0 : _a.altText, imageUrl: (_b = lineItem === null || lineItem === void 0 ? void 0 : lineItem.images[0]) === null || _b === void 0 ? void 0 : _b.url, maxWidth: MAX_IMAGE_WIDTH }), _jsxs("div", { className: classes.content, children: [_jsx("div", { className: classes.name, children: lineItem.name }), _jsx("div", { className: classes.price, children: _jsx("span", { children: lineItem.price.formatted }) }), _jsxs("div", { className: classes.quantity, children: ["Quantity: ", lineItem.quantity] })] })] }));
};
export default CheckoutFinishingTouchItem;
