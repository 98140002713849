import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Logo from '../Logo/Logo';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './CheckoutFooter.styles';
import PaymentTypeIcons from '../PaymentTypeIcons/PaymentTypeIcons';
const CheckoutFooter = ({ title = 'Secure checkout', links, legal }) => {
    const { classes } = useStyles();
    return (_jsxs("footer", { className: classes.footer, "data-hj-ignore-attributes": true, children: [_jsxs("div", { className: classes.footerInner, children: [_jsx(Logo, { className: classes.logo, isFooter: true }), _jsxs("h3", { className: classes.title, children: [_jsx(Icon, { icon: "lock", className: classes.icon }), title] }), !!links && (_jsx("ul", { className: clsx(classes.links), children: links.map((link, index) => (_jsx("li", { className: classes.link, children: _jsx(Link, Object.assign({}, link)) }, `${index}${link.label}`))) }))] }), _jsx("div", { className: classes.outerSection, children: _jsxs("div", { className: classes.innerSection, children: [_jsx(PaymentTypeIcons, { isFromCheckoutFooter: true }), _jsx(RawHtml, { className: classes.legal, content: legal })] }) })] }));
};
export default CheckoutFooter;
