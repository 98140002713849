import { useQuery } from '@apollo/client'
import { useLoaded } from '@interflora/ui-components'
import AvailabilityCheckerUI, {
  Props,
} from '@interflora/ui-components/build/components/AvailabilityChecker/AvailabilityChecker'
import { SiteContext } from '@interflora/ui-components/build/utils/common'
import AnalyticsContext from 'context/AnalyticsContext'
import { useContext, useState } from 'react'
import { GET_ALL_COUNTRIES_LIST } from '../../graphql/queries'

const AvailabilityChecker = (props: Props) => {
  const [countriesList, setCountriesList] = useState([])
  const { isFlyingFlowers } = useContext(SiteContext)
  const analytics = useContext(AnalyticsContext)

  //Get all countries list for international link
  useQuery(GET_ALL_COUNTRIES_LIST, {
    onCompleted: (response) => {
      const countryList = response.getAllCountriesList.countriesList
      setCountriesList(countryList)
    },
    skip: isFlyingFlowers,
  })

  useLoaded(props)

  return (
    <AvailabilityCheckerUI
      countriesList={countriesList}
      backgroundColor={props.backgroundColor}
      clickPostCodeChecker={() => analytics.postCodeCheckerOpen()}
      trackPostCodeCheckerDate={() => analytics.postCodeCheckerDate()}
      trackPostCodeCheckerAddress={(pcc_postcode: string, pcc_city: string, pcc_county: string) =>
        analytics.postCodeCheckerAddressSuccess(pcc_postcode, pcc_city, pcc_county)
      }
      trackPostCodeCheckerManualAddress={() => analytics.postCodeCheckerAddressManual()}
      trackPostCodeCheckerOccasion={(occasion: string) => analytics.postCodeCheckerOccasionSelected(occasion)}
      submitPostCodeCheckerComplete={() => analytics.postCodeCheckerComplete()}
      trackEnterManualAddressClick={(click_location) => analytics.trackEnterManualAddressClick(click_location)}
      locationInternationalDelivery={(click_location: string) => {
        analytics.doYouWantInternationalDelivery(click_location)
      }}
    />
  )
}

export default AvailabilityChecker
