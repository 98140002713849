import { makeStyles } from 'tss-react/mui';
import { fontWeights, spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    body: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: spacing.large, textAlign: 'center', color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: spacing.xl }), '& p:last-child': {
            marginBottom: 0,
        } }),
    roundButtonLabel: {
        [theme.breakpoints.down('md')]: {
            marginTop: spacing.small,
            padding: `0 !important`,
            textAlign: 'center',
        },
    },
    title: Object.assign(Object.assign({}, theme.typography.large_bold), { fontWeight: fontWeights.bold, marginBottom: spacing.medium, textAlign: 'center', color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    roundButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: `0 ${spacing.medium}`,
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            alignItems: 'center',
            marginRight: spacing.large,
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.medium,
        },
    },
}));
export default useStyles;
