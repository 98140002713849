import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        margin: '0 auto',
        maxWidth: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '82%',
            height: '100%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            height: 'calc(100vh - 128px)',
        },
    },
    mobileMediaContainer: {
        height: 'initial',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    mobileMediaSubContainer: {
        width: '100%',
        margin: '0 auto',
    },
    mobileMediaImageContainer: {
        '& > div': {
            '& > div': {
                height: '100%',
                '& > img:first-child': {
                    objectFit: 'cover',
                    width: '100%',
                    height: '188px',
                    [theme.breakpoints.up('sm')]: {
                        height: '428px',
                        '@media (orientation: landscape)': {
                            position: 'relative',
                        },
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '448px',
                    },
                    [theme.breakpoints.up('xl')]: {
                        height: '620px',
                    },
                },
            },
        },
    },
    transformContentClass: {
        width: '100%',
    },
    wrapperClass: {
        width: 'initial',
        display: 'flex',
    },
    mobileImageContainer: {
        '& > div': {
            height: '100%',
            '& > div': {
                '& > img:first-child': {
                    objectFit: 'cover',
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                        height: 'calc(100vh - 170px)',
                    },
                    [theme.breakpoints.between('md', 'xl')]: {
                        height: 'calc(100vh - 194px)',
                    },
                },
            },
        },
    },
    mobileZoomout: {
        '& div': {
            width: 'initial !important',
        },
        '& img': {
            [theme.breakpoints.down('xl')]: {
                height: '335px',
                '@media (orientation: landscape)': {
                    height: '335px',
                },
            },
        },
    },
    productName: Object.assign(Object.assign({ backgroundColor: colors.grey.grey1, color: colors.grey.grey6, textAlign: 'center', padding: spacing.small }, (theme.isFlyingFlowers ? theme.typography.small_regular : theme.typography.small_bold)), theme.typographySpacing.none),
    imageHeading: Object.assign(Object.assign({ backgroundColor: colors.grey.grey1, width: '100%', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, textAlign: theme.isFlyingFlowers ? 'center' : 'left', padding: theme.isFlyingFlowers ? '8px 16px !important' : '12px 16px !important' }, theme.typography.small_bold), theme.typographySpacing.none),
}));
export default useStyles;
