import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    accordianLink: Object.assign(Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_sm : theme.typographySpacing.small_regular_sm)), link()), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    accordianSummaryContent: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.small_regular)), (theme.isFlyingFlowers ? {} : link())), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? undefined : theme.typography.medium_regular)), '&.Mui-expanded': Object.assign(Object.assign({ textDecoration: 'none' }, (theme.isFlyingFlowers ? undefined : theme.typography.small_bold)), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? undefined : theme.typography.medium_bold)), '& h3': Object.assign(Object.assign({ textDecoration: 'none !important', margin: '0px' }, (theme.isFlyingFlowers ? undefined : theme.typography.small_bold)), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? undefined : theme.typography.medium_bold)) }) }), '& h3': Object.assign(Object.assign(Object.assign({ margin: '0px' }, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.small_regular)), (theme.isFlyingFlowers ? {} : link())), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? undefined : theme.typography.medium_regular)) }) }),
    accordianSummaryRoot: {
        '&.Mui-expanded': {
            backgroundColor: theme.isFlyingFlowers ? `${colors.black[200]} !important` : undefined,
        },
        borderRadius: theme.isFlyingFlowers ? spacing.xs : 'none',
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : undefined,
        minHeight: spacing.xxl,
        width: '100% !important',
        padding: '0px 16px !important',
    },
    accordianAnswer: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, '& > p': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { marginBottom: `${spacing.xs}!important` }), '& ul': { marginTop: '0px', marginBottom: spacing.xs }, '& ul li': Object.assign({}, theme.typography.small_regular), '& a': {
            color: theme.isFlyingFlowers ? undefined : colors.primary.gold5,
        }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({ '& p': Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : undefined)), '& ul li': Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : undefined)) }, (theme.isFlyingFlowers ? theme.typography.medium_regular : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_lg : undefined)) }),
    accordian: {
        color: colors.grey.grey6,
        borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.supplementary.black}`,
        paddingLeft: '0!important',
        borderBottomLeftRadius: '0!important',
        borderBottomRightRadius: '0!important',
        boxShadow: 'none',
        backgroundColor: 'unset',
        margin: theme.isFlyingFlowers ? `0 0 ${spacing.xs} 0` : '0!important',
        '&:before': { backgroundColor: 'unset' },
        '&.Mui-expanded:last-child': { borderBottom: '0 !important' },
        '&.MuiAccordion-root.Mui-expanded': { margin: '0 0 8px' },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: theme.isFlyingFlowers ? 'unset' : 'rotate(360deg)',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
        '& .MuiAccordionDetails-root': {
            paddingBottom: '0px',
        },
    },
    accordianIconClosed: {
        color: `${colors.grey.grey5}`,
        fontSize: '24px',
    },
    accordianIconOpen: {
        color: colors.supplementary.black,
        fontSize: '24px',
    },
    flyingFlowersIcon: {
        padding: spacing.xs,
        border: `1px solid ${colors.wildExotic[400]}`,
        backgroundColor: colors.supplementary.white,
        borderRadius: '50%',
        '& .material-icons': { color: colors.wildExotic[400], fontSize: '14px' },
    },
}));
export default useStyles;
