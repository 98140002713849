import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../utils/common';
import Link from '../Link/Link';
import useStyles from './LinkGallery.styles';
import { useRouter } from 'next/router';
import { useLoaded } from '../MainContent/useComponentLoading';
// Supported grid layouts
const gridLayoutCols = [0, 1, 2, 3, 4, 6, 3, 12, 4, 12];
const LinkGallery = (props) => {
    const { className, title, description, titleTag, sendLinkGalleryTextAnalytics } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const [analyticsItem, setAnalyticsItem] = useState();
    const router = useRouter();
    const { classes } = useStyles();
    const links = props.links || props.items;
    const CustomTitleTag = titleTag ? `${titleTag === null || titleTag === void 0 ? void 0 : titleTag.toLowerCase()}` : 'div';
    // Data for GA4 click event
    useEffect(() => {
        var _a;
        if ((_a = router === null || router === void 0 ? void 0 : router.asPath) === null || _a === void 0 ? void 0 : _a.endsWith('page/world-cup')) {
            const analyticsItem = {
                category: 'FWC23',
                action: 'Hero',
                label: 'Hub',
            };
            setAnalyticsItem(analyticsItem);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [router === null || router === void 0 ? void 0 : router.asPath]);
    // Remove links if too many for the supported grid layouts
    const gridLinks = (links === null || links === void 0 ? void 0 : links.slice(0, gridLayoutCols.length - 1)) || [];
    const onlyTitle = !!title && !(links === null || links === void 0 ? void 0 : links.length) && !description;
    const onlyDescription = !(links === null || links === void 0 ? void 0 : links.length) && !!description;
    const removeMarginBottom = onlyTitle || onlyDescription;
    useLoaded(props);
    return (_jsxs("div", { className: clsx(classes.gallery, removeMarginBottom ? classes.removeMarginBottom : ''), children: [_jsxs(_Fragment, { children: [title && (_jsx(CustomTitleTag, { className: clsx(classes.title, onlyTitle ? classes.removeMarginBottom : ''), children: title })), description && (_jsx("p", { className: clsx(classes.description, onlyDescription ? classes.removeMarginBottom : ''), children: description }))] }), _jsx("div", { className: clsx(props.className), children: _jsx("div", { className: clsx(classes.grid), children: gridLinks.map((link, i) => (_jsx("div", { className: clsx(classes.linkContainer, links && (links === null || links === void 0 ? void 0 : links.length) <= 2 ? classes.singleLinkContainer : ''), "data-item": analyticsItem ? JSON.stringify(analyticsItem) : undefined, children: _jsx(Link, Object.assign({ className: classes.link }, link, { variant: isFlyingFlowers ? 'secondary' : 'primary', button: true, tabIndex: -1, sendLinkGalleryTextAnalytics: sendLinkGalleryTextAnalytics })) }, `${link.label}${i}`))) }) })] }));
};
export default LinkGallery;
