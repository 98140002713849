import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Select from '../Select/Select';
import Button from '../Button/Button';
import useStyles from './ProductCountriesList.styles';
import { errorMessages } from '../../utils/common';
import { useRouter } from 'next/router';
import clsx from 'clsx';
const validationConfig = Yup.object().shape({
    country: Yup.string().required(errorMessages().required),
});
const ProductCountriesList = (props) => {
    const { classes } = useStyles();
    const router = useRouter();
    const formik = {
        initialValues: {
            country: '',
        },
        validationSchema: validationConfig,
        onSubmit: (value) => {
            router.push(value.country);
        },
    };
    const countriesList = props.countriesList.map((country) => ({ label: country.label, value: country.url }));
    return (_jsxs("div", { className: clsx(classes.container, props.isFromPCCDelivery ? classes.adjustContainer : ''), children: [!props.isFromPCCDelivery ? _jsx("div", { className: classes.modalTitle, children: "International delivery" }) : '', _jsx("div", { className: clsx(classes.title, props.isFromPCCDelivery ? classes.alignModalTitle : ''), children: "Select the country that you would like delivery to and we will show you a selection of available gifts." }), _jsx("div", { className: clsx(classes.countryDropdown, props.isFromPCCDelivery ? classes.adjustCountryDropdown : ''), children: _jsx(Formik, Object.assign({}, formik, { children: _jsxs(Form, { children: [_jsx(Select, { label: "Country", placeholder: props.isFromPCCDelivery ? 'Where would you like delivery to?' : 'Where you would like delivery to?', name: "country", items: countriesList, required: true }), _jsx(Button, { isSubmit: true, variant: "primary", thin: true, title: "See available gifts", className: clsx(classes.saveButton, props.isFromPCCDelivery ? classes.adjustSaveButton : '') })] }) })) })] }));
};
export default ProductCountriesList;
