import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-62px',
    },
    modalTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { color: colors.grey.grey6, fontWeight: 325, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm), { fontWeight: 325 }) }),
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { fontWeight: 325, marginBottom: spacing.large, color: colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { fontWeight: 325 }) }),
    countryDropdown: {
        '& form': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '45ch',
                },
            },
            '& > button': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: 'initial',
                },
            },
        },
    },
    adjustCountryDropdown: {
        marginTop: 0,
        [theme.breakpoints.up('md')]: {
            marginTop: spacing.large,
        },
        '& form': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '520px',
                },
            },
            '& > button': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: 'initial',
                },
            },
        },
    },
    saveButton: {
        marginTop: spacing.medium,
    },
    alignModalTitle: Object.assign(Object.assign(Object.assign({ marginLeft: '0' }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.grey.grey6, [theme.breakpoints.up('md')]: {
            marginLeft: '84px',
            [theme.breakpoints.up('lg')]: {
                marginLeft: '136px',
            },
        } }),
    adjustContainer: {
        marginTop: 0,
        padding: '0 20px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '40px',
        },
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    adjustSaveButton: {
        marginTop: spacing.large,
    },
}));
export default useStyles;
