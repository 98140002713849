import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useStyles from './SpotlightBanner.styles';
import SpotlightBannerText from './SpotlightBannerText';
import SpotlightBannerImage from './SpotlightBannerImage';
import Button from '../Button/Button';
import { useMobileDeviceCheck } from '../../utils/utilityCustomHooks';
const SpotlightBannerDesktop = (props) => {
    const { transparentOverlay, buttonLink } = props;
    const { title, body, handleClick } = props;
    const { classes } = useStyles({ transparentOverlay });
    const { url, label } = buttonLink !== null && buttonLink !== void 0 ? buttonLink : {};
    const { isMobile: isMobileDevice } = useMobileDeviceCheck();
    const renderContent = transparentOverlay ? (_jsx("div", { className: classes.imageContainer, children: _jsxs(_Fragment, { children: [_jsx(SpotlightBannerImage, Object.assign({}, props)), _jsxs("div", { className: classes.overlayTextContainerDesktop, children: [_jsx(SpotlightBannerText, { title: title, body: body }), label && url && (_jsx(Button, { onClick: handleClick, title: label, variant: 'primary', thin: true, icon: '', className: classes.linkButton }))] })] }) })) : (_jsxs("div", { className: classes.imageContainer, children: [_jsx(SpotlightBannerImage, Object.assign({}, props)), _jsxs("div", { className: classes.bodyContainer, children: [_jsx(SpotlightBannerText, { title: title, body: body }), url && (_jsx(Button, { onClick: handleClick, title: label, variant: 'primary', thin: true, icon: '', className: classes.linkButton }))] })] }));
    return !isMobileDevice ? renderContent : null;
};
export default SpotlightBannerDesktop;
