import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    orderHistoryFarEyeImageContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '336px',
        [theme.breakpoints.up('sm')]: {
            height: '336px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '288px',
            height: '288px',
        },
    },
    orderHistoryFarEyeImage: {
        width: 'auto',
        maxHeight: '100%',
        margin: 'auto',
        maxWidth: '100%',
        height: 'auto',
    },
    orderDetailsFarEyeImageContainer: {
        [theme.breakpoints.up('md')]: {
            height: '336px',
            width: '336px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
export default useStyles;
