import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import ProductImage from '../ProductImage/ProductImage';
import Tags from '../Tags/Tags';
import useStyles from './RelatedContentItem.styles';
import { MAX_CARD_WIDTH } from '../../styles/jss/utils';
const RelatedContentItem = (props) => {
    const { message, tag, image, link, sendRelatedContentGalleryAnalytics } = props;
    const { classes } = useStyles();
    return (_jsxs(Card, { className: classes.relatedContentCard, children: [(link === null || link === void 0 ? void 0 : link.url) ? (_jsx(Link, { href: link.url, passHref: true, legacyBehavior: true, children: _jsx("a", { target: link.newWindow ? '_blank' : '_self', children: _jsx(ProductImage, { altText: image === null || image === void 0 ? void 0 : image.altText, imageUrl: image === null || image === void 0 ? void 0 : image.url, maxWidth: MAX_CARD_WIDTH, imageWidths: [MAX_CARD_WIDTH], loading: "lazy" }) }) })) : (_jsx(ProductImage, { altText: image === null || image === void 0 ? void 0 : image.altText, imageUrl: image === null || image === void 0 ? void 0 : image.url, maxWidth: MAX_CARD_WIDTH, imageWidths: [MAX_CARD_WIDTH], loading: "lazy" })), _jsxs("div", { className: classes.textContent, children: [tag &&
                        ((link === null || link === void 0 ? void 0 : link.url) ? (_jsx(Link, { href: link.url, passHref: true, legacyBehavior: true, children: _jsx("a", { href: link.url, target: link.newWindow ? '_blank' : '_self', onClick: () => sendRelatedContentGalleryAnalytics === null || sendRelatedContentGalleryAnalytics === void 0 ? void 0 : sendRelatedContentGalleryAnalytics(tag), children: _jsx(Tags, { className: clsx(classes.tag, !message ? classes.adjustTagMargin : ''), tags: [`${tag}`], isFromRelatedContentItem: true }) }) })) : (_jsx(Tags, { className: clsx(classes.tag, !message ? classes.adjustTagMargin : ''), tags: [`${tag}`], isFromRelatedContentItem: true }))), message &&
                        (!!link && link.url ? (_jsx(Link, { href: link.url, passHref: true, legacyBehavior: true, children: _jsx("a", { href: link.url, target: link.newWindow ? '_blank' : '_self', className: classes.messageContainer, onClick: () => sendRelatedContentGalleryAnalytics === null || sendRelatedContentGalleryAnalytics === void 0 ? void 0 : sendRelatedContentGalleryAnalytics(message), children: _jsx("span", { className: clsx(classes.message, classes.link), children: message }) }) })) : (_jsx("div", { className: classes.messageContainer, children: _jsx("span", { className: classes.message, children: message }) })))] })] }));
};
export default RelatedContentItem;
