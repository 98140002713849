import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Carousel from '../Carousel/Carousel';
import useStyles from './ImageZoomInGallery.styles';
import RoundButton from '../RoundButton/RoundButton';
import ZoomInImage from '../ZoomInImage/ZoomInImage';
import { useTheme } from '@mui/material';
export const getResponsiveList = (theme) => {
    const { sm, md, xl } = theme.breakpoints.values;
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: 1,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 1,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 1,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    return responsive;
};
export const CustomButtonGroup = ({ next, previous }) => {
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.customButtonGroup, children: [_jsx(RoundButton, { className: classes.leftButton, ariaLabel: 'chevron left arrow', icon: 'chevron_left', onClick: previous }), _jsx(RoundButton, { ariaLabel: 'chevron right arrow', icon: 'chevron_right', onClick: next, className: classes.rightButton })] }));
};
const ImageZoomInGallery = ({ images }) => {
    const carouselRef = React.createRef();
    const { classes } = useStyles();
    const [isMobile, setIsMobile] = useState(true);
    const theme = useTheme();
    const [currentSlide, setCurrentSlide] = useState(1);
    const responsive = getResponsiveList(theme);
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    const updateCurrentState = () => setCurrentSlide(Math.random());
    const deviceType = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator === null || navigator === void 0 ? void 0 : navigator.userAgent)) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
    };
    useEffect(() => {
        window === null || window === void 0 ? void 0 : window.addEventListener('orientationchange', updateCurrentState);
        return () => {
            window === null || window === void 0 ? void 0 : window.removeEventListener('orientationchange', updateCurrentState);
        };
    }, []);
    useEffect(() => {
        deviceType();
    }, []);
    return (_jsx(_Fragment, { children: !!(images === null || images === void 0 ? void 0 : images.length) && (_jsx("div", { className: classes.carouselContainer, children: _jsx(Carousel, { containerClass: classes.carousel, sliderClass: classes.slider, responsive: responsive, arrows: false, autoPlay: false, itemClass: classes.carouselItem, ssr: true, showDots: false, renderDotsOutside: true, infinite: true, ref: carouselRef, partialVisible: false, customButtonGroup: _jsx(CustomButtonGroup, { next: moveToNextSlide, previous: moveToPreviouSlide }), renderButtonGroupOutside: true, carouselContainerClass: classes.carouselContainer, swipeable: false, draggable: false, afterChange: updateCurrentState, children: images.map((image) => (_jsx(ZoomInImage, { image: image, isMobile: isMobile }, currentSlide))) }) })) }));
};
export default ImageZoomInGallery;
