import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useState } from 'react';
import useStyles from './PromoContentGallery.styles';
import PromoContentItem from '../PromoContentItem/PromoContentItem';
import Alert from '../Alert/Alert';
const PromoContentGallery = ({ title, description, items, addDeliveryPassToCart, sendPromoContentGalleryAnalytics, }) => {
    const { classes } = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const halfWidth = items && (items === null || items === void 0 ? void 0 : items.length) % 2 === 0;
    const addDeliveryPass = (sku) => {
        addDeliveryPassToCart &&
            addDeliveryPassToCart(sku)
                .then(() => setErrorMessage(''))
                .catch((error) => {
                setErrorMessage(error.message);
            });
    };
    return (_jsxs("div", { className: classes.gallery, children: [_jsxs("div", { className: classes.headingSection, children: [title && _jsx("h2", { className: classes.title, children: title }), description && _jsx("p", { children: description })] }), errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), !!(items === null || items === void 0 ? void 0 : items.length) && (_jsx(Grid, { container: true, spacing: 3, direction: "row", className: classes.productGrid, children: items.map((item, index) => (_jsx(Grid, { className: classes.gridItem, item: true, md: halfWidth ? 6 : 4, children: _jsx(PromoContentItem, Object.assign({}, item, { itemIndex: index, halfWidth: halfWidth, addDeliveryPassToCart: addDeliveryPass, sendPromoContentGalleryAnalytics: sendPromoContentGalleryAnalytics })) }, `${index}${item.heading}`))) }))] }));
};
export default PromoContentGallery;
