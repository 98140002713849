import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {},
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    message: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    list: {
        marginBlock: '0px',
        marginInline: '0px',
        paddingInlineStart: '112px',
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), { paddingInlineStart: '0px', display: 'flex' }),
        position: 'relative',
        listStyle: 'none',
        counterReset: 'counter',
    },
    item: {
        listStyle: 'none',
        counterIncrement: 'counter',
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            flexBasis: '0',
            '&:not(:last-child)': {
                marginRight: spacing.medium,
            },
        },
        '&::before': Object.assign(Object.assign({ content: 'counter(counter) "." ', textAlign: 'center' }, theme.typography.xl_regular), { color: colors.supplementary.black, backgroundColor: colors.grey.grey1, left: '0px', width: '72px', position: 'absolute', paddingTop: '6px', paddingBottom: '6px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({ position: 'relative', display: 'block' }, theme.typography.xxxl), { width: '112px', marginBottom: spacing.medium }) }),
    },
    itemHeading: Object.assign(Object.assign({}, theme.typography.medium_bold), { paddingTop: spacing.small, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
    itemContent: {
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
        '& a': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    },
    linkContainer: { textAlign: 'center' },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            '& button': {
                flexGrow: 1,
            },
        },
    },
}));
export default useStyles;
