import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './OrderSummary.styles';
import format from 'date-fns/format';
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material';
const OrderSummary = (props) => {
    var _a, _b;
    const { order, farEyeTrackOrderAnalytics } = props;
    const theme = useTheme();
    const { createdAt, totalPrice, orderStatusMessage, orderStatusStep = 0, orderStatusIconColor, isFarEye, consignments, } = order;
    const { orderTracking, orderNumber, isDigital, isClickAndCollect } = consignments[0];
    const { classes } = useStyles({ isClickAndCollect: !!isClickAndCollect });
    const deliveryMessage = isClickAndCollect
        ? 'Ready to collect'
        : isFarEye
            ? 'Order being delivered today'
            : 'Being delivered today';
    const orderCompletedMessage = isClickAndCollect ? 'Collected' : 'Delivered';
    //Stepper Messages
    const statusMessageStep1 = orderStatusStep === 1 ? orderStatusMessage : 'Order received';
    const statusMessageStep2 = orderStatusStep === 2 ? orderStatusMessage : `${deliveryMessage}`;
    const statusMessageStep3 = orderStatusStep === 3 ? orderStatusMessage : orderCompletedMessage;
    const trackingLink = orderTracking === null || orderTracking === void 0 ? void 0 : orderTracking.trackingUrl;
    const deliveryPassProduct = (_a = order.consignments) === null || _a === void 0 ? void 0 : _a.find((consignment) => consignment.isDigital);
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    //rendering the steps to reduce repeated code
    const renderTrackerStep = (currentStep = 0, message, iconColor) => {
        const isCompleted = orderStatusStep > currentStep;
        const isActive = orderStatusStep === currentStep;
        //rendering the icons based on the action
        const getStatusIcon = () => {
            if (isActive && iconColor === 'red') {
                return 'info_icon';
            }
            else if (isActive || isCompleted) {
                return 'check_circle_icon';
            }
            else {
                return 'circle_icon';
            }
        };
        // Only show tracking link at step 2 OR step 3 but not both
        let showTrackingLink = false;
        if (currentStep === orderStatusStep &&
            (currentStep === 2 || currentStep === 3) &&
            isFarEye &&
            !isDigital &&
            trackingLink) {
            showTrackingLink = true;
        }
        const handlefarEyeTrackOrderAnalyticsCheck = (trackingLink) => {
            farEyeTrackOrderAnalytics === null || farEyeTrackOrderAnalytics === void 0 ? void 0 : farEyeTrackOrderAnalytics(trackingLink);
        };
        return (_jsxs("div", { className: classes.orderTrackerStep, children: [_jsx("div", { className: classes.orderTrackerIcon, children: _jsx(Icon, { className: clsx(classes.iconSize, isActive ? classes.activeIconColor : classes.inActiveIconColor, isCompleted && classes.completedStep, isActive && iconColor === 'red' && classes.failureStatusIconColor), icon: getStatusIcon() }) }), _jsxs("div", { children: [_jsx("div", { className: clsx(orderStatusStep >= currentStep ? classes.orderTrackerStepActive : classes.orderTrackerStepInActive), children: message }), !!showTrackingLink && trackingLink && (_jsx("a", { className: classes.orderTrackingLink, href: trackingLink, target: "_blank", onClick: () => {
                                handlefarEyeTrackOrderAnalyticsCheck(trackingLink);
                            }, children: !isLargerViewport ? 'Track order' : 'Track your order' }))] })] }));
    };
    return (_jsxs("div", { className: classes.container, children: [_jsxs("div", { children: [_jsx("div", { className: classes.orderSummary, children: _jsx("div", { className: classes.orderSummaryHeading, children: _jsx("div", { children: "Order summary" }) }) }), _jsxs("div", { className: classes.summaryDetails, children: [_jsxs("div", { className: classes.orderSummaryElements, children: [_jsx("p", { className: classes.orderLabel, children: "Order number:" }), _jsx(Link, { className: classes.navigationLink, label: `${orderNumber}`, onClick: () => {
                                            var _a;
                                            (_a = document.getElementById(`order_${orderNumber}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                                        }, noFollow: true })] }), _jsxs("div", { className: classes.orderTrackerContainer, children: [_jsx("div", { className: classes.statusBorder }), _jsxs("div", { className: classes.orderTrackerstepper, children: [renderTrackerStep(1, statusMessageStep1), isClickAndCollect && renderTrackerStep(1.5, 'Order is with the florist'), renderTrackerStep(2, statusMessageStep2, orderStatusIconColor), renderTrackerStep(3, statusMessageStep3, orderStatusIconColor)] })] }), _jsxs("div", { className: classes.orderSummaryElements, children: [_jsx("p", { className: classes.orderLabel, children: "Date placed:" }), _jsx("p", { className: classes.orderCreatedOn, children: _jsx("span", { children: format(new Date(createdAt), 'EEEE do MMMM yyy') }) })] }), _jsxs("div", { className: classes.orderSummaryElements, children: [_jsx("p", { className: classes.orderLabel, children: "Order total:" }), _jsx("p", { className: classes.orderTotal, children: totalPrice.formatted })] })] })] }), !!deliveryPassProduct && (_jsxs("div", { className: classes.deliveryPass, children: [_jsxs("p", { className: classes.deliveryPassProduct, children: [(_b = deliveryPassProduct.items[0].lineItem) === null || _b === void 0 ? void 0 : _b.name, " Delivery Pass order number:"] }), _jsx("p", { className: classes.deliverPassOrderNumber, children: deliveryPassProduct.orderNumber })] }))] }));
};
export default OrderSummary;
