import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    hide: {
        display: 'none',
    },
    show: {
        display: 'block',
    },
    productListing: {
        marginBottom: '64px',
    },
    additional: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            width: 'fit-content',
            display: 'block',
        },
    },
    productGrid: {
        marginBottom: '20px !important',
        [theme.breakpoints.up('md')]: {
            marginBottom: '50px !important',
        },
    },
    loaderIcon: {
        height: '50px',
        width: '50px',
        [theme.breakpoints.up('sm')]: {
            height: '60px',
            width: '60px',
        },
    },
}));
export default useStyles;
