import { colors, spacing } from './variables';
export const addEditAddressStyles = (theme) => ({
    linkText: Object.assign(Object.assign({}, theme.typography.small_regular), { border: 'none !important', boxShadow: 'none !important', color: colors.primary.gold5, cursor: 'pointer', textDecoration: 'underline !important', '& span': {
            fontWeight: theme.typography.medium_regular.fontWeight,
        }, '&:hover': {
            backgroundColor: 'transparent !important',
        } }),
    countryTitle: {
        color: colors.supplementary.black,
        marginTop: '0px',
        marginBottom: spacing.xs,
        cursor: 'pointer',
    },
    countryDisplay: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { color: colors.grey.grey7 }),
});
