import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../../styles/jss/variables';
const MAX_CARD_WIDTH = '384px';
const CARD_MARGIN = '12px';
const useStyles = makeStyles()((theme) => ({
    slider: {
        display: 'flex',
        flexDirection: 'row',
    },
    carousel: {
        margin: '0 auto',
        padding: `3px 3px ${spacing.small} 3px`,
    },
    carouselItem: {
        margin: 0,
        display: 'flex',
        '& > div': {
            margin: `0 ${CARD_MARGIN}`,
        },
    },
    'custom-dot-list-style': {
        position: 'relative',
        top: '35px',
        '& li:last-child button': {
            marginRight: '0',
        },
    },
    carouselContainer: {
        margin: '0 auto',
        width: '100%',
        maxWidth: `calc(${MAX_CARD_WIDTH} + ${CARD_MARGIN})`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 2)`,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 3)`,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: `calc((${MAX_CARD_WIDTH} + ${CARD_MARGIN}) * 4)`,
        },
    },
}));
export default useStyles;
