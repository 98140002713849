import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
const HEIGHT_BELOW_HEADER = 136;
const HEIGHT_BELOW_HEADER_SM_UP = 148;
const HEIGHT_BELOW_HEADER_CONDENSED = 63;
const HEIGHT_BELOW_HEADER_CONDENSED_SM_UP = 71;
const HEIGHT_BELOW_HEADER_FF = 96;
const useStyles = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: `-33px 0 ${spacing.small} 0`,
    },
    contentContainer: {
        minWidth: '445px',
        height: '100%',
        padding: spacing.xl,
        '& h3': Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: 0, maxWidth: '300px', color: theme.isFlyingFlowers ? colors.black[700] : undefined }),
    },
    headerTitle: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: 0, maxWidth: '300px', color: theme.isFlyingFlowers ? colors.black[700] : undefined }),
    menuContainer: {
        minWidth: 'calc(100vw - 24px)',
        padding: 0,
    },
    modalBackDrop: {
        top: `${HEIGHT_BELOW_HEADER}px !important`,
        '& .MuiBackdrop-root': {
            top: HEIGHT_BELOW_HEADER,
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
        '& .MuiDrawer-paper': {
            top: HEIGHT_BELOW_HEADER,
            right: spacing.medium,
            boxShadow: 'initial',
        },
        [theme.breakpoints.up('sm')]: {
            top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : `${HEIGHT_BELOW_HEADER}px !important`,
            '& .MuiBackdrop-root': {
                top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : HEIGHT_BELOW_HEADER_SM_UP,
                backgroundColor: 'rgba(0,0,0,0.6)',
            },
            '& .MuiDrawer-paper': {
                top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : HEIGHT_BELOW_HEADER_SM_UP,
                right: spacing.medium,
            },
        },
    },
    adjustHeader: {
        top: theme.isFlyingFlowers
            ? `${HEIGHT_BELOW_HEADER_FF}px !important`
            : `${HEIGHT_BELOW_HEADER_CONDENSED}px !important`,
        '& .MuiBackdrop-root': {
            top: theme.isFlyingFlowers ? `${HEIGHT_BELOW_HEADER_FF}px !important` : HEIGHT_BELOW_HEADER_CONDENSED,
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
        '& .MuiDrawer-paper': {
            top: theme.isFlyingFlowers ? `${HEIGHT_BELOW_HEADER_FF}px !important` : HEIGHT_BELOW_HEADER_CONDENSED,
            right: spacing.medium,
            boxShadow: 'initial',
        },
        [theme.breakpoints.up('sm')]: {
            top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : `${HEIGHT_BELOW_HEADER_CONDENSED_SM_UP}px !important`,
            '& .MuiBackdrop-root': {
                top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : HEIGHT_BELOW_HEADER_CONDENSED_SM_UP,
                backgroundColor: 'rgba(0,0,0,0.6)',
            },
            '& .MuiDrawer-paper': {
                top: theme.isFlyingFlowers ? HEIGHT_BELOW_HEADER : HEIGHT_BELOW_HEADER_CONDENSED_SM_UP,
                right: spacing.medium,
            },
        },
    },
}));
export default useStyles;
