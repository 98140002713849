import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './Title.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const Title = (props) => {
    const { classes } = useStyles();
    const { title, headingTag = 'H2' } = props;
    const HeadingTag = headingTag.toLowerCase();
    useLoaded(props);
    return _jsx(HeadingTag, { className: classes.title, children: title });
};
export default Title;
