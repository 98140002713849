var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { RawHtml } from '..';
import GenericContent from '../GenericContent/GenericContent';
import useGenericStyles from '../GenericContent/GenericContent.styles';
import Link from '../Link/Link';
import useStyles from './FFHomeVideoBanner.styles';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
const FFHomeVideoBanner = (props) => {
    const genericContentProps = __rest(props, []);
    const { message, links, headingColor = 'COBALT', contentPosition = 'LEFT', videoSchemaSEO } = props;
    const { classes } = useStyles();
    const { classes: genericClasses } = useGenericStyles({});
    const heading = typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line, index) => (_jsx("span", { className: clsx(classes.headingLine, headingColor && classes[headingColor], contentPosition === 'CENTER' ? classes.centerHeading : ''), children: line.trim() }, index)))
        : props.heading;
    return (_jsxs(_Fragment, { children: [!!videoSchemaSEO ? (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps(JSON.parse(videoSchemaSEO)))) })) : null, _jsx(GenericContent, Object.assign({}, genericContentProps, { heading: heading, classNames: classes, headingLevel: "NONE", linkStyle: "BUTTON", textAlignment: contentPosition === 'CENTER' ? 'CENTER' : 'LEFT', contentPosition: contentPosition, mobileContentPosition: "OVER", minHeight: "LARGE", isFFHeroVideo: true, showTrustpilotWidget: props.showTrustpilotWidget, trustpilotBackground: props.trustpilotBackground, trustPilotDarknessLevel: props.trustPilotDarknessLevel })), _jsx(RawHtml, { className: clsx(classes.message, classes.ffMessage), content: message }), !!(links === null || links === void 0 ? void 0 : links.length) && (_jsx("div", { className: clsx(genericClasses.linkContainer, classes.outsideLinkContainer), children: links.map((link, index) => (_jsx(Link, Object.assign({ button: true, variant: props.buttonVariant || 'primary', thin: true, className: clsx(genericClasses.link, genericClasses.linkButton, classes.link) }, link), index))) })), props.showTrustpilotWidget && (_jsx("div", { className: clsx(classes.outsideShowTrustpilotWidget), children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) }))] }));
};
export default FFHomeVideoBanner;
