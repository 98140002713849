import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import clsx from 'clsx';
import Icon from '../../Icon/Icon';
import useStyles from './Quotation.styles';
const Quotation = (props) => {
    const { isQuotation, heading, headingColor, titleTextVariant, classNames, headingTag } = props;
    const { classes } = useStyles({ headingColor: headingColor });
    if (!isQuotation) {
        return heading ? (_jsx(Box, { className: clsx(headingColor ? classes === null || classes === void 0 ? void 0 : classes.headingColor : '', classes === null || classes === void 0 ? void 0 : classes.noBottomMargin, titleTextVariant === 'LARGE_ADVERTISING' ? classNames === null || classNames === void 0 ? void 0 : classNames.largeAdvertisingHeading : classNames === null || classNames === void 0 ? void 0 : classNames.standardHeading), component: headingTag, children: heading })) : null;
    }
    return (_jsxs(Box, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.contentFeatureQuoteContainer, isQuotation ? classes === null || classes === void 0 ? void 0 : classes.contentFeatureTitle : ''), children: [isQuotation && (_jsx(Icon, { icon: "format_quote", className: clsx(classes === null || classes === void 0 ? void 0 : classes.quotationMark, classes === null || classes === void 0 ? void 0 : classes.openQuote, classes === null || classes === void 0 ? void 0 : classes.contentFeatureQuote) })), heading && (_jsx(Box, { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.mediaHeading, headingColor ? classes === null || classes === void 0 ? void 0 : classes.headingColor : '', classes === null || classes === void 0 ? void 0 : classes.noBottomMargin, titleTextVariant === 'LARGE_ADVERTISING' ? classNames === null || classNames === void 0 ? void 0 : classNames.largeAdvertisingHeading : classNames === null || classNames === void 0 ? void 0 : classNames.standardHeading), component: headingTag, children: heading })), isQuotation && (_jsx(Icon, { icon: "format_quote", className: clsx(classes === null || classes === void 0 ? void 0 : classes.quotationMark, classes === null || classes === void 0 ? void 0 : classes.closeQuote, classes === null || classes === void 0 ? void 0 : classes.contentFeatureQuote) }))] }));
};
export default Quotation;
