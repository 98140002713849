import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            alignItems: 'unset',
        },
    },
    heading: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.medium, alignItems: 'center', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: spacing.large }) }),
}));
export default useStyles;
