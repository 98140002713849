var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import CheckCircle from '../CheckCircle/CheckCircle';
import useStyles from './ProductPageAddressCard.styles';
import { FormikContext } from 'formik';
import clsx from 'clsx';
import { addressFields } from '../../utils/common';
import Icon from '../Icon/Icon';
const AddressCard = (props) => {
    const { setAddressBookId, addressBookId, savedAddress, className, setFormikContextValues, setIsChangeAddress, setIsVariantChange, setHasMissingAddrField, isFlyingFlowers, isFromBasketUpdate, isFromBasket, cardDivRef, } = props;
    const { firstName, id, addressLine1, isDefaultAddress } = savedAddress;
    const formikContext = useContext(FormikContext);
    const { classes } = useStyles();
    const handleChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        setIsChangeAddress && setIsChangeAddress(false);
        setIsVariantChange && setIsVariantChange(false);
        const target = e.target.value;
        setAddressBookId(target);
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isChooseAddress', false);
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('shouldValidatePostCode', false);
        if (savedAddress && !!(savedAddress === null || savedAddress === void 0 ? void 0 : savedAddress.id) && savedAddress.id === target) {
            setFormikContextValues(savedAddress);
        }
    });
    const hasMissingAddrField = !isFlyingFlowers && !isFromBasket && !isFromBasketUpdate && !savedAddress.phone;
    return (_jsx(_Fragment, { children: !!firstName && !!addressLine1 && (_jsx("div", { className: clsx(classes.addressCardContainer, className), ref: cardDivRef, children: _jsx(CheckCircle, { name: "addressBook", type: "radio", value: id, checked: addressBookId === id, onChange: handleChange, controlAlignment: "top", fullWidth: true, className: classes.tickboxSection, label: _jsxs("div", { className: classes.addressContainer, children: [_jsxs("h3", { className: classes.addressNameTitle, children: [firstName && `${savedAddress === null || savedAddress === void 0 ? void 0 : savedAddress.firstName}`, " ", savedAddress === null || savedAddress === void 0 ? void 0 : savedAddress.lastName] }), _jsx("div", { className: classes.address, children: addressFields.map((addressLine, index) => {
                                const addressValue = savedAddress[addressLine];
                                return (!!addressValue && (_jsxs(React.Fragment, { children: [_jsx("span", { children: addressValue }), addressLine !== 'country' && _jsx("span", { children: ", \u00A0" })] }, `${addressLine}${index}`)));
                            }) }), isDefaultAddress && _jsx("p", { className: classes.defaultAddressLabel, children: "Default delivery address" }), hasMissingAddrField && !isFromBasket && !isFromBasketUpdate && (_jsxs("p", { className: clsx(classes.moreInfo, isDefaultAddress ? classes.moreInfoDefaultAddr : ''), children: [_jsx(Icon, { icon: "info_outline" }), _jsx("span", { className: classes.moreInfoLabel, children: "More details required" })] }))] }) }) })) }));
};
export default AddressCard;
