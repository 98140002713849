import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { default as _get } from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import FilterButton from '../FilterButton/FilterButton';
import FilterClearRefinements from '../FilterClearRefinements/FilterClearRefinements';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './FilterList.styles';
const FFFilterList = ({ filters, setFiltersClosed, totalResultCount, setFacetListOpen, refinementList, sortBy, scrollToItem, }) => {
    var _a;
    const { classes } = useStyles();
    const refArray = filters.map(() => React.createRef());
    const ulRef = React.useRef(null);
    const [scrollPosition, setScrollPosition] = useState('start');
    const [hasScrolled, setHasScrolled] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    useEffect(() => {
        const selectedFilterIndex = filters.findIndex((filter) => filter.isOpen) || 0;
        const currentRef = _get(refArray, `${selectedFilterIndex}.current`);
        const containerRef = ulRef.current;
        if (currentRef && containerRef && scrollToItem && !hasScrolled) {
            containerRef.scrollTo({ top: 0, left: currentRef.offsetLeft - 10 });
            setHasScrolled(true);
        }
    }, [refArray, filters, hasScrolled, scrollToItem, ulRef]);
    const scrollToStart = () => {
        ulRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setScrollPosition('start');
    };
    const scrollToEnd = () => {
        ulRef.current.scrollTo({ top: 0, left: ulRef.current.scrollWidth, behavior: 'smooth' });
        setScrollPosition('end');
    };
    const handleWindowResize = useCallback(() => {
        const containerRef = ulRef.current;
        if (containerRef) {
            setHasOverflow(containerRef.clientWidth < containerRef.scrollWidth);
        }
        // The linter complains about an 'unecessary dependency' but this is needed to trigger the component call
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = ulRef.current) === null || _a === void 0 ? void 0 : _a.scrollWidth]);
    useEffect(() => {
        const containerRef = ulRef.current;
        const handleScroll = () => {
            if (containerRef) {
                if (containerRef.scrollLeft + containerRef.clientWidth === containerRef.scrollWidth) {
                    setScrollPosition('end');
                }
                else if (containerRef.scrollLeft === 0) {
                    setScrollPosition('start');
                }
            }
        };
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        if (containerRef) {
            containerRef.addEventListener('scroll', handleScroll);
        }
        return function cleanup() {
            window.removeEventListener('resize', handleWindowResize);
            if (containerRef) {
                containerRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleWindowResize]);
    return (_jsxs("div", { className: classes.filterList, children: [_jsxs("div", { className: classes.filterAction, children: [_jsxs("span", { className: classes.itemCount, children: [totalResultCount, " items"] }), _jsx(FilterClearRefinements, { onClick: setFiltersClosed })] }), _jsxs("div", { className: classes.listContainer, children: [scrollPosition === 'end' && hasOverflow && (_jsx(RoundButton, { className: clsx(classes.navigationButton, classes.showLess), small: true, icon: "arrow_back_ios", ariaLabel: "Scroll to start", onClick: () => scrollToStart() })), _jsx("ul", { ref: ulRef, children: filters.map((filter, i) => {
                            const { attributeLabel, attributeName } = filter;
                            const label = refinementList && refinementList[attributeName]
                                ? `${attributeLabel} (${refinementList[attributeName].length})`
                                : attributeLabel;
                            return (_jsx("li", { ref: refArray[i], children: _jsx(FilterButton, { title: attributeName === 'sortBy' ? `Sorted by: ${sortBy}` : label, onClick: () => setFacetListOpen(attributeName), open: filter.isOpen, name: attributeName }) }, attributeName));
                        }) }), scrollPosition === 'start' && hasOverflow && (_jsx(RoundButton, { className: clsx(classes.navigationButton, classes.showMore), small: true, icon: "arrow_forward_ios", ariaLabel: "Scroll to end", onClick: () => scrollToEnd() }))] })] }));
};
export default FFFilterList;
