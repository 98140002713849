import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    links: {
        '& li': Object.assign(Object.assign({ background: colors.grey.grey2 }, link(colors.supplementary.black)), { color: colors.supplementary.black, width: '100%', maxWidth: '335px', minHeight: '60px', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', [theme.breakpoints.up('md')]: {
                maxWidth: '520px',
                minHeight: '84px',
            }, '& a': {
                textDecoration: 'underline',
                color: colors.supplementary.black,
            } }),
    },
}));
export default useStyles;
