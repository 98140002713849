import { makeStyles } from 'tss-react/mui';
import { removePaddingWithMargin } from '../../styles/jss/utils';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    floristDetails: Object.assign(Object.assign({}, theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('md')]: Object.assign({ flexBasis: '45%' }, theme.typographySpacing.none) }),
    floristName: Object.assign(Object.assign({}, theme.typography.xl_bold), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xxl_bold) }),
    bodyText: Object.assign(Object.assign({}, theme.typography.small_regular), { marginLeft: '20px', [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    floristDetailItem: Object.assign(Object.assign({ display: 'flex' }, theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('md')]: {
            marginBottom: '20px',
        }, '& a': {
            color: theme.isFlyingFlowers ? colors.wildExotic[500] : theme.statusColors.warning.dark,
            textDecoration: 'underline',
        } }),
    icon: {
        fontSize: '24px',
        [theme.breakpoints.up('md')]: {
            marginTop: '6px',
            fontSize: '32px',
        },
        '& .MuiIcon-root': {
            fontSize: '1.71rem',
        },
    },
    workingHours: {
        '& li:not(:last-child)': Object.assign({}, theme.typographySpacing.small_bold_sm),
    },
    floristSpecifications: Object.assign(Object.assign({ display: 'flex' }, theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: {
            marginBottom: '20px',
        } }),
    floristMapContainer: Object.assign(Object.assign({}, removePaddingWithMargin), { [theme.breakpoints.up('md')]: {
            flexBasis: '55%',
            marginLeft: '0 !important',
            marginRight: '0 !important',
        } }),
}));
export default useStyles;
