import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import BlogArticleCard from '../BlogArticleCard/BlogArticleCard';
import GenericGalleryGrid from '../GenericGalleryGrid/GenericGalleryGrid';
import useStyles from './BlogFeatureArticleGallery.styles';
import { SiteContext } from '../../utils/common';
import { useLoaded } from '../MainContent/useComponentLoading';
const BlogFeatureArticleGallery = (props) => {
    const { title, items } = props;
    const { blogArticleGalleryUrlAnalytics } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const breakpoints = {
        extraSmall: 12,
        small: 12,
        medium: 12,
        large: 6,
    };
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("div", { className: classes.title, children: title }), (items === null || items === void 0 ? void 0 : items.length) && (_jsx(GenericGalleryGrid, { breakpoints: breakpoints, ItemComponent: BlogArticleCard, items: items, initialVisibleItems: 2, componentProps: { halfWidth: true, alignLeft: isFlyingFlowers && true }, blogArticleGalleryUrlAnalytics: blogArticleGalleryUrlAnalytics, className: classes.blogFeatureArticleGallery }))] }));
};
export default BlogFeatureArticleGallery;
