import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './CountryFlagsGallery.styles';
import CountryFlag from '../CountryFlag/CountryFlag';
import { useLoaded } from '../MainContent/useComponentLoading';
const CountryFlagsGallery = (props) => {
    const { title, countryFlagItems } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h2", { className: classes.title, children: title }), !!(countryFlagItems === null || countryFlagItems === void 0 ? void 0 : countryFlagItems.length) && (_jsx("div", { className: classes.container, children: countryFlagItems === null || countryFlagItems === void 0 ? void 0 : countryFlagItems.map((flag) => _jsx(CountryFlag, Object.assign({}, flag), flag.name)) }))] }));
};
export default CountryFlagsGallery;
