export const billingAddressCountries = [
    {
        codeAlpha2: 'AL',
        codeAlpha3: 'ALB',
        name: 'Albania',
    },
    {
        codeAlpha2: 'DZ',
        codeAlpha3: 'DZA',
        name: 'Algeria',
    },
    {
        codeAlpha2: 'AD',
        codeAlpha3: 'AND',
        name: 'Andorra',
    },
    {
        codeAlpha2: 'AO',
        codeAlpha3: 'AGO',
        name: 'Angola',
    },
    {
        codeAlpha2: 'AG',
        codeAlpha3: 'ATG',
        name: 'Antigua',
    },
    {
        codeAlpha2: 'AR',
        codeAlpha3: 'ARG',
        name: 'Argentina',
    },
    {
        codeAlpha2: 'AM',
        codeAlpha3: 'ARM',
        name: 'Armenia',
    },
    {
        codeAlpha2: 'AW',
        codeAlpha3: 'ABW',
        name: 'Aruba',
    },
    {
        codeAlpha2: 'AU',
        codeAlpha3: 'AUS',
        name: 'Australia',
    },
    {
        codeAlpha2: 'AT',
        codeAlpha3: 'AUT',
        name: 'Austria',
    },
    {
        codeAlpha2: 'AZ',
        codeAlpha3: 'AZE',
        name: 'Azerbaijan',
    },
    {
        codeAlpha2: 'PT-20',
        codeAlpha3: '',
        name: 'Azores',
    },
    {
        codeAlpha2: 'BS',
        codeAlpha3: 'BHS',
        name: 'Bahamas',
    },
    {
        codeAlpha2: 'BH',
        codeAlpha3: 'BHR',
        name: 'Bahrain',
    },
    {
        codeAlpha2: 'BB',
        codeAlpha3: 'BRB',
        name: 'Barbados',
    },
    {
        codeAlpha2: 'BY',
        codeAlpha3: 'BLR',
        name: 'Belarus',
    },
    {
        codeAlpha2: 'BZ',
        codeAlpha3: 'BLZ',
        name: 'Belize',
    },
    {
        codeAlpha2: 'BE',
        codeAlpha3: 'BEL',
        name: 'Belgium',
    },
    {
        codeAlpha2: 'BJ',
        codeAlpha3: 'BEN',
        name: 'Benin',
    },
    {
        codeAlpha2: 'BM',
        codeAlpha3: 'BMU',
        name: 'Bermuda',
    },
    {
        codeAlpha2: 'BO',
        codeAlpha3: 'BOL',
        name: 'Bolivia',
    },
    {
        codeAlpha2: 'BQ',
        codeAlpha3: 'BES',
        name: 'Bonaire',
    },
    {
        codeAlpha2: 'BA',
        codeAlpha3: 'BIH',
        name: 'Bosnia-Herzegovina',
    },
    {
        codeAlpha2: 'BW',
        codeAlpha3: 'BWA',
        name: 'Botswana',
    },
    {
        codeAlpha2: 'BR',
        codeAlpha3: 'BRA',
        name: 'Brazil',
    },
    {
        codeAlpha2: 'VG',
        codeAlpha3: 'VGB',
        name: 'British Virgin Islands',
    },
    {
        codeAlpha2: 'BN',
        codeAlpha3: 'BRN',
        name: 'Brunei',
    },
    {
        codeAlpha2: 'BG',
        codeAlpha3: 'BGR',
        name: 'Bulgaria',
    },
    {
        codeAlpha2: 'KH',
        codeAlpha3: 'KHM',
        name: 'Cambodia',
    },
    {
        codeAlpha2: 'CA',
        codeAlpha3: 'CAN',
        name: 'Canada',
    },
    {
        codeAlpha2: 'KY',
        codeAlpha3: 'CYM',
        name: 'Cayman Islands',
    },
    {
        codeAlpha2: 'CL',
        codeAlpha3: 'CHL',
        name: 'Chile',
    },
    {
        codeAlpha2: 'CN',
        codeAlpha3: 'CHN',
        name: 'China',
    },
    {
        codeAlpha2: 'CO',
        codeAlpha3: 'COL',
        name: 'Colombia',
    },
    {
        codeAlpha2: 'CK',
        codeAlpha3: 'COK',
        name: 'Cook Islands',
    },
    {
        codeAlpha2: 'CR',
        codeAlpha3: 'CRI',
        name: 'Costa Rica',
    },
    {
        codeAlpha2: 'HR',
        codeAlpha3: 'HRV',
        name: 'Croatia',
    },
    {
        codeAlpha2: 'CU',
        codeAlpha3: 'CUB',
        name: 'Cuba',
    },
    {
        codeAlpha2: 'CW',
        codeAlpha3: 'CUW',
        name: 'Curacao',
    },
    {
        codeAlpha2: 'CY',
        codeAlpha3: 'CYP',
        name: 'Cyprus',
    },
    {
        codeAlpha2: 'CZ',
        codeAlpha3: 'CZE',
        name: 'Czech-Republic',
    },
    {
        codeAlpha2: 'DK',
        codeAlpha3: 'DNK',
        name: 'Denmark',
    },
    {
        codeAlpha2: 'DO',
        codeAlpha3: 'DOM',
        name: 'Dominican Republic',
    },
    {
        codeAlpha2: 'EC',
        codeAlpha3: 'ECU',
        name: 'Ecuador',
    },
    {
        codeAlpha2: 'EG',
        codeAlpha3: 'EGY',
        name: 'Egypt',
    },
    {
        codeAlpha2: 'SV',
        codeAlpha3: 'SLV',
        name: 'El Salvador',
    },
    {
        codeAlpha2: 'EE',
        codeAlpha3: 'EST',
        name: 'Estonia',
    },
    {
        codeAlpha2: 'ET',
        codeAlpha3: 'ETH',
        name: 'Ethiopia',
    },
    {
        codeAlpha2: 'FO',
        codeAlpha3: 'FRO',
        name: 'Faroe Islands',
    },
    {
        codeAlpha2: 'FJ',
        codeAlpha3: 'FJI',
        name: 'Fiji',
    },
    {
        codeAlpha2: 'FI',
        codeAlpha3: 'FIN',
        name: 'Finland',
    },
    {
        codeAlpha2: 'FR',
        codeAlpha3: 'FRA',
        name: 'France',
    },
    {
        codeAlpha2: 'GF',
        codeAlpha3: 'GUF',
        name: 'French Guiana',
    },
    {
        codeAlpha2: 'PF',
        codeAlpha3: 'PYF',
        name: 'French Polynesia',
    },
    {
        codeAlpha2: 'GA',
        codeAlpha3: 'GAB',
        name: 'Gabon',
    },
    {
        codeAlpha2: 'DE',
        codeAlpha3: 'DEU',
        name: 'Germany',
    },
    {
        codeAlpha2: 'GI',
        codeAlpha3: 'GIB',
        name: 'Gibraltar',
    },
    {
        codeAlpha2: 'GR',
        codeAlpha3: 'GRC',
        name: 'Greece',
    },
    {
        codeAlpha2: 'GL',
        codeAlpha3: 'GRL',
        name: 'Greenland',
    },
    {
        codeAlpha2: 'GP',
        codeAlpha3: 'GLP',
        name: 'Guadeloupe',
    },
    {
        codeAlpha2: 'GU',
        codeAlpha3: 'GUM',
        name: 'Guam',
    },
    {
        codeAlpha2: 'GT',
        codeAlpha3: 'GTM',
        name: 'Guatemala',
    },
    {
        codeAlpha2: 'GY',
        codeAlpha3: 'GUY',
        name: 'Guyana',
    },
    {
        codeAlpha2: 'HN',
        codeAlpha3: 'HND',
        name: 'Honduras',
    },
    {
        codeAlpha2: 'HK',
        codeAlpha3: 'HKG',
        name: 'Hong Kong',
    },
    {
        codeAlpha2: 'HU',
        codeAlpha3: 'HUN',
        name: 'Hungary',
    },
    {
        codeAlpha2: 'IS',
        codeAlpha3: 'ISL',
        name: 'Iceland',
    },
    {
        codeAlpha2: 'IN',
        codeAlpha3: 'IND',
        name: 'India',
    },
    {
        codeAlpha2: 'ID',
        codeAlpha3: 'IDN',
        name: 'Indonesia',
    },
    {
        codeAlpha2: 'IR',
        codeAlpha3: 'IRN',
        name: 'Iran',
    },
    {
        codeAlpha2: 'IE',
        codeAlpha3: 'IRL',
        name: 'Ireland',
    },
    {
        codeAlpha2: 'IL',
        codeAlpha3: 'ISR',
        name: 'Israel',
    },
    {
        codeAlpha2: 'IT',
        codeAlpha3: 'ITA',
        name: 'Italy',
    },
    {
        codeAlpha2: 'CI',
        codeAlpha3: 'CIV',
        name: 'Ivory Coast',
    },
    {
        codeAlpha2: 'JM',
        codeAlpha3: 'JAM',
        name: 'Jamaica',
    },
    {
        codeAlpha2: 'JP',
        codeAlpha3: 'JPN',
        name: 'Japan',
    },
    {
        codeAlpha2: 'JO',
        codeAlpha3: 'JOR',
        name: 'Jordan',
    },
    {
        codeAlpha2: 'KZ',
        codeAlpha3: 'KAZ',
        name: 'Kazakhstan',
    },
    {
        codeAlpha2: 'KE',
        codeAlpha3: 'KEN',
        name: 'Kenya',
    },
    {
        codeAlpha2: 'KR',
        codeAlpha3: 'KOR',
        name: 'Korea',
    },
    {
        codeAlpha2: 'KG',
        codeAlpha3: 'KGZ',
        name: 'Kyrgyzstan',
    },
    {
        codeAlpha2: 'KW',
        codeAlpha3: 'KWT',
        name: 'Kuwait',
    },
    {
        codeAlpha2: 'LV',
        codeAlpha3: 'LVA',
        name: 'Latvia',
    },
    {
        codeAlpha2: 'LB',
        codeAlpha3: 'LBN',
        name: 'Lebanon',
    },
    {
        codeAlpha2: 'LI',
        codeAlpha3: 'LIE',
        name: 'Liechtenstein',
    },
    {
        codeAlpha2: 'LT',
        codeAlpha3: 'LTU',
        name: 'Lithuania',
    },
    {
        codeAlpha2: 'LU',
        codeAlpha3: 'LUX',
        name: 'Luxembourg',
    },
    {
        codeAlpha2: 'MO',
        codeAlpha3: 'MAC',
        name: 'Macau',
    },
    {
        codeAlpha2: 'MW',
        codeAlpha3: 'MWI',
        name: 'Malawi',
    },
    {
        codeAlpha2: 'MY',
        codeAlpha3: 'MYS',
        name: 'Malaysia',
    },
    {
        codeAlpha2: 'MT',
        codeAlpha3: 'MLT',
        name: 'Malta',
    },
    {
        codeAlpha2: 'MQ',
        codeAlpha3: 'MTQ',
        name: 'Martinique',
    },
    {
        codeAlpha2: 'MU',
        codeAlpha3: 'MUS',
        name: 'Mauritius',
    },
    {
        codeAlpha2: 'MX',
        codeAlpha3: 'MEX',
        name: 'Mexico',
    },
    {
        codeAlpha2: 'MC',
        codeAlpha3: 'MCO',
        name: 'Monaco',
    },
    {
        codeAlpha2: 'ME',
        codeAlpha3: 'MNE',
        name: 'Montenegro',
    },
    {
        codeAlpha2: 'MD',
        codeAlpha3: 'MDA',
        name: 'Moldova',
    },
    {
        codeAlpha2: 'MA',
        codeAlpha3: 'MAR',
        name: 'Morocco',
    },
    {
        codeAlpha2: 'MZ',
        codeAlpha3: 'MOZ',
        name: 'Mozambique',
    },
    {
        codeAlpha2: 'MM',
        codeAlpha3: 'MMR',
        name: 'Myanmar',
    },
    {
        codeAlpha2: 'NA',
        codeAlpha3: 'NAM',
        name: 'Namibia',
    },
    {
        codeAlpha2: 'NL',
        codeAlpha3: 'NLD',
        name: 'Netherlands',
    },
    {
        codeAlpha2: 'NZ',
        codeAlpha3: 'NZL',
        name: 'New Zealand',
    },
    {
        codeAlpha2: 'NI',
        codeAlpha3: 'NIC',
        name: 'Nicaragua',
    },
    {
        codeAlpha2: 'NG',
        codeAlpha3: 'NGA',
        name: 'Nigeria',
    },
    {
        codeAlpha2: 'CY',
        codeAlpha3: 'CYP',
        name: 'Northern Cyprus',
    },
    {
        codeAlpha2: 'NO',
        codeAlpha3: 'NOR',
        name: 'Norway',
    },
    {
        codeAlpha2: 'NC',
        codeAlpha3: 'NCL',
        name: 'Nouvelle Caledonie',
    },
    {
        codeAlpha2: 'OM',
        codeAlpha3: 'OMN',
        name: 'Oman',
    },
    {
        codeAlpha2: 'PK',
        codeAlpha3: 'PAK',
        name: 'Pakistan',
    },
    {
        codeAlpha2: 'PW',
        codeAlpha3: 'PLW',
        name: 'Palau',
    },
    {
        codeAlpha2: 'PA',
        codeAlpha3: 'PAN',
        name: 'Panama',
    },
    {
        codeAlpha2: 'PY',
        codeAlpha3: 'PRY',
        name: 'Paraguay',
    },
    {
        codeAlpha2: 'PE',
        codeAlpha3: 'PER',
        name: 'Peru',
    },
    {
        codeAlpha2: 'PH',
        codeAlpha3: 'PHL',
        name: 'Philippines',
    },
    {
        codeAlpha2: 'PL',
        codeAlpha3: 'POL',
        name: 'Poland',
    },
    {
        codeAlpha2: 'PT',
        codeAlpha3: 'PRT',
        name: 'Portugal',
    },
    {
        codeAlpha2: 'PR',
        codeAlpha3: 'PRI',
        name: 'Puerto Rico',
    },
    {
        codeAlpha2: 'QA',
        codeAlpha3: 'QAT',
        name: 'Qatar',
    },
    {
        codeAlpha2: 'MK',
        codeAlpha3: 'MKD',
        name: 'Republic of North Macedonia',
    },
    {
        codeAlpha2: 'RE',
        codeAlpha3: 'REU',
        name: 'Reunion Islands',
    },
    {
        codeAlpha2: 'RO',
        codeAlpha3: 'ROU',
        name: 'Romania',
    },
    {
        codeAlpha2: 'RU',
        codeAlpha3: 'RUS',
        name: 'Russia',
    },
    {
        codeAlpha2: 'WS',
        codeAlpha3: 'WSM',
        name: 'Samoa',
    },
    {
        codeAlpha2: 'SA',
        codeAlpha3: 'SAU',
        name: 'Saudi Arabia',
    },
    {
        codeAlpha2: 'SN',
        codeAlpha3: 'SEN',
        name: 'Senegal',
    },
    {
        codeAlpha2: 'RS',
        codeAlpha3: 'SRB',
        name: 'Serbia',
    },
    {
        codeAlpha2: 'SC',
        codeAlpha3: 'SYC',
        name: 'Seychelles',
    },
    {
        codeAlpha2: 'SG',
        codeAlpha3: 'SGP',
        name: 'Singapore',
    },
    {
        codeAlpha2: 'SK',
        codeAlpha3: 'SVK',
        name: 'Slovakia',
    },
    {
        codeAlpha2: 'SI',
        codeAlpha3: 'SVN',
        name: 'Slovenia',
    },
    {
        codeAlpha2: 'ZA',
        codeAlpha3: 'ZAF',
        name: 'South Africa',
    },
    {
        codeAlpha2: 'ES',
        codeAlpha3: 'ESP',
        name: 'Spain',
    },
    {
        codeAlpha2: 'LK',
        codeAlpha3: 'LKA',
        name: 'Sri Lanka',
    },
    {
        codeAlpha2: 'LC',
        codeAlpha3: 'LCA',
        name: 'St Lucia',
    },
    {
        codeAlpha2: 'PM',
        codeAlpha3: 'SPM',
        name: 'St.Pierre et Miquelon',
    },
    {
        codeAlpha2: 'VC',
        codeAlpha3: 'VCT',
        name: 'St.Vincent, the Grenadines',
    },
    {
        codeAlpha2: 'SR',
        codeAlpha3: 'SUR',
        name: 'Suriname',
    },
    {
        codeAlpha2: 'SE',
        codeAlpha3: 'SWE',
        name: 'Sweden',
    },
    {
        codeAlpha2: 'CH',
        codeAlpha3: 'CHE',
        name: 'Switzerland',
    },
    {
        codeAlpha2: 'SY',
        codeAlpha3: 'SYR',
        name: 'Syria',
    },
    {
        codeAlpha2: 'TW',
        codeAlpha3: 'TWN',
        name: 'Taiwan Formosa',
    },
    {
        codeAlpha2: 'TH',
        codeAlpha3: 'THA',
        name: 'Thailand',
    },
    {
        codeAlpha2: 'TO',
        codeAlpha3: 'TON',
        name: 'Tonga',
    },
    {
        codeAlpha2: 'TT',
        codeAlpha3: 'TTO',
        name: 'Trinidad',
    },
    {
        codeAlpha2: 'TN',
        codeAlpha3: 'TUN',
        name: 'Tunisia',
    },
    {
        codeAlpha2: 'TR',
        codeAlpha3: 'TUR',
        name: 'Turkey',
    },
    {
        codeAlpha2: 'TM',
        codeAlpha3: 'TKM',
        name: 'Turkmenistan',
    },
    {
        codeAlpha2: 'GB',
        codeAlpha3: 'GBR',
        name: 'United Kingdom',
    },
    {
        codeAlpha2: 'US',
        codeAlpha3: 'USA',
        name: 'USA',
    },
    {
        codeAlpha2: 'UA',
        codeAlpha3: 'UKR',
        name: 'Ukraine',
    },
    {
        codeAlpha2: 'AE',
        codeAlpha3: 'ARE',
        name: 'UAE',
    },
    {
        codeAlpha2: 'UY',
        codeAlpha3: 'URY',
        name: 'Uruguay',
    },
    {
        codeAlpha2: 'UZ',
        codeAlpha3: 'UZB',
        name: 'Uzbekistan',
    },
    {
        codeAlpha2: 'VU',
        codeAlpha3: 'VUT',
        name: 'Vanuatu',
    },
    {
        codeAlpha2: 'VE',
        codeAlpha3: 'VEN',
        name: 'Venezuela',
    },
    {
        codeAlpha2: 'VN',
        codeAlpha3: 'VNM',
        name: 'Vietnam',
    },
    {
        codeAlpha2: 'VI',
        codeAlpha3: 'VIR',
        name: 'Virgin Islands',
    },
    {
        codeAlpha2: 'ZM',
        codeAlpha3: 'ZMB',
        name: 'Zambia',
    },
    {
        codeAlpha2: 'ZW',
        codeAlpha3: 'ZWE',
        name: 'Zimbabwe',
    },
];
