import { useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TRANSITION_DELAY_DEFAULT } from './common';
/** identify mobile device */
export const useMobileDeviceCheck = () => {
    const theme = useTheme();
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileDevice);
    }, [isMobileDevice]);
    return {
        isMobile,
    };
};
/** handling session storage */
export const useSessionStorage = () => {
    /** save data into session storage */
    const saveToSessionStorage = (key, data) => {
        const storedValue = typeof data === 'object' ? JSON.stringify(data) : data;
        sessionStorage.setItem(key, storedValue);
    };
    /** get data from session storage */
    const getFromSessionStorage = (key) => {
        if (typeof window === 'undefined')
            return null;
        const storedValue = sessionStorage.getItem(key);
        if (!storedValue)
            return null;
        try {
            // handling parse issue of non object type data
            const parsedValue = JSON.parse(storedValue);
            return typeof parsedValue === 'object' ? parsedValue : {};
        }
        catch (error) {
            return storedValue;
        }
    };
    /** remove data from sessionStorage */
    const removeFromSessionStorage = (key) => {
        if (Array.isArray(key)) {
            for (const element of key) {
                sessionStorage.removeItem(element);
            }
        }
        else
            sessionStorage.removeItem(key);
    };
    return {
        saveToSessionStorage,
        getFromSessionStorage,
        removeFromSessionStorage,
    };
};
// handling transition delays in HomeHeroBannerGallery, FFHomeHeroBannerGallery and SiteUsp Gallery
export const useCalculateTransitionTime = (transitionDelay) => {
    // Converting the provided transition delay to milliseconds
    const delayInSeconds = transitionDelay * 1000 || TRANSITION_DELAY_DEFAULT;
    // Returning the calculated transition time in milliseconds
    return delayInSeconds;
};
export const usePaymentIconsCallback = () => {
    const imageDiementiosnCallback = useCallback((payementType) => {
        let width = 72;
        let height = 32;
        if (payementType === 'payPal') {
            width = 51;
            height = 21;
        }
        else if (payementType === 'card') {
            width = 51;
            height = 27;
        }
        return {
            width,
            height,
        };
    }, []);
    return { imageDiementiosnCallback };
};
/** handling local storage */
export const useLocalStorage = () => {
    /** save data into session storage */
    const saveToLocalStorage = (key, data) => {
        const storedValue = typeof data === 'object' ? JSON.stringify(data) : data;
        localStorage.setItem(key, storedValue);
    };
    /** get data from session storage */
    const getFromLocalStorage = (key) => {
        if (typeof window === 'undefined')
            return null;
        const storedValue = localStorage.getItem(key);
        if (!storedValue)
            return null;
        try {
            // handling parse issue of non object type data
            const parsedValue = JSON.parse(storedValue);
            return typeof parsedValue === 'object' ? parsedValue : {};
        }
        catch (error) {
            return storedValue;
        }
    };
    /** remove data from localStorage */
    const removeFromLocalStorage = (key) => {
        if (Array.isArray(key)) {
            for (const element of key) {
                localStorage.removeItem(element);
            }
        }
        else
            localStorage.removeItem(key);
    };
    return {
        saveToLocalStorage,
        getFromLocalStorage,
        removeFromLocalStorage,
    };
};
