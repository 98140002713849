import { makeStyles } from 'tss-react/mui';
import { link, MAX_CARD_WIDTH } from '../../styles/jss/utils';
import { spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    relatedContentCard: {
        width: '100%',
        maxWidth: `${MAX_CARD_WIDTH}px`,
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        borderRadius: theme.isFlyingFlowers ? '0px' : undefined,
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    textContent: {
        padding: `${spacing.small} ${spacing.medium} 0 ${spacing.medium}`,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    message: Object.assign(Object.assign(Object.assign({ display: 'block !important' }, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { textAlign: theme.isFlyingFlowers ? 'center' : 'left', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
    messageContainer: {
        paddingBottom: spacing.xs,
    },
    link: Object.assign(Object.assign({}, link()), { textDecoration: 'none' }),
    tag: Object.assign(Object.assign({}, theme.typography.xxs_regular), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, border: theme.isFlyingFlowers ? `1px solid ${colors.grey.grey3}` : 'none', backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1, borderRadius: theme.isFlyingFlowers ? spacing.xs : '0', textDecoration: theme.isFlyingFlowers ? 'underline' : 'none', padding: `0px ${spacing.xs}` }),
    adjustTagMargin: {
        marginBottom: spacing.xs,
    },
}));
export default useStyles;
