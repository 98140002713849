import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {},
    // Interflora
    image: Object.assign({}, (!theme.isFlyingFlowers && {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        minHeight: 'unset',
    })),
    content: Object.assign({}, (!theme.isFlyingFlowers && {
        display: 'flex',
        alignSelf: 'start !important',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            position: 'absolute !important',
        },
    })),
    contentInner: {
        alignSelf: 'start !important',
        margin: `${spacing.medium} !important`,
        [theme.breakpoints.up('md')]: {
            margin: '32px !important',
        },
        [theme.breakpoints.up('lg')]: {
            alignSelf: 'start !important',
        },
    },
    heading: {
        'div&,h1&,h2&,h3&,h4&': Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column' }, theme.typography.xl_regular), theme.typographySpacing.none), { color: colors.supplementary.white, [theme.breakpoints.up('sm')]: {
                marginBottom: spacing.small,
            }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl), { marginBottom: spacing.small }), [theme.breakpoints.up('lg')]: {
                marginBottom: spacing.medium,
            }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), { marginBottom: spacing.large }) }),
        'div&:first-child': {
            color: theme.isFlyingFlowers ? undefined : colors.supplementary.white,
        },
    },
    blogHeading: {
        'h1&': Object.assign(Object.assign(Object.assign({ display: theme.isFlyingFlowers ? 'flex' : undefined, flexDirection: theme.isFlyingFlowers ? 'column' : undefined }, theme.typography.xl_regular), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xl_regular_sm)), { color: colors.supplementary.white, [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers && theme.typography.xxl)), { marginBottom: theme.isFlyingFlowers ? spacing.small : undefined }), [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers && theme.typography.xxl)), { marginBottom: theme.isFlyingFlowers ? spacing.small : undefined }), [theme.breakpoints.up('lg')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxxl), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xxxl_sm)), { marginBottom: theme.isFlyingFlowers ? spacing.large : undefined }), [theme.breakpoints.up('xl')]: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxxxl : undefined)) }),
    },
    headingLine: {
        whiteSpace: 'nowrap',
        alignSelf: 'flex-start',
        padding: spacing.xs,
        '&:first-child': {
            transform: 'rotate(358deg) translate(5px,5px)',
            transformOrigin: 'bottom left',
        },
    },
    newHeading: {
        alignSelf: 'flex-start',
        display: 'block',
    },
    COBALT: {
        backgroundColor: colors.cobalt[500],
    },
    JADE: {
        backgroundColor: colors.jade[500],
    },
    WILD_EXOTIC: {
        backgroundColor: colors.wildExotic[500],
    },
    BLACK: {
        color: 'BLACK',
    },
    message: Object.assign(Object.assign({ display: theme.isFlyingFlowers ? 'none' : undefined, color: theme.isFlyingFlowers ? undefined : colors.supplementary.white }, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.xxs_bold)), { margin: '0 !important', [theme.breakpoints.up('sm')]: Object.assign({ display: theme.isFlyingFlowers ? 'block' : undefined }, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.small_bold)), [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? undefined : theme.typography.medium_bold)), [theme.breakpoints.up('lg')]: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.large_bold : undefined)) }),
    outerMessage: {
        display: 'block',
        marginTop: spacing.medium,
        marginLeft: '20px',
        marginRight: '20px',
        [theme.breakpoints.up('sm')]: { display: 'none !important' },
    },
    centerHeading: {
        alignSelf: 'center',
    },
    ffCenterContentInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ffContent: {
        [theme.breakpoints.up('sm')]: {
            width: '50% !important',
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    fontSohne: {
        [`& .${classes.heading}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_bold : theme.typography.xxl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_bold_sm : theme.typographySpacing.xxl_bold_sm)), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_bold : theme.typography.xxxxl)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_bold_sm : theme.typographySpacing.xxxxl_sm)) }),
        [`& .${classes.message}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('sm')]: Object.assign({}, theme.typographySpacing.none), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none) }),
    },
    blogContainer: {
        placeContent: theme.isFlyingFlowers ? 'center' : 'start !important',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '376px',
        },
    },
}));
export default useStyles;
