import { makeStyles } from 'tss-react/mui';
import { link, maxHeightBelowHeader } from '../../styles/jss/utils';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: maxHeightBelowHeader(theme, Object.assign(Object.assign({ position: 'relative', borderRight: `${spacing.medium} solid transparent`, overflowX: 'hidden', overflowY: 'scroll', width: '100%' }, hideScrollbars), { [theme.breakpoints.up('sm')]: {}, [theme.breakpoints.up('md')]: {
            borderRight: 'none',
            borderTop: theme.isFlyingFlowers ? `4px solid ${colors.wildExotic[500]}` : undefined,
            marginTop: theme.isFlyingFlowers ? '-4px' : undefined,
            boxShadow: theme.isFlyingFlowers
                ? `0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)`
                : `0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)`,
            background: colors.supplementary.white,
            // Full Height - USP Bar
            maxHeight: 'calc(100vh - 40px)',
            marginBottom: spacing.small,
            width: '324px',
        }, [theme.breakpoints.up('lg')]: {
            // Full Height - USP Bar
            maxHeight: 'calc(100vh - 56px)',
            borderTop: theme.isFlyingFlowers ? `8px solid ${colors.wildExotic[500]}` : undefined,
            marginTop: theme.isFlyingFlowers ? '-8px' : undefined,
        } })),
    menuNavigationLinks: {
        background: colors.supplementary.white,
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.isFlyingFlowers ? '12px' : spacing.small,
            paddingBottom: theme.isFlyingFlowers ? '12px' : spacing.small,
        },
        '& li': {
            '&:not(:last-child)': {
                borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey3}`,
                [theme.breakpoints.up('md')]: {
                    borderBottom: 'none',
                },
            },
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.isFlyingFlowers ? '12px 20px' : '15px 20px',
        '&:hover': {
            backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey2,
            [`& .${classes.forwardIcon}`]: {
                color: 'inherit',
            },
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers ? '12px 24px' : '15px 24px',
            '&:hover': {
                backgroundColor: colors.supplementary.white,
                color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4,
            },
        },
    },
    signOutLink: {
        [theme.breakpoints.down('md')]: {
            background: colors.grey.grey2,
            paddingTop: '32px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '20px',
            '& button': Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }),
        },
        [theme.breakpoints.up('md')]: {
            '& button': {
                display: 'flex',
                alignItems: 'flex-end',
                color: colors.grey.grey7,
                padding: '15px 24px',
                width: '100%',
            },
            '& button:hover': {
                backgroundColor: colors.supplementary.white,
                color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4,
            },
        },
    },
    icon: {
        // Pick up body or hover color from parent
        color: 'inherit',
        paddingRight: theme.isFlyingFlowers ? '4px' : '20px',
        display: theme.isFlyingFlowers ? undefined : 'none !important',
        fontSize: '24px',
        [theme.breakpoints.up('md')]: {
            display: 'inherit !important',
            fontSize: '32px',
        },
    },
    label: Object.assign(Object.assign({}, theme.typography.small_bold), { paddingRight: theme.isFlyingFlowers ? spacing.medium : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), { paddingRight: '0px' }) }),
    forwardIcon: {
        color: `${theme.isFlyingFlowers ? colors.black[500] : colors.grey.grey5} !important`,
        position: 'absolute',
        right: spacing.small,
        fontSize: '24px',
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
        },
    },
}));
export default useStyles;
