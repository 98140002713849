import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    floristSpecialismContainer: {
        width: '100%',
    },
    floristSpecialismContentContainer: {
        height: '240px',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    floristImageOverlay: {
        position: 'absolute',
        padding: `18px ${spacing.medium} 18px 18px`,
        background: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        alignItems: 'center',
        bottom: 0,
        right: 0,
        left: 0,
    },
    floristIcon: {
        marginRight: '26px',
        [theme.breakpoints.up('md')]: {
            marginRight: '18px',
        },
    },
    floristSpecialismTitle: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: 0, color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }),
    floristSpecialsmTextContainer: {
        backgroundColor: colors.grey.grey1,
        display: 'flex',
        alignItems: 'center',
        padding: spacing.small,
    },
}));
export default useStyles;
