import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    FilterChip: {
        backgroundColor: colors.grey.grey1,
        padding: `6px 0`,
        height: 'auto',
        borderRadius: '20px',
        '& span': Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6 }),
        '& .MuiChip-deletable:focus': {
            backgroundColor: colors.grey.grey2,
        },
        '& .MuiChip-deleteIcon': {
            color: colors.grey.grey6,
            marginTop: '1px',
            marginRight: spacing.xs,
        },
        '&:hover, &:focus': {
            '& span, & .MuiChip-deleteIcon': {
                color: colors.supplementary.black,
            },
        },
        '&:focus': {
            backgroundColor: colors.grey.grey1,
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: colors.grey.grey2,
        },
    },
}));
export default useStyles;
