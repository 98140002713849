import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import SiteUsp from '../SiteUsp/SiteUsp';
import Carousel from '../Carousel/Carousel';
import useStyles from '../SiteUspGallery/SiteUspGallery.styles';
import { useTheme } from '@mui/material';
import clsx from 'clsx';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import CustomButtonGroup from './CustomButtonGroup';
import { siteUspsCarouselResponsive } from '../../styles/jss/utils';
import { useCalculateTransitionTime } from '../../utils/utilityCustomHooks';
import { useLoaded } from '../MainContent/useComponentLoading';
const TruspilotWidgetComponent = (props) => {
    const { carouselIndex } = props;
    const { classes } = useStyles();
    return (_jsx("div", { className: classes.trustpilotContainer, "data-index": carouselIndex, "data-testid": "Trustpilot", id: "siteUSPTrustpilot", children: _jsx(TrustpilotWidget, { template: "MICRO_STAR" }) }));
};
const SiteUspGallery = (props) => {
    const { siteUspList, showTrustpilotWidget, trustpilotWidgetPosition, transitionDelay } = props;
    const { classes } = useStyles();
    const carouselRef = React.createRef();
    const [uspsList, setUspsList] = useState(siteUspList);
    const theme = useTheme();
    const moveToNextSlide = () => { var _a; return (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1); };
    const moveToPreviouSlide = () => { var _a; return (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1); };
    useEffect(() => {
        if (showTrustpilotWidget && trustpilotWidgetPosition && !!(siteUspList === null || siteUspList === void 0 ? void 0 : siteUspList.length)) {
            if (siteUspList.length >= trustpilotWidgetPosition) {
                const siteUspsLeftList = siteUspList
                    .map((usp, index) => {
                    if (index < trustpilotWidgetPosition - 1)
                        return usp;
                })
                    .filter(Boolean);
                const siteUspRightList = siteUspList
                    .map((usp, index) => {
                    if (index >= trustpilotWidgetPosition - 1)
                        return usp;
                })
                    .filter(Boolean);
                const mergedSiteUspsList = [...siteUspsLeftList, {}, ...siteUspRightList];
                setUspsList(mergedSiteUspsList);
            }
            else {
                setUspsList([...siteUspList]);
            }
        }
        else {
            setUspsList(siteUspList);
        }
    }, [showTrustpilotWidget, siteUspList, trustpilotWidgetPosition]);
    const delayInSeconds = useCalculateTransitionTime(transitionDelay);
    useLoaded(props);
    return (_jsx("div", { className: classes.carouselGallery, children: (uspsList === null || uspsList === void 0 ? void 0 : uspsList.length) === 1 && !showTrustpilotWidget ? (_createElement(SiteUsp, Object.assign({}, uspsList[0], { key: `usp_${uspsList[0].title}`, carouselIndex: uspsList === null || uspsList === void 0 ? void 0 : uspsList.length }))) : !(uspsList === null || uspsList === void 0 ? void 0 : uspsList.length) && showTrustpilotWidget ? (_jsx(TruspilotWidgetComponent, { carouselIndex: 1 })) : (_jsxs(Carousel, { arrows: false, autoPlay: true, autoPlaySpeed: delayInSeconds, carouselContainerClass: clsx(classes.carousel), customButtonGroup: _jsx(CustomButtonGroup, { next: moveToNextSlide, previous: moveToPreviouSlide }), infinite: true, itemClass: classes.carouselItem, partialVisbile: false, ref: carouselRef, renderDotsOutside: true, responsive: siteUspsCarouselResponsive(theme), showDots: false, sliderClass: classes.slider, slidesToSlide: 1, ssr: false, swipeable: true, children: [uspsList === null || uspsList === void 0 ? void 0 : uspsList.map((siteUsp, index) => (_jsx(React.Fragment, { children: showTrustpilotWidget && trustpilotWidgetPosition && index === trustpilotWidgetPosition - 1 ? (_jsx(TruspilotWidgetComponent, { carouselIndex: index + 1 })) : (_jsx(SiteUsp, Object.assign({}, siteUsp, { carouselIndex: index + 1 }))) }, `site_usp_${siteUsp.title}`))), showTrustpilotWidget &&
                    (!trustpilotWidgetPosition || trustpilotWidgetPosition > ((siteUspList === null || siteUspList === void 0 ? void 0 : siteUspList.length) || 0)) ? (_jsx(TruspilotWidgetComponent, { carouselIndex: !(siteUspList === null || siteUspList === void 0 ? void 0 : siteUspList.length) ? 1 : (siteUspList === null || siteUspList === void 0 ? void 0 : siteUspList.length) + 1 })) : null, showTrustpilotWidget && !(siteUspList === null || siteUspList === void 0 ? void 0 : siteUspList.length) ? _jsx(TruspilotWidgetComponent, { carouselIndex: 1 }) : null] })) }));
};
export default SiteUspGallery;
