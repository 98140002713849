import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import Link from '../Link/Link';
import { useLoaded } from '../MainContent/useComponentLoading';
import useStyles from './RelatedLinksGallery.styles';
const RelatedLinksGalleryComponent = (props) => {
    const { linkTitle, links, classNames } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsx(_Fragment, { children: !!(links === null || links === void 0 ? void 0 : links.length) && (_jsxs("div", { children: [linkTitle && (_jsx("h2", { className: (classNames === null || classNames === void 0 ? void 0 : classNames.linksTitle) ? classNames === null || classNames === void 0 ? void 0 : classNames.linksTitle : classes.relatedTitle, children: linkTitle })), _jsx("ul", { className: classes.linkContainer, children: links === null || links === void 0 ? void 0 : links.map((link, index) => (_jsx("li", { className: clsx(classes.linkContent, (classNames === null || classNames === void 0 ? void 0 : classNames.link) ? classNames === null || classNames === void 0 ? void 0 : classNames.link : classes.relatedLinks), children: _jsx(Link, Object.assign({}, link)) }, index))) })] })) }));
};
export default RelatedLinksGalleryComponent;
