import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    headings: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: '10px' }),
    '& label': Object.assign({}, theme.typography.small_bold),
    '& input': Object.assign({}, theme.typography.small_regular),
    input: {
        marginBottom: spacing.small,
    },
    inputMedium: {
        marginBottom: spacing.medium,
    },
    error: { marginBottom: spacing.small },
    buttons: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& button': {
            [theme.breakpoints.up('md')]: {
                width: theme.isFlyingFlowers ? '100%' : '247px',
            },
        },
    },
    saveButton: {
        marginBottom: spacing.small,
    },
    dateFields: {
        display: 'flex',
        marginBottom: spacing.large,
    },
    dateField: {
        width: 'calc(50% - 12px)',
        '&:first-child': {
            marginRight: '24px',
        },
    },
    reminderMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xxs_regular_lg)), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xxs_regular_sm)), color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, marginBottom: !theme.isFlyingFlowers ? spacing.small : '', [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        } }),
}));
export default useStyles;
