import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
import FaqItem from '../FaqItem/FaqItem';
import useStyles from './FaqGallery.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
/**
 *
 * <h2>Designs</h2>
 * <p>Interflora: https://www.figma.com/file/uriqJDufclCYzXT4yjsFum/Design-Sprint-4?node-id=712%3A39679</p>
 * <p>Flying Flowers: https://www.figma.com/file/sStbIo2VTXH7Tbs27KZU21/FF-Weekly-Outputs?node-id=716%3A60885</p>
 */
const FaqGallery = (props) => {
    const { title, faqItems, onlyLinks } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs("div", { children: [title && _jsx("h2", { className: classes.title, children: title }), !!(faqItems === null || faqItems === void 0 ? void 0 : faqItems.length) &&
                faqItems.map((faqItem, index) => (_jsx(FaqItem, { id: faqItem.id, question: faqItem.question, answer: (faqItem === null || faqItem === void 0 ? void 0 : faqItem.answer) && faqItem.answer, onlyLinks: !!onlyLinks, anchorKey: faqItem.anchorKey }, index))), !!(faqItems === null || faqItems === void 0 ? void 0 : faqItems.length) && (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps({
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: faqItems === null || faqItems === void 0 ? void 0 : faqItems.map((faqItem) => ({
                        '@type': 'Question',
                        name: faqItem.question,
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: faqItem.answer,
                        },
                    })),
                }))) }))] }));
};
export default FaqGallery;
