import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import BrandUsp from '../BrandUsp/BrandUsp';
import { useLoaded } from '../MainContent/useComponentLoading';
import useStyles from './BrandUspGallery.styles';
const BrandUspGallery = (props) => {
    const { title, usps } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("div", { className: classes.heading, children: title }), !!(usps === null || usps === void 0 ? void 0 : usps.length) && (_jsx("div", { className: classes.container, children: usps.map((usp, index) => (_jsx(BrandUsp, Object.assign({}, usp), index))) }))] }));
};
export default BrandUspGallery;
