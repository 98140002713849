import { makeStyles } from 'tss-react/mui';
import omit from 'lodash/omit';
import { formStyles } from '../../../styles/jss/form';
import { link } from '../../../styles/jss/utils';
import { colors, spacing, typographyMontserrat } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign({}, omit(formStyles(theme), ['container', 'box'])), { input: {
        marginBottom: spacing.small,
    }, addressContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    }, addressLabel: Object.assign({}, theme.typography.small_bold), addressValue: Object.assign(Object.assign({}, typographyMontserrat.small_regular), { paddingTop: '0px', paddingBottom: '0px', lineHeight: '1.43rem' }), searchAddressContiner: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
    }, searchAddressLink: Object.assign(Object.assign(Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }) })));
export default useStyles;
