var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FeatureBanner from '../FeatureBanner/FeatureBanner';
import Alert from '../Alert/Alert';
import useStyles from './RenewalDeliveryPass.styles';
import format from 'date-fns/format';
export const AMPLIENCE_KEY = 'component/existing-delivery-pass';
const RenewalDeliveryPass = (props) => {
    var _a, _b;
    const [errorMessage, setErrorMessage] = useState('');
    const { classes } = useStyles();
    const heading = (_jsxs(_Fragment, { children: ["Your ", (_a = props.product) === null || _a === void 0 ? void 0 : _a.name, " Delivery pass expires on", ' ', _jsx("span", { children: format(new Date(props.expiryDate), 'EEEE do MMMM') })] })); // Inorder to add white color toexpiryDate as per figma added span here
    const renewalDeliveryPass = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (props.addToBasket && ((_c = props.product) === null || _c === void 0 ? void 0 : _c.sku)) {
            try {
                yield props.addToBasket(props.product.sku);
            }
            catch (error) {
                setErrorMessage('There was a problem, please try again later');
            }
        }
    });
    const args = Object.assign({ heading, isFullButtonWidth: true, buttonVariant: 'tertiary', links: [{ onClick: renewalDeliveryPass, label: `Renew ${(_b = props.product) === null || _b === void 0 ? void 0 : _b.name}` }], linkStyle: 'BUTTON' }, props);
    return (_jsxs(_Fragment, { children: [errorMessage && _jsx(Alert, { type: "alertError", message: errorMessage, className: classes.errorMessage }), _jsx(FeatureBanner, Object.assign({}, args))] }));
};
export default RenewalDeliveryPass;
