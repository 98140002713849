import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0 20px',
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: `0 ${spacing.medium} 0 40px`,
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            padding: 0,
            overflow: 'initial',
        },
    },
    chooseDate: {
        border: '0 !important',
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            margin: `${spacing.medium} auto 0  auto`,
            maxWidth: '100%',
            justifyContent: 'center',
            borderTop: 'none',
        },
    },
    divider: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            borderBottom: `1px solid ${colors.grey.grey4}`,
            marginBottom: '40px',
        },
    },
    stepContainer: {
        minWidth: '335px',
        padding: '0 20px',
        [theme.breakpoints.up('sm')]: {
            margin: '0 138px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '600px',
            width: '44%',
            margin: '0 auto',
            padding: 0,
        },
    },
    hideSteps: {
        display: 'none',
    },
    removePadding: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    addPadding: {
        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        },
    },
    manualAddressModalContainer: {
        [theme.breakpoints.down('xs')]: {
            overflowY: 'auto',
        },
    },
    stepSelect: {},
}));
export default useStyles;
