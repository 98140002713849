import { createTheme } from '@mui/material';
import { link } from './utils';
import { colors, fontWeights, statusColorsFlyingFlowers, statusColorsInterflora, typographyMontserrat, typographyMontserratBase, 
// new fonts
typographySohne, typographySohneBase, typographySpacing, } from './variables';
const noSpacing = {
    margin: 0,
    padding: 0,
};
const breakpoints = {
    values: {
        zero: 0,
        xs: 480,
        sm: 720,
        md: 1024,
        lg: 1280,
        xl: 1672, // 1608px content with 32px margins
    },
};
const overrides = (theme) => ({
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                height: '100%',
            },
            body: {
                height: '100%',
                color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey7,
            },
            a: Object.assign({ textDecoration: 'none', color: 'inherit' }, noSpacing),
            ul: Object.assign({ listStyle: 'none' }, noSpacing),
            ol: Object.assign({ listStyle: 'none' }, noSpacing),
            li: Object.assign({ display: 'block' }, noSpacing),
            dl: Object.assign({}, noSpacing),
            dd: Object.assign({}, noSpacing),
            button: {
                background: 'none',
                border: 'none',
                padding: '0',
                font: 'inherit',
            },
            h1: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { [`@media (min-width:${breakpoints.values.md}px)`]: Object.assign(Object.assign({}, theme.typography.xxl), theme.typographySpacing.xxl_sm) }),
            h2: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typography.large_bold_sm), { fontWeight: fontWeights.medium, [`@media (min-width:${breakpoints.values.md}px)`]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm) }),
            h3: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { fontWeight: fontWeights.medium, [`@media (min-width:${breakpoints.values.md}px)`]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
            p: Object.assign(Object.assign({ 
                // removing browser default margins and adding defaults for content
                padding: 0 }, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm),
            mark: {
                color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold5,
                backgroundColor: 'inherit',
            },
            select: {
                '-webkit-appearance': 'none',
                cursor: 'pointer',
            },
            textarea: {
                fontSize: 16,
                border: 'none',
                overflow: 'auto',
                outline: 'none',
                '-webkit-box-shadow': 'none',
                '-moz-box-shadow': 'none',
                boxShadow: 'none',
                resize: 'none',
                backgroundColor: 'inherit',
                padding: 0,
            },
            main: { display: 'flex', flexDirection: 'column', minHeight: '100vh' },
            '.content': {
                '& > :last-child': {
                    marginBottom: '0px',
                },
                '& ul': {
                    listStyleType: 'disc',
                },
                '& ol': {
                    listStyleType: 'decimal',
                    '& > li::marker': {
                        content: 'counter(list-item)"  "',
                    },
                },
                '& ul, & ol': Object.assign(Object.assign({}, theme.typographySpacing.medium_regular_sm), { margin: 'initial', paddingInlineStart: '2em' }),
                '& ul > li, & ol > li': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.none), { display: 'list-item', listStyle: 'outside' }),
                '& a': Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : undefined)),
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                margin: 0,
                width: '100%',
            },
        },
    },
    MuiInput: {
        styleOverrides: {
            root: {
                padding: '0',
                margin: '0!important',
                position: 'initial',
            },
            input: {
                padding: '0',
                '&::placeholder': {
                    color: colors.grey.grey6,
                    opacity: 1,
                },
            },
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                margin: '0!important',
            },
            label: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '2px', 
                // Mui 5 upgrade changes
                '&$disabled': {
                    color: colors.grey.grey6,
                } }),
        },
    },
    // Mui 5 upgrade changes
    MuiRadio: { styleOverrides: { colorSecondary: { '&$checked': { '&:hover': { backgroundColor: 'inherit' } } } } },
    MuiCheckbox: { styleOverrides: { colorSecondary: { '&$checked': { '&:hover': { backgroundColor: 'inherit' } } } } },
    MuiFab: {
        styleOverrides: {
            root: {
                backgroundColor: colors.supplementary.white,
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            // Disable ripple for jest tests
            disableRipple: !!process.env.JEST_WORKER_ID,
        },
    },
    // PrivateSwitchBase: { styleOverrides: { root: { padding: spacing.xs } } },
});
const createSiteTheme = (isFlyingFlowers) => {
    const typographyBase = isFlyingFlowers ? typographyMontserratBase : typographySohneBase;
    const typography = isFlyingFlowers ? typographyMontserrat : typographySohne;
    return createTheme({
        breakpoints,
        typography: Object.assign(Object.assign({}, typographyBase), typography),
        components: overrides({ typography, typographyBase, typographySpacing, isFlyingFlowers }),
        typographySpacing,
        statusColors: isFlyingFlowers ? statusColorsFlyingFlowers : statusColorsInterflora,
        isFlyingFlowers: !!isFlyingFlowers,
    });
};
export default createSiteTheme;
