import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UspImage from '../UspImage/UspImage';
import UspBanner from '../UspBanner/UspBanner';
import useStyles from './BrandUsp.styles';
const BrandUsp = (props) => {
    const { description, title, image } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.uspContainer, children: [_jsx("div", { children: !!image && _jsx(UspImage, { image: image }) }), _jsx("div", { children: title && _jsx(UspBanner, { title: title }) }), _jsx("div", { children: description && _jsx("div", { className: classes.uspDescription, children: description }) })] }));
};
export default BrandUsp;
