import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    reminderTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, textAlign: theme.isFlyingFlowers ? 'center' : 'left', [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        } }),
    noReminderTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typographySpacing.none), { marginBottom: '12px' }) }),
    noReminderDescription: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.medium_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    noReminderCard: {
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        padding: spacing.medium,
        background: colors.background.LIGHT_GREY,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },
    reminderCardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridColumnGap: '0',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: spacing.medium,
        },
    },
    viewEditButton: {
        marginRight: spacing.medium,
        marginLeft: spacing.medium,
        [theme.breakpoints.up('sm')]: {
            margin: 0,
        },
        [theme.breakpoints.up('md')]: {
            width: '287px',
        },
    },
    addOccasionButton: {
        '& button': {
            '&.MuiFab-root': {
                '&:focus': {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
                },
            },
        },
    },
}));
export default useStyles;
