import { makeStyles } from 'tss-react/mui';
import { colors, fontWeights, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), { color: colors.supplementary.black }), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    monthsList: Object.assign(Object.assign({ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }, hideScrollbars), { marginBottom: spacing.medium, [theme.breakpoints.up('lg')]: {
            flexFlow: 'row wrap',
            marginBottom: spacing.large,
        }, '& li': Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ border: `1px solid ${theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey4}`, marginRight: '12px', marginBottom: '12px' }, theme.typography.medium_bold), { backgroundColor: colors.supplementary.white, borderRadius: theme.isFlyingFlowers ? spacing.xs : undefined }), theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, fontWeight: theme.isFlyingFlowers ? fontWeights.bold : 350, padding: '0px !important' }) }),
    activeMonth: Object.assign(Object.assign({ backgroundColor: theme.isFlyingFlowers
            ? `${colors.lazyLime[300]} !important`
            : `${colors.primary.gold1} !important` }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg),
    disableMonth: Object.assign(Object.assign(Object.assign({ pointerEvents: 'none', border: `1px solid ${colors.grey.grey2} !important`, color: theme.isFlyingFlowers ? `${colors.black[600]} !important` : `${colors.grey.grey6} !important` }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { backgroundColor: `${colors.grey.grey2} !important` }),
    actionButtons: {
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        '& button': {
            width: '248px',
            height: '44px',
            [theme.breakpoints.up('md')]: {
                width: '300px',
            },
        },
        '& button:first-child': {
            marginBottom: spacing.medium,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
        },
        '& button:last-child': {
            marginLeft: 0,
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.isFlyingFlowers ? spacing.large : spacing.medium,
            },
        },
    },
    resultsViewed: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.large }) }),
    monthCursor: {
        padding: '10px 18px !important',
        cursor: 'pointer',
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
    },
    additional: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            width: 'fit-content',
            display: 'block',
        },
    },
}));
export default useStyles;
