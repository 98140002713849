import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        display: 'flex',
        border: `1px solid ${colors.supplementary.black}`,
        paddingLeft: spacing.xs,
        paddingRight: spacing.xs,
        paddingTop: '10px',
        paddingBottom: '8px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '13px',
            paddingBottom: '13px',
        },
    },
    icon: {
        fontSize: `24px`,
        marginRight: spacing.xs,
    },
    message: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.small_regular), { paddingTop: 0, paddingBottom: 0, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { paddingTop: 0, paddingBottom: 0 }), '& a': Object.assign(Object.assign({}, link()), { display: 'inline' }), '& p': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }, '& > :last-child': {
            marginBottom: '0px',
        } }),
    alertInfo: {
        borderColor: theme.statusColors.warning.dark,
        [`& .${classes.icon}`]: { color: theme.statusColors.warning.dark },
    },
    alertError: {
        borderColor: theme.statusColors.error.dark,
        backgroundColor: theme.statusColors.error.light,
        [`& .${classes.icon}`]: { color: theme.statusColors.error.dark },
    },
    alertSuccess: {
        borderColor: theme.statusColors.success.base,
        [`& .${classes.icon}`]: { color: theme.statusColors.success.base },
    },
    alertTimer: {
        borderColor: theme.statusColors.warning.dark,
        [`& .${classes.icon}`]: { color: theme.statusColors.warning.dark },
    },
    errorMessage: {
        '& span': {
            '& p': Object.assign(Object.assign({}, theme.typography.medium_regular), { paddingTop: 0, paddingBottom: 0 }),
        },
    },
}));
export default useStyles;
