import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    errorMessage: {
        color: theme.statusColors.error.base,
    },
    container: {
        '& h4': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: colors.supplementary.black, fontWeight: 500 }),
        '& h5': Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none),
        '& p': Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none),
        '& .MuiFormControlLabel-label': {
            marginLeft: spacing.small,
        },
    },
    card: {
        borderRadius: 0,
        padding: spacing.small,
        background: colors.grey.grey1,
        '& h5': {
            color: colors.grey.grey6,
        },
        '& .MuiRadio-root': {
            paddingTop: 0,
        },
        '& .MuiFormControlLabel-label': {
            paddingTop: 0,
        },
    },
    deliveryPassCards: {
        borderTop: `1px solid ${colors.supplementary.black}`,
        marginBottom: spacing.large,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        '& > :first-child': {
            paddingBottom: spacing.xs,
        },
        '& > :last-child': {
            paddingTop: spacing.xs,
        },
        '& span': {
            marginTop: `0px !important`,
            paddingTop: `0px !important`,
        },
    },
    selectDeliveryPassContainer: {
        width: '100%',
        padding: spacing.small,
        alignItems: 'start',
        display: 'grid',
        gridTemplateColumns: `${spacing.xxl} auto ${spacing.medium}`,
        gridColumnGap: spacing.small,
        backgroundColor: colors.supplementary.white,
        cursor: 'pointer',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
        },
        '&:hover': {
            backgroundColor: colors.grey.grey2,
        },
        '&:focus': {
            boxShadow: '0px 0px 8px #008BE9, 0px 4px 4px rgba(0, 0, 0, 0.16), 0px 2px 12px rgba(0, 0, 0, 0.08)',
        },
    },
    imgContainer: {
        height: spacing.xxl,
        width: spacing.xxl,
    },
    imageContainer: {
        position: 'relative',
    },
    image: {
        position: 'absolute',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    deliveryPassDescription: {
        display: 'flex',
        justifyContent: 'left',
    },
    contentImage: {
        width: '100%',
        height: '100%',
    },
    arrowIcon: {},
    deliveryPassOption: Object.assign({}, theme.typographySpacing.xxs_regular_sm),
    selectedOption: Object.assign(Object.assign({ color: theme.statusColors.success.base }, theme.typography.xxs_bold), theme.typographySpacing.none),
    closeDeliveryPassContainer: {
        marginBottom: spacing.large,
    },
    deliveryPassDescriptionText: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { color: colors.grey.grey6, '& p': {
            '& span': {
                backgroundColor: 'transparent !important',
            },
        } }),
}));
export default useStyles;
