var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const IMAGE_EXT = '.jpg';
/**
 * Default Amplience Dynamic media API parameters:
 * https://amplience.com/docs/dynamicmedia/dmapireference.html
 */
const defaultParams = {
    fmt: 'auto',
    qlt: 'default',
    'fmt.jp2.qlt': 60,
    bg: 'rgb(255, 255, 255)',
    // 'fmt.jpeg.interlaced': true,
};
/**
 * Converts all image urls to jpg
 * Adds/replaces params in the query string and appends templates
 */
export const processImageUrl = (urlString, params, templates, isConfigProgressImage) => {
    try {
        const url = new URL(urlString);
        // Only apply default params if not set on the URL already
        Object.keys(defaultParams).forEach((key) => !url.searchParams.has(key) && url.searchParams.set(key, '' + defaultParams[key]));
        // Apply all params passed in
        params && Object.keys(params).forEach((key) => url.searchParams.set(key, '' + params[key]));
        if (isConfigProgressImage) {
            url.searchParams.set('fmt.jpeg.interlaced', 'true');
        }
        const paramString = url.searchParams.toString();
        const templateString = (templates === null || templates === void 0 ? void 0 : templates.join('&')) || '';
        const query = templateString || paramString
            ? '?' + templateString + (templateString && paramString ? '&' : '') + paramString
            : '';
        const pathname = url.pathname.split('.')[0];
        return url.origin + pathname + IMAGE_EXT + query;
    }
    catch (e) {
        // Invalid URL format
        return '';
    }
};
export const checkImageExists = (imgUrl) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = imgUrl;
        loadImg.onload = () => setTimeout(() => {
            resolve(imgUrl);
        }, 50);
        loadImg.onerror = (err) => reject(err);
    });
});
