import { makeStyles } from 'tss-react/mui';
import { colors, spacing, typographySohne } from '../../styles/jss/variables';
import { link } from '../../styles/jss/utils';
const useStyles = makeStyles()((theme) => ({
    errorMessage: {
        color: theme.statusColors.error.base,
    },
    container: {
        '& h4': Object.assign(Object.assign({}, theme.typography.xxs_bold), { margin: 0, marginBottom: spacing.small }),
        '& h5': Object.assign(Object.assign({}, theme.typography.xxs_bold), { margin: 0, marginBottom: spacing.xxs }),
        '& p': Object.assign(Object.assign({}, theme.typography.xxs_regular), { margin: 0 }),
        '& .MuiFormControlLabel-label': {
            marginLeft: spacing.small,
        },
        marginBottom: theme.isFlyingFlowers ? spacing.xl : undefined,
        marginTop: spacing.medium,
    },
    card: {
        borderRadius: 0,
        marginBottom: spacing.small,
        padding: spacing.medium,
    },
    cardSelected: {
        border: `1px solid ${colors.supplementary.black}`,
        padding: `calc(${spacing.medium} - 1px)`,
    },
    rightAlign: {
        float: 'right',
        '& span': {
            fontSize: '1.3rem',
        },
    },
    deliveryOptionsInfo: {
        '& header': {
            backgroundColor: colors.supplementary.white,
            marginBottom: '0',
            '& h3': {
                padding: '34px 12px 0 20px',
                marginRight: '12px',
                [theme.breakpoints.up('md')]: {
                    padding: '45px 0 0 136px',
                    marginRight: '44px',
                },
                display: 'flex',
                '& span': {
                    display: 'inline-block',
                    marginTop: '3px',
                    [theme.breakpoints.down('xl')]: {
                        fontSize: '1.3rem',
                    },
                    [theme.breakpoints.up('md')]: {
                        marginTop: '2px',
                    },
                },
                '& .material-icons': {
                    height: '100%',
                    marginRight: '10px',
                    [theme.breakpoints.up('md')]: {
                        marginRight: '18px',
                    },
                },
            },
        },
        '& .MuiDialogContent-root': {
            overflowY: 'scroll',
            [theme.breakpoints.up('md')]: {
                padding: '0 136px 65px 136px !important',
            },
        },
    },
    content: {
        '& p, & div': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.grey.grey6 }),
    },
    icon: {},
    modal: {},
    collectionCard: {
        borderRadius: 0,
        marginBottom: spacing.medium,
        padding: spacing.medium,
    },
    collectionSubText: Object.assign(Object.assign({}, typographySohne.xxs_regular), { color: colors.grey.grey6 }),
    collectionCardSelected: {
        border: `1px solid ${colors.supplementary.black}`,
        padding: `calc(${spacing.medium} - 1px)`,
        marginBottom: '0px',
    },
    collectionDataCard: Object.assign(Object.assign({}, typographySohne.xxs_regular), { color: colors.grey.grey6, backgroundColor: colors.primary.gold1, padding: '12px 12px 0 80px', border: `1px solid ${colors.supplementary.black}`, borderRadius: '0px', marginBottom: '32px' }),
    collectionHeading: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    collectionTime: {
        padding: `${spacing.small} 0px`,
    },
    changeFloristLink: Object.assign(Object.assign(Object.assign({ color: colors.primary.gold5 }, link(colors.primary.gold4)), theme.typography.xxs_regular), { padding: '12px 0 12px 0' }),
    ccModelTitleStyle: Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm), '& span': Object.assign(Object.assign({}, theme.typography.medium_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }) }),
    clickAndCollectModal: {
        '& .MuiDialog-paper': {
            padding: '0!important', // as the styles of the modal are coming from Parent Modal so we are overriding it
        },
        '& .MuiDialogContent-root': {
            padding: `${spacing.xs} 20px ${spacing.small} 20px !important`, // as the styles of the modal are coming from Parent Modal so we are overriding it
            [theme.breakpoints.up('md')]: {
                padding: `32px 44px!important`, // as the styles of the modal are coming from Parent Modal so we are overriding it
            },
        },
        '& header': {
            position: 'sticky',
            top: 0,
            backgroundColor: colors.supplementary.white,
            boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
            padding: `${spacing.small} 20px 32px 44px`,
            zIndex: 100,
            [theme.breakpoints.up('md')]: {
                padding: `${spacing.medium} 44px 52px 44px`,
            },
        },
    },
    searchNewAddressLink: Object.assign(Object.assign(Object.assign({ color: colors.primary.gold5 }, link(colors.primary.gold4)), theme.typography.xxs_regular), { display: 'block', position: 'absolute', top: 72, [theme.breakpoints.up('md')]: {
            top: 100,
        } }),
}));
export default useStyles;
