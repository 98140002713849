import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        border: `1px solid ${colors.supplementary.black}`,
        marginBottom: spacing.xl,
        padding: `${spacing.large} ${spacing.medium}`,
        '& h1': Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { textTransform: 'inherit', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
        [theme.breakpoints.up('md')]: {
            padding: spacing.xl,
            marginBottom: spacing.medium,
        },
    },
    error: {
        marginBottom: spacing.small,
    },
    content: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('md')]: {
            marginBottom: '42px',
        } }),
    buttons: {
        display: 'inline-block',
        width: theme.isFlyingFlowers ? '100%' : '287px',
        marginBottom: spacing.medium,
    },
    card: {
        display: 'flex',
        padding: spacing.large,
        marginBottom: spacing.medium,
        width: '100%',
        backgroundColor: theme.isFlyingFlowers ? colors.grey.grey1 : colors.background.WHITE,
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('md')]: {
            width: '520px',
        },
    },
    reminderCheckbox: {
        '& span': {
            paddingTop: 0,
            paddingRight: 0,
        },
    },
    reminderSection: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: spacing.medium,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginLeft: theme.isFlyingFlowers ? spacing.small : '36px',
        },
        '& p': {
            margin: 0,
            '&:first-of-type': Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { paddingTop: 0 }),
            '&:last-child': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, paddingTop: 0 }),
        },
    },
    reminderName: {
        marginLeft: 0,
        marginTop: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginLeft: spacing.small,
            marginTop: 0,
        },
    },
    privacyInformation: {
        marginTop: spacing.medium,
        marginBottom: theme.isFlyingFlowers ? spacing.xs : spacing.medium,
        '& p': Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm),
    },
    link: Object.assign(Object.assign(Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm),
    reminderMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    icon: {},
    reminderCard: {},
    privacyLinks: {},
}));
export default useStyles;
