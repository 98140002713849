var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from '@mui/material';
import clsx from 'clsx';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { FormikContext, useField } from 'formik';
import _get from 'lodash/get';
import { useContext, useState } from 'react';
import { showValidationMeta } from '../../utils/common';
import DatePicker from '../DatePicker/DatePicker';
import Icon from '../Icon/Icon';
import useStyles from './TextField.styles';
const TextFieldWrappper = (props) => {
    // check if formik is available
    return useContext(FormikContext) ? _jsx(FormikTextField, Object.assign({}, props)) : _jsx(TextField, Object.assign({}, props));
};
const FormikTextField = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const validation = showValidationMeta(meta);
    const errorMessage = Array.isArray(validation.errorMessage) ? validation.errorMessage[0] : validation.errorMessage;
    return _jsx(TextField, Object.assign({ setValue: helpers.setValue }, field, props, validation, { errorMessage: errorMessage }));
};
const TextField = (props) => {
    const { classes } = useStyles();
    const [isOpen, setOpen] = useState(false);
    // separate props that are not passed through to text input field
    const { className, completed, completedDefault, datePicker, datePickerTitle, minDate, maxDate, rows, id, name, value, disabled, onBlur, onClick, onChange, filterDate, fullWidth, label, required, state, errorMessage, dateFormat, setValue, hideRequiredOptional, hideError, children, trimOnBlur, endAdornment, nonEditable } = props, textInputProps = __rest(props, ["className", "completed", "completedDefault", "datePicker", "datePickerTitle", "minDate", "maxDate", "rows", "id", "name", "value", "disabled", "onBlur", "onClick", "onChange", "filterDate", "fullWidth", "label", "required", "state", "errorMessage", "dateFormat", "setValue", "hideRequiredOptional", "hideError", "children", "trimOnBlur", "endAdornment", "nonEditable"]);
    const handleOpenDialog = (e) => {
        // In absence of event.stopPropagation
        if (!_get(e, 'target.className').includes('MuiDialog-container') && !isOpen) {
            setOpen(true);
        }
    };
    const commonInputProps = Object.assign({ id: id !== null && id !== void 0 ? id : name, type: 'text', disableUnderline: true, className: classes.value, onChange, onBlur: trimOnBlur
            ? (event) => {
                var _a, _b;
                onBlur && onBlur(event);
                setValue && setValue((_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.trim());
            }
            : onBlur, value }, textInputProps);
    const dateValue = (completed && datePicker && isValid(value) && format(value, dateFormat !== null && dateFormat !== void 0 ? dateFormat : 'dd/MM/yyyy')) || '';
    const hideRequiredOptionalCheck = () => {
        if (hideRequiredOptional)
            return '';
        else if (!hideRequiredOptional) {
            if (required)
                return '(Required)';
            else
                return '(Optional)';
        }
    };
    return (_jsxs("div", { className: clsx(classes.textFieldWrapper, fullWidth && classes.fullWidth), children: [completed && _jsx("span", { className: classes.labelCompleted, children: label }), _jsx("div", Object.assign({ className: clsx(classes.textField, className !== null && className !== void 0 ? className : '', state === 'error' && classes.error, state === 'success' && classes.success, disabled && classes.disabled, nonEditable && classes.nonEditable, completed && classes.completed, (completed || datePicker) && classes.buttonType, value && classes.touched) }, (completed && { role: 'button', onClick }), (datePicker && {
                role: 'button',
                onClick: handleOpenDialog,
            }), { children: completed ? (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.textContent, children: datePicker ? dateValue : value || children || completedDefault }), !disabled && _jsx(Icon, { icon: "create", fontSize: "inherit", className: classes.editIcon })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.textContainer, children: [_jsxs("label", { className: classes.label, htmlFor: commonInputProps.id, children: [`${label} `, hideRequiredOptionalCheck()] }), datePicker ? (_jsx(DatePicker, Object.assign({ isOpen,
                                    setOpen,
                                    filterDate,
                                    minDate,
                                    maxDate,
                                    dateFormat,
                                    setValue,
                                    datePickerTitle }, commonInputProps))) : (_jsx(Input, Object.assign({}, commonInputProps, { endAdornment: endAdornment })))] }), datePicker && (_jsx(Icon, { icon: isOpen ? 'clear' : 'calendar_today', fontSize: "inherit", className: classes.icon })), disabled && _jsx(Icon, { icon: "lock", fontSize: "inherit", className: classes.diabledIcon })] })) })), errorMessage && !hideError && _jsx("span", { className: classes.errorMessage, children: errorMessage })] }));
};
export default TextFieldWrappper;
