import { makeStyles } from 'tss-react/mui';
import { link } from '../../../styles/jss/utils';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    pccAddressContainer: {
        flex: 1,
        '& label:nth-of-type(1)': {
            color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
        },
        '& .MuiAutocomplete-input': {
            '&::placeholder': {
                color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey6,
            },
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '98px',
            marginRight: '114px',
        },
        [theme.breakpoints.up('md')]: {
            width: '520px',
            position: 'relative',
            margin: `0 auto ${theme.isFlyingFlowers ? '101px' : '190px'} auto`,
        },
    },
    autoCompleteClass: {
        [theme.breakpoints.up('md')]: {
            width: '100% !important',
            left: '0 !important',
            '& .MuiPaper-root': {
                paddingBottom: 'initial !important',
            },
        },
    },
    manualAddressContainer: {
        marginBottom: spacing.large,
    },
    internationalDeliveryLink: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), link(colors.primary.gold4)), { color: colors.primary.gold5, marginTop: spacing.xs, display: 'flex', justifyContent: 'end center' }),
    internationalDelivery: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    internationalTitle: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm),
    internationalContainer: {
        width: '100%',
    },
    countryDropdown: {
        marginTop: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            marginTop: spacing.large,
        },
        '& > div': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '520px',
            },
        },
        '& > button': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 'initial',
            },
        },
    },
}));
export default useStyles;
