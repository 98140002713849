import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import useStyles from './SpotlightBanner.styles';
import NextLink from 'next/link';
const SpotlightBannerLinkWrapper = (props) => {
    const { buttonLink, bannerLink, sendSpotlightBannerAnalytics } = props;
    const { children } = props;
    const { classes } = useStyles();
    const handleClick = (bannerLink) => {
        sendSpotlightBannerAnalytics === null || sendSpotlightBannerAnalytics === void 0 ? void 0 : sendSpotlightBannerAnalytics(bannerLink);
    };
    if (bannerLink && !(buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.url)) {
        return (_jsx("button", { onClick: () => handleClick(bannerLink), className: classes.linkWrapper, children: _jsx(NextLink, { href: bannerLink, passHref: true, children: children }) }));
    }
    return _jsx(_Fragment, { children: children });
};
export default SpotlightBannerLinkWrapper;
