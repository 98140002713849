import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { default as NextLink } from 'next/link';
import React from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Highlighted from '../../utils/Highlighted/Highlighted';
import useStyles from './Link.styles';
import { Box } from '@mui/material';
const Link = (props) => {
    const { label, url, tabIndex, icon, iconPosition = 'left', iconFontSize, nofollow, noFollow, className, onClick, newWindow, button, variant, thin, highlightTextMatch, id, roundel, rel, sendLinkGalleryTextAnalytics, homeHeroCTAnalytics, sendFooterAnalytics, contentFeatureAnalytics, } = props;
    const { classes } = useStyles();
    const LinkChildren = () => icon && iconPosition === 'top' ? (_jsx(Icon, { icon: icon, label: label, fontSize: iconFontSize, roundel: roundel })) : (_jsxs(_Fragment, { children: [icon && iconPosition === 'left' && (_jsx(Icon, { icon: icon, fontSize: iconFontSize, className: classes.iconLeft, roundel: roundel })), _jsx(Highlighted, { text: label, highlight: highlightTextMatch }), icon && iconPosition === 'right' && (_jsx(Icon, { icon: icon, fontSize: iconFontSize, className: classes.iconRight, roundel: roundel }))] }));
    const LinkContents = React.forwardRef(({ onClick, href }, ref) => (_jsx(Box, Object.assign({ href: href, onClick: onClick, ref: ref, id: id, className: clsx(className, icon && iconPosition !== 'top' && classes.hasInlineIcon) || undefined }, (tabIndex && { tabIndex }), ((nofollow || noFollow) && rel
        ? { rel: 'nofollow canonical' }
        : nofollow || noFollow
            ? { rel: 'nofollow' }
            : rel
                ? { rel: 'canonical' }
                : undefined), (newWindow && { target: '_blank' }), { component: 'a', children: button && variant ? _jsx(Button, { variant, thin, title: label, icon, id, roundel }) : _jsx(LinkChildren, {}) }))));
    LinkContents.displayName = 'LinkContents';
    const handleClick = (e) => {
        if (homeHeroCTAnalytics) {
            homeHeroCTAnalytics(label);
        }
        else if (sendLinkGalleryTextAnalytics) {
            sendLinkGalleryTextAnalytics(label);
        }
        else if (sendFooterAnalytics) {
            sendFooterAnalytics(label);
        }
        else if (contentFeatureAnalytics) {
            contentFeatureAnalytics(label);
        }
        else
            onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return (_jsx(_Fragment, { children: url ? (_jsx(NextLink, { href: url, passHref: true, legacyBehavior: true, children: _jsx(LinkContents, { onClick: handleClick }) })) : onClick ? (_jsx(_Fragment, { children: button && variant ? (_jsx(Button, { variant, thin, title: label, icon, onClick, id, roundel })) : (_jsx(Box, Object.assign({ component: 'button' }, (tabIndex && { tabIndex }), { className: clsx(className, classes.linkButton), onClick: onClick, type: "button", id: id, children: _jsx(LinkChildren, {}) }))) })) : (_jsx(Box, { component: 'span', id: id, className: clsx(className, icon && iconPosition !== 'top' && classes.hasInlineIcon, 'no-link'), children: _jsx(LinkChildren, {}) })) }));
};
export default Link;
