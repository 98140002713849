import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from '../SearchOverlay.styles';
import SearchProducts from '../SearchProducts/SearchProducts';
import PreviousSearches from './PreviousSearches';
import SuggestedSearches from './SuggestedSearches';
import NoSearchResults from '../../NoSearchResults/NoSearchResults';
const InitialSearchView = (props) => {
    const { handleKeywordAndPreviousSearch, recentSearches, keywords = [], categories = [], searchDefaultProducts = [], noResultsFound, inputValue, } = props;
    const { classes } = useStyles();
    const showPreviousSearchAndKeywords = recentSearches.length || keywords.length || categories.length;
    return (_jsxs("div", { className: clsx(classes.searchOverlayInner, classes.searchOverlayWrapper, classes.recentSearchOverlay), children: [noResultsFound && inputValue ? _jsx(NoSearchResults, { searchInput: inputValue }) : null, _jsxs("div", { className: clsx(classes.suggested, searchDefaultProducts.length ? classes.suggestedSearchWithProductsContianer : classes.suggestedSearchContainer), children: [showPreviousSearchAndKeywords ? (_jsxs("div", { children: [_jsx(PreviousSearches, { recentSearches: recentSearches, searchDefaultProducts: searchDefaultProducts, handleKeywordAndPreviousSearch: handleKeywordAndPreviousSearch }), _jsx(SuggestedSearches, { keywords: keywords, categories: categories, searchDefaultProducts: searchDefaultProducts, handleKeywordAndPreviousSearch: handleKeywordAndPreviousSearch })] })) : (_jsx("div", {})), searchDefaultProducts.length ? (_jsx("div", { className: classes.productsContainer, children: _jsx(SearchProducts, { productsList: searchDefaultProducts.slice(0, 3), isFromInitialSearchView: true }) })) : null] })] }));
};
export default InitialSearchView;
