import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import useStyles from './BingMap.styles';
// Singleton promise for loading the Bing Maps script
let bingMapsScriptLoaded = null;
// Function to load Bing Maps script
const loadBingMapsScript = (callback) => {
    if (!bingMapsScriptLoaded) {
        bingMapsScriptLoaded = new Promise((resolve) => {
            const scriptTag = document.createElement('script');
            scriptTag.setAttribute('type', 'text/javascript');
            scriptTag.setAttribute('src', `https://www.bing.com/api/maps/mapcontrol?callback=initializeBingMap`);
            scriptTag.async = true;
            scriptTag.defer = true;
            scriptTag.id = 'bingMapsScript';
            document.body.appendChild(scriptTag);
            window.initializeBingMap = () => {
                resolve();
                callback();
            };
        });
    }
    else {
        // If script is already loaded, just execute the callback
        bingMapsScriptLoaded.then(callback);
    }
};
const BingMap = React.forwardRef((props, ref) => {
    const { coordinates, zoomLevel, isFromOrderConfirmation } = props;
    const { classes } = useStyles();
    const mapKey = process.env.NEXT_PUBLIC_BING_MAPS_KEY;
    // Refs for map container and map instance
    const mapContainer = useRef(null);
    const map = useRef(null);
    // Create the map with markers and custom options
    const makeMap = useCallback(() => {
        const { Maps } = window.Microsoft || {};
        const addPushpins = (pushPinsToAdd, mapInstance, Maps) => {
            pushPinsToAdd.forEach((pushPin) => {
                const pushPinOptions = {
                    text: pushPin.center.type || '',
                    icon: `/images/maps/${pushPin.center.type ? 'placewithnumber' : 'place'}.svg`,
                    textOffset: new Maps.Point(0, 8),
                };
                const newPin = new Maps.Pushpin(pushPin.center, pushPinOptions);
                mapInstance.entities.push(newPin);
            });
        };
        if ((Maps === null || Maps === void 0 ? void 0 : Maps.Location) && (Maps === null || Maps === void 0 ? void 0 : Maps.Map)) {
            map.current = new Maps.Map(mapContainer.current, {
                credentials: mapKey,
                center: new Maps.Location(coordinates[0].latitude, coordinates[0].longitude),
                mapTypeId: Maps.MapTypeId.road,
                zoom: zoomLevel,
                showLocateMeButton: false,
                showMapTypeSelector: false,
                showZoomButtons: isFromOrderConfirmation,
            });
            const pushPinCoordinates = coordinates.map((coordinate) => ({
                center: { latitude: coordinate.latitude, longitude: coordinate.longitude, type: coordinate.type },
            }));
            addPushpins(pushPinCoordinates, map.current, Maps);
        }
    }, [coordinates, mapKey, zoomLevel, isFromOrderConfirmation]);
    useEffect(() => {
        // Load the Bing Maps script only if it hasn't been loaded yet
        loadBingMapsScript(makeMap);
    }, [makeMap]);
    return _jsx("div", { ref: ref || mapContainer, className: classes.bingMap });
});
BingMap.displayName = 'BingMap';
export default BingMap;
