import { set, isAfter, isBefore, isEqual } from 'date-fns';
const setDateWithTime = (time) => {
    const date = set(new Date(), {
        hours: parseInt(time === null || time === void 0 ? void 0 : time.split(':')[0]),
        minutes: parseInt(time === null || time === void 0 ? void 0 : time.split(':')[1]),
        seconds: 0,
        milliseconds: 0,
    });
    return date;
};
const defaultIFImage = {
    url: 'https://media.interflora.co.uk/i/interflora/FlowerIcon_400px',
    altText: 'FlowerIcon_400px',
};
const defaultFFImage = {
    url: 'https://media.flyingflowers.co.uk/i/flyingflowers/Check_Circle_FF_400px',
    altText: 'Check_Circle_FF_400px',
};
export const getDefaultImage = (isFlyingFlowers) => {
    return isFlyingFlowers ? defaultFFImage : defaultIFImage;
};
export const checkStartandEndTime = (startTime, endTime) => {
    const startDate = setDateWithTime(startTime);
    const endDate = setDateWithTime(endTime);
    const londonTime = Intl.DateTimeFormat('en-GB', { timeZone: 'Europe/London', timeStyle: 'short' }).format(Date.now());
    const currentDateInLondonTime = setDateWithTime(londonTime);
    const isCurrentTimeValid = (isAfter(currentDateInLondonTime, startDate) && isBefore(currentDateInLondonTime, endDate)) ||
        isEqual(currentDateInLondonTime, startDate) ||
        isEqual(currentDateInLondonTime, endDate);
    return isCurrentTimeValid;
};
