var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import ProductGallery from '../../components/ProductGallery/ProductGallery';
import Alert from '../Alert/Alert';
import FeatureBanner from '../FeatureBanner/FeatureBanner';
import useStyles from './DeliveryPass.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
export const AMPLIENCE_KEY = 'component/delivery-pass-overview';
const DeliveryPass = (props) => {
    const { galleryTitle, galleryBody, products, genericContent, addToBasket } = props;
    const isDeliveryPassInnerContentClass = true;
    const [errorMessage, setErrorMessage] = useState('');
    const ref = useRef(null);
    const { classes } = useStyles();
    const onClick = (sku) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            if (addToBasket)
                yield addToBasket(sku);
        }
        catch (error) {
            setErrorMessage('There was a problem, please try again later.');
            (_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.closest('.MuiDialog-paperScrollPaper')) === null || _b === void 0 ? void 0 : _b.scrollTo(0, 0);
        }
    });
    useLoaded(props);
    return (_jsxs("div", { className: classes.container, ref: ref, children: [errorMessage && _jsx(Alert, { type: "alertError", message: errorMessage, className: classes.errorMessage }), _jsx("div", { className: classes.deliveryPassHeading, children: _jsx(FeatureBanner, Object.assign({}, genericContent, { isDeliveryPassInnerContentClass: isDeliveryPassInnerContentClass })) }), _jsx("div", { children: _jsx(ProductGallery, { title: galleryTitle, body: galleryBody, products: products, cardDisplayType: "DELIVERY_PASS", galleryDisplayType: "GRID", addToBasket: onClick }) })] }));
};
export default DeliveryPass;
