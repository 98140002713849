import { makeStyles } from 'tss-react/mui';
import { colors, spacing, statusColorsInterflora } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    filterList: {
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        '& ul': {
            display: 'flex',
            overflowX: theme.isFlyingFlowers ? 'scroll' : 'auto',
            marginLeft: theme.isFlyingFlowers ? 'initial' : '-6px',
            width: '100%',
            padding: `6px 6px 10px ${theme.isFlyingFlowers ? '6px' : '0px'}`,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                marginRight: spacing.medium,
                padding: '6px 6px 10px 6px',
            },
        },
        '& li': {
            '&:not(:last-child)': {
                marginRight: spacing.xs,
            },
        },
    },
    clearFilter: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.grey.grey6 : colors.grey.grey5 }, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), { whiteSpace: 'nowrap' }) }),
    itemCount: Object.assign(Object.assign({}, theme.typography.small_bold), { color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), { paddingRight: spacing.medium }), theme.typographySpacing.none), { whiteSpace: 'nowrap' }) }),
    filterAction: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            order: 1,
            marginBottom: 0,
        },
    },
    navigationButton: {
        position: 'absolute',
        top: '15px',
        zIndex: 5,
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    },
    showLess: {
        left: 0,
        '& .MuiIcon-root': {
            position: 'relative',
            left: '2px',
        },
    },
    showMore: {
        right: 0,
    },
    listContainer: {
        marginRight: theme.isFlyingFlowers ? spacing.small : '-2px',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('xl')]: {
            width: 'auto',
        },
    },
    filterText: {
        border: `1px solid ${statusColorsInterflora.success.base}`,
        backgroundColor: colors.supplementary.white,
        boxShadow: 'none !important',
    },
}));
export default useStyles;
