import { makeStyles } from 'tss-react/mui';
import omit from 'lodash/omit';
import { formStyles } from '../../styles/jss/form';
import { colors, spacing } from '../../styles/jss/variables';
import { addEditAddressStyles } from '../../styles/jss/addEditAddress';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign(Object.assign({}, addEditAddressStyles(theme)), omit(formStyles(theme), ['container', 'box'])), { contactText: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xxs_regular_sm)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }), contextFFText: {
        marginBottom: theme.isFlyingFlowers ? spacing.xl : undefined,
    }, addressContainer: {
        '& label': {
            color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
        },
    }, button: {
        marginBottom: spacing.medium,
        display: 'block',
    }, checkCircle: {
        color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey6,
    }, error: { marginBottom: spacing.small } })));
export default useStyles;
