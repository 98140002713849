import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    filterButton: {
        borderRadius: theme.isFlyingFlowers ? 8 : 4,
        padding: `11px ${theme.isFlyingFlowers ? spacing.small : spacing.xs} 11px ${theme.isFlyingFlowers ? spacing.small : spacing.xs}`,
        textTransform: 'capitalize',
        border: theme.isFlyingFlowers ? `1px solid ${colors.black[600]}` : `1px solid ${colors.grey.grey7}`,
        width: 'initial',
        [theme.breakpoints.up('md')]: {
            padding: `15px ${spacing.xs} 15px ${spacing.xs}`,
            width: theme.isFlyingFlowers ? 'initial' : '180px',
        },
        '&:focus': {
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
        color: colors.grey.grey7,
        '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            backgroundColor: 'inherit',
            [`& .${classes.title}`]: {
                color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
            },
        },
        '& .MuiButton-endIcon > .MuiSvgIcon-root': {
            fontSize: '24px',
        },
    },
    open: {
        backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
    },
    title: Object.assign(Object.assign({}, theme.typography.xxs_bold), { whiteSpace: 'nowrap', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, display: 'inline-block', [theme.breakpoints.up('md')]: {
            minWidth: theme.isFlyingFlowers ? 'initial' : '120px',
        }, minWidth: theme.isFlyingFlowers ? 'initial' : '93px', textAlign: theme.isFlyingFlowers ? 'initial' : 'left' }),
    resultCount: Object.assign(Object.assign({}, theme.typography.xxs_regular), { paddingLeft: spacing.xxs, lineHeight: '0.75rem' }),
    icon: {
        paddingLeft: '6px',
        [theme.breakpoints.up('md')]: {
            paddingLeft: spacing.medium,
        },
        '& span': {
            fontSize: '17px',
        },
    },
    iconButton: {
        '& .MuiButton-label': {
            justifyContent: 'space-between',
        },
    },
}));
export default useStyles;
