import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import Image from 'next/image';
import useStyles from './FarEyeImage.styles';
const FarEyeImage = (props) => {
    const { farEyeImage, altText, width, height, isFromOrderDetails } = props;
    const { classes } = useStyles();
    return (_jsx("div", { className: clsx(classes.orderHistoryFarEyeImageContainer, isFromOrderDetails && classes.orderDetailsFarEyeImageContainer), "data-testid": "farEyeImageContainer", children: _jsx(Image, { src: farEyeImage, className: classes.orderHistoryFarEyeImage, alt: altText, width: width, height: height, unoptimized: true }) }));
};
export default FarEyeImage;
