import { makeStyles } from 'tss-react/mui';
import { link } from '../../../styles/jss/utils';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.xl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : theme.typographySpacing.xl_bold_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.large_bold : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_bold_lg : theme.typographySpacing.xl_bold_sm)) }),
    signUpForm: {
        flex: '100%',
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            flex: '50%',
            flexGrow: 0,
            marginRight: '81px',
        },
    },
    message: {
        '& p': Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6 }), theme.typographySpacing.xl_regular_sm), { marginBottom: `${theme.typographySpacing.xl_regular_sm.marginBottom}!important` }),
        [theme.breakpoints.up('md')]: {
            '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.small_regular_lg), { marginBottom: `${theme.typographySpacing.small_regular_lg.marginBottom}!important` }),
        },
    },
    signUpFormImage: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            flex: '50%',
            display: 'block',
        },
    },
    imgContainer: {
        width: '100%',
        marginBottom: spacing.xxs,
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '100%',
            '& > span': {
                width: '100% !important',
                height: '100% !important',
            },
        },
    },
    newsletterSignUpContainer: {
        display: 'flex',
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    buttons: {
        marginTop: '52px',
    },
    saveButton: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '184px',
        },
    },
    input: Object.assign({}, theme.typographySpacing.xl_regular_sm),
    privacyPolicyText: {
        '& p': Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : undefined)), { color: colors.grey.grey6, marginTop: `${spacing.medium} !important`, marginBottom: 0, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_regular), '& a': Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }) }),
    },
    error: { marginBottom: spacing.small },
}));
export default useStyles;
