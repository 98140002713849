import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './PersonalDetailsView.styles';
import TextField from '../TextField/TextField';
import { replaceCountryCodes } from '../../utils/common';
import Alert from '../Alert/Alert';
const selectedAddressLines = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'county', 'postalCode', 'country'];
const PersonalDetailsView = (props) => {
    const { classes } = useStyles();
    const { firstName, lastName, email, address, contactNumber, success, onClick } = props;
    const formattedAddress = replaceCountryCodes(address);
    const handleChange = (component) => {
        if (onClick) {
            onClick(component);
        }
    };
    return (_jsxs("div", { className: classes.container, children: [!!success && (_jsx(Alert, { type: "alertSuccess", message: `Your ${success} has been updated successfully`, className: classes.alertMessage })), _jsx("h1", { children: "Personal Details" }), _jsxs("div", { className: classes.box, children: [firstName && lastName && (_jsxs("div", { className: classes.profileSection, children: [_jsx("h2", { children: "Name" }), _jsx("div", { children: _jsx(TextField, { name: "fullName", value: `${firstName} ${lastName}`, placeholder: "Firstname Lastname", fullWidth: true, required: true, completed: true, onClick: () => handleChange('EditName') }) })] })), _jsxs("div", { className: classes.profileSection, children: [_jsx("h2", { children: "Email" }), _jsx("div", { children: _jsx(TextField, { name: "email", value: email, placeholder: "email@address.com", fullWidth: true, required: true, completed: true, onClick: () => handleChange('EditEmail') }) }), _jsx("span", { className: classes.caption, children: "This is the email address you use to sign into your account." })] }), _jsxs("div", { className: classes.profileSection, children: [_jsx("h2", { children: "Contact number" }), _jsx("div", { children: _jsx(TextField, { name: "contactNumber", value: contactNumber, placeholder: "07654 321 098", fullWidth: true, required: true, completed: true, onClick: () => handleChange('EditPhoneNumber') }) }), _jsx("span", { className: classes.caption, children: "We will only use this to contact you about your orders." })] }), _jsxs("div", { className: classes.profileSection, children: [_jsx("h2", { children: "Address" }), _jsx("div", { children: _jsx(TextField, { name: "address", completed: true, fullWidth: true, onClick: () => handleChange('EditBillingAddress'), children: !!(formattedAddress === null || formattedAddress === void 0 ? void 0 : formattedAddress.addressLine1) &&
                                        selectedAddressLines.map((addressLine, i) => {
                                            const addressValue = formattedAddress[addressLine];
                                            return !!addressValue && _jsx("div", { children: addressValue }, `${addressLine}${i}`);
                                        }) }) }), _jsx("span", { className: classes.caption, children: "This is used as your default billing address in checkout." })] })] })] }));
};
export default PersonalDetailsView;
