import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    accordianContainer: {
        width: '100%',
    },
    accordianRoot: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey7 }, theme.typography.small_bold), { boxShadow: 'none', borderRadius: '0!important', backgroundColor: colors.grey.grey1, [`&.${classes.accordianExpanded}`]: {
            marginTop: 0,
            marginBottom: 0,
            boxShadow: 'none',
            '&:before': {
                opacity: 1,
            },
        }, '&:before': {
            height: 0,
            content: 'unset',
        }, '&:first-child': {
            borderTop: `1px solid ${colors.grey.grey3}`,
            [theme.breakpoints.up('xs')]: { borderTop: 'none' },
        }, '& .MuiAccordionDetails-root': {
            padding: '0px',
        }, '& .MuiAccordionSummary-expandIconWrapper': {
            padding: '12px',
            marginRight: '-12px',
        } }),
    accordianExpanded: {},
    accordianSummaryRoot: {
        display: 'flex',
        padding: '0 16px',
        overflow: 'hidden',
        borderBottom: `1px solid ${colors.grey.grey3}`,
        [`&.${classes.accordianSummaryExpanded}`]: {
            minHeight: '48px',
        },
    },
    accordianSummaryExpanded: {},
    accordianSummaryContent: {
        [`&.${classes.accordianSummaryExpanded}`]: {
            margin: '0',
        },
    },
    accordianSummaryExpandIcon: {
        [`&.${classes.accordianSummaryExpanded}`]: {
            transform: theme.isFlyingFlowers ? undefined : 'rotate(90deg)',
        },
    },
    accordianDetailsRoot: {
        padding: 0,
        backgroundColor: colors.supplementary.white,
        '& ul': {
            width: '100%',
        },
        '& li': Object.assign(Object.assign({ height: '52px', borderBottom: `1px solid ${colors.grey.grey3}` }, theme.typography.small_bold), { padding: '0px 16px', display: 'flex', alignItems: 'center', '& a': Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? undefined : colors.grey.grey6, textDecoration: 'none' }) }),
    },
    cookiePreferenceLink: Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? undefined : colors.grey.grey6, textDecoration: 'none' }),
}));
export default useStyles;
