import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import clsx from 'clsx';
import useStyles from './PDPTimedContentBulletPoints.styles';
import Icon from '../Icon/Icon';
import { checkStartandEndTime, getDefaultImage } from './utils';
import { format } from 'date-fns';
import { SiteContext } from '../../utils/common';
import Image from '../Image/Image';
const PDPTimedContentBulletPoints = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers = false } = useContext(SiteContext);
    const { startTime, endTime, weekDays, icon, messages } = props;
    const isValidStartandEndTime = checkStartandEndTime(startTime, endTime);
    const currentDay = format(new Date(), 'EEEE').toLowerCase();
    const isValidWeekDay = weekDays[currentDay];
    const defaultImage = getDefaultImage(isFlyingFlowers);
    return isValidStartandEndTime && isValidWeekDay ? (_jsx(_Fragment, { children: messages === null || messages === void 0 ? void 0 : messages.map((text, index) => {
            var _a;
            return (_jsxs("div", { className: clsx(classes.container, !icon && classes.defaultImageContainer), children: [icon ? (_jsx(Icon, { icon: icon, className: classes.icon })) : (_jsx(Image, { imageUrl: defaultImage.url, altText: (_a = defaultImage === null || defaultImage === void 0 ? void 0 : defaultImage.altText) !== null && _a !== void 0 ? _a : '', className: classes.image, height: 20, width: 20 })), _jsx("div", { className: classes.deliveryMessageText, children: text })] }, `${index}${messages}`));
        }) })) : null;
};
export default PDPTimedContentBulletPoints;
