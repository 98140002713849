import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    contentCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& li': {
            lineHeight: 0,
        },
    },
    prevArrow: {
        marginRight: spacing.small,
    },
    dotList: {
        pointerEvents: 'none',
    },
}));
export default useStyles;
