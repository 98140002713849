import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_CARD_WIDTH = { sm: 473, md: 796, lg: 1009, xl: 1366 };
const useStyles = makeStyles()((theme) => ({
    navigationGuidedContainer: {
        // backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[400] : colors.grey.grey2,
        display: 'flex',
        flexDirection: 'column',
        padding: `${spacing.small} ${spacing.small} ${spacing.medium} ${spacing.small}`,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0  auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: `${MAX_CARD_WIDTH.sm}px`,
            margin: '0 auto',
            padding: `12px ${spacing.large} ${spacing.medium}`,
        },
        [theme.breakpoints.between(839, 1023.95)]: {
            maxWidth: theme.isFlyingFlowers ? '552px' : '563px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: `${MAX_CARD_WIDTH.md}px`,
            flexDirection: 'row',
            margin: '0 auto',
            padding: `12px ${spacing.large}`,
        },
        [theme.breakpoints.between(1279, 1671.95)]: {
            maxWidth: `${MAX_CARD_WIDTH.lg}px`,
            padding: theme.isFlyingFlowers ? `12px ${spacing.large}` : undefined,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: `${MAX_CARD_WIDTH.xl}px`,
        },
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { textAlign: 'center', flex: '100%', paddingTop: '2px', paddingBottom: '2px', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('sm')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { paddingTop: theme.isFlyingFlowers ? '1px' : '10px', paddingBottom: theme.isFlyingFlowers ? '3px' : '2px' }), [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            marginRight: spacing.large,
            flex: '50%',
            padding: '2px 0 !important',
        }, [theme.breakpoints.up('lg')]: {
            maxWidth: 'initial',
        }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.none) }),
    navigationInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 auto',
        },
    },
    chooseDate: {
        border: '0 !important',
    },
    navigationGuidedButton: {
        marginTop: spacing.small,
        '& .MuiButton-startIcon': {
            marginRight: '7px !important',
        },
        '& .MuiButtonBase-root': {
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: spacing.small,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    basicButton: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? link() : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }),
}));
export default useStyles;
