import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        backgroundColor: colors.grey.grey1,
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        [`& .${classes.logInText}, & .${classes.linkText}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { lineHeight: '0.8rem' }),
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xs,
        },
    },
    logInText: {},
    iconContainer: {
        flexShrink: 0,
        display: 'flex',
        marginRight: '12px',
    },
    linkText: Object.assign({ color: theme.isFlyingFlowers ? undefined : colors.primary.gold5 }, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
}));
export default useStyles;
