import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    root: {
        position: 'relative',
        display: 'flex',
        [`&.${classes.checked} .${classes.layerCircle} circle`]: { fill: colors.supplementary.white },
        [`&.${classes.error} .${classes.layerCircle} circle`]: {
            stroke: theme.statusColors.error.base,
            strokeWidth: '2px',
            r: 15,
        },
        [`&.${classes.checked} .${classes.layerChecked}`]: {
            transform: 'scale(1)',
            transition: theme.transitions.create('transform', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shortest,
            }),
        },
        [`&.${classes.disabled} .${classes.layerDisabled}`]: {
            transform: 'scale(1)',
        },
    },
    layerChecked: {
        left: 0,
        position: 'absolute',
        transform: 'scale(0)',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest,
        }),
    },
    layerDisabled: {
        left: 0,
        position: 'absolute',
        transform: 'scale(0)',
    },
    checked: {},
    disabled: {},
    error: {},
    layerCircle: { '& circle': { fill: colors.grey.grey2, stroke: colors.grey.grey5 } },
}));
export default useStyles;
