import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { priceFormat, SiteContext } from '../../../utils/common';
import useStyles from '../OrderConfirmation.styles';
import OrderConfirmationItem from './OrderConfirmationItem';
const OrderConfirmationGiftSummary = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { order, isFromOrderDetails = false } = props;
    const { billingAddress, consignments, deliveryCost, subtotal, totalPrice, totalDeliveryPassDiscount, couponDiscount, isLegacy, } = order;
    const selectedAddressLines = [
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'city',
        'county',
        'postalCode',
        'country',
    ];
    return (_jsxs(_Fragment, { children: [consignments.map((consignment) => {
                var _a, _b, _c, _d, _e;
                const deliveryAddress = (_a = consignment === null || consignment === void 0 ? void 0 : consignment.delivery) === null || _a === void 0 ? void 0 : _a.address;
                const serviceDescription = (_b = consignment === null || consignment === void 0 ? void 0 : consignment.delivery) === null || _b === void 0 ? void 0 : _b.serviceDescription;
                const items = consignment === null || consignment === void 0 ? void 0 : consignment.items;
                const farEyeImage = (_c = consignment === null || consignment === void 0 ? void 0 : consignment.orderTracking) === null || _c === void 0 ? void 0 : _c.image;
                const isDigital = consignment === null || consignment === void 0 ? void 0 : consignment.isDigital;
                const orderNumber = consignment === null || consignment === void 0 ? void 0 : consignment.orderNumber;
                const isClickAndCollect = consignment === null || consignment === void 0 ? void 0 : consignment.isClickAndCollect;
                const collectionAddress = (_d = consignment === null || consignment === void 0 ? void 0 : consignment.collection) === null || _d === void 0 ? void 0 : _d.address;
                const collectionDate = (_e = consignment === null || consignment === void 0 ? void 0 : consignment.collection) === null || _e === void 0 ? void 0 : _e.date;
                const collection = consignment === null || consignment === void 0 ? void 0 : consignment.collection;
                return items.map((item) => (_jsx(OrderConfirmationItem, { deliveryAddress: deliveryAddress, item: item, isDigital: isDigital, orderNumber: orderNumber, serviceDescription: serviceDescription, isFromOrderDetails: isFromOrderDetails, farEyeImage: farEyeImage, isClickAndCollect: isClickAndCollect, collectionAddress: collectionAddress, collectionDate: collectionDate, collection: collection }, `${item.lineItem.id}_order_confirmation_summary}`)));
            }), _jsxs("div", { className: clsx(classes.active, classes.attached, classes.border), children: [_jsx("div", { className: classes.sectionTitle, children: "Billing and payment information" }), _jsxs("ul", { className: clsx(classes.definitionList, classes.equalWidthColumns), children: [_jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Your name:" }), _jsx("div", { children: `${billingAddress.firstName} ${billingAddress.lastName}` })] }), !!billingAddress.phone && (_jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Your contact number:" }), _jsx("div", { children: billingAddress.phone })] })), _jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Your email:" }), _jsx("div", { children: billingAddress.email })] }), _jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Billing address:" }), _jsx("ul", { className: classes.addressList, children: selectedAddressLines.map((addressLine, i) => {
                                            const addressValue = billingAddress[addressLine];
                                            return !!addressValue && _jsx("li", { children: addressValue }, `${addressLine}${i}`);
                                        }) })] })] })] }), _jsx("div", { className: clsx(classes.active, classes.fullSpacing, classes.border), children: _jsxs("ul", { className: clsx(classes.definitionList, classes.equalWidthColumns, classes.orderSummaryTotal), children: [!isLegacy && (_jsxs(_Fragment, { children: [_jsxs("li", { children: [_jsx("div", { children: "Order subtotal:" }), _jsx("div", { children: subtotal.formatted })] }), _jsxs("li", { children: [_jsx("div", { children: "Delivery charge:" }), _jsx("div", { children: deliveryCost.formatted || 'FREE' })] }), !!(totalDeliveryPassDiscount === null || totalDeliveryPassDiscount === void 0 ? void 0 : totalDeliveryPassDiscount.centAmount) && !isFlyingFlowers && (_jsxs("li", { children: [_jsx("div", { children: "Delivery Pass discount:" }), _jsx("div", { children: priceFormat(Object.assign(Object.assign({}, totalDeliveryPassDiscount), { centAmount: -totalDeliveryPassDiscount.centAmount })) })] })), !!(couponDiscount === null || couponDiscount === void 0 ? void 0 : couponDiscount.centAmount) && (_jsxs("li", { children: [_jsx("div", { children: "Discount:" }), _jsx("div", { children: priceFormat(Object.assign(Object.assign({}, couponDiscount), { centAmount: -couponDiscount.centAmount })) })] }))] })), _jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Order total:" }), _jsxs("div", { className: classes.highlight, children: [totalPrice.formatted, _jsx("span", { className: classes.supplementary, children: "inc. delivery" })] })] })] }) })] }));
};
export default OrderConfirmationGiftSummary;
