import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './RoundButton.styles';
import clsx from 'clsx';
import Image from 'next/image';
import { Icon, Fab } from '@mui/material';
const RoundButton = (props) => {
    const { icon, label, labelAlign, ariaLabel, disabled, onClick, className, classNames, small, iconWidth, style, id, disableRipple, svg, altText, } = props;
    const { classes } = useStyles();
    const labelClassName = {
        [classes.label]: true,
        [classes.labelAlignSide]: !labelAlign || labelAlign === 'side',
        [classes.labelAlignBottom]: labelAlign === 'bottom',
    };
    return (_jsxs("div", { style: style, className: clsx(classes.buttonContainer, className, classNames === null || classNames === void 0 ? void 0 : classNames.container, small && classes.small), id: id !== null && id !== void 0 ? id : '', children: [_jsxs(Fab, { variant: "circular", className: clsx(classes.roundButton, small && classes.small), onClick: onClick, "aria-label": ariaLabel, disabled: disabled, TouchRippleProps: { classes: { child: disableRipple ? classes.noRipple : classes.rippleClass } }, children: [icon && (_jsx(Icon, Object.assign({ className: clsx(classes.icon, disabled && classes.disabledIcon) }, (iconWidth && { style: { width: iconWidth } }), { children: icon }))), svg && _jsx(Image, { src: svg, alt: altText, className: classes.icon, priority: true, height: 24, width: 24 })] }), label && _jsx("span", { className: clsx(labelClassName, classNames === null || classNames === void 0 ? void 0 : classNames.label), children: label })] }));
};
export default RoundButton;
