import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slide } from '@mui/material';
import NavLink from '../NavLink/NavLink';
import MobileSpotlightDoubleNavigationCard from './MobileSpotlightDoubleNavigationCard';
import useStyles from './MegaNavMobile.styles';
import MobileNavProducts from './MobileNavProducts';
const MobileNavSingleLinkGroup = (props) => {
    var _a, _b;
    const { classes } = useStyles();
    const { navDirection, productsList, currentMenuItem, spotlightDoubleBanner, isAllSingleSpotlightCards, hasCombinationSpotlightCards, setBadgeLineHeight, onLinkSelection, } = props;
    return (_jsx(Slide, { direction: navDirection, in: true, children: _jsxs("ul", { children: [_jsx(NavLink, Object.assign({}, currentMenuItem.link, { onClick: currentMenuItem.link.url ? () => onLinkSelection(currentMenuItem.link.label) : undefined, isFromMegaNavMobile: true })), currentMenuItem.linkGroups[0].titleLink.label && (_jsx(NavLink, Object.assign({}, currentMenuItem.linkGroups[0].titleLink, { onClick: currentMenuItem.linkGroups[0].titleLink.url
                        ? () => onLinkSelection(currentMenuItem.linkGroups[0].titleLink.label)
                        : undefined, isFromMegaNavMobile: true }))), currentMenuItem.linkGroups[0].links.map((link) => link.label ? (_jsx(NavLink, Object.assign({}, link, { onClick: link.url ? () => onLinkSelection(link.label) : undefined, isFromMegaNavMobile: true }), link.label)) : null), (productsList === null || productsList === void 0 ? void 0 : productsList.length) ? (_jsx(MobileNavProducts, { productsList: productsList, isDigital: productsList.length === 1 && ((_a = productsList[0]) === null || _a === void 0 ? void 0 : _a.typeKey) === 'digital', setBadgeLineHeight: setBadgeLineHeight })) : null, (spotlightDoubleBanner === null || spotlightDoubleBanner === void 0 ? void 0 : spotlightDoubleBanner.length) ? (_jsx(MobileSpotlightDoubleNavigationCard, { spotlightDoubleBanner: spotlightDoubleBanner, hasCombinationSpotlightCards: !!hasCombinationSpotlightCards, isAllSingleSpotlightCards: isAllSingleSpotlightCards })) : null, (currentMenuItem.linkGroups[0].links.length > 8 || !!((_b = currentMenuItem.products) === null || _b === void 0 ? void 0 : _b.length)) && (_jsx("li", { className: classes.navLinkDummy }))] }) }));
};
export default MobileNavSingleLinkGroup;
