import { makeStyles } from 'tss-react/mui';
import { spacing, typographySohne, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    contentInner: {
        width: '100%',
        margin: `${spacing.medium} !important`,
        [theme.breakpoints.up('md')]: {
            margin: `${spacing.large} !important`,
        },
        '& > :last-child': {
            [theme.breakpoints.up('md')]: {
                marginBottom: '0px',
            },
        },
    },
    headingLine: {
        alignSelf: 'flex-start',
        display: 'block',
    },
    linkContainer: {
        '& .MuiButtonBase-root.MuiButton-root': {
            '& .MuiButton-label': {
                '& span': {
                    padding: '0 !important',
                },
            },
        },
    },
    heading: {
        width: '100%',
    },
    message: {},
    fontSohne: {
        [`& .${classes.heading}`]: Object.assign(Object.assign(Object.assign({}, typographySohne.large_bold), theme.typographySpacing.large_bold_sm), { marginBottom: `${spacing.small} !important`, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, typographySohne.xxl_bold), theme.typographySpacing.xxl_bold_sm), { marginBottom: `${spacing.large} !important` }), [theme.breakpoints.up(1920)]: Object.assign(Object.assign(Object.assign({}, typographySohne.xxxxl), theme.typographySpacing.xxxxl_sm), { marginBottom: `${spacing.large} !important` }) }),
        [`& .${classes.message}`]: Object.assign(Object.assign({}, typographySohne.small_regular), { marginBottom: `${spacing.large}`, [theme.breakpoints.up('sm')]: Object.assign(Object.assign(Object.assign({}, typographySohne.small_bold), theme.typographySpacing.small_bold_lg), { marginBottom: `${spacing.xs} !important` }), [theme.breakpoints.up(1920)]: Object.assign(Object.assign(Object.assign({}, typographySohne.medium_regular), theme.typographySpacing.medium_regular_sm), { marginBottom: `${spacing.medium} !important` }) }),
    },
    RED: {
        color: colors.genericContentHeading.RED,
    },
    GREEN: {
        color: colors.genericContentHeading.GREEN,
    },
    PEACH: {
        color: colors.genericContentHeading.PEACH,
    },
    LILAC: {
        color: colors.genericContentHeading.LILAC,
    },
    PINK: {
        color: colors.genericContentHeading.PINK,
    },
    PURPLE: {
        color: colors.genericContentHeading.PURPLE,
    },
    YELLOW: {
        color: colors.genericContentHeading.YELLOW,
    },
    BLACK: {
        color: colors.genericContentHeading.BLACK,
    },
    WHITE: {
        color: colors.genericContentHeading.WHITE,
    },
    noImagecontent: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.down('xl')]: {
            position: theme.isFlyingFlowers ? 'absolute' : 'relative',
        },
    },
    noImageContentInner: {
        width: '100%',
    },
    homeBannerRightContainer: {
        placeContent: 'end !important',
    },
    homeBannerLeftContainer: {
        placeContent: 'start !important',
    },
    content: {
        alignSelf: 'start !important',
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            width: '0% !important',
            position: 'absolute !important',
        },
    },
}));
export default useStyles;
