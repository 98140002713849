import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        // For full width image to position against
        position: 'relative',
        display: 'flex',
        // Mobile - image below content
        flexDirection: 'column-reverse',
        placeContent: 'center',
        overflow: 'hidden',
        '& video': {
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            objectFit: 'cover',
        },
    },
    heading: {
        'h1&,h2&,h3&,h4&,div&': Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: _params.isQuotation ? 0 : spacing.small, [theme.breakpoints.up('xl')]: Object.assign({}, theme.typography.xl_bold) }),
    },
    message: {
        'p&,div&': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.small_regular_lg) }),
    },
    contentInner: {
        'h1&,h2&,h3&,h4&,div&,p&': {
            padding: spacing.large,
            margin: 'initial',
            [theme.breakpoints.up('sm')]: {
                padding: spacing.large,
            },
            [theme.breakpoints.up('md')]: {
                padding: spacing.xl,
            },
            [theme.breakpoints.up('xl')]: {
                padding: spacing.xxl,
            },
        },
        '& > :last-child': {
            marginBottom: spacing.large,
            [theme.breakpoints.up('md')]: {
                marginBottom: '0px',
            },
        },
    },
    headingLine: {
        alignSelf: 'flex-start',
        marginBottom: spacing.xs,
        display: 'block',
    },
    positionBottom: {
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
    },
    positionTop: {
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
    },
    positionCenter: {
        alignItems: 'center',
        alignSelf: 'flex-center',
    },
    largeAdvertisingHeading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl_bold), theme.typographySpacing.xxl_bold_sm), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), theme.typographySpacing.xxxxl_sm) }),
    standardHeading: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm) }),
    headingColor: {
        color: (_params === null || _params === void 0 ? void 0 : _params.headingColor) ? `${colors.genericContentHeading[_params === null || _params === void 0 ? void 0 : _params.headingColor]}` : '',
    },
    ffImage: {},
    positionLeft: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom right',
            },
        },
    },
    positionRight: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            [`& .${classes.content}`]: {
                justifyContent: 'right',
            },
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom left',
            },
        },
    },
    content: {},
    image: {},
}));
export default useStyles;
