import { makeStyles } from 'tss-react/mui';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    uspHeaderText: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : theme.typography.xxs_bold)), { padding: '0 !important', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '242px', [theme.breakpoints.between(320, 374)]: {
            maxWidth: '220px',
        }, [theme.breakpoints.up('sm')]: {
            maxWidth: '328px',
        }, [theme.breakpoints.up('md')]: {
            maxWidth: '420px',
        } }),
    linkWrapper: {
        textDecoration: 'underline',
    },
    uspLink: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : theme.typography.xxs_bold)), { paddingTop: '6px' }),
    container: {
        display: 'flex',
        padding: `10px ${spacing.xs}`,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
        [theme.breakpoints.up('md')]: {
            padding: `10px ${spacing.xs}`,
        },
        [theme.breakpoints.down('xl')]: Object.assign({ overflowX: 'auto', whiteSpace: 'nowrap', marginRight: 0 }, hideScrollbars),
    },
    ASPIDISTRA: {
        color: colors.siteUspsBackgroundText.ASPIDISTRA,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.ASPIDISTRA,
        },
    },
    DEEP_ROSE: {
        color: colors.siteUspsBackgroundText.DEEP_ROSE,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.DEEP_ROSE,
        },
    },
    WILD_LAVENDER: {
        color: colors.siteUspsBackgroundText.WILD_LAVENDER,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.WILD_LAVENDER,
        },
    },
    MONARCH_BLUE: {
        color: colors.siteUspsBackgroundText.MONARCH_BLUE,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.MONARCH_BLUE,
        },
    },
    PINK: {
        color: colors.siteUspsBackgroundText.PINK,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.PINK,
        },
    },
    RED: {
        color: colors.siteUspsBackgroundText.RED,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.RED,
        },
    },
    PURPLE: {
        color: colors.siteUspsBackgroundText.PURPLE,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.PURPLE,
        },
    },
    LILAC: {
        color: colors.siteUspsBackgroundText.LILAC,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.LILAC,
        },
    },
    PEACH: {
        color: colors.siteUspsBackgroundText.PEACH,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.PEACH,
        },
    },
    YELLOW: {
        color: colors.siteUspsBackgroundText.YELLOW,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.YELLOW,
        },
    },
    GREEN: {
        color: colors.siteUspsBackgroundText.GREEN,
        [`& .${classes.icon}`]: {
            color: colors.siteUspsBackgroundText.GREEN,
        },
    },
    GOLD: {
        color: colors.primary.gold5,
        [`& .${classes.icon}`]: {
            color: colors.primary.gold5,
        },
    },
    BLACK: {
        color: colors.grey.grey6,
        [`& .${classes.icon}`]: {
            color: colors.supplementary.black,
        },
    },
    FF_WILD_EXOTIC: {
        color: `${colors.wildExotic[500]} !important`,
        [`& .${classes.icon}`]: {
            color: colors.wildExotic[500],
        },
    },
    FF_COBALT: {
        color: `${colors.cobalt[500]} !important`,
        [`& .${classes.icon}`]: {
            color: colors.cobalt[500],
        },
    },
    FF_JADE: {
        color: `${colors.jade[500]} !important`,
        [`& .${classes.icon}`]: {
            color: colors.jade[500],
        },
    },
    FF_BLACK: {
        color: colors.black[700],
    },
    icon: {
        marginRight: '7px',
        fontSize: '18px',
        height: '18px',
        width: '18px',
        justifyContent: 'center',
        '& .MuiIcon-root': {
            width: 'unset',
            height: 'unset',
            fontSize: '18px',
        },
    },
}));
export default useStyles;
