export var ROUNDELS;
(function (ROUNDELS) {
    ROUNDELS["ROUNDEL_1"] = "roundel1";
    // We have unused these roundels
    // ROUNDEL_2 = 'roundel2',
    // ROUNDEL_3 = 'roundel3',
    // ROUNDEL_4 = 'roundel4',
    // ROUNDEL_5 = 'roundel5',
})(ROUNDELS || (ROUNDELS = {}));
export var DigitalProductTypes;
(function (DigitalProductTypes) {
    DigitalProductTypes["GOLD"] = "GOLD";
    DigitalProductTypes["PLATINUM"] = "PLATINUM";
})(DigitalProductTypes || (DigitalProductTypes = {}));
export var DigitalProductNames;
(function (DigitalProductNames) {
    DigitalProductNames["INTERFLORA_GOLD"] = "Interflora Gold";
    DigitalProductNames["INTEFRFLORA_PLATINUM"] = "Interflora Platinum";
})(DigitalProductNames || (DigitalProductNames = {}));
export var CheckoutErrors;
(function (CheckoutErrors) {
    CheckoutErrors["PRODUCT_UNAVAILABLE"] = "PRODUCT_UNAVAILABLE";
    CheckoutErrors["MEMBER_UNAVAILABLE"] = "MEMBER_UNAVAILABLE";
    CheckoutErrors["DELIVERY_DATE_PASSED"] = "DELIVERY_DATE_PASSED";
})(CheckoutErrors || (CheckoutErrors = {}));
export var PaymentMethodTypes;
(function (PaymentMethodTypes) {
    PaymentMethodTypes["CARD"] = "card";
    PaymentMethodTypes["PAYPAL"] = "payPal";
    PaymentMethodTypes["KLARNA"] = "klarna";
    PaymentMethodTypes["KLARNA_PAY_SLICED"] = "klarnaPaySliced";
    PaymentMethodTypes["KLARNA_PAY_LATER"] = "klarnaPayLater";
    PaymentMethodTypes["KLARNA_PAY_NOW"] = "klarnaPayNow";
})(PaymentMethodTypes || (PaymentMethodTypes = {}));
export var SearchOptionsList;
(function (SearchOptionsList) {
    SearchOptionsList["CATEGORIES"] = "Categories";
    SearchOptionsList["INSPIRATIONS"] = "Inspiration";
    SearchOptionsList["PRODUCTS"] = "Products";
    SearchOptionsList["GIFTS"] = "Gifts";
})(SearchOptionsList || (SearchOptionsList = {}));
export const KLARNA_PAY_SLICED = 'klarnaPaySliced';
export const KLARNA_PAY_LATER = 'klarnaPayLater';
export const KLARNA_PAY_NOW = 'klarnaPayNow';
export const klarnaPaymentOptions = [
    PaymentMethodTypes.KLARNA_PAY_SLICED,
    PaymentMethodTypes.KLARNA_PAY_LATER,
    PaymentMethodTypes.KLARNA_PAY_NOW,
];
export const klarnaTypes = [PaymentMethodTypes.KLARNA, ...klarnaPaymentOptions];
export var AllDays;
(function (AllDays) {
    AllDays["SUNDAY"] = "Sunday";
    AllDays["MONDAY"] = "Monday";
    AllDays["TUESDAY"] = "Tuesday";
    AllDays["WEDNESDAY"] = "Wednesday";
    AllDays["THURSDAY"] = "Thursday";
    AllDays["FRIDAY"] = "Friday";
    AllDays["SATURDAY"] = "Saturday";
    AllDays["MONDAY_TO_FRIDAY"] = "Monday to Friday";
})(AllDays || (AllDays = {}));
export var OpenFlags;
(function (OpenFlags) {
    OpenFlags["CLOSED"] = "Closed";
})(OpenFlags || (OpenFlags = {}));
