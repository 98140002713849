import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { errorMessages, inputValidationRegex, removeSessionItems } from '../../utils/common';
import addDays from 'date-fns/addDays';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import Select from '../Select/Select';
import { FormikContext, useField } from 'formik';
import useStyles from '../ProductDetailPurchase/ProductDetailPurchase.styles';
import { addMonths, isValid } from 'date-fns';
import formatDate from 'date-fns/format';
import { useSessionStorage } from '../../utils/utilityCustomHooks';
import { Box } from '@mui/material';
const times = [
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
];
export const validationConfig = Yup.object().shape({
    deceasedName: Yup.string()
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(50, errorMessages(50).maxChars)
        .required('Please enter a name')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    funeralServiceDate: Yup.date().required(errorMessages().required),
    funeralServiceTime: Yup.string().required('Please enter a funeral start time'),
});
export const initialValues = {
    deceasedName: '',
    funeralServiceDate: '',
    funeralServiceTime: '',
    isFuneralDirector: false,
};
const PurchaseTribute = ({ step, currentStep, setCurrentStep, handleFuneralSaveManualAddress, handleCancelChanges, isFromTributeUpdate, }) => {
    const { classes } = useStyles();
    const formikContext = useContext(FormikContext);
    const { getFromSessionStorage } = useSessionStorage();
    const { deceasedName, funeralServiceDate, funeralServiceTime } = formikContext.values;
    const [, , helper] = useField('funeralServiceDate');
    const buttonTitile = isFromTributeUpdate && handleCancelChanges ? 'Next' : 'Next: Delivery details';
    const handleDateChange = (date) => {
        var _a;
        helper.setValue(date);
        const formattedDate = isFromTributeUpdate ? formatDate(new Date(), 'yyyy-MM-dd') : formatDate(date, 'yyyy-MM-dd');
        const endDate = isFromTributeUpdate ? formatDate(addMonths(date, 2), 'yyyy-MM-dd') : formattedDate;
        if ((_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.city) {
            handleFuneralSaveManualAddress({
                startDate: formattedDate,
                endDate,
                formikContext,
                tributeDate: formatDate(date, 'yyyy-MM-dd'),
            });
        }
    };
    useEffect(() => {
        if (currentStep === step) {
            const buyAgainSessionStorageData = getFromSessionStorage('buyAgainOrderItem');
            if (buyAgainSessionStorageData) {
                removeSessionItems('buyAgainOrderItem');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, step]);
    const CompletedStep = currentStep > step ? (_jsx("div", { className: clsx(classes.completed, isFromTributeUpdate ? classes.tributeUpdateCompleted : ''), children: _jsxs(TextField, { name: "placeholderFuneralDetails", fullWidth: true, completed: true, onClick: () => setCurrentStep(step), children: [_jsx("div", { children: deceasedName }), isValid(new Date(funeralServiceDate))
                    ? formatDate(new Date(funeralServiceDate), 'EEEE do MMMM yyy')
                    : 'invalid date', _jsx("div", { children: funeralServiceTime })] }) })) : null;
    return (_jsxs("div", { children: [_jsxs(Box, { className: clsx(classes.heading, currentStep < step && classes.pending), children: [step, ". Who is this tribute for?"] }), currentStep === step ? (_jsxs(_Fragment, { children: [_jsx("p", { className: clsx(classes.caption, classes.tributeEditCaption), children: "Tribute details" }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "deceasedName", label: "Deceased's name", placeholder: "Enter name here", inputProps: { maxLength: 50 }, required: true, fullWidth: true, trimOnBlur: true, autoFocus: currentStep === step }) }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "funeralServiceDate", label: "Funeral date", datePickerTitle: 'Choose a funeral date', datePicker: true, placeholder: "Press to select", setValue: (date) => handleDateChange(date), minDate: addDays(new Date(), 1), fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(Select, { name: "funeralServiceTime", label: "Funeral start time", required: true, placeholder: "Press to select", fullWidth: true, items: times.map((time) => ({ label: time, value: time })) }) }), _jsxs("div", { className: classes.next, children: [_jsx(Button, { variant: "primary", thin: true, fullWidth: true, title: buttonTitile, isSubmit: true, className: classes.tributeActions }), handleCancelChanges ? (_jsx(Button, { variant: "tertiary", thin: true, fullWidth: true, title: "Cancel - discard changes", onClick: () => handleCancelChanges() })) : null] })] })) : (CompletedStep)] }));
};
export default PurchaseTribute;
