import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    body: {
        textAlign: 'center',
        color: theme.isFlyingFlowers ? colors.black[700] : undefined,
        [theme.breakpoints.down('xl')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg),
        '& p:last-child': {
            marginBottom: 0,
        },
    },
    roundButtonLabel: {
        marginTop: `10px`,
        padding: '0 !important',
        textAlign: 'center',
    },
    title: {
        textAlign: 'center',
        color: theme.isFlyingFlowers ? colors.black[700] : undefined,
        [theme.breakpoints.down('xl')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm),
        [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm),
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg),
        [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm),
    },
    roundButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: `0 ${spacing.medium}`,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
    },
}));
export default useStyles;
