import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import clsx from 'clsx';
import useStyles from '../SearchOverlay.styles';
import { SearchOptionsList } from '../../../common/props';
import LinkBlock from '../../LinkBlock/LinkBlock';
import Link from '../../Link/Link';
import { SiteContext } from '../../../utils/common';
const SearchLinkBlocks = (props) => {
    const { links, inputValue, linkTitle, isProductsExists, totalCount, handleViewAllButtonClick } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const showViewAllLink = linkTitle !== SearchOptionsList.CATEGORIES && totalCount > 6;
    return (_jsx(_Fragment, { children: _jsx("div", { className: classes.linkBlockHeadingContainer, "data-type": "Inspirations", children: _jsxs("div", { className: clsx(classes.categories, classes.querySuggestionContainer), children: [_jsxs("div", { className: classes.viewAllContainer, children: [_jsxs("h4", { className: clsx(classes.categoryHeading, classes.suggestSeachLinkHeading, showViewAllLink ? classes.linkCategoryHeading : ''), children: [linkTitle, " (", totalCount, ")"] }), showViewAllLink ? (_jsx(Link, { label: isFlyingFlowers ? 'See all' : 'View all', className: clsx(classes.categoryHeading, classes.linkHeading), onClick: (e) => handleViewAllButtonClick === null || handleViewAllButtonClick === void 0 ? void 0 : handleViewAllButtonClick(SearchOptionsList.INSPIRATIONS), url: `/search?query=${encodeURIComponent(inputValue)}` })) : null] }), _jsx("ul", { className: clsx(classes.linkBlockContainer, classes.searchLinkContainer, isProductsExists ? classes.linkBlockContainerWithProducts : ''), children: links.map((category) => (_jsx("li", { "data-testid": category.name, children: _jsx(LinkBlock, { label: category.name, url: category.url, highlightTextMatch: inputValue }) }, category.url))) })] }) }) }));
};
export default SearchLinkBlocks;
