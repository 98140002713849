import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import ProductDetailContent from '../ProductDetailContent/ProductDetailContent';
import ProductDetailImages from '../ProductDetailImages/ProductDetailImages';
import ProductDetailPurchase from '../ProductDetailPurchase/ProductDetailPurchase';
import ProductDetailSummary from '../ProductDetailSummary/ProductDetailSummary';
import useStyles from './ProductDetail.styles';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import { SiteContext } from '../../utils/common';
import Link from '../Link/Link';
import Alert from '../Alert/Alert';
import clsx from 'clsx';
import PCCMOdal from '../PCCModal/PCCModal';
import Modal from '../Modal/Modal';
import { useMediaQuery } from 'react-responsive';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { useRouter } from 'next/router';
import qs from 'qs';
import AddIDPToBasket from '../AddIDPToBasket/AddIDPToBasket';
import { useTheme } from '@mui/material';
export const submitFormInitialValue = {
    date: null,
    address: '',
    occasion: '',
    city: '',
    county: '',
    postalCode: '',
    country: '',
    isManualAddress: false,
    shouldValidatePostCode: true,
    addressLine2: '',
    addressLine1: '',
};
const ProductDetail = (props) => {
    var _a, _b, _c;
    const { classes } = useStyles();
    const { product, funeralUspGallery, digitalProducts, hasActiveDeliveryPass, sendStepAnalytics, sendEditVariantAnalytics, sendEditRecipientAnalytics, addNewAddress, updateContactAddress, newAddressId, setOpenControlled, isOpenControlled, countriesList, deliveryOptionsInfo, deliveryOptionsOrAlternateProductsCallback, updateMissingContactAddress, sendDateAnalytics, productVariantStyleInformation, addDeliveryPassToCart, activeDeliveryPassType, sendOpenPriceAnalytics, saveUserJourneyAnalytics, saveKlarnaLearnMoreAnalytics, timedContentInformation, pdpThumbnailImageClick, sendPersonalMessageLocationToAnalytics, locationInternationalDelivery, ccProductAvailabilityCallBack, sendDeliveryOptionAnalytics, sendClickAndCollectStatusAnalytics, isAddNewButtonClicked, setIsAddNewButtonClicked, trackEnterManualAddressClick, } = props;
    const [variant, setVariant] = useState((_a = product === null || product === void 0 ? void 0 : product.variants) === null || _a === void 0 ? void 0 : _a.find((v) => v.isMaster));
    const { shorthand } = useContext(SiteContext);
    const [isOpenUncontrolled, setOpenUncontrolled] = useState(false);
    const setOpen = setOpenControlled !== null && setOpenControlled !== void 0 ? setOpenControlled : setOpenUncontrolled;
    const isOpen = setOpenControlled ? !!isOpenControlled : isOpenUncontrolled;
    const theme = useTheme();
    const router = useRouter();
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const [showStep1Title, setShowStep1Title] = useState(false);
    const [info, setInfo] = useState(submitFormInitialValue);
    const productOutOfStockMessage = (_jsxs("div", { children: [_jsx("p", { className: classes.errorMessageText, children: "Unfortunately this product has sold out." }), _jsx(Link, { className: classes.errorMessageItemLink, onClick: () => setOpen(true), label: "Click here to find available products" })] }));
    const modalTitle = showStep1Title ? (_jsxs("span", { className: classes.modalHeading, children: [_jsx("span", { children: "1." }), _jsx("span", { children: "When would you like us to deliver your gift?" })] })) : undefined;
    const submitForm = (values) => {
        sessionStorage.setItem('guidedNavigationValues', JSON.stringify(values));
        const { date, postalCode, city, county, occasion } = values;
        setOpen(false);
        // Reset form data when submitted
        setInfo(submitFormInitialValue);
        const qsObj = {
            date: date ? format(date, 'yyyy-MM-dd') : undefined,
            postalCode,
            city,
            county,
            occasion,
            isSameDay: !!date && isSameDay(date, new Date()),
        };
        router.push(`/search?${qs.stringify(qsObj, { encodeValuesOnly: true })}`);
    };
    /** rendering product right side section and product detail content based on condtion */
    const renderProductDetailPurchase = () => {
        var _a, _b;
        const contentCommonProps = {
            className: classes.content,
            isFuneral: product.isFuneral,
            isInternational: product.isInternational,
            description: product.description,
            attributes: [...((_a = product.attributes) !== null && _a !== void 0 ? _a : []), ...((_b = variant === null || variant === void 0 ? void 0 : variant.attributes) !== null && _b !== void 0 ? _b : [])],
            funeralUspGallery: funeralUspGallery,
        };
        if (product.isProductOutOfStock) {
            return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: isOpen, className: classes.modal, maxWidth: "lg", fullScreen: !isLargerViewport, setOpen: setOpen, noPadding: !isLargerViewport, title: modalTitle, children: _jsx(PCCMOdal, { info: info, setInfo: setInfo, submitForm: submitForm, onCancel: setOpen, isOpen: isOpen, setShowStep1Title: setShowStep1Title }) }), _jsx(Alert, { className: clsx(classes.error, classes.errorContainer), type: "alertError", message: productOutOfStockMessage })] }));
        }
        else if (product.typeKey === 'digital' && addDeliveryPassToCart) {
            return (_jsxs(_Fragment, { children: [_jsx(AddIDPToBasket, { className: classes.purchase, addDeliveryPassToCart: addDeliveryPassToCart, activeDeliveryPassType: activeDeliveryPassType, sku: product.sku }), _jsx(ProductDetailContent, Object.assign({}, contentCommonProps))] }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx(ProductDetailPurchase, Object.assign({ className: classes.purchase, variant: variant, setVariant: setVariant }, props, { digitalProducts: digitalProducts, hasActiveDeliveryPass: hasActiveDeliveryPass, sendStepAnalytics: sendStepAnalytics, sendEditVariantAnalytics: sendEditVariantAnalytics, sendEditRecipientAnalytics: sendEditRecipientAnalytics, addNewAddress: addNewAddress, updateContactAddress: updateContactAddress, newAddressId: newAddressId, countriesList: countriesList, deliveryOptionsInfo: deliveryOptionsInfo, deliveryOptionsOrAlternateProductsCallback: deliveryOptionsOrAlternateProductsCallback, updateMissingContactAddress: updateMissingContactAddress, sendDateAnalytics: sendDateAnalytics, productVariantStyleInformation: productVariantStyleInformation, sendOpenPriceAnalytics: sendOpenPriceAnalytics, saveUserJourneyAnalytics: saveUserJourneyAnalytics, sendPersonalMessageLocationToAnalytics: sendPersonalMessageLocationToAnalytics, locationInternationalDelivery: locationInternationalDelivery, ccProductAvailabilityCallBack: ccProductAvailabilityCallBack, sendDeliveryOptionAnalytics: sendDeliveryOptionAnalytics, sendClickAndCollectStatusAnalytics: sendClickAndCollectStatusAnalytics, isAddNewButtonClicked: isAddNewButtonClicked, setIsAddNewButtonClicked: setIsAddNewButtonClicked, trackEnterManualAddressClick: trackEnterManualAddressClick })), _jsx(ProductDetailContent, Object.assign({}, contentCommonProps, { productSku: variant === null || variant === void 0 ? void 0 : variant.sku }))] }));
        }
    };
    const brand = useMemo(() => {
        if (shorthand) {
            const lowercaseShorthand = shorthand.toLowerCase();
            if (lowercaseShorthand === 'uk' || lowercaseShorthand === 'ie') {
                return 'Interflora';
            }
            else if (lowercaseShorthand === 'ff') {
                return 'Flying Flowers';
            }
        }
    }, [shorthand]);
    return (_jsxs(_Fragment, { children: [variant ? (_jsxs("div", { className: classes.container, children: [_jsx(ProductDetailSummary, { className: classes.summary, variant: variant, product: product, saveKlarnaLearnMoreAnalytics: saveKlarnaLearnMoreAnalytics, timedContentInformation: timedContentInformation }), _jsx(ProductDetailImages, { className: classes.images, productName: product.name, images: variant.images, pdpThumbnailImageClick: pdpThumbnailImageClick }), renderProductDetailPurchase()] })) : null, _jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps({
                    '@context': 'https://schema.org',
                    '@type': 'Product',
                    name: product === null || product === void 0 ? void 0 : product.name,
                    description: product === null || product === void 0 ? void 0 : product.description,
                    image: (_b = product === null || product === void 0 ? void 0 : product.images) === null || _b === void 0 ? void 0 : _b.map((image) => image.url),
                    brand,
                    offers: (_c = product === null || product === void 0 ? void 0 : product.variants) === null || _c === void 0 ? void 0 : _c.map((variant) => {
                        var _a, _b, _c, _d;
                        return ({
                            '@type': 'Offer',
                            price: (_c = (_b = (_a = variant === null || variant === void 0 ? void 0 : variant.price) === null || _a === void 0 ? void 0 : _a.formatted) === null || _b === void 0 ? void 0 : _b.slice(1)) !== null && _c !== void 0 ? _c : 0,
                            priceCurrency: (_d = product === null || product === void 0 ? void 0 : product.price) === null || _d === void 0 ? void 0 : _d.currencyCode,
                            sku: variant === null || variant === void 0 ? void 0 : variant.sku,
                        });
                    }),
                }))) })] }));
};
export default ProductDetail;
