import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, Box, useTheme } from '@mui/material';
import { useContext } from 'react';
import useStyles from './ContentGallery.styles';
import ContentItem from '../ContentItem/ContentItem';
import Link from '../Link/Link';
import clsx from 'clsx';
import Carousel from '../Carousel/Carousel';
import { SiteContext } from '../../utils/common';
import { useLoaded } from '../MainContent/useComponentLoading';
/**
 *
 *
 * <h2>Designs</h2>
 * <p>Interflora: https://www.figma.com/file/TLHrK1x4xxUOwVgiQ7McFD/DS_version-2.13.3?node-id=3065%3A67976</p>
 * <p>Flying Flowers: https://www.figma.com/file/UI4BmbaVYDdLOBd0ZDO7Po/FF-DS_version-1.0.0?node-id=3065%3A67976</p>
 */
const ContentGallery = (props) => {
    const { title, description, items, bottomDescription, link, linkStyle, headingTag } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { md } = theme.breakpoints.values;
    const responsive = {
        mobile: {
            breakpoint: { max: md - 1, min: 0 },
            items: 1,
            partialVisibilityGutter: 24,
        },
    };
    const isButton = (linkStyle === null || linkStyle === void 0 ? void 0 : linkStyle.toUpperCase()) === 'BUTTON';
    const HeadingTag = (!!headingTag ? headingTag.toLowerCase() : 'h3');
    useLoaded(props);
    return (_jsxs("div", { className: classes.gallery, children: [_jsxs("div", { className: classes.headingSection, children: [title && _jsx(HeadingTag, { className: classes.title, children: title }), description && _jsx("p", { className: classes.description, children: description })] }), !!(items === null || items === void 0 ? void 0 : items.length) && (_jsxs(_Fragment, { children: [_jsx(Box, { display: { zero: 'none', md: 'block' }, children: _jsx(Grid, { className: classes.grid, container: true, spacing: 3, direction: "row", children: items.map((item, index) => (_jsx(Grid, { className: classes.gridItem, item: true, sm: items.length === 6 ? 2 : 3, children: _jsx(ContentItem, Object.assign({}, item)) }, `${index}`))) }) }), _jsx(Box, { display: { md: 'none' }, children: _jsx("div", { className: clsx(classes.carousel), children: _jsx(Carousel, { responsive: responsive, arrows: false, autoPlay: false, ssr: true, showDots: true, renderDotsOutside: true, partialVisible: true, infinite: true, children: items.map((item, index) => (_createElement(ContentItem, Object.assign({}, item, { key: index })))) }) }) })] })), _jsxs("div", { className: classes.footerSection, children: [bottomDescription && _jsx("p", { className: classes.description, children: bottomDescription }), link && (_jsx(Link, Object.assign({}, link, { button: isButton,
                        variant: isFlyingFlowers ? 'secondary' : 'primary',
                        thin: true,
                        className: clsx(isButton ? classes.linkButton : classes.linkText) })))] })] }));
};
export default ContentGallery;
