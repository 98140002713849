var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import NewsletterSignUpConfirmation from './NewsletterSignUpConfirmation/NewsletterSignUpConfirmation';
import NewsletterSignUpForm from './NewsletterSignUpForm/NewsletterSignUpForm';
const NewsletterSignUp = (props) => {
    const { newsletterSignUp, setOpen, signUp } = props;
    const [confirm, setConfirm] = useState(false);
    const [firstName, setFirstName] = useState('');
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield signUp(values);
            setConfirm(true);
            setFirstName(values.firstName);
        }
        catch (error) {
            setConfirm(false);
            setFirstName('');
            throw new Error(error === null || error === void 0 ? void 0 : error.message);
        }
    });
    return confirm ? (_jsx(NewsletterSignUpConfirmation, { bodyOnSignUpConfirmation: newsletterSignUp.bodyOnSignUpConfirmation, linkOnSignUpConfirmation: newsletterSignUp.linkOnSignUpConfirmation, imageOnSignUpConfirmation: newsletterSignUp.imageOnSignUpConfirmation, firstName: firstName, setOpen: setOpen })) : (_jsx(NewsletterSignUpForm, { signUpLink: newsletterSignUp.signUpLink, privacyPolicyText: newsletterSignUp.privacyPolicyText, bodyOnSignUpForm: newsletterSignUp.bodyOnSignUpForm, imageOnSignUpForm: newsletterSignUp.imageOnSignUpForm, onSubmit: onSubmit }));
};
export default NewsletterSignUp;
