import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import DatePicker from '../../DatePicker/DatePicker';
import { FormikContext, useField } from 'formik';
import * as Yup from 'yup';
import { convertDateFormat, errorMessages, filterSelectedDates, SiteContext } from '../../../utils/common';
import useStyles from './PCCDatePicker.styles';
import { addDays, format, isSameDay, isToday, isTomorrow } from 'date-fns';
import SelectionButton from '../../SelectionButton/SelectionButton';
export const validationDatePickerSchema = Yup.object({
    date: Yup.date().required(errorMessages().required),
});
const PCCDatePicker = (props) => {
    const { currentStep, step, onCancelModal, isSubmiting, setCurrentStep, isShowDayPicker, setIsShowDayPicker, trackPostCodeCheckerDate, } = props;
    const { classes } = useStyles();
    const [input, , helper] = useField('date');
    const [isCalendarPicked, setIsCalendarPicked] = useState(!!(input === null || input === void 0 ? void 0 : input.value) ? true : false);
    const { deliverySettings, isFlyingFlowers = false, calendarDates } = useContext(SiteContext);
    const formikContext = useContext(FormikContext);
    const [dates, setDates] = useState();
    const selectedDate = input.value;
    const { availableSundays, bankHolidays } = calendarDates || {};
    const filterDate = useCallback((date) => {
        return filterSelectedDates(date, isFlyingFlowers, bankHolidays, availableSundays, deliverySettings);
    }, [availableSundays, bankHolidays, deliverySettings, isFlyingFlowers]);
    const fitleringDate = useCallback((date) => {
        let dateVal = date;
        if (filterDate(date)) {
            const nextDate = addDays(dateVal, 1);
            dateVal = fitleringDate(nextDate);
        }
        return dateVal;
    }, [filterDate]);
    useEffect(() => {
        let currentDate = new Date();
        const dates = new Array(3).fill('').map((_, index) => {
            const dateVal = fitleringDate(currentDate);
            currentDate = addDays(dateVal, 1);
            return dateVal;
        });
        setDates(dates);
    }, [fitleringDate]);
    const updateCurrentStep = () => {
        if (!!(formikContext === null || formikContext === void 0 ? void 0 : formikContext.values)) {
            const { addressLine1, occasion } = formikContext.values;
            if (addressLine1 && !occasion)
                setCurrentStep(3);
            else if (!addressLine1)
                setCurrentStep(2);
        }
        trackPostCodeCheckerDate === null || trackPostCodeCheckerDate === void 0 ? void 0 : trackPostCodeCheckerDate();
    };
    const handleSetValue = (date) => {
        helper.setValue(date);
        setIsCalendarPicked(true);
        setIsShowDayPicker(true);
        updateCurrentStep();
    };
    return (_jsx(_Fragment, { children: step === currentStep ? (_jsx("div", { className: classes.pccDatePickerContainer, children: isShowDayPicker ? (_jsxs("div", { className: classes.dayPicker, children: [dates === null || dates === void 0 ? void 0 : dates.map((date, index) => {
                        const formatedDate = convertDateFormat(format(date, 'yyyy-MM-dd'));
                        const filterdDate = fitleringDate(formatedDate);
                        const formattedDay = format(filterdDate, 'EEEE');
                        const formattedDate = format(filterdDate, 'do MMM');
                        return (_jsx(SelectionButton, { title: isToday(filterdDate) ? 'Today' : isTomorrow(filterdDate) ? 'Tomorrow' : formattedDay, onClick: () => {
                                setIsCalendarPicked(false);
                                helper.setValue(date);
                                updateCurrentStep();
                            }, supplementaryDate: formattedDate, selected: selectedDate && !isCalendarPicked && isSameDay(date, new Date(selectedDate)) }, `pcc_date_picker_${index}`));
                    }), _jsx(SelectionButton, Object.assign({ title: "More" }, (selectedDate &&
                        isCalendarPicked && {
                        supplementaryDate: format(new Date(selectedDate), 'do MMM'),
                    }), { icon: "calendar_today", selected: selectedDate && isCalendarPicked, onClick: () => {
                            setIsCalendarPicked(true);
                            setIsShowDayPicker(false);
                        }, isFromPCC: true }))] })) : (_jsx(DatePicker, { value: input.value, minDate: !!dates ? dates[0] : new Date(), isOpen: false, noModal: true, setOpen: () => onCancelModal(false), setValue: handleSetValue, hideInput: true, confirmDisabled: isSubmiting, confirmIsSubmit: true, filterDate: filterDate, isActionsCenter: isFlyingFlowers ? true : false, isFromPCC: true })) })) : null }));
};
export default PCCDatePicker;
