import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    clearFilter: Object.assign(Object.assign({ cursor: 'pointer', color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, textDecoration: 'underline' }, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), { whiteSpace: 'nowrap' }), '&:disabled': {
            cursor: 'auto',
            color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey5,
            textDecoration: 'none',
        } }),
}));
export default useStyles;
