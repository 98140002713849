import { makeStyles } from 'tss-react/mui';
import { addWidthInner, maxHeightBelowHeader, widthOuter } from '../../styles/jss/utils';
import { colors, hideScrollbars, spacing } from './../../styles/jss/variables';
import { searchLinkBlockStyle } from './SearchResults/SearchLinkBlocks.styles';
const useStyles = makeStyles()((theme, params, classes) => (Object.assign(Object.assign({ input: { marginBottom: spacing.small }, searchOverlay: maxHeightBelowHeader(theme, {
        backgroundColor: 'rgba(255,255,255,0.96)',
        padding: `${spacing.medium} 0 48px 0`,
        '& h2': Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg),
        maxHeight: 'calc(100vh - 125px) !important',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 'calc(100vh - 120px) !important',
        },
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, widthOuter), { height: 'calc(100vh - 120px) !important', '& h2': Object.assign(Object.assign({ color: colors.supplementary.black }, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
    }), suggested: {
        backgroundColor: 'transparent',
    }, searchOverlayInner: addWidthInner(theme, {
        opacity: 1,
        backgroundColor: 'transparent',
    }), search: {
        order: 1,
        [theme.breakpoints.up('md')]: {
            borderBottom: `1px solid ${colors.grey.grey5}`,
        },
    }, searchFieldWrapper: {
        backgroundColor: colors.supplementary.white,
        [theme.breakpoints.down('md')]: {
            borderBottom: `1px solid ${colors.grey.grey5}`,
            marginBottom: '27px',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '34px',
        },
    }, noresultsfoundcontainer: {
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        },
    }, categories: {
        [theme.breakpoints.up('md')]: {
            display: theme.isFlyingFlowers ? 'flex' : undefined,
        },
    }, querySuggestionContainer: {}, categoryHeading: Object.assign(Object.assign({}, theme.typography.xxs_bold), { paddingRight: spacing.medium }), searchOverlayWrapper: Object.assign(Object.assign({}, hideScrollbars), { overflowX: 'hidden', overflowY: 'scroll' }), previousSearchTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg) }), recentSearchOverlay: {
        height: 'calc(100vh - 140px)',
        paddingBottom: '100px',
    }, searchLinkContainer: {
        '& a': {
            borderRadius: theme.isFlyingFlowers ? spacing.xs : 'unset',
            color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
            minWidth: 'initial',
        },
        '& button': {
            color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
            minWidth: 'initial',
        },
        '& span': {
            color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
        },
    }, suggesSearchContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    }, suggestLinkContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '50%',
        '& li': {
            margin: `0 ${spacing.xs} ${spacing.xs} 0`,
            [theme.breakpoints.up('md')]: {
                margin: '0 12px 12px 0',
            },
        },
    }, suggestSearchOverlay: {
        paddingBottom: '100px',
    }, searchOverlayProductGrid: {
        paddingBottom: '100px',
    }, searchItem: {
        cursor: 'pointer',
    }, productsContainer: {
        width: '100%',
        flexWrap: 'wrap',
        marginTop: spacing.xs,
        [theme.breakpoints.up('md')]: {
            width: theme.isFlyingFlowers ? '48.76%' : '51.6%',
            flexWrap: 'initial',
            marginTop: 'initial',
        },
    }, suggestedSearchWithProductsContianer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginBottom: spacing.large,
            '& > :first-child': {
                width: theme.isFlyingFlowers ? '51.24%' : '48.4%',
                marginRight: theme.isFlyingFlowers ? spacing.xl : (params === null || params === void 0 ? void 0 : params.isDynamicProducts) ? spacing.large : spacing.xs,
            },
        },
    }, suggestedSearchContainer: {
        display: 'flex',
        flexDirection: 'column',
    }, linkBlockContainerWithProducts: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.medium,
        },
    }, emptyLeftDiv: {
        minWidth: 'initial',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginBottom: spacing.large,
            '& > :first-child': {
                minWidth: theme.isFlyingFlowers ? '51.24%' : '48.4%',
            },
        },
    } }, searchLinkBlockStyle(theme, classes.categories)), { suggestSearchContainer: {} })));
export default useStyles;
