import { makeStyles } from 'tss-react/mui';
import { addWidthInner, link, widthOuter } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    footer: Object.assign(Object.assign({}, widthOuter), { background: `linear-gradient(${colors.supplementary.white} ${theme.isFlyingFlowers ? 51 : 54}px, ${colors.grey.grey1} ${theme.isFlyingFlowers ? 51 : 54}px, ${colors.grey.grey1} 100%)`, [theme.breakpoints.up('md')]: {
            background: `linear-gradient(${colors.supplementary.white} ${theme.isFlyingFlowers ? 59 : 74}px, ${colors.grey.grey1} ${theme.isFlyingFlowers ? 59 : 74}px, ${colors.grey.grey1} 100%)`,
        } }),
    footerInner: addWidthInner(theme, {}),
    logo: {
        margin: `0 auto ${spacing.medium} auto`,
        width: '100%',
        maxWidth: theme.isFlyingFlowers ? '334px' : '108px',
        [theme.breakpoints.up('md')]: {
            margin: `0 0 ${spacing.xl} 0`,
            gridRow: 1,
            width: theme.isFlyingFlowers ? '386px' : '148px',
            maxWidth: theme.isFlyingFlowers ? '386px' : '148px',
        },
    },
    title: Object.assign(Object.assign({}, theme.typography.small_bold), { display: 'flex', alignItems: 'center', marginBottom: '12px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginTop: '-70px', justifyContent: 'flex-end' }) }),
    icon: { display: 'inline-block', marginRight: spacing.xs, lineHeight: '0rem' },
    links: {
        marginBottom: '40px',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        },
    },
    link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? undefined : colors.primary.gold5, display: 'block', marginBottom: '12px', [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            '&:not(:last-child):after': {
                content: "'|'",
                margin: '0 16px',
            },
        } }),
    outerSection: Object.assign(Object.assign({}, widthOuter), { width: '100%', minHeight: '110px', paddingTop: '33px', paddingBottom: '47px', [theme.breakpoints.up('md')]: {
            borderTop: `1px solid ${colors.grey.grey2}`,
        } }),
    innerSection: addWidthInner(theme, {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }),
    legal: Object.assign(Object.assign({ textAlign: 'center' }, theme.typography.xxs_regular), { [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        } }),
}));
export default useStyles;
