import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormikContext } from 'formik';
import useStyles from './RegisterForm.styles';
import { useState, useEffect, useContext } from 'react';
import CheckCircle from '../CheckCircle/CheckCircle';
const marketingPreferencesOptions = ['optInEmail', 'optInPost', 'optInSms', 'optInOnline'];
const MarketingPreferencesToggle = ({ isFlyingFlowers, setShowOptions, showOptions }) => {
    var _a;
    const { classes } = useStyles();
    const formikContext = useContext(FormikContext);
    const [isPreferenceChecked, setIsPreferenceChecked] = useState(true);
    const { optInEmail, optInPost, optInSms, optInOnline, marketing } = (_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) !== null && _a !== void 0 ? _a : {};
    const onLinkClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowOptions(!showOptions);
    };
    const handleMarketingPreference = (isPreferenceChecked) => {
        setIsPreferenceChecked(isPreferenceChecked);
        marketingPreferencesOptions.forEach((preference) => {
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(preference, isPreferenceChecked);
        });
        if (!isPreferenceChecked && showOptions) {
            setShowOptions(false);
        }
    };
    useEffect(() => {
        if (optInEmail || optInPost || optInSms || (optInOnline && !marketing)) {
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('marketing', true);
            setIsPreferenceChecked(true);
        }
        else if (!optInEmail && !optInPost && !optInSms && !optInOnline && marketing) {
            setIsPreferenceChecked(false);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('marketing', false);
            setShowOptions(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optInEmail, optInPost, optInSms, optInOnline, marketing]);
    return (_jsx("div", { className: classes.marketing, children: _jsx(CheckCircle, { name: "marketing", type: "checkbox", label: _jsxs("p", { className: classes.termsText, children: ["Yes, I would like to receive offers, updates and marketing from", ' ', isFlyingFlowers ? 'Flying Flowers. ' : 'Interflora. ', "If you wish to opt out, simply untick the pre-ticked box. Alternatively, you can", ' ', isFlyingFlowers ? (_jsx("span", { className: classes.optionsLink, children: _jsx("button", { className: classes.linkDisplay, onClick: onLinkClick, children: "tell us how you'd like to hear from us." }) })) : (_jsx("span", { className: classes.optionsLink, children: _jsx("button", { className: classes.linkDisplay, onClick: onLinkClick, children: "tell us how you would like to hear from us." }) }))] }), controlAlignment: "top", onChange: () => handleMarketingPreference(!isPreferenceChecked), checked: isPreferenceChecked, "data-testId": "PreferenceChecked" }) }));
};
export default MarketingPreferencesToggle;
