import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useBackgroundClass } from '../../styles/jss/utils';
import { processImageUrl } from '../../utils/image';
import ResponsiveImage from '../Image/Image';
import Link from '../Link/Link';
import useStyles from './PromoContentItem.styles';
import { SiteContext } from '../../utils/common';
import { useRouter } from 'next/router';
import { useLoaded } from '../MainContent/useComponentLoading';
import SplitContentBySlash from '../../utils/SplitContentBySlash';
/**
 * Promo Content Item - Design System v2.10.2 - Molecules / Cards
 *
 * * Promo Cards Vertical - m-card_promo_vertical
 */
const getContent = (isFlyingFlowers, text, className) => {
    return !isFlyingFlowers ? _jsx(SplitContentBySlash, { content: text, classNames: { headingLine: className } }) : text;
};
const getImageWidths = (halfWidth) => {
    if (halfWidth) {
        return [240, 384, 480, 960];
    }
    else {
        return [240, 384, 520, 960];
    }
};
const getVariant = (isFlyingFlowers, membershipProductSKU) => {
    if (isFlyingFlowers) {
        return 'secondary';
    }
    else if (membershipProductSKU) {
        return 'tertiary';
    }
    else {
        return 'primary';
    }
};
const PromoContentItem = (props) => {
    var _a;
    const { image, link, linkStyle, backgroundColor, halfWidth, membershipProductSKU, headingTag, itemIndex, backgroundAndHeaderTextColors, } = props; // To make sure we handle any nulls from graphQL
    const { addDeliveryPassToCart, sendPromoContentGalleryAnalytics } = props;
    const [analyticsItem, setAnalyticsItem] = useState();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { bgClass, rgbColor } = useBackgroundClass(backgroundColor);
    const router = useRouter();
    const sizes = [
        {
            breakpoint: 'sm',
            query: 'max-width',
            width: '100vw',
        },
        {
            breakpoint: 'md',
            query: 'min-width',
            width: halfWidth ? '50w' : '33vw',
        },
    ];
    const imageUrl = image && processImageUrl(image.url, { w: 960, aspect: '16:9', bg: rgbColor }, ['$poi-square$']);
    const isButton = (linkStyle === null || linkStyle === void 0 ? void 0 : linkStyle.toUpperCase()) === 'BUTTON';
    const HeadingTag = !!headingTag ? headingTag.toLowerCase() : 'h3';
    const [backgroundTextColor, headingColor] = ((_a = backgroundAndHeaderTextColors === null || backgroundAndHeaderTextColors === void 0 ? void 0 : backgroundAndHeaderTextColors.split('-')) !== null && _a !== void 0 ? _a : []).map((color) => color.trim());
    // Data for GA4 click event
    useEffect(() => {
        var _a;
        if ((_a = router === null || router === void 0 ? void 0 : router.asPath) === null || _a === void 0 ? void 0 : _a.endsWith('page/world-cup')) {
            const analyticsItem = {
                category: 'FWC23',
                action: 'Sub' + ' ' + (itemIndex || itemIndex === 0 ? itemIndex + 1 : ''),
                label: itemIndex == 2 ? 'Virtual' : 'Hub',
            };
            setAnalyticsItem(analyticsItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router === null || router === void 0 ? void 0 : router.asPath]);
    useLoaded(props);
    const onClickHandler = () => {
        if (membershipProductSKU && addDeliveryPassToCart) {
            addDeliveryPassToCart(membershipProductSKU);
        }
        else if (sendPromoContentGalleryAnalytics && link) {
            sendPromoContentGalleryAnalytics(link.label);
        }
    };
    const { classes } = useStyles({
        headingColor: headingColor,
        backgroundTextColor: backgroundTextColor,
    });
    const getTitleClasses = () => {
        if (isFlyingFlowers) {
            return classes.titleFF;
        }
        else {
            return classes.titleUK;
        }
    };
    const getBgColorFF = () => {
        if (isFlyingFlowers || !backgroundAndHeaderTextColors) {
            return bgClass;
        }
        else {
            return '';
        }
    };
    const imageWidths = getImageWidths(halfWidth);
    const titleClasses = getTitleClasses();
    const bgColorFF = getBgColorFF();
    const heading = getContent(isFlyingFlowers, props.heading, classes.headingLine);
    const message = getContent(isFlyingFlowers, props.message, classes.headingLine);
    return (_jsxs("div", { "data-testid": "promoContentId", className: clsx(classes.container, bgColorFF), children: [_jsx("div", { className: clsx(classes.imageContainer, !imageUrl && classes.noImage), children: !!image && !!imageUrl && (_jsx(ResponsiveImage, { altText: image.altText || heading || '', imageUrl: imageUrl, sizes: sizes, imageWidths: imageWidths, className: classes.image, loading: "lazy" })) }), _jsxs("div", { className: classes.textContent, children: [heading && _jsx(HeadingTag, { className: titleClasses, children: heading }), _jsx("p", { className: classes.message, children: message }), (link === null || link === void 0 ? void 0 : link.label) ? (_jsx("div", { className: classes.linkContainer, "data-item": analyticsItem ? JSON.stringify(analyticsItem) : undefined, children: _jsx(Link, Object.assign({}, link, { url: !membershipProductSKU ? link.url : undefined,
                            button: isButton,
                            variant: getVariant(isFlyingFlowers, membershipProductSKU),
                            thin: true,
                            className: clsx(classes.link, isButton ? classes.linkButton : classes.linkText),
                            onClick: membershipProductSKU || sendPromoContentGalleryAnalytics ? onClickHandler : undefined })) })) : null] })] }));
};
export default PromoContentItem;
