var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Form, Formik, FormikContext, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import CheckCircle from '../../CheckCircle/CheckCircle';
import AddressLookup from '../../AddressLookup/AddressLookup';
import useStyles from '../Checkout.styles';
import useStylesMissingContactInfo from '../../MissingContactInfoFields/MissingContactInfoFields.styles';
import { CircularProgress, useTheme } from '@mui/material';
import { addressFields, SiteContext, replaceCountryCodes, getCountryFromAlpha2Code, inputValidationRegex, errorMessages, } from '../../../utils/common';
import { billingAddressCountries } from '../../../utils/billingAddressCountries';
import Select from '../../Select/Select';
import Modal from '../../Modal/Modal';
import MissingContactInfoFields, { initialValues as missingContactInfoInitialValues, validationSchema as missingContactInfoValidationConfig, } from '../../MissingContactInfoFields/MissingContactInfoFields';
import { useMediaQuery } from 'react-responsive';
export const validationSchema = Yup.object({
    useDeliveryAddress: Yup.string(),
    isManualAddress: Yup.boolean(),
    billingAddress: Yup.object().when('useDeliveryAddress', {
        is: 'false',
        then: Yup.object().shape({
            addressLine1: Yup.string()
                .required('Please enter address line 1')
                .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            addressLine2: Yup.string().matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            addressLine3: Yup.string().matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            city: Yup.string()
                .required('Please enter the city')
                .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            county: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string()
                    .required('Please enter a county')
                    .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            }),
            postalCode: Yup.string()
                .when('shouldValidatePostCode', {
                is: true,
                then: Yup.string().required('Please enter a postcode'),
            })
                .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
            country: Yup.string().required('Please select a country'),
        }),
    }),
    billingAddressCountrySelector: Yup.string().when(['useDeliveryAddress', 'showCountrySelector'], {
        is: (useDeliveryAddress, showCountrySelector) => useDeliveryAddress === 'false' && showCountrySelector === true,
        then: Yup.string().required('Please select a country'),
    }),
    billingAddressSelection: Yup.string().when('useDeliveryAddress', {
        is: 'false',
        then: Yup.string()
            .required('Please enter a billing address')
            .oneOf([Yup.ref('originalAddressSelection'), null], "Please select an address from the list or choose 'enter manually'"),
    }),
});
const title = '2. Billing details';
const MissingContactFieldInfoModal = ({ hasMissingAddrField, setHasMissingAddrField, isMobile, classesMissingContactInfo, formikConfigMissingContactInfo, isSubmitting, }) => {
    const renderModal = () => (_jsx(Modal, { open: !!hasMissingAddrField, setOpen: () => (setHasMissingAddrField ? setHasMissingAddrField(false) : null), fullScreen: isMobile, fullWidth: true, maxWidth: "md", title: "We just need", className: classesMissingContactInfo === null || classesMissingContactInfo === void 0 ? void 0 : classesMissingContactInfo.missingContactInfoModal, keepMounted: false, children: _jsx(Formik, Object.assign({}, formikConfigMissingContactInfo, { children: ({ isSubmitting }) => (_jsx(Form, { children: _jsx(MissingContactInfoFields, { isMissingAddressLine1: true }) })) })) }));
    const renderEmpty = () => null;
    return hasMissingAddrField ? renderModal() : renderEmpty();
};
const CheckoutBillingDetails = ({ className, step, currentStep, isSubmitting, cutOffPassedAlert, trackEnterManualAddressClick, }) => {
    var _a, _b, _c;
    const context = useFormikContext();
    const formikContext = useContext(FormikContext);
    const theme = useTheme();
    const { getFormattedAddress, countryCode, isFlyingFlowers, shorthand, getCustomer } = useContext(SiteContext);
    const customer = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer;
    const customerDefaultBillingAddress = customer === null || customer === void 0 ? void 0 : customer.defaultBillingAddress;
    const contextDeliveryAddress = (_c = (_b = context.values.consignments.find((consignment) => { var _a; return !consignment.isDigital && ((_a = consignment.delivery) === null || _a === void 0 ? void 0 : _a.address); })) === null || _b === void 0 ? void 0 : _b.delivery) === null || _c === void 0 ? void 0 : _c.address;
    const [hasMissingAddrField, setHasMissingAddrField] = useState(false);
    const deliveryAddress = !!(customer === null || customer === void 0 ? void 0 : customer.id) &&
        !!customerDefaultBillingAddress &&
        !!(customerDefaultBillingAddress === null || customerDefaultBillingAddress === void 0 ? void 0 : customerDefaultBillingAddress.addressLine1) &&
        !!(customerDefaultBillingAddress === null || customerDefaultBillingAddress === void 0 ? void 0 : customerDefaultBillingAddress.country)
        ? customerDefaultBillingAddress
        : contextDeliveryAddress;
    const { addressLine1, addressLine2, addressLine3, city, county, postalCode, country } = replaceCountryCodes(deliveryAddress || {});
    const deliveryAddressItems = [addressLine1, addressLine2, addressLine3, city, county, postalCode, country].filter(Boolean);
    const { classes } = useStyles();
    const { classes: classesMissingContactInfo } = useStylesMissingContactInfo();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const [, , useDeliveryAddressHelper] = useField('useDeliveryAddress');
    const isCurrent = currentStep === step;
    const isCompleted = currentStep > step;
    const { setFieldValue, setFieldTouched } = context;
    const { billingAddressCountrySelector, useDeliveryAddress, showCountrySelector } = context.values;
    const selectedCountryCode = context.values.billingAddress.country;
    const countryBasedOnSite = getCountryFromAlpha2Code(countryCode);
    const countryBasedOnSelection = billingAddressCountries === null || billingAddressCountries === void 0 ? void 0 : billingAddressCountries.find((country) => {
        var _a;
        return country.codeAlpha2 ===
            (selectedCountryCode && ((_a = context.values.billingAddress) === null || _a === void 0 ? void 0 : _a.addressLine1)
                ? selectedCountryCode
                : billingAddressCountrySelector);
    });
    const countryList = billingAddressCountries
        ? billingAddressCountries === null || billingAddressCountries === void 0 ? void 0 : billingAddressCountries.map((country) => ({ label: country.name, value: country.codeAlpha2 }))
        : [];
    const handleAddressFormatCall = (suggestion, format) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        if (!getFormattedAddress) {
            throw new Error('Format address callback not found in context');
        }
        else {
            const { data, error } = yield getFormattedAddress({
                input: {
                    suggestion,
                    format,
                    countryCode: (countryBasedOnSelection === null || countryBasedOnSelection === void 0 ? void 0 : countryBasedOnSelection.codeAlpha2) || countryCode,
                },
            });
            if (error) {
                throw new Error(`Address formatting returned an error response: ${error}`);
            }
            else if (!data) {
                throw new Error('No results returned from address formatting');
            }
            if (!((_d = data.formatAddress) === null || _d === void 0 ? void 0 : _d.addressLine1))
                setHasMissingAddrField(true);
            return data.formatAddress;
        }
    });
    const handleSaveManualAddressCallback = () => {
        setFieldValue('billingAddressCountrySelector', selectedCountryCode);
    };
    const handleShowCountrySelector = () => {
        setFieldTouched('billingAddressCountrySelector', false, false);
        setFieldValue('showCountrySelector', true, false);
    };
    const handleCountrySelectorChange = (ev) => {
        const countrySelected = ev.target.value;
        addressFields.forEach((fieldName) => {
            const value = fieldName === 'country' ? countrySelected : '';
            setFieldValue(`billingAddress.${fieldName}`, value, false);
        });
        setFieldValue('billingAddressSelection', '', false);
        setFieldTouched('billingAddress', false);
        setFieldValue('billingAddressCountrySelector', countrySelected || countryCode, true);
    };
    const addressPlaceholder = (shorthand === 'ie' && countryCode !== 'GB') || countryCode === 'IE'
        ? 'Try the eircode or street name'
        : 'Enter Billing Postcode';
    const formikConfigMissingContactInfo = {
        initialValues: missingContactInfoInitialValues,
        validationSchema: missingContactInfoValidationConfig,
        onSubmit: (values) => {
            setHasMissingAddrField(false);
            formikContext.setFieldValue('billingAddress.addressLine1', values.updateAddressLine1);
        },
    };
    const isMissingAddressField = () => {
        var _a;
        const { errors, values } = context;
        return useDeliveryAddress === 'false' && !(errors === null || errors === void 0 ? void 0 : errors.billingAddressSelection) && !((_a = values === null || values === void 0 ? void 0 : values.billingAddress) === null || _a === void 0 ? void 0 : _a.addressLine1);
    };
    const validateBillingAddress = () => {
        if (isMissingAddressField()) {
            setHasMissingAddrField(true);
        }
        else
            formikContext.submitForm();
    };
    const setManualAddressChosen = (isManualAddress) => {
        setFieldValue('billingAddress.isManualAddress', isManualAddress);
        if (isManualAddress) {
            setFieldValue('billingAddress.country', countryCode); // Set default site country
            trackEnterManualAddressClick('Billing');
        }
    };
    const getClasses = () => {
        if (isCurrent) {
            return classes.active;
        }
        else if (isCompleted) {
            return classes.completed;
        }
        else {
            return classes.pending;
        }
    };
    const getCheckCircle = () => {
        var _a, _b;
        return deliveryAddress ? (_jsx(CheckCircle, { name: "useDeliveryAddress", value: "true", label: _jsxs(_Fragment, { children: [_jsxs("div", { className: classes.labelTitle, children: ["Use ", ((_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id) ? 'default billing address' : 'delivery address', ' '] }), deliveryAddressItems.map((item, index) => (_jsx("div", { className: classes.addressListItems, children: item }, index)))] }), fullWidth: true, controlAlignment: "top", className: classes.input })) : null;
    };
    return (_jsxs("div", { className: clsx(className, getClasses()), children: [_jsx(MissingContactFieldInfoModal, { hasMissingAddrField: hasMissingAddrField, setHasMissingAddrField: setHasMissingAddrField, isMobile: isMobile, classesMissingContactInfo: classesMissingContactInfo, formikConfigMissingContactInfo: formikConfigMissingContactInfo, isSubmitting: isSubmitting }), isCurrent ? (_jsxs("div", { className: classes.spinnerContainer, children: [_jsx("div", { className: classes.stepTitle, "data-testid": "checkout-billing-details", children: title }), cutOffPassedAlert || (_jsxs(_Fragment, { children: [getCheckCircle(), deliveryAddress ? _jsx("div", { className: classes.subTitle, children: "OR" }) : null, _jsx(CheckCircle, { name: "useDeliveryAddress", value: "false", fullWidth: true, controlAlignment: "top", className: classes.tickboxSection, label: _jsxs(_Fragment, { children: [_jsx("div", { className: classes.labelTitle, children: "Enter your billing address" }), !showCountrySelector || !useDeliveryAddress ? (_jsxs(_Fragment, { children: [_jsx("h4", { children: "Country" }), _jsxs("p", { children: [(countryBasedOnSelection === null || countryBasedOnSelection === void 0 ? void 0 : countryBasedOnSelection.name) || (countryBasedOnSite === null || countryBasedOnSite === void 0 ? void 0 : countryBasedOnSite.name), ' ', _jsx("button", { type: "button", className: classes.link, onClick: () => handleShowCountrySelector(), children: "Change" })] })] })) : (_jsx("div", { className: classes.input, children: _jsx(Select, { onChange: (ev) => handleCountrySelectorChange(ev), name: "billingAddressCountrySelector", label: "Choose a country", placeholder: "Select from list", items: countryList, required: true, fullWidth: true }) })), ((showCountrySelector && billingAddressCountrySelector) ||
                                            !showCountrySelector ||
                                            !useDeliveryAddress) && (_jsx(AddressLookup, { name: "billingAddressSelection", fieldContainer: "billingAddress", label: "Search for address (Required)", placeholder: addressPlaceholder, handleSaveManualAddressCallback: handleSaveManualAddressCallback, countryCode: (countryBasedOnSelection === null || countryBasedOnSelection === void 0 ? void 0 : countryBasedOnSelection.codeAlpha3) || (countryBasedOnSite === null || countryBasedOnSite === void 0 ? void 0 : countryBasedOnSite.codeAlpha3), addressFields: addressFields, onFocus: () => useDeliveryAddressHelper.setValue('false'), setManualAddressChosen: setManualAddressChosen, manualAddressTitle: "Billing address", handleAddressFormatCall: handleAddressFormatCall, provideManualAddress: true, hideRequiredOptional: true, required: true, fullWidth: true }))] }) }), _jsx("div", { className: classes.next, children: _jsx(Button, { variant: "primary", thinOnMobile: true, fullWidth: true, title: "Continue to Payment", onClick: validateBillingAddress, disabled: isSubmitting, icon: isFlyingFlowers ? 'shopping_basket' : '' }) }), isSubmitting && _jsx(CircularProgress, { className: classes.spinner })] }))] })) : (_jsxs(_Fragment, { children: [_jsx("span", { children: title }), isCompleted && _jsx(Icon, { icon: "check_circle", className: classes.tick })] }))] }));
};
export default CheckoutBillingDetails;
