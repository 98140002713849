import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Logo from '../Logo/Logo';
import useStyles from './CheckoutHeader.styles';
const CheckoutHeader = ({ title = 'Secure checkout', email }) => {
    const { classes } = useStyles();
    const url = '/';
    return (_jsx("header", { id: "header", className: classes.header, "data-hj-ignore-attributes": true, children: _jsxs("div", { className: classes.container, children: [_jsx(Logo, { url: url, className: classes.logo }), _jsxs("div", { className: classes.content, children: [_jsxs("h1", { className: classes.title, children: [_jsx(Icon, { icon: "lock", className: classes.icon }), title] }), !!email && _jsx(Link, { className: classes.link, label: email, url: `mailto:${email}`, newWindow: true }), ' '] })] }) }));
};
export default CheckoutHeader;
