import { makeStyles } from 'tss-react/mui';
import { inputStyles } from '../../styles/jss/inputs';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign({}, inputStyles(theme)), { menuListItem: Object.assign(Object.assign({}, theme.typography.small_regular), { 
        // These styles were implemented to address compatibility issues between React 18 and Material UI version 4. It's anticipated that these adjustments may become redundant upon upgrading to Material UI version 5.
        width: '100%', justifyContent: 'left !important', paddingTop: theme.isFlyingFlowers ? '2px' : '0px', paddingBottom: theme.isFlyingFlowers ? '2px' : '0px', paddingLeft: '16px !important', paddingRight: '16px !important' }), muiIcon: {
        display: 'none !important',
    }, muiRoot: Object.assign(Object.assign({}, theme.typography.small_regular), { backgroundColor: 'inherit', '&:focus': {
            backgroundColor: 'none',
        } }), label: Object.assign(Object.assign({}, theme.typography.small_bold), { display: 'block', cursor: 'pointer' }), muiSelectRoot: {
        padding: '0 24px 0 0 !important',
        '&:focus': {
            background: 'none !important',
        },
        '&.Mui-disabled': {
            '-webkit-text-fill-color': `${colors.grey.grey6} !important`,
        },
    }, 
    // These styles were implemented to address compatibility issues between React 18 and Material UI version 4. It's anticipated that these adjustments may become redundant upon upgrading to Material UI version 5.
    menuList: {
        display: 'flex',
        flexDirection: 'column',
    }, formControl: {
        minWidth: 'calc(100% - 30px)',
    } })));
export default useStyles;
