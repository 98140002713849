import LinkGalleryUI, { Props as UIProps } from '@interflora/ui-components/build/components/LinkGallery/LinkGallery'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { useLoaded } from '@interflora/ui-components'

type Props = UIProps

const LinkGallery = (props: Props) => {
  const analytics = useContext(AnalyticsContext)

  const sendLinkGalleryTextAnalytics = (linkText: string) => {
    analytics.sendLinkGalleryTextAnalytics(linkText)
  }

  useLoaded(props)

  return <LinkGalleryUI {...props} sendLinkGalleryTextAnalytics={sendLinkGalleryTextAnalytics} />
}

export default LinkGallery
