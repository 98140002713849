import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { errorMessages, isCookieAccepted, inputValidationRegex } from '../../utils/common';
import useStyles from './EditEmailAddress.styles';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';
import { SiteContext } from '../../utils/common';
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .required(errorMessages().required)
        .email(errorMessages().email)
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
});
const EditEmailAddress = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { email, onSubmit, onCancel } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const initialValues = {
        email,
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        onSubmit: (values) => onSubmit(values)
            .then(() => {
            setErrorMessage('');
            const isPerformanceCookieAccepted = isCookieAccepted();
            if ((values === null || values === void 0 ? void 0 : values.email) && window.ometria && isPerformanceCookieAccepted) {
                window.ometria.identify(values === null || values === void 0 ? void 0 : values.email);
            }
        })
            .catch((error) => {
            setErrorMessage(error.message);
        }),
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { children: "Editing email address" }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting, values }) => (_jsx(Form, { children: _jsxs("div", { className: classes.box, children: [_jsx("h2", { children: "Email" }), _jsx("p", { children: "This is the email address you use to sign into your account." }), errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "email", label: "Email address", value: values.email, placeholder: "e.g. john.smith@domain.co.uk", fullWidth: true, required: true }) }), _jsxs("div", { className: classes.buttons, children: [_jsx(Button, { variant: "secondary", thin: true, fullWidth: true, title: "Save email address", className: classes.saveButton, isSubmit: true, disabled: isSubmitting, icon: isFlyingFlowers ? 'check' : undefined }), _jsx(Button, { variant: "tertiary", onClick: onCancel, thin: true, fullWidth: true, icon: isFlyingFlowers ? 'close' : undefined, title: "Cancel - discard changes" })] })] }) })) }))] }));
};
export default EditEmailAddress;
