var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Form, Formik, FormikContext } from 'formik';
import { useState, useContext, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import useStyles from './ProductDetailPurchase.styles';
import PurchaseVariant, { initialValues as variantInitialValues, validationConfig as variantValidationConfig, } from './PurchaseVariant/PurchaseVariant';
import PurchaseRecipient, { initialValues as recipientInitialValues, validationConfig as recipientValidationConfig, } from '../PurchaseRecipient/PurchaseRecipient';
import PurchaseTribute, { initialValues as tributeInitialValues, validationConfig as tributeValidationConfig, } from '../PurchaseTribute/PurchaseTribute';
import PurchaseTributeDelivery, { validationConfig as tributeDeliveryValidationConfig, } from '../PurchaseTributeDelivery/PurchaseTributeDelivery';
import PurchaseDate, { initialValues as purchaseDateInitialValues, validationConfig as purchaseDateValidationConfig, } from '../PurchaseDate/PurchaseDate';
import PurchaseMessage, { initialValues as messageInitialValues, validationConfig as messageValidationConfig, } from './PurchaseMessage/PurchaseMessage';
import { getAttribute, getCoordinates, hasMultipleAttribute, priceFormatPartial, SiteContext } from '../../utils/common';
import _get from 'lodash/get';
import { useSessionStorage } from '../../utils/utilityCustomHooks';
import { useFeatureFlag } from '../../utils/useExperiment';
import { click_and_collect } from '../../utils/featureFlags';
const buyAgainInitialValues = { specialInstructions: '', openPrice: '', isVisited: false, sku: '' };
const ProductDetailPurchase = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { classes } = useStyles();
    const { product, submitForm, variant, setVariant, formatAddressCallback, deliveryOptionsCallback, isProductFulfillableCallback, productAvailabilityCallback, addToCartError, isSubmittingForm, onSignIn, digitalProducts, hasActiveDeliveryPass, sendStepAnalytics, sendEditVariantAnalytics, sendEditRecipientAnalytics, addNewAddress, updateContactAddress, newAddressId, countriesList, deliveryOptionsInfo, deliveryOptionsOrAlternateProductsCallback, updateMissingContactAddress, sendDateAnalytics, productVariantStyleInformation, sendOpenPriceAnalytics, saveUserJourneyAnalytics, sendPersonalMessageLocationToAnalytics, locationInternationalDelivery, ccProductAvailabilityCallBack, sendDeliveryOptionAnalytics, sendClickAndCollectStatusAnalytics, isAddNewButtonClicked, setIsAddNewButtonClicked, trackEnterManualAddressClick, } = props;
    const { getFromSessionStorage, saveToSessionStorage } = useSessionStorage();
    // More than 1 variant and more than 1 unique color
    const hasColorVariants = hasMultipleAttribute(product === null || product === void 0 ? void 0 : product.variants, 'variantColor');
    const hasSizeVariants = hasMultipleAttribute(product === null || product === void 0 ? void 0 : product.variants, 'variantSize');
    const hasStyleVariants = hasMultipleAttribute(product === null || product === void 0 ? void 0 : product.variants, 'variantStyle');
    const hasFloristChoice = getAttribute('isFloristChoice', product === null || product === void 0 ? void 0 : product.attributes);
    const hasOpenPrice = getAttribute('openPrice', variant === null || variant === void 0 ? void 0 : variant.attributes);
    const deliveryTypes = getAttribute('deliveryTypes', product === null || product === void 0 ? void 0 : product.attributes);
    const clickAndCollect = (_a = useFeatureFlag(click_and_collect.key)) !== null && _a !== void 0 ? _a : {};
    const clickAndCollectMVPCC1 = clickAndCollect === null || clickAndCollect === void 0 ? void 0 : clickAndCollect.MVP_CC1;
    const hasClickAndCollectDeliveryType = clickAndCollectMVPCC1 && (deliveryTypes === null || deliveryTypes === void 0 ? void 0 : deliveryTypes.includes('Collection'));
    //Old calendar flag to view old calendar on PDP
    const oldCalendar = !!getAttribute('oldCalendar', product === null || product === void 0 ? void 0 : product.attributes);
    const hasVariants = hasColorVariants || hasSizeVariants || hasStyleVariants;
    const [buyAgainVariantOpenPrice, setBuyAgainVariantOpenPrice] = useState(buyAgainInitialValues);
    const [currentStep, setCurrentStep] = useState(1);
    const [guidedNavData, setGuidedNavData] = useState({ address: '', occasion: '', date: '' });
    const [lineItemInitialValuesData, setlineItemInitialValuesData] = useState({
        address: '',
        occasion: '',
        deliveryDate: '',
        deliveryInstructionOption: '',
        deliveryInstructionNote: '',
        firstName: '',
        lastName: '',
        phone: '',
        specialInstructions: '',
        cardMessage: '',
        openPrice: '',
    });
    const [funeralLineItemData, setFuneralLineItemData] = useState({
        address: '',
        deceasedName: '',
        funeralServiceDate: '',
        funeralServiceTime: '',
        occasion: '',
        deliveryDate: '',
        deliveryInstructionOption: '',
        deliveryInstructionNote: '',
        firstName: '',
        lastName: '',
        phone: '',
        specialInstructions: '',
        cardMessage: '',
        openPrice: '',
    });
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState();
    const [deliveryOptionsLoading, setDeliveryOptionsLoading] = useState(false);
    const [collectionOptions, setCollectionOptions] = useState();
    const { countryCode: siteCountryCode, overseasCountryCode, isFlyingFlowers, getCustomer } = useContext(SiteContext);
    const [isAddNewAddress, setIsAddNewAddress] = useState(false);
    const [initialDeliveryOptions, setInitialDeliveryOptions] = useState([]);
    const [coordinates, setCoordinates] = useState([]);
    const [variantsInfo, setVariantInfo] = useState({});
    const formikContext = useContext(FormikContext);
    const countryCode = (product === null || product === void 0 ? void 0 : product.isOverseas) && overseasCountryCode
        ? overseasCountryCode
        : (product === null || product === void 0 ? void 0 : product.countryCode)
            ? product === null || product === void 0 ? void 0 : product.countryCode
            : siteCountryCode;
    const addressAvailable = !!((_e = (_d = (_c = (_b = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _b === void 0 ? void 0 : _b.customer) === null || _c === void 0 ? void 0 : _c.shippingAddresses) === null || _d === void 0 ? void 0 : _d.filter((address) => !!address.addressLine1 && !!address.firstName && countryCode === address.country)) === null || _e === void 0 ? void 0 : _e.length);
    const lastStep = 3 + (hasVariants || hasFloristChoice ? 1 : 0);
    const validationSchemas = [];
    if (hasVariants || hasFloristChoice)
        validationSchemas.push(variantValidationConfig);
    validationSchemas.push((product === null || product === void 0 ? void 0 : product.isFuneral) ? tributeValidationConfig : recipientValidationConfig);
    validationSchemas.push((product === null || product === void 0 ? void 0 : product.isFuneral) ? tributeDeliveryValidationConfig : purchaseDateValidationConfig);
    validationSchemas.push(messageValidationConfig);
    const defaultAddress = useMemo(() => { var _a, _b, _c; return (_c = (_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.shippingAddresses) === null || _c === void 0 ? void 0 : _c.find((address) => address === null || address === void 0 ? void 0 : address.isDefaultAddress); }, [(_g = (_f = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _f === void 0 ? void 0 : _f.customer) === null || _g === void 0 ? void 0 : _g.shippingAddresses]);
    const initialLineItemRecepientValues = defaultAddress && (lineItemInitialValuesData.address || guidedNavData.address || funeralLineItemData.address)
        ? defaultAddress
        : {};
    const firstNameInDefaultAddressFromPCC = (defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.firstName) && guidedNavData.address ? defaultAddress.firstName : lineItemInitialValuesData.firstName;
    const lastNameInDefaultAddressFromPCC = (defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.lastName) && guidedNavData.address ? defaultAddress.lastName : lineItemInitialValuesData.lastName;
    const initialValues = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, variantInitialValues), Object.assign(Object.assign(Object.assign({}, recipientInitialValues), { shouldValidatePhone: !isFlyingFlowers, isChooseAddress: !addressAvailable }), initialLineItemRecepientValues)), tributeInitialValues), purchaseDateInitialValues), messageInitialValues), { country: countryCode, recipientAddress: addressAvailable
            ? ''
            : (product === null || product === void 0 ? void 0 : product.isFuneral) && !!funeralLineItemData.address
                ? funeralLineItemData.address
                : !!(lineItemInitialValuesData === null || lineItemInitialValuesData === void 0 ? void 0 : lineItemInitialValuesData.address)
                    ? lineItemInitialValuesData.address
                    : guidedNavData.address, isCalendarPicked: !!funeralLineItemData.address || !!lineItemInitialValuesData.address || !!guidedNavData.address, deliveryDate: !!lineItemInitialValuesData.deliveryDate
            ? format(new Date(lineItemInitialValuesData === null || lineItemInitialValuesData === void 0 ? void 0 : lineItemInitialValuesData.deliveryDate), 'yyyy-MM-dd')
            : !!guidedNavData.date
                ? format(new Date(guidedNavData.date), 'yyyy-MM-dd')
                : '', occasion: (product === null || product === void 0 ? void 0 : product.isFuneral)
            ? 'Funeral'
            : !!(lineItemInitialValuesData === null || lineItemInitialValuesData === void 0 ? void 0 : lineItemInitialValuesData.occasion)
                ? lineItemInitialValuesData.occasion
                : guidedNavData.occasion, isFuneralDirector: (product === null || product === void 0 ? void 0 : product.isFuneral) ? true : false, variantColor: getAttribute('variantColor', variant === null || variant === void 0 ? void 0 : variant.attributes) || '', variantSize: getAttribute('variantSize', variant === null || variant === void 0 ? void 0 : variant.attributes) || '', variantStyle: getAttribute('variantStyle', variant === null || variant === void 0 ? void 0 : variant.attributes) || '', productName: product === null || product === void 0 ? void 0 : product.name, deliveryInstructionOption: (product === null || product === void 0 ? void 0 : product.isFuneral)
            ? funeralLineItemData.deliveryInstructionOption
            : lineItemInitialValuesData.deliveryInstructionOption, deliveryInstructionNote: (product === null || product === void 0 ? void 0 : product.isFuneral)
            ? funeralLineItemData.deliveryInstructionNote
            : lineItemInitialValuesData.deliveryInstructionNote, firstName: (product === null || product === void 0 ? void 0 : product.isFuneral) ? funeralLineItemData.firstName : firstNameInDefaultAddressFromPCC, lastName: (product === null || product === void 0 ? void 0 : product.isFuneral) ? funeralLineItemData.lastName : lastNameInDefaultAddressFromPCC, phone: (product === null || product === void 0 ? void 0 : product.isFuneral) ? funeralLineItemData.phone : lineItemInitialValuesData.phone, specialInstructions: (_h = buyAgainVariantOpenPrice.specialInstructions) !== null && _h !== void 0 ? _h : lineItemInitialValuesData.specialInstructions, cardMessage: lineItemInitialValuesData.cardMessage, deceasedName: funeralLineItemData.deceasedName, funeralServiceDate: !!funeralLineItemData.funeralServiceDate
            ? new Date(funeralLineItemData.funeralServiceDate)
            : '', funeralServiceTime: funeralLineItemData.funeralServiceTime.replace(/(.{2})$/, ':$1'), openPrice: (_j = buyAgainVariantOpenPrice.openPrice) !== null && _j !== void 0 ? _j : lineItemInitialValuesData.openPrice, hasOpenPrice, minOpenPrice: parseInt(priceFormatPartial(variant === null || variant === void 0 ? void 0 : variant.price).slice(1)) });
    const [hasMissingAddrField, setHasMissingAddrField] = useState(false);
    const analyticsStep = hasVariants && currentStep === 1
        ? 'VARIANTS'
        : !(product === null || product === void 0 ? void 0 : product.isFuneral) && !hasVariants && currentStep === 1
            ? 'ADDRESS'
            : !(product === null || product === void 0 ? void 0 : product.isFuneral) && hasVariants && currentStep === 2
                ? 'ADDRESS'
                : !(product === null || product === void 0 ? void 0 : product.isFuneral) && !hasVariants && currentStep === 2
                    ? 'OPTION'
                    : !(product === null || product === void 0 ? void 0 : product.isFuneral) && hasVariants && currentStep === 3
                        ? 'OPTION'
                        : // PERSONALISE event is handled in submit method
                            '';
    const handleRecipientAddress = (values, clickAndCollectOption) => {
        if ((!(product === null || product === void 0 ? void 0 : product.isFuneral) && !hasVariants && currentStep === 1) ||
            (!(product === null || product === void 0 ? void 0 : product.isFuneral) && hasVariants && currentStep === 2)) {
            setVariantInfo({
                color: values.variantColor,
                size: values.variantSize,
                style: values.variantStyle,
                specialInstructions: values.specialInstructions,
                recipientAddress: values.recipientAddress,
                addressId: values.recipientAddress ? '' : values.addressId,
            });
            analyticsStep && clickAndCollectOption === 'true' && (sendEditRecipientAnalytics === null || sendEditRecipientAnalytics === void 0 ? void 0 : sendEditRecipientAnalytics(values, variantsInfo));
        }
    };
    const formikConfig = Object.assign(Object.assign(Object.assign({}, useMemo(() => ({
        initialValues,
        enableReinitialize: true,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemInitialValuesData, guidedNavData, funeralLineItemData, hasOpenPrice, buyAgainVariantOpenPrice])), useMemo(() => ({
        validateOnBlur: !(hasOpenPrice && currentStep === 1),
        validateOnChange: !(hasOpenPrice && currentStep === 1),
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOpenPrice, currentStep])), { validationSchema: validationSchemas[currentStep - 1], onSubmit: (values, formikHelpers) => {
            if (currentStep < lastStep) {
                formikHelpers.setTouched({});
                const clickAndCollectOption = sessionStorage.getItem('clickAndCollect');
                if (hasVariants && currentStep === 1) {
                    setVariantInfo({
                        color: values.variantColor,
                        size: values.variantSize,
                        style: values.variantStyle,
                        specialInstructions: values.specialInstructions,
                    });
                    analyticsStep && clickAndCollectOption === 'true' && (sendEditVariantAnalytics === null || sendEditVariantAnalytics === void 0 ? void 0 : sendEditVariantAnalytics(values, variantsInfo));
                    analyticsStep && sendStepAnalytics(analyticsStep, Object.assign(Object.assign({}, values), variant));
                    if (hasOpenPrice) {
                        (variant === null || variant === void 0 ? void 0 : variant.sku) && (sendOpenPriceAnalytics === null || sendOpenPriceAnalytics === void 0 ? void 0 : sendOpenPriceAnalytics(variant.sku, values.openPrice, 'VALID'));
                    }
                }
                else {
                    analyticsStep && sendStepAnalytics(analyticsStep, values, formikContext === null || formikContext === void 0 ? void 0 : formikContext.errors);
                }
                handleRecipientAddress(values, clickAndCollectOption);
                setHasMissingAddrField(false);
                setCurrentStep((prev) => prev + 1);
            }
            else {
                submitForm(values);
            }
        } });
    const handleDeliveryOptionsCall = (deliveryDate, ruleIds, formikContext, queryOnly, deliveryPassType, address, isFromDateChange) => __awaiter(void 0, void 0, void 0, function* () {
        setDeliveryOptionsLoading(true);
        const { data, error } = yield deliveryOptionsCallback({
            variant: variant.sku,
            deliveryDate,
            ruleIds,
            deliveryPassType,
            countryCode,
            address: isFlyingFlowers && !!address ? address : undefined,
        });
        setDeliveryOptionsLoading(false);
        if (!error && data) {
            const { deliveryOptions } = data;
            if (!!deliveryOptions && !isFromDateChange)
                setInitialDeliveryOptions(deliveryOptions);
            !queryOnly && formikContext.setFieldValue('deliveryOptions', deliveryOptions);
            return deliveryOptions;
        }
        return [];
    });
    const handleDeliveryOptionsOrAlternateProducts = (deliveryDate, ruleIds, deliveryPassType, countryCode, address, occasion) => __awaiter(void 0, void 0, void 0, function* () {
        setDeliveryOptionsLoading(true);
        try {
            const response = yield deliveryOptionsOrAlternateProductsCallback(product === null || product === void 0 ? void 0 : product.key, variant.sku, deliveryDate, ruleIds, deliveryPassType, countryCode, address, occasion);
            setDeliveryOptionsLoading(false);
            return response;
        }
        catch (error) {
            setDeliveryOptionsLoading(false);
            return { deliveryOptions: [], productSuggestions: [] };
        }
    });
    const handleCCProductAvailability = (deliveryDate, countryCode, address) => __awaiter(void 0, void 0, void 0, function* () {
        var _m;
        const { latitude, longitude } = getCoordinates(coordinates);
        setDeliveryOptionsLoading(true);
        if (hasClickAndCollectDeliveryType) {
            try {
                const response = yield (ccProductAvailabilityCallBack === null || ccProductAvailabilityCallBack === void 0 ? void 0 : ccProductAvailabilityCallBack(product === null || product === void 0 ? void 0 : product.key, variant.sku, deliveryDate, countryCode, address, latitude.toString(), longitude.toString()));
                if ((_m = response === null || response === void 0 ? void 0 : response.ccAddress) === null || _m === void 0 ? void 0 : _m.length) {
                    saveToSessionStorage('clickAndCollect', 'true');
                }
                else {
                    saveToSessionStorage('clickAndCollect', 'false');
                }
                setDeliveryOptionsLoading(false);
                setCollectionOptions(response === null || response === void 0 ? void 0 : response.ccAddress);
                return response === null || response === void 0 ? void 0 : response.ccAddress;
            }
            catch (error) {
                setDeliveryOptionsLoading(false);
                return {};
            }
        }
        else
            return {};
    });
    const sameDay = !!getAttribute('sameDay', product === null || product === void 0 ? void 0 : product.attributes);
    useEffect(() => {
        const guidedNavigationValues = sessionStorage.getItem('guidedNavigationValues')
            ? JSON.parse(sessionStorage.getItem('guidedNavigationValues'))
            : undefined;
        if (guidedNavigationValues) {
            setGuidedNavData(guidedNavigationValues);
        }
        const sku = sessionStorage.getItem('productScrollIntoView');
        sku && sessionStorage.setItem('isFromPDP', 'true');
    }, []);
    useEffect(() => {
        const lineItemIntialValues = sessionStorage.getItem('removedProductLineItem')
            ? JSON.parse(sessionStorage.getItem('removedProductLineItem'))
            : undefined;
        if (lineItemIntialValues) {
            setlineItemInitialValuesData(lineItemIntialValues);
        }
    }, []);
    useEffect(() => {
        const funeralLineItemValues = sessionStorage.getItem('funeralLineItemStore')
            ? JSON.parse(sessionStorage.getItem('funeralLineItemStore'))
            : null;
        if (funeralLineItemValues)
            setFuneralLineItemData(funeralLineItemValues);
    }, []);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isChooseAddress', (!!((_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id) && !addressAvailable) ||
            (!!((_d = (_c = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _c === void 0 ? void 0 : _c.customer) === null || _d === void 0 ? void 0 : _d.id) && isAddNewAddress) ||
            !((_f = (_e = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _e === void 0 ? void 0 : _e.customer) === null || _f === void 0 ? void 0 : _f.id));
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('shouldValidatePhone', !!isFlyingFlowers ? false : true);
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('shouldValidatePostCode', !((_h = (_g = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _g === void 0 ? void 0 : _g.customer) === null || _h === void 0 ? void 0 : _h.id) || isAddNewAddress || (!!((_k = (_j = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _j === void 0 ? void 0 : _j.customer) === null || _k === void 0 ? void 0 : _k.id) && !addressAvailable));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_k = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _k === void 0 ? void 0 : _k.customer, isAddNewAddress, (_l = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _l === void 0 ? void 0 : _l.isChooseAddress, isFlyingFlowers]);
    useEffect(() => {
        var _a;
        const buyAgainStorageData = getFromSessionStorage('buyAgainOrderItem');
        if (buyAgainStorageData) {
            const buyAgainVariant = product.variants.find((v) => v.sku === buyAgainStorageData.sku);
            setVariant === null || setVariant === void 0 ? void 0 : setVariant(buyAgainVariant);
            const hasBuyAgainOpenPrice = buyAgainStorageData.isOpenPrice
                ? ((_a = buyAgainStorageData.price.slice(1)) === null || _a === void 0 ? void 0 : _a.split('.')[0]) + ''
                : '';
            setBuyAgainVariantOpenPrice(Object.assign(Object.assign({}, buyAgainStorageData), { openPrice: hasBuyAgainOpenPrice }));
            setCurrentStep(hasVariants || hasFloristChoice ? 2 : 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setFuneralDeliveryOptions = (availability, formikContext, funeralServiceDateString) => {
        formikContext.setFieldValue('productAvailability', availability);
        const dateAvailability = availability.find((item) => item.date === funeralServiceDateString);
        const ruleIds = _get(dateAvailability, 'deliveryServiceTypes', []).map((serviceType) => serviceType.ruleId);
        formikContext.setFieldValue('isCalendarPicked', false);
        formikContext.setFieldValue('deliveryDate', funeralServiceDateString);
        handleDeliveryOptionsCall(funeralServiceDateString, ruleIds, formikContext);
    };
    const handleFuneralSaveManualAddress = ({ startDate, endDate, formikContext, }) => {
        const { addressLine1, addressLine2, city, county, postalCode, country } = formikContext.values;
        productAvailabilityCallback({
            startDate,
            endDate,
            variant: variant.sku,
            address: {
                addressLine1,
                addressLine2,
                city,
                county,
                postalCode,
                country,
            },
        })
            .then((response) => {
            if (!response || response.error) {
                throw new Error(`The product availability check returned an error response: ${response === null || response === void 0 ? void 0 : response.error}`);
            }
            const { productAvailability } = response.data;
            if (!productAvailability.length) {
                formikContext.setFieldValue('deliveryError', 'There are no available delivery options');
                setFuneralDeliveryOptions([], formikContext, startDate);
            }
            else {
                formikContext.setFieldValue('deliveryError', '');
                setFuneralDeliveryOptions(productAvailability, formikContext, startDate);
            }
        })
            .catch((error) => {
            console.error(error);
        });
    };
    var stepCount;
    return (_jsx("div", { className: clsx(props.className, classes.purchase), children: _jsx(Formik, Object.assign({}, formikConfig, { children: ({ errors, isSubmitting }) => {
                stepCount = 1;
                return (_jsxs(Form, { children: [(hasVariants || hasFloristChoice) && (_jsx(PurchaseVariant, { step: stepCount++,
                            currentStep,
                            setCurrentStep,
                            sameDay,
                            product,
                            setVariant,
                            hasFloristChoice,
                            productVariantStyleInformation,
                            hasOpenPrice,
                            errors,
                            isSubmitting,
                            sendOpenPriceAnalytics })), (product === null || product === void 0 ? void 0 : product.isFuneral) ? (_jsxs(_Fragment, { children: [_jsx(PurchaseTribute, { step: stepCount++,
                                    currentStep,
                                    setCurrentStep,
                                    handleFuneralSaveManualAddress }), _jsx(PurchaseTributeDelivery, { step: stepCount++,
                                    currentStep,
                                    setCurrentStep,
                                    sameDay,
                                    variant: variant.sku,
                                    formatAddressCallback,
                                    handleFuneralSaveManualAddress,
                                    handleDeliveryOptionsCall,
                                    productAvailabilityCallback,
                                    isProductFulfillableCallback,
                                    setDeliveryOptions: setFuneralDeliveryOptions,
                                    countryCode,
                                    deliveryOptionsInfo: deliveryOptionsInfo,
                                    initialRecipientAddress: !!(funeralLineItemData === null || funeralLineItemData === void 0 ? void 0 : funeralLineItemData.address) ? funeralLineItemData.address : '' })] })) : (_jsxs(_Fragment, { children: [_jsx(PurchaseRecipient, { step: stepCount++,
                                    currentStep,
                                    setCurrentStep,
                                    sameDay,
                                    variant: variant === null || variant === void 0 ? void 0 : variant.sku,
                                    formatAddressCallback,
                                    productAvailabilityCallback,
                                    countryCode,
                                    onSignIn,
                                    initialRecipientAddress: !!(lineItemInitialValuesData === null || lineItemInitialValuesData === void 0 ? void 0 : lineItemInitialValuesData.address)
                                        ? lineItemInitialValuesData.address
                                        : !!(guidedNavData === null || guidedNavData === void 0 ? void 0 : guidedNavData.address)
                                            ? guidedNavData === null || guidedNavData === void 0 ? void 0 : guidedNavData.address
                                            : undefined,
                                    addNewAddress,
                                    updateContactAddress,
                                    newAddressId,
                                    setIsAddNewAddress,
                                    isAddNewAddress,
                                    isDropship: product === null || product === void 0 ? void 0 : product.isDropship,
                                    isInternational: product === null || product === void 0 ? void 0 : product.isInternational,
                                    countriesList,
                                    updateMissingContactAddress,
                                    sendStepAnalytics,
                                    hasMissingAddrField,
                                    setHasMissingAddrField,
                                    oldCalendar,
                                    saveUserJourneyAnalytics,
                                    locationInternationalDelivery,
                                    hasClickAndCollectDeliveryType,
                                    setCoordinates,
                                    isAddNewButtonClicked,
                                    setIsAddNewButtonClicked,
                                    trackEnterManualAddressClick }), _jsx(PurchaseDate, { step: stepCount++,
                                    currentStep,
                                    setCurrentStep,
                                    sameDay,
                                    loading: deliveryOptionsLoading,
                                    handleDeliveryOptionsCall,
                                    selectedDeliveryOption,
                                    setSelectedDeliveryOption,
                                    variant: variant === null || variant === void 0 ? void 0 : variant.sku,
                                    productAvailabilityCallback,
                                    isProductFulfillableCallback,
                                    onSignIn,
                                    digitalProducts,
                                    hasActiveDeliveryPass,
                                    initialDeliveryOptions,
                                    isDropship: product === null || product === void 0 ? void 0 : product.isDropship,
                                    isFuneral: product === null || product === void 0 ? void 0 : product.isFuneral,
                                    isInternational: product === null || product === void 0 ? void 0 : product.isInternational,
                                    deliveryOptionsInfo: deliveryOptionsInfo,
                                    handleDeliveryOptionsOrAlternateProducts,
                                    sendDateAnalytics,
                                    oldCalendar,
                                    saveUserJourneyAnalytics,
                                    handleCCProductAvailability,
                                    sendDeliveryOptionAnalytics,
                                    sendClickAndCollectStatusAnalytics,
                                    collectionOptions })] })), _jsx(PurchaseMessage, Object.assign({}, (stepCount === lastStep && { isSubmittingForm }), { step: stepCount++,
                            currentStep,
                            setCurrentStep,
                            isFuneral: product === null || product === void 0 ? void 0 : product.isFuneral,
                            addToCartError,
                            sendPersonalMessageLocationToAnalytics }))] }));
            } })) }));
};
export default ProductDetailPurchase;
