import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
import { addImportant } from '../../utils/common';
const useStyles = makeStyles()((theme, params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column' }, theme.typography.xl_regular), theme.typographySpacing.none), { color: colors.supplementary.white, [theme.breakpoints.up('sm')]: {
            marginBottom: spacing.small,
        }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl), { marginBottom: spacing.small }), [theme.breakpoints.up('lg')]: {
            marginBottom: spacing.medium,
        }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), { marginBottom: spacing.large }) }),
    subheading: Object.assign(Object.assign(Object.assign({}, addImportant(theme.typography.small_bold)), theme.typographySpacing.none), { textAlign: 'center', [theme.breakpoints.up('sm')]: {
            display: 'block',
            textAlign: 'left',
            marginBottom: spacing.medium,
        }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign(Object.assign({}, addImportant(theme.typography.large_bold)), theme.typographySpacing.none), { marginBottom: spacing.medium }), [theme.breakpoints.down('md')]: {
            marginTop: '4px',
            marginBottom: '0',
        } }),
    message: Object.assign(Object.assign({ marginTop: spacing.small, textAlign: 'center' }, theme.typography.xxs_regular), { paddingBottom: '6px', marginBottom: spacing.small, '& p': Object.assign({}, theme.typography.xxs_regular) }),
    linkContainer: {
        display: 'block',
        // width:'100%',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            // margin: `0 -${spacing.xs}`,
            [`& .${classes.link}`]: {
                // width: '100%',
                flexDirection: 'column',
                margin: 0,
                // padding: `0 ${spacing.xs}`,
            },
        },
        '& button': { width: '100%' },
    },
    hideOnMobile: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    ffLinkContainer: {
        position: 'absolute',
        bottom: spacing.medium,
        left: 0,
        right: 0,
        marginLeft: '20px',
        marginRight: '20px',
    },
    outsideLinkContainer: {
        marginLeft: '20px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: spacing.medium,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        // [theme.breakpoints.down('sm')]: {
        //   marginLeft: 0,
        //   marginRight: 0,
        // },
    },
    outsideSubheading: {
        marginTop: spacing.medium,
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            // flexGrow: 1,
            whiteSpace: 'pre-wrap',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            margin: 0,
            '& button': { width: '100%' },
            '&:not(:last-child)': {
                marginBottom: '0',
                marginRight: spacing.medium,
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '46%',
        },
    },
    headingLine: {
        whiteSpace: 'nowrap',
        alignSelf: 'flex-start',
        padding: spacing.xs,
        '&:first-child': {
            transform: 'rotate(358deg) translate(5px,5px)',
            transformOrigin: 'bottom left',
        },
    },
    COBALT: {
        backgroundColor: colors.cobalt[500],
    },
    JADE: {
        backgroundColor: colors.jade[500],
    },
    WILD_EXOTIC: {
        backgroundColor: colors.wildExotic[500],
    },
    image: {
        '& img': {
            // flying flowers image positioned at the bottom
            objectPosition: 'bottom right',
            // objectFit: 'cover',
            [theme.breakpoints.down('md')]: {
                // height: '268px',
                // maxHeight: '268px',
                objectPosition: 'bottom',
                // objectFit: 'cover',
            },
        },
    },
    subMessageContainer: {
        textAlign: 'center',
        display: 'flex',
        padding: `0 ${theme.spacing(2)}`,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: (params === null || params === void 0 ? void 0 : params.isSubHeading) ? '56px' : 'initial',
            height: (params === null || params === void 0 ? void 0 : params.isSubHeading) ? '56px' : 'initial',
            '@media(orientation: portrait)': {
                placeContent: 'unset',
            },
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            padding: '14px 0',
            minHeight: (params === null || params === void 0 ? void 0 : params.isSubHeading) ? '72px' : 'initial',
            height: (params === null || params === void 0 ? void 0 : params.isSubHeading) ? '72px' : 'initial',
        },
        [`& .${classes.subMessageTitle}`]: Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
        [`& .${classes.subMessage}`]: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular) }),
    },
    subMessageTitle: {},
    subMessage: {},
    ffMobileSubMessageContainer: {
        display: 'none !important',
        [theme.breakpoints.up('sm')]: {
            display: 'flex !important',
        },
    },
    topsubMessageContainer: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },
    ffCenterContentInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'initial',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    centerHeading: {
        alignSelf: 'center',
    },
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginTop: spacing.small,
        marginLeft: '20px',
        marginRight: '20px',
        padding: '18px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    adjustSubHeading: {
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: spacing.small,
            marginRight: spacing.small,
        },
    },
    contentInner: {
        margin: `${spacing.small} 20px 10px`,
        [theme.breakpoints.up('md')]: {
            margin: spacing.xxl,
        },
        [theme.breakpoints.up('xl')]: {
            margin: '128px !important',
        },
    },
    displayContentCenter: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    ffHomeHeroBannerMobile: {
        minHeight: '512px',
        placeContent: 'start',
    },
}));
export default useStyles;
