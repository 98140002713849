import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import useStyles from './CnCFloristGallery.styles';
import Button from '../Button/Button';
import CnCFloristCard from '../CnCFloristCard/CnCFloristCard';
import { LOAD_MORE_FLORISTS } from '../../utils/constants';
const CnCFloristGallery = ({ date, ccAddresses, resetModal, setCurrentChosenCcDetails, handleCloseChangeFloristModel, }) => {
    const { classes } = useStyles();
    const [openIndex, setOpenIndex] = useState(0); // Default to the first card
    const [visibleFlorists, setVisibleFlorists] = useState(3);
    const firstNewCardRef = useRef(null); // Ref for the first new card
    const handleToggle = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index)); // Close if card is open, otherwise open the clicked card
    };
    const handleLoadMore = () => {
        setVisibleFlorists((prevCount) => prevCount + 3); // Load next 3 records
    };
    useEffect(() => {
        if (firstNewCardRef.current) {
            firstNewCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Scroll to the first new card
        }
    }, [visibleFlorists]);
    useEffect(() => {
        setOpenIndex(0);
        setVisibleFlorists(3);
        if (firstNewCardRef.current && typeof firstNewCardRef.current.scrollIntoView === 'function') {
            firstNewCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [resetModal]);
    return (_jsxs("div", { className: classes.container, children: [ccAddresses === null || ccAddresses === void 0 ? void 0 : ccAddresses.slice(0, visibleFlorists).map((collection, index) => (_jsx("div", { ref: index === visibleFlorists - 3 ? firstNewCardRef : null, children: _jsx(CnCFloristCard, { ccAddress: collection, isOpen: openIndex === index, date: date, onToggle: () => handleToggle(index), handleSelectFlorist: () => {
                        setCurrentChosenCcDetails === null || setCurrentChosenCcDetails === void 0 ? void 0 : setCurrentChosenCcDetails(ccAddresses[index]);
                        handleCloseChangeFloristModel();
                    } }) }, `collection_${index}_${collection === null || collection === void 0 ? void 0 : collection.name}`))), visibleFlorists < (ccAddresses === null || ccAddresses === void 0 ? void 0 : ccAddresses.length) && (_jsx("div", { className: classes.loadMoreButton, children: _jsx(Button, { onClick: handleLoadMore, title: LOAD_MORE_FLORISTS, variant: "tertiary", thin: true }) }))] }));
};
export default CnCFloristGallery;
