import { makeStyles } from 'tss-react/mui';
import { colors, spacing, fontSizes } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    iconPosition: {
        marginLeft: 'auto',
        alignSelf: 'center',
    },
    iconSize: {
        '& .MuiIcon-root': {
            fontSize: fontSizes.large,
        },
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: 0, alignSelf: 'stretch', textWrap: 'nowrap', color: colors.grey.grey7 }),
    iconColor: {
        color: `${colors.grey.grey4} !important`,
    },
    helperIconSize: {
        '& .MuiIcon-root': {
            fontSize: fontSizes.large,
        },
    },
    helperIconColor: {
        color: colors.primary.gold5,
        marginLeft: 'auto',
    },
    selectContainer: {
        width: '100%',
        display: 'flex',
        gap: '12px',
        border: `1px solid ${colors.grey.grey3}`,
        borderRadius: '4px',
        padding: `12px ${spacing.large}`,
        background: `linear-gradient(0deg,${colors.background.WHITE} , ${colors.background.WHITE})`,
        cursor: 'pointer',
    },
}));
export default useStyles;
