import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { default as _uniqBy } from 'lodash/uniqBy';
import { useContext } from 'react';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import { guidedNavigationFilters, SiteContext } from '../../utils/common';
import useStyles from './FilterClearRefinements.styles';
const FilterClearRefinements = ({ onClick, className, setSelectedFilter }) => {
    const { items } = useCurrentRefinements();
    const { refine } = useClearRefinements({ excludedAttributes: guidedNavigationFilters });
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const handleRefine = () => {
        refine();
        !isFlyingFlowers && setSelectedFilter && setSelectedFilter([]);
        onClick && onClick();
    };
    const onlyGuidedNavFilters = !!items.length && items.every((filter) => guidedNavigationFilters.includes(filter.attribute));
    const nonGuidedNavFiltersCount = _uniqBy(items, 'attribute')
        .filter((filter) => !guidedNavigationFilters.includes(filter.attribute))
        .reduce((count, filter) => count + (filter.attribute !== 'price' && filter.refinements ? filter.refinements.length : 1), 0);
    const text = onlyGuidedNavFilters
        ? 'Clear active filters'
        : nonGuidedNavFiltersCount === 0
            ? 'Clear all filters'
            : nonGuidedNavFiltersCount === 1
                ? 'Clear 1 active filter'
                : `Clear ${nonGuidedNavFiltersCount} active filters`;
    return (_jsx("button", { className: clsx(classes.clearFilter, className !== null && className !== void 0 ? className : ''), onClick: handleRefine, disabled: nonGuidedNavFiltersCount === 0 || onlyGuidedNavFilters, children: text }));
};
export default FilterClearRefinements;
