import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {},
    imageContainer: {
        position: 'relative',
        width: '100%',
        // Make the container a square as all padding is calculated from width, not height
        '&span:after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        },
    },
    image: {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        display: 'block',
    },
    label: {
        paddingTop: spacing.xs,
        paddingBottom: spacing.xs,
        textAlign: 'center',
        '& span': Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    },
    badge: {
        position: 'absolute',
        left: theme.isFlyingFlowers ? undefined : spacing.xs,
        top: theme.isFlyingFlowers ? undefined : spacing.xs,
        right: theme.isFlyingFlowers ? spacing.xs : undefined,
        bottom: theme.isFlyingFlowers ? spacing.xs : undefined,
    },
}));
export default useStyles;
