import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    imageContent: {
        [theme.breakpoints.up('md')]: {
            width: '248px !important',
            height: '248px !important',
        },
    },
    messageContent: {
        width: '167px !important',
        height: '160px !important',
        [theme.breakpoints.up('md')]: {
            width: '272px !important',
            height: '248px !important',
        },
    },
    floristContent: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.medium,
        },
    },
}));
export default useStyles;
