import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    missingContactInfoModal: {
        '& .MuiPaper-root': {
            padding: '20px',
            [theme.breakpoints.up('md')]: {
                padding: '30px 32px 64px 32px',
                maxWidth: '792px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0 104px 0 104px !important',
                },
            },
        },
        '& header': Object.assign(Object.assign({}, theme.typography.large_regular), { marginBottom: '20px', [theme.breakpoints.up('md')]: {
                marginLeft: '104px',
                marginBottom: spacing.large,
            }, '& h3': {
                paddingTop: '23px',
            } }),
        '& #bodyContainer': {
            '& button': {
                '& .MuiButton-label': Object.assign({}, theme.typography.medium_button),
            },
        },
    },
}));
export default useStyles;
