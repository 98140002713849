import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
const SearchStateContext = createContext(undefined);
const SearchUpdateContext = createContext(undefined);
const SearchNbHitsContext = createContext({
    nbHits: [],
    setnbHits: () => { },
});
function SearchProvider({ children }) {
    const [state, setQuery] = useState('');
    const [nbHits, setnbHits] = useState([]);
    const nbHitscontextValue = useMemo(() => ({ nbHits, setnbHits }), [nbHits]);
    return (_jsx(SearchStateContext.Provider, { value: state, children: _jsx(SearchUpdateContext.Provider, { value: setQuery, children: _jsx(SearchNbHitsContext.Provider, { value: nbHitscontextValue, children: children }) }) }));
}
const useSearchState = () => {
    const context = useContext(SearchStateContext);
    if (context === undefined) {
        throw new Error('useSearchState must be used within a SearchStateContext');
    }
    return context;
};
const useSearchUpdate = () => {
    const context = useContext(SearchUpdateContext);
    if (context === undefined) {
        throw new Error('useGlobalDispatch must be used within a SearchUpdateContext');
    }
    return context;
};
const useSearchNBHitsContext = () => {
    const context = useContext(SearchNbHitsContext);
    if (context === undefined) {
        throw new Error('useGlobalDispatch must be used within a SearchUpdateContext');
    }
    return context;
};
export { SearchProvider, useSearchState, useSearchUpdate, useSearchNBHitsContext };
