import { makeStyles } from 'tss-react/mui';
import { fontWeights, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    Gallerycontent: {
        minWidth: '25%',
    },
    contentContainer: {
        flexGrow: 1,
        flexBasis: '50%',
        '&:not(:last-child)': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: spacing.small,
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '160px',
            },
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            marginRight: spacing.medium,
            paddingRight: spacing.small,
        },
        '&, & p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular), '&:last-child': {
                marginBottom: 0,
            } }),
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.large }),
    },
    title: Object.assign(Object.assign({}, theme.typography.large_bold), { fontWeight: fontWeights.bold, marginBottom: spacing.xs, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    bodyContent: {
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    },
}));
export default useStyles;
