import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    orderDetailTitle: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: '26px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), { marginBottom: spacing.large }) }),
    orderDetailsHelpContainer: {
        marginBottom: spacing.xl,
        paddingTop: spacing.xs,
    },
    orderDetailSummary: {
        [theme.breakpoints.up('md')]: {
            margin: 'initial !important',
        },
    },
    orderDetailButton: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            margin: `${spacing.xxl} 0 0 !important`,
            display: 'block',
        },
    },
    removeMarginInOrderDetail: {
        marginLeft: '0 !important',
        marginRight: '0 !important',
    },
}));
export default useStyles;
