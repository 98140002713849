import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from '../SearchOverlay.styles';
import LinkBlock from '../../LinkBlock/LinkBlock';
const SuggestedSearches = ({ keywords = [], categories = [], searchDefaultProducts = [], handleKeywordAndPreviousSearch, }) => {
    const showKeywordsOrCategories = keywords.length || categories.length;
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: showKeywordsOrCategories ? (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.previousSearchTitle, children: "Suggested searches" }), _jsxs("div", { className: classes.suggestSearchContainer, children: [keywords.length ? (_jsxs("div", { className: clsx(classes.querySuggestionContainer, classes.suggestLinkContainer, classes.searchLinkContainer), children: [_jsx("div", { className: clsx(classes.categoryHeading, classes.suggestSeachLinkHeading), children: "Keywords" }), _jsx("ul", { className: clsx(classes.linkBlockContainer, (searchDefaultProducts === null || searchDefaultProducts === void 0 ? void 0 : searchDefaultProducts.length) ? classes.linkBlockContainerWithProducts : ''), children: keywords.map((keyWord) => (_jsx("li", { "data-testid": keyWord, children: _jsx(LinkBlock, { label: keyWord, className: classes.searchItem, onClick: (e) => handleKeywordAndPreviousSearch(e, keyWord) }) }, `suggested_keywords_${keyWord}`))) })] })) : null, categories.length ? (_jsxs("div", { className: clsx(classes.querySuggestionContainer, classes.suggestLinkContainer, classes.searchLinkContainer), children: [_jsx("div", { className: clsx(classes.categoryHeading, classes.suggestSeachLinkHeading), children: "Categories" }), _jsx("ul", { className: clsx(classes.linkBlockContainer, (searchDefaultProducts === null || searchDefaultProducts === void 0 ? void 0 : searchDefaultProducts.length)
                                        ? classes.linkBlockContainerWithProducts
                                        : classes.suggestSearchOverlay), children: categories.map((category) => (_jsx("li", { "data-testid": category.label, children: _jsx(LinkBlock, { label: category.label, url: category.url }) }, `suggestedCategory_${category.label}`))) })] })) : null] })] })) : null }));
};
export default SuggestedSearches;
