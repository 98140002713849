import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    cardcontainer: {
        border: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
        borderRadius: '1px',
        width: '100%',
        height: 'auto',
        padding: spacing.small,
        backgroundColor: colors.supplementary.white,
        marginBottom: spacing.xs,
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            flex: '1 0 50%',
            marginLeft: 0,
        },
        '&:focus': {
            BackgroundColor: colors.grey.grey1,
            boxShadow: '0px 0px 2px #008be9, 0px 2px 2px rgba(0, 0, 0, 0.32)',
        },
    },
    floristDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        alignItems: 'flex-start',
        marginTop: spacing.large,
        [theme.breakpoints.up('md')]: {
            flex: '1 0 auto',
            marginBottom: 0,
        },
    },
    floristName: Object.assign(Object.assign({}, theme.typography.small_bold), { padding: '1px !important', marginBottom: 0, color: colors.supplementary.black, width: '70%', maxHeight: '52px', overflow: 'hidden', [theme.breakpoints.up('md')]: {
            width: '80%',
        } }),
    collectionTime: Object.assign({}, theme.typography.xxs_regular),
    floristInfo: Object.assign(Object.assign({}, theme.typography.xxs_regular), { color: colors.grey.grey6 }),
    toggleInfo: Object.assign(Object.assign({}, theme.typography.xxs_regular), { display: 'flex' }),
    floristDistance: {
        color: colors.grey.grey6,
    },
    hideIcon: {
        display: 'block',
        marginRight: spacing.medium,
    },
    chevronIcon: {
        display: 'block',
        '& span': {
            paddingRight: 0,
        },
    },
    selectFlorist: {
        alignSelf: 'flex-end',
    },
    floristInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            flex: '1 0 auto',
            marginBottom: 0,
        },
    },
    floristShopTimings: Object.assign(Object.assign({ marginBottom: spacing.small }, theme.typography.xxs_regular), { display: 'flex', color: colors.grey.grey6, [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        } }),
    floristDetailsContainer: {
        marginTop: '4px',
    },
    expandContainer: {
        paddingBottom: '40px',
    },
    showDetails: {
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
}));
export default useStyles;
