import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    addEditOccasionsModal: {
        '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
                padding: '50px 40px 0 40px',
                maxWidth: '792px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0 96px 0 96px !important',
                },
            },
        },
        '& header': {
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.isFlyingFlowers ? '96px' : '0px',
            },
        },
    },
}));
export default useStyles;
