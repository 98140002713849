import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useStyles from './EditName.styles';
import TextField from '../TextField/TextField';
import { errorMessages, inputValidationRegex, SiteContext } from '../../utils/common';
import Button from '../Button/Button';
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters)
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(50, errorMessages(50).maxChars)
        .required('Please enter a first name'),
    lastName: Yup.string()
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters)
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(40, errorMessages(40).maxChars)
        .required('Please enter a last name'),
});
const EditName = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { firstName, lastName, onSubmit, onCancel } = props;
    const initialValues = {
        firstName: firstName,
        lastName: lastName,
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        onSubmit,
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { children: "Editing name" }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting, values }) => (_jsx(Form, { children: _jsxs("div", { className: classes.box, children: [_jsx("h2", { children: "Name" }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "firstName", label: "First name", value: values.firstName, placeholder: "e.g. Rose", fullWidth: true, required: true }) }), _jsx("div", { className: classes.input, children: _jsx(TextField, { name: "lastName", label: "Last name", value: values.lastName, placeholder: "e.g. Thorne", fullWidth: true, required: true }) }), _jsxs("div", { className: classes.buttons, children: [_jsx(Button, { variant: "secondary", thin: true, fullWidth: true, title: "Save name", className: classes.saveButton, isSubmit: true, disabled: isSubmitting, icon: isFlyingFlowers ? 'check' : undefined }), _jsx(Button, { variant: "tertiary", onClick: onCancel, thin: true, fullWidth: true, icon: isFlyingFlowers ? 'close' : undefined, title: "Cancel - discard changes" })] })] }) })) }))] }));
};
export default EditName;
