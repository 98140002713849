import { jsx as _jsx } from "react/jsx-runtime";
import { useRouter } from 'next/router';
import useStyles from './NoSearchResultsHeader.styles';
import { decodeQueryParam } from '../../utils/common';
import { useLoaded } from '../MainContent/useComponentLoading';
const NoSearchResultsHeader = (props) => {
    var _a;
    const { classes } = useStyles();
    const { searchBeforeText, searchAfterText = '' } = props;
    const router = useRouter();
    const { query = '' } = (_a = router === null || router === void 0 ? void 0 : router.query) !== null && _a !== void 0 ? _a : {};
    const noSearchTitle = `${searchBeforeText} \u201C${decodeQueryParam(query)}\u201D ${searchAfterText}`;
    useLoaded(props);
    return (_jsx("h1", { className: classes.title, "data-type": "NoSearchResultsHeader", children: noSearchTitle }));
};
export default NoSearchResultsHeader;
