import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Carousel from '../Carousel/Carousel';
import CarouselDots from '../Carousel/CarouselDots/CarouselDots';
import useStyles from './GenericGalleryCarousel.styles';
import clsx from 'clsx';
import Modal from '../Modal/Modal';
import { useMediaQuery } from 'react-responsive';
import MediaZoomInGallery from '../MediaZoomInGallery/MediaZoomInGallery';
import { useTheme } from '@mui/material';
const GenericGalleryCarousel = (props) => {
    const { items, ItemComponent, isFromRelatedContentGallery, isFromMediaGallery, componentProps } = props;
    const carouselRef = React.createRef();
    const { classes } = useStyles();
    const theme = useTheme();
    const { sm, md, xl } = theme.breakpoints.values;
    const [isOpen, setIsOpen] = useState(false);
    const [mediaGalleryItems, setMediaGalleryItems] = useState(items);
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const isLargeDesktop = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.lg}px)` });
    const handleModalOpen = () => {
        setIsOpen(!isOpen);
    };
    const handleMediaCardClick = (selectedImageIndex) => {
        let sortedMedia = items;
        if ((items === null || items === void 0 ? void 0 : items.length) > 1) {
            const beforeSelectedMediaList = items.slice(0, selectedImageIndex);
            const afterSelectedMediaList = items.slice(selectedImageIndex, items.length);
            sortedMedia = [...afterSelectedMediaList, ...beforeSelectedMediaList];
        }
        setMediaGalleryItems(sortedMedia);
        handleModalOpen();
    };
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: isFromMediaGallery ? 3 : 4,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 3,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 2,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: isOpen, setOpen: () => handleModalOpen(), fullScreen: isMobile, fullWidth: true, maxWidth: isLargeDesktop ? 'lg' : 'md', className: classes.zoomInModal, isMediaGallery: true, title: '', children: _jsx(MediaZoomInGallery, { contentItems: mediaGalleryItems }) }), _jsx(Carousel, { containerClass: clsx(classes.carousel, isFromRelatedContentGallery ? classes.adjustHeadingMargin : '', isFromMediaGallery ? classes.mediaCarousel : ''), sliderClass: classes.slider, responsive: responsive, arrows: false, autoPlay: false, itemClass: clsx(classes.carouselItem, isFromMediaGallery ? classes.mediaGalleryItem : ''), ssr: true, showDots: true, renderDotsOutside: true, infinite: true, customDot: _jsx(CarouselDots, { itemsLength: items.length - 1, nextSlide: moveToNextSlide, prevSlide: moveToPreviouSlide, disableDotsClick: isFromRelatedContentGallery }), ref: carouselRef, carouselContainerClass: classes.carouselContainer, children: items.map((item, index) => (_jsx(ItemComponent, Object.assign({ selectedIndex: index }, item, { handleMediaCardClick: handleMediaCardClick }, componentProps), `${index}_${item.name}`))) })] }));
};
export default GenericGalleryCarousel;
