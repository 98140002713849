import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { calculateSecondaryBadgeTitleLength, getSpotLightProduct } from '../../utils/common';
import useStyles from './MegaNavMobile.styles';
import MobileNavMultiLinkGroup from './MobileNavMultiLinkGroup';
import MobileNavSingleLinkGroup from './MobileNavSingleLinkGroup';
import MobileNavQuickLinks from './MobileNavQuickLinks';
import MobileTopLevelLink from './MobileTopLevelLink';
import MobileSubmenuLink from './MobileSubmenuLink';
import MobileBackToMenu from './MobileBackToMenu';
const MegaNavMobile = ({ menuItems, onLinkSelection, iconPosition, quickLinks, sendMenuDrillDownAnalytics }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { classes } = useStyles();
    const [navLocation, setNavLocation] = useState([]);
    const [navDirection, setNavDirection] = useState('right');
    const [productsList, setProductsList] = useState();
    const changeMenu = (action, index, label) => {
        setNavLocation(action === 'forward' ? [...navLocation, index] : navLocation.slice(0, -1));
        setNavDirection(action === 'back' ? 'right' : 'left');
        label && (sendMenuDrillDownAnalytics === null || sendMenuDrillDownAnalytics === void 0 ? void 0 : sendMenuDrillDownAnalytics(label));
    };
    const currentMenuItem = menuItems[navLocation[0]];
    const currentLinkGroup = currentMenuItem === null || currentMenuItem === void 0 ? void 0 : currentMenuItem.linkGroups[navLocation[1]];
    const maxCols = 4;
    const maxNumberInCol = 8;
    const chunkBy = (_b = (_a = currentMenuItem === null || currentMenuItem === void 0 ? void 0 : currentMenuItem.linkGroups) === null || _a === void 0 ? void 0 : _a.reduce((largest, group) => Math.max(largest, Math.ceil(group.links.length / Math.ceil(group.links.length / maxNumberInCol))), 0)) !== null && _b !== void 0 ? _b : maxNumberInCol;
    const linkCols = (_d = (_c = currentMenuItem === null || currentMenuItem === void 0 ? void 0 : currentMenuItem.linkGroups) === null || _c === void 0 ? void 0 : _c.reduce((cols, group) => cols + Math.ceil(group.links.length / chunkBy), 0)) !== null && _d !== void 0 ? _d : 0;
    const menuSpotLightProduct = (_e = currentMenuItem === null || currentMenuItem === void 0 ? void 0 : currentMenuItem.spotlightProducts) !== null && _e !== void 0 ? _e : [];
    const isAllSingleSpotlightBanner = menuSpotLightProduct === null || menuSpotLightProduct === void 0 ? void 0 : menuSpotLightProduct.every((spotlightProducts) => spotlightProducts.columnWidth === '1');
    const spotlightProducts = (_g = (_f = getSpotLightProduct(linkCols, isAllSingleSpotlightBanner, menuSpotLightProduct)) === null || _f === void 0 ? void 0 : _f.slice(0, Math.max(maxCols - linkCols, 0))) !== null && _g !== void 0 ? _g : [];
    const products = (_h = currentMenuItem === null || currentMenuItem === void 0 ? void 0 : currentMenuItem.products) !== null && _h !== void 0 ? _h : [];
    const spotlightDoubleBanner = spotlightProducts;
    const setBadgeLineHeight = useCallback(() => {
        const hasProductBadge = products.some((product) => { var _a; return !!((_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge); });
        if (products.length <= 1 || !hasProductBadge) {
            setProductsList(products);
        }
        else {
            const heightOfBadge = calculateSecondaryBadgeTitleLength(products);
            const productsData = products.map((product) => (Object.assign(Object.assign({}, product), { hasProductBadge,
                heightOfBadge })));
            setProductsList(productsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(products)]);
    useEffect(() => {
        window.addEventListener('resize', setBadgeLineHeight);
        return () => window.removeEventListener('resize', setBadgeLineHeight);
    }, [setBadgeLineHeight]);
    useEffect(() => {
        if (JSON.stringify(products) !== JSON.stringify(productsList)) {
            setBadgeLineHeight();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(products), setBadgeLineHeight]);
    const hasCombinationSpotlightCards = (spotlightDoubleBanner === null || spotlightDoubleBanner === void 0 ? void 0 : spotlightDoubleBanner.length) === 2 &&
        ((_j = spotlightDoubleBanner.filter((_, _index, array) => {
            const columnWidth1Exists = array.some((el) => el.columnWidth === '1');
            const columnWidth2Exists = array.some((el) => el.columnWidth === '2');
            return columnWidth1Exists && columnWidth2Exists;
        })) === null || _j === void 0 ? void 0 : _j.length);
    const isAllSingleSpotlightCards = spotlightDoubleBanner === null || spotlightDoubleBanner === void 0 ? void 0 : spotlightDoubleBanner.every((banner) => banner.columnWidth === '1');
    return (_jsxs("div", { className: classes.navContainer, children: [(navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) ? _jsx(MobileBackToMenu, { changeMenu: changeMenu }) : null, !(navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) && (quickLinks === null || quickLinks === void 0 ? void 0 : quickLinks.length) ? (_jsx(MobileNavQuickLinks, { quickLinks: quickLinks, iconPosition: iconPosition, navDirection: navDirection, onLinkSelection: onLinkSelection })) : null, !(navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) ? (_jsx(MobileTopLevelLink, { menuItems: menuItems, changeMenu: changeMenu, navDirection: navDirection })) : null, (navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) === 1 && currentMenuItem.linkGroups.length > 1 ? (_jsx(MobileNavMultiLinkGroup, { navDirection: navDirection, spotlightDoubleBanner: spotlightDoubleBanner, productsList: productsList, currentMenuItem: currentMenuItem, changeMenu: changeMenu, isAllSingleSpotlightCards: isAllSingleSpotlightCards, hasCombinationSpotlightCards: !!hasCombinationSpotlightCards, setBadgeLineHeight: setBadgeLineHeight })) : null, (navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) === 1 && currentMenuItem.linkGroups.length === 1 ? (_jsx(MobileNavSingleLinkGroup, { navDirection: navDirection, spotlightDoubleBanner: spotlightDoubleBanner, productsList: productsList, currentMenuItem: currentMenuItem, isAllSingleSpotlightCards: isAllSingleSpotlightCards, hasCombinationSpotlightCards: !!hasCombinationSpotlightCards, setBadgeLineHeight: setBadgeLineHeight, onLinkSelection: onLinkSelection })) : null, (navLocation === null || navLocation === void 0 ? void 0 : navLocation.length) === 2 ? (_jsx(MobileSubmenuLink, { currentLinkGroup: currentLinkGroup, onLinkSelection: onLinkSelection, navDirection: navDirection })) : null] }));
};
export default MegaNavMobile;
