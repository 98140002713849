import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    filterCurrentRefinements: {
        minHeight: '48px',
        marginBottom: spacing.small,
        '& ul': {
            display: 'flex',
            flexWrap: 'wrap',
        },
        '& li': {
            margin: `0 ${spacing.xs} ${spacing.xs} 0`,
        },
    },
    noFilterText: Object.assign(Object.assign({}, theme.typography.small_regular), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
}));
export default useStyles;
