import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import ReminderCard from '../ReminderCard/ReminderCard';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './MyAccountReminder.styles';
const MyAccountReminder = (props) => {
    const { remindersList, openAddRemindersModal, remindersTotalCount } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const remindersCount = !!remindersList && remindersTotalCount ? `(${remindersTotalCount})` : '';
    return (_jsxs("div", { children: [_jsx("h2", { className: classes.reminderTitle, children: `Reminders ${isFlyingFlowers ? '' : remindersCount}` }), (remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) ? (_jsx("div", { className: classes.reminderCardContainer, children: remindersList === null || remindersList === void 0 ? void 0 : remindersList.map((reminder, index) => {
                    return _jsx(ReminderCard, { reminder: reminder, showActions: false }, index);
                }) })) : (_jsxs("div", { className: classes.noReminderCard, children: [_jsxs("h3", { className: classes.noReminderTitle, children: ["You don\u2019t have any ", isFlyingFlowers ? '' : 'occasion', " reminders set yet"] }), _jsx("p", { className: classes.noReminderDescription, children: isFlyingFlowers
                            ? 'Make sure you never forget a special occasion by setting a reminder'
                            : `Don't miss those special occasions! Set up a reminder and we'll give you a nudge when it's time to send something lovely.` }), _jsx("div", { children: _jsx(RoundButton, { ariaLabel: `Add new ${isFlyingFlowers ? '' : 'occasion'} reminder`, icon: "add", label: `Add new ${isFlyingFlowers ? '' : 'occasion'} reminder`, onClick: () => openAddRemindersModal(true), className: classes.addOccasionButton }) })] })), (remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) ? (_jsx("div", { className: classes.viewEditButton, children: _jsx(Button, { variant: "tertiary", thin: true, fullWidth: true, title: "View / edit all reminders", href: `/account/reminders` }) })) : null] }));
};
export default MyAccountReminder;
