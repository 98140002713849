import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Link from 'next/link';
import React, { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Icon from '../Icon/Icon';
import useStyles from './NavLink.styles';
const NavLink = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { label, url, icon, highlight, nofollow, noFollow, goForward, goBackward, newWindow, onClick, isFromMegaNavMobile, quickLinkIconPosition, isFromQuickLinks, } = props;
    const { classes } = useStyles({ isFromMegaNavMobile });
    const isIconPositionRight = quickLinkIconPosition === 'RIGHT';
    const isIconPositionLeft = quickLinkIconPosition === 'LEFT';
    const displayIconLeft = (!quickLinkIconPosition && !isFromQuickLinks) || isIconPositionLeft;
    const NavChildren = () => (_jsxs(_Fragment, { children: [goBackward ? (_jsx(Icon, { icon: isFlyingFlowers ? 'arrow_back' : 'arrow_back_ios', className: classes.backwardIcon, fontSize: "inherit" })) : null, displayIconLeft && icon ? (_jsx(Icon, { icon: icon, className: clsx(classes.backwardIcon, isIconPositionLeft ? classes.quickLinkIcon : '') })) : null, isFromMegaNavMobile ? (_jsx("p", { className: clsx(classes.label, classes.adjustLabel, isIconPositionRight ? classes.quickLinkLabel : ''), children: label })) : (_jsx("span", { className: classes.label, children: label })), isIconPositionRight && icon ? (_jsx(Icon, { icon: icon, className: clsx(classes.backwardIcon, classes.quickLinkPosition, classes.quickLinkIcon) })) : null, goForward && (_jsx(Icon, { icon: isFlyingFlowers ? 'arrow_forward' : 'arrow_forward_ios', className: classes.forwardIcon, fontSize: "inherit" }))] }));
    const LinkContents = React.forwardRef(({ onClick, href }, ref) => (_jsx("a", Object.assign({ href: href, onClick: onClick, ref: ref }, (url && { href: url }), ((nofollow || noFollow) && { rel: 'nofollow' }), (newWindow && { target: '_blank' }), { children: _jsx(NavChildren, {}) }))));
    LinkContents.displayName = 'LinkContents';
    return (_jsx("li", Object.assign({ className: clsx(classes.navLink, goBackward ? classes.navLinkBack : '', highlight ? classes.highlight : '', !url && !onClick ? classes.inactive : '', icon && isIconPositionRight && isFromMegaNavMobile ? classes.quickNavLink : '') }, (onClick && { onClick }), { "data-testid": label, children: url ? (_jsx(Link, { href: url, passHref: true, legacyBehavior: true, children: _jsx(LinkContents, {}) })) : (_jsx(NavChildren, {})) })));
};
export default NavLink;
