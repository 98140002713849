import { makeStyles } from 'tss-react/mui';
import { formStyles } from '../../styles/jss/form';
import { link } from '../../styles/jss/utils';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => (Object.assign(Object.assign({}, formStyles(theme)), { receiveOffersCheckbox: {
        marginTop: `${spacing.medium} !important`,
        '& p': Object.assign(Object.assign({}, theme.typography.xxs_regular), { fontWeight: fontWeights.medium, marginTop: '22px', marginBottom: spacing.small, [theme.breakpoints.up('md')]: {
                marginBottom: spacing.medium,
            } }),
    }, chooseText: Object.assign(Object.assign({}, theme.typography.small_bold), { margin: `${spacing.small} 0`, fontWeight: `${fontWeights.regular} !important`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }), preferencesOptions: {
        '& li': {
            marginBottom: spacing.xs,
        },
    }, preferencesContainer: {
        '& p': Object.assign(Object.assign({}, theme.typography.small_bold), { marginTop: '20px', [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }),
    }, privacyInformation: {
        marginTop: `${spacing.xxl} !important`,
        '& h2': Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: `${spacing.medium} !important`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }),
        '& p': Object.assign(Object.assign({}, theme.typography.small_bold), { fontWeight: `${fontWeights.regular} !important`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }),
    }, link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, fontWeight: `${fontWeights.regular} !important`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }) })));
export default useStyles;
