import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import useStyles from './AvailabilitySelect.styles';
import clsx from 'clsx';
const AvailabilitySelect = (props) => {
    const { classes } = useStyles();
    const { icon, title, onClick } = props;
    return (_jsx("div", { children: _jsxs("button", { onClick: onClick, className: classes.selectContainer, children: [_jsx(Icon, { className: clsx(classes.iconSize, classes.iconColor), icon: icon }), _jsx("div", { className: clsx(classes.title), children: title }), _jsx(Icon, { className: clsx(classes.iconPosition, classes.helperIconSize, classes.helperIconColor), icon: "expand_more" })] }) }));
};
export default AvailabilitySelect;
