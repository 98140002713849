import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            alignItems: 'unset',
            margin: '0 -12px',
        },
    },
}));
export default useStyles;
