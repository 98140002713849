import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from '../SalesBadge/SalesBadge.styles';
import clsx from 'clsx';
const SalesBadge = (props) => {
    const { backgroundColor, contentLine1, contentLine2, largeFontCheck, isFromProductCard } = props;
    const { classes } = useStyles();
    const variantClass = backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase();
    const isLargeContent1 = largeFontCheck === 'contentLine1';
    const isLargeContent2 = largeFontCheck === 'contentLine2';
    const line1LargeFont = isLargeContent1 && isFromProductCard ? 'plpLargeText' : isLargeContent1 && !isFromProductCard ? 'largeText' : '';
    const line2LargeFont = isLargeContent2 && isFromProductCard ? 'plpLargeText' : isLargeContent2 && !isFromProductCard ? 'largeText' : '';
    const smallTextLine1 = !isLargeContent1 && isFromProductCard ? 'plpSmallText' : 'smallText';
    const smallTextLine2 = !isLargeContent2 && isFromProductCard ? 'plpSmallText' : 'smallText';
    const line1 = isLargeContent1 ? contentLine1 === null || contentLine1 === void 0 ? void 0 : contentLine1.slice(0, 4) : contentLine1 === null || contentLine1 === void 0 ? void 0 : contentLine1.slice(0, 10);
    const line2 = isLargeContent2 ? contentLine2 === null || contentLine2 === void 0 ? void 0 : contentLine2.slice(0, 4) : contentLine2 === null || contentLine2 === void 0 ? void 0 : contentLine2.slice(0, 10);
    return (_jsxs("div", { className: clsx(classes.container, variantClass ? classes[variantClass] : '', isFromProductCard ? classes.plpContainer : ''), children: [contentLine1 && (_jsx("div", { className: clsx(classes[smallTextLine1], classes[line1LargeFont]), children: line1 })), contentLine2 && (_jsx("div", { className: clsx(classes[smallTextLine2], classes[line2LargeFont]), children: line2 }))] }));
};
export default SalesBadge;
