import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((_theme, _params, classes) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        '& video::-webkit-media-controls-fullscreen-button': {
            display: 'none',
        },
        '& video::-webkit-media-controls-play-button': {
            display: 'none',
        },
    },
    icon: {
        fontSize: `100px`,
    },
    controlsContainer: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        [`& .${classes.icon}`]: {
            fontSize: '6.25rem',
            color: colors.supplementary.white,
            cursor: 'pointer',
            marginRight: 0,
            '& span': {
                fontSize: 'inherit',
            },
        },
    },
}));
export default useStyles;
