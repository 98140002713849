import { makeStyles } from 'tss-react/mui';
import { maxHeightBelowHeader } from '../../styles/jss/utils';
import { colors, hideScrollbars, spacing } from './../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    navContainer: maxHeightBelowHeader(theme, Object.assign(Object.assign({ overflowX: 'hidden', overflowY: 'scroll', width: '100%' }, hideScrollbars), { paddingBottom: spacing.xxl, '& ul': {
            '& li': {
                padding: `0 0 0 20px`,
                '& a': {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        } })),
    productCards: {
        display: 'flex',
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.supplementary.white,
        justifyContent: 'space-between',
        padding: spacing.small,
    },
    spotlightProductCards: {
        display: 'flex',
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.supplementary.white,
        justifyContent: 'center',
        padding: `${spacing.large} ${spacing.medium}`,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        '& li': {
            padding: '0 !important',
        },
    },
    spotLightproductCard: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'unset',
        },
    },
    doubleSpotLightCard: {
        width: '100%',
        marginBottom: '10px',
    },
    singleSpotLightCard: {
        width: `calc(50% - 20px)`,
        marginBottom: '10px',
        marginRight: '10px',
        [theme.breakpoints.up('sm')]: {
            width: `calc(33% - 20px)`,
        },
    },
    productCard: {
        display: 'flex',
        width: `calc(50% - ${spacing.xs})`,
        padding: '0 !important',
    },
    navLinkDummy: {
        height: '8rem',
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.supplementary.white,
    },
    combinationSingleCardCard: {
        width: '140px',
        marginRight: '10px',
        marginBottom: '10px',
        display: 'inline-block',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 'initial',
        },
    },
    combinationDoubleCardCard: {
        width: '304px',
        display: 'inline-block',
        marginRight: '10px',
    },
    twoSingleSpotLightCard: {
        width: 'initial',
    },
    combinationCardContainer: {
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'unset',
        },
    },
    idpProductsList: {
        justifyContent: 'center',
    },
}));
export default useStyles;
