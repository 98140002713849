import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import useStyles from './DayPicker.styles';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import SelectionButton from '../SelectionButton/SelectionButton';
import DatePicker from '../DatePicker/DatePicker';
import { convertDateFormat, filterSelectedDates, SiteContext } from '../../utils/common';
import addDays from 'date-fns/addDays';
const DayPicker = ({ setSelectedDate, selectedDate: initialSelectedDate, isCalendarPicked, renderSelectedDayDetails, calendarLoading, currentStep, isChooseAnotherDate, }) => {
    const { classes } = useStyles();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const selectedDate = initialSelectedDate ? convertDateFormat(initialSelectedDate) : undefined;
    const checkIfDateCardSelectedAndSetDate = (dateVal, isCalendarPicked) => {
        setSelectedDate(dateVal, isCalendarPicked);
    };
    const [dates, setDates] = useState();
    const { deliverySettings, isFlyingFlowers = false, calendarDates } = useContext(SiteContext);
    const { availableSundays, bankHolidays } = calendarDates || {};
    const filterDate = useCallback((date) => filterSelectedDates(date, isFlyingFlowers, bankHolidays, availableSundays, deliverySettings), [availableSundays, bankHolidays, deliverySettings, isFlyingFlowers]);
    const filteringDate = useCallback((date) => {
        let dateVal = date;
        if (filterDate(date)) {
            const nextDate = addDays(dateVal, 1);
            dateVal = filteringDate(nextDate);
        }
        return dateVal;
    }, [filterDate]);
    useEffect(() => {
        let currentDate = new Date();
        const dates = new Array(3).fill('').map((_, index) => {
            const dateVal = filteringDate(currentDate);
            currentDate = addDays(dateVal, 1);
            return dateVal;
        });
        setDates(dates);
    }, [filteringDate]);
    useEffect(() => {
        if (isChooseAnotherDate)
            setCalendarOpen(true);
    }, [isChooseAnotherDate]);
    return (_jsxs(_Fragment, { children: [!calendarOpen && (_jsxs("div", { className: classes.dayPicker, children: [dates === null || dates === void 0 ? void 0 : dates.slice(0, 3).map((date, i) => {
                        const formatedDate = convertDateFormat(format(date, 'yyyy-MM-dd'));
                        const filterdDate = filteringDate(formatedDate);
                        const formattedDay = format(filterdDate, 'EEEE');
                        const formattedDate = format(filterdDate, 'do MMM');
                        return (_jsx(SelectionButton, { title: isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : formattedDay, onClick: () => checkIfDateCardSelectedAndSetDate(format(date, 'yyyy-MM-dd'), false), supplementaryDate: formattedDate, selected: selectedDate && !isCalendarPicked && isSameDay(date, new Date(selectedDate)) }, formattedDate));
                    }), _jsx(SelectionButton, Object.assign({ title: "More" }, (selectedDate &&
                        isCalendarPicked && {
                        supplementaryDate: format(new Date(selectedDate), 'do MMM'),
                    }), { icon: "calendar_today", selected: selectedDate && isCalendarPicked, onClick: () => {
                            setCalendarOpen(true);
                        } }))] })), _jsx(DatePicker, { datePickerTitle: "Choose a delivery date", isOpen: calendarOpen, setOpen: setCalendarOpen, minDate: new Date(), value: selectedDate ? new Date(selectedDate) : undefined, setValue: (date) => setSelectedDate(format(date, 'yyyy-MM-dd'), true), filterDate: filterDate, renderSelectedDayDetails: renderSelectedDayDetails, calendarLoading: calendarLoading, hideInput: true, isSaveOnClose: true, noModal: false }, (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.toDateString()) || '')] }));
};
export default DayPicker;
