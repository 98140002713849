import { makeStyles } from 'tss-react/mui';
import { addWidthInner, widthOuter } from '../../styles/jss/utils';
import { colors, spacing } from './../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme, _params, classes) => ({
    topLevelNav: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        gap: '24px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '100%',
        minWidth: '400px',
        flexGrow: 1,
        zIndex: 100,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginLeft: '17px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '40px',
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: '64px',
            // the following is temporary due to beta only launching with 1 category - will change to justify-content: center
            '& > li': {
                padding: `${spacing.small} ${spacing.medium}`,
                alignItems: 'center',
            },
        },
        '& > li': {
            padding: `${spacing.small} 10px`,
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            [theme.breakpoints.between('sm', 'xl')]: {
                padding: `22px 12px`,
            },
            [theme.breakpoints.down('xl')]: {
                padding: `22px 12px`,
            },
            [theme.breakpoints.up('md')]: {
                padding: `12px 0`,
                maxWidth: '93px',
            },
            [theme.breakpoints.up('lg')]: {
                padding: `${spacing.medium} 12px`,
                maxWidth: '108.43px',
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '152.57px',
            },
            borderTop: theme.isFlyingFlowers ? `12px solid transparent` : `10px solid transparent`,
            borderBottom: theme.isFlyingFlowers ? `12px solid transparent` : `10px solid transparent`,
            display: 'flex',
            height: '100%',
            '&:focus': {
                'outline-offset': '-5px',
            },
            '&:hover': {
                cursor: 'default',
                color: theme.isFlyingFlowers ? colors.supplementary.black : colors.primary.gold3,
                borderBottom: theme.isFlyingFlowers
                    ? `12px solid ${colors.wildExotic[400]}`
                    : `10px solid ${colors.primary.gold3}`,
                transition: 'border-bottom 1.5s ease,color 1.5s ease',
                '-webkit-transition': 'border-bottom 1.5s ease,color 1.5s ease',
            },
        },
        '& a': {
            '&:hover': {
                cursor: 'pointer',
                color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold3,
                transition: 'border-bottom 1.5s ease,color 1.5s ease',
                '-webkit-transition': 'border-bottom 1.5s ease,color 1.5s ease',
            },
        },
    },
    megaDropDown: Object.assign(Object.assign({}, widthOuter), { display: 'none', left: 0, position: 'absolute', justifyContent: 'center', backgroundColor: colors.supplementary.white, top: '100%', width: '100%', height: 'fit-content', borderTop: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey2}`, cursor: 'default', boxShadow: `0px 8px 8px rgba(60, 60, 59, 0.12), 0px 16px 16px rgba(60, 60, 59, 0.32)` }),
    inFocus: {
        display: 'flex',
    },
    megaDropDownInner: addWidthInner(theme, {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.isFlyingFlowers ? '20px' : (_params === null || _params === void 0 ? void 0 : _params.spotlightProducts) ? spacing.xl : spacing.large,
        paddingBottom: theme.isFlyingFlowers ? spacing.large : spacing.xl,
        // logo width plus inner padding - needs to change per logo width breakpoint
        [theme.breakpoints.up('md')]: {
            paddingLeft: '140px',
            paddingTop: (_params === null || _params === void 0 ? void 0 : _params.spotlightProducts) && theme.isFlyingFlowers ? spacing.large : spacing.xl,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '196px',
            paddingTop: (_params === null || _params === void 0 ? void 0 : _params.spotlightProducts) && theme.isFlyingFlowers ? spacing.medium : spacing.xl,
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '196px',
            paddingTop: `${spacing.xl} !important`,
        },
    }),
    heading: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.xxs_bold)), theme.typographySpacing.none), { fontSize: theme.isFlyingFlowers ? '16px' : '14px !important', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    l2heading: Object.assign(Object.assign({}, theme.typography.small_bold), { paddingBottom: theme.isFlyingFlowers ? '4px' : spacing.small, paddingTop: theme.isFlyingFlowers ? '4px' : spacing.small, [`&.${classes.colLast}`]: {
            display: 'none',
            [theme.breakpoints.up('xl')]: {
                display: 'block',
            },
        } }),
    removeExtraPadding: {
        paddingTop: '0 !important',
    },
    l2Lists: {
        textAlign: 'left',
        color: colors.supplementary.black,
        '& li > a, & li > span.no-link': {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingBottom: theme.isFlyingFlowers ? '12px' : spacing.small,
            paddingTop: theme.isFlyingFlowers ? '12px' : spacing.small,
            whiteSpace: 'normal',
        },
    },
    l3ListContainer: {
        display: 'flex',
        '& ul': {
            color: colors.supplementary.black,
            '& li': {
                marginBottom: spacing.medium,
            },
        },
    },
    l3Chunk: {},
    productCard: {
        display: 'flex',
        '& a': {
            height: '100%',
        },
        '& > div': {
            height: '100%',
        },
    },
    productGrid: {
        display: 'flex',
        height: '100%',
    },
    highlight: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.secondary.rose,
    },
    col: {
        width: '222px',
        marginLeft: '24px',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            width: '222px',
            [`.${classes.colx1} &, .${classes.colx2} &, .${classes.colx3} &`]: {
                width: '285px',
                height: '100%',
            },
        },
        [theme.breakpoints.up('xl')]: {
            width: '258px',
            height: '100%',
            marginLeft: spacing.large,
            [`.${classes.colx1} &, .${classes.colx2} &, .${classes.colx3} &, .${classes.colx4} &`]: {
                width: '330px',
                height: '100%',
            },
            [`&.${classes.colLast}`]: {
                display: 'block',
            },
        },
        [`&.${classes.colFirst}`]: {
            marginLeft: '0px !important',
        },
    },
    spotlightCol: {
        width: '304px',
        marginLeft: '10px',
        [theme.breakpoints.up('md')]: {
            width: '464px',
        },
        [theme.breakpoints.up('xl')]: {
            width: '552px',
            marginLeft: spacing.large,
            [`&.${classes.colLast}`]: {
                display: 'block',
            },
        },
        [`&.${classes.colFirst}`]: {
            marginLeft: '0px !important',
        },
    },
    singleSpotlightCol: {
        width: 'initial',
        marginLeft: spacing.medium,
        [`&.${classes.colLast}`]: {
            display: 'block',
        },
        [`&.${classes.colFirst}`]: {
            marginLeft: '0px !important',
        },
        '&:not(:first-child)': {
            marginLeft: '10px',
        },
    },
    doubleColumnsAlignment: {
        marginLeft: spacing.medium,
    },
    spotLightCardPadding: {
        [theme.breakpoints.between(1024, 1150)]: {
            paddingLeft: '48px',
        },
    },
    firstSpotLight: {
        marginLeft: `${spacing.medium} !important`,
    },
    colFirst: {},
    colLast: {
        display: 'none',
    },
    colx1: {},
    colx2: {},
    colx3: {},
    colx4: {},
    colx5: {},
}));
export default useStyles;
