import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { processImageUrl } from '../../utils/image';
import useStyles from './ContentLinkItem.styles';
import Carousel from '../Carousel/Carousel';
import Image from '../Image/Image';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';
import { SiteContext } from '../../utils/common';
import { useTheme } from '@mui/material';
const ContentLinkItem = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { images, title, description } = props;
    const { linkItem } = props;
    const carouselRef = React.createRef();
    const theme = useTheme();
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const onCardHover = () => {
        if (carouselRef.current && isLargerViewport) {
            carouselRef.current.next();
        }
    };
    const responsive = {
        all: {
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            partialVisibilityGutter: 24,
        },
    };
    const sizes = [
        {
            breakpoint: 'sm',
            query: 'max-width',
            width: '312px',
        },
        {
            width: '520px',
        },
    ];
    return (_jsx("div", { className: classes.container, children: _jsx(Link, { href: linkItem.url || '', passHref: true, legacyBehavior: true, children: _jsxs("a", { children: [_jsx("div", { onMouseEnter: onCardHover, children: !!(images === null || images === void 0 ? void 0 : images.length) && (_jsx(Carousel, { ref: carouselRef, responsive: responsive, showDots: !isLargerViewport, autoPlay: false, arrows: false, partialVisible: true, infinite: true, renderDotsOutside: true, children: images.map((image, index) => (_jsx(Image, { imageUrl: processImageUrl(image.url, { w: 520, aspect: isFlyingFlowers ? '3:2' : '1:1' }, [
                                    '$poi-square$',
                                ]), altText: image.altText, sizes: sizes, width: 1, height: 1, className: classes.contentImage, loading: "lazy" }, index))) })) }), _jsxs("div", { className: classes.titleContainer, children: [title && _jsx("h2", { className: classes.contentTitle, children: title }), description && _jsx("div", { className: classes.contentDescription, children: description })] })] }) }) }));
};
export default ContentLinkItem;
