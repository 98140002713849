import { jsx as _jsx } from "react/jsx-runtime";
import { Children, cloneElement, isValidElement, } from 'react';
import clsx from 'clsx';
import useStyles from './MainContent.styles';
import { useComponentLoading } from './useComponentLoading';
const MainContent = (props) => {
    const { classes } = useStyles();
    const counter = {};
    const centreContent = props.centreContent;
    return (_jsx("div", { "data-component": "MainContent", className: clsx(classes.mainContent, centreContent === true && classes.centreContent, centreContent === 'small' && classes.centreContentSmall, centreContent === 'extraSmall' && classes.centreContentExtraSmall), "data-hj-ignore-attributes": true, children: Children.map(props.children, (child, index) => {
            var _a, _b;
            if (!isValidElement(child)) {
                return child;
            }
            const type = ((_b = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.component) === null || _b === void 0 ? void 0 : _b['uiType']) || 'unknown';
            counter[type] = (counter[type] || 0) + 1;
            const dataTestId = `${type}-${counter[type]}`;
            return _jsx(MainContentComponent, { dataTestId, type, index, component: child });
        }) }));
};
const MainContentComponent = ({ component, dataTestId, type, index }) => {
    const { classes } = useStyles();
    const isMyAccountTitle = type === 'MyAccountWelcomeTitle';
    const isMyAccountCards = type === 'MyAccountCards';
    const isMyAccountReminders = type === 'MyAccountReminders' || type === 'MyAccountRecentOrdersAndDeliveryPass';
    const isTitle = type === 'Title' || type === 'NoSearchResultsHeader';
    const topSpacing = !index && !['HeroBanner', 'HomeHeroBanner', 'FfHomeHeroBannerGallery'].includes(type);
    const homeGallerySpacing = !index && type === 'FfHomeHeroBannerGallery';
    const linkGallery = type === 'LinkGallery';
    const [isLoading, onLoaded] = useComponentLoading(type);
    return (_jsx("div", { "data-testid": dataTestId, "data-loading": isLoading || undefined, className: clsx(classes.componentContainer, topSpacing ? classes.topSpacing : '', homeGallerySpacing ? classes.homeHeroGallerySpacing : '', linkGallery ? classes.linkComponentContainer : '', isMyAccountTitle ? classes.myAccountTitlePadding : '', isMyAccountCards ? classes.myAccountCardPadding : '', isTitle ? classes.titlePadding : '', isMyAccountReminders ? classes.myAccountRemindersPadding : ''), children: cloneElement(component, { onLoaded }) }, type));
};
export default MainContent;
