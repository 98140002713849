import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles()((theme, _params, classes) => ({
    highlight: {
        color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.secondary.rose,
    },
    navLink: {
        height: '3.25rem',
        padding: `0 ${spacing.small}`,
        borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey3}`,
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.supplementary.white,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'space-between',
        '& a': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        '&:hover': {
            backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey2,
        },
        [`&.${classes.inactive}`]: {
            cursor: 'default',
            backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey2,
            '&:hover': {
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey2,
            },
        },
    },
    quickNavLink: {
        justifyContent: 'unset',
        '& a': {
            justifyContent: 'unset !important',
        },
    },
    label: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: 0 }),
    inactive: {},
    navLinkBack: {
        borderBottom: theme.isFlyingFlowers ? `1px solid ${colors.grey.grey3}` : undefined,
        justifyContent: 'initial',
    },
    backwardIcon: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
        paddingRight: spacing.xs,
        fontSize: spacing.medium,
    },
    forwardIcon: {
        color: theme.isFlyingFlowers ? colors.black[500] : colors.grey.grey5,
        fontSize: spacing.medium,
        marginRight: _params.isFromMegaNavMobile ? spacing.small : 0,
    },
    adjustLabel: {
        marginBottom: 0,
        width: '100%',
    },
    quickLinkPosition: {
        paddingRight: 0,
        height: spacing.medium,
        width: spacing.medium,
        marginRight: spacing.small,
        marginLeft: spacing.xs,
    },
    quickLinkLabel: {
        width: 'auto',
        wordBreak: 'break-all',
    },
    quickLinkIcon: {
        '& .MuiIcon-root, & img': {
            fontSize: `${spacing.medium} !important`,
        },
    },
}));
export default useStyles;
