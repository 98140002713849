import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import useStyles from './SpotlightBanner.styles';
import { processImageUrl } from '../../utils/image';
import Image from '../Image/Image';
import clsx from 'clsx';
import { useMobileDeviceCheck } from '../../utils/utilityCustomHooks';
const SpotlightBannerImage = (props) => {
    var _a;
    const { backgroundImage, transparentOverlay, isMobile, mobileBackgroundImage } = props; // To make sure we handle any nulls from graphQL
    const theme = useTheme();
    const { classes } = useStyles();
    const altText = (_a = backgroundImage === null || backgroundImage === void 0 ? void 0 : backgroundImage.altText) !== null && _a !== void 0 ? _a : '';
    const { isMobile: isMobileView } = useMobileDeviceCheck();
    const darknessAttributes = typeof transparentOverlay === 'number' && !isMobile
        ? { cm: 'dark', bg: `rgba(0,0,0, ${Math.round((100 - transparentOverlay) * 2.55)})` }
        : {};
    const bgImage = isMobileView && (mobileBackgroundImage === null || mobileBackgroundImage === void 0 ? void 0 : mobileBackgroundImage.url) ? mobileBackgroundImage : backgroundImage;
    const imageUrl = (bgImage === null || bgImage === void 0 ? void 0 : bgImage.url) &&
        processImageUrl(bgImage.url, Object.assign({ w: theme.breakpoints.values.xl, aspect: '2:1' }, darknessAttributes), ['$poi-square$']);
    const genericBreakpoints = [
        { breakpoint: 'xs', query: 'max-width', w: theme.breakpoints.values.xs, aspect: '1:1' },
        {
            breakpoint: 'sm',
            query: 'max-width',
            w: theme.breakpoints.values.sm,
            aspect: '1:1',
        },
        {
            breakpoint: 'md',
            query: 'max-width',
            w: theme.breakpoints.values.md,
            aspect: '3:2',
        },
        {
            breakpoint: 'lg',
            query: 'max-width',
            w: theme.breakpoints.values.lg,
            aspect: '2:1',
        },
    ];
    const mobileBreakpoints = [
        { breakpoint: 'xs', query: 'max-width', w: theme.breakpoints.values.xs, aspect: '1:1' },
        {
            breakpoint: 'sm',
            query: 'max-width',
            w: theme.breakpoints.values.sm,
            aspect: '1:1',
        },
    ];
    const breakpoints = isMobileView && (mobileBackgroundImage === null || mobileBackgroundImage === void 0 ? void 0 : mobileBackgroundImage.url) ? mobileBreakpoints : genericBreakpoints;
    const images = (bgImage === null || bgImage === void 0 ? void 0 : bgImage.url)
        ? breakpoints.map(({ breakpoint, query, w, aspect }) => ({
            breakpoint,
            query,
            filename: processImageUrl(bgImage.url, Object.assign({ w, aspect }, darknessAttributes), ['$poi-square$']),
        }))
        : undefined;
    return imageUrl ? (_jsx(Image, { className: clsx(classes.image, classes.fullWidth), imageUrl: imageUrl, altText: altText, images: images, loading: "lazy" })) : null;
};
export default SpotlightBannerImage;
