import Script from 'next/script'

/*
 * DO NOT CHANGE THIS CODE WITHOUT GOOD REASON!
 *
 * The functions exported here are responsible for rendering out the IAdvize script
 *
 */
const FF_IADVISE_SITE_ID = 2099
const UK_IADVISE_SITE_ID = 7567

export function renderIAdvizeScript(siteShorthand) {
  const iAdvizeScriptSiteId = siteShorthand === 'ff' ? FF_IADVISE_SITE_ID : UK_IADVISE_SITE_ID
  return iAdvizeScriptSiteId ? (
    <Script
      id="iAdvizeScript"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
        (function() {
          var idz = document.createElement('script');
          idz.type = 'text/plain';
          idz.async = true;
          idz.src = document.location.protocol + '//halc.iadvize.com/iadvize.js?sid=${iAdvizeScriptSiteId}';
          idz.className ="optanon-category-C0003"; 
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(idz, s);
        })();
            `,
      }}
    />
  ) : (
    ''
  )
}
