import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import Select from '../../Select/Select';
import { SiteContext } from '../../../utils/common';
import CheckCircleWrappper from '../../CheckCircle/CheckCircle';
import useStyles from './PCCOccasion.styles';
import * as Yup from 'yup';
import { FormikContext, useField } from 'formik';
export const validationOccasionSchema = Yup.object().shape({
    occasion: Yup.string(),
});
const PCCOccasion = (props) => {
    const { currentStep, step, selectedOccasion, setCurrentStep, setIsShowDayPicker, trackPostCodeCheckerOccasion } = props;
    const { occasions = [] } = useContext(SiteContext);
    const formikContext = useContext(FormikContext);
    const { classes } = useStyles();
    const [, , helper] = useField('occasion');
    const occasionsList = useMemo(() => {
        const postCodeCheckerOccsions = occasions.filter((occasion) => !!occasion.isPostcodeChecker);
        return !!(postCodeCheckerOccsions === null || postCodeCheckerOccsions === void 0 ? void 0 : postCodeCheckerOccsions.length)
            ? postCodeCheckerOccsions.map((occasion) => ({ value: occasion.label, label: occasion.label }))
            : [];
    }, [occasions]);
    const handleChange = (event) => {
        var _a, _b;
        helper.setValue(event.target.value);
        setIsShowDayPicker(true);
        trackPostCodeCheckerOccasion === null || trackPostCodeCheckerOccasion === void 0 ? void 0 : trackPostCodeCheckerOccasion(event.target.value);
        if (!((_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.date))
            setCurrentStep(1);
        else if (!((_b = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _b === void 0 ? void 0 : _b.addressLine1))
            setCurrentStep(2);
    };
    return (_jsx(_Fragment, { children: currentStep === step ? (_jsx("div", { className: classes.occasionContainer, children: _jsxs("div", { children: [_jsx(Box, { sx: { display: { xs: 'block', md: 'none' } }, children: _jsx(Select, { name: "occasion", label: "Select occasion", placeholder: 'Select occasion', items: occasionsList, fullWidth: true, onChange: handleChange, value: selectedOccasion, hideRequiredOptional: true }) }), _jsx(Box, { sx: { display: { zero: 'none', sm: 'block' } }, children: _jsx("div", { className: classes.occasionList, children: occasionsList === null || occasionsList === void 0 ? void 0 : occasionsList.map((occasion, index) => (_jsx(React.Fragment, { children: _jsx(CheckCircleWrappper, { label: occasion.label, name: occasion.label, value: occasion.label, className: classes.occasionItem, type: "radio", onChange: handleChange, checked: selectedOccasion === occasion.label }) }, `guidedNavigation-occasion-${index}`))) }) })] }) })) : null }));
};
export default PCCOccasion;
