import Script from 'next/script'

/*
 * DO NOT CHANGE THIS CODE WITHOUT GOOD REASON!
 *
 * The functions exported here are responsible for rendering out the
 * Real user monitoring as defined in the following page:
 * https://www.debugbear.com/docs/rum/setup#install-the-debugbear-rum-snippet-on-your-website
 *
 * The debugbear site id is supplied by the build process and is specific
 * to each environment.
 *
 */

export function renderDebugbearScript() {
  const debugbearSiteId = getDebugbearSiteId()
  return debugbearSiteId ? (
    <Script
      id="debugbear"
      dangerouslySetInnerHTML={{
        __html: `
            //add Debugbear script
            (function(){var scr=document.createElement("script"); 
            scr.src="https://cdn.debugbear.com/${debugbearSiteId}.js?v=1"; 
            scr.type="text/plain";
            scr.async=true; 
            scr.className ="optanon-category-C0002"; 
            document.head.appendChild(scr);})()
            `,
      }}
    />
  ) : (
    ''
  )
}

/**
 * Get the Debugbear ID. This should always be available in the
 * sever-side code as `process.env.DEBUG_BEAR_ID`, but may also be required when
 * events are fired from the client-side code, where it can be found in
 * process.env.NEXT_PUBLIC_DEBUG_BEAR_ID
 */

function getDebugbearSiteId() {
  return process.env.DEBUG_BEAR_ID || process.env.NEXT_PUBLIC_DEBUG_BEAR_ID
}
