import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        color: colors.black[700],
        backgroundColor: colors.plushBlush[400],
        width: `104px`,
        height: '104px',
        padding: `${spacing.xxs} ${spacing.xs}`,
        cursor: 'default',
        [theme.breakpoints.up('md')]: {
            width: `240px`,
            padding: spacing.small,
            height: '240px',
        },
        maskImage: 'url("/images/roundels/roundel-1.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-1.svg")',
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    plpContainer: {
        [theme.breakpoints.up('md')]: {
            height: '120px',
            width: '120px',
        },
    },
    smallText: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { [theme.breakpoints.between('sm', 'xl')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.none) }),
    plpSmallText: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none) }),
    largeText: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.none), { [theme.breakpoints.between('sm', 'xl')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.none), [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl_bold), theme.typographySpacing.none) }),
    plpLargeText: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.none) }),
    FF_SHARP_YELLOW: {
        backgroundColor: colors.sharpYellow[400],
    },
    FF_PLUSH_BLUSH: {
        backgroundColor: colors.plushBlush[400],
    },
    FF_LAZY_LIME: {
        backgroundColor: colors.lazyLime[400],
    },
}));
export default useStyles;
