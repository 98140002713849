import { useEffect } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import { useSearchNBHitsContext } from '../components/SearchContext/SearchContext';
const SearchNBHitsStats = () => {
    const { results } = useInfiniteHits();
    const { nbHits, index } = results || {};
    const { nbHits: contextNBHits, setnbHits } = useSearchNBHitsContext();
    useEffect(() => {
        if (typeof index !== 'undefined' && typeof nbHits !== 'undefined') {
            const nbHitExistsIndex = contextNBHits.findIndex((item) => Object.keys(item)[0] === index);
            if (nbHitExistsIndex !== -1) {
                setnbHits((prev) => prev.map((item) => (Object.keys(item)[0] === index ? { [index]: nbHits } : item)));
            }
            else {
                setnbHits([...contextNBHits, { [index]: nbHits }]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, nbHits]);
    return null;
};
export default SearchNBHitsStats;
