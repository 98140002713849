import { makeStyles } from 'tss-react/mui';
import { HEADER_HEIGHT, HEADER_HEIGHT_LG_UP, HEADER_HEIGHT_SM_UP } from '../../components/Header/Header.styles';
import { colors, spacing } from './variables';
import { hexToRgb } from '@mui/material';
export const MAX_CARD_WIDTH = 384;
export const minMargin = spacing.small;
export const widthOuter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
export const addWidthInner = (theme, styles) => (Object.assign(Object.assign({ width: '100%', paddingLeft: minMargin, paddingRight: minMargin }, styles), { [theme.breakpoints.up('md')]: Object.assign({ paddingLeft: spacing.large, paddingRight: spacing.large }, styles[theme.breakpoints.up('md')]), [theme.breakpoints.up('xl')]: Object.assign({ maxWidth: `${theme.breakpoints.values.xl}px` }, styles[theme.breakpoints.up('xl')]) }));
export const removePaddingWithMargin = {
    marginLeft: `-${minMargin} !important`,
    marginRight: `-${minMargin} !important`,
};
export const addPadding = {
    paddingLeft: minMargin,
    paddingRight: minMargin,
};
export const link = (color) => ({
    textDecoration: 'underline',
    display: 'inline-block',
    '&:hover': {
        color: color || colors.grey.grey5,
        cursor: 'pointer',
    },
});
export const siteUspsCarouselResponsive = (theme) => ({
    desktop: {
        breakpoint: { max: 3000, min: theme.breakpoints.values.xl },
        items: 1,
    },
    small: {
        breakpoint: { max: theme.breakpoints.values.xl - 1, min: theme.breakpoints.values.md },
        items: 1,
    },
    tablet: {
        breakpoint: { max: theme.breakpoints.values.md - 1, min: theme.breakpoints.values.sm },
        items: 1,
    },
    mobile: {
        breakpoint: { max: theme.breakpoints.values.sm - 1, min: 0 },
        items: 1,
    },
});
const bgColors = colors.background;
const textColors = colors.backgroundText;
const spotlightBannerLinkTextColors = colors.spotlightBannerLinkBackgroundText;
const linkColors = colors.backgroundLink;
const spotlightLinkColors = colors.spotlightNavigationBackgroundLink;
const linkHoverColors = colors.backgroundLinkHover;
const spotlightLinkHoverColors = colors.spotlightBannerNavigationLinkHover;
const imageBgColors = colors.imageBackground;
const newsLetterBGColors = colors.newsLetterBackground;
const spotlightBannerBGColors = colors.spotlightBannerBackround;
const backgrounds = Object.keys(colors.background).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: bgColors[key] || colors.supplementary.white,
        color: textColors[key] || colors.supplementary.black,
        '& a > span': Object.assign({ color: linkColors[key] || colors.primary.gold5 }, link(linkHoverColors[key] || colors.primary.gold4)),
    } })), {});
const newsLetterBackgrounds = Object.keys(colors.newsLetterBackground).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: newsLetterBGColors[key] || colors.supplementary.white,
        color: textColors[key] || colors.supplementary.black,
        '& a > span': Object.assign({ color: linkColors[key] || colors.primary.gold5 }, link(linkHoverColors[key] || colors.primary.gold4)),
    } })), {});
const spotlightBannerBackgrounds = Object.keys(colors.spotlightBannerBackround).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: spotlightBannerBGColors[key] || colors.supplementary.white,
        color: textColors[key] || colors.supplementary.black,
        '& a > span': Object.assign({ color: linkColors[key] || colors.primary.gold5 }, link(linkHoverColors[key] || colors.primary.gold4)),
    } })), {});
const spotlightNavigationCardBackgrounds = Object.keys(colors.spotlightBannerBackround).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: spotlightBannerBGColors[key] || colors.supplementary.white,
        color: spotlightBannerLinkTextColors[key] || colors.supplementary.black,
        '& a > span': Object.assign({ color: spotlightLinkColors[key] || colors.black[700] }, link(spotlightLinkHoverColors[key] || colors.primary.gold4)),
    } })), {});
const imageBackgrounds = Object.keys(colors.imageBackground).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: imageBgColors[key] || colors.supplementary.white,
    } })), {});
const availabilityCheckerBackgrounds = Object.keys(colors.background).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: {
        backgroundColor: bgColors[key] || colors.supplementary.white,
        color: colors.supplementary.black,
    } })), {});
const UseBackgroundStyles = makeStyles()(() => (Object.assign({}, backgrounds)));
const UseImageBackgroundStyles = makeStyles()(() => (Object.assign({}, imageBackgrounds)));
const UseNewsLetterBackgroundStyles = makeStyles()(() => (Object.assign({}, newsLetterBackgrounds)));
const UseSpotlightBannerBackgroundStyles = makeStyles()(() => (Object.assign({}, spotlightBannerBackgrounds)));
const UseSpotlightNavigationBackgroundStyles = makeStyles()(() => (Object.assign({}, spotlightNavigationCardBackgrounds)));
const UseAvailabilityCheckerBackgroundStyles = makeStyles()(() => (Object.assign({}, availabilityCheckerBackgrounds)));
export const useBackgroundClass = (backgroundColor, defaultColor) => {
    const bgColor = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'WHITE';
    const { classes: bgClasses } = UseBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['WHITE'];
    const hexColor = colors.background[bgColor] || colors.background.WHITE;
    const rgbColor = hexToRgb(hexColor);
    return { bgClass, rgbColor };
};
export const newsLetterBackgroundClass = (backgroundColor, defaultColor) => {
    const bgColor = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'WHITE';
    const { classes: bgClasses } = UseNewsLetterBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['WHITE'];
    return { bgClass };
};
export const spotlightBannerBackgroundClass = (backgroundColor, defaultColor) => {
    const bgColor = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'WHITE';
    const { classes: bgClasses } = UseSpotlightBannerBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['WHITE'];
    return { bgClass };
};
export const spotlightNavigationBackgroundClass = (backgroundColor, defaultColor) => {
    const bgColor = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'WHITE';
    const { classes: bgClasses } = UseSpotlightNavigationBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['WHITE'];
    return { bgClass };
};
export const useImageBackgroundClass = (imageBackgroundColor, defaultColor) => {
    const bgColor = (imageBackgroundColor === null || imageBackgroundColor === void 0 ? void 0 : imageBackgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'WHITE';
    const { classes: bgClasses } = UseImageBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['WHITE'];
    const hexColor = colors.imageBackground[bgColor] || colors.background.WHITE;
    const rgbColor = hexToRgb(hexColor);
    return { imgBgClass: bgClass, imgRgbColor: rgbColor };
};
export const useAvailabilityCheckerBackgroundClass = (backgroundColor, defaultColor) => {
    const bgColor = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toUpperCase().replace(' ', '_')) || defaultColor || 'BLACK';
    const { classes: bgClasses } = UseAvailabilityCheckerBackgroundStyles();
    const bgClass = bgClasses[bgColor] || bgClasses['BLACK'];
    const hexColor = colors.background[bgColor] || colors.background.BLACK;
    const rgbColor = hexToRgb(hexColor);
    return { bgClass, rgbColor };
};
export const maxHeightBelowHeader = (theme, styles) => (Object.assign(Object.assign({ maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`, '.condensed &': {
        maxHeight: `calc(100vh - 71px)`,
    } }, styles), { [theme.breakpoints.up('sm')]: Object.assign({ maxHeight: `calc(100vh - ${HEADER_HEIGHT_SM_UP}px)`, '.condensed &': {
            maxHeight: `calc(100vh - 71px)`,
        } }, styles[theme.breakpoints.up('sm')]), [theme.breakpoints.up('md')]: Object.assign({ maxHeight: `calc(100vh - ${HEADER_HEIGHT_SM_UP}px)` }, styles[theme.breakpoints.up('md')]), [theme.breakpoints.up('lg')]: Object.assign({ maxHeight: `calc(100vh - ${HEADER_HEIGHT_LG_UP}px)` }, styles[theme.breakpoints.up('lg')]) }));
