import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    contentHidden: {
        overflow: 'hidden',
    },
    contentGradient: {
        // from transparent at bottom to black at 2em from bottom
        background: 'linear-gradient(to top, rgba(0,0,0, 0) 0%, rgba(0,0,0, 1) 2em)',
        '-webkit-background-clip': 'text',
        '-moz-background-clip': 'text',
        '-o-background-clip': 'text',
        '-ms-background-clip': 'text',
        backgroundClip: 'text',
        '-webkit-mask-image': 'linear-gradient(to bottom, black 50%, transparent 100%)',
        '-moz-mask-image': 'linear-gradient(to bottom, black 50%, transparent 100%)',
        '-o-mask-image': 'linear-gradient(to bottom, black 50%, transparent 100%)',
        '-ms-mask-image': 'linear-gradient(to bottom, black 50%, transparent 100%)',
        maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
    },
    removeFadding: {
        [theme.breakpoints.down('xl')]: {
            '-webkit-mask-image': 'linear-gradient(to bottom, black 50%, black 100%)',
            '-moz-mask-image': 'linear-gradient(to bottom, black 50%, black 100%)',
            '-o-mask-image': 'linear-gradient(to bottom, black 50%, black 100%)',
            '-ms-mask-image': 'linear-gradient(to bottom, black 50%, black 100%)',
            maskImage: 'linear-gradient(to bottom, black 50%, black 100%)',
        },
    },
    contentExpanded: { maxHeight: 'none !important' },
    link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, flex: '0 0 auto', '&:not(:last-child):after': { content: "'|'", margin: '0 16px' }, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    content: {
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.large_regular) }),
    },
}));
export default useStyles;
