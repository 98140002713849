import { makeStyles } from 'tss-react/mui';
import { colors, spacing, typographySohne } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { color: colors.supplementary.black, [theme.breakpoints.between('sm', 'xl')]: {
            color: colors.supplementary.white,
        }, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), { color: colors.supplementary.white }), [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, theme.typography.xxl), { color: colors.supplementary.white }), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), { color: colors.supplementary.white }) }),
    subheading: {
        color: colors.supplementary.black,
        [theme.breakpoints.up('md')]: {
            color: colors.supplementary.white,
        },
    },
    message: {
        color: colors.supplementary.black,
        [theme.breakpoints.between('sm', 'xl')]: {
            color: colors.supplementary.white,
        },
        [theme.breakpoints.up('md')]: {
            color: colors.supplementary.white,
        },
    },
    link: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            margin: 0,
            '& button': { width: '100%' },
            '&:not(:last-child)': {
                marginBottom: '0',
                marginRight: spacing.medium,
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '46%',
        },
    },
    linkContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            maxWidth: '650px',
            display: 'flex',
            [`& .${classes.link}`]: {
                width: '50%',
                margin: 0,
                '& button': { width: '100%' },
                '&:first-child': {
                    marginRight: spacing.medium,
                },
            },
        },
    },
    outsideLinkContainer: {
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        marginTop: spacing.small,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    homeVideoContainer: {
        justifyContent: 'start',
    },
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginTop: spacing.small,
        marginLeft: '20px',
        marginRight: '20px',
        padding: '18px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    headingLine: {
        padding: 0,
        alignSelf: 'flex-start',
        display: 'block',
    },
    wrapHeading: {
        textAlign: 'left',
    },
    contentInner: {
        alignSelf: 'start !important',
        width: '100%',
        margin: `${spacing.small} 20px`,
        [theme.breakpoints.up('sm')]: {
            margin: `${spacing.small} ${spacing.medium}`,
        },
        [theme.breakpoints.up('xl')]: {
            margin: `${spacing.medium} ${spacing.large}`,
        },
    },
    fontSohne: {
        [`& .${classes.heading}`]: Object.assign(Object.assign(Object.assign({}, typographySohne.xl_bold), theme.typographySpacing.xl_bold_sm), { [theme.breakpoints.up('sm')]: Object.assign({}, theme.typographySpacing.none), [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, typographySohne.xxl_bold), theme.typographySpacing.xxl_bold_sm), [theme.breakpoints.up('lg')]: Object.assign(Object.assign(Object.assign({}, typographySohne.xxl_bold), theme.typographySpacing.none), { marginBottom: spacing.large }), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, typographySohne.xxxxxl), theme.typographySpacing.xxxxl_sm) }),
    },
    newMessage: Object.assign(Object.assign(Object.assign({}, typographySohne.small_bold), theme.typographySpacing.none), { textAlign: 'left', color: colors.supplementary.white, [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, typographySohne.small_bold), { marginBottom: spacing.medium, textAlign: 'left' }), [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, typographySohne.medium_bold), theme.typographySpacing.none), { marginBottom: `${spacing.medium}` }), [theme.breakpoints.up('lg')]: {
            marginBottom: spacing.large,
        }, [theme.breakpoints.up('xl')]: {
            marginBottom: `${spacing.xl} !important`,
        } }),
    content: {
        width: '100%',
        alignSelf: 'start',
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
        },
    },
}));
export default useStyles;
