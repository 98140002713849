import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    giftContainer: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.medium,
        },
    },
}));
export default useStyles;
