import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import useStyles from './MyAccountWelcomeTitle.styles';
import Alert from '../Alert/Alert';
import Link from '../Link/Link';
import { myAccountSuccessMessages } from '../../utils/common';
const MyAccountWelcomeTitle = (props) => {
    const { customer, success } = props;
    const [reminderStatus, setReminderStatus] = useState();
    const { classes } = useStyles();
    const successMessage = success ? myAccountSuccessMessages[success] : undefined;
    useEffect(() => {
        const handleReminderSaveMessages = (e) => {
            if (e.detail) {
                setReminderStatus(e.detail);
            }
        };
        document === null || document === void 0 ? void 0 : document.addEventListener('add-reminder-message', handleReminderSaveMessages); // custom to recieve to get save reminder status messages
        return () => document === null || document === void 0 ? void 0 : document.removeEventListener('add-reminder-message', handleReminderSaveMessages);
    }, []);
    return (_jsxs(_Fragment, { children: [successMessage ? _jsx(Alert, { type: "alertSuccess", message: successMessage, className: classes.alertMessage }) : null, (reminderStatus === null || reminderStatus === void 0 ? void 0 : reminderStatus.reminderStatus) ? (_jsx(Alert, { type: reminderStatus.reminderStatus, message: _jsxs(_Fragment, { children: [reminderStatus.reminderStatusMessage, ' ', reminderStatus.reminderStatus === 'alertInfo' ? (_jsx(Link, { url: "/account/reminders", label: "Take a look", className: classes.highlight })) : null] }), className: classes.alertMessage })) : null, _jsxs("h1", { children: ["Hello ", customer.firstName, ", welcome to your account."] })] }));
};
export default MyAccountWelcomeTitle;
