import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { useBackgroundClass, useImageBackgroundClass, } from '../../styles/jss/utils';
import { decodeQueryParam, SiteContext } from '../../utils/common';
import { processImageUrl } from '../../utils/image';
import GuidedNavigation from '../GuidedNavigation/GuidedNavigation';
import Image from '../Image/Image';
import RawHtml from '../RawHtml/RawHtml';
import RelatedLinksGallery from '../RelatedLinksGallery/RelatedLinksGallery';
import { ExpandingText } from './ExpandingText';
import useStyles from './PageHeader.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
/**
 * <h2>Designs</h2>
 * <p>Flying Flowers: https://www.figma.com/file/sStbIo2VTXH7Tbs27KZU21/FF-Weekly-Outputs?node-id=984%3A95560</p>
 * <h2>Design Systems</h2>
 * **Right hand promo - 7:5 image aspect ratio promo**
 * <p>Interlora: https://www.figma.com/file/TLHrK1x4xxUOwVgiQ7McFD/DS_version-2.13.3?node-id=3055%3A66959</p>
 * <p>Flying Flowers: https://www.figma.com/file/UI4BmbaVYDdLOBd0ZDO7Po/FF-DS_version-2.1.0?node-id=3055%3A66959</p>
 */
const PageHeader = (props) => {
    const { title, description, message, linksTitle, links, body, image, backgroundColor, imageBackgroundColor, searchResults, guidedNavigation, moreLinkLabel, lessLinkLabel, source, } = props;
    const { countriesList } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { bgClass } = useBackgroundClass(backgroundColor, isFlyingFlowers ? 'FF_SHARP_YELLOW' : 'RED');
    const { imgBgClass, imgRgbColor } = useImageBackgroundClass(imageBackgroundColor, isFlyingFlowers ? 'FF_COBALT' : 'WHITE');
    // Fallback Image Url
    const imageUrl = (image === null || image === void 0 ? void 0 : image.url) && processImageUrl(image.url, { w: 1024, bg: imgRgbColor }, ['$poi-square$']);
    // Square image for lg and above
    // Width maxes out at 395 here, but sometimes can be streched tall by the content so getting a larger image
    const squareFilename = (image === null || image === void 0 ? void 0 : image.url) ? processImageUrl(image.url, { w: 600, bg: imgRgbColor }, ['$poi-square$']) : '';
    // Wide image for below lg
    const wideWidths = [384, 480, 780, 1024];
    const wideFilenames = (image === null || image === void 0 ? void 0 : image.url)
        ? wideWidths
            .map((w) => `${processImageUrl(image.url, { w, aspect: '19:7', bg: imgRgbColor }, ['$poi-square$'])} ${w}w`)
            .join(', ')
        : '';
    const images = (image === null || image === void 0 ? void 0 : image.url)
        ? [{ breakpoint: 'lg', query: 'min-width', sizes: '25w', filename: squareFilename }, { filename: wideFilenames }]
        : [];
    useLoaded(props);
    return (_jsxs("div", { className: classes.container, "data-hj-ignore-attributes": true, children: [_jsxs("div", { className: classes.content, children: [!!title && (_jsx("h1", { className: clsx(classes.title, searchResults && classes.searchResultsTitle), children: decodeQueryParam(title) })), !!description && source === 'category' ? (_jsx(RawHtml, { content: description, className: classes.description })) : !!description ? (_jsx(ExpandingText, { className: classes.description, labelTruncated: moreLinkLabel || 'More about this category', labelExpanded: lessLinkLabel || 'Less about this category', text: description, maxHeight: '3.5em', isCatalogInfo: false })) : null, !!(links === null || links === void 0 ? void 0 : links.length) && (_jsx(RelatedLinksGallery, { linkTitle: linksTitle || 'Related Links', links: links, classNames: { linksTitle: classes.linksTitle, link: classes.link } })), (message || guidedNavigation) && (_jsxs("div", { className: classes.guidedNavigationContainer, children: [message && _jsx("div", { className: classes.message, children: message }), guidedNavigation && _jsx(GuidedNavigation, { basicInitiator: true, countriesList: countriesList })] }))] }), !!imageUrl && (_jsx("div", { className: clsx(classes.promoImage, imgBgClass), children: _jsx(Image, { imageUrl: imageUrl, altText: (image === null || image === void 0 ? void 0 : image.altText) || '', images: images, width: 1024, height: 1024, priority: true }) })), _jsx(RawHtml, { className: clsx(classes.promoContent, classes.heading, bgClass), content: body })] }));
};
export default PageHeader;
