import { makeStyles } from 'tss-react/mui';
import { spacing, typographySohne } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.between('md', 'xl')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, theme.typography.xxxxl), theme.typographySpacing.xxxxl_sm) }),
    fontSohne: {
        [`& .${classes.heading}`]: Object.assign(Object.assign(Object.assign({}, typographySohne.small_regular), theme.typographySpacing.small_bold_sm), { [theme.breakpoints.between('md', 'xl')]: Object.assign(Object.assign({}, typographySohne.medium_regular), theme.typographySpacing.medium_bold_sm), [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, typographySohne.large_regular), theme.typographySpacing.large_bold_lg) }),
    },
    message: {
        'p&': Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { marginBottom: '24px', [theme.breakpoints.between('md', 'xl')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_lg) }),
    },
    linkContainer: {
        [theme.breakpoints.up('xl')]: {
            display: !!params.links && (params === null || params === void 0 ? void 0 : params.links) > 1 ? 'flex !important' : 'inline-flex !important',
            flexDirection: 'row !important',
            [`& .${classes.link}`]: {
                flex: '50%',
                width: '100%',
                margin: 0,
                padding: `0 ${spacing.xs}`,
                '& button': { width: '100%' },
            },
        },
    },
    link: {
        [theme.breakpoints.up('xl')]: {
            flex: '50%',
            width: '100%',
            margin: 0,
            padding: `0 ${spacing.xs}`,
            '& button': { width: '100%' },
        },
    },
    contentInner: {
        '& > :last-child': {
            marginBottom: spacing.large,
            [theme.breakpoints.up('md')]: {
                marginBottom: '0px',
            },
        },
    },
}));
export default useStyles;
