import { makeStyles } from 'tss-react/mui';
import { addWidthInner, widthOuter } from '../../styles/jss/utils';
import { colors, fontSizes, spacing } from '../../styles/jss/variables';
export const HEADER_HEIGHT = 137;
export const HEADER_HEIGHT_SM_UP = 149;
export const HEADER_HEIGHT_LG_UP = 161;
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    contentContainer: {},
    stickyHeader: {
        top: '0px !important',
    },
    header: {
        top: -40,
        position: 'sticky',
        zIndex: 100,
        width: '100%',
        '@media print': {
            position: 'relative',
            left: 0,
            right: 0,
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            top: 'unset',
            position: 'relative',
        },
        '& ${contentContainer}': {
            padding: spacing.small,
        },
    },
    headerContent: {
        backgroundColor: colors.supplementary.white,
        top: -40,
        '@media print': {
            position: 'relative',
            width: '100%',
            top: 0,
        },
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, widthOuter), { borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey2}`, position: 'relative', top: 'unset' }),
    },
    uspContainer: {
        height: '40px',
        width: '100%',
        backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
        display: 'flex',
        justifyContent: 'center',
    },
    uspInner: addWidthInner(theme, {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // Hide site USPs if trustpilot is visible on small
        [theme.breakpoints.down('xl')]: {
            '& > :not(:first-child)': { display: 'none' },
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '196px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '208px',
        },
    }),
    logo: {
        width: '96px !important',
        [theme.breakpoints.up('sm')]: {
            width: theme.isFlyingFlowers ? undefined : '108px !important',
        },
        [theme.breakpoints.up('md')]: {
            width: '108px !important',
        },
        [theme.breakpoints.between(1279, 1920)]: {
            width: '108px !important',
        },
        [theme.breakpoints.up(1919)]: {
            width: '112px !important',
        },
    },
    controlContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        position: 'sticky',
        backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[400] : colors.supplementary.white,
        borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey2}`,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'stretch',
            borderBottom: 'none',
            // For mega nav to go below
            position: 'relative',
        },
        [theme.breakpoints.up('lg')]: {
            borderBottom: 'none',
            // For mega nav to go below
            position: 'relative',
        },
    },
    controlContainerInner: Object.assign(Object.assign({ display: 'flex', minHeight: theme.isFlyingFlowers ? '96px' : undefined, alignItems: 'center', justifyContent: 'space-around', transition: 'min-height 0.2s', marginBottom: theme.isFlyingFlowers ? undefined : '-1px' }, addWidthInner(theme, {
        [theme.breakpoints.up('md')]: {
            minHeight: theme.isFlyingFlowers ? '120px' : 'fit-content',
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: theme.isFlyingFlowers ? '104px' : 'fit-content',
        },
        [theme.breakpoints.up('xl')]: {
            minHeight: theme.isFlyingFlowers ? '104px' : 'fit-content',
            padding: '0 32px',
        },
    })), { '.condensed &': {
            paddingTop: theme.isFlyingFlowers ? undefined : '0px',
        } }),
    sticky: {},
    svpControls: {
        alignSelf: 'center',
        width: '60px !important',
        '& .material-icons': {
            width: theme.isFlyingFlowers ? '40px' : 'unset',
            height: theme.isFlyingFlowers ? '40px' : 'unset',
            lineHeight: theme.isFlyingFlowers ? '40px' : 'unset',
            fontSize: fontSizes.xl,
            color: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey7,
        },
        '& div': Object.assign(Object.assign({}, theme.typography.caption), { color: colors.grey.grey6, 
            // When label empty on mobile menu icon still maintain line height
            height: fontSizes.medium }),
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
        },
    },
    svpLink: {
        display: 'flex',
        justifyContent: 'center',
    },
    lvpNavContainer: {
        display: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        height: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.none), { marginLeft: '28px' }),
    lvpControls: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            marginLeft: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
        },
        '& .material-icons': {
            fontSize: '1.75rem',
            height: theme.isFlyingFlowers ? '36px' : undefined,
            width: theme.isFlyingFlowers ? '36px' : undefined,
            lineHeight: theme.isFlyingFlowers ? '36px' : undefined,
            color: theme.isFlyingFlowers ? colors.supplementary.white : undefined,
        },
    },
    icon: {
        '&:hover .material-icons': {
            backgroundColor: theme.isFlyingFlowers ? colors.wildExotic[600] : undefined,
        },
    },
    action: {
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '14px',
        marginRight: theme.isFlyingFlowers ? '2px' : '5px',
        [theme.breakpoints.up('md')]: {
            marginRight: theme.isFlyingFlowers ? '2px' : '-5px',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: theme.isFlyingFlowers ? '2px' : '0px',
        },
    },
    anonymousUserNoMargin: {
        marginRight: 0,
    },
    actionActive: {
        '&:after': {
            width: '100%',
            height: '10px',
            content: "''",
            position: 'absolute',
            borderBottom: theme.isFlyingFlowers
                ? `12px solid ${colors.supplementary.white}`
                : `10px solid ${colors.primary.gold4}`,
            left: '0px',
            bottom: '0px',
        },
    },
    collapse: {
        width: '100%',
    },
    input: { marginBottom: spacing.small },
    siteUspContainer: {
        display: 'flex',
        // Hide second USP except on xl
        [theme.breakpoints.down('xl')]: {
            '& > :not(:first-child)': { display: 'none' },
        },
    },
    signInLink: {
        display: 'none',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        color: theme.isFlyingFlowers ? colors.wildExotic[400] : undefined,
        '&:hover': {
            color: theme.isFlyingFlowers ? colors.wildExotic[600] : colors.grey.grey5,
            '& .material-icons': {
                backgroundColor: theme.isFlyingFlowers ? colors.wildExotic[600] : undefined,
                color: theme.isFlyingFlowers ? undefined : colors.grey.grey5,
            },
        },
        [theme.breakpoints.up('md')]: Object.assign({ display: 'flex' }, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.xs)),
        [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.small_bold),
        '& .material-icons': {
            height: theme.isFlyingFlowers ? '28px' : undefined,
            width: theme.isFlyingFlowers ? '28px' : undefined,
            color: theme.isFlyingFlowers ? colors.supplementary.white : undefined,
        },
    },
    loginLink: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    hoverOnLink: {
        color: theme.isFlyingFlowers ? undefined : colors.primary.gold5,
        cursor: 'pointer',
        '&:after': {
            width: '100%',
            height: '10px',
            content: "''",
            position: 'absolute',
            borderBottom: theme.isFlyingFlowers
                ? `12px solid ${colors.supplementary.white}`
                : `10px solid ${colors.primary.gold4}`,
            left: '0px',
            bottom: '0px',
        },
        '& .MuiIcon-root': {
            color: theme.isFlyingFlowers ? undefined : colors.primary.gold5,
        },
    },
    menuOptions: {
        position: 'absolute',
        top: '96px',
        zIndex: 999,
        right: 0,
        left: 0,
        [theme.breakpoints.down('xl')]: {
            top: theme.isFlyingFlowers ? '128px !important' : '112px',
        },
        [theme.breakpoints.up('md')]: {
            top: '112px',
            zIndex: 999,
            left: 'initial',
            right: 0,
        },
        '& .material-icons': {
            color: 'initial',
        },
    },
    close: {
        '& .material-icons': {
            backgroundColor: theme.isFlyingFlowers ? colors.black[700] : undefined,
        },
    },
    shopCart: {
        padding: '14px',
        paddingLeft: 0,
        margin: theme.isFlyingFlowers ? '0 -12px 0 -14px' : '0',
    },
    loggedInIcon: {
        width: '18px',
        height: '18px',
        '& .MuiIcon-root': {
            width: 'unset',
            height: 'unset',
            fontSize: '18px',
            lineHeight: '18px',
        },
    },
    itemsCount: Object.assign(Object.assign(Object.assign({ position: 'absolute', right: theme.isFlyingFlowers ? '-6px' : 0, top: 0, border: `1px solid ${theme.isFlyingFlowers ? colors.jade[300] : colors.primary.gold4}`, backgroundColor: theme.isFlyingFlowers ? colors.jade[300] : colors.primary.gold4, width: spacing.medium, height: spacing.medium, borderRadius: '50%', textAlign: 'center', alignItems: 'center', justifyContent: 'center', color: theme.isFlyingFlowers ? colors.supplementary.white : colors.supplementary.black }, theme.typography.xxs_bold), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: {
            top: theme.isFlyingFlowers ? '12px' : '-4px',
            right: 0,
        } }),
    shoppingCartContainer: {
        position: 'relative',
        padding: 'initial',
        [theme.breakpoints.up('md')]: {
            padding: '14px !important',
        },
    },
    mobileShoppingCartContainer: {
        padding: 'initial',
    },
    mobileItemsCount: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    dekstopItemCount: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    loginAndAccountIcon: {
        [theme.breakpoints.up('md')]: {
            top: theme.isFlyingFlowers ? '42px' : spacing.medium,
            right: 0,
        },
        [theme.breakpoints.up('lg')]: {
            top: theme.isFlyingFlowers ? spacing.large : spacing.medium,
        },
    },
    anonymousUser: {
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '14px',
        marginRight: '0px',
        paddingLeft: theme.isFlyingFlowers ? 0 : '14px',
        paddingRight: theme.isFlyingFlowers ? 0 : '14px',
        '& > span': {
            paddingRight: 0,
        },
    },
    hideBasket: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    iconContainer: {
        alignItems: 'center',
        flexDirection: 'column',
    },
}));
export default useStyles;
