import { makeStyles } from 'tss-react/mui';
import { addWidthInner, widthOuter } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme, _params, classes) => ({
    mainContent: {
        overflowX: 'hidden',
        flexGrow: 1,
        backgroundColor: colors.supplementary.white,
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: Object.assign({}, widthOuter),
        ['& [data-loading] ~  div']: {
            visibility: 'hidden',
        },
    },
    componentContainer: addWidthInner(theme, {
        paddingBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xl,
        },
    }),
    linkComponentContainer: addWidthInner(theme, {
        paddingBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xl,
        },
    }),
    topSpacing: {
        paddingTop: spacing.medium,
        [theme.breakpoints.up('md')]: {
            paddingTop: spacing.large,
        },
    },
    centreContent: {
        [`& .${classes.componentContainer}`]: {
            [theme.breakpoints.up('md')]: {
                maxWidth: '1064px',
            },
        },
    },
    centreContentSmall: {
        [`& .${classes.componentContainer}`]: {
            [theme.breakpoints.up('md')]: {
                maxWidth: '792px',
            },
        },
    },
    centreContentExtraSmall: {
        [`& .${classes.componentContainer}`]: {
            [theme.breakpoints.up('md')]: {
                maxWidth: '520px',
            },
        },
    },
    homeHeroGallerySpacing: {
        paddingTop: `${spacing.xs} !important`,
        [theme.breakpoints.up('md')]: {
            paddingTop: 'initial',
        },
    },
    myAccountTitlePadding: {
        paddingTop: spacing.medium,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            paddingTop: spacing.xxl,
        },
    },
    myAccountCardPadding: {
        paddingBottom: spacing.xl,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xxl,
        },
    },
    titlePadding: {
        paddingBottom: 0,
    },
    myAccountRemindersPadding: {
        paddingBottom: spacing.xl,
    },
}));
export default useStyles;
