var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useReducer, useEffect, useRef, useCallback, useContext } from 'react';
import useStyles from './DatePicker.styles';
import Modal from '../Modal/Modal';
import { Input, useTheme } from '@mui/material';
import format from 'date-fns/format';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import isToday from 'date-fns/isToday';
import isSameDay from 'date-fns/isSameDay';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import clsx from 'clsx';
import Button from '../Button/Button';
import { useMediaQuery } from 'react-responsive';
import Icon from '../Icon/Icon';
import Skeleton from 'react-loading-skeleton';
import { SiteContext } from '../../utils/common';
import { isEmpty } from 'lodash';
import getMonth from 'date-fns/getMonth';
import { differenceInMonths } from 'date-fns';
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_CURRENT_DATE_PARTS"] = "SET_CURRENT_DATE_PARTS";
    ActionTypes["SET_SELECTED_DAY"] = "SET_SELECTED_DAY";
    ActionTypes["SET_MOBILE_MONTHS_SHOWN"] = "SET_MOBILE_MONTHS_SHOWN";
    ActionTypes["SET_DATE_DETAILS"] = "SET_DATE_DETAILS";
})(ActionTypes || (ActionTypes = {}));
const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_DATE_PARTS:
            return Object.assign(Object.assign({}, state), action.payload);
        case ActionTypes.SET_SELECTED_DAY:
            return Object.assign(Object.assign({}, state), { selectedDay: action.payload });
        case ActionTypes.SET_MOBILE_MONTHS_SHOWN:
            return Object.assign(Object.assign({}, state), { mobileMonthsShown: action.payload });
        case ActionTypes.SET_DATE_DETAILS:
            return Object.assign(Object.assign({}, state), { dateDetails: action.payload });
        default:
            throw new Error('Action type not identified');
    }
};
const date = new Date();
const DatePicker = ({ minDate, maxDate, filterDate, onChange, setValue, id, name, onBlur, className, placeholder, setOpen, isOpen, getDateDetails, renderSelectedDayDetails, value, datePickerTitle, handleMonthChange, calendarLoading, hideInput, noModal, confirmDisabled, confirmButtonLabel, confirmIsSubmit, hideCancelButton, isActionsCenter, isSaveOnClose, isFromPCC, showProductAvailableCalendar, initialFirstDate, oldCalendar, }) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const dateFormat = 'dd/MM/yyyy';
    const theme = useTheme();
    // Available after initial SSR
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const mobileMonthLimit = 2;
    const elRefs = React.useRef([]);
    elRefs.current = Array(mobileMonthLimit)
        .fill('')
        .map((_, i) => elRefs.current[i] || React.createRef());
    const scrollableDivRef = React.useRef(null);
    const componentHasRendered = useRef(false);
    const hasScrolled = useRef(false);
    const getDayDetails = (index, year, month) => {
        const initialDateObject = new Date(year, month);
        const currentMonthDayTotal = getDaysInMonth(initialDateObject);
        const startDayNumberOfMonth = initialDateObject.getDay();
        // If startDayNumberOfMonth is a Sunday (0) set it to 7 because Monday is used as the first day
        const relativeStartDay = index - (startDayNumberOfMonth === 0 ? 7 : startDayNumberOfMonth);
        let prevMonth = month - 1;
        let prevYear = year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        const prevMonthDayTotal = getDaysInMonth(new Date(prevYear, prevMonth));
        const date = (relativeStartDay < 0 ? prevMonthDayTotal + relativeStartDay : relativeStartDay % currentMonthDayTotal) + 1;
        const validMonth = relativeStartDay < 0 ? -1 : relativeStartDay >= currentMonthDayTotal ? 1 : 0;
        const currentDateObject = validMonth < 0 ? new Date(prevYear, prevMonth, date) : new Date(year, month, date);
        const isDisabled = (minDate && isBefore(currentDateObject, startOfDay(minDate))) ||
            (maxDate && isAfter(currentDateObject, startOfDay(maxDate))) ||
            (filterDate && filterDate(currentDateObject));
        return {
            date,
            month: validMonth,
            dateObject: currentDateObject,
            isDisabled,
        };
    };
    const getMonthDetails = (year, month) => {
        let monthArray = [];
        let currentDay = null;
        let index = 1;
        Array(6)
            .fill('')
            .forEach(() => {
            Array(7)
                .fill('')
                .forEach(() => {
                currentDay = getDayDetails(index, year, month);
                monthArray.push(currentDay);
                index++;
            });
        });
        return monthArray;
    };
    const initialState = {
        year: date.getFullYear(),
        month: date.getMonth(),
        selectedDay: value || '',
        dateDetails: [],
        mobileMonthsShown: mobileMonthLimit,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        let productAvailabilityMonth = 2;
        if ((!!value || !!initialFirstDate) &&
            elRefs.current[0] &&
            !isLargerViewport &&
            showProductAvailableCalendar &&
            initialFirstDate) {
            const currentMonth = getMonth(new Date()) + 1;
            const startMonth = getMonth(new Date(initialFirstDate)) + 1;
            productAvailabilityMonth = startMonth > currentMonth ? startMonth : 2;
        }
        if (!isLargerViewport) {
            const monthDifference = value ? differenceInCalendarMonths(value, new Date()) : 0;
            elRefs.current = elRefs.current.filter((ref) => ref.current !== null);
            if (isOpen && !hasScrolled.current && productAvailabilityMonth && showProductAvailableCalendar) {
                dispatch({
                    type: ActionTypes.SET_MOBILE_MONTHS_SHOWN,
                    payload: productAvailabilityMonth && showProductAvailableCalendar ? productAvailabilityMonth : monthDifference + 1,
                });
            }
            else if (isOpen && !hasScrolled.current && monthDifference > state.mobileMonthsShown) {
                dispatch({ type: ActionTypes.SET_MOBILE_MONTHS_SHOWN, payload: monthDifference + 1 });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLargerViewport, isOpen, value, state.mobileMonthsShown, initialFirstDate]);
    useEffect(() => {
        let productAvailabilityMonth = 2;
        if ((!!value || !!initialFirstDate) &&
            elRefs.current[0] &&
            !isLargerViewport &&
            showProductAvailableCalendar &&
            initialFirstDate) {
            const currentMonth = getMonth(new Date()) + 1;
            const startMonth = getMonth(new Date(initialFirstDate)) + 1;
            productAvailabilityMonth = startMonth > currentMonth ? startMonth : 2;
        }
        if (!!value && elRefs.current[0] && !hasScrolled.current && !isLargerViewport) {
            const monthDifference = differenceInCalendarMonths(value, new Date());
            const currentRef = elRefs.current[productAvailabilityMonth && showProductAvailableCalendar
                ? productAvailabilityMonth
                : monthDifference > 1
                    ? monthDifference + 2
                    : monthDifference] || null;
            if (currentRef && isOpen === true) {
                currentRef.scrollIntoView && currentRef.scrollIntoView({ block: 'start' });
                hasScrolled.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLargerViewport, isOpen, value, state.mobileMonthsShown]);
    useEffect(() => {
        if (isLargerViewport && !!value && !componentHasRendered.current) {
            const selectedDate = value;
            dispatch({
                type: ActionTypes.SET_CURRENT_DATE_PARTS,
                payload: { year: selectedDate.getFullYear(), month: selectedDate.getMonth() },
            });
            componentHasRendered.current = true;
        }
    }, [isLargerViewport, value]);
    const onDateSelected = (day, event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        dispatch({ type: ActionTypes.SET_SELECTED_DAY, payload: day.dateObject });
        if (isFromPCC) {
            setValue && setValue(day.dateObject);
        }
        else if (getDateDetails) {
            try {
                const dateDetails = yield getDateDetails(format(day.dateObject, 'yyyy-MM-dd'));
                dispatch({ type: ActionTypes.SET_DATE_DETAILS, payload: dateDetails });
            }
            catch (e) {
                console.log(e);
            }
        }
    });
    const setMonth = (offset) => {
        const currentDate = new Date(state.year, state.month);
        const newDate = offset === -1 ? subMonths(currentDate, 1) : addMonths(currentDate, 1);
        const differenceInMonthsCount = differenceInMonths(newDate, new Date());
        if (handleMonthChange && !showProductAvailableCalendar) {
            handleMonthChange(format(lastDayOfMonth(addMonths(newDate, 1)), 'yyyy-MM-dd'));
        }
        else if (handleMonthChange && showProductAvailableCalendar && differenceInMonthsCount >= 3) {
            handleMonthChange(format(lastDayOfMonth(addMonths(newDate, 1)), 'yyyy-MM-dd'));
        }
        dispatch({
            type: ActionTypes.SET_CURRENT_DATE_PARTS,
            payload: { year: newDate.getFullYear(), month: newDate.getMonth() },
        });
    };
    const onScroll = useCallback(() => {
        if (scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;
            if (
            // Check the div has fully rendered so has height
            scrollHeight &&
                // Check the div is not bigger thant the window height, if so might not be wrapped in a scrolling area
                scrollHeight - scrollTop < (window === null || window === void 0 ? void 0 : window.innerHeight) &&
                scrollTop + clientHeight + 20 > scrollHeight) {
                const currentDate = new Date(state.year, state.month);
                const newDate = addMonths(currentDate, state.mobileMonthsShown + mobileMonthLimit);
                const differenceInMonthsCount = differenceInMonths(newDate, new Date());
                if (handleMonthChange && !showProductAvailableCalendar) {
                    handleMonthChange(format(lastDayOfMonth(addMonths(newDate, 1)), 'yyyy-MM-dd'));
                }
                else if (handleMonthChange && showProductAvailableCalendar) {
                    if (differenceInMonthsCount >= 3) {
                        handleMonthChange(format(lastDayOfMonth(addMonths(newDate, 1)), 'yyyy-MM-dd'));
                    }
                }
                dispatch({ type: ActionTypes.SET_MOBILE_MONTHS_SHOWN, payload: state.mobileMonthsShown + mobileMonthLimit });
            }
        }
    }, [handleMonthChange, showProductAvailableCalendar, state.mobileMonthsShown, state.month, state.year]);
    useEffect(() => {
        var _a;
        // Call scroll on first render or when the modal is opened to see if the
        // height is already greater than the number of months shown
        ((_a = scrollableDivRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) && onScroll();
        // Call scroll when window is resized incase we are moving from dekstop to mobile
        // and not enough months are shown
        window.addEventListener('resize', onScroll);
        return () => window.removeEventListener('resize', onScroll);
    }, [isOpen, onScroll]);
    const saveDate = () => {
        setValue && setValue(state.selectedDay);
        if (!noModal)
            setOpen(false);
        hasScrolled.current = false;
    };
    useEffect(() => {
        if (isLargerViewport && (showProductAvailableCalendar || oldCalendar) && initialFirstDate) {
            dispatch({
                type: ActionTypes.SET_CURRENT_DATE_PARTS,
                payload: { year: new Date(initialFirstDate).getFullYear(), month: new Date(initialFirstDate).getMonth() },
            });
        }
    }, [initialFirstDate, showProductAvailableCalendar, isLargerViewport, oldCalendar]);
    const onCancel = () => {
        dispatch({ type: ActionTypes.SET_SELECTED_DAY, payload: '' });
        setOpen(false);
    };
    const daysMarkup = (monthDetails) => {
        return monthDetails.map((day, index) => (_jsx(React.Fragment, { children: day.month === 0 ? (_jsx("div", { children: _jsx("button", { onClick: (e) => onDateSelected(day, e), className: `${clsx(classes.day, day.isDisabled && classes.disabled, isToday(day.dateObject) && classes.currentDay, state.selectedDay && isSameDay(day.dateObject, state.selectedDay) && classes.selected)}${day.isDisabled ? ' unavailableDay' : ''}`, children: day.date }) })) : (_jsx("div", {})) }, index)));
    };
    const renderDayHeader = () => (_jsx("div", { className: classes.dayNameContainer, children: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((d, i) => (_jsx("div", { className: classes.dayName, children: d }, i))) }));
    const renderMonths = (monthsToRender, showDayHeader) => (_jsx(_Fragment, { children: [...Array(monthsToRender)].map((_x, i) => (_jsxs("div", { className: clsx(classes.calendarContainer, isFromPCC ? classes.pccCalendarContainer : ''), ref: (ref) => ref && elRefs.current.push(ref), children: [_jsx("div", { className: clsx(classes.monthName, isFromPCC ? classes.pccMonthName : ''), children: format(new Date(state.year, state.month + i), 'MMMM y') }), showDayHeader && renderDayHeader(), calendarLoading ? (_jsx("div", { className: classes.skeletonContainer, children: _jsx(Skeleton, { count: 5, height: 50, duration: 1 }) })) : (_jsx("div", { className: clsx(classes.monthDaysContainer, isFromPCC ? classes.pccMonthsDaysContainer : ''), children: daysMarkup(getMonthDetails(state.year, state.month + i)) }))] }, i))) }));
    const renderDateDetails = () => {
        return (_jsx("div", { className: classes.dayDetailsContainer, children: _jsxs("div", { className: classes.dayDetails, children: [_jsxs("span", { children: ["Selected date: ", format(state.selectedDay, dateFormat)] }), !isEmpty(state.dateDetails) && renderSelectedDayDetails && (_jsx("ul", { children: renderSelectedDayDetails(state.dateDetails).map((detail, i) => (_jsx("li", { children: detail }, i))) }))] }) }));
    };
    const renderActionButtons = () => {
        return (_jsxs("div", { className: clsx(classes.calendarActions, hideCancelButton ? classes.singleButton : ''), children: [!hideCancelButton && (_jsx(Button, { variant: "tertiary", thin: true, title: "Cancel", onClick: onCancel, icon: isFlyingFlowers ? 'close' : undefined })), _jsx(Button, { variant: "primary", thin: true, title: confirmButtonLabel || 'Save date', onClick: () => saveDate(), isSubmit: confirmIsSubmit, disabled: confirmDisabled || !state.selectedDay, icon: isFlyingFlowers ? 'check' : undefined })] }));
    };
    const renderMobileView = () => {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.mobileDatePicker, children: [_jsx("div", { className: clsx(classes.calendarBody, isFromPCC ? classes.pccCalrendarBody : ''), onScroll: () => onScroll(), ref: scrollableDivRef, children: renderMonths(state.mobileMonthsShown) }), state.selectedDay && !noModal && renderDateDetails()] }), !isFromPCC ? renderActionButtons() : null] }));
    };
    const renderDesktopView = () => (_jsxs("div", { className: clsx(classes.datePicker, isFromPCC ? classes.pccDatePicker : ''), children: [_jsxs("div", { className: classes.navigationContainer, children: [!isBefore(new Date(state.year, state.month), date) && (_jsx(Icon, { icon: "arrow_back_ios", className: clsx(classes.navigation), onClick: () => setMonth(-1), fontSize: "small" })), _jsx(Icon, { icon: "arrow_forward_ios", className: clsx(classes.navigation, classes.nextMonthGroup), onClick: () => setMonth(1), fontSize: "small" })] }), _jsxs("div", { className: classes.calendarBody, children: [_jsx("div", { className: clsx(classes.monthsContainer, isFromPCC ? classes.pccMonthContainer : ''), children: renderMonths(2, true) }), state.selectedDay && !noModal && renderDateDetails(), _jsx("div", { className: clsx(classes.calendarActionsContainer, isActionsCenter && classes.buttonCenter), children: !isFromPCC ? renderActionButtons() : null })] })] }));
    const handleChange = (e) => {
        if (!(e.target.value instanceof Date)) {
            setOpen(true);
            e.preventDefault();
        }
    };
    const handleModalClose = () => {
        isSaveOnClose && state.selectedDay && saveDate();
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Input, Object.assign({}, (id && { id }), (name && { name }), (onBlur && { onBlur }), (className && { className }), (onChange && { onChange: (e) => handleChange(e) }), (hideInput && { style: { display: 'none' } }), { placeholder: placeholder, value: !!value ? format(value, dateFormat) : '', autoComplete: "off", type: "text", disableUnderline: true })), noModal ? (isLargerViewport ? (renderDesktopView()) : (renderMobileView())) : isLargerViewport ? (_jsx(Modal, { open: isOpen, className: classes.modal, maxWidth: "md", setOpen: handleModalClose, title: datePickerTitle, children: renderDesktopView() })) : (_jsx(Modal, { open: isOpen, noPadding: true, fullScreen: true, disablePortal: true, setOpen: handleModalClose, title: datePickerTitle, className: classes.mobileModal, children: renderMobileView() }))] }));
};
export default DatePicker;
