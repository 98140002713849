import { colors, spacing } from './variables';
const MAX_WIDTH_INNER = 520;
const MAX_WIDTH_OUTER = 792;
export const formStyles = (theme) => ({
    container: {
        maxWidth: MAX_WIDTH_OUTER,
        margin: `0 auto`,
        padding: spacing.small,
        '& h1': Object.assign(Object.assign({}, theme.typography.xl_regular), { [theme.breakpoints.down('sm')]: {
                maxWidth: MAX_WIDTH_INNER,
                margin: '0 auto',
            }, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xxxl) }),
    },
    box: {
        '& > div, & > form': { maxWidth: MAX_WIDTH_INNER, margin: `0 auto` },
        '&:last-child': { marginTop: spacing.small },
        [theme.breakpoints.up('md')]: {
            border: `1px solid ${colors.supplementary.black}`,
            padding: spacing.small,
        },
        '& h2': Object.assign(Object.assign({ maxWidth: MAX_WIDTH_INNER, margin: `0 auto` }, theme.typography.medium_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
        '& p': Object.assign(Object.assign(Object.assign({ maxWidth: MAX_WIDTH_INNER, margin: `0 auto` }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    },
    caption: Object.assign({}, theme.typography.xxs_regular),
    error: { marginBottom: spacing.small },
    buttons: {
        marginTop: `${spacing.xl} !important`,
        marginBottom: spacing.medium,
    },
    saveButton: {
        marginBottom: `${spacing.medium} !important`,
    },
    input: {
        marginBottom: `${spacing.small} !important`,
    },
});
