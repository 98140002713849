import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
export const expandLinkStyles = (theme) => (Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), { justifyContent: 'space-between', width: '100%', [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }), (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, display: 'flex', alignItems: 'center', '& .material-icons': { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }, '&:hover': {
        color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4,
        textDecoration: 'none',
        '& .material-icons': { color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4 },
    } }));
const useStyles = makeStyles()((theme) => ({
    container: {},
    expandLink: expandLinkStyles(theme),
    details: {
        padding: `${spacing.small} 0`,
        [theme.breakpoints.up('md')]: {
            padding: `${spacing.medium} 0`,
        },
    },
    fieldContainer: {
        marginBottom: spacing.small,
    },
    textField: {
        color: colors.grey.grey6,
    },
    address: {
        fontStyle: 'normal',
    },
    cardMessage: {
        wordBreak: 'break-word',
    },
    textContent: {},
    disableField: {
        border: 'none !important',
        '& > div': {
            cursor: 'auto !important',
        },
        '&:hover': {
            border: 'none !important',
            cursor: 'auto',
        },
    },
    collectionTime: {
        marginBottom: '36px',
    },
    collectionContainer: {
        marginBottom: '36px',
    },
}));
export default useStyles;
