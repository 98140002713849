import { colors, spacing } from './variables';
const overflowWrapAnywhere = {
    overflowWrap: 'anywhere',
};
const hoverStyles = (theme) => ({
    boxShadow: theme.isFlyingFlowers ? '0px 4px 8px rgba(60, 60, 59, 0.32)' : '0px 4px 8px rgba(0, 0, 0, 0.32)',
    backgroundColor: colors.supplementary.white,
});
const focusStyles = {
    boxShadow: '0px 0px 8px #008BE9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
    backgroundColor: colors.supplementary.white,
};
const borderStyles = (theme, status) => {
    if (status === 'success') {
        return theme.isFlyingFlowers
            ? `2px solid ${theme.statusColors.success.base}`
            : `1px solid ${theme.statusColors.success.base}`;
    }
    return `2px solid ${theme.statusColors.error.base}`;
};
const getTextFieldStyles = (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey1,
    padding: spacing.xs,
    border: theme.isFlyingFlowers ? `1px solid ${colors.black[700]}` : `1px solid ${colors.grey.grey7}`,
    borderRadius: theme.isFlyingFlowers ? spacing.xxs : undefined,
    lineHeight: '1.43rem',
    '&:hover': hoverStyles(theme),
    '&:active': {
        backgroundColor: colors.supplementary.white,
    },
    '&:focus-within': focusStyles,
});
const getLabelStyles = (theme) => (Object.assign(Object.assign({}, theme.typography.small_bold), { display: 'block', color: theme.isFlyingFlowers ? colors.black[700] : undefined }));
const getErrorStyles = (theme) => ({
    border: borderStyles(theme, 'error'),
    backgroundColor: colors.supplementary.white,
    marginBottom: spacing.xs,
    padding: `calc(${spacing.xs} - 1px)`,
    '& input': {
        color: theme.isFlyingFlowers ? colors.supplementary.black : undefined,
    },
});
export const inputStyles = (theme, classes) => ({
    textFieldWrapper: {
        width: '30ch',
    },
    textField: getTextFieldStyles(theme),
    textFieldUnboxed: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.supplementary.white,
        padding: `${spacing.small} ${spacing.xs}`,
    },
    label: getLabelStyles(theme),
    labelCompleted: Object.assign(Object.assign(Object.assign({ display: 'block' }, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : undefined }),
    value: Object.assign(Object.assign({ width: '100%' }, theme.typography.small_regular), { backgroundColor: 'inherit', border: 0, '&:focus': {
            outline: 'none',
        } }),
    success: {
        border: borderStyles(theme, 'success'),
        padding: theme.isFlyingFlowers ? `calc(${spacing.xs} - 1px)` : undefined,
    },
    error: getErrorStyles(theme),
    disabled: {
        backgroundColor: `${colors.grey.grey2}!important`,
        border: theme.isFlyingFlowers ? `2px solid ${colors.grey.grey5}` : undefined,
        padding: theme.isFlyingFlowers ? `calc(${spacing.xs} - 1px)` : undefined,
        color: theme.isFlyingFlowers ? colors.black[600] : undefined,
        '&:hover': {
            backgroundColor: colors.grey.grey2,
            boxShadow: 'none !important',
        },
    },
    nonEditable: {
        backgroundColor: `${colors.grey.grey1} !important`,
        '&:hover': {
            backgroundColor: `${colors.grey.grey1} !important`,
        },
    },
    fullWidth: {
        width: '100%',
    },
    textContainer: {
        width: '100%',
        '& input': {
            height: '1.187em !important',
        },
    },
    errorMessage: {
        color: theme.statusColors.error.base,
    },
    icon: {
        color: colors.supplementary.black,
    },
    diabledIcon: {
        color: colors.grey.grey5,
    },
    textContent: Object.assign(Object.assign(Object.assign({ paddingRight: spacing.xs, display: 'block' }, overflowWrapAnywhere), theme.typography.medium_regular), { overflow: 'hidden' }),
    editIcon: {
        color: theme.isFlyingFlowers ? colors.black[700] : `${colors.primary.gold5} !important`,
    },
    touched: {
        backgroundColor: colors.supplementary.white,
    },
    completed: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
        padding: spacing.small,
        border: '1px solid transparent',
        '&:hover': {
            backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
            border: theme.isFlyingFlowers ? `1px solid ${colors.lazyLime[400]}` : `1px solid ${colors.primary.gold4}`,
            boxShadow: theme.isFlyingFlowers ? '0px 4px 8px rgba(60, 60, 59, 0.32)' : undefined,
        },
        '&:focus': {
            backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.primary.gold1,
            border: theme.isFlyingFlowers ? `1px solid ${colors.blue}` : `1px solid ${colors.primary.gold4}`,
        },
        '&$disabled': {
            cursor: 'auto',
            background: theme.isFlyingFlowers ? colors.grey.grey1 : undefined,
            '& *': {
                cursor: 'auto',
            },
            '&:hover': {
                border: '1px solid transparent',
            },
        },
        [`&.${classes === null || classes === void 0 ? void 0 : classes.error}`]: {
            border: `2px solid ${theme.statusColors.error.base}`,
            backgroundColor: theme.statusColors.error.light,
        },
    },
    buttonType: {
        cursor: 'pointer',
        '& *': {
            cursor: 'pointer',
        },
    },
    placeholder: {
        color: colors.grey.grey6,
    },
    newPlaceholder: {
        color: colors.black[600],
    },
    input: {
        marginBottom: spacing.small,
        color: theme.isFlyingFlowers ? colors.black[600] : undefined,
    },
    inputSpacingLarge: { marginBottom: spacing.large },
});
