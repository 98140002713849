import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6, textAlign: 'center', width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            alignItems: 'unset',
        } }),
    heading: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.xs, boxSizing: 'border-box', alignItems: 'center', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.small }) }),
}));
export default useStyles;
