import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Formik } from 'formik';
import { useState, useContext } from 'react';
import useStyles from './RemindersSummary.styles';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';
import { SiteContext } from '../../utils/common';
import Link from '../Link/Link';
import ReminderSummaryCard from './ReminderSummaryCard';
const RemindersSummary = (props) => {
    const { handleSaveOccasionReminder, occasionRemindersLists } = props;
    const [remindersList, setRemindersList] = useState([]);
    const { isFlyingFlowers, occasions } = useContext(SiteContext);
    const { classes } = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const initialValues = {
        occasionReminders: [
            {
                occasion: '',
                date: '',
                firstName: '',
                lastName: '',
            },
        ],
    };
    const formikConfig = {
        initialValues,
        onSubmit: () => handleSaveOccasionReminder &&
            handleSaveOccasionReminder({ occasionReminders: remindersList })
                .then(() => {
                setErrorMessage('');
            })
                .catch((error) => {
                setErrorMessage(error.message);
            }),
    };
    const buttonTitle = !!occasionRemindersLists && occasionRemindersLists.length > 1 ? 'Set reminders' : 'Set reminder';
    return (_jsxs("div", { className: classes.container, children: [errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsxs(Form, { children: [_jsx("h1", { children: isFlyingFlowers ? 'Next year nudge?' : 'Same time next year?' }), _jsx("div", { className: classes.content, children: isFlyingFlowers
                                ? "We can remind you this time next year that they're due another special delivery. Just pop in the details below and we'll drop you an email."
                                : 'We can help make sure you never miss this date. We’ll email you a reminder before it comes around again next year.' }), _jsx("div", { children: _jsx("div", { className: classes.reminderCard, children: occasionRemindersLists === null || occasionRemindersLists === void 0 ? void 0 : occasionRemindersLists.map((occasionReminder, index) => {
                                    var _a, _b;
                                    const occasionIcon = (_b = (_a = occasions === null || occasions === void 0 ? void 0 : occasions.find((occasion) => { var _a; return occasion.label.toLowerCase() === ((_a = occasionReminder === null || occasionReminder === void 0 ? void 0 : occasionReminder.occasion) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })) === null || _a === void 0 ? void 0 : _a.occasionReminders) === null || _b === void 0 ? void 0 : _b.occasionIcon;
                                    return (_jsx(ReminderSummaryCard, { occasion: occasionReminder.occasion, firstName: occasionReminder.firstName, lastName: occasionReminder.lastName, date: occasionReminder.date, remindersList: remindersList, setRemindersList: setRemindersList, icon: occasionIcon }, index));
                                }) }) }), _jsx("div", { className: classes.privacyInformation, children: _jsxs("p", { className: classes.privacyLinks, children: ["We will treat your personal information with respect and use it as detailed in our", ' ', _jsx(Link, { label: "Privacy Policy", url: "/page/privacy", className: classes.link }), " and", ' ', _jsx(Link, { label: "Cookie Policies", url: "/page/cookie-policy", className: classes.link }), "."] }) }), _jsx("div", { className: classes.buttons, children: _jsx(Button, { variant: isFlyingFlowers ? 'secondaryDark' : 'primary', icon: isFlyingFlowers ? 'sentiment_very_satisfied' : undefined, fullWidth: true, title: buttonTitle, isSubmit: true, disabled: isSubmitting }) }), _jsx("div", { className: classes.reminderMessage, children: "When you set up a reminder, we will send you a reminder email 10 and 5 days before the occasion date and on the day too (we don\u2019t want you to forget).\u202FIf at a later date you decide you don\u2019t want to receive reminder emails, please log into your account and delete your reminders." })] })) }))] }));
};
export default RemindersSummary;
