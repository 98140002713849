var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Formik } from 'formik';
import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import useStyles from './ConfirmationRegistration.styles';
import PasswordField, { passwordValidationSchema } from '../PasswordField/PasswordField';
import { validateAllErrorsForSchema } from '../../utils/common';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';
import { SiteContext } from '../../utils/common';
import ReminderSummaryCard from '../RemindersSummary/ReminderSummaryCard';
import Link from '../Link/Link';
import confirmationStyles from '../../styles/jss/confirmation';
const validationSchema = Yup.object().shape({
    password: passwordValidationSchema,
});
const ConfirmationRegistration = (props) => {
    const { onSubmit, orderId, consignmentReminders, saveUserJourneyAnalytics } = props;
    const { isFlyingFlowers, occasions } = useContext(SiteContext);
    const { classes } = useStyles();
    const { classes: confirmationClasses } = confirmationStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const [remindersList, setRemindersList] = useState([]);
    const [openPasswordRequirement, setOpenPasswordRequirement] = useState(false);
    const initialValues = {
        password: '',
        orderId,
    };
    useEffect(() => {
        const element = document.getElementById('confirmRegister');
        if (element)
            window === null || window === void 0 ? void 0 : window.scrollTo(0, (element === null || element === void 0 ? void 0 : element.offsetTop) - 80);
    }, [errorMessage]);
    const handleConfirmationRegistrationSubmission = (isSuccessful, error) => {
        setErrorMessage(error !== null && error !== void 0 ? error : '');
        saveUserJourneyAnalytics === null || saveUserJourneyAnalytics === void 0 ? void 0 : saveUserJourneyAnalytics({ journeyType: 'REGISTER', isSuccessful });
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        validate: validateAllErrorsForSchema(Yup.object().shape({
            password: passwordValidationSchema,
        })),
        onSubmit: (values) => onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(Object.assign(Object.assign({}, values), { occasionReminders: remindersList })).then(() => __awaiter(void 0, void 0, void 0, function* () {
            handleConfirmationRegistrationSubmission(true);
        })).catch((error) => {
            handleConfirmationRegistrationSubmission(false, error.message);
        }),
    };
    return (_jsxs("div", { className: confirmationClasses.container, id: "confirmRegister", children: [errorMessage && _jsx(Alert, { className: confirmationClasses.error, type: "alertError", message: errorMessage }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsxs(Form, { children: [_jsx("h1", { children: "Create an account" }), _jsx("div", { className: confirmationClasses.content, children: isFlyingFlowers
                                ? "Make an account and you can save this date. We'll send you an email to remind you about it next year – handy!"
                                : 'With an account you can save this date, so you don’t forget it. We’ll email you a reminder before it comes around again next year.' }), consignmentReminders === null || consignmentReminders === void 0 ? void 0 : consignmentReminders.map((consignmentReminder, index) => {
                            var _a, _b;
                            const occasion = occasions === null || occasions === void 0 ? void 0 : occasions.find((occasion) => { var _a; return occasion.label.toLowerCase() === ((_a = consignmentReminder === null || consignmentReminder === void 0 ? void 0 : consignmentReminder.occasion) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
                            return (_jsx(ReminderSummaryCard, { occasion: (_a = occasion === null || occasion === void 0 ? void 0 : occasion.label) !== null && _a !== void 0 ? _a : '', firstName: consignmentReminder === null || consignmentReminder === void 0 ? void 0 : consignmentReminder.firstName, lastName: consignmentReminder === null || consignmentReminder === void 0 ? void 0 : consignmentReminder.lastName, date: consignmentReminder.date, remindersList: remindersList, setRemindersList: setRemindersList, icon: (_b = occasion === null || occasion === void 0 ? void 0 : occasion.occasionReminders) === null || _b === void 0 ? void 0 : _b.occasionIcon }, index));
                        }), _jsx("p", { className: classes.reminderDescription, children: isFlyingFlowers
                                ? 'Now just pop in a password to finish setting up your account and reminder.'
                                : 'Now just add your password and you’ll be all set up with an account and a reminder.' }), _jsx("div", { children: _jsx(PasswordField, { id: "password", name: "password", label: isFlyingFlowers ? 'Password' : 'Create a password', placeholder: isFlyingFlowers ? 'e.g. OpenSe5ame' : 'Enter a password', fullWidth: true, required: true, newPassword: openPasswordRequirement }) }), _jsx(Link, { className: classes.passwordRequirements, icon: "info", iconFontSize: "small", label: "Password requirements", onClick: () => setOpenPasswordRequirement(!openPasswordRequirement) }), _jsx("div", { className: confirmationClasses.buttons, children: _jsx(Button, { variant: isFlyingFlowers ? 'secondaryDark' : 'primary', icon: isFlyingFlowers ? 'account_circle' : undefined, title: "Create account", isSubmit: true, disabled: isSubmitting }) }), _jsx("div", { className: confirmationClasses.reminderMessage, children: "When you set up a reminder, we will send you a reminder email 10 and 5 days before the occasion date and on the day too (we don\u2019t want you to forget).\u202FIf at a later date you decide you don\u2019t want to receive reminder emails, please log into your account and delete your reminders." })] })) }))] }));
};
export default ConfirmationRegistration;
