import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    passwordRequirements: {
        marginBottom: spacing.large,
        [`& .${classes.passwordCaption}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.supplementary.black }),
        '& li': {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '12px',
            color: colors.grey.grey6,
            '&:before': {
                content: '""',
                borderColor: `transparent ${colors.grey.grey6}`,
                borderStyle: 'solid',
                borderWidth: '3px 0 3px 3px',
                height: 0,
                width: 0,
                left: '-10px',
                position: 'relative',
            },
            [`&.${classes.success}`]: {
                color: theme.statusColors.success.base,
            },
            [`&.${classes.error}`]: {
                color: theme.statusColors.error.base,
            },
        },
    },
    passwordCaption: {},
    label: Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6, '&:first-letter': {
            textTransform: 'uppercase',
        }, [`&.${classes.success}`]: {
            color: theme.statusColors.success.base,
        }, [`&.${classes.error}`]: {
            color: theme.statusColors.error.base,
        } }),
    success: {},
    error: {},
}));
export default useStyles;
