import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from 'next/link';
import { useContext } from 'react';
import { myAccountMenuItems } from '../../common/data/menuItems.data';
import { SiteContext } from '../../utils/common';
import Icon from '../Icon/Icon';
import LinkUI from '../Link/Link';
import useStyles from './MyAccountMenu.styles';
const MyAccountMenu = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { signOut } = props;
    return (_jsx("div", { className: classes.container, children: _jsxs("ul", { className: classes.menuNavigationLinks, children: [_jsx("li", { "data-testid": `My account`, children: _jsx(Link, { href: "/account", passHref: true, legacyBehavior: true, children: _jsxs("a", { className: classes.link, children: [_jsx("span", { className: classes.label, children: "My account" }), isFlyingFlowers ? (_jsx(Icon, { icon: "arrow_forward", className: classes.forwardIcon, fontSize: "inherit" })) : null] }) }) }), myAccountMenuItems
                    ? myAccountMenuItems.map((menuItem) => (_jsx("li", { "data-testid": menuItem.title, children: _jsx(Link, { href: menuItem.url, passHref: true, legacyBehavior: true, children: _jsxs("a", { className: classes.link, children: [menuItem.icon ? _jsx(Icon, { icon: menuItem.icon, className: classes.icon, fontSize: "inherit" }) : null, _jsx("span", { className: classes.label, children: menuItem.title }), isFlyingFlowers ? (_jsx(Icon, { icon: "arrow_forward", className: classes.forwardIcon, fontSize: "inherit" })) : null] }) }) }, menuItem.title)))
                    : null, _jsx("li", { className: classes.signOutLink, "data-testid": `Sign out`, children: _jsx(LinkUI, { onClick: () => {
                            signOut && signOut();
                        }, label: "Sign out" }) })] }) }));
};
export default MyAccountMenu;
