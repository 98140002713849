import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './OrderDetail.styles';
import OrderSummary from '../OrderSummary/OrderSummary';
import OrderConfirmationGiftSummary from '../OrderConfirmation/OrderConfirmationGiftSummary/OrderConfirmationGiftSummary';
import Button from '../Button/Button';
import PreloadedComponent from '../PreloadedComponent/PreloadedComponent';
import MyAccountHelp, { AMPLIENCE_KEY } from '../MyAccountHelp/MyAccountHelp';
const OrderDetail = (props) => {
    const { order, farEyeTrackOrderAnalytics } = props;
    const { classes } = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: classes.orderDetailTitle, children: "Order details" }), _jsx("div", { className: classes.orderDetailSummary, children: _jsx(OrderSummary, { order: order, farEyeTrackOrderAnalytics: farEyeTrackOrderAnalytics }) }), _jsx("div", { className: classes.orderDetailsHelpContainer, children: _jsx(PreloadedComponent, { Component: MyAccountHelp, componentKey: AMPLIENCE_KEY }) }), _jsx("div", { id: `order_${order.orderNumber}`, children: _jsx(OrderConfirmationGiftSummary, { order: order, isFromOrderDetails: true }) }), _jsx("div", { className: classes.orderDetailButton, children: _jsx(Button, { title: "Continue shopping", fullWidth: true, variant: "secondary", href: "/" }) })] }));
};
export default OrderDetail;
