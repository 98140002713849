import { makeStyles } from 'tss-react/mui';
import { addWidthInner, link, minMargin, widthOuter } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const tabletBreakpoint = 'xs';
const contentWidth = 386;
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    footer: {
        background: `linear-gradient(${colors.supplementary.white} ${theme.isFlyingFlowers ? 51 : 54}px, ${colors.grey.grey1} 
    ${theme.isFlyingFlowers ? 51 : 54}px, ${colors.grey.grey1} 100%)`,
        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(${colors.supplementary.white} ${theme.isFlyingFlowers ? 59 : 74}px, ${colors.grey.grey1} ${theme.isFlyingFlowers ? 59 : 74}px, ${colors.grey.grey1} 100%)`,
        },
        '& a': {
            textDecoration: 'underline',
        },
    },
    // Outers & Inners
    footerOuter: Object.assign({}, widthOuter),
    footerInner: addWidthInner(theme, {}),
    legalOuter: Object.assign(Object.assign({}, widthOuter), { [theme.breakpoints.up('md')]: {
            borderTop: `1px solid ${colors.black[300]}`,
        } }),
    legalInner: addWidthInner(theme, {}),
    // Main Content
    gridContainer: {
        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: `${contentWidth}px 1fr`,
            gridColumnGap: '106px',
        },
        [theme.breakpoints.up('lg')]: {
            gridColumnGap: '54px',
        },
    },
    logo: {
        margin: `0 auto ${spacing.medium} auto`,
        width: theme.isFlyingFlowers ? '334px' : '108px',
        maxWidth: theme.isFlyingFlowers ? '334px' : '108px',
        [theme.breakpoints.up('md')]: {
            margin: `0 0 ${spacing.xl} 0`,
            gridRow: 1,
            width: theme.isFlyingFlowers ? '386px' : '148px',
            maxWidth: theme.isFlyingFlowers ? '386px' : '148px',
        },
    },
    sectionContainer: {
        [theme.breakpoints.up(tabletBreakpoint)]: {
            maxWidth: `${contentWidth}px`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
            gridRow: 2,
            position: 'relative',
            marginRight: spacing.small,
        },
    },
    description: Object.assign(Object.assign({ textAlign: 'center', '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg) }), '& p': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm) }, theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up(tabletBreakpoint)]: {
            // Half the padding between the sides of the inner and the container
            marginLeft: `calc((100vw - ${minMargin} - ${contentWidth}px) / -4)`,
            marginRight: `calc((100vw - ${minMargin} - ${contentWidth}px) / -4)`,
        }, [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            marginLeft: '0px',
            marginRight: '0px',
        } }),
    actionContainer: {
        margin: `0 auto ${spacing.xl} auto`,
        '& button': {
            width: '100%',
            marginBottom: spacing.medium,
        },
        '& > :last-child': {
            marginBottom: 0,
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    accordianContainer: {
        marginBottom: spacing.large,
        '& .MuiAccordion-root': {
            marginLeft: '-16px',
            marginRight: '-16px',
            '&.Mui-expanded': {
                margin: '0 -16px !important',
            },
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    socialContainer: {
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        },
    },
    linksContainer: {
        [theme.breakpoints.up('md')]: {
            gridRow: 2,
        },
    },
    lists: {
        justifyContent: 'right',
        display: 'grid',
        gridTemplateColumns: `repeat(2, minmax(222px, 286px))`,
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: `repeat(4, minmax(195px, 248px))`,
        },
    },
    linkColumn: {
        marginBottom: spacing.xl,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xxl,
        },
        '& li': {
            marginBottom: spacing.xs,
        },
        '&:not(:last-child) li': {
            marginRight: spacing.small,
        },
        '& a, & span.no-link': Object.assign(Object.assign({}, theme.typography.small_regular), { color: theme.isFlyingFlowers ? undefined : colors.grey.grey6 }),
        '& a': Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
    },
    column: {
        maxWidth: 222,
        [theme.breakpoints.up('md')]: {
            maxWidth: 286,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 195,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 248,
        },
    },
    groupHeading: Object.assign(Object.assign({}, theme.typography.medium_bold), { color: theme.isFlyingFlowers ? undefined : colors.supplementary.black }),
    // Legal Content
    legal: {
        '& p': Object.assign({}, theme.typography.xxs_regular),
        '& ul li': Object.assign({}, theme.typography.xxs_regular),
        '& ul': {
            paddingInlineStart: theme.isFlyingFlowers ? 0 : undefined,
        },
        margin: `${spacing.large} auto`,
        textAlign: 'center',
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            margin: `${spacing.large} 0`,
        },
    },
    innerSection: addWidthInner(theme, {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }),
    linkAsScript: {
        marginRight: spacing.small,
        marginBottom: spacing.xs,
    },
    unset: {
        all: 'unset',
    },
    cookiePreferenceLink: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), { color: theme.isFlyingFlowers ? undefined : colors.grey.grey6 }), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
}));
export default useStyles;
