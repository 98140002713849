import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Icon from '../Icon/Icon';
import useStyles from './FilterButton.styles';
const FilterButton = ({ title, disabled, fullWidth, onClick, open, active, resultCount, name, icon, className, }) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    return (_jsxs(Button, { name: name || 'button', disabled: disabled, onClick: onClick, fullWidth: fullWidth, endIcon: _jsx(Box, { sx: { display: { zero: 'none', xs: 'block', md: 'none' }, height: '24px' }, children: icon === 'tune' ? _jsx(_Fragment, {}) : open ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) }), className: clsx(classes.filterButton, open && isFlyingFlowers ? classes.open : '', className !== null && className !== void 0 ? className : '', icon ? classes.iconButton : ''), children: [_jsx("span", { className: classes.title, children: title }), active && _jsxs("span", { className: classes.resultCount, children: ["(", resultCount, ")"] }), !!icon && _jsx(Icon, { icon: icon, className: classes.icon })] }));
};
export default FilterButton;
