import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Link from 'next/link';
import useStyles from './Tags.styles';
import { Box } from '@mui/material';
const isActiveLink = (tag, activeLinkItem) => activeLinkItem && typeof tag !== 'string' && tag.url === activeLinkItem;
const Tags = (props) => {
    const { tags, thinOnMobile, className, activeLinkItem, isFromRelatedContentItem, blogArticleGalleryUrlAnalytics } = props;
    const { classes } = useStyles();
    return (_jsx("ul", { className: clsx(classes.tags, isFromRelatedContentItem ? classes.adjustTagSpacing : ''), children: tags.map((tag) => (_jsx(Box, { component: 'li', className: clsx(classes.tag, thinOnMobile && classes.tagSmall, className, isActiveLink(tag, activeLinkItem) && classes.activeLinkItem), children: typeof tag === 'string' ? (_jsx("span", { children: tag })) : (_jsx(Link, { href: `${tag.url}`, passHref: true, legacyBehavior: true, children: _jsx("a", { href: `${tag.url}`, onClick: () => {
                        blogArticleGalleryUrlAnalytics === null || blogArticleGalleryUrlAnalytics === void 0 ? void 0 : blogArticleGalleryUrlAnalytics(tag.url);
                    }, children: _jsxs("span", { children: [tag.name, " "] }) }) })) }, typeof tag === 'string' ? tag : tag.name))) }));
};
export default Tags;
