import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { currencyFormatPartial, filterAttributeNameMapping, removeSessionItems, SiteContext } from '../../utils/common';
import FacetSelectionDesktop from '../FacetSelectionDesktop/FacetSelectionDesktop';
import FacetSelectionMobile from '../FacetSelectionMobile/FacetSelectionMobile';
import FFFilterList from '../FilterList/FFFilterList';
import FilterList from '../FilterList/FilterList';
import useStyles from './ProductListingFilter.styles';
import { useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useInstantSearch } from 'react-instantsearch';
export const generatePriceRanges = (currency, isFlyingFlowers) => {
    const ranges = isFlyingFlowers
        ? [{ end: 25 }, { start: 25, end: 30 }, { start: 30, end: 35 }, { start: 35, end: 40 }, { start: 40 }]
        : [{ end: 49.99 }, { start: 50, end: 75 }, { start: 75, end: 100 }, { start: 100, end: 150 }, { start: 150 }];
    return ranges.map((range) => {
        const { start = 0.0, end } = range;
        if (end) {
            if (isFlyingFlowers && end === 25) {
                return Object.assign(Object.assign({}, range), { label: `${currencyFormatPartial(end, 2, currency)} and Under` });
            }
            else if (!isFlyingFlowers && start === 0) {
                return Object.assign(Object.assign({}, range), { label: `Under ${currencyFormatPartial(end + 0.01, 2, currency)}` });
            }
            else {
                return Object.assign(Object.assign({}, range), { label: `${currencyFormatPartial(start, 2, currency)} - ${currencyFormatPartial(end, 2, currency)}` });
            }
        }
        else {
            return Object.assign(Object.assign({}, range), { label: `${currencyFormatPartial(start, 2, currency)}${isFlyingFlowers ? ' +' : '+'}` });
        }
    });
};
const reducer = (state, action) => {
    const clonedFilters = _cloneDeep(state.filters || []);
    switch (action.type) {
        case 'setFilterOpen':
            const { attributeName, showFacetList } = action.payload;
            clonedFilters.forEach((filter) => {
                filter.isOpen = filter.attributeName === attributeName;
            });
            return { filters: clonedFilters, facetListOpen: showFacetList };
        case 'setFiltersClosed':
            const updatedFilters = clonedFilters.map((filter) => (Object.assign(Object.assign({}, filter), { isOpen: false })));
            return Object.assign(Object.assign({}, state), { filters: updatedFilters });
        case 'setFacetListOpen':
            return Object.assign(Object.assign({}, state), { facetListOpen: action.payload });
        case 'resetFilters':
            return Object.assign(Object.assign({}, state), { filters: action.payload });
        default:
            throw new Error('Action type not identified');
    }
};
const sessionItem = ['sessionFilers'];
const ProductListingFilter = ({ currency = 'GBP', sortConfig, facetDetails }) => {
    var _a, _b, _c;
    const { results: searchResults, indexUiState: searchState, indexRenderState: { sortBy }, } = useInstantSearch();
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const totalResultCount = searchResults && searchResults.nbHits;
    const dynamicRuleFilters = (_c = (_b = (_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.renderingContent) === null || _a === void 0 ? void 0 : _a.facetOrdering) === null || _b === void 0 ? void 0 : _b.facets) === null || _c === void 0 ? void 0 : _c.order;
    const [deviceType, setDeviceType] = useState('');
    const theme = useTheme();
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const allFilters = [
        'sortBy',
        ...(dynamicRuleFilters && dynamicRuleFilters.length
            ? dynamicRuleFilters
            : Array.from(filterAttributeNameMapping(isFlyingFlowers).keys()).filter((item) => item !== 'sortBy')),
    ];
    const initialFilters = allFilters
        .map((filter) => ({
        attributeName: filter,
        attributeLabel: filterAttributeNameMapping(isFlyingFlowers).get(filter),
        isOpen: false,
    }))
        .filter((decoratedFilter) => !!decoratedFilter.attributeLabel);
    const initialFiltersRef = useRef(initialFilters);
    const initialState = {
        filters: initialFilters,
        facetListOpen: false,
    };
    const priceRanges = generatePriceRanges(currency, isFlyingFlowers);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const getSortByLabel = () => {
        var _a;
        const labels = sortConfig.reduce((obj, item) => {
            obj[item.value] = item.label;
            return obj;
        }, {});
        return labels[(_a = sortBy === null || sortBy === void 0 ? void 0 : sortBy.currentRefinement) !== null && _a !== void 0 ? _a : searchResults.index];
    };
    useEffect(() => {
        if (!_isEqual(initialFiltersRef.current, initialFilters)) {
            dispatch({ type: 'resetFilters', payload: initialFilters });
            initialFiltersRef.current = initialFilters;
        }
    }, [initialFilters]);
    useEffect(() => {
        const deviceName = isMobileDevice ? 'mobile' : 'desktop';
        setDeviceType(deviceName);
    }, [isMobileDevice]);
    const commonProps = {
        sortConfig,
        sortBy: getSortByLabel(),
        filters: state.filters,
        setFiltersClosed: () => {
            removeSessionItems(sessionItem);
            dispatch({ type: 'setFiltersClosed' });
        },
    };
    const filterListProps = {
        refinementList: searchState.refinementList,
        setFacetListOpen: (attributeName) => dispatch({ type: 'setFilterOpen', payload: { attributeName, isOpen: true, showFacetList: true } }),
    };
    return (_jsxs("div", { className: classes.productListingFilter, children: [isFlyingFlowers ? (_jsx(FFFilterList, Object.assign({ totalResultCount: totalResultCount }, filterListProps, commonProps))) : (_jsx(FilterList, Object.assign({ totalResultCount: totalResultCount }, filterListProps, commonProps, { selectedFilter: selectedFilter, setSelectedFilter: setSelectedFilter }))), deviceType === 'mobile' && (_jsx(FacetSelectionMobile, Object.assign({ totalResultCount: totalResultCount, open: state.facetListOpen, priceRanges: priceRanges, setFacetListOpen: (isOpen) => dispatch({ type: 'setFacetListOpen', payload: isOpen }), setFilterOpen: (attributeName) => dispatch({ type: 'setFilterOpen', payload: { attributeName, isOpen: true, showFacetList: true } }) }, commonProps, { selectedFilter: selectedFilter, setSelectedFilter: setSelectedFilter, refinementList: searchState.refinementList, facetDetails: facetDetails }))), deviceType === 'desktop' && (_jsx(FacetSelectionDesktop, Object.assign({ totalResultCount: totalResultCount, open: state.facetListOpen, priceRanges: priceRanges, setFacetListOpen: (isOpen) => dispatch({ type: 'setFacetListOpen', payload: isOpen }) }, commonProps, { selectedFilter: selectedFilter, setSelectedFilter: setSelectedFilter, facetDetails: facetDetails })))] }));
};
export default ProductListingFilter;
