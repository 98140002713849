import { makeStyles } from 'tss-react/mui';
import { colors, spacing, typographySohne } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params, classes) => {
    var _a, _b;
    return ({
        heading: Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column' }, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { marginBottom: params.homeHeroBannersList ? `${spacing.medium}!important` : spacing.small, color: colors.supplementary.white, [theme.breakpoints.up('sm')]: {
                marginBottom: params.homeHeroBannersList ? `${spacing.xs}!important` : spacing.small,
            }, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxl_bold), theme.typographySpacing.xxl_bold_sm), { marginBottom: params.homeHeroBannersList ? `${spacing.large}!important` : spacing.small }), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), theme.typographySpacing.xxxxl_sm) }),
        subheading: { color: colors.supplementary.white },
        message: Object.assign(Object.assign(Object.assign({ color: colors.supplementary.white }, typographySohne.small_bold), theme.typographySpacing.none), { marginBottom: params.homeHeroBannersList ? spacing.xs : 'initial', [theme.breakpoints.up('sm')]: {
                marginBottom: `${spacing.medium} !important`,
            }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, typographySohne.medium_bold), { marginBottom: `${spacing.large} !important` }), [theme.breakpoints.up(1920)]: Object.assign(Object.assign({}, typographySohne.medium_bold), { marginBottom: `${spacing.xl} !important` }) }),
        linkContainer: {
            display: params.homeHeroBannersList ? 'inline-flex !important' : 'none',
            maxWidth: '700px',
            flexDirection: 'column',
            '& .MuiButtonBase-root.MuiButton-root': {
                // padding: '15px 24px 13px 24px',
                '& .MuiButton-label': {
                    '& span': {
                        padding: '0 !important',
                    },
                },
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                width: '100%',
                margin: '0 auto',
                justifyContent: 'left',
                display: 'inline-flex !important',
                maxWidth: 'initial',
                [`& .${classes.link}`]: {
                    width: '50%',
                    margin: 0,
                    '& button': { width: '100%' },
                    '&:not(:last-child)': {
                        marginBottom: '0',
                        marginRight: spacing.medium,
                    },
                },
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 'initial',
                width: '700px',
                [`& .${classes.link}`]: {
                    width: '46%',
                },
            },
            [theme.breakpoints.up('lg')]: {
                [`& .${classes.link}`]: {
                    width: '50%',
                },
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        showLinksOnGallery: {
            display: 'flex',
        },
        outsideLinkContainer: {
            marginTop: spacing.small,
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: !params.showTrustpilotWidget ? spacing.medium : '0px',
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        link: {
            width: '100%',
            display: 'flex',
            marginBottom: spacing.small,
            '&:last-child': {
                marginBottom: 0,
            },
            '& button': {
                flexGrow: 1,
            },
            [theme.breakpoints.up('sm')]: {
                width: '50%',
                margin: 0,
                '& button': { width: '100%' },
                '&:not(:last-child)': {
                    marginBottom: '0',
                    marginRight: spacing.medium,
                },
            },
            [theme.breakpoints.up('md')]: {
                width: '46%',
            },
        },
        outsideShowTrustpilotWidget: {
            minHeight: '60px',
            display: 'flex',
            marginTop: params.linksCount ? spacing.medium : spacing.small,
            marginLeft: '20px',
            marginRight: '20px',
            padding: '18px',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        homeGallerylinksOnMobile: {
            display: 'none',
        },
        content: {
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'none',
            textAlign: 'left',
            width: '100%',
            height: 'initial',
            padding: `${spacing.small} ${spacing.medium} ${spacing.medium}`,
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                width: '75%',
            },
            [theme.breakpoints.up('xl')]: {
                width: '50%',
            },
        },
        contentInner: {
            margin: '0 !important',
        },
        galleryLinkContainer: {
            display: params.homeHeroBannersList ? 'inline-flex' : 'none',
            [`& .${classes.link}`]: {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: '50%',
                },
                margin: 0,
                '& button': { width: '100%' },
                '&:not(:last-child)': {
                    marginBottom: spacing.small,
                    [theme.breakpoints.up('sm')]: {
                        marginRight: `${spacing.small} !important`,
                        marginBottom: '0 !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        marginBottom: 0,
                    },
                    [theme.breakpoints.up(1920)]: {
                        marginRight: `${spacing.medium} !important`,
                    },
                },
            },
            [theme.breakpoints.between(1024, 1120)]: {
                width: '100% !important',
            },
        },
        fontSohne: {
            [`& .${classes.heading}`]: Object.assign(Object.assign({}, typographySohne.xl_bold), { [theme.breakpoints.up('md')]: Object.assign({}, typographySohne.xxl_bold), [theme.breakpoints.up('xl')]: Object.assign({}, typographySohne.xxxxxl) }),
        },
        container: {
            [theme.breakpoints.down('xl')]: {
                minHeight: (_a = params.height) !== null && _a !== void 0 ? _a : '384px',
                height: (_b = params.height) !== null && _b !== void 0 ? _b : '384px',
                '@media(orientation: portrait)': {
                    placeContent: 'unset',
                },
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: '344px',
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '476px',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '600px',
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: '824px',
            },
        },
    });
});
export default useStyles;
