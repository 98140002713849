import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { spotlightBannerBackgroundClass } from '../../styles/jss/utils';
import useStyles from './SpotlightBanner.styles';
import SpotlightBannerText from './SpotlightBannerText';
import Button from '../Button/Button';
import { useRouter } from 'next/router';
import SpotlightBannerDesktop from './SpotlightBannerDesktop';
import SpotlightBannerMobile from './SpotlightBannerMobile';
import SpotlightBannerLinkWrapper from './SpotlightBannerLinkWrapper';
import { useLoaded } from '../MainContent/useComponentLoading';
import { useMobileDeviceCheck } from '../../utils/utilityCustomHooks';
const SpotlightBanner = (props) => {
    const router = useRouter();
    const { bannerLink } = props;
    const { title, body, backgroundColor, transparentOverlay, buttonLink, backgroundImage, mobileBackgroundImage, sendSpotlightBannerAnalytics, } = props;
    const { bgClass } = spotlightBannerBackgroundClass(backgroundColor);
    const { classes } = useStyles({ transparentOverlay });
    const { isMobile: isMobileView } = useMobileDeviceCheck();
    const handleClick = () => {
        if (buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.url) {
            sendSpotlightBannerAnalytics === null || sendSpotlightBannerAnalytics === void 0 ? void 0 : sendSpotlightBannerAnalytics(buttonLink.label);
            router.push(buttonLink.url);
        }
    };
    useLoaded(props);
    return (_jsx(SpotlightBannerLinkWrapper, { buttonLink: buttonLink, bannerLink: bannerLink, sendSpotlightBannerAnalytics: sendSpotlightBannerAnalytics, children: _jsx("div", { className: classes.spotlightWrapper, children: (!isMobileView && !backgroundImage) || (!backgroundImage && !mobileBackgroundImage) ? (_jsxs("div", { className: clsx(classes.container, bgClass), children: [_jsx(SpotlightBannerText, { title: title, body: body }), (buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.url) ? (_jsx(Button, { onClick: handleClick, title: buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.label, variant: 'primary', thin: true, icon: '', className: classes.linkButton })) : null] })) : (_jsx(_Fragment, { children: isMobileView ? (_jsx(SpotlightBannerMobile, Object.assign({}, props, { handleClick }))) : (_jsx(SpotlightBannerDesktop, Object.assign({}, props, { handleClick }))) })) }) }));
};
export default SpotlightBanner;
