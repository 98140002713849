import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        border: `${theme.isFlyingFlowers ? '2px' : '1px'} solid ${theme.isFlyingFlowers ? theme.statusColors.warning.base : theme.statusColors.warning.dark}`,
        marginBottom: spacing.medium,
    },
    icon: {
        color: theme.isFlyingFlowers ? theme.statusColors.warning.base : colors.primary.gold5,
        margin: `15px 9px 9px`,
        '& .material-icons': {
            fontSize: '22px',
        },
    },
    header: Object.assign({ display: 'inline-block', margin: 0 }, theme.typography.small_bold),
    textContainer: {
        margin: `11px ${spacing.xs} 9px 0`,
    },
    body: Object.assign({ margin: 0 }, theme.typography.small_regular),
}));
export default useStyles;
