import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    reminderDescription: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg),
    passwordRequirements: Object.assign(Object.assign(Object.assign({ display: 'flex' }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, '&:first-child': {
            paddingRight: '10px',
        }, '& :last-child': {
            textDecoration: 'underline',
            marginBottom: spacing.medium,
        } }),
}));
export default useStyles;
