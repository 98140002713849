import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import useStyles from './HomeHeroBannerGallery.styles';
import Carousel from '../Carousel/Carousel';
import RoundButton from '../RoundButton/RoundButton';
import clsx from 'clsx';
import { useCalculateTransitionTime } from '../../utils/utilityCustomHooks';
import HomeHeroBanner from '../HomeHeroBanner/HomeHeroBanner';
import { useLoaded } from '../MainContent/useComponentLoading';
import { useTheme } from '@mui/material';
const CustomButtonGroup = (props) => {
    const { next, previous } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs("div", { className: classes.customButtonGroup, children: [_jsx(RoundButton, { className: classes.leftButton, ariaLabel: 'chevron left arrow', icon: 'chevron_left', onClick: () => {
                    previous === null || previous === void 0 ? void 0 : previous();
                } }), _jsx(RoundButton, { ariaLabel: 'chevron right arrow', icon: 'chevron_right', onClick: () => {
                    next === null || next === void 0 ? void 0 : next();
                }, className: classes.rightButton })] }));
};
const HomeHeroBannerGallery = (props) => {
    const { homeHeroBannersList, showTrustpilotWidget, trustpilotBackground, trustPilotDarknessLevel, transitionDelay, onLoaded, } = props;
    const [height, setHeight] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const { classes } = useStyles({ height });
    const carouselRef = useRef(null);
    const theme = useTheme();
    const { sm, md, xl } = theme.breakpoints.values;
    const [trustpilotDarkness, setTrustpilotDarkness] = useState('rgba(255,255,255,100%)');
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: 1,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 1,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 1,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    const calculateMaxLiHeight = useCallback(() => {
        let max = 0;
        homeHeroBannersList.forEach((banner) => {
            var _a;
            if (banner.links && banner.links.length > max) {
                max = (_a = banner.links) === null || _a === void 0 ? void 0 : _a.length;
            }
        });
        max === 3 ? setHeight(504) : max === 2 ? setHeight(444) : max === 1 ? setHeight(384) : setHeight(334);
    }, [homeHeroBannersList]);
    useEffect(() => {
        calculateMaxLiHeight();
    }, [calculateMaxLiHeight]);
    useEffect(() => {
        var _a, _b;
        if ((_b = (_a = carouselRef === null || carouselRef === void 0 ? void 0 : carouselRef.current) === null || _a === void 0 ? void 0 : _a.containerRef) === null || _b === void 0 ? void 0 : _b.current) {
            setIsLoaded(true);
        }
        window.addEventListener('orientationchange', calculateMaxLiHeight);
        window.removeEventListener('orientationchange', calculateMaxLiHeight);
    }, [calculateMaxLiHeight, carouselRef]);
    useEffect(() => {
        const trustPilotDarknessAttributes = typeof trustPilotDarknessLevel === 'number' ? `rgba(255,255,255, ${trustPilotDarknessLevel}%)` : null;
        trustPilotDarknessAttributes && setTrustpilotDarkness(trustPilotDarknessAttributes);
    }, [trustPilotDarknessLevel]);
    const delayInSeconds = useCalculateTransitionTime(transitionDelay);
    return (_jsxs(_Fragment, { children: [_jsx(Carousel, { arrows: false, autoPlay: isLoaded, autoPlaySpeed: delayInSeconds, carouselContainerClass: clsx(classes.carousel, showTrustpilotWidget ? classes.truspilotContainer : ''), customButtonGroup: _jsx(CustomButtonGroup, { onLoaded: onLoaded }), infinite: true, itemClass: classes.carouselItem, partialVisbile: false, ref: carouselRef, renderDotsOutside: true, responsive: responsive, showDots: true, sliderClass: classes.slider, slidesToSlide: 1, ssr: false, swipeable: true, dotListClass: classes.carouselDotList, children: homeHeroBannersList.map((homeHeroBanner, index) => {
                    var _a;
                    return (_jsx(HomeHeroBanner, Object.assign({}, homeHeroBanner, { homeHeroBannersList: homeHeroBannersList.length, contentPosition: 'CENTER', height: height, ignoreMobileImageProp: true }), `home_hero_banner_gallery_${(_a = homeHeroBanner.image) === null || _a === void 0 ? void 0 : _a.url}`));
                }) }), showTrustpilotWidget && (_jsx("div", { className: classes.outsideShowTrustpilotWidget, style: { backgroundColor: trustpilotBackground === 'BLACK' ? 'transparent' : trustpilotDarkness }, children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) }))] }));
};
export default HomeHeroBannerGallery;
