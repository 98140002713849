import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    tags: {
        display: 'flex',
        justifyContent: theme.isFlyingFlowers ? 'left' : 'center',
        '& ul': {
            justifyContent: theme.isFlyingFlowers ? 'left' : 'center',
            marginLeft: '-6px',
            marginRight: '-6px',
            [theme.breakpoints.up('md')]: {
                marginLeft: '-23px',
                marginRight: '-23px',
                justifyContent: 'center',
            },
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
        overflow: 'hidden',
    },
    tagChipsItems: {
        backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.grey.grey1,
        color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
        marginLeft: '6px',
        marginRight: '6px',
        borderRadius: theme.isFlyingFlowers ? '8px' : 'none',
        '& > a': Object.assign(Object.assign({}, theme.typography.small_regular), { padding: '7px 12px !important', display: 'inline-block' }),
        marginBottom: '12px',
        [theme.breakpoints.up('md')]: {
            '& > a': Object.assign(Object.assign({}, theme.typography.medium_regular), { display: 'inline-block', padding: `10px ${spacing.small} !important` }),
            marginLeft: '23px',
            marginRight: '23px',
        },
    },
}));
export default useStyles;
