import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import useStyles from './GenericGalleryGrid.styles';
import Button from '../Button/Button';
import SearchShowMore from '../SearchShowMore/SearchShowMore';
import { Box } from '@mui/material';
const GenericGalleryGrid = (props) => {
    const { items, className, hasAlgoliaResults, blogArticleGalleryUrlAnalytics, isInspiration = false, } = props;
    const { ItemComponent, componentProps } = props;
    const initialVisibleItems = props.initialVisibleItems || (items === null || items === void 0 ? void 0 : items.length) || 4;
    const itemsPerLoadMore = props.itemsPerLoadMore || 4;
    const { classes } = useStyles();
    const [visibleItems, setVisibleItems] = useState(initialVisibleItems);
    const viewableItems = hasAlgoliaResults ? items : items === null || items === void 0 ? void 0 : items.slice(0, visibleItems);
    const LoadMore = () => setVisibleItems(visibleItems + itemsPerLoadMore);
    return (items === null || items === void 0 ? void 0 : items.length) ? (_jsxs(_Fragment, { children: [_jsx(Box, { className: clsx(classes.gridContainer, className), children: viewableItems === null || viewableItems === void 0 ? void 0 : viewableItems.map((item, index) => (_jsx(Box, { className: classes.gridItem, children: _jsx(ItemComponent, Object.assign({}, item, componentProps, { isInspiration: isInspiration, blogArticleGalleryUrlAnalytics: blogArticleGalleryUrlAnalytics })) }, `card_${index}`))) }), hasAlgoliaResults ? (_jsx("div", { className: classes.loadMore, children: _jsx(SearchShowMore, { isInspiration: !!isInspiration }) })) : items.length > visibleItems ? (_jsx("div", { className: classes.loadMore, children: _jsx(Button, { className: classes.loadMoreButton, variant: "tertiary", thin: true, title: "Load more", onClick: LoadMore }) })) : null] })) : null;
};
export default GenericGalleryGrid;
