import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import useStyles from './RawHtml.styles';
import { Box } from '@mui/material';
const RawHtml = React.forwardRef(({ content, className }, ref) => {
    const router = useRouter();
    const { classes } = useStyles();
    const handleAnchorClick = (e) => {
        const targetLink = e.target.closest('a');
        if ((targetLink === null || targetLink === void 0 ? void 0 : targetLink.href) &&
            targetLink.hostname === window.location.hostname &&
            targetLink.protocol === window.location.protocol) {
            // Use router.push for links with same site
            e.preventDefault();
            router === null || router === void 0 ? void 0 : router.push(targetLink.pathname);
        }
    };
    let contentElement = null;
    if (content) {
        if (typeof content === 'string') {
            contentElement = (_jsx(Box, { ref: ref, className: clsx(classes.content, className), onClick: handleAnchorClick, onKeyDown: handleAnchorClick, dangerouslySetInnerHTML: { __html: content } }));
        }
        else {
            contentElement = (_jsx(Box, { ref: ref, className: clsx(classes.content, className), onClick: handleAnchorClick, onKeyDown: handleAnchorClick, children: content }));
        }
    }
    return contentElement;
});
RawHtml.displayName = 'RawHtml';
export default RawHtml;
