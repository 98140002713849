import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import useStyles from './CnCFloristCard.styles';
import { formatTime } from '../../utils/common';
import { format } from 'date-fns';
import Button from '../Button/Button';
import clsx from 'clsx';
import Link from '../Link/Link';
import { SELECT_FLORIST } from '../../utils/constants';
const CnCFloristCard = ({ date, ccAddress, isOpen, onToggle, handleSelectFlorist }) => {
    const { name, distance = '0', addLine2, town, countyName, postCode, openingTime, closingTime } = ccAddress;
    const { classes } = useStyles();
    const collectionDistance = useMemo(() => Number(parseFloat(distance).toFixed(2)), [distance]);
    const collectionDay = useMemo(() => date && format(new Date(date), 'EEEE'), [date]);
    return (_jsxs("button", { className: clsx(!isOpen && classes.expandContainer, classes.cardcontainer), onClick: onToggle, children: [_jsxs("div", { className: classes.floristInfoContainer, children: [_jsx("h3", { className: classes.floristName, children: name }), _jsxs("div", { className: classes.toggleInfo, children: [_jsx("span", { className: clsx(isOpen ? classes.hideIcon : '', classes.floristDistance), children: `${collectionDistance} miles` }), !isOpen && _jsx(Link, { icon: "chevron_right", label: "", className: classes.chevronIcon })] })] }), isOpen && (_jsxs("div", { className: classes.floristDetailsContainer, children: [_jsxs("div", { className: classes.floristInfo, children: [_jsxs("div", { children: [addLine2 && `${addLine2}, `, town && `${town}, `] }), _jsxs("div", { children: [countyName && `${countyName} `, postCode] })] }), _jsxs("div", { className: clsx(classes.floristDetails, isOpen && classes.showDetails), children: [openingTime && closingTime && date && (_jsx("div", { className: classes.floristShopTimings, children: `${collectionDay} ${formatTime(openingTime)} - ${formatTime(closingTime)}` })), _jsx("div", { className: classes.selectFlorist, children: _jsx(Button, { variant: "primary", thin: true, fullWidth: true, title: SELECT_FLORIST, onClick: handleSelectFlorist }) })] })] }))] }));
};
export default CnCFloristCard;
