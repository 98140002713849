import { makeStyles } from 'tss-react/mui';
import { addWidthInner, widthOuter } from '../../styles/jss/utils';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params) => ({
    container: Object.assign(Object.assign({}, widthOuter), { backgroundColor: colors.supplementary.white, borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey2}` }),
    inner: addWidthInner(theme, {}),
    siteNotice: {
        textAlign: 'center',
        border: theme.isFlyingFlowers ? `2px solid ${theme.statusColors.warning.base}` : undefined,
        color: theme.isFlyingFlowers
            ? colors.black[700]
            : (params === null || params === void 0 ? void 0 : params.noticeColor)
                ? colors.siteNoticeColor[params.noticeColor]
                : colors.siteNoticeColor['RED'],
        marginTop: theme.isFlyingFlowers ? '8px' : undefined,
        paddingTop: theme.isFlyingFlowers ? '10px' : '8px',
        paddingBottom: theme.isFlyingFlowers ? '6px' : '4px',
        paddingLeft: theme.isFlyingFlowers ? '18px' : undefined,
        paddingRight: theme.isFlyingFlowers ? '18px' : undefined,
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.isFlyingFlowers ? '10px' : '12px',
            paddingBottom: theme.isFlyingFlowers ? '10px' : '12px',
            paddingLeft: theme.isFlyingFlowers ? '30px' : undefined,
            paddingRight: theme.isFlyingFlowers ? '30px' : undefined,
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: theme.isFlyingFlowers ? '136px' : undefined,
            paddingRight: theme.isFlyingFlowers ? '136px' : undefined,
        },
        '& p': Object.assign(Object.assign({}, theme.typography.xxs_bold), { [theme.breakpoints.up('lg')]: Object.assign({}, theme.typography.small_bold), '& a': Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : undefined)), { paddingTop: theme.isFlyingFlowers ? '0px' : undefined, paddingBottom: theme.isFlyingFlowers ? '0px' : undefined, [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_regular : undefined)), { paddingTop: theme.isFlyingFlowers ? '0px' : undefined, paddingBottom: theme.isFlyingFlowers ? '0px' : undefined }) }) }),
    },
}));
export default useStyles;
