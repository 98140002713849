import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useState, useContext, useEffect } from 'react';
import Alert from '../Alert/Alert';
import CheckCircle from '../CheckCircle/CheckCircle';
import RegisterForm from '../RegisterForm/RegisterForm';
import SignInForm from '../SignInForm/SignInForm';
import useStyles from './SignIn.styles';
import { SiteContext } from '../../utils/common';
const SignIn = (props) => {
    var _a;
    const { onSignIn, onRegister, success = '', saveUserJourneyAnalytics } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const [mode, setMode] = useState('signIn');
    const isPromoErrorTypeSession = (_a = sessionStorage.getItem('promoErrorType')) !== null && _a !== void 0 ? _a : '';
    const isSignInPage = success === '';
    const showNewCustomerSigninRegisterAlert = isSignInPage && isPromoErrorTypeSession;
    useEffect(() => {
        const promoErrorType = sessionStorage.getItem('promoErrorType');
        if (!isSignInPage)
            return;
        if (promoErrorType === 'signin')
            setMode('signIn');
        else if (promoErrorType === 'register')
            setMode('register');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const successMessage = success === 'registration-success'
        ? 'You are now registered, please sign in to proceed'
        : success === 'password-reset'
            ? 'Your password has been updated'
            : '';
    const ReasonList = () => (_jsx("ul", { className: classes.list, children: isFlyingFlowers ? (_jsx(_Fragment, { children: _jsx("li", { children: "Address book setup to make your next order as easy as possible." }) })) : (_jsxs(_Fragment, { children: [_jsx("li", { children: "A quick way to check your order has been delivered." }), _jsx("li", { children: "Access to any delivery passes for great savings." }), _jsx("li", { children: "An address book \u2013 no need to write out their address each time." })] })) }));
    return (_jsxs("div", { className: classes.container, children: [showNewCustomerSigninRegisterAlert && (_jsx(Alert, { className: classes.alertInline, type: "alertInfo", message: 'You just need to sign in or register to use that code' })), successMessage && _jsx(Alert, { className: classes.alert, type: "alertSuccess", message: successMessage }), _jsx("h1", { children: `${isFlyingFlowers ? 'Welcome to Flying Flowers' : 'Welcome to Interflora'}` }), _jsxs("div", { className: clsx(classes.box, mode === 'signIn' && classes.open), children: [_jsx(CheckCircle, { name: "option", value: "signIn", checked: mode === 'signIn', onChange: (event) => setMode('signIn'), label: _jsxs(_Fragment, { children: [_jsx("h2", { children: "Sign in" }), _jsx("p", { className: classes.caption, children: "If you\u2019re already registered" })] }), controlAlignment: "top" }), mode === 'signIn' && _jsx(SignInForm, { onSubmit: onSignIn, saveUserJourneyAnalytics: saveUserJourneyAnalytics })] }), _jsxs("div", { className: clsx(classes.box, mode === 'register' && classes.open), children: [_jsx(CheckCircle, { name: "option", value: "register", checked: mode === 'register', onChange: (event) => setMode('register'), label: _jsxs(_Fragment, { children: [_jsx("h2", { children: "Register" }), _jsxs(Box, { sx: { display: { zero: 'none', sm: 'block' } }, children: [_jsx("p", { className: classes.paragraph, children: `${isFlyingFlowers ? 'If you don’t yet have an account, register for:' : 'Register for an account for:'}` }), _jsx(ReasonList, {})] })] }), controlAlignment: "top" }), _jsxs(Box, { sx: { display: { xs: 'block', md: 'none' } }, children: [_jsx("p", { className: classes.paragraph, children: "Register for an account:" }), _jsx(ReasonList, {})] }), mode === 'register' && (_jsx(RegisterForm, { onSubmit: onRegister, saveUserJourneyAnalytics: saveUserJourneyAnalytics }))] })] }));
};
export default SignIn;
