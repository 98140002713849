import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, fontWeights, spacing, statusColorsFlyingFlowers, statusColorsInterflora, } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        padding: spacing.medium,
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        backgroundColor: theme.isFlyingFlowers ? colors.grey.grey1 : colors.supplementary.white,
        marginBottom: spacing.medium,
    },
    cardContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    cardBodyContainer: {
        width: '100%',
    },
    iconContainer: {
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.small,
            marginBottom: 'initial',
        },
    },
    icon: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
    },
    occasionTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
    contactContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: spacing.xs,
    },
    currentMonthContactContainer: {
        marginBottom: spacing.medium,
    },
    noActionsContactContainer: {
        marginBottom: 0,
    },
    contactName: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, theme.typography.small_regular), theme.typographySpacing.none), { fontWeight: theme.isFlyingFlowers ? 400 : 325 }),
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    deleteAction: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
    editAction: {
        borderRadius: theme.isFlyingFlowers ? spacing.xs : spacing.xxs,
        height: '44px',
        width: theme.isFlyingFlowers ? '138px' : '135px',
    },
    currentMonth: {
        border: theme.isFlyingFlowers ? `2px solid ${colors.wildExotic[500]}` : `2px solid ${colors.primary.gold5}`,
    },
    currentOccasionDate: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers
            ? `${statusColorsFlyingFlowers.success.dark} !important`
            : `${statusColorsInterflora.success.base} !important` }, theme.typography.small_bold), theme.typographySpacing.none), { fontWeight: theme.isFlyingFlowers ? fontWeights.bold : fontWeights.medium }),
    linkTitle: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), link(theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5)), { fontWeight: fontWeights.regular }),
    deleteModalContainer: {},
    deleteTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, margin: `-60px auto ${spacing.large} !important`, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    deleteSubText: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, lineHeight: '36px' }),
    deleteConfirmationText: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_lg : theme.typographySpacing.small_regular_lg)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_sm : theme.typographySpacing.small_regular_lg)) }),
    deleteButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& button': {
            '& .MuiButton-label': {
                '& span': {
                    fontSize: spacing.small,
                    lineHeight: spacing.small,
                    letterSpacing: theme.isFlyingFlowers ? '0.5px' : '1px',
                },
            },
        },
    },
    deleteContainer: {
        margin: '0 auto',
        maxWidth: '520px',
    },
    button: { marginBottom: theme.isFlyingFlowers ? spacing.medium : spacing.large },
    linkWrapper: {},
}));
export default useStyles;
