import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import GenericContent from '../GenericContent/GenericContent';
import useGenericStyles from '../GenericContent/GenericContent.styles';
import Link from '../Link/Link';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import useStyles from './HomeHeroBanner.styles';
import { Box, useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
const HomeHeroBanner = (props) => {
    const links = props.links;
    const { headingTag = 'H1', headingColor = 'BLACK', isFromHomeHeroGallery, ignoreMobileImageProp, homeHeroCTAnalytics, } = props;
    const theme = useTheme();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, [isMobile]);
    const { classes } = useStyles({
        linksCount: !!(links === null || links === void 0 ? void 0 : links.length),
        showTrustpilotWidget: !!props.showTrustpilotWidget,
        homeHeroBannersList: !!props.homeHeroBannersList,
        height: props.height,
    });
    const { classes: genericClasses } = useGenericStyles({});
    const heading = typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line) => _jsx("span", { children: line.trim() }, line))
        : props.heading;
    return (_jsxs(_Fragment, { children: [_jsx(GenericContent, Object.assign({}, props, { heading: heading, classNames: classes, headingLevel: "NONE", linkStyle: 'BUTTON', fullWidthImage: true, contentPosition: props.contentPosition, mobileContentPosition: "OVER", minHeight: "LARGE", showTrustpilotWidget: props.showTrustpilotWidget, trustpilotBackground: props.trustpilotBackground, trustPilotDarknessLevel: props.trustPilotDarknessLevel, homeHeroBannersList: props.homeHeroBannersList, isFromHomeHeroBanner: true, headingTag: headingTag, headingColor: headingColor, font: 'SOHNE', maxLinks: props.homeHeroBannersList, isFromHomeHeroGallery: isFromHomeHeroGallery, ignoreMobileImageProp: ignoreMobileImageProp, isMobileDevice: isMobileDevice, homeHeroCTAnalytics: homeHeroCTAnalytics })), links && !props.homeHeroBannersList && (_jsx(Box, { className: clsx(genericClasses.linkContainer, classes.outsideLinkContainer), children: links.map((link, index) => (_jsx(Link, Object.assign({ button: true, variant: "primary", thin: true, className: clsx(genericClasses.link, genericClasses.linkButton, classes.link) }, link, { homeHeroCTAnalytics: homeHeroCTAnalytics }), index))) })), props.showTrustpilotWidget && !props.homeHeroBannersList && (_jsx("div", { className: classes.outsideShowTrustpilotWidget, children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) }))] }));
};
export default HomeHeroBanner;
