import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Carousel from '../Carousel/Carousel';
import useStyles from './MediaZoomInGallery.styles';
import RoundButton from '../RoundButton/RoundButton';
import ZoomInImage from '../ZoomInImage/ZoomInImage';
import { videoSource } from '../../common/data/videoSourceTypes.data';
import { useMediaQuery } from 'react-responsive';
import Icon from '../Icon/Icon';
import { getResponsiveList } from '../ImageZoomInGallery/ImageZoomInGallery';
import Image from 'next/image';
import { useTheme } from '@mui/material';
const CustomButtonGroup = ({ next, previous }) => {
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.customButtonGroup, children: [_jsx(RoundButton, { className: classes.leftButton, ariaLabel: 'chevron left arrow', icon: 'chevron_left', onClick: previous }), _jsx(RoundButton, { ariaLabel: 'chevron right arrow', icon: 'chevron_right', onClick: next, className: classes.rightButton })] }));
};
const MediaZoomInGallery = ({ contentItems }) => {
    const carouselRef = React.createRef();
    const { classes } = useStyles();
    const theme = useTheme();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, [isMobile]);
    const [currentSlide, setCurrentSlide] = useState(1);
    const responsive = getResponsiveList(theme);
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    const VideoComponent = ({ video, heading }) => {
        const { classes } = useStyles();
        const [isVideoPlay, setIsVideoPlay] = useState(false);
        const videoRef = useRef(null);
        const handlePlayButtonClick = () => {
            var _a, _b;
            !isVideoPlay ? (_a = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _a === void 0 ? void 0 : _a.play() : (_b = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _b === void 0 ? void 0 : _b.pause();
            setIsVideoPlay(true);
        };
        const handleClick = (event) => {
            var _a, _b;
            event.preventDefault();
            !isVideoPlay ? (_a = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _a === void 0 ? void 0 : _a.play() : (_b = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) === null || _b === void 0 ? void 0 : _b.pause();
            setIsVideoPlay(!isVideoPlay);
        };
        return (_jsx("div", { className: clsx(classes.videoContainer), children: _jsxs("div", { className: classes.videoSubContainer, children: [_jsxs("video", { id: "video", ref: videoRef, width: video === null || video === void 0 ? void 0 : video.width, height: video === null || video === void 0 ? void 0 : video.height, muted: video === null || video === void 0 ? void 0 : video.muted, autoPlay: isVideoPlay, poster: video === null || video === void 0 ? void 0 : video.thumbnailURL, loop: video === null || video === void 0 ? void 0 : video.loop, playsInline: true, onClick: handleClick, disablePictureInPicture: true, controlsList: 'nodownload noplaybackrate', children: [_jsx("track", { kind: "captions", src: video.url, srcLang: "en", label: "English" }), videoSource.map((videoElem, index) => (_jsx("source", { "data-quality-label": videoElem === null || videoElem === void 0 ? void 0 : videoElem.quality, "data-bitrate": videoElem === null || videoElem === void 0 ? void 0 : videoElem.birtrate, src: `${video === null || video === void 0 ? void 0 : video.url}${videoElem === null || videoElem === void 0 ? void 0 : videoElem.urlExtension}`, type: videoElem === null || videoElem === void 0 ? void 0 : videoElem.videoType, media: `screen and (${videoElem === null || videoElem === void 0 ? void 0 : videoElem.deviceWidth}:801px)` }, videoElem.urlExtension)))] }), !isVideoPlay && (_jsx("div", { className: classes.controlsContainer, children: _jsx(Icon, { icon: "play_circle_outline", className: classes.icon, onClick: handlePlayButtonClick }) })), heading && _jsx("div", { className: classes.imageTitle, children: heading })] }) }));
    };
    const RenderMedia = ({ item, currentSlide, isMobileDevice }) => {
        var _a, _b;
        const { classes } = useStyles();
        if (item === null || item === void 0 ? void 0 : item.image) {
            if (item.shouldZoom)
                return (_jsx(ZoomInImage, { image: item === null || item === void 0 ? void 0 : item.image, isMobile: isMobileDevice, isMediaCarousal: true, heading: item === null || item === void 0 ? void 0 : item.heading }, currentSlide));
            else
                return (_jsx("div", { className: classes.container, children: _jsxs("div", { className: classes.imageCardContainer, children: [_jsx("div", { className: classes.imageContainer, children: _jsx(Image, { src: (_a = item.image) === null || _a === void 0 ? void 0 : _a.url, className: classes.defaultImage, alt: (_b = item.image) === null || _b === void 0 ? void 0 : _b.altText, width: 500, height: 500 }) }), (item === null || item === void 0 ? void 0 : item.heading) && _jsx("div", { className: classes.imageTitle, children: item.heading })] }) }));
        }
        else if (item === null || item === void 0 ? void 0 : item.video) {
            return _jsx(VideoComponent, { video: item === null || item === void 0 ? void 0 : item.video, heading: item === null || item === void 0 ? void 0 : item.heading });
        }
        else
            return null;
    };
    const updateCurrentState = () => setCurrentSlide(Math.random());
    useEffect(() => {
        window === null || window === void 0 ? void 0 : window.addEventListener('orientationchange', updateCurrentState);
        return () => {
            window === null || window === void 0 ? void 0 : window.removeEventListener('orientationchange', updateCurrentState);
        };
    }, []);
    return (_jsx(_Fragment, { children: !!(contentItems === null || contentItems === void 0 ? void 0 : contentItems.length) && (_jsx("div", { className: classes.carouselContainer, children: _jsx(Carousel, { containerClass: classes.carousel, sliderClass: classes.slider, responsive: responsive, arrows: false, autoPlay: false, itemClass: classes.carouselItem, ssr: true, showDots: false, renderDotsOutside: true, infinite: true, ref: carouselRef, partialVisible: false, customButtonGroup: _jsx(CustomButtonGroup, { next: moveToNextSlide, previous: moveToPreviouSlide }), renderButtonGroupOutside: true, carouselContainerClass: classes.carouselContainer, swipeable: false, draggable: false, afterChange: updateCurrentState, children: contentItems.map((item, index) => (_jsx(RenderMedia, { item: item, currentSlide: currentSlide, isMobileDevice: isMobileDevice }, `${item.heading}-${index}`))) }) })) }));
};
export default MediaZoomInGallery;
