import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import useStyles from './AvailabilityChecker.styles';
import AvailabilitySelect from '../AvailabilitySelect/AvailabilitySelect';
import Button from '../Button/Button';
import clsx from 'clsx';
import { useAvailabilityCheckerBackgroundClass } from '../../styles/jss/utils';
import PCCModalWrapper from '../PCCModalWrapper/PCCModalWrapper';
import { Box } from '@mui/material';
const AvailabilityChecker = (props) => {
    const { basicInitiator, isOpenControlled, setOpenControlled, backgroundColor, countriesList, clickPostCodeChecker, trackPostCodeCheckerDate, trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion, submitPostCodeCheckerComplete, trackEnterManualAddressClick, locationInternationalDelivery, } = props;
    const { classes } = useStyles();
    const { bgClass } = useAvailabilityCheckerBackgroundClass(backgroundColor);
    const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);
    const setOpen = setOpenControlled !== null && setOpenControlled !== void 0 ? setOpenControlled : setIsOpenUncontrolled;
    const isOpen = setOpenControlled ? !!isOpenControlled : isOpenUncontrolled;
    const [showInternationalLink, setShowInternationalLink] = useState(false);
    const onClickHandler = () => {
        setShowInternationalLink(false);
        setOpen(true);
        clickPostCodeChecker === null || clickPostCodeChecker === void 0 ? void 0 : clickPostCodeChecker();
    };
    return (_jsxs(_Fragment, { children: [_jsx(PCCModalWrapper, { isOpen: isOpen, setOpen: setOpen, setShowInternationalLink: setShowInternationalLink, showInternationalLink: showInternationalLink, countriesList: countriesList, trackPostCodeCheckerDate: trackPostCodeCheckerDate, trackPostCodeCheckerAddress: trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress: trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion: trackPostCodeCheckerOccasion, submitPostCodeCheckerComplete: submitPostCodeCheckerComplete, trackEnterManualAddressClick: trackEnterManualAddressClick, locationInternationalDelivery: locationInternationalDelivery }), !setOpenControlled && (_jsx(_Fragment, { children: basicInitiator ? (_jsx("button", { className: classes.basicButton, onClick: () => setOpen(true), children: "Start new search" })) : (_jsxs(Box, { className: clsx(classes.availabilityChecker, bgClass), children: [_jsxs("div", { className: classes.availabilitySelectors, children: [_jsx(AvailabilitySelect, { onClick: onClickHandler, title: "Delivery date", icon: "calendar_today" }), _jsx(AvailabilitySelect, { onClick: onClickHandler, title: "Address", icon: "local_shipping" }), _jsx(AvailabilitySelect, { onClick: onClickHandler, title: "Occasion", icon: "local_florist" })] }), _jsx(Button, { onClick: onClickHandler, variant: "primary", title: "View gifts", className: classes.actionButton, thin: true })] })) }))] }));
};
export default AvailabilityChecker;
