import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
    },
    imageContainer: {
        position: 'relative',
        display: 'block',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    textContent: {
        padding: spacing.medium,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    topSectionContainer: {
        flexGrow: 1,
    },
    bottomSectionContainer: {},
    content: {
        display: 'flex',
        position: 'relative',
        textAlign: 'center',
        '&.LEFT': {
            textAlign: 'left',
        },
        '&.RIGHT': {
            textAlign: 'right',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
            height: '100%',
        },
        padding: spacing.large,
        [theme.breakpoints.up('md')]: {
            padding: spacing.xxl,
        },
    },
    contentWithQuotationMarks: {
        paddingTop: '50px',
        paddingBottom: '40px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '80px',
        },
    },
    contentInner: {
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
    },
    title: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl), theme.typographySpacing.xxl_sm) }),
    message: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.small_regular), theme.typographySpacing.small_regular_lg),
    linkContainer: { display: 'inline-flex', flexDirection: 'column' },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
    },
    linkText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(colors.primary.gold4)), { color: colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    linkButton: {},
}));
export default useStyles;
