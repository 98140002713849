import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { handleSecondaryBadgeHeight } from '../../../utils/common';
import Carousel from '../../Carousel/Carousel';
import CarouselDots from '../../Carousel/CarouselDots/CarouselDots';
import ProductCard, { SECONDARY_BADGE_SINGLE_LINE_DESKTOP, SECONDARY_BADGE_SINGLE_LINE_MOBILE, SECONDARY_BADGE_TWO_LINES, } from '../../ProductCard/ProductCard';
import useStyles from './ProductGalleryCarousel.styles';
import { useTheme } from '@mui/material';
const ProductGalleryCarousel = (props) => {
    const { products, cardDisplayType } = props;
    const carouselRef = React.createRef();
    const { classes } = useStyles();
    const theme = useTheme();
    const { sm, md, xl } = theme.breakpoints.values;
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: 4,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 3,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 2,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    const [device, setDevice] = useState();
    const [productsList, setProductsList] = useState(products);
    const handleBadgeHeight = () => {
        const { device } = handleSecondaryBadgeHeight();
        setDevice(device);
        setBadgeLineHeight();
    };
    const setHeight = () => {
        handleBadgeHeight();
    };
    const setBadgeLineHeight = () => {
        var _a, _b, _c, _d, _e;
        if (!!products && (products === null || products === void 0 ? void 0 : products.length) > 1) {
            let lengthOfTitle;
            let productsData = [];
            const hasBadge = products.some((product) => { var _a; return !!((_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge); });
            if (hasBadge) {
                const sortedProducts = (_a = products
                    .filter((product) => { var _a, _b; return !!((_b = (_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge) === null || _b === void 0 ? void 0 : _b.title); })) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                    var _a, _b, _c, _d;
                    if (!!((_b = (_a = a === null || a === void 0 ? void 0 : a.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge) === null || _b === void 0 ? void 0 : _b.title) && !!((_d = (_c = b === null || b === void 0 ? void 0 : b.images[0]) === null || _c === void 0 ? void 0 : _c.secondaryBadge) === null || _d === void 0 ? void 0 : _d.title)) {
                        return a.images[0].secondaryBadge.title.length < b.images[0].secondaryBadge.title.length ? 1 : -1;
                    }
                    else
                        return 1;
                });
                const badgeCharLength = !!(sortedProducts === null || sortedProducts === void 0 ? void 0 : sortedProducts.length) && ((_e = (_d = (_c = (_b = sortedProducts[0]) === null || _b === void 0 ? void 0 : _b.images[0]) === null || _c === void 0 ? void 0 : _c.secondaryBadge) === null || _d === void 0 ? void 0 : _d.title) === null || _e === void 0 ? void 0 : _e.length);
                if ((device === 'DESKTOP' || device === 'LARGEDESKTOP') && !!badgeCharLength) {
                    // uptop 3 lins of a sbadge title
                    if (badgeCharLength > 48)
                        lengthOfTitle = SECONDARY_BADGE_TWO_LINES + 32 + 14;
                    else if (device === 'DESKTOP' &&
                        window.screen.width > 1023 &&
                        window.screen.width < 1045 &&
                        !!badgeCharLength) {
                        lengthOfTitle = SECONDARY_BADGE_TWO_LINES + 14;
                    }
                    else if (badgeCharLength > 23 && badgeCharLength <= 48)
                        lengthOfTitle = SECONDARY_BADGE_TWO_LINES + 14;
                    else
                        lengthOfTitle = SECONDARY_BADGE_SINGLE_LINE_DESKTOP + 14;
                }
                if (device === 'TABLET' && !!badgeCharLength) {
                    if (badgeCharLength > 30)
                        lengthOfTitle = 54 + 14;
                    else
                        lengthOfTitle = SECONDARY_BADGE_SINGLE_LINE_MOBILE + 14;
                }
                if ((device === 'SMALL' || device === 'MOBILE') && !!badgeCharLength) {
                    if (badgeCharLength > 29)
                        lengthOfTitle = 54 + 14;
                    else
                        lengthOfTitle = SECONDARY_BADGE_SINGLE_LINE_MOBILE + 14;
                }
                for (let i = 0; i < (products === null || products === void 0 ? void 0 : products.length); i++) {
                    productsData.push(Object.assign(Object.assign({}, products[i]), { hasProductBadge: hasBadge, heightOfBadge: lengthOfTitle }));
                }
                !!productsData.length && setProductsList([...productsData]);
            }
        }
    };
    useEffect(() => {
        if (!!products && (products === null || products === void 0 ? void 0 : products.length) > 1) {
            handleBadgeHeight();
        }
        window === null || window === void 0 ? void 0 : window.addEventListener('resize', handleBadgeHeight);
        return () => window === null || window === void 0 ? void 0 : window.removeEventListener('resize', handleBadgeHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device, products]);
    useEffect(() => {
        setBadgeLineHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device]);
    return (_jsx(Carousel, { containerClass: classes.carousel, sliderClass: classes.slider, responsive: responsive, arrows: false, autoPlay: false, itemClass: classes.carouselItem, ssr: true, showDots: true, renderDotsOutside: true, infinite: true, customDot: _jsx(CarouselDots, { itemsLength: productsList.length - 1, nextSlide: moveToNextSlide, prevSlide: moveToPreviouSlide, disableDotsClick: true }), ref: carouselRef, carouselContainerClass: classes.carouselContainer, children: productsList === null || productsList === void 0 ? void 0 : productsList.map((product, index) => (_jsx(ProductCard, { product: product, imageCarousel: false, cardDisplayType: cardDisplayType, index: index, setHeight: setHeight }, `${product.name}${index}`))) }));
};
export default ProductGalleryCarousel;
