import { errorMessages, inputValidationRegex } from '../../utils/common';
import * as Yup from 'yup';
export const validationConfig = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(50, errorMessages(50).maxChars)
        .required('Please enter a first name')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    lastName: Yup.string()
        .trim()
        .min(1, errorMessages(1).minChars)
        .max(40, errorMessages(40).maxChars)
        .required('Please enter a last name')
        .matches(inputValidationRegex, errorMessages().unsupportedCharacters),
    occasion: Yup.string().required('Please choose an occasion'),
    day: Yup.string().required('Please select a day'),
    month: Yup.string().required('Please select a month'),
});
export const initialValues = {
    firstName: '',
    lastName: '',
    occasion: '',
    day: '',
    month: '',
};
export const monthsList = [
    {
        value: '01',
        label: 'January',
        length: 31,
    },
    {
        value: '02',
        label: 'February',
        length: 29,
    },
    {
        value: '03',
        label: 'March',
        length: 31,
    },
    {
        value: '04',
        label: 'April',
        length: 30,
    },
    {
        value: '05',
        label: 'May',
        length: 31,
    },
    {
        value: '06',
        label: 'June',
        length: 30,
    },
    {
        value: '07',
        label: 'July',
        length: 31,
    },
    {
        value: '08',
        label: 'August',
        length: 31,
    },
    {
        value: '09',
        label: 'September',
        length: 30,
    },
    {
        value: '10',
        label: 'October',
        length: 31,
    },
    {
        value: '11',
        label: 'November',
        length: 30,
    },
    {
        value: '12',
        label: 'December',
        length: 31,
    },
];
