import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './Drawer.styles';
import clsx from 'clsx';
import { Drawer as ImportedDrawer } from '@mui/material';
const Drawer = ({ isFromCategory, open, anchor = 'left', children, title, setOpen, onClose, isFromHeader, condensed, }) => {
    const { classes } = useStyles();
    const handleClose = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setOpen && setOpen(false);
        !!onClose && onClose();
    };
    return (_jsx(ImportedDrawer, { open: open, anchor: anchor, ModalProps: {
            disablePortal: true,
            keepMounted: true,
            onClose: handleClose,
            disableScrollLock: false,
        }, transitionDuration: 700, className: clsx(isFromHeader ? classes.modalBackDrop : '', condensed ? classes.adjustHeader : ''), children: _jsxs("div", { className: clsx(classes.contentContainer, isFromHeader ? classes.menuContainer : ''), children: [!isFromHeader && (_jsxs("div", { className: classes.header, children: [title && isFromCategory ? _jsx("div", { className: classes.headerTitle, children: title }) : _jsx("h3", { children: title }), _jsx(RoundButton, { icon: "close", ariaLabel: "Close", onClick: (event) => handleClose(event) })] })), children] }) }));
};
export default Drawer;
