import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()(() => ({
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 'fit-content',
        color: colors.supplementary.black,
        '& div': {
            marginTop: spacing.xxs,
        },
    },
    small: { fontSize: '1.4285714285714284rem' },
    large: { fontSize: ' 2.571428571428571rem' },
    inherit: { fontSize: 'inherit' },
    roundel: {
        backgroundColor: colors.wildExotic[400],
        maskSize: 'cover',
        WebkitMaskSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    roundel1: {
        maskImage: 'url("/images/roundels/roundel-1.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-1.svg")',
    },
    roundel2: {
        maskImage: 'url("/images/roundels/roundel-2.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-2.svg")',
    },
    roundel3: {
        maskImage: 'url("/images/roundels/roundel-3.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-3.svg")',
    },
    roundel4: {
        maskImage: 'url("/images/roundels/roundel-4.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-4.svg")',
    },
    roundel5: {
        maskImage: 'url("/images/roundels/roundel-5.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-5.svg")',
    },
}));
export default useStyles;
