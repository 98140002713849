import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import Link from '../../Link/Link';
import clsx from 'clsx';
import useStyles from './LinkContainer.styles';
const LinkContainer = (props) => {
    const { links, isButton, buttonVariant, classNames, maxLinks, contentFeatureAnalytics } = props;
    const { classes } = useStyles();
    return (_jsx(Box, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.linkContainer, classNames === null || classNames === void 0 ? void 0 : classNames.linkContainer, maxLinks && maxLinks > 1 ? classes.adjustLinkContainer : ''), children: links === null || links === void 0 ? void 0 : links.map((link, index) => {
            var _a;
            return (link === null || link === void 0 ? void 0 : link.label) ? (_jsx(Link, Object.assign({ button: isButton, variant: buttonVariant !== null && buttonVariant !== void 0 ? buttonVariant : 'primary', thin: true, className: clsx(classes.link, !isButton ? classes.linkText : '', classNames === null || classNames === void 0 ? void 0 : classNames.link) }, link, { contentFeatureAnalytics: contentFeatureAnalytics }), (_a = link.id) !== null && _a !== void 0 ? _a : `link-${index}`)) : null;
        }) }));
};
export default LinkContainer;
