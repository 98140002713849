import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './FuneralUsp.styles';
import ContentBlock from '../ContentBlock/ContentBlock';
const FuneralUsp = (props) => {
    const { image, message, title } = props;
    const { classes } = useStyles();
    return (_jsx(ContentBlock, { classNames: {
            imageContent: classes.imageContent,
            messageContent: classes.messageContent,
            container: classes.container,
            description: classes.message,
            title: classes.title,
        }, image: image, description: message, maxImageWidth: 384, title: title }));
};
export default FuneralUsp;
