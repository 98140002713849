import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import useStyles from './SecondaryBadge.styles';
import clsx from 'clsx';
const SecondaryBadge = (props) => {
    var _a;
    const { secondaryBadge, className, isFromSimpleCard, isFromSearchCard } = props;
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: !!(secondaryBadge === null || secondaryBadge === void 0 ? void 0 : secondaryBadge.title) ? (_jsx("div", { className: clsx(classes.titleContainer, classes[(_a = secondaryBadge.variant) !== null && _a !== void 0 ? _a : 'DARK_GREY'], className !== null && className !== void 0 ? className : '', isFromSimpleCard ? classes.simpleCardTitle : '', isFromSearchCard ? classes.searchCardAlignment : ''), children: secondaryBadge.title })) : (_jsx(_Fragment, {})) }));
};
export default SecondaryBadge;
