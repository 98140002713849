var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import EditBillingAddressForm from './EditBillingAddressForm/EditBillingAddressForm';
import AddressSearchForm, { validationSchema as AddressSearchValidationSchema, } from './AddressSearchForm/AddressSearchForm';
import useStyles from './EditBillingAddress.style';
import { SiteContext } from '../../utils/common';
import { Form, Formik } from 'formik';
const EditBillingAddress = (props) => {
    const [isManualAddress, setIsManualAddress] = useState(false);
    const { classes } = useStyles();
    const { onSubmit, onCancel, city, postalCode, addressLine1, addressLine2, addressLine3, country, county, trackEnterManualAddressClick, } = props;
    const { countryCode } = useContext(SiteContext);
    const [hasFormError, setHasFormError] = useState(false);
    const initialValues = {
        addressLine1: addressLine1 !== null && addressLine1 !== void 0 ? addressLine1 : '',
        addressLine2: addressLine2 !== null && addressLine2 !== void 0 ? addressLine2 : '',
        addressLine3: addressLine3 !== null && addressLine3 !== void 0 ? addressLine3 : '',
        city: city !== null && city !== void 0 ? city : '',
        county: county !== null && county !== void 0 ? county : '',
        postalCode: postalCode !== null && postalCode !== void 0 ? postalCode : '',
        country: country !== null && country !== void 0 ? country : countryCode,
        addressSelection: '',
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setHasFormError(false);
        const response = yield onSubmit(values);
        if (response.error) {
            setHasFormError(true);
        }
    });
    const formikConfig = {
        initialValues,
        enableReinitialize: true,
        validationSchema: AddressSearchValidationSchema,
        onSubmit: handleSubmit,
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { children: "Editing billing address" }), _jsx("div", { className: classes.box, children: _jsx(Formik, Object.assign({}, formikConfig, { children: () => (_jsx(Form, { children: !isManualAddress && props.addressLine1 ? (_jsx(EditBillingAddressForm, { onSubmit: onSubmit, onCancel: onCancel, addressLine1: addressLine1, addressLine2: addressLine2, addressLine3: addressLine3, city: city, county: county, postalCode: postalCode, country: country, toggleManualAddress: setIsManualAddress })) : (_jsx(AddressSearchForm, { hasFormError: hasFormError, onCancel: onCancel, isNewAddress: !!props.addressLine1 === false, trackEnterManualAddressClick: trackEnterManualAddressClick })) })) })) })] }));
};
export default EditBillingAddress;
