import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
import { rawContentBlockStyles } from './../../styles/jss/contentBlock';
const useStyles = makeStyles()((theme) => ({
    gallery: {
        textAlign: 'center',
        marginBottom: spacing.large,
    },
    headingSection: Object.assign(Object.assign({}, rawContentBlockStyles(theme).headingSection), { '& p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }) }),
    title: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.xl_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : theme.typographySpacing.xl_regular_lg)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.large_bold : theme.typography.xl_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.large_bold_lg : theme.typographySpacing.xl_regular_lg)) }),
    description: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, (theme.isFlyingFlowers ? theme.typography.small_regular : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.small_regular_lg : undefined)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_lg : undefined)) }),
    footerSection: {},
    grid: {
        marginBottom: spacing.medium,
        flexGrow: 1,
        justifyContent: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
        [theme.breakpoints.up('sm')]: {
            width: 'fit-content',
            margin: '0 auto',
        },
    },
    linkText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), (theme.isFlyingFlowers ? link(colors.black[600]) : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    linkButton: {
        justifyContent: 'center',
        '& button': {
            [theme.breakpoints.down('xl')]: {
                width: '100%',
            },
        },
    },
    carousel: {
        margin: '0 auto',
        marginBottom: spacing.medium,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& .react-multi-carousel-dot-list': {
            position: 'initial',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
            marginBottom: spacing.large,
        },
    },
    message: {},
}));
export default useStyles;
