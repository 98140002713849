import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    facetSelection: {
        width: '350px',
        height: 'calc(100% - 140px)',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '100px',
            left: 0,
            zIndex: 0,
            backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
            height: '50px',
            width: '100%',
        },
    },
    facetContainer: {
        height: '100%',
        overflowY: 'scroll',
        position: 'relative',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    globalFilterActions: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginBottom: spacing.medium,
        [`& .${classes.clearFilters}`]: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_regular : undefined)),
    },
    facets: {
        marginBottom: spacing.small,
    },
    resultCount: Object.assign({}, theme.typography.small_bold),
    clearFilters: Object.assign({}, theme.typography.small_regular),
    closeButton: {
        position: 'absolute',
        bottom: spacing.xl,
        width: '350px',
        zIndex: 5,
    },
}));
export default useStyles;
