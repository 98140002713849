import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    buttonPrimary: {
        '&.MuiButtonBase-root': {
            color: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey7,
            backgroundColor: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4,
            '&:hover': {
                backgroundColor: theme.isFlyingFlowers ? colors.wildExotic[600] : colors.primary.gold3,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:disabled': {
                color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey7,
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.primary.gold1,
                boxShadow: theme.isFlyingFlowers ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
        },
    },
    buttonSecondaryDark: {
        '&.MuiButtonBase-root': {
            color: colors.supplementary.white,
            backgroundColor: theme.isFlyingFlowers ? colors.cobalt[500] : colors.grey.grey7,
            '&:hover': {
                color: theme.isFlyingFlowers ? undefined : colors.primary.gold1,
                backgroundColor: theme.isFlyingFlowers ? colors.cobalt[600] : colors.supplementary.black,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:focus': {
                backgroundColor: theme.isFlyingFlowers ? colors.cobalt[600] : undefined,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: colors.grey.grey5,
            },
            '&:disabled': {
                color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey7,
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey3,
                boxShadow: theme.isFlyingFlowers ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
        },
    },
    buttonSecondaryLight: {
        '&.MuiButtonBase-root': {
            color: theme.isFlyingFlowers ? colors.cobalt[500] : colors.grey.grey7,
            backgroundColor: colors.supplementary.white,
            '&:hover': {
                color: theme.isFlyingFlowers ? colors.cobalt[600] : undefined,
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.primary.gold1,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:focus': {
                color: theme.isFlyingFlowers ? colors.cobalt[600] : undefined,
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : undefined,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: colors.grey.grey5,
            },
            '&:disabled': {
                color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey7,
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.grey.grey3,
                boxShadow: theme.isFlyingFlowers ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
        },
    },
    buttonTertiaryDark: {
        '&.MuiButtonBase-root': {
            color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.grey.grey7,
            backgroundColor: colors.supplementary.white,
            borderColor: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.grey.grey7,
            '&:hover': {
                color: theme.isFlyingFlowers ? colors.wildExotic[600] : undefined,
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.primary.gold1,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:focus': {
                color: theme.isFlyingFlowers ? colors.wildExotic[600] : undefined,
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : undefined,
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:disabled': {
                color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey8,
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.supplementary.white,
                borderColor: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey5,
                boxShadow: theme.isFlyingFlowers ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
        },
    },
    buttonTertiaryLight: {
        '&.MuiButtonBase-root': {
            color: theme.isFlyingFlowers ? colors.plushBlush[400] : colors.supplementary.white,
            backgroundColor: colors.grey.grey7,
            borderColor: theme.isFlyingFlowers ? colors.plushBlush[400] : colors.supplementary.white,
            '&:hover': {
                color: theme.isFlyingFlowers ? colors.plushBlush[300] : colors.primary.gold1,
                borderColor: theme.isFlyingFlowers ? colors.plushBlush[300] : colors.primary.gold1,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:focus': {
                color: theme.isFlyingFlowers ? colors.plushBlush[300] : colors.primary.gold1,
                backgroundColor: theme.isFlyingFlowers ? colors.supplementary.black : undefined,
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:disabled': {
                color: theme.isFlyingFlowers ? colors.black[300] : colors.grey.grey4,
                backgroundColor: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey7,
                borderColor: theme.isFlyingFlowers ? colors.black[300] : colors.grey.grey4,
                boxShadow: theme.isFlyingFlowers ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
        },
    },
    button: {
        textTransform: 'none',
        borderRadius: theme.isFlyingFlowers ? 8 : 4,
        boxShadow: theme.isFlyingFlowers ? '0px 2px 4px rgba(60, 60, 59, 0.24)' : '0px 2px 4px rgba(0, 0, 0, 0.24)',
        '&:focus': {
            boxShadow: theme.isFlyingFlowers
                ? '0px 0px 0px 2px #008BE9, 0px 4px 8px rgba(60, 60, 59, 0.32)'
                : '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
        '&:disabled': {
            boxShadow: theme.isFlyingFlowers ? 'none' : undefined,
        },
        // Expected height 60px
        '&.MuiButtonBase-root.MuiButton-root': {
            paddingTop: theme.isFlyingFlowers ? 16 : 20,
            paddingBottom: 20,
            paddingLeft: 25,
            paddingRight: 25,
            '&.buttonTertiaryDark, &.buttonTertiaryLight': {
                paddingTop: theme.isFlyingFlowers ? 14 : 18,
                paddingBottom: 18,
                paddingLeft: 23,
                paddingRight: 23,
                borderWidth: '2px',
                borderStyle: 'solid',
            },
        },
        '&.MuiButton-root': {
            flexDirection: theme.isFlyingFlowers ? 'row-reverse' : undefined,
            '& .MuiButton-startIcon': {
                marginLeft: theme.isFlyingFlowers ? spacing.xxs : 0,
                marginRight: theme.isFlyingFlowers ? 0 : spacing.xxs,
                // Use margin to adjust icon to same height as text
                marginTop: theme.isFlyingFlowers ? undefined : -2,
                marginBottom: theme.isFlyingFlowers ? undefined : -2,
            },
        },
        '& .MuiButton-startIcon': {
            marginLeft: theme.isFlyingFlowers ? spacing.xxs : 0,
            marginRight: theme.isFlyingFlowers ? 0 : spacing.xxs,
            // Use margin to adjust icon to same height as text
            marginTop: theme.isFlyingFlowers ? undefined : -2,
            marginBottom: theme.isFlyingFlowers ? undefined : -2,
        },
        '& .MuiIcon-root': { fontSize: '1.5rem' },
    },
    buttonThin: {
        // Expected height 44px
        '&.MuiButtonBase-root.MuiButton-root': {
            paddingTop: theme.isFlyingFlowers ? 12 : 14,
            paddingBottom: theme.isFlyingFlowers ? 12 : 14,
            paddingLeft: 31,
            paddingRight: 31,
            '&.buttonTertiaryDark, &.buttonTertiaryLight': {
                paddingTop: theme.isFlyingFlowers ? 10 : 12,
                paddingBottom: theme.isFlyingFlowers ? 10 : 12,
                paddingLeft: 29,
                paddingRight: 29,
            },
            '& .MuiButton-startIcon': {
                // Use margin to adjust icon to same height as text
                marginTop: theme.isFlyingFlowers ? -2 : -4,
                marginBottom: theme.isFlyingFlowers ? -2 : -4,
            },
        },
        '& .labelSmall': Object.assign({}, theme.typography.small_button),
    },
    buttonThinMobile: {
        // Duplicate of buttonThin but inside breakpoint
        [theme.breakpoints.down('sm')]: {
            // Expected height 44px
            '&.MuiButtonBase-root.MuiButton-root': {
                paddingTop: theme.isFlyingFlowers ? 12 : 14,
                paddingBottom: theme.isFlyingFlowers ? 12 : 14,
                paddingLeft: 31,
                paddingRight: 31,
                '&.buttonTertiaryDark, &.buttonTertiaryLight': {
                    paddingTop: theme.isFlyingFlowers ? 10 : 12,
                    paddingBottom: theme.isFlyingFlowers ? 10 : 12,
                    paddingLeft: 29,
                    paddingRight: 29,
                },
                '& .MuiButton-startIcon': {
                    // Use margin to adjust icon to same height as text
                    marginTop: theme.isFlyingFlowers ? -2 : -4,
                    marginBottom: theme.isFlyingFlowers ? -2 : -4,
                },
            },
            '& .labelSmall': Object.assign({}, theme.typography.small_button),
        },
    },
    label: Object.assign({ display: 'block' }, theme.typography.medium_button),
    iconContainer: { color: 'inherit !important' },
    rippleClassPrimary: {
        color: colors.primary.gold2,
    },
    rippleClassSecondaryDark: {
        color: colors.grey.grey5,
    },
    spinner: {
        marginLeft: theme.isFlyingFlowers ? 0 : 5,
        marginRight: theme.isFlyingFlowers ? 5 : 0,
    },
    icon: {},
}));
export default useStyles;
