import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import clsx from 'clsx';
import ProductCard from '../../ProductCard/ProductCard';
import useStyles from './SearchProducts.styles';
import { SearchOptionsList } from '../../../common/props';
import Button from '../../Button/Button';
import { SiteContext } from '../../../utils/common';
const RenderSearchProducts = (props) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { productsList, showViewAllButton, searchTerm, handleViewAllButtonClick, isFromInitialSearchView } = props;
    return (_jsxs(_Fragment, { children: [_jsx("ul", { className: clsx(classes.productCards, showViewAllButton ? classes.productsContainer : ''), "data-type": "SearchProduct", children: productsList.map((product, index) => (_jsx("li", { className: clsx(classes.productCard, index === 2 ? classes.lastProduct : ''), children: _jsx(ProductCard, { product: product, cardDisplayType: "SIMPLE", hideBorder: true, index: index, hidSecondaryBadge: true, textContentClasses: classes.productContentClasses, isFromSearchOverlay: true, isFromInitialSearch: isFromInitialSearchView }) }, `${product.name}_${product.sku}`))) }), searchTerm && showViewAllButton ? (_jsx("div", { className: classes.showAllButton, children: _jsx(Button, { title: 'View all gifts', thin: true, variant: isFlyingFlowers ? 'secondaryDark' : 'primary', href: `/search?query=${encodeURIComponent(searchTerm)}`, onClick: (e) => handleViewAllButtonClick === null || handleViewAllButtonClick === void 0 ? void 0 : handleViewAllButtonClick(isFlyingFlowers ? SearchOptionsList.GIFTS : SearchOptionsList.PRODUCTS) }) })) : null] }));
};
export default RenderSearchProducts;
