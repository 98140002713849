import { makeStyles } from 'tss-react/mui';
import { default as _pick } from 'lodash/pick';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    linkBlock: Object.assign(Object.assign({ minWidth: '155px', padding: `10px ${spacing.small}` }, _pick(theme.typography.xxs_regular, ['fontSize', 'fontWeight', 'letterSpacing', 'lineHeight'])), { display: 'inline-block', backgroundColor: colors.grey.grey1, [theme.breakpoints.up('md')]: Object.assign({}, _pick(theme.typography.small_regular, ['fontSize', 'fontWeight', 'letterSpacing', 'lineHeight'])), '&:hover': {
            backgroundColor: colors.supplementary.white,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
        }, '&:focus': {
            backgroundColor: colors.supplementary.white,
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        } }),
    linkButton: {
        cursor: 'pointer',
    },
}));
export default useStyles;
