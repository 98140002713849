import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Link from 'next/link';
import Image from '../Image/Image';
import useStyles from './CountryFlag.styles';
import { processImageUrl } from '../../utils/image';
const MAX_IMAGE_WIDTH = 108;
const CountryFlag = (props) => {
    const { image, message, name, url } = props;
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: url && (_jsx(Link, { href: url, passHref: true, legacyBehavior: true, children: _jsxs("a", { className: classes.box, children: [image && (_jsx(Image, { className: classes.image, imageUrl: processImageUrl(image.url, { w: MAX_IMAGE_WIDTH }, ['$poi-square$']), altText: image.altText, width: MAX_IMAGE_WIDTH, loading: "lazy", height: 100 })), _jsxs("div", { className: classes.messageContent, children: [message && _jsx("div", { className: classes.message, children: message }), name && _jsx("div", { className: classes.country, children: name })] })] }) })) }));
};
export default CountryFlag;
