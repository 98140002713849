import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { removeSessionItems, SiteContext } from '../../utils/common';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import useStyles from './BreadcrumbBar.style';
import Image from 'next/image';
const BreadcrumbBar = (props) => {
    var _a;
    const { canonicalHostname, subject } = useContext(SiteContext);
    const router = useRouter();
    const asPath = router === null || router === void 0 ? void 0 : router.asPath;
    const [links, setLinks] = useState(props.links);
    useEffect(() => {
        var _a;
        const excludedPaths = [
            '/search',
            '/blog',
            '/basket',
            '/checkout/',
            '/florist',
            '/page/',
            '/flower-deliery',
            '/find-a-florist',
            '/find-location-florist',
            '/account',
        ];
        const categorypath = '/category/';
        if (asPath === null || asPath === void 0 ? void 0 : asPath.includes(categorypath)) {
            removeSessionItems(['isFromPLP']);
            if ((_a = props === null || props === void 0 ? void 0 : props.links) === null || _a === void 0 ? void 0 : _a.length)
                sessionStorage.setItem('breadcrumb', JSON.stringify(props.links));
        }
        else if (excludedPaths.some((path) => asPath === null || asPath === void 0 ? void 0 : asPath.includes(path))) {
            removeSessionItems(['isFromPLP', 'breadcrumb']);
        }
    }, [asPath, props === null || props === void 0 ? void 0 : props.links]);
    useEffect(() => {
        var _a;
        const { asPath } = router !== null && router !== void 0 ? router : {};
        const { product } = subject !== null && subject !== void 0 ? subject : {};
        const isFromPLP = sessionStorage.getItem('isFromPLP');
        const pdpBreadCrumb = sessionStorage.getItem('breadcrumb');
        const productBreadCrumb = (pdpBreadCrumb === null || pdpBreadCrumb === void 0 ? void 0 : pdpBreadCrumb.length) ? JSON.parse(pdpBreadCrumb) : [];
        if ((asPath === null || asPath === void 0 ? void 0 : asPath.includes('/product/')) && isFromPLP) {
            setLinks([...productBreadCrumb, { label: (_a = product === null || product === void 0 ? void 0 : product.name) !== null && _a !== void 0 ? _a : '' }]);
        }
        else if (productBreadCrumb.length) {
            setLinks([...productBreadCrumb]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router === null || router === void 0 ? void 0 : router.asPath, subject === null || subject === void 0 ? void 0 : subject.product]);
    // Override default breadcrumbs for products where a matching category key is found in the url
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const isFromPLP = sessionStorage.getItem('breadcrumb');
        if (!isFromPLP) {
            const category = ((_b = (_a = subject === null || subject === void 0 ? void 0 : subject.product) === null || _a === void 0 ? void 0 : _a.categories) === null || _b === void 0 ? void 0 : _b.find((category) => { var _a; return category.key === ((_a = router === null || router === void 0 ? void 0 : router.query) === null || _a === void 0 ? void 0 : _a.category); })) ||
                ((_d = (_c = subject === null || subject === void 0 ? void 0 : subject.product) === null || _c === void 0 ? void 0 : _c.categories) === null || _d === void 0 ? void 0 : _d.find((category) => { var _a; return (_a = category.ancestors) === null || _a === void 0 ? void 0 : _a.some((ancestor) => { var _a; return ancestor.key == ((_a = router === null || router === void 0 ? void 0 : router.query) === null || _a === void 0 ? void 0 : _a.category); }); })) ||
                ((_f = (_e = subject === null || subject === void 0 ? void 0 : subject.product) === null || _e === void 0 ? void 0 : _e.categories) === null || _f === void 0 ? void 0 : _f[0]);
            setLinks(((subject === null || subject === void 0 ? void 0 : subject.product) &&
                category && [
                { label: 'Home', url: '/' },
                ...(((_g = category.ancestors) === null || _g === void 0 ? void 0 : _g.map((category) => ({ label: category.name, url: category.url }))) || []),
                { label: category.name, url: category.url },
                { label: ((_h = subject.product) === null || _h === void 0 ? void 0 : _h.name) || '', url: ((_j = subject.product) === null || _j === void 0 ? void 0 : _j.url) || '' },
            ]) ||
                props.links);
        }
    }, [(_a = router === null || router === void 0 ? void 0 : router.query) === null || _a === void 0 ? void 0 : _a.category, props.links, subject, subject === null || subject === void 0 ? void 0 : subject.product]);
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: !!(links === null || links === void 0 ? void 0 : links.length) && (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.breadcrumbContent, "data-hj-ignore-attributes": true, children: _jsx("div", { className: classes.container, children: links.map((link, index) => (_jsxs(React.Fragment, { children: [_jsx(Breadcrumb, { link: link, currentPage: index === links.length - 1 }), index !== links.length - 1 && (_jsx(Image, { src: `/images/icons/breadcrumBar/chevronRight.svg`, alt: 'chevronRight', className: classes.arrowIcon, height: 16, width: 16 }))] }, `breadcrumb_${link.label}}`))) }) }), !!canonicalHostname && (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps({
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        itemListElement: links === null || links === void 0 ? void 0 : links.map((link, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            name: link.label,
                            item: `https://${canonicalHostname}${link.url}`,
                        })),
                    }))) }))] })) }));
};
export default BreadcrumbBar;
