import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    orderHistoryContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1,1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2,1fr)',
        },
        gridColumnGap: spacing.medium,
        gridRowGap: spacing.medium,
    },
    orderHistorySection: {},
    ordersHeading: {
        display: 'none',
        [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.small_regular_sm), { display: 'block' }),
    },
    orderHistoryLoadMore: {
        marginTop: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '248px',
            },
        },
    },
    noResults: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default useStyles;
