import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Form, Formik } from 'formik';
import useStyles from '../Checkout.styles';
import Modal from '../../Modal/Modal';
import { cardMessageValidationRegEx, errorMessages } from '../../../utils/common';
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import EditCardMessageModal from './EditCardMessageModal';
import { useTheme } from '@mui/material';
const validationCardMessageSchemaConfig = Yup.object().shape({
    cardMessage: Yup.string().matches(cardMessageValidationRegEx, errorMessages().unsupportedCharacters),
});
const EditCardMessage = ({ updateCheckout, setOpen, isOpen, initialValues, errorMessage }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const [cardMessage, setCardMessage] = useState(initialValues.cardMessage || '');
    const [messageLength, setMessageLength] = useState(cardMessage === null || cardMessage === void 0 ? void 0 : cardMessage.length);
    return (_jsx(Formik, { enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationCardMessageSchemaConfig,
        onSubmit: updateCheckout, children: ({ resetForm, isSubmitting }) => {
            const handleClose = () => {
                setOpen(false);
                resetForm();
            };
            return (_jsx(Modal, { open: isOpen, setOpen: setOpen, onClose: handleClose, title: "Click below to add your message", fullWidth: true, fullScreen: !isLargerViewport, maxWidth: "md", className: classes.cardModalTitle, children: _jsx(Form, { className: classes.updateCheckoutForm, children: _jsx(EditCardMessageModal, { messageLength: messageLength, errorMessage: errorMessage, setMessageLength: setMessageLength, setCardMessage: setCardMessage, handleClose: handleClose, isSubmitting: isSubmitting, cardMessage: cardMessage }) }) }));
        } }));
};
export default EditCardMessage;
