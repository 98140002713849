import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { ucFirst } from '../../utils/common';
import Icon from '../Icon/Icon';
import useStyles from './Button.styles';
import { Button as MuiButton, CircularProgress } from '@mui/material';
/**
 * variants tertiary & secondary should be replaced
 */
const Button = (props) => {
    const { title, disabled, thin, thinOnMobile, fullWidth, icon, onClick, isSubmit, variant = 'primary', href, className, style, loading, id, roundel, } = props;
    const { classes } = useStyles();
    const formattedVariant = ucFirst(variant === 'secondary' ? 'secondaryDark' : variant === 'tertiary' ? 'tertiaryDark' : variant);
    const calcClassName = {
        [classes.button]: true,
        [classes[`button${formattedVariant}`]]: true,
        [`button${formattedVariant}`]: true,
        [classes.buttonThin]: thin,
        [classes.buttonThinMobile]: thinOnMobile,
    };
    const calcLableClassName = {
        [classes.label]: true,
        ['labelSmall']: thinOnMobile || thin,
    };
    return (_jsx(_Fragment, { children: _jsxs(MuiButton, { disabled: disabled, onClick: onClick, fullWidth: fullWidth, type: isSubmit ? 'submit' : 'button', className: clsx(className), classes: { startIcon: classes.icon, root: clsx(calcClassName) }, TouchRippleProps: { classes: { root: classes[`rippleClass${formattedVariant}`] } }, startIcon: icon && _jsx(Icon, { icon: icon, roundel: roundel, className: classes.iconContainer }), href: href, style: style, id: id, disableFocusRipple: true, children: [_jsx("span", { className: clsx(calcLableClassName), children: title }), loading && _jsx(CircularProgress, { className: classes.spinner, size: 20 })] }) }));
};
export default Button;
