import { makeStyles } from 'tss-react/mui';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
export const MOBILE_IMAGE_WIDTH = 136;
export const DESKTOP_IMAGE_WIDTH = 326;
const useStyles = makeStyles()((theme) => ({
    orderHistoryCardContainer: {
        border: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '1px',
        width: '100%',
        height: 'auto',
        padding: spacing.medium,
        backgroundColor: colors.supplementary.white,
        [theme.breakpoints.up('md')]: {
            flex: '1 0 48%',
            padding: spacing.xl,
            marginLeft: 0,
        },
        '&:focus': {
            BackgroundColor: colors.grey.grey1,
            boxShadow: '0px 0px 2px #008be9, 0px 2px 2px rgba(0, 0, 0, 0.32)',
        },
    },
    orderHistoryCardContent: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    orderHistoryCardImage: {
        width: '100%',
        maxWidth: theme.isFlyingFlowers ? `${MOBILE_IMAGE_WIDTH}` : '288px',
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            maxWidth: `${DESKTOP_IMAGE_WIDTH}px`,
            marginRight: spacing.medium,
            marginBottom: 0,
        },
    },
    orderHistoryCardSummary: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: `${DESKTOP_IMAGE_WIDTH}px`,
        },
    },
    orderNumberContent: {
        lineHeight: '1.43rem',
        marginBottom: spacing.small,
    },
    orderNumberContentAction: {},
    orderLabel: Object.assign(Object.assign({}, theme.typography.xxs_bold), { lineHeight: '1.25rem', padding: '3px 0 !important', fontWeight: fontWeights.bold, color: colors.grey.grey6 }),
    orderSummaryValue: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: 0, overflowWrap: 'anywhere', fontWeight: fontWeights.bold, color: colors.supplementary.black }),
    orderSummaryNumber: Object.assign(Object.assign({}, theme.typography.small_regular), { padding: '0 !important', margin: '0', color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: 0, fontWeight: fontWeights.regular }) }),
    orderSummaryStatusValue: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: 0, fontWeight: fontWeights.bold, color: theme.isFlyingFlowers ? undefined : colors.supplementary.black, marginRight: 8, display: theme.isFlyingFlowers ? 'flex' : 'inline-flex' }),
    orderTrackingLink: Object.assign(Object.assign({}, theme.typography.small_regular), { display: 'inline', color: colors.primary.gold5, textDecoration: 'underline' }),
    orderSummaryContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.medium,
        },
    },
    orderStatusIcon: {
        display: 'flex',
        margin: '2px 0',
        '& img': {
            margin: '4px 12px 4px 4px',
        },
    },
}));
export default useStyles;
