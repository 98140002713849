import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '../Link/Link';
import useStyles from './SavedAddressesLogin.styles';
import clsx from 'clsx';
import Image from '../Image/Image';
const SavedAddressesLogin = (props) => {
    const { onLoginClick, className } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: clsx(classes.container, className !== null && className !== void 0 ? className : ''), children: [_jsx("div", { className: classes.iconContainer, children: _jsx(Image, { imageUrl: `/images/icons/headerIcons/account.svg`, altText: 'Person Icon', priority: true, height: 16, width: 16 }) }), _jsxs("div", { className: classes.logInText, children: [_jsx(Link, { label: "Sign in", onClick: onLoginClick, className: classes.linkText }), " for your address book"] })] }));
};
export default SavedAddressesLogin;
