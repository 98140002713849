import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useEffect, useState } from 'react';
import GenericContent from '../GenericContent/GenericContent';
import useStyles from './HeroBanner.styles';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material';
const HeroBanner = (props) => {
    var _a;
    const { classes } = useStyles();
    const theme = useTheme();
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const [isMobile, setIsMobile] = useState(false);
    const { headingTag = 'H3', desktopBackgroundAndHeaderTextColors, mobileBackgroundAndHeaderTextColors } = props;
    const heading = (props === null || props === void 0 ? void 0 : props.heading) && typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line) => (_jsx("span", { className: classes.headingLine, children: line.trim() }, line)))
        : props.heading;
    const parseColor = (colorString, index) => {
        if (colorString) {
            return colorString.split('-')[index].trim();
        }
        else {
            return '';
            // Commenting below as we don't need default red and black combo for now
            // if (index === 0) {
            //   return 'RED'
            // } else {
            //   return 'BLACK'
            // }
        }
    };
    const bannerMinHeight = (_a = props.minHeight) !== null && _a !== void 0 ? _a : 'MEDIUM';
    useEffect(() => {
        setIsMobile(isMobileDevice);
    }, [isMobileDevice]);
    const backgroundColorHeroBanner = useMemo(() => parseColor(isMobile
        ? mobileBackgroundAndHeaderTextColors || props.backgroundColor
        : desktopBackgroundAndHeaderTextColors || props.backgroundColor, 0), [isMobile, mobileBackgroundAndHeaderTextColors, desktopBackgroundAndHeaderTextColors, props.backgroundColor]);
    const headingColorHeroBanner = useMemo(() => parseColor(isMobile ? mobileBackgroundAndHeaderTextColors || '' : desktopBackgroundAndHeaderTextColors || '', 1), [isMobile, mobileBackgroundAndHeaderTextColors, desktopBackgroundAndHeaderTextColors]);
    const textColor = useMemo(() => {
        switch (backgroundColorHeroBanner) {
            case 'PURPLE':
            case 'DARK GREY':
                return 'WHITE';
            default:
                return 'BLACK';
        }
    }, [backgroundColorHeroBanner]);
    return (_jsx(GenericContent, Object.assign({}, props, { headingColor: headingColorHeroBanner, backgroundColor: backgroundColorHeroBanner, textColor: textColor, isFromHeroBanner: true, classNames: classes, heading: heading, linkStyle: 'BUTTON', fullWidthImage: true, headingTag: headingTag, font: 'SOHNE', minHeight: bannerMinHeight })));
};
export default HeroBanner;
