import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import CheckCircle from '../CheckCircle/CheckCircle';
import useStyles from './RegisterForm.styles';
import { FormikContext } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material';
const PreferenceOptions = ({ isFlyingFlowers }) => {
    const { classes } = useStyles();
    const formikContext = useContext(FormikContext);
    const theme = useTheme();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const handleOptionChange = (keyName) => {
        formikContext.setFieldValue(keyName, !formikContext.values[keyName]);
    };
    return (_jsxs("div", { id: "showOptions", children: [_jsxs("p", { className: classes.titleText, children: [!isFlyingFlowers && !isMobile
                        ? 'Tick to confirm your choices'
                        : 'Please tick below to confirm how you would like to hear from us', ":"] }), _jsxs("ul", { children: [_jsx("li", { className: classes.marketingPreferences, children: _jsx(CheckCircle, { label: "Email", name: "optInEmail", type: "checkbox", onClick: () => handleOptionChange('optInEmail') }) }), _jsx("li", { className: classes.marketingPreferences, children: _jsx(CheckCircle, { label: "Post", name: "optInPost", type: "checkbox", onClick: () => handleOptionChange('optInPost') }) }), _jsx("li", { className: classes.marketingPreferences, children: _jsx(CheckCircle, { label: "SMS", name: "optInSms", type: "checkbox", onClick: () => handleOptionChange('optInSms') }) }), _jsx("li", { className: classes.marketingPreferences, children: _jsx(CheckCircle, { label: "Online advertising", name: "optInOnline", type: "checkbox", onClick: () => handleOptionChange('optInOnline') }) })] })] }));
};
export default PreferenceOptions;
