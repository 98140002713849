import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    uspContainer: Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6, textAlign: 'center', marginBottom: spacing.large, width: '100%', display: 'flex', flexDirection: 'column', flex: '1 1 auto', [theme.breakpoints.between('sm', 'xl')]: {
            width: '50%',
        }, [theme.breakpoints.up('lg')]: {
            width: '25%',
        } }),
    uspDescription: Object.assign(Object.assign({}, theme.typography.small_regular), { margin: 'auto', color: colors.grey.grey6, textAlign: 'center', maxWidth: '220px' }),
}));
export default useStyles;
