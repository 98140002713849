import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { SiteContext } from '../../utils/common';
import { CustomPaginationContext } from '../../utils/CustomPaginationContext';
import Button from '../Button/Button';
import Link from '../Link/Link';
import clsx from 'clsx';
import useStyles from './SearchShowMore.styles';
import { useRouter } from 'next/router';
const SearchShowMore = ({ showViewedCount, isInspiration, pageNo, isFromCategory }) => {
    const router = useRouter();
    const { loadAll, loadMore } = useContext(CustomPaginationContext);
    const { showMore } = useInfiniteHits();
    const { results } = useInstantSearch();
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const { hits, nbHits, hitsPerPage, page } = results;
    const defaultPageSize = 12;
    const totalViewed = hits.length === hitsPerPage ? (page + 1) * hitsPerPage : nbHits;
    const viewed = hitsPerPage !== defaultPageSize ? hitsPerPage : (page + 1) * defaultPageSize;
    const loadNextTotal = viewed >= nbHits ? 0 : viewed + defaultPageSize > nbHits ? nbHits - viewed : defaultPageSize;
    const isBlogArticle = router === null || router === void 0 ? void 0 : router.asPath.includes('blog');
    const loadMoreCanonicalURL = isFromCategory && typeof pageNo === 'number'
        ? `${window.location.pathname}?page=${pageNo + 2}`
        : `${window.location.pathname}?page=${page + 2}`;
    const showLoadMoreSection = !!loadNextTotal;
    const viewedMsgSuffix = isInspiration ? ' stories' : '';
    const handleLoadMore = (event) => {
        event.preventDefault();
        if (loadMore) {
            loadMore();
        }
        else {
            showMore();
        }
    };
    return (_jsx(_Fragment, { children: !!results && (_jsxs(_Fragment, { children: [(showViewedCount || isInspiration) && (_jsx("p", { className: classes.resultsViewed, children: `Viewed ${totalViewed} of ${nbHits}${viewedMsgSuffix}` })), showLoadMoreSection && (_jsxs("div", { className: clsx(classes.actionButtons, classes.canonicalLink), children: [_jsx(Link, { onClick: handleLoadMore, label: `Load ${loadNextTotal} more`, variant: "tertiary", thin: true, icon: isFlyingFlowers ? 'refresh' : undefined, url: loadMoreCanonicalURL, button: true, rel: true, id: "loadMore" }), loadAll && !isBlogArticle && !isInspiration ? (_jsx(Button, { title: `Load all ${nbHits} `, variant: "tertiary", onClick: () => loadAll(nbHits), thin: true, icon: isFlyingFlowers ? 'refresh' : undefined, id: "loadAll" })) : null] }))] })) }));
};
export default SearchShowMore;
