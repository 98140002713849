import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    addressCardContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'initial',
        },
        marginBottom: spacing.medium,
        minHeight: '260px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        background: theme.isFlyingFlowers ? colors.grey.grey1 : colors.supplementary.white,
        padding: spacing.medium,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        [`& .${classes.addressNameTitle}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none),
        [`& .${classes.address}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, display: 'inline-block' }),
        [`& .${classes.defaultAddressLabel}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    },
    addressNameTitle: {},
    defaultAddressLabel: {},
    address: {},
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [`& .${classes.deleteLink}`]: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? undefined : colors.primary.gold5 }), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
    },
    deleteLink: {},
    addressContainer: {},
    spinner: {},
}));
export default useStyles;
