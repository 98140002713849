import { jsx as _jsx } from "react/jsx-runtime";
import _escapeRegExp from 'lodash/escapeRegExp';
const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return _jsx("span", { children: text });
    }
    const regex = new RegExp(`(${_escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regex);
    return (_jsx("span", { children: parts
            .filter((part) => part)
            .map((part, i) => (regex.test(part) ? _jsx("mark", { children: part }, i) : _jsx("span", { children: part }, i))) }));
};
export default Highlighted;
