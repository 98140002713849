import { link } from '../../../styles/jss/utils';
import { colors, spacing } from '.././../../styles/jss/variables';
export const searchLinkBlockStyle = (theme, categories) => ({
    linkBlockHeadingContainer: {
        [`& .${categories}`]: {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'column',
            },
        },
    },
    viewAllContainer: {
        alignItems: 'center',
        display: 'flex',
        '& > span': Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4 }, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), theme.typography.xxs_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_regular) }),
    },
    suggestSeachLinkHeading: Object.assign(Object.assign(Object.assign({ marginTop: '0px !important' }, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_sm), { color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: '12px' }) }),
    linkCategoryHeading: {
        paddingRight: spacing.xs,
    },
    linkHeading: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_sm), { textDecoration: 'underline', color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold5, marginTop: '0px !important', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: '12px' }) }),
    linkBlockContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: spacing.xs,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.small,
        },
        '& li': {
            margin: `0 12px ${spacing.xs} 0`,
            '& a': {
                textAlign: 'center',
            },
        },
    },
});
