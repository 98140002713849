import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import AddNewAddressForm, { validationSchema, } from '../AddNewAddressForm/AddNewAddressForm';
import Alert from '../Alert/Alert';
import useStyles from './AddNewAddress.styles';
import { Form, Formik } from 'formik';
import { SiteContext } from '../../utils/common';
const AddNewAddress = (props) => {
    const { saveAddress, onCancel, trackEnterManualAddressClick } = props;
    const { classes } = useStyles();
    const { countryCode, isFlyingFlowers } = useContext(SiteContext);
    const [errorMessage, setErrorMessage] = useState('');
    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        contactAddress: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        county: '',
        country: countryCode,
        postalCode: '',
        deliveryInstructionOption: '',
        deliveryInstructionNote: '',
        defaultAddress: false,
        shouldValidatePhone: !isFlyingFlowers,
    };
    const formikConfig = {
        initialValues,
        validationSchema,
        onSubmit: (values) => saveAddress(values)
            .then(() => setErrorMessage === null || setErrorMessage === void 0 ? void 0 : setErrorMessage(''))
            .catch((error) => {
            setErrorMessage === null || setErrorMessage === void 0 ? void 0 : setErrorMessage(error.message);
        }),
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { className: classes.contactHeading, children: "Add new contact address" }), errorMessage && _jsx(Alert, { className: classes.error, type: "alertError", message: errorMessage }), _jsx("div", { className: classes.box, children: _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsx(Form, { children: _jsx(AddNewAddressForm, { onCancel: onCancel, isSubmitting: isSubmitting, trackEnterManualAddressClick: trackEnterManualAddressClick }) })) })) })] }));
};
export default AddNewAddress;
