import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    yourAccountTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { color: colors.supplementary.black }),
    personalCardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridColumnGap: '0',
        gridRowGap: spacing.small,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridColumnGap: spacing.small,
            gridRowGap: spacing.small,
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridColumnGap: spacing.medium,
            gridRowGap: spacing.medium,
        },
    },
    container: {
        border: '1px solid rgba(0,0,0,.1)',
        backgroundColor: theme.isFlyingFlowers ? colors.grey.grey1 : undefined,
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.32)',
        borderRadius: '1px',
        height: 'auto',
        padding: spacing.medium,
        '&:hover': {
            backgroundColor: colors.grey.grey1,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.32)',
            cursor: 'pointer',
            borderColor: colors.grey.grey6,
        },
        '&:focus': {
            backgroundColor: colors.grey.grey1,
            boxShadow: '0px 0px 2px #008BE9, 0px 2px 2px rgba(0, 0, 0, 0.32)',
        },
    },
    icon: {
        '& .MuiIcon-root': {
            fontSize: '36px',
        },
    },
    title: Object.assign(Object.assign(Object.assign({ marginBottom: theme.isFlyingFlowers ? undefined : spacing.small, color: theme.isFlyingFlowers ? colors.black[700] : undefined, display: 'block', marginRight: spacing.xxs }, theme.typography.medium_bold), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_sm : undefined)), { paddingTop: spacing.small }),
    body: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.small_regular), { marginBottom: 0 }),
}));
export default useStyles;
