import { makeStyles } from 'tss-react/mui';
import { carouselStyles } from '../../styles/jss/carousel';
const useStyles = makeStyles()((theme) => (Object.assign({ slider: {
        display: 'flex',
        flexDirection: 'row',
    }, carousel: {
        margin: '0 auto',
        padding: `0`,
    }, carouselItem: {
        margin: 0,
        display: 'flex',
    } }, carouselStyles(theme))));
export default useStyles;
