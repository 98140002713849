import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    titleContainer: Object.assign(Object.assign(Object.assign({ display: 'inline-block', textAlign: 'center' }, theme.typography.small_regular), theme.typographySpacing.none), { paddingLeft: spacing.xs, paddingRight: spacing.xs, textTransform: 'uppercase', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.none), [`&.${classes.GOLD}`]: {
            color: colors.primary.gold5,
            border: `1px solid ${colors.primary.gold5}`,
        }, [`&.${classes.DARK_GREY}`]: {
            color: colors.grey.grey6,
            border: `1px solid ${colors.grey.grey6}`,
        }, [`&.${classes.FF_CHARCOL}`]: {
            color: colors.black[700],
            border: `1px solid ${colors.black[700]}`,
        }, [`&.${classes.FF_WHILD_EXOTIC}`]: {
            color: colors.wildExotic[500],
            border: `1px solid ${colors.wildExotic[500]}`,
        }, [`&.${classes.FF_COBALT}`]: {
            color: colors.cobalt[500],
            border: `1px solid ${colors.cobalt[500]}`,
        }, [`&.${classes.FF_JADE}`]: {
            color: colors.jade[500],
            border: `1px solid ${colors.jade[500]}`,
        } }),
    GOLD: {},
    DARK_GREY: {},
    FF_CHARCOL: {},
    FF_WHILD_EXOTIC: {},
    FF_COBALT: {},
    FF_JADE: {},
    simpleCardTitle: Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none),
    searchCardAlignment: {
        textAlign: 'left',
    },
    FF_GREY: {},
}));
export default useStyles;
