import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    iframeContainer: {
        height: '600px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxHeight: '500px',
            maxWidth: '800px',
        },
    },
}));
export default useStyles;
