import { jsx as _jsx } from "react/jsx-runtime";
import { useRouter } from 'next/router';
import Tags from '../Tags/Tags';
import useStyles from './BlogCategoryLinks.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const BlogCategoryLinks = (props) => {
    const { categories } = props;
    const { classes } = useStyles();
    const router = useRouter();
    const activeLinkItem = router === null || router === void 0 ? void 0 : router.asPath;
    useLoaded(props);
    return (_jsx("div", { className: classes.tags, children: !!(categories === null || categories === void 0 ? void 0 : categories.length) && (_jsx(Tags, { tags: categories, className: classes.tagChipsItems, activeLinkItem: activeLinkItem })) }));
};
export default BlogCategoryLinks;
