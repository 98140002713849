import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    // Moved from productSummary which was only used here
    '& p': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg),
    // Removed as overridden below
    // heading: {
    //   ...theme.typography.xl_bold,
    //   ...theme.typographySpacing.xl_bold_lg,
    //   fontWeight: fontWeights.regular,
    //   color: colors.supplementary.black,
    //   [theme.breakpoints.up('md')]: {
    //     ...theme.typography.xxxl,
    //   },
    // },
    steps: {
        marginBottom: spacing.large,
    },
    active: {
        border: `1px solid ${colors.supplementary.black}`,
        borderWidth: '1px 0',
        paddingTop: spacing.large,
        paddingLeft: spacing.small,
        paddingRight: spacing.small,
        paddingBottom: spacing.small,
        marginBottom: spacing.xl,
        borderBottom: `1px solid ${colors.supplementary.black}`,
        [theme.breakpoints.up('md')]: {
            borderWidth: '1px',
            marginLeft: `0 !important`,
            marginRight: `0 !important`,
            padding: spacing.xl,
            paddingBottom: spacing.medium,
            marginBottom: spacing.medium,
        },
    },
    attached: {
        marginBottom: 0,
        borderBottom: 'none',
        paddingBottom: spacing.large,
    },
    sectionTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    input: { marginBottom: spacing.small },
    inputSpacingLarge: { marginBottom: spacing.large },
    labelTitle: { marginBottom: spacing.medium },
    link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(colors.primary.gold4)), { color: colors.primary.gold5 }),
    imageContainer: {
        maxWidth: '286px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '200px',
        },
    },
    priceContainer: {
        marginBottom: spacing.medium,
    },
    price: Object.assign(Object.assign({}, theme.typography.medium_bold), { paddingRight: spacing.xs }),
    promoText: Object.assign(Object.assign({}, theme.typography.small_bold), { color: colors.secondary.lavender }),
    // Removed as overridden below
    // productName: {
    //   ...theme.typography.small_regular,
    //   [theme.breakpoints.up('md')]: {
    //     ...theme.typography.large_regular,
    //   },
    // },
    highlight: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.small_bold)),
    addressList: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        } }),
    definitionList: {
        '& > li': {
            marginBottom: spacing.xs,
            overflowWrap: 'anywhere',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '&:last-child': {
                marginBottom: '0',
            },
        },
        '& div': Object.assign(Object.assign({}, theme.typography.medium_regular), { overflowWrap: 'anywhere' }),
        [`& .${classes.highlight}`]: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.small_bold)), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : theme.typographySpacing.small_bold_lg)) }),
    },
    serviceDescription: {},
    equalWidthColumns: {
        [theme.breakpoints.up('md')]: {
            '& > li': {
                display: 'grid',
                gridTemplateColumns: '50% 50%',
            },
            '& ul': {
                textAlign: 'left',
            },
        },
        [`& .${classes.highlight}`]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none),
        '& div': Object.assign({}, theme.typography.small_regular),
        [`& .${classes.serviceDescription}`]: Object.assign({}, theme.typography.small_bold),
    },
    adjacent: {
        '& > li': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    fullSpacing: {
        paddingBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xl,
        },
    },
    supplementary: Object.assign({ display: 'block' }, theme.typography.xxs_regular),
    controls: {
        marginBottom: spacing.large,
    },
    orderConfirmationContainer: {
        marginBottom: spacing.xxl,
        [theme.breakpoints.up('md')]: {
            width: '792px',
            [`& .${classes.price}`]: Object.assign({}, theme.typography.xl_bold),
            [`& .${classes.promoText}`]: Object.assign({}, theme.typography.medium_bold),
        },
    },
    heading: Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : 'left' }, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.xl_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_sm : theme.typographySpacing.xl_bold_lg)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_lg : theme.typographySpacing.xl_regular_sm)) }),
    contentContainer: {
        [theme.breakpoints.up('md')]: {
            padding: 0,
            marginBottom: spacing.xxl,
        },
    },
    orderSummary: {
        border: 'none',
        borderBottom: `1px solid ${colors.grey.grey3}`,
        [`& .${classes.active}`]: {
            border: 'none',
            backgroundColor: colors.grey.grey1,
            borderBottom: `1px solid ${colors.grey.grey3}`,
            '&:last-child': {
                borderBottom: 'none',
            },
        },
    },
    border: {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    orderSummaryHeading: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    viewDetails: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, textAlign: 'left', [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        } }),
    noMargin: {
        marginBottom: '0',
    },
    summaryHeading: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    alertMessage: {
        marginBottom: spacing.large,
        '& a': Object.assign(Object.assign({}, link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? undefined : colors.primary.gold5 }),
    },
    buyAgainContainer: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '200px 1fr',
            columnGap: theme.isFlyingFlowers ? spacing.xl : spacing.large,
            alignItems: 'center',
        },
    },
    farEyeBuyAgainContainer: {
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '336px 1fr',
        },
    },
    wantAnotherTitle: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm),
    productImageContainer: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
        '& .[$imageContainer]': {
            marginBottom: 'initial',
        },
    },
    mobileProductImageContainer: {
        marginTop: spacing.medium,
        marginBottom: theme.isFlyingFlowers ? 'initial' : spacing.small,
    },
    buyAgainProductName: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.isFlyingFlowers ? `${spacing.large} !important` : `${spacing.medium} !important`,
        },
    },
    wantAnotherButton: {
        [theme.breakpoints.up('md')]: {
            width: '188px',
        },
    },
    finishingTouches: {
        marginTop: spacing.large,
        '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.supplementary.black }),
    },
    finishingTouchContainer: {
        border: `1px solid ${colors.grey.grey3}`,
        padding: spacing.small,
        '&:not(:last-of-type)': {
            marginBottom: spacing.small,
        },
    },
    productName: Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { textTransform: theme.isFlyingFlowers ? 'uppercase' : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    orderNumber: Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    variants: Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), '& > span:not(:last-child):after': {
            content: '"|"',
            marginLeft: spacing.xs,
            marginRight: spacing.xs,
        }, deliveryPassImageContainer: {
            maxWidth: '480px',
            marginBottom: '32px',
        } }),
    deliveryPassImageContainer: {},
    orderNumbers: {
        '& button': {
            display: 'block',
            textAlign: 'left',
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
            },
            width: '100%',
        },
    },
    orderSummaryTotal: {
        '& > li': {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        },
        '& div:last-child': {
            textAlign: 'right',
        },
    },
    collectionSectionTitle: Object.assign(Object.assign({}, theme.typography.small_bold), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg) }),
    collectionInfo: {
        marginBottom: spacing.medium,
        '& p': Object.assign({ margin: 0 }, theme.typography.small_regular),
    },
    floristLocation: {
        position: 'relative',
        height: '287px',
        width: '287px',
        [theme.breakpoints.up('md')]: {
            height: '336px',
            width: '336px',
        },
        '& > div': {
            height: '100%',
        },
    },
}));
export default useStyles;
