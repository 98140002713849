import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FuneralUsp from '../FuneralUsp/FuneralUsp';
import useStyles from './FuneralUspGallery.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const FuneralUspGallery = (props) => {
    const { items, title, description } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [_jsx("h2", { className: classes.title, children: title }), _jsx("div", { className: classes.description, children: description }), !!(items === null || items === void 0 ? void 0 : items.length) && (_jsx("div", { className: classes.container, children: items === null || items === void 0 ? void 0 : items.map((item, index) => (_jsx("div", { className: classes.itemContainer, children: _jsx(FuneralUsp, Object.assign({}, item)) }, index))) }))] }));
};
export default FuneralUspGallery;
