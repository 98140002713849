import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    contentFeature: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        textAlign: 'center',
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            height: '100%',
        },
    },
    contentInner: {
        width: '100%',
        margin: spacing.large,
        [theme.breakpoints.between('sm', 'lg')]: {
            marginBottom: '56px',
        },
        [theme.breakpoints.up('md')]: {
            margin: spacing.xxl,
            alignSelf: 'center',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '128px',
            alignSelf: 'center',
        },
        '& > :last-child': {
            marginBottom: '0px',
        },
    },
    citationMessage: {
        marginBottom: '20px',
    },
    squareVideoContainer: {
        paddingTop: '100%',
        '& > div': {
            position: 'unset',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '50%',
        },
    },
    quotationMarkStart: {
        transform: 'scaleX(-1)',
        top: '30px',
        left: '32px',
        [theme.breakpoints.up('md')]: { top: '61px', left: '73px' },
    },
    quotationMarkEnd: {
        right: '26px',
        bottom: '24px',
        [theme.breakpoints.up('md')]: {
            right: '64px',
            bottom: '69px',
        },
    },
    contentFeatureQuoteContainer: {
        position: 'relative',
    },
    // Needed for Content Feature
    contentWithQuotationMarks: {
        [`& .${classes.contentInner}`]: {
            marginTop: '48px',
            marginBottom: '48px',
            [theme.breakpoints.up('md')]: {
                marginTop: '116px',
                marginBottom: '116px',
            },
        },
    },
    citation: Object.assign(Object.assign({}, theme.typographySpacing.none), { fontStyle: 'italic' }),
    content: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
        },
    },
    imageSpacer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: '50%',
        },
    },
    positionCenter: {
        [theme.breakpoints.up('sm')]: {
            [`& .${classes.imageSpacer}`]: {
                display: 'none',
            },
            [`& .${classes.content}`]: {
                width: '100%',
            },
        },
    },
    textLeft: {
        textAlign: 'left',
    },
    textRight: {
        textAlign: 'right',
    },
    textCenter: {
        textAlign: 'center',
    },
    positionLeft: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom right',
            },
        },
    },
    positionRight: {
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            [`& .${classes.content}`]: {
                justifyContent: 'right',
            },
            [`& .${classes.image}.${classes.ffImage} img`]: {
                objectPosition: 'bottom left',
            },
        },
    },
    image: {},
    ffImage: {},
}));
export default useStyles;
