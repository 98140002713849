import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    bingMap: {
        height: '300px',
        [theme.breakpoints.up('md')]: {
            height: '100%',
        },
        '& > div': {
            zIndex: '12 !important',
        },
    },
}));
export default useStyles;
