import { makeStyles } from 'tss-react/mui';
import { fontSizes } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    checkCircle: {
        '& .MuiSvgIcon-root': {
            fontSize: fontSizes.xl,
        },
        '& .MuiButtonBase-root': {
            padding: 0,
            margin: '8px 8px 8px 0px',
            borderRadius: '50%',
            '&:hover': { boxShadow: '0px 4px 8px rgb(0 0 0 / 32%)' },
            '&:focus-within': { outline: 'none', boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgb(0 0 0 / 32%)' },
        },
    },
    error: {
        '&  .MuiFormControlLabel-label': {
            color: theme.statusColors.error.base,
        },
    },
    fullWidth: {
        width: '100%',
        '& .MuiFormControlLabel-label': { flexGrow: 1 },
    },
    top: { alignItems: 'flex-start', '& .MuiFormControlLabel-label': { paddingTop: 10 } },
    bottom: { alignItems: 'flex-end', '&  .MuiFormControlLabel-label': { paddingBottom: 10 } },
    errorMessage: {
        color: theme.statusColors.error.base,
    },
}));
export default useStyles;
