import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slide } from '@mui/material';
import useStyles from './MegaNavMobile.styles';
import NavLink from '../NavLink/NavLink';
import MobileNavProducts from './MobileNavProducts';
import MobileSpotlightDoubleNavigationCard from './MobileSpotlightDoubleNavigationCard';
const MobileNavMultiLinkGroup = (props) => {
    var _a;
    const { classes } = useStyles();
    const { navDirection, currentMenuItem, productsList, spotlightDoubleBanner, hasCombinationSpotlightCards, isAllSingleSpotlightCards, changeMenu, setBadgeLineHeight, } = props;
    return (_jsx(Slide, { direction: navDirection, in: true, children: _jsxs("ul", { children: [_jsx(NavLink, Object.assign({}, currentMenuItem.link, { isFromMegaNavMobile: true })), currentMenuItem.linkGroups.map((linkGroup, index) => (_jsx(NavLink, Object.assign({}, linkGroup.titleLink, { goForward: true, onClick: () => changeMenu('forward', index, linkGroup.titleLink.label), isFromMegaNavMobile: true }), linkGroup.titleLink.label))), (productsList === null || productsList === void 0 ? void 0 : productsList.length) ? (_jsx(MobileNavProducts, { productsList: productsList, setBadgeLineHeight: setBadgeLineHeight })) : null, (spotlightDoubleBanner === null || spotlightDoubleBanner === void 0 ? void 0 : spotlightDoubleBanner.length) ? (_jsx(MobileSpotlightDoubleNavigationCard, { spotlightDoubleBanner: spotlightDoubleBanner, hasCombinationSpotlightCards: hasCombinationSpotlightCards, isAllSingleSpotlightCards: isAllSingleSpotlightCards })) : null, currentMenuItem.linkGroups.length > 8 || !!((_a = currentMenuItem.products) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx("li", { className: classes.navLinkDummy })) : null] }) }));
};
export default MobileNavMultiLinkGroup;
