import { makeStyles } from 'tss-react/mui';
import { colors, spacing, hideScrollbars, fontWeights } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params) => ({
    savedAddresses: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1,1fr)',
        gridRowGap: theme.isFlyingFlowers ? spacing.small : spacing.medium,
        gridColumnGap: 0,
        [theme.breakpoints.up('md')]: {
            gridColumnGap: theme.isFlyingFlowers ? spacing.small : spacing.medium,
            width: '520px',
        },
    },
    contactList: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
    contact: {
        fontWeight: theme.isFlyingFlowers ? fontWeights.bold : fontWeights.medium,
    },
    icon: {
        color: theme.isFlyingFlowers ? theme.statusColors.warning.base : colors.supplementary.black,
        marginRight: spacing.xs,
        '& .material-icons': {
            fontSize: '22px',
        },
    },
    button: {
        width: '100%',
        textAlign: 'center',
        padding: `${spacing.medium} 0 12px 0`,
        margin: 'auto',
    },
    addressCardWrapper: {
        width: '100%',
    },
    addressBookOuterWrapper: Object.assign(Object.assign({ overflowY: 'auto' }, hideScrollbars), { maxHeight: '340px', margin: `${spacing.medium} 0`, [theme.breakpoints.up('md')]: {
            height: '100%',
            maxHeight: '500px',
            overflowY: 'hidden',
        } }),
    addressBookInnerWrapper: Object.assign(Object.assign({ position: 'relative' }, hideScrollbars), { display: 'grid', justifyContent: 'center', gridRowGap: theme.isFlyingFlowers ? spacing.small : spacing.medium, gridColumnGap: 0, [theme.breakpoints.up('md')]: {
            gridColumnGap: theme.isFlyingFlowers ? spacing.small : spacing.medium,
        } }),
    addressBookInnerWrapperLgUp: {
        transition: 'all 1.5s ease-in-out',
        transform: `translateY(calc(calc(-176px * ${params.cardIndex}) - calc(${theme.isFlyingFlowers ? spacing.small : spacing.medium} * ${params.cardIndex})))`,
    },
    addressCardContainer: {
        [theme.breakpoints.up('md')]: {
            width: '516px',
        },
    },
    recipientLabel: Object.assign({}, theme.typography.xxs_bold),
    alert: {
        alignItems: 'flex-start',
        '& > :first-child': {
            paddingTop: '3px',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            '& > :first-child': {
                paddingTop: '0px',
            },
        },
    },
    alertMessage: Object.assign({}, theme.typography.small_regular),
    roundButton: {},
    roundButtonLabel: {},
}));
export default useStyles;
