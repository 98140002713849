import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import useStyles from './EditPhoneNumber.styles';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { Form, Formik, FormikContext } from 'formik';
import * as Yup from 'yup';
import { errorMessages, phoneValidation, SiteContext, trimPhoneSpace } from '../../utils/common';
export const validationSchema = Yup.object().shape({
    contactNumber: Yup.string()
        .trim()
        .max(50, errorMessages(50).maxChars)
        .when('shouldValidatePhone', {
        is: (shouldValidatePhone) => !!shouldValidatePhone,
        then: Yup.string()
            .required(errorMessages().required)
            .matches(/^[+]?[0-9 ]*$/, errorMessages().phone)
            .min(4, errorMessages(4).minChars),
        otherwise: Yup.string()
            .test('Phone Number Validation', errorMessages().phone, (value) => phoneValidation(value))
            .min(9, errorMessages(9).minChars),
    }),
    shouldValidatePhone: Yup.boolean(),
});
export const getInitialValues = (phoneNumber, isFlyingFlowers) => {
    return {
        contactNumber: phoneNumber,
        shouldValidatePhone: !isFlyingFlowers,
    };
};
const EditPhoneNumber = (props) => {
    var _a;
    const { phoneNumber, onSubmit, onCancel } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const formikContext = useContext(FormikContext);
    const { classes } = useStyles();
    const formikConfig = {
        initialValues: getInitialValues(phoneNumber, isFlyingFlowers),
        enableReinitialize: true,
        validationSchema,
        onSubmit,
    };
    useEffect(() => {
        if (formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue) {
            formikContext.setFieldValue('shouldValidatePhone', !isFlyingFlowers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFlyingFlowers, (_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.shouldValidatePhone]);
    return (_jsxs("div", { className: classes.container, children: [_jsx("h1", { children: "Editing contact number" }), _jsxs("div", { className: classes.box, children: [_jsx("h2", { children: "Contact number" }), _jsx("p", { children: "We will only use this to contact you about your orders." }), _jsx(Formik, Object.assign({}, formikConfig, { children: ({ isSubmitting }) => (_jsxs(Form, { children: [_jsx(TextField, { name: "contactNumber", label: "Phone Number", placeholder: "Please enter a contact number", fullWidth: true, required: true, trimOnBlur: true, inputProps: { maxLength: 50 }, onInput: trimPhoneSpace }), _jsxs("div", { className: classes.buttons, children: [_jsx("div", { className: classes.saveButton, children: _jsx(Button, { variant: "secondary", thin: true, fullWidth: true, title: "Save contact number", isSubmit: true, disabled: isSubmitting, icon: isFlyingFlowers ? 'check' : undefined }) }), _jsx(Button, { variant: "tertiary", thin: true, fullWidth: true, title: "Cancel - discard changes", icon: isFlyingFlowers ? 'close' : undefined, onClick: onCancel })] })] })) }))] })] }));
};
export default EditPhoneNumber;
