var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Link from '../Link/Link';
import DeliveryPass, { AMPLIENCE_KEY } from '../DeliveryPass/DeliveryPass';
import useStyles from './DeliveryPassLink.styles';
import Modal from '../Modal/Modal';
import PreloadedComponent from '../PreloadedComponent/PreloadedComponent';
import { ucFirst } from '../../utils/common';
const DeliveryPassLinkInner = (props) => {
    const [open, setOpen] = useState(false);
    const { classes } = useStyles();
    const { title, addDeliveryPass, hasInternationalProduct, deliveryPassPromotionText, deliveryPassPromotionType, 
    // eslint-disable-next-line
    showDeliveryPassSkeleton, setShowDeliveryPassSkeleton } = props, other = __rest(props, ["title", "addDeliveryPass", "hasInternationalProduct", "deliveryPassPromotionText", "deliveryPassPromotionType", "showDeliveryPassSkeleton", "setShowDeliveryPassSkeleton"]);
    const addToBasket = (sku) => __awaiter(void 0, void 0, void 0, function* () {
        yield addDeliveryPass(sku);
        setOpen(false);
    });
    useEffect(() => {
        if (deliveryPassPromotionType &&
            deliveryPassPromotionText &&
            showDeliveryPassSkeleton &&
            setShowDeliveryPassSkeleton) {
            setShowDeliveryPassSkeleton(false);
        }
    }, [deliveryPassPromotionType, deliveryPassPromotionText, showDeliveryPassSkeleton, setShowDeliveryPassSkeleton]);
    return (_jsxs(_Fragment, { children: [deliveryPassPromotionType && deliveryPassPromotionText && !showDeliveryPassSkeleton ? (_jsxs("div", { className: classes.container, children: [_jsx("p", { children: deliveryPassPromotionText }), _jsx(Link, { onClick: () => setOpen(true), className: classes.link, label: `Add Interflora ${ucFirst(deliveryPassPromotionType === null || deliveryPassPromotionType === void 0 ? void 0 : deliveryPassPromotionType.toLowerCase())} to your order` })] })) : null, open ? (_jsx(Modal, { open: open, setOpen: setOpen, title: props.title || 'Delivery Passes', maxWidth: "xl", children: _jsx(DeliveryPass, Object.assign({}, other, { addToBasket: addToBasket })) })) : null] }));
};
const DeliveryPassLink = (props) => (_jsx(PreloadedComponent, { componentKey: AMPLIENCE_KEY, Component: DeliveryPassLinkInner, ComponentProps: props }));
export default DeliveryPassLink;
