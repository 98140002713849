import React from 'react'
import dynamic from 'next/dynamic'
import ProductDetail from '../components/ProductDetail'
import ProductGallery from '../components/ProductGallery'
import SkeletonExperiment from '@interflora/ui-components/build/components/SkeletonExperiment/SkeletonExperiment'
import { cssForPCCSkeleton } from '../constants'
import DefaultHeader from '../components/Header/Header'
import SkeletonPageHeader from '@interflora/ui-components/build/components/SkeletonPageHeader/SkeletonPageHeader'
import LinkGallery from '../components/LinkGallery'
import HomeHeroBanner from '../components/HomeHeroBanner'
import FfHomeHeroBanner from '../components/FFHomeHeroBanner'

const skeletonComponents = {
  HomeHeroBannerGallery: SkeletonPageHeader,
  FfHomeHeroBannerGallery: SkeletonPageHeader,
}

function getSkeletonComponents(componentName: string) {
  const DynamicComponent = skeletonComponents[componentName]
  return <DynamicComponent />
}

const components = {
  Alert: dynamic(() => import('@interflora/ui-components/build/components/Alert/Alert')),
  AlphabetFilter: dynamic(() => import('@interflora/ui-components/build/components/AlphabetFilter/AlphabetFilter')),
  AvailabilityChecker: dynamic(() => import('../components/AvailabilityChecker/AvailabilityChecker'), {
    loading: () => <SkeletonExperiment css={cssForPCCSkeleton} />,
  }),

  Banner: dynamic(() => import('@interflora/ui-components/build/components/Banner/Banner')),
  BlogArticleContent: dynamic(
    () => import('@interflora/ui-components/build/components/BlogArticleContent/BlogArticleContent')
  ),
  BlogArticleGallery: dynamic(() => import('../components/BlogFeatureArticleGallery/BlogFeatureArticleGallery')),
  BlogArticleListing: dynamic(() => import('../components/BlogArticleListing/BlogArticleListing')),
  BlogCategoryLinkGallery: dynamic(
    () => import('@interflora/ui-components/build/components/BlogCategoryLinks/BlogCategoryLinks')
  ),
  BlogCategoryLinks: dynamic(
    () => import('@interflora/ui-components/build/components/BlogCategoryLinks/BlogCategoryLinks')
  ),
  BlogFeatureArticleGallery: dynamic(() => import('../components/BlogFeatureArticleGallery/BlogFeatureArticleGallery')),
  BlogHeroBanner: dynamic(() => import('@interflora/ui-components/build/components/BlogHeroBanner/BlogHeroBanner')),
  BrandUspGallery: dynamic(() => import('@interflora/ui-components/build/components/BrandUspGallery/BrandUspGallery')),
  Breadcrumb: dynamic(() => import('@interflora/ui-components/build/components/BreadcrumbBar/BreadcrumbBar')),
  CheckoutFooter: dynamic(() => import('@interflora/ui-components/build/components/CheckoutFooter/CheckoutFooter')),
  CheckoutHeader: dynamic(() => import('@interflora/ui-components/build/components/CheckoutHeader/CheckoutHeader')),
  ChildLocations: dynamic(
    () => import('@interflora/ui-components/build/components/ChildLocationsGallery/ChildLocationsGallery')
  ),
  ContentFeature: dynamic(() => import('../components/ContentFeature/ContentFeature')),
  ContentGallery: dynamic(() => import('@interflora/ui-components/build/components/ContentGallery/ContentGallery')),
  ContentLinkGallery: dynamic(
    () => import('@interflora/ui-components/build/components/ContentLinkGallery/ContentLinkGallery')
  ),
  ContentReference: dynamic(
    () => import('@interflora/ui-components/build/components/ContentReference/ContentReference')
  ),
  ContentStory: dynamic(() => import('@interflora/ui-components/build/components/ContentStory/ContentStory')),
  CountryFlagsGallery: dynamic(
    () => import('@interflora/ui-components/build/components/CountryFlagsGallery/CountryFlagsGallery')
  ),
  DefaultHeader,
  DefaultFooter: dynamic(() => import('../components/Footer/Footer')),
  DeliveryInformation: dynamic(
    () => import('@interflora/ui-components/build/components/DeliveryInformation/DeliveryInformation')
  ),
  DeliveryPassOverview: dynamic(() => import('@interflora/ui-components/build/components/DeliveryPass/DeliveryPass')),
  ExpandableContainer: dynamic(() => import('../components/ExpandableContainer/ExpandableContainer')),
  ExpandableDeliveryInformation: dynamic(
    () =>
      import('@interflora/ui-components/build/components/ExpandableDeliveryInformation/ExpandableDeliveryInformation')
  ),
  Experiment: dynamic(() => import('../components/Experiment/Experiment')),
  ExplanatorySteps: dynamic(
    () => import('@interflora/ui-components/build/components/ExplanatorySteps/ExplanatorySteps')
  ),
  FaqGallery: dynamic(() => import('@interflora/ui-components/build/components/FaqGalleryComponent/FaqGallery')),
  FeatureBanner: dynamic(() => import('@interflora/ui-components/build/components/FeatureBanner/FeatureBanner')),
  FfHeroBanner: dynamic(() => import('@interflora/ui-components/build/components/FFHeroBanner/FFHeroBanner')),
  FfHomeHeroBanner,
  FfHomeHeroBannerGallery: dynamic(
    () => import('@interflora/ui-components/build/components/FFHomeHeroBannerGallery/FFHomeHeroBannerGallery'),
    {
      loading: () => getSkeletonComponents('FfHomeHeroBannerGallery'),
      ssr: false,
    }
  ),
  FfProductBadge: dynamic(() => import('@interflora/ui-components/build/components/ProductBadge/ProductBadge')),
  FfVideo: dynamic(() => import('@interflora/ui-components/build/components/Video/Video')),
  FfVideoHomeHeroBanner: dynamic(
    () => import('@interflora/ui-components/build/components/FFHomeVideoBanner/FFHomeVideoBanner')
  ),
  FfSalesBadge: dynamic(() => import('@interflora/ui-components/build/components/SalesBadge/SalesBadge')),
  FloristDetails: dynamic(() => import('@interflora/ui-components/build/components/FloristDetails/FloristDetails')),
  FloristSearch: dynamic(() => import('../components/FloristSearch/FloristSearch')),
  FloristSpecialismContentGallery: dynamic(
    () =>
      import(
        '@interflora/ui-components/build/components/FloristSpecialismContentGallery/FloristSpecialismContentGallery'
      )
  ),
  FloristUsp: dynamic(() => import('@interflora/ui-components/build/components/FloristUsp/FloristUsp')),
  FloristUspGallery: dynamic(
    () => import('@interflora/ui-components/build/components/FloristUspGallery/FloristUspGallery')
  ),
  FuneralUspGallery: dynamic(
    () => import('@interflora/ui-components/build/components/FuneralUspGallery/FuneralUspGallery')
  ),
  GuidedNavigation: dynamic(() => import('../components/GuidedNavigation/GuidedNavigation'), {
    loading: () => <SkeletonExperiment css={cssForPCCSkeleton} />,
  }),
  Help: dynamic(() => import('@interflora/ui-components/build/components/Help/Help')),
  HeroBanner: dynamic(() => import('@interflora/ui-components/build/components/HeroBanner/HeroBanner')),
  HomeHeroBanner,
  HomeHeroBannerGallery: dynamic(
    () => import('@interflora/ui-components/build/components/HomeHeroBannerGallery/HomeHeroBannerGallery'),
    {
      loading: () => getSkeletonComponents('HomeHeroBannerGallery'),
      ssr: false,
    }
  ),
  IframeHolder: dynamic(() => import('@interflora/ui-components/build/components/Iframe/Iframe')),
  LatestBlogArticleGallery: dynamic(() => import('../components/BlogLatestArticleGallery/BlogLatestArticleGallery')),
  LinkGallery,
  LocationFloristListing: dynamic(() => import('../components/LocationFlorist')),
  LocationHeroBanner: dynamic(
    () => import('@interflora/ui-components/build/components/LocationHeroBanner/LocationHeroBanner')
  ),
  MediaGallery: dynamic(() => import('@interflora/ui-components/build/components/MediaGallery/MediaGallery')),
  MyAccountCards: dynamic(() => import('@interflora/ui-components/build/components/MyAccountCards/MyAccountCards')),
  MyAccountReminders: dynamic(() => import('../components/MyAccountReminders')),
  MyAccountRecentOrdersAndDeliveryPass: dynamic(() => import('../components/MyAccountRecentOrdersAndDeliveryPass')),
  MyAccountWelcomeTitle: dynamic(() => import('../components/MyAccountWelcomeTitle')),
  NewsLetterSignUpSiteWide: dynamic(() => import('../components/NewsLetter')),
  NoSearchResultsHeader: dynamic(
    () => import('@interflora/ui-components/build/components/NoSearchResultsHeader/NoSearchResultsHeader')
  ),
  PageHeader: dynamic(() => import('@interflora/ui-components/build/components/PageHeader/PageHeader')),
  ProductBadge: dynamic(() => import('@interflora/ui-components/build/components/ProductBadge/ProductBadge')),
  ProductDetail,
  ProductGallery,
  ProductListing: dynamic(() => import('../components/ProductListing/ProductListing')),
  PromoContentItem: dynamic(
    () => import('@interflora/ui-components/build/components/PromoContentItem/PromoContentItem')
  ),
  PromoContentGallery: dynamic(() => import('../components/PromoContentGallery/PromoContentGallery')),
  RelatedContentGallery: dynamic(() => import('../components/RelatedContentGallery/RelatedContentGallery')),
  RelatedLinkGallery: dynamic(
    () => import('@interflora/ui-components/build/components/RelatedLinksGallery/RelatedLinksGallery')
  ),
  RichText: dynamic(() => import('@interflora/ui-components/build/components/RichText/RichText')),
  SpotlightBanner: dynamic(() => import('@interflora/ui-components/build/components/SpotlightBanner/SpotlightBanner')),
  SpotlightNavigationProductCard: dynamic(
    () => import('@interflora/ui-components/build/components/SpotlightNavigationCard/SpotlightNavigationCard')
  ),
  SearchResultsHeader: dynamic(() => import('../components/SearchResultsHeader/SearchResultsHeader')),
  // SearchResultsListing: dynamic(() => import('../components/ProductListing/ProductListing')),
  SearchResultsListing: dynamic(() => import('../components/ProductListing/SearchResultsListing')),
  SeoCategoryInformation: dynamic(
    () => import('@interflora/ui-components/build/components/CategoryInformation/CategoryInformation')
  ),
  SiteNotice: dynamic(() => import('@interflora/ui-components/build/components/SiteNotice/SiteNotice')),
  SiteUspGallery: dynamic(() => import('@interflora/ui-components/build/components/SiteUspGallery/SiteUspGallery')),
  SkeletonPageHeader: dynamic(
    () => import('@interflora/ui-components/build/components/SkeletonPageHeader/SkeletonPageHeader')
  ),
  Title: dynamic(() => import('@interflora/ui-components/build/components/Title/Title')),
  TrustpilotWidget: dynamic(
    () => import('@interflora/ui-components/build/components/TrustpilotWidget/TrustpilotWidget')
  ),
  Video: dynamic(() => import('@interflora/ui-components/build/components/Video/Video')),
  VideoHomeHeroBanner: dynamic(
    () => import('@interflora/ui-components/build/components/HomeVideoBanner/HomeVideoBanner')
  ),
}

function getDynamicComponent(c: any) {
  if (Object.prototype.hasOwnProperty.call(components, c.uiType)) {
    return components[c.uiType]
  }
  console.log('Unable to find component:', c.uiType)
  return ''
}

export function renderComponents(components) {
  if (!components) {
    return ''
  }
  return components.map(renderComponent).filter(Boolean)
}

/**
 * Component to wrap dynamic component in div but also pass the onLoaded param through
 */
function RenderDynamicComponent({
  component,
  onLoaded,
  DynamicComponent,
}: Readonly<{
  onLoaded?: () => void
  component: any // Adjust the type for 'component' based on your needs
  DynamicComponent: any
}>) {
  return (
    <div id={component.data?.anchorKey} data-type={component.uiType} data-id={component.meta?.deliveryId}>
      <DynamicComponent {...component.data} meta={component.meta} uiType={component.uiType} onLoaded={onLoaded} />
    </div>
  )
}

function renderComponent(component, index = undefined) {
  const DynamicComponent = getDynamicComponent(component)
  if (DynamicComponent) {
    return component.uiType === 'DefaultHeader' ? (
      <DynamicComponent key={index} {...component.data} meta={component.meta} uiType={component.uiType} />
    ) : (
      <RenderDynamicComponent key={index} component={component} DynamicComponent={DynamicComponent} />
    )
  }
  console.warn('No such component:', component.uiType)
  return ''
}

// Helper to populate a template from an objects data
export function populateTemplateValues<T>(original: any, template: T): T {
  const result = {} as T
  Object.keys(template).forEach((key) =>
    original[key] && typeof original[key] === 'object'
      ? (result[key] = populateTemplateValues(original[key], template[key]))
      : (result[key] = original[key] ?? template[key])
  )
  return result
}
