export const monthsList = [
    { month: 'January', monthNumber: 1 },
    { month: 'February', monthNumber: 2 },
    { month: 'March', monthNumber: 3 },
    { month: 'April', monthNumber: 4 },
    { month: 'May', monthNumber: 5 },
    { month: 'June', monthNumber: 6 },
    { month: 'July', monthNumber: 7 },
    { month: 'August', monthNumber: 8 },
    { month: 'September', monthNumber: 9 },
    { month: 'October', monthNumber: 10 },
    { month: 'November', monthNumber: 11 },
    { month: 'December', monthNumber: 12 },
];
