import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, params) => ({
    carousel: {
        maxWidth: '500px',
        backgroundColor: colors.grey.grey1,
        '& .react-multi-carousel-dot-list': {
            position: 'initial',
            paddingBottom: spacing.small,
        },
        '& .react-multi-carousel-dot button': {
            marginRight: '12px !important',
        },
    },
    // Based on the current Carousal behaviour, the carousalPlaceholder dimensions are calulated as below,
    // width is 500px if it the screen width is more than 500px otherwise its value is width of the screen
    // height value is width + 21.875px (21.875 is the height we reserve fo the carousal dots). If we have a label we are adding 40px to the carousalPlaceholder height
    // Here we are adding only 40px for label as we assume the label to be a one liner
    carousalPlaceholder: {
        width: '100vw',
        minHeight: params.hasLabelForImage ? `calc(100vw + 21.875px + 40px)` : `calc(100vw + 21.875px)`,
        backgroundColor: colors.grey.grey1,
        margin: 'auto',
        [theme.breakpoints.up(500)]: {
            width: '500px',
            minHeight: params.hasLabelForImage ? '561.875px' : '521.875px',
        },
    },
    imageContainer: {
        backgroundColor: colors.grey.grey1,
        cursor: 'pointer',
    },
    grid: {
        display: 'grid',
        gridGap: theme.spacing(3),
        gridTemplateRows: 'auto',
        gridTemplateColumns: 'auto',
        gridTemplateAreas: '"area-1"',
    },
    zoomInModal: {
        [theme.breakpoints.between('md', 'xl')]: {
            '& .MuiDialog-paperScrollPaper': {
                padding: spacing.medium,
            },
        },
        '& .MuiDialog-paper': {
            padding: '12px 20px 0 20px',
            [theme.breakpoints.up('md')]: {
                padding: '30px',
            },
            '& header': {
                display: 'grid',
                alignItems: 'unset',
                '& button': {
                    position: 'relative',
                    width: '40px',
                    height: '40px',
                    [theme.breakpoints.up('sm')]: {
                        top: spacing.medium,
                        '@media (orientation: landscape)': {
                            top: '21px',
                        },
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '60px',
                        width: '60px',
                        top: 2,
                    },
                },
                '& .MuiFab-label': {
                    width: '40px',
                    height: '40px',
                    [theme.breakpoints.up('md')]: {
                        height: '60px',
                        width: '60px',
                    },
                },
            },
        },
    },
}));
export default useStyles;
