import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from 'react-loading-skeleton';
import useStyles from './SkeletonProductListing.styles';
import SkeletonProductCard from '../SkeletonProductCard/SkeletonProductCard';
import productListingFilterStyles from '../ProductListingFilter/ProductListingFilter.styles';
import filterListStyles from '../FilterList/FilterList.styles';
import productListingStyles from '../ProductListing/ProductListing.styles';
import clsx from 'clsx';
const SkeletonProductListing = (props) => {
    const { productCount } = props;
    const { classes } = useStyles();
    const { classes: filterClasses } = productListingFilterStyles();
    const { classes: filterListClasses } = filterListStyles();
    const { classes: productListingClasses } = productListingStyles();
    return (_jsxs("div", { className: clsx(productListingClasses.productListing, classes.skeletonProductListing), children: [_jsx("div", { className: filterClasses.productListingFilter, children: _jsxs("div", { className: filterListClasses.filterList, children: [_jsxs("div", { className: filterListClasses.filterAction, children: [_jsx(Skeleton, { height: 40, width: 125, style: { marginRight: '24px' } }), _jsx(Skeleton, { height: 40, width: 156 })] }), _jsx("div", { className: filterListClasses.listContainer, children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Skeleton, { height: 56, width: 180 }) }), _jsx("li", { children: _jsx(Skeleton, { height: 56, width: 180 }) })] }) })] }) }), _jsx("div", { className: classes.skeletonProductListProductCard, children: _jsx(SkeletonProductCard, { count: productCount, breakpoints: {
                        small: 12,
                        medium: 4,
                        large: 3,
                    } }) })] }));
};
export default SkeletonProductListing;
