import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
import { link } from '../../styles/jss/utils';
const useStyles = makeStyles()((theme) => ({
    // This class reserves space for the KlanaPlacementComponent. The below minHeight calculations were made based on the current behaviour of the KlarnaPlacement Component.
    KlarnaPlacementContainer: {
        backgroundColor: colors.grey.grey1,
        [theme.breakpoints.down('md')]: {
            minWidth: '100%',
            minHeight: '122px',
            [theme.breakpoints.up(388)]: {
                minHeight: theme.isFlyingFlowers ? '102px' : '98px',
            },
            [theme.breakpoints.up(408)]: {
                minHeight: theme.isFlyingFlowers ? '82px' : '78px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '345px',
            minHeight: theme.isFlyingFlowers ? '82px' : '78px',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '130px',
            minWidth: '100%',
        },
        [theme.breakpoints.up(1063)]: {
            minHeight: '110px',
        },
        [theme.breakpoints.up(1212)]: {
            minHeight: theme.isFlyingFlowers ? '86px' : '82px',
            minWidth: '435px',
        },
    },
    klarnaContent: {
        marginBottom: `${spacing.medium}`,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            display: 'inline-flex',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: `${spacing.large}`,
        },
        '& ::part(osm-container)': {
            border: `1px solid ${colors.grey.grey3}`,
            backgroundColor: colors.supplementary.white,
        },
        '& ::part(osm-message)': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: colors.grey.grey6, padding: `0px 12px 0px ${spacing.xxs}`, textAlign: 'left', [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_regular) }),
        '& ::part(osm-cta)': Object.assign(Object.assign(Object.assign(Object.assign({ color: colors.grey.grey6 }, link(colors.grey.grey6)), theme.typography.xxs_regular), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_regular) }),
    },
}));
export default useStyles;
