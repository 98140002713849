import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Card } from '@mui/material';
import { useField } from 'formik';
import CheckCircle from '../CheckCircle/CheckCircle';
import useStyles from './ChooseDeliveryPass.styles';
import Image from '../Image/Image';
import { processImageUrl } from '../../utils/image';
import Icon from '../Icon/Icon';
import RawHtml from '../RawHtml/RawHtml';
import clsx from 'clsx';
const ChooseDeliveryPass = (props) => {
    const { deliveryPassOptions, deliveryPassPromoText, deliveryPassPromotionType } = props;
    const { classes } = useStyles();
    const [fieldInputProps, , helpers] = useField('deliveryPassSku');
    const [showDeliveryPassOptions, setShowDeliveryPassOptions] = useState(true);
    const MAX_IMAGE_WIDTH = 64;
    const deliveryPassUpgradeText = useMemo(() => {
        return !!fieldInputProps.value ? 'Delivery pass selected and active - saving you money now' : deliveryPassPromoText;
    }, [deliveryPassPromoText, fieldInputProps.value]);
    const matchedDeliveryPassImgUrl = useMemo(() => {
        var _a, _b, _c;
        return (((_a = deliveryPassOptions === null || deliveryPassOptions === void 0 ? void 0 : deliveryPassOptions.find((option) => (fieldInputProps === null || fieldInputProps === void 0 ? void 0 : fieldInputProps.value) === option.sku)) === null || _a === void 0 ? void 0 : _a.images[0]) ||
            ((_b = deliveryPassOptions === null || deliveryPassOptions === void 0 ? void 0 : deliveryPassOptions.find((pass) => { var _a, _b; return ((_b = (_a = pass.name) === null || _a === void 0 ? void 0 : _a.split(' ')[1]) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === deliveryPassPromotionType; })) === null || _b === void 0 ? void 0 : _b.images[0]) ||
            ((_c = deliveryPassOptions === null || deliveryPassOptions === void 0 ? void 0 : deliveryPassOptions[0]) === null || _c === void 0 ? void 0 : _c.images[0]));
    }, [deliveryPassOptions, fieldInputProps === null || fieldInputProps === void 0 ? void 0 : fieldInputProps.value, deliveryPassPromotionType]);
    return (_jsxs("div", { className: classes.container, children: [_jsx("div", { className: clsx(showDeliveryPassOptions && classes.closeDeliveryPassContainer), children: _jsxs("button", { className: classes.selectDeliveryPassContainer, onClick: (e) => {
                        e.preventDefault();
                        setShowDeliveryPassOptions(!showDeliveryPassOptions);
                    }, children: [_jsx("div", { className: classes.imageContainer, children: !!matchedDeliveryPassImgUrl && (_jsx(Image, { imageUrl: processImageUrl(matchedDeliveryPassImgUrl === null || matchedDeliveryPassImgUrl === void 0 ? void 0 : matchedDeliveryPassImgUrl.url, { w: MAX_IMAGE_WIDTH }, ['$poi-square$']), altText: matchedDeliveryPassImgUrl === null || matchedDeliveryPassImgUrl === void 0 ? void 0 : matchedDeliveryPassImgUrl.altText, className: classes.contentImage, height: 64, width: 64 })) }), _jsx("div", { className: classes.deliveryPassDescription, children: _jsx("h4", { children: deliveryPassUpgradeText }) }), _jsx("div", { children: _jsx(Icon, { icon: "keyboard_arrow_down", fontSize: "small", className: classes.arrowIcon }) })] }) }), !showDeliveryPassOptions && (_jsx("div", { className: classes.deliveryPassCards, children: deliveryPassOptions === null || deliveryPassOptions === void 0 ? void 0 : deliveryPassOptions.map((option, index) => {
                    return (_jsx(Card, { className: classes.card, children: _jsx(CheckCircle, { name: "deliveryPassSku", value: option.sku, checked: fieldInputProps.value === option.sku, onClick: () => helpers.setValue(fieldInputProps.value === option.sku ? '' : option.sku), onChange: () => { }, label: _jsxs("div", { className: classes.deliveryPassOption, children: [_jsxs("h5", { children: [option.name, " (1 Year) ", option.price.formatted] }), fieldInputProps.value !== option.sku && (_jsx(RawHtml, { content: option.description, className: classes.deliveryPassDescriptionText })), fieldInputProps.value === option.sku && (_jsx("p", { className: classes.selectedOption, children: "Added to your order" }))] }), fullWidth: true, controlAlignment: "top" }) }, index));
                }) }))] }));
};
export default ChooseDeliveryPass;
