import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    content: {
        '& h1, & h2, & h3, & h4': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { textAlign: theme.isFlyingFlowers ? 'center' : undefined }),
        '& ul li, & ol li': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none),
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
        '& li': {
            display: 'list-item',
        },
        '& ul': {
            listStyleType: 'disc',
            paddingInlineStart: '2em',
        },
        '& ol': {
            listStyleType: 'decimal',
            paddingInlineStart: '2em',
            '& > li::marker': {
                content: 'counter(list-item)"  "',
            },
        },
        '& a': {
            textDecoration: 'underline !important',
        },
    },
}));
export default useStyles;
