import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import FilterCurrentRefinements from '../FilterCurrentRefinements/FilterCurrentRefinements';
import { FilterNumericList, FilterRefinementList, SortByList } from '../FilterFacet/FilterFacet';
import FFFilterList from '../FilterList/FFFilterList';
import FilterList from '../FilterList/FilterList';
import Modal from '../Modal/Modal';
import useStyles from './FacetSelectionMobile.styles';
const FacetSelectionMobile = ({ open, setFacetListOpen, setFilterOpen, setFiltersClosed, filters, priceRanges, totalResultCount, sortConfig, sortBy, refinementList, selectedFilter: selectedFilterProp, setSelectedFilter, isFromCategory = true, facetDetails, }) => {
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const selectedFilter = (isFlyingFlowers && filters.find((filter) => filter.isOpen)) || filters[0];
    return (_jsx(Modal, { open: open, onClose: setFiltersClosed, keepMounted: true, fullScreen: true, setOpen: setFacetListOpen, title: "Sorting and filters", className: classes.modal, isFromCategory: isFromCategory, children: _jsxs("div", { className: classes.facetSelection, children: [_jsx(FilterCurrentRefinements, { priceRanges: priceRanges }), _jsx("div", { className: classes.filterListContainer, children: isFlyingFlowers ? (_jsx(FFFilterList, { totalResultCount: totalResultCount, filters: filters, setFacetListOpen: setFilterOpen, setFiltersClosed: () => { }, sortBy: sortBy, refinementList: refinementList, scrollToItem: true })) : (_jsx(FilterList, { totalResultCount: totalResultCount, filters: filters, setFacetListOpen: setFilterOpen, setFiltersClosed: () => { }, sortBy: sortBy, selectedFilter: selectedFilterProp, setSelectedFilter: setSelectedFilter })) }), _jsxs("div", { className: classes.facetContainer, children: [filters.map((filter) => (_jsx("div", { className: clsx(isFlyingFlowers
                                ? selectedFilter.attributeName === filter.attributeName
                                    ? classes.activeFilterItem
                                    : classes.hidden
                                : ''), children: filter.attributeName === 'sortBy' ? (_jsx(SortByList, { title: `${filter.attributeLabel} ${sortBy}`, attribute: "sortBy", defaultOpen: filter.isOpen, items: sortConfig, facetDetails: facetDetails })) : filter.attributeName === 'price' ? (_jsx(FilterNumericList, { title: filter.attributeLabel, attribute: "price", defaultOpen: filter.isOpen, items: priceRanges, selectedFilter: selectedFilterProp, setSelectedFilter: setSelectedFilter, facetDetails: facetDetails, isFromMobile: true })) : (_jsx(FilterRefinementList, { title: filter.attributeLabel, attribute: filter.attributeName, defaultOpen: filter.isOpen, selectedFilter: selectedFilterProp, setSelectedFilter: setSelectedFilter, facetDetails: facetDetails, isFromMobile: true })) }, filter.attributeName))), _jsx(Button, { className: classes.closeButton, variant: "secondary", onClick: () => setFacetListOpen(false), title: `Close - view ${totalResultCount} results` })] })] }) }));
};
export default FacetSelectionMobile;
