import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    resultsViewed: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.large }) }),
    actionButtons: {
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
        '& > :first-child': {
            marginBottom: spacing.medium,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
        },
        '& > :not(:last-child)': {
            marginRight: 0,
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.isFlyingFlowers ? spacing.large : spacing.medium,
            },
        },
        '& button': {
            width: '335px',
            height: '44px',
            color: colors.grey.grey7,
            [theme.breakpoints.up('md')]: {
                width: '300px',
                height: '60px',
            },
        },
    },
    canonicalLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default useStyles;
