import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    deliveryPassContainer: {
        flex: '50%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },
    deliveryPassTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typographySpacing.none), { marginBottom: spacing.small }) }),
    noDeliveryPassTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({ color: colors.supplementary.black }, theme.typographySpacing.none), { marginBottom: '18px' }) }),
    deliveryPassCard: {
        border: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        padding: spacing.medium,
        flex: '100%',
        [theme.breakpoints.up('md')]: {
            padding: spacing.xl,
            backgroundColor: colors.supplementary.white,
            marginBottom: 0,
        },
    },
    noDeliveryPassCard: {
        backgroundColor: colors.grey.grey1,
        [theme.breakpoints.up('md')]: {
            backgroundColor: colors.supplementary.white,
            paddingLeft: '40px',
            paddingRight: spacing.xl,
        },
    },
    productImage: {
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: 'none',
        },
    },
    productDetailContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: spacing.small,
        [`& .${classes.productImage}`]: {
            height: '108px',
            width: '108px',
            [theme.breakpoints.up('md')]: {
                height: '168px',
                width: '168px',
            },
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginBottom: spacing.large,
        },
    },
    expiresOnLabel: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { color: colors.grey.grey6 }),
    expiryDate: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    productName: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typographySpacing.medium_bold_sm), { marginBottom: spacing.xs }) }),
    productContainer: {
        lineHeight: '1.43rem',
        [theme.breakpoints.up('md')]: {
            marginLeft: '42px',
        },
    },
    productDescription: {
        lineHeight: '1.43rem',
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { color: colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.supplementary.black }) }),
    },
    renewalDescription: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg) }),
    noDeliveryPassContainer: {},
    noDeliveryPassContent: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            height: '280px',
            position: 'relative',
            top: 15,
        },
    },
    deliveryPassButton: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        '& button': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
        },
    },
    expiresInClass: {
        lineHeight: '1.43rem !important',
        [`& .${classes.expiresOnLabel}, & .${classes.expiryDate}`]: {
            color: theme.statusColors.error.base,
        },
    },
    renewalSavingsDescription: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_lg), { color: colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg) }),
    noDeliveryPassDescription: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: colors.grey.grey6, marginBottom: spacing.small }),
    productDetailSection: {
        '&:last-child': {
            marginBottom: spacing.xs,
        },
    },
}));
export default useStyles;
