import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import RecentOrderHistory from '../RecentOrderHistory/RecentOrderHistory';
import DeliveryPassCard from '../DeliveryPassCard/DeliveryPassCard';
import useStyles from './MyAccountRecentOrdersAndDeliveryPass.styles';
import { SiteContext } from '../../utils/common';
const MyAccountRecentOrdersAndDeliveryPass = (props) => {
    var _a, _b;
    const { classes } = useStyles();
    const { isFlyingFlowers, getCustomer } = useContext(SiteContext);
    const { order, addToBasket } = props;
    const deliveryPass = (_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.deliveryPass;
    useEffect(() => {
        getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs("div", { className: classes.recentOrderContainer, children: [_jsx(RecentOrderHistory, { order: order }), !isFlyingFlowers && deliveryPass && _jsx(DeliveryPassCard, Object.assign({}, deliveryPass, { addToBasket: addToBasket }))] }));
};
export default MyAccountRecentOrdersAndDeliveryPass;
