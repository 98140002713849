import RelatedContentGalleryUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/RelatedContentGallery/RelatedContentGallery'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { useLoaded } from '@interflora/ui-components'

type Props = UIProps

const RelatedContentGallery = (props: Props) => {
  const analytics = useContext(AnalyticsContext)

  const sendRelatedContentGalleryAnalytics = (linkText: string) => {
    analytics.sendRelatedContentGalleryAnalytics(linkText)
  }
  useLoaded(props)

  return <RelatedContentGalleryUI {...props} sendRelatedContentGalleryAnalytics={sendRelatedContentGalleryAnalytics} />
}

export default RelatedContentGallery
