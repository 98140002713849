import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        backgroundColor: colors.grey.grey1,
        padding: spacing.small,
        marginBottom: spacing.small,
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { marginBottom: spacing.xs, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm) }),
    },
    link: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), link(colors.primary.gold4)), { color: colors.primary.gold5, display: 'inline', cursor: 'pointer', textAlign: 'left', [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
}));
export default useStyles;
