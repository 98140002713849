import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    availabilityChecker: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing.small,
        gap: '12px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        backgroundColor: '#e8e8e8',
    },
    availabilitySelectors: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignContent: 'center',
        borderRadius: '4px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
            '& :nth-of-type(n+3)': {
                display: 'none',
            },
        },
    },
    actionButton: {
        '&.MuiButtonBase-root.MuiButton-root': { padding: `15px ${spacing.large} 13px ${spacing.large}` },
        alignSelf: 'center',
    },
    basicButton: {},
}));
export default useStyles;
