import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './OrderHistory.styles';
import OrderHistoryCard from '../OrderHistoryCard/OrderHistoryCard';
import Button from '../Button/Button';
const OrderHistory = (props) => {
    const { classes } = useStyles();
    const { orders, setPage, isLastPage, page, isLoading, farEyeTrackOrderAnalytics } = props;
    const loadMore = () => {
        setPage(page + 1);
    };
    return (_jsxs("div", { className: classes.orderHistorySection, children: [_jsx("h1", { children: "Order history" }), _jsx("h2", { className: classes.ordersHeading, children: "Your orders" }), orders && !!(orders === null || orders === void 0 ? void 0 : orders.length) ? (_jsx("div", { className: classes.orderHistoryContainer, children: orders === null || orders === void 0 ? void 0 : orders.map((order) => (_jsx(OrderHistoryCard, { order: order, farEyeTrackOrderAnalytics: farEyeTrackOrderAnalytics }, order.orderNumber))) })) : (_jsx("h3", { className: classes.noResults, children: "No Orders" })), !isLastPage && (_jsx("div", { className: classes.orderHistoryLoadMore, children: _jsx(Button, { variant: "tertiary", fullWidth: true, thin: true, title: "Load more orders", onClick: () => loadMore(), disabled: isLoading }) }))] }));
};
export default OrderHistory;
