import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    description: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_lg) }),
    itemContainer: {
        marginBottom: spacing.large,
    },
}));
export default useStyles;
