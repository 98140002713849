import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './PccStepSelect.styles';
import clsx from 'clsx';
import Link from '../Link/Link';
import { Box } from '@mui/material';
const PccStepSelect = (props) => {
    const { label, linkText, selectedValue, handleClick } = props;
    const { classes } = useStyles();
    return (_jsx("div", { className: clsx(classes.pccStepContainer, selectedValue ? classes.greenBorder : ''), onClick: handleClick, children: _jsxs("button", { className: classes.pccStepSelectButton, type: "button", children: [_jsx("h3", { className: clsx(classes.label, !!selectedValue ? classes.greenTextLabel : ''), children: label }), !!selectedValue ? (_jsx(Box, { component: 'h3', className: clsx(classes.selectedValueText, label.includes('Occasion') ? classes.occasionSelectedValue : ''), children: selectedValue })) : !!linkText ? (_jsx(Link, { label: linkText, className: classes.linkText })) : null] }) }));
};
export default PccStepSelect;
