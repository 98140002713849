import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '790px',
        margin: '0 auto',
    },
    deliveryPassHeading: {
        marginBottom: '50px',
    },
    errorMessage: {
        marginBottom: spacing.xs,
    },
}));
export default useStyles;
