import { makeStyles } from 'tss-react/mui';
import { default as _omit } from 'lodash/omit';
import { inputStyles } from '../../styles/jss/inputs';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => (Object.assign(Object.assign({ hasPopupIcon: {
        display: 'none',
    }, endAdornment: {
        top: '-10px',
    }, root: {
        width: '100%',
    }, popper: {
        display: 'none',
    }, popperOpen: {
        display: 'block',
        zIndex: 5,
    }, outsideDOM: {
        '& .MuiAutocomplete-listbox': {
            maxHeight: _params.height || '40vh',
        },
    }, paper: {
        left: '-9px',
        position: 'relative',
        width: 'calc(100% + 18px)',
        border: `1px solid ${colors.supplementary.black}`,
        boxShadow: '0px 0px 8px #008BE9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        '& .MuiAutocomplete-noOptions': {
            display: 'none',
        },
        '& .MuiAutocomplete-loading': {
            display: 'none',
        },
    }, option: Object.assign(Object.assign({ cursor: 'pointer' }, theme.typography.small_regular), { borderBottom: `1px solid ${colors.grey.grey2}`, '&:last-child': {
            borderBottom: 'none',
        }, padding: `0px ${spacing.xs}`, '&:hover': {
            backgroundColor: colors.grey.grey1,
        } }), manualAddress: {
        padding: `${spacing.small} ${spacing.xs}`,
        zIndex: 1,
    }, resultsShown: {
        borderTop: `1px solid ${colors.supplementary.black}`,
    }, manualEntryButton: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            color: theme.isFlyingFlowers ? colors.wildExotic[500] : undefined,
        },
    }, textContainer: {
        [`& .${classes.manualAddress}`]: {
            padding: `${spacing.small} ${spacing.xs} ${spacing.xs} ${spacing.xs}`,
            margin: '0 -8px 0 -8px',
        },
        '& .MuiAutocomplete-input': {
            margin: 0,
            padding: '0!important',
        },
        '& .MuiCircularProgress-root': {
            position: 'relative',
            top: '-10px',
            left: '-10px',
        },
    } }, _omit(inputStyles(theme), 'textContainer')), { recipientAddressLabel: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.black[700] }) })));
export default useStyles;
