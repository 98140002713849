import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createRef, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import useStyles from './RemindersAdvancedListing.styles';
import clsx from 'clsx';
import { monthsList } from '../../common/data/months.data';
import ReminderCardGallery from '../ReminderCardGallery/ReminderCardGallery';
import Button from '../Button/Button';
import { SiteContext } from '../../utils/common';
import _get from 'lodash/get';
import { useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
const RemindersAdvancedListing = (props) => {
    const { classes } = useStyles();
    const { availableMonths, reminders, setPage, page, monthCount, isLastPage, setMonth, setLoadAll, currentActiveMonth, setHasScrolled, hasScrolled, } = props;
    const { editReminder, deleteReminder, setReminderStatusInfo, reminders: remindersList } = reminders;
    const { isFlyingFlowers } = useContext(SiteContext);
    const refArray = monthsList.map(() => createRef());
    const ulRef = useRef(null);
    const theme = useTheme();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm})` });
    const totalLeftToView = !!monthCount && !!(remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) ? monthCount - remindersList.length : 9;
    const loadNextTotal = totalLeftToView > 9 ? 9 : totalLeftToView;
    const currentMonth = useRef();
    const filterMonths = useCallback((month) => {
        return availableMonths.find((availableMonth) => availableMonth === month);
    }, [availableMonths]);
    const disableMonths = useMemo(() => {
        return monthsList.map((month) => ({
            month: month.month,
            monthNumber: month.monthNumber,
            isDisabled: !filterMonths(month.monthNumber),
        }));
    }, [filterMonths]);
    useEffect(() => {
        if (currentActiveMonth) {
            const activeIndex = monthsList.findIndex((item) => item.monthNumber === currentActiveMonth) || 0;
            const currentRef = _get(refArray, `${activeIndex}.current`);
            const containerRef = ulRef.current;
            if (currentRef && containerRef && !hasScrolled && isMobile) {
                containerRef.scrollTo({ top: 0, left: currentRef.offsetLeft - 40 });
                setHasScrolled(true);
                currentMonth.current = currentActiveMonth;
            }
        }
    }, [currentActiveMonth, hasScrolled, isMobile, refArray, setHasScrolled]);
    useEffect(() => {
        if (!!(currentMonth === null || currentMonth === void 0 ? void 0 : currentMonth.current) && currentMonth.current !== currentActiveMonth) {
            setHasScrolled(false);
        }
    }, [currentActiveMonth, setHasScrolled]);
    return (_jsxs(_Fragment, { children: [!!disableMonths.length && !!(remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) && (_jsx("ul", { className: classes.monthsList, ref: ulRef, children: disableMonths.map((month, index) => (_jsx("li", { ref: refArray ? refArray[index] : null, className: clsx((month === null || month === void 0 ? void 0 : month.isDisabled) && classes.disableMonth, month.monthNumber === currentActiveMonth && classes.activeMonth), children: _jsx("button", { onClick: () => {
                            setPage(1);
                            setHasScrolled(false);
                            !month.isDisabled && setMonth && setMonth(month.monthNumber);
                        }, className: classes.monthCursor, children: month.month }) }, month.month))) })), !!(remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) && (_jsx(ReminderCardGallery, { deleteReminder: deleteReminder, setReminderStatusInfo: setReminderStatusInfo, reminders: remindersList, editReminder: editReminder, setHasScrolled: setHasScrolled })), !isLastPage && !!(remindersList === null || remindersList === void 0 ? void 0 : remindersList.length) && monthCount && (_jsxs("div", { className: classes.additional, children: [_jsxs("p", { className: classes.resultsViewed, children: [`Viewed ${remindersList.length} of ${monthCount}`, " reminders"] }), _jsxs("div", { className: classes.actionButtons, children: [_jsx(Button, { variant: "tertiary", thin: true, title: `Load ${loadNextTotal} more`, onClick: () => setPage(page + 1), icon: isFlyingFlowers ? 'refresh' : undefined }), _jsx(Button, { title: `Load all ${monthCount} `, variant: "tertiary", thin: true, icon: isFlyingFlowers ? 'refresh' : undefined, onClick: () => {
                                    setPage(page + 1);
                                    setLoadAll && setLoadAll(true);
                                } })] })] }))] }));
};
export default RemindersAdvancedListing;
