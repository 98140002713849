import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { processImageUrl } from '../../utils/image';
import Image from '../Image/Image';
import ProductBadge from '../ProductBadge/ProductBadge';
import useStyles from './ProductImage.styles';
import SalesBadge from '../SalesBadge/SalesBadge';
const IMAGE_PRODUCT_PLACEHOLDER = 'https://cdn.media.amplience.net/i/interflora/not-available';
export function filterImageWidths(imageWidths, maxWidth) {
    const index = imageWidths.findIndex((width) => width >= maxWidth);
    const filteredImageWidths = index !== -1 ? imageWidths.slice(0, 1 + index) : [...imageWidths, maxWidth];
    return filteredImageWidths;
}
const ProductImage = (props) => {
    const { classes } = useStyles();
    const { imageUrl, altText, label, badge, badgeSmall, classNames, style, maxWidth = 480, sizes, imageWidths = [240, 384, 480, 960, 1280, 1600], salesBadge, isFromProductCard, onClick, isFromSpotlightNavigation, layout, } = props;
    const processedUrl = processImageUrl(imageUrl || IMAGE_PRODUCT_PLACEHOLDER, { w: maxWidth, img404: 'not-available' }, ['$poi-square$']);
    // Get all the image sizes less than the max height except 1
    const filteredImageWidths = filterImageWidths(imageWidths, maxWidth);
    return (_jsxs("div", { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.container, classes.container), style: style, children: [_jsxs("div", { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.imageContainer, classes.imageContainer), "data-testid": isFromSpotlightNavigation ? processedUrl : undefined, children: [_jsx(Image, { imageUrl: processedUrl, sizes: sizes, 
                        // only include image widths if there is more than 1
                        imageWidths: filteredImageWidths.length > 1 ? filteredImageWidths : undefined, altText: altText || '', className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.image, classes.image), draggable: false, width: maxWidth, height: maxWidth, onClick: onClick, loading: "lazy" }), badge && (_jsx("div", { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.badge, classes.badge), children: _jsx(ProductBadge, Object.assign({}, badge, { small: badgeSmall })) })), !!salesBadge && (_jsx("div", { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.badge, classes.badge), children: _jsx(SalesBadge, Object.assign({}, salesBadge, { isFromProductCard: isFromProductCard })) }))] }), label && (_jsx("div", { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.label, classes.label), children: _jsx("span", { children: label }) }))] }));
};
export default ProductImage;
