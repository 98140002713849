import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SiteContext } from '../../utils/common';
import Button from '../Button/Button';
import Link from '../Link/Link';
import Modal from '../Modal/Modal';
import useStyles from './AddressCard.styles';
import AddressRemoveItems from '../AddressRemoveItems/AddressRemoveItems';
import { useTheme, CircularProgress } from '@mui/material';
const AddressCard = (props) => {
    const { address, removeAddressItem, setErrorMessage } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const theme = useTheme();
    const { id, firstName, lastName, addressLine1, addressLine2, addressLine3, postalCode, city, county, isDefaultAddress, } = address;
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [removing, setRemoving] = useState(false);
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const handleRemoveAddress = () => {
        setRemoving(true);
        id &&
            removeAddressItem(id)
                .then(() => setErrorMessage && setErrorMessage(''))
                .catch((error) => setErrorMessage && setErrorMessage(error.message))
                .finally(() => setRemoving(false));
    };
    return (_jsx(_Fragment, { children: !!firstName && !!addressLine1 && (_jsxs("div", { className: classes.addressCardContainer, children: [_jsxs("div", { className: classes.addressContainer, children: [_jsxs("h3", { className: classes.addressNameTitle, children: [firstName, "\u00A0", lastName] }), _jsxs("div", { className: classes.address, children: [addressLine1 && _jsxs("span", { children: [addressLine1, ", "] }), addressLine2 && _jsxs("span", { children: [addressLine2, ", "] }), city && _jsxs("span", { children: [city, ", "] }), county && _jsxs("span", { children: [county, ", "] }), postalCode && _jsx("span", { children: postalCode })] }), isDefaultAddress && _jsx("p", { className: classes.defaultAddressLabel, children: "Default delivery address" })] }), _jsx(Modal, { open: isOpen, onClose: () => setIsOpen(false), keepMounted: true, fullScreen: !isLargerViewport, setOpen: () => setIsOpen(true), maxWidth: "sm", fullWidth: true, children: _jsx(AddressRemoveItems, { address: address, handleRemoveItem: () => {
                            handleRemoveAddress();
                            setIsOpen(false);
                        }, handleCancel: () => {
                            setIsOpen(false);
                        } }) }), _jsxs("div", { className: classes.cardActions, children: [removing ? (_jsx(CircularProgress, { className: classes.spinner, size: 24 })) : (_jsx(Link, { className: classes.deleteLink,
                            label: 'Delete',
                            onClick: () => setIsOpen(true),
                            highlight: false,
                            noFollow: false,
                            newWindow: true })), _jsx(Button, { href: `/account/address-book/edit-contact?id=${id}`, title: "Edit", variant: "tertiaryDark", thin: true, icon: isFlyingFlowers ? 'mode_edit' : undefined })] })] })) }));
};
export default AddressCard;
