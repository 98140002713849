import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    filterFacet: {
        marginBottom: '2px',
        '& ul': {
            width: '100%',
        },
        '& li': Object.assign(Object.assign({}, theme.typography.small_regular), { alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBottom: spacing.xxs }),
        '& .MuiIconButton-label': {
            color: theme.isFlyingFlowers ? colors.black[700] : undefined,
        },
    },
    filterHeadings: Object.assign(Object.assign({ marginBottom: spacing.xxs }, theme.typography.medium_bold), { [theme.breakpoints.up('md')]: Object.assign({ marginBottom: 0 }, theme.typography.large_bold) }),
    total: {
        paddingRight: spacing.xs,
    },
    accordianRoot: {
        boxShadow: 'none',
        '& .MuiAccordionSummary-content': {
            margin: `0`,
        },
        '& .Mui-expanded': {
            minHeight: 'auto',
            margin: '0 !important',
        },
    },
    summaryRoot: {
        '& > *': {
            cursor: 'initial',
            pointerEvents: 'none',
        },
        padding: '0 6px 0 0',
        [theme.breakpoints.up('md')]: {
            '& > *': {
                cursor: 'pointer',
                pointerEvents: 'auto',
            },
        },
    },
    detailsRoot: {
        padding: 0,
    },
    expandMore: {
        '& .MuiIconButton-label': {
            color: colors.supplementary.black,
        },
    },
}));
export default useStyles;
