import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    footerSection: {},
    message: {},
    gallery: {
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    title: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    linkContainer: {
        height: '100%',
        position: 'relative',
        display: 'block',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    link: {
        width: '100%',
        display: 'flex',
        '& .MuiButtonBase-root': {
            border: 'none',
            minHeight: spacing.xl,
            height: 'auto',
            color: theme.isFlyingFlowers ? colors.supplementary.white : colors.grey.grey7,
            boxShadow: theme.isFlyingFlowers ? 'none' : undefined,
            borderRadius: theme.isFlyingFlowers ? spacing.xs : undefined,
            padding: `12px !important`,
            '&:hover': {
                textDecoration: theme.isFlyingFlowers ? 'underline' : undefined,
            },
        },
        '& .MuiTouchRipple-root': {
            color: theme.isFlyingFlowers ? colors.black[200] : undefined,
        },
        '& .Mui-focusVisible': {
            backgroundColor: theme.isFlyingFlowers ? colors.black[200] : undefined,
            boxShadow: theme.isFlyingFlowers ? '0px 0px 0px 2px #008BE9' : undefined,
        },
        '& button': {
            flexGrow: 1,
            borderRadius: theme.isFlyingFlowers ? '8px' : undefined,
            color: colors.supplementary.white,
            textDecoration: 'underline',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            height: 'auto',
            '& span': Object.assign(Object.assign({}, theme.typography.small_regular), { padding: '0 !important' }),
        },
    },
    grid: {
        display: 'grid',
        justifyContent: 'center',
        gap: `${spacing.small}`,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
        },
        [`& .${classes.linkContainer}`]: {
            flex: '100%',
            width: '335px',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '335px',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '304px',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '389px',
            },
            [theme.breakpoints.between(1616.9, 1671.9)]: {
                maxWidth: '419px',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '520px',
            },
            [theme.breakpoints.down(350)]: {
                width: '100%',
            },
        },
        [`& .${classes.singleLinkContainer}`]: {
            maxWidth: '335px',
            [theme.breakpoints.up('md')]: {
                flex: 'calc(100% / 4)',
                maxWidth: '468px !important',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '595.5px !important',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '792px !important',
            },
            [`& .${classes.link}`]: {
                maxWidth: '100%',
            },
        },
    },
    singleLinkContainer: {},
    description: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    removeMarginBottom: {
        marginBottom: 0,
    },
}));
export default useStyles;
