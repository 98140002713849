import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_WIDTH_INNER = 520;
const MAX_WIDTH_OUTER = 792;
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        maxWidth: MAX_WIDTH_OUTER,
        margin: `0 auto`,
        '& h1': Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : undefined }, (theme.isFlyingFlowers && theme.typography.xl_regular)), (theme.isFlyingFlowers && theme.typographySpacing.xl_regular_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers && theme.typography.xxxxl)), (theme.isFlyingFlowers && theme.typographySpacing.xxxxl_lg)) }),
        '& h2': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers && theme.typography.large_bold)), (theme.isFlyingFlowers && theme.typographySpacing.none)) }),
        '& h4': Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg),
    },
    box: {
        border: `1px solid ${colors.supplementary.black}`,
        padding: spacing.small,
        '& > div, & > form': { maxWidth: MAX_WIDTH_INNER, margin: `0 auto` },
        '&:last-child': { marginTop: spacing.small },
        '& .MuiFormControlLabel-label': {
            marginLeft: theme.isFlyingFlowers ? '12px' : undefined,
            paddingTop: theme.isFlyingFlowers ? spacing.xxs : undefined,
        },
    },
    open: {
        paddingBottom: spacing.large,
    },
    caption: {
        'p&': Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { marginBottom: spacing.large, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
    },
    paragraph: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), theme.typographySpacing.none),
    list: {
        listStyle: 'disc inside',
        padding: '4px',
        '& li': Object.assign({ display: 'list-item' }, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)),
        [`.${classes.open} &`]: { marginBottom: spacing.medium },
    },
    register: { marginTop: spacing.small },
    input: { marginBottom: spacing.small },
    alert: { marginBottom: spacing.large },
    buttons: {},
    alertInline: {
        marginBottom: spacing.large,
    },
}));
export default useStyles;
