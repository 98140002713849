import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import RawHtml from '../RawHtml/RawHtml';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './Help.styles';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material';
import { useLoaded } from '../MainContent/useComponentLoading';
const Help = (props) => {
    const { title, body, email, isCorporateEmail = false } = props;
    const { headingTag = 'H2' } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const HeadingTag = headingTag.toLowerCase();
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx(HeadingTag, { className: classes.title, children: title }), _jsx(RawHtml, { className: classes.body, content: body }), _jsxs("div", { className: classes.content, children: [_jsx(RoundButton, { icon: "email", ariaLabel: "Email", label: "Email", small: isMobile !== null && isMobile !== void 0 ? isMobile : false, onClick: () => window.open(`mailto:${email}`), classNames: { container: classes.roundButton, label: classes.roundButtonLabel } }), !isCorporateEmail && (_jsxs("div", { className: "idz_btn_fix", children: [_jsx(RoundButton, { id: "contactidzfonline", icon: "textsms", ariaLabel: "Live chat", label: "Live chat", small: isMobile !== null && isMobile !== void 0 ? isMobile : false, style: { display: 'none' }, onClick: () => { }, className: "contactidzfonline", classNames: { container: classes.roundButton, label: classes.roundButtonLabel } }), _jsx(RoundButton, { id: "contactidzfoffline", icon: "textsms", ariaLabel: "Live chat unavailable", label: "Live chat unavailable", style: { display: 'none' }, onClick: () => { }, small: isMobile !== null && isMobile !== void 0 ? isMobile : false, className: "contactidzfoffline", classNames: { container: classes.roundButton, label: classes.roundButtonLabel }, disabled: true }), _jsx(RoundButton, { id: "contactidzfbusy", icon: "textsms", ariaLabel: "Live chat unavailable", label: "Live chat unavailable", style: { display: 'none' }, small: isMobile !== null && isMobile !== void 0 ? isMobile : false, onClick: () => { }, className: "contactidzfbusy", classNames: { container: classes.roundButton, label: classes.roundButtonLabel }, disabled: true })] }))] })] }));
};
export default Help;
