import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    modal: {
        [theme.breakpoints.down('xl')]: {
            '& .MuiDialog-paperFullScreen': { height: '100%' },
            '& .MuiDialogContent-root': {
                overflowY: 'hidden',
                '@media(orientation: landscape)': {
                    overflowY: 'initial !important',
                },
                height: '100%',
            },
            '& form': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
        },
        '& .MuiPaper-root': {
            paddingBottom: spacing.xs,
            '& header': {
                alignItems: 'center',
                margin: `${spacing.small} ${spacing.small} ${spacing.medium} 20px`,
                padding: 0,
                [theme.breakpoints.up('sm')]: {
                    margin: `${spacing.medium} ${spacing.medium} ${theme.isFlyingFlowers ? '72px' : '74px'} 40px`,
                    alignItems: theme.isFlyingFlowers ? 'flex-start' : 'flex-end',
                },
                [theme.breakpoints.up('md')]: {
                    margin: `${spacing.large} ${spacing.medium}  ${theme.isFlyingFlowers ? '82px' : '72px'} ${spacing.small}`,
                },
                '& h3': {
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        paddingTop: spacing.xs,
                    },
                },
            },
            [theme.breakpoints.up('md')]: {
                padding: 0,
                paddingBottom: spacing.large,
                width: '100%',
                maxWidth: '1336px',
                maxHeight: '90vh',
                left: 0,
            },
        },
        '& .MuiAutocomplete-listbox': {
            maxHeight: '25vh',
            [theme.breakpoints.up('md')]: {
                maxHeight: '18vh',
            },
        },
    },
    modalHeading: Object.assign(Object.assign({ color: colors.grey.grey6 }, theme.typography.medium_bold), { fontWeight: fontWeights.regular, display: 'flex', '& span': {
            margin: 0,
            '&:first-child': {
                marginRight: spacing.xs,
            },
        }, [theme.breakpoints.up('md')]: {
            display: 'none',
        } }),
    basicButton: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? link() : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }),
    modalTitle: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '0', marginLeft: '0', [theme.breakpoints.up('md')]: Object.assign(Object.assign({ marginLeft: '66px', display: 'flex', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, theme.typography.large_regular), { justifyContent: 'left', marginBottom: 0 }), [theme.breakpoints.up('lg')]: Object.assign(Object.assign({ marginLeft: '120px', display: 'flex', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, theme.typography.large_regular), { justifyContent: 'left', marginBottom: 0 }) }),
    customizeTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    internationalModal: {
        '& .MuiPaper-root': {
            height: '100%',
            paddingBottom: spacing.xs,
            '& header': {
                alignItems: 'center',
                margin: `${spacing.small} ${spacing.small} ${spacing.medium} 20px`,
                padding: 0,
                [theme.breakpoints.up('sm')]: {
                    margin: `${spacing.medium} ${spacing.medium} ${spacing.large} 40px !important`,
                    alignItems: theme.isFlyingFlowers ? 'flex-start' : 'flex-end',
                },
                [theme.breakpoints.up('md')]: {
                    margin: `${spacing.medium} ${spacing.medium} 0 ${spacing.small} !important`,
                },
                '& h3': {
                    padding: 0,
                },
            },
            [theme.breakpoints.up('md')]: {
                padding: 0,
                paddingBottom: spacing.large,
                width: '100%',
                maxWidth: '1336px',
                maxHeight: '90vh',
                left: 0,
                height: '402px',
            },
        },
    },
}));
export default useStyles;
