import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    imageContainer: {
        marginRight: spacing.small,
        width: '72px',
    },
    content: { flex: 1 },
    name: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.medium_bold_lg), { color: colors.grey.grey6 }),
    price: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.small_bold_sm) }),
    quantity: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: colors.grey.grey6 }),
}));
export default useStyles;
