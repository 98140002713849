import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import useStyles from './AlphabetFilter.styles';
import clsx from 'clsx';
import SortedLinksGallery from '../SortedLinksGallery/SortedLinksGallery';
import { ALPHABET as alphabet } from '../../common/data/alphabet.data';
import { useLoaded } from '../MainContent/useComponentLoading';
const filterLinksByLetter = (links, letter) => {
    if (letter.toUpperCase() === 'XYZ') {
        return links === null || links === void 0 ? void 0 : links.filter((link) => ['x', 'y', 'z'].includes(link.label.charAt(0).toLowerCase()));
    }
    else {
        return links === null || links === void 0 ? void 0 : links.filter((link) => link.label.charAt(0).toLowerCase() === letter.toLowerCase());
    }
};
const AlphabetFilter = (props) => {
    var _a;
    const { classes } = useStyles();
    const { title, links } = props;
    const disableAlphabet = useMemo(() => {
        return alphabet.map((letter) => ({
            letter,
            isDisabled: filterLinksByLetter(!!(links === null || links === void 0 ? void 0 : links.length) ? links : [], letter).length === 0,
        }));
    }, [links]);
    const [filterByLetter, setFilterByLetter] = useState(((_a = disableAlphabet.find((letterState) => !letterState.isDisabled)) === null || _a === void 0 ? void 0 : _a.letter) || '');
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h2", { className: classes.title, children: title }), !!disableAlphabet.length && (_jsx("ul", { className: classes.alphabetList, children: disableAlphabet.map((letter) => (_jsx("li", { className: clsx((letter === null || letter === void 0 ? void 0 : letter.isDisabled) && classes.disableAlphabet, (letter === null || letter === void 0 ? void 0 : letter.letter) === filterByLetter && classes.activeAlphabet), children: _jsx("button", { onClick: () => !letter.isDisabled && setFilterByLetter(letter.letter), className: classes.alphabetButton, children: letter.letter }) }, letter.letter))) })), disableAlphabet.map((letter) => {
                const filteredLinks = !!(links === null || links === void 0 ? void 0 : links.length) ? filterLinksByLetter(links, letter.letter) : [];
                let activeStatus = letter.letter === filterByLetter ? '' : classes.isNotInView;
                return (!!(filteredLinks === null || filteredLinks === void 0 ? void 0 : filteredLinks.length) && (_jsx(SortedLinksGallery, { links: filteredLinks, className: activeStatus }, letter.letter)));
            })] }));
};
export default AlphabetFilter;
