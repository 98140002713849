import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import RelatedContentItem from '../RelatedContentItem/RelatedContentItem';
import GenericGalleryCarousel from '../GenericGalleryCarousel/GenericGalleryCarousel';
import GenericGalleryGrid from '../GenericGalleryGrid/GenericGalleryGrid';
import useStyles from './RelatedContentGallery.styles';
import { SiteContext } from '../../utils/common';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useLoaded } from '../MainContent/useComponentLoading';
import { Box } from '@mui/material';
const RelatedContentGallery = (props) => {
    var _a;
    const { heading, contentItems, galleryDisplayType, headingTag, sendRelatedContentGalleryAnalytics } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const HeadingTag = (!!headingTag ? headingTag.toLowerCase() : 'h3');
    const router = useRouter();
    const isPdpPage = (_a = router === null || router === void 0 ? void 0 : router.asPath) === null || _a === void 0 ? void 0 : _a.includes('/product'); //checks whether url contains /product
    useLoaded(props);
    const componentProps = { sendRelatedContentGalleryAnalytics: sendRelatedContentGalleryAnalytics };
    return (_jsxs(_Fragment, { children: [heading && (_jsx(HeadingTag, { className: clsx(classes.heading, isPdpPage ? classes.productPageHeading : ''), children: heading })), _jsx(Box, { sx: { display: { xs: 'block', md: 'none' } }, children: isFlyingFlowers && galleryDisplayType === 'CAROUSEL' && (_jsx("h3", { className: classes.subheading, children: "Swipe to see more" })) }), galleryDisplayType === 'GRID'
                ? (contentItems === null || contentItems === void 0 ? void 0 : contentItems.length) && (_jsx(GenericGalleryGrid, { ItemComponent: RelatedContentItem, items: contentItems, itemsPerLoadMore: 4, initialVisibleItems: 4, componentProps: componentProps }))
                : (contentItems === null || contentItems === void 0 ? void 0 : contentItems.length) && (_jsx(GenericGalleryCarousel, { ItemComponent: RelatedContentItem, items: contentItems, isFromRelatedContentGallery: true, componentProps: componentProps }))] }));
};
export default RelatedContentGallery;
