import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../../styles/jss/variables';
import { productCardStyle } from './SearchProductCard.styles';
const useStyles = makeStyles()((theme) => (Object.assign({ productContentClasses: {
        padding: theme.isFlyingFlowers ? `12px ${spacing.xs}` : `${spacing.xs}`,
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers ? `12px ${spacing.xs}` : '12px',
        },
        '& h4': Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? `${colors.black[700]} !important` : colors.grey.grey6 }, (theme.isFlyingFlowers ? theme.typography.small_regular : theme.typography.xxs_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.xxs_regular_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.small_regular_sm)), { marginBottom: `${spacing.small} !important` }) }),
    }, productCards: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        placeContent: 'center',
        gap: theme.isFlyingFlowers ? '14px' : '15px',
        [theme.breakpoints.up('sm')]: {
            gap: theme.isFlyingFlowers ? '20px' : spacing.medium,
        },
        [theme.breakpoints.up('md')]: {
            placeContent: 'flex-end',
            flexWrap: 'initial',
        },
    }, productsContainer: {
        justifyContent: 'center',
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        },
    } }, productCardStyle(theme))));
export default useStyles;
