var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { PaymentMethodTypes, klarnaTypes, klarnaPaymentOptions, KLARNA_PAY_SLICED, KLARNA_PAY_LATER, KLARNA_PAY_NOW, } from '../../../common/props';
import Icon from '../../Icon/Icon';
import Link from '../../Link/Link';
import useStyles from '../Checkout.styles';
import { SiteContext, paymentTypes } from '../../../utils/common';
import CheckCircle from '../../CheckCircle/CheckCircle';
import Image from '../../Image/Image';
import { CircularProgress, useTheme, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import Alert from '../../Alert/Alert';
import _get from 'lodash/get';
import KlarnaPaymentOptions, { FORMATTED_CART_PRICE_CHECK } from './KlarnaPaymentOptions';
import NextLink from 'next/link';
import { usePaymentIconsCallback } from '../../../utils/utilityCustomHooks';
import * as Sentry from '@sentry/nextjs';
export const CHECKOUT_PAYMENT_TYPES_AMP_KEY = 'component/checkout-payment-types';
export const validationSchema = Yup.object().shape({});
const title = '3. Payment';
// Worldpay Hosted SDK Manual - https://developer.worldpay.com/docs/wpg/hostedintegration/javascriptsdk/
// Uses helper file from - https://payments.worldpay.com/resources/hpp/integrations/embedded/doc/
const WORLDPAY_URL = 'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js';
const phoneNumberStarts = ['01', '02', '07'];
const WORLDPAY_CONFIG = {
    type: 'iframe',
    inject: 'immediate',
    target: 'wordpay-target',
};
const CheckoutPayment = ({ className, step, currentStep, isSubmitting, completeOrder, logOrder, logOrderPaypalAndKlarna, MockWorldpayComponent, cutOffPassedAlert, errorAlert, handleChangePaymethod, isPaymentLoading, redirectPaymentError, saveCheckoutUILogs, isPaymentInProgress, setIsPaymentProgress, setKlarnaPayError, paymentSelection, }) => {
    var _a, _b, _c, _d;
    const router = useRouter();
    const { classes } = useStyles();
    const { billingAddress, worldpayRedirectUrl, paymentMethod, unavailableItems, totalPrice, id } = useFormikContext().values;
    const { preloadedComponents, isKlarnaEnabled, getCustomer } = useContext(SiteContext);
    const isCurrent = currentStep === step;
    const isCompleted = currentStep > step;
    const [klarnaSliceRemoved, setKlarnaSliceRemoved] = useState(false);
    const initialPaymentMethod = !klarnaSliceRemoved && paymentMethod ? paymentMethod : '';
    const [paymentMethodType, setPaymentMethodType] = useState(initialPaymentMethod);
    const worldpayRedirectUrlRef = useRef('');
    const [isCardLoaded, setIsCardLoaded] = useState(false);
    const [isKlarnaPayLoader, setIsKlarnaPayLoader] = useState(false);
    const theme = useTheme();
    const [isMobile, setIsMobile] = useState(false);
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const [paypalError, setPaypalError] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const previousPaymentMethod = useRef('');
    const { imageDiementiosnCallback } = usePaymentIconsCallback();
    const ampliencePaymentTypes = (_a = _get(preloadedComponents, CHECKOUT_PAYMENT_TYPES_AMP_KEY, {})) === null || _a === void 0 ? void 0 : _a.data;
    /** sorting payment methods */
    const sortByAmplienceTypes = (a, b) => {
        var _a, _b;
        const aIndex = (_a = ampliencePaymentTypes === null || ampliencePaymentTypes === void 0 ? void 0 : ampliencePaymentTypes.paymentTypes) === null || _a === void 0 ? void 0 : _a.findIndex((item) => item.type === a.type);
        const bIndex = (_b = ampliencePaymentTypes === null || ampliencePaymentTypes === void 0 ? void 0 : ampliencePaymentTypes.paymentTypes) === null || _b === void 0 ? void 0 : _b.findIndex((item) => item.type === b.type);
        return aIndex - bIndex;
    };
    const paymentTypesMatchedWithAmplience = paymentTypes.filter((paymentType) => {
        var _a;
        return (_a = ampliencePaymentTypes === null || ampliencePaymentTypes === void 0 ? void 0 : ampliencePaymentTypes.paymentTypes) === null || _a === void 0 ? void 0 : _a.some((ampPaymentTypes) => {
            return ampPaymentTypes.type === paymentType.type;
        });
    });
    let sortedPaymentTypes = (paymentTypesMatchedWithAmplience === null || paymentTypesMatchedWithAmplience === void 0 ? void 0 : paymentTypesMatchedWithAmplience.length)
        ? paymentTypesMatchedWithAmplience.slice().sort(sortByAmplienceTypes)
        : paymentTypes;
    if (!isKlarnaEnabled ||
        billingAddress.country !== 'GB' ||
        !phoneNumberStarts.includes((_c = (_b = billingAddress.phone) === null || _b === void 0 ? void 0 : _b.slice(0, 2)) !== null && _c !== void 0 ? _c : '')) {
        sortedPaymentTypes = sortedPaymentTypes.filter((paymentMethod) => !paymentMethod.type.startsWith('klarna'));
    }
    const klarnaPaymentTypes = [];
    /** creating main accordian payments and klarna Payment accordians list */
    const cartTotalPrice = parseFloat(totalPrice.formatted.slice(1));
    const klarnaCount = sortedPaymentTypes.filter((str) => str.type.startsWith('klarna')).length;
    const klarnaSliced = !!sortedPaymentTypes.find((payment) => payment.type === PaymentMethodTypes.KLARNA_PAY_SLICED);
    let isKlarnaSliceRemoved = false;
    const accordinPaymentTypes = sortedPaymentTypes.reduce((payments, current) => {
        const hideKlarnaAccordian = cartTotalPrice < FORMATTED_CART_PRICE_CHECK && klarnaCount === 1 && klarnaSliced;
        if (current.type.startsWith('klarna') && !hideKlarnaAccordian) {
            if (!payments.some((payment) => payment.type === PaymentMethodTypes.KLARNA)) {
                payments.push(Object.assign(Object.assign({}, current), { type: PaymentMethodTypes.KLARNA }));
            }
            if (current.type === PaymentMethodTypes.KLARNA_PAY_SLICED) {
                if (cartTotalPrice > FORMATTED_CART_PRICE_CHECK) {
                    klarnaPaymentTypes.push(current);
                    if (klarnaSliceRemoved) {
                        setKlarnaSliceRemoved(false);
                    }
                }
                else {
                    isKlarnaSliceRemoved = true;
                }
            }
            else {
                klarnaPaymentTypes.push(current);
            }
        }
        else if (!current.type.startsWith('klarna')) {
            payments.push(current);
        }
        return payments;
    }, []);
    useEffect(() => {
        setIsMobile(isMobileDevice);
    }, [isMobileDevice]);
    const checkingKlarnaRemoved = useMemo(() => {
        if (isKlarnaSliceRemoved)
            return true;
    }, [isKlarnaSliceRemoved]);
    useEffect(() => {
        if (checkingKlarnaRemoved)
            setKlarnaSliceRemoved(true);
    }, [checkingKlarnaRemoved]);
    const resultCallback = useCallback((responseData) => {
        var _a;
        if (logOrder)
            logOrder(responseData);
        switch (responseData.order.status) {
            case 'success':
            case 'pending':
                paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, true);
                completeOrder();
                break;
            case 'cancelled_by_shopper':
                router.push('/checkout/billing');
                break;
            case 'already_processed':
            case 'failure':
            case 'error':
            case 'exception':
            case 'session_expired':
            default:
                Sentry.captureException(`Payment ${responseData.order.status} issue occured with method type ${paymentMethodType} with worldpayRedirectUrl - ${worldpayRedirectUrl}`);
                paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, false);
                saveCheckoutUILogs === null || saveCheckoutUILogs === void 0 ? void 0 : saveCheckoutUILogs(true, paymentMethodType, true, (_a = responseData === null || responseData === void 0 ? void 0 : responseData.order) === null || _a === void 0 ? void 0 : _a.status);
                console.log('Unhandled response', responseData);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completeOrder, logOrder]);
    const triggerHotJarEvents = (paymentMethodType, worldpayRedirectUrl) => {
        var _a, _b;
        if (window === null || window === void 0 ? void 0 : window.hj) {
            // hotjar logs
            window === null || window === void 0 ? void 0 : window.hj('event', 'worldpay_url');
            window === null || window === void 0 ? void 0 : window.hj('identify', (_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id, {
                paymentMethod: paymentMethodType,
                worldpayRedirectUrlExists: worldpayRedirectUrl !== '',
                cart_id: id,
                worldpayRedirectUrl,
            });
        }
    };
    useEffect(() => {
        if (isCurrent &&
            !isSubmitting &&
            worldpayRedirectUrl &&
            worldpayRedirectUrlRef.current !== worldpayRedirectUrl &&
            paymentMethod &&
            paymentMethodType === PaymentMethodTypes.CARD &&
            !isPaymentLoading) {
            triggerHotJarEvents(paymentMethodType, worldpayRedirectUrl);
            const loadWorldPayScript = () => {
                // @ts-ignore
                const wpcl = new WPCL.Library();
                wpcl.setup(Object.assign(Object.assign({}, WORLDPAY_CONFIG), { iframeHelperURL: `${window.location.origin}/worldpay/helper.html`, url: worldpayRedirectUrl, resultCallback, flowCallback: () => {
                        setShowIframe(true);
                        saveCheckoutUILogs === null || saveCheckoutUILogs === void 0 ? void 0 : saveCheckoutUILogs(true, paymentMethod);
                    } }));
            };
            const script = document.createElement('script');
            script.async = true;
            script.src = WORLDPAY_URL;
            script.addEventListener('load', loadWorldPayScript);
            worldpayRedirectUrlRef.current = worldpayRedirectUrl;
            document.head.appendChild(script);
            setIsCardLoaded(true);
            return () => {
                document.head.removeChild(script);
            };
        }
        else if (isCurrent &&
            !isSubmitting &&
            worldpayRedirectUrl &&
            (paymentMethodType === PaymentMethodTypes.PAYPAL || paymentMethodType.includes('klarna'))) {
            triggerHotJarEvents(paymentMethodType, worldpayRedirectUrl);
            worldpayRedirectUrlRef.current = worldpayRedirectUrl;
            paymentMethod && (saveCheckoutUILogs === null || saveCheckoutUILogs === void 0 ? void 0 : saveCheckoutUILogs(true, paymentMethod));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worldpayRedirectUrl, isCurrent, isSubmitting, resultCallback, paymentMethodType, isPaymentLoading]);
    useEffect(() => {
        const handlePayNowButton = () => {
            setIsCardLoaded(false);
            setIsKlarnaPayLoader(false);
        };
        if (!!paymentMethod &&
            !isPaymentLoading &&
            isCardLoaded &&
            worldpayRedirectUrl &&
            paymentMethod === PaymentMethodTypes.CARD) {
            const iframeSetTimeOut = setTimeout(() => {
                var _a;
                const iframeElm = document.querySelector('#wp-cl-wordpay-target-iframe');
                if (!iframeElm && step === currentStep) {
                    window.location.reload();
                }
                else {
                    (_a = document.getElementById('submitButton')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', handlePayNowButton);
                }
            }, 3500);
            return () => {
                var _a;
                iframeSetTimeOut && clearTimeout(iframeSetTimeOut);
                (_a = document.getElementById('submitButton')) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', handlePayNowButton);
            };
        }
        if (paymentMethod &&
            previousPaymentMethod.current &&
            [KLARNA_PAY_SLICED, KLARNA_PAY_LATER, KLARNA_PAY_NOW].includes(paymentMethod) &&
            previousPaymentMethod.current === paymentMethod) {
            if (paymentMethod === PaymentMethodTypes.KLARNA_PAY_SLICED && isKlarnaSliceRemoved) {
                setPaymentMethodType('');
            }
            else
                setPaymentMethodType(paymentMethod);
        }
    }, [
        step,
        currentStep,
        paymentMethod,
        isPaymentLoading,
        isCardLoaded,
        worldpayRedirectUrl,
        unavailableItems === null || unavailableItems === void 0 ? void 0 : unavailableItems.length,
        isKlarnaSliceRemoved,
    ]);
    useEffect(() => {
        if (paymentMethod) {
            const initialPaymentMethod = paymentMethod;
            if (paymentMethod === PaymentMethodTypes.KLARNA_PAY_SLICED && isKlarnaSliceRemoved) {
                setPaymentMethodType('');
            }
            else
                setPaymentMethodType(initialPaymentMethod);
            setPaypalError(false);
            setKlarnaPayError === null || setKlarnaPayError === void 0 ? void 0 : setKlarnaPayError(false);
            if (paymentMethod === PaymentMethodTypes.PAYPAL ||
                [KLARNA_PAY_LATER, KLARNA_PAY_NOW, KLARNA_PAY_SLICED].includes(paymentMethod)) {
                setIsCardLoaded(true);
                setShowIframe(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethod]);
    const getPaymentTitle = useMemo(() => {
        const paymentTitles = {
            [PaymentMethodTypes.CARD]: isMobile ? 'Debit / Credit card' : 'Pay with Debit / Credit card',
            [PaymentMethodTypes.PAYPAL]: isMobile ? 'PayPal' : 'Pay with PayPal',
            [PaymentMethodTypes.KLARNA]: 'Klarna. Buy now or pay later',
        };
        return (paymentType) => paymentTitles[paymentType] || '';
    }, [isMobile]);
    const handlePaymentChange = (event, paymentType) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        event.stopPropagation();
        setPaypalError(false);
        setKlarnaPayError === null || setKlarnaPayError === void 0 ? void 0 : setKlarnaPayError(false);
        if ((paymentType === paymentMethodType || isPaymentLoading) && !isKlarnaSliceRemoved)
            return;
        previousPaymentMethod.current = paymentType;
        if ([PaymentMethodTypes.CARD, PaymentMethodTypes.PAYPAL, PaymentMethodTypes.KLARNA].includes(paymentType)) {
            setIsCardLoaded(false);
        }
        if (klarnaPaymentOptions.includes(paymentType)) {
            setIsKlarnaPayLoader(true);
        }
        setShowIframe(false);
        if (paymentType === PaymentMethodTypes.KLARNA && (klarnaPaymentTypes === null || klarnaPaymentTypes === void 0 ? void 0 : klarnaPaymentTypes.length)) {
            setPaymentMethodType(klarnaPaymentTypes[0].type);
            yield handleChangePaymethod(klarnaPaymentTypes[0].type);
        }
        else {
            setPaymentMethodType(paymentType);
            yield handleChangePaymethod(paymentType);
        }
        if (paymentType === PaymentMethodTypes.PAYPAL || klarnaTypes.includes(paymentType)) {
            setIsCardLoaded(true);
            setIsKlarnaPayLoader(false);
        }
    });
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        const status = urlParams.get('status');
        const connectorToken = urlParams.get('connectorToken');
        const paymentStatus = urlParams.get('paymentStatus');
        if (type) {
            if ([PaymentMethodTypes.PAYPAL, ...klarnaPaymentOptions].includes(type)) {
                setPaymentMethodType(type);
                if (logOrderPaypalAndKlarna) {
                    if (type.includes('klarna')) {
                        logOrderPaypalAndKlarna(type, status, connectorToken, paymentStatus);
                    }
                    else {
                        logOrderPaypalAndKlarna(type, status, connectorToken);
                    }
                }
                if (status === 'success') {
                    setIsPaymentProgress === null || setIsPaymentProgress === void 0 ? void 0 : setIsPaymentProgress(true);
                    paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, true);
                    completeOrder();
                }
                else if (status === 'pending' && connectorToken && type.includes('klarna')) {
                    paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, true);
                    completeOrder();
                }
                else {
                    if (type === PaymentMethodTypes.PAYPAL) {
                        paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, false);
                        setPaypalError(true);
                    }
                    if (type.includes('klarna')) {
                        setKlarnaPayError === null || setKlarnaPayError === void 0 ? void 0 : setKlarnaPayError(true);
                        paymentSelection === null || paymentSelection === void 0 ? void 0 : paymentSelection(paymentMethodType, false);
                        setPaymentMethodType('');
                    }
                    /* This line is fix for the below scenario of tab change :
                        1. Proceed till payment in tab1 and select paypal and refuse at paypal
                        2. On the error payment section, switch to card and switch back to paypal
                        3. Proceed till payment in tab2 for the same basket without any change.
                        4. Paypal is in selected state but url is of initial worlpay.
                        */
                    if (status === 'failure' && type === PaymentMethodTypes.PAYPAL)
                        handleChangePaymethod(type);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const renderPaymentWidgets = (paymentType) => {
        if ((isPaymentLoading && !klarnaPaymentOptions.includes(paymentMethodType)) ||
            !isCardLoaded ||
            !worldpayRedirectUrl) {
            return _jsx(Skeleton, { height: 250, duration: 1 });
        }
        else if (paymentType.type === PaymentMethodTypes.CARD && worldpayRedirectUrl) {
            return (_jsxs(_Fragment, { children: [!showIframe ? _jsx(Skeleton, { height: 250, duration: 1 }) : null, _jsx("div", { id: "wordpay-target", className: clsx(isSubmitting ? classes.disbaleIframePointerEvents : classes.enableIframePointerEvents, showIframe ? classes.displayIframe : classes.hideIframe), children: MockWorldpayComponent && _jsx(MockWorldpayComponent, { resultCallback: resultCallback }) }), isSubmitting && _jsx(CircularProgress, { className: classes.spinner })] }));
        }
        else if (paymentType.type === PaymentMethodTypes.PAYPAL && worldpayRedirectUrl) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.paypalDescription, children: _jsx("p", { children: paypalError || redirectPaymentError ? (_jsx(Alert, { className: classes.error, type: "alertError", message: "Couldn't finish your payment, please retry" })) : (paymentType.description) }) }), _jsx("div", { className: classes.paypalButton, children: _jsx(NextLink, { href: worldpayRedirectUrl, passHref: true, legacyBehavior: true, children: _jsx("a", { className: classes.linkWrapper, children: _jsx(Image, { imageUrl: `/images/icons/paymentIcons/${paymentType.buttonLabel}.svg`, altText: paymentType.buttonLabel, className: classes.linkWrapper, height: 44, width: 300 }, paymentType.buttonLabel) }) }) }), isSubmitting && _jsx(CircularProgress, { className: classes.spinner })] }));
        }
        else if (paymentType.type.includes('klarna') && worldpayRedirectUrl) {
            return (_jsxs(_Fragment, { children: [_jsx(KlarnaPaymentOptions, { paymentType: paymentType, worldpayRedirectUrl: worldpayRedirectUrl, paymentMethodType: paymentMethodType, klarnaPaymentTypes: klarnaPaymentTypes, handlePaymentChange: handlePaymentChange, isKlarnaPayLoader: isKlarnaPayLoader || isPaymentLoading }), isSubmitting && !isKlarnaPayLoader ? _jsx(CircularProgress, { className: classes.spinner }) : null] }));
        }
        else {
            return null;
        }
    };
    return (_jsx("div", { className: clsx(className, isCurrent ? classes.active : isCompleted ? classes.completed : classes.pending), children: isCurrent ? (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.stepTitle, "data-testid": "checkout-payment", children: title }), (_d = cutOffPassedAlert !== null && cutOffPassedAlert !== void 0 ? cutOffPassedAlert : errorAlert) !== null && _d !== void 0 ? _d : (_jsxs(_Fragment, { children: [_jsxs("p", { className: classes.showOptionsTitle, children: ["By adding payment details and placing the order, I am confirming that I have read and agree with the", ' ', _jsx(Link, { className: classes.link,
                                    label: 'Terms & Conditions',
                                    url: '/page/terms-and-conditions',
                                    highlight: false,
                                    noFollow: false,
                                    newWindow: true }), ", and", ' ', _jsx(Link, { className: classes.link,
                                    label: 'Privacy Policy',
                                    url: '/page/privacy',
                                    highlight: false,
                                    noFollow: false,
                                    newWindow: true }), "."] }), _jsx("div", { className: classes.choosePayment, children: "Choose a way to pay for your order" }), _jsx("div", { className: classes.spinnerContainer, children: accordinPaymentTypes.map((paymentType) => (_jsx("div", { className: clsx(classes.paymentContainer, paymentType.type === paymentMethodType ? classes.activePaymentMethod : '', isPaymentLoading ? classes.disbaleIframePointerEvents : classes.enableIframePointerEvents), children: _jsxs(Accordion, { expanded: paymentType.type === paymentMethodType ||
                                        (paymentMethodType.includes('klarna') && paymentType.type.includes('klarna')), classes: {
                                        root: classes.accordianRoot,
                                    }, children: [_jsxs(AccordionSummary, { classes: {
                                                root: classes.summaryRoot,
                                            }, "aria-controls": `panel-content-${paymentType.type}`, id: `panel-header-${paymentType.type}`, "data-testid": `panel-header-${paymentType.type}`, onClick: (e) => handlePaymentChange(e, paymentType.type), className: isPaymentInProgress ? classes.disbaleIframePointerEvents : classes.enableIframePointerEvents, children: [_jsx(CheckCircle, { name: paymentType.type, label: _jsx("div", { className: classes.checkboxDescription, children: getPaymentTitle(paymentType.type) }), checked: paymentType.type === paymentMethodType ||
                                                        (paymentMethodType.includes('klarna') && paymentType.type.includes('klarna')), value: paymentType.type, type: "checkbox", id: paymentType.type, "data-testid": `payment_type_${paymentType.type}`, onClick: (e) => handlePaymentChange(e, paymentType.type) }), _jsx("div", { className: clsx((paymentType === null || paymentType === void 0 ? void 0 : paymentType.icons.length) > 1 ? classes.iconContainer : '', (paymentType === null || paymentType === void 0 ? void 0 : paymentType.type.includes('klarna')) ? classes.klarnaIconContainer : ''), children: paymentType.icons.map((icon) => {
                                                        var _a, _b;
                                                        return (_jsx(Image, { imageUrl: `/images/icons/paymentIcons/${icon}.svg`, altText: icon, width: (_a = imageDiementiosnCallback(paymentType.type)) === null || _a === void 0 ? void 0 : _a.width, height: (_b = imageDiementiosnCallback(paymentType.type)) === null || _b === void 0 ? void 0 : _b.height }, icon));
                                                    }) })] }), _jsxs(AccordionDetails, { classes: {
                                                root: classes.detailsRoot,
                                            }, children: [isPaymentInProgress ? _jsx(CircularProgress, { className: classes.spinner }) : null, renderPaymentWidgets(paymentType)] })] }) }, paymentType.type))) })] }))] })) : (_jsxs(_Fragment, { children: [_jsx("span", { children: title }), isCompleted && _jsx(Icon, { icon: "check_circle", className: classes.tick })] })) }));
};
export default CheckoutPayment;
