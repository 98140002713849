import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Card, useTheme } from '@mui/material';
import useStyles from './SpotlightBanner.styles';
import SpotlightBannerText from './SpotlightBannerText';
import SpotlightBannerImage from './SpotlightBannerImage';
import Image from '../Image/Image';
import { processImageUrl } from '../../utils/image';
import { imageWidths, sizes } from '../../utils/common';
import Button from '../Button/Button';
import { useMediaQuery } from 'react-responsive';
const SpotlightBannerMobile = (props) => {
    var _a, _b, _c;
    const { transparentOverlay, buttonLink } = props;
    const { title, body, handleClick, backgroundImage, mobileBackgroundImage } = props;
    const { classes } = useStyles({ transparentOverlay });
    const { url, label } = buttonLink !== null && buttonLink !== void 0 ? buttonLink : {};
    const theme = useTheme();
    const isMobileView = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const bgImage = isMobileView && (mobileBackgroundImage === null || mobileBackgroundImage === void 0 ? void 0 : mobileBackgroundImage.url) ? mobileBackgroundImage : backgroundImage;
    const mobileContent = transparentOverlay != null && transparentOverlay >= 0 ? (_jsxs("div", { className: clsx(classes.tranparentMobileView, classes.imageContainer), children: [_jsx(SpotlightBannerImage, Object.assign({}, props, { isMobile: true })), (title || body || (url && label)) && (_jsxs("div", { className: clsx(classes.overlayTextContainer), children: [_jsx(SpotlightBannerText, { title: title, body: body }), url && label && (_jsx(Button, { onClick: handleClick, title: label, variant: 'primary', thin: true, icon: '', className: classes.linkButton }))] }))] })) : (_jsxs(Card, { children: [_jsx(Image, { className: clsx(classes.image, classes.mobileImage), imageUrl: processImageUrl((_a = bgImage === null || bgImage === void 0 ? void 0 : bgImage.url) !== null && _a !== void 0 ? _a : '', {}, ['$poi-square$']), altText: (_c = (_b = bgImage === null || bgImage === void 0 ? void 0 : bgImage.altText) !== null && _b !== void 0 ? _b : title) !== null && _c !== void 0 ? _c : '', imageWidths: imageWidths, sizes: sizes, height: 376, width: 376 }), _jsxs("div", { className: clsx(classes.container, classes.textContainer), children: [_jsx(SpotlightBannerText, { title: title, body: body }), url && (_jsx(Button, { onClick: handleClick, title: label, variant: 'primary', thin: true, icon: '', className: classes.linkButton }))] })] }));
    return isMobileView ? mobileContent : null;
};
export default SpotlightBannerMobile;
