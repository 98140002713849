import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from 'next/image';
import useStyles from './StyleVariantInformation.styles';
import RawHtml from '../RawHtml/RawHtml';
const StyleVariantInformation = (props) => {
    const { styleVariantInformationItems } = props;
    const { classes } = useStyles();
    return (_jsx("div", { className: classes.modal, children: _jsx("div", { className: classes.container, children: styleVariantInformationItems === null || styleVariantInformationItems === void 0 ? void 0 : styleVariantInformationItems.map((item) => {
                var _a;
                return (_jsxs("div", { className: classes.information, children: [((_a = item.image) === null || _a === void 0 ? void 0 : _a.url) ? (_jsx("div", { children: _jsx(Image, { className: classes.image, src: item.image.url, alt: '', width: 305, height: 171 }) })) : null, _jsxs("div", { className: classes.bodyContainer, children: [_jsx("div", { children: item.title }), _jsx(RawHtml, { content: item.body, className: classes.description })] })] }, item.title));
            }) }) }));
};
export default StyleVariantInformation;
