export const carouselStyles = (theme, isMediaCarousel) => ({
    'custom-dot-list-style': {
        position: 'relative',
        top: '35px',
        '& li:last-child button': {
            marginRight: '0',
        },
    },
    carouselContainer: {
        margin: '0 auto',
        width: '100%',
        position: 'relative',
        top: 0,
        height: 'initial',
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: isMediaCarousel ? 0 : '-32px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: isMediaCarousel ? '8px' : '-72px',
        },
    },
    leftButton: {},
    rightButton: {},
    customButtonGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        top: isMediaCarousel ? '36.5%' : '50%',
        '@media (orientation: landscape)': {
            top: '42%',
        },
        [theme.breakpoints.up('xs')]: {
            top: isMediaCarousel ? '39.5%' : '50%',
        },
        [theme.breakpoints.up('sm')]: {
            top: isMediaCarousel ? '45.5% !important' : '50%',
            '@media (orientation: landscape)': {
                top: '45% !important',
            },
        },
        '& $leftButton': {
            left: theme.spacing(1),
        },
        '& $leftButton, $rightButton': {
            position: 'absolute',
            '& button': {
                width: '40px',
                height: '40px',
                [theme.breakpoints.up('md')]: {
                    height: isMediaCarousel ? '40px' : '60px',
                    width: isMediaCarousel ? '40px' : '60px',
                },
                [theme.breakpoints.up('xl')]: {
                    height: '60px',
                    width: '60px',
                },
                '& .MuiFab-label': {
                    width: '40px',
                    height: '40px',
                    [theme.breakpoints.up('md')]: {
                        height: isMediaCarousel ? '40px' : '60px',
                        width: isMediaCarousel ? '40px' : '60px',
                    },
                    [theme.breakpoints.up('xl')]: {
                        height: '60px',
                        width: '60px',
                    },
                },
            },
        },
        '& $rightButton': {
            right: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                right: '8px',
            },
        },
    },
});
