import { makeStyles } from 'tss-react/mui';
import { colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: Object.assign(Object.assign({}, theme.typography.small_regular), { paddingTop: 0, color: colors.grey.grey6, minHeight: '83px' }),
    image: {
        height: '75px',
        width: '75px',
    },
}));
export default useStyles;
