import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import useStyles from './ContentFeature.styles';
import { useBackgroundClass } from '../../styles/jss/utils';
import SplitContentBySlash from '../../utils/SplitContentBySlash';
import { SiteContext } from '../../utils/common';
import ImageContainer from './ImageContainer/ImageContainer';
import ContentContainer from './ContentContainer/ContentContainer';
import VideoContainer from './VideoContainer/VideoContainer';
import clsx from 'clsx';
import { Box } from '@mui/material';
const ContentFeature = (props) => {
    var _a, _b;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { headingTag = 'H3', textContentPosition = 'CENTER', titleTextVariant, backgroundAndHeaderTextColors, backgroundColor, contentPosition, } = props;
    const hasVideo = !!((_a = props === null || props === void 0 ? void 0 : props.video) === null || _a === void 0 ? void 0 : _a.url);
    const [brandBackgroundColor, headingColor] = ((_b = backgroundAndHeaderTextColors === null || backgroundAndHeaderTextColors === void 0 ? void 0 : backgroundAndHeaderTextColors.split('-')) !== null && _b !== void 0 ? _b : []).map((color) => color.trim());
    const { classes } = useStyles({ isQuotation: !!props.isQuotation, headingColor: headingColor });
    const titleHeadingColor = headingColor && titleTextVariant === 'LARGE_ADVERTISING' && !isFlyingFlowers ? classes.headingColor : undefined;
    const renderHeading = !isFlyingFlowers ? (_jsx(SplitContentBySlash, { content: props.heading, classNames: { headingColor: titleHeadingColor, headingLine: classes.headingLine } })) : (props.heading);
    let bgColor;
    if (isFlyingFlowers && backgroundColor) {
        bgColor = backgroundColor;
    }
    else if (titleTextVariant && brandBackgroundColor) {
        bgColor = brandBackgroundColor;
    }
    else {
        bgColor = backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : 'WHITE'; // Use default if undefined
    }
    const { bgClass } = useBackgroundClass(bgColor);
    const getPositionClass = (contentPosition) => {
        switch (contentPosition === null || contentPosition === void 0 ? void 0 : contentPosition.toUpperCase()) {
            case 'LEFT':
                return classes.positionLeft;
            case 'RIGHT':
                return classes.positionRight;
            default:
                return classes.positionCenter;
        }
    };
    const positionClass = getPositionClass(contentPosition);
    return (_jsxs(Box, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.container, bgClass, positionClass), "data-testid": 'content-feature-container', children: [!hasVideo && _jsx(ImageContainer, Object.assign({}, props, { classNames: classes, squareImage: true })), hasVideo && _jsx(VideoContainer, Object.assign({}, props)), _jsx(ContentContainer, Object.assign({}, props, { heading: renderHeading, textContentPosition: textContentPosition, titleTextVariant: titleTextVariant, classNames: classes, headingTag: headingTag }))] }));
};
export default ContentFeature;
