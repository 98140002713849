import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './CategoryInformation.styles';
import SimpleLinkGalleryComponent from '../SimpleLinkGallery/SimpleLinkGallery';
import RawHtml from '../RawHtml/RawHtml';
import { useLoaded } from '../MainContent/useComponentLoading';
const CategoryInformation = (props) => {
    const { title, body, linksTitle, links } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.contentContainer, children: [title && _jsx("h2", { className: classes.title, children: title }), body && _jsx(RawHtml, { content: body, className: classes.bodyContent })] }), linksTitle && links && (_jsx("span", { className: classes.Gallerycontent, children: _jsx(SimpleLinkGalleryComponent, { linkTitle: linksTitle, links: links }) }))] }));
};
export default CategoryInformation;
