import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import useStyles from '../../OrderConfirmation.styles';
import Button from '../../../Button/Button';
import { useRouter } from 'next/router';
import { SiteContext } from '../../../../utils/common';
import { useMobileDeviceCheck, useSessionStorage } from '../../../../utils/utilityCustomHooks';
const OrderConfirmationItemBuyAgain = (props) => {
    const { sku, price, productURL, isOpenPrice, specialInstructions } = props;
    const { classes } = useStyles();
    const router = useRouter();
    const { saveToSessionStorage } = useSessionStorage();
    const { isMobile } = useMobileDeviceCheck();
    const { isFlyingFlowers } = useContext(SiteContext);
    const handleBuyAgain = () => {
        const buyAgainData = { sku, isOpenPrice, price, specialInstructions };
        saveToSessionStorage('buyAgainOrderItem', buyAgainData);
        router.push(`${productURL}?productOrderedFrom=order_confirmation_buy_again`);
    };
    return (_jsxs("div", { children: [_jsx("p", { className: classes.wantAnotherTitle, children: "Want another?" }), _jsx(Button, { variant: isFlyingFlowers ? 'secondary' : 'primary', title: "Buy again", icon: "autorenew", thin: true, fullWidth: isMobile, onClick: handleBuyAgain, className: classes.wantAnotherButton })] }));
};
export default OrderConfirmationItemBuyAgain;
