import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '520px',
        },
        '& button': {
            marginBottom: spacing.large,
        },
        '& a': {
            textDecoration: 'underline',
        },
    },
}));
export default useStyles;
