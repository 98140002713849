import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { default as NextLink } from 'next/link';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import useStyles from './Logo.styles';
import { Box } from '@mui/material';
const Logo = ({ condensed, className, url, isFooter }) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const imgUrl = isFlyingFlowers
        ? isFooter
            ? '/images/ff-logo-horizontal-r-wild-exotic.svg'
            : '/images/ff-logo-stacked.svg'
        : '/images/logo.svg';
    const { classes } = useStyles();
    const classNames = clsx(classes.logo, condensed && classes.condensed);
    return (_jsx(Box, { className: clsx(classes.container, className), children: url ? (_jsx(NextLink, { href: url, legacyBehavior: true, children: _jsxs("a", { className: classNames, children: [_jsx("div", { className: classes.band }), _jsx("img", { src: imgUrl, alt: "Logo", width: 128, height: 55 })] }) })) : (_jsxs("div", { className: classNames, children: [_jsx("div", { className: classes.band }), _jsx("img", { src: imgUrl, alt: "Logo", width: 128, height: 55 })] })) }));
};
export default Logo;
