import { makeStyles } from 'tss-react/mui';
import { colors } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        margin: '0 auto',
        maxWidth: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '82%',
            height: '100%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            height: 'calc(100vh - 128px)',
        },
    },
    mediaCarousalContainer: {
        height: 'initial',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    mediaCarousalSubContainer: {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            width: '86%',
        },
    },
    desktopMediaImage: {
        '& > div': {
            height: '100%',
            '& > div': {
                '& > img:first-child': {
                    objectFit: 'cover',
                    height: '188px',
                    [theme.breakpoints.up('sm')]: {
                        height: '428px',
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '448px',
                    },
                    [theme.breakpoints.up('xl')]: {
                        height: '620px',
                    },
                },
            },
        },
    },
    image: {
        '& > div': {
            height: '100%',
            '& > div': {
                '& > img:first-child': {
                    objectFit: 'cover',
                    [theme.breakpoints.down('sm')]: {
                        height: 'calc(100vh - 170px)',
                        '@media (orientation: landscape)': {
                            height: 'calc(100vh - 125px)',
                        },
                    },
                    [theme.breakpoints.up('md')]: {
                        height: 'calc(100vh - 194px)',
                    },
                },
            },
        },
    },
    zoomIn: {
        '& > div': {
            cursor: `url('/images/icons/cursors/zoomIn.svg'), auto !important`,
        },
    },
    zoomOut: {
        '& > div': {
            cursor: `url('/images/icons/cursors/zoomOut.svg'), auto !important`,
            caretColor: 'white',
        },
    },
    productName: {
        backgroundColor: colors.grey.grey1,
        color: colors.grey.grey6,
        textAlign: 'center',
        paddingTop: '9px',
        paddingBottom: '11px',
        [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { padding: '0 !important' }),
    },
    imageHeading: Object.assign(Object.assign(Object.assign({ backgroundColor: colors.grey.grey1, width: '100%' }, theme.typography.small_bold), theme.typographySpacing.none), { padding: theme.isFlyingFlowers ? '8px 16px !important' : '12px 16px !important', textAlign: theme.isFlyingFlowers ? 'center' : 'left', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }),
}));
export default useStyles;
