import HomeHeroBannerUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/HomeHeroBanner/HomeHeroBanner'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { useLoaded } from '@interflora/ui-components'

type Props = UIProps

const HomeHeroBanner = (props: Props) => {
  const analytics = useContext(AnalyticsContext)

  const homeHeroCTAnalytics = (category?: string) => {
    analytics.homeHeroBanner(category)
  }

  useLoaded(props)

  return <HomeHeroBannerUI {...props} homeHeroCTAnalytics={homeHeroCTAnalytics} />
}

export default HomeHeroBanner
