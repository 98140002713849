import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { default as _get } from 'lodash/get';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import Drawer from '../Drawer/Drawer';
import FilterClearRefinements from '../FilterClearRefinements/FilterClearRefinements';
import FilterCurrentRefinements from '../FilterCurrentRefinements/FilterCurrentRefinements';
import { FilterNumericList, FilterRefinementList, SortByList } from '../FilterFacet/FilterFacet';
import useStyles from './FacetSelectionDesktop.styles';
const FacetSelectionDesktop = ({ open, setFacetListOpen, setFiltersClosed, filters, priceRanges, totalResultCount, sortConfig, sortBy, selectedFilter, setSelectedFilter, isFromCategory = true, facetDetails, }) => {
    const { classes } = useStyles();
    const refArray = filters.map(() => React.createRef());
    const [selectedAttribute, setSelectedAttribute] = useState();
    useEffect(() => {
        const selectedFilterIndex = selectedAttribute
            ? filters.findIndex((filter) => filter.attributeName === selectedAttribute)
            : filters.findIndex((filter) => filter.isOpen);
        if (selectedFilterIndex !== -1) {
            const currentRef = _get(refArray, `${selectedFilterIndex}.current`);
            if (currentRef) {
                const delay = selectedAttribute ? 150 : 350;
                setTimeout(() => {
                    currentRef.scrollIntoView({ block: 'start' });
                }, delay);
            }
        }
    }, [filters, refArray, selectedAttribute]);
    return (_jsx(Drawer, { isFromCategory: isFromCategory, open: open, onClose: setFiltersClosed, setOpen: setFacetListOpen, title: "Sorting and filters", children: _jsxs("div", { className: classes.facetSelection, children: [_jsxs("div", { className: classes.facetContainer, children: [_jsx(FilterCurrentRefinements, { priceRanges: priceRanges }), _jsxs("div", { className: classes.globalFilterActions, children: [_jsxs("span", { className: classes.resultCount, children: [totalResultCount, " items"] }), _jsx(FilterClearRefinements, { className: classes.clearFilters, onClick: setFiltersClosed, setSelectedFilter: setSelectedFilter })] }), _jsx("div", { className: classes.facets, children: filters.map((filter, i) => (_jsx("div", { ref: refArray[i], children: filter.attributeName === 'sortBy' ? (_jsx(SortByList, { title: `${filter.attributeLabel} ${sortBy}`, attribute: "sortBy", defaultOpen: filter.isOpen, items: sortConfig, facetDetails: facetDetails })) : filter.attributeName === 'price' ? (_jsx(FilterNumericList, { title: filter.attributeLabel, attribute: "price", defaultOpen: filter.isOpen, items: priceRanges, selectedFilter: selectedFilter, setSelectedFilter: setSelectedFilter, facetDetails: facetDetails, setSelectedAttribute: setSelectedAttribute })) : (_jsx(FilterRefinementList, { title: filter.attributeLabel, attribute: filter.attributeName, defaultOpen: filter.isOpen, selectedFilter: selectedFilter, setSelectedFilter: setSelectedFilter, facetDetails: facetDetails, setSelectedAttribute: setSelectedAttribute })) }, filter.attributeName))) })] }), _jsx(Button, { className: classes.closeButton, variant: "secondary", onClick: () => setFacetListOpen(false), fullWidth: true, title: `Close - view ${totalResultCount} results` })] }) }));
};
export default FacetSelectionDesktop;
