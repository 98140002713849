import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import clsx from 'clsx';
import { SiteContext } from '../../utils/common';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import IDPModalContent from './IDPModalContent';
import useStyles from './AddIDPToBasket.styles';
const AddIDPToBasket = (props) => {
    var _a, _b, _c;
    const { addDeliveryPassToCart, sku, className, activeDeliveryPassType } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const [isOpen, setIsOpen] = useState(false);
    const [viewBasket, setViewBasket] = useState(false);
    const { getCustomer } = useContext(SiteContext);
    const hasDeliveryPassInAccount = !!((_c = (_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.deliveryPass) === null || _c === void 0 ? void 0 : _c.type);
    const handleAddDelieryPass = () => {
        const activeDeliveryPass = activeDeliveryPassType === null || activeDeliveryPassType === void 0 ? void 0 : activeDeliveryPassType.toUpperCase();
        const activeGoldDeliveryPass = activeDeliveryPass === null || activeDeliveryPass === void 0 ? void 0 : activeDeliveryPass.includes('GOLD');
        const activePlatinumDeliveryPass = activeDeliveryPass === null || activeDeliveryPass === void 0 ? void 0 : activeDeliveryPass.includes('PLATINUM');
        const deliveryPassSku = sku.toUpperCase();
        const platinumSKU = deliveryPassSku.includes('PLATINUM');
        const goldSKU = deliveryPassSku.includes('GOLD');
        if (hasDeliveryPassInAccount || (activeGoldDeliveryPass && platinumSKU)) {
            setIsOpen(true);
        }
        else if ((activeGoldDeliveryPass && goldSKU) ||
            (activePlatinumDeliveryPass && goldSKU) ||
            (activePlatinumDeliveryPass && platinumSKU)) {
            setIsOpen(true);
            setViewBasket(true);
        }
        else {
            addDeliveryPassToCart(sku);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: clsx(className, classes.idpAddToBasketButton), children: [_jsx(Button, { variant: "primary", thin: true, fullWidth: true, title: 'Add to basket', onClick: handleAddDelieryPass }), _jsx(Modal, { open: isOpen, onClose: () => setIsOpen(false), keepMounted: true, fullScreen: !isLargerViewport, setOpen: () => setIsOpen(true), maxWidth: "sm", fullWidth: true, title: "Interflora delivery pass", className: classes.idpModal, children: _jsx(IDPModalContent, { sku: sku, activeDeliveryPassType: activeDeliveryPassType, hasDeliveryPassInAccount: hasDeliveryPassInAccount, setIsOpen: setIsOpen, viewBasket: viewBasket, addDeliveryPassToCart: addDeliveryPassToCart }) })] }) }));
};
export default AddIDPToBasket;
