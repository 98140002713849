import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import connectVoiceSearch from 'instantsearch.js/es/connectors/voice-search/connectVoiceSearch';
import { useEffect, useRef } from 'react';
import { useConnector, useSearchBox } from 'react-instantsearch';
import Icon from '../Icon/Icon';
import useStyles from './SearchAutoComplete.styles';
function useVoiceSearch(props) {
    return useConnector(connectVoiceSearch, props);
}
const SearchAutoComplete = (props) => {
    const { placeholder, fullWidth, name, onChange, value, unboxed, showSearch } = props;
    const { classes } = useStyles();
    const { isBrowserSupported, isListening, toggleListening } = useVoiceSearch();
    const { query } = useSearchBox();
    const inputRef = useRef();
    const isListeningRef = useRef(isListening);
    const handleInputChange = (value) => {
        if (sessionStorage.getItem('activeSearchTab')) {
            sessionStorage.removeItem('activeSearchTab');
        }
        onChange(value);
    };
    // Update the value from voice search when listening is changed from true to false
    useEffect(() => {
        if (isListeningRef.current && !isListening) {
            onChange(query);
        }
        isListeningRef.current = isListening;
    }, [isListening, onChange, query]);
    useEffect(() => {
        if (showSearch && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showSearch]);
    useEffect(() => {
        const searchFieldHTML = document.getElementById('SearchOverlayTextField');
        if (!!searchFieldHTML) {
            searchFieldHTML === null || searchFieldHTML === void 0 ? void 0 : searchFieldHTML.setAttribute('data-hj-allow', true);
        }
    }, []);
    return (_jsx("div", { className: classes.searchAutoComplete, children: _jsx("div", { className: clsx(unboxed ? classes.textFieldUnboxed : classes.textField, classes.searchTextField), children: _jsx("div", { className: classes.textContainer, children: _jsx(TextField, { name: name, placeholder: placeholder, fullWidth: fullWidth, value: value, inputRef: inputRef, onChange: (e) => handleInputChange(e.target.value), id: "SearchOverlayTextField", variant: "standard", InputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'list',
                        'aria-haspopup': 'true',
                        disableUnderline: true,
                        startAdornment: (_jsx(InputAdornment, { position: "start", "data-testid": isListening ? 'mic' : 'mic_none', children: isBrowserSupported && (_jsx(Icon, { icon: isListening ? 'mic' : 'mic_none', ariaLabel: "Voice search", onClick: toggleListening })) })),
                        endAdornment: (_jsxs("div", { className: classes.endAdornmentContainer, children: [!!value && (_jsx(IconButton, { type: "button", onClick: () => handleInputChange(''), className: clsx(classes.iconButton, classes.clearbutton), "aria-label": "clear", "data-testid": `Clear`, size: "large", children: _jsx(ClearIcon, {}) })), _jsx(IconButton, { type: "submit", className: clsx(classes.iconButton, classes.searchbutton), "aria-label": "search", "data-testid": `Search`, size: "large", children: _jsx(SearchIcon, {}) })] })),
                    } }) }) }) }));
};
export default SearchAutoComplete;
