import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import useStyles from './SortedLinksGallery.styles';
import Link from '../Link/Link';
import clsx from 'clsx';
const SortedLinksGallery = (props) => {
    const { classes } = useStyles();
    const { links, className } = props;
    const sortedLinks = useMemo(() => links === null || links === void 0 ? void 0 : links.slice().sort((item1, item2) => { var _a, _b; return (_a = item1.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().localeCompare((_b = item2.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()); }), [links]);
    return (_jsx(_Fragment, { children: !!sortedLinks && (_jsx("ul", { className: clsx(classes.sortedList, className), children: sortedLinks.map((link, index) => (_jsx("li", { children: _jsx(Link, Object.assign({}, link)) }, `${link.label}_${index}`))) })) }));
};
export default SortedLinksGallery;
