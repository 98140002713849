import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './Iframe.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const Iframe = (props) => {
    const { url } = props;
    const { classes } = useStyles();
    useLoaded(props);
    return (_jsx("div", { className: classes.container, children: !!url && _jsx("iframe", { src: url, className: classes.iframeContainer, frameBorder: 0 }) }));
};
export default Iframe;
