import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import Link from '../Link/Link';
import BlogArticleCard from '../BlogArticleCard/BlogArticleCard';
import GenericGalleryGrid from '../GenericGalleryGrid/GenericGalleryGrid';
import useStyles from './BlogLatestArticleGallery.styles';
import { handleSecondaryBadgeHeight as getDeviceType, SiteContext } from '../../utils/common';
import { useTheme } from '@mui/material';
import Carousel from '../Carousel/Carousel';
import CarouselDots from '../Carousel/CarouselDots/CarouselDots';
import { useMediaQuery } from 'react-responsive';
import { useLoaded } from '../MainContent/useComponentLoading';
const BlogLatestArticleGallery = (props) => {
    const { title, link, latestArticlesList } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const theme = useTheme();
    const [device, setDevice] = useState();
    const tablet = useMediaQuery({ query: `(max-width: ${theme.breakpoints.values.md}px)` });
    const desktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const isLargeDesktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.lg}px)` });
    const [displayType, setDisplayType] = useState();
    const breakpoints = {
        extraSmall: 12,
        small: 12,
        medium: 4,
        large: 4,
    };
    const { sm, md, xl } = theme.breakpoints.values;
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: 3,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 3,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 2,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    const carouselRef = React.createRef();
    useEffect(() => {
        const { device } = getDeviceType(desktop, tablet);
        if (!!device)
            setDevice(device);
        const resize = () => {
            const { device } = getDeviceType(desktop, tablet);
            if (!!device)
                setDevice(device);
        };
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [desktop, tablet, isLargeDesktop, isLargerViewport]);
    useEffect(() => {
        if (((device === 'MOBILE' || device === 'SMALL') && (latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.length) <= 1) ||
            (device === 'TABLET' && (latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.length) <= 2) ||
            ((device === 'DESKTOP' || device === 'LARGEDESKTOP') && (latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.length) <= 3)) {
            setDisplayType('GRID');
        }
        else
            setDisplayType('CAROUSEL');
    }, [device, latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.length]);
    useLoaded(props);
    return (_jsxs(_Fragment, { children: [title && _jsx("h2", { className: classes.title, children: title }), !!(latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.length) && (_jsx(_Fragment, { children: displayType === 'GRID' ? (_jsx(GenericGalleryGrid, { breakpoints: breakpoints, ItemComponent: BlogArticleCard, items: latestArticlesList, componentProps: { halfWidth: false, showReadMore: true }, className: classes.gridGalleryContainer })) : (_jsx(Carousel, { containerClass: classes.carousel, sliderClass: classes.slider, responsive: responsive, arrows: false, autoPlay: false, itemClass: classes.carouselItem, ssr: true, showDots: true, renderDotsOutside: true, infinite: true, customDot: _jsx(CarouselDots, { itemsLength: latestArticlesList.length - 1, nextSlide: moveToNextSlide, prevSlide: moveToPreviouSlide, className: classes.carouselDots }), ref: carouselRef, carouselContainerClass: classes.carouselContainer, children: latestArticlesList === null || latestArticlesList === void 0 ? void 0 : latestArticlesList.map((item) => (_createElement(BlogArticleCard, Object.assign({}, item, { key: item.title, showReadMore: true, className: classes.blogArticleCardContainer })))) })) })), _jsx("div", { className: classes.button, children: link && _jsx(Link, Object.assign({ button: true }, link, { variant: isFlyingFlowers ? 'secondaryDark' : 'primary' })) })] }));
};
export default BlogLatestArticleGallery;
