import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_WIDTH_INNER = 520;
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        margin: '-60px auto 0',
        maxWidth: MAX_WIDTH_INNER,
        [`& .${classes.itemText}`]: Object.assign(Object.assign({}, theme.typography.large_bold), { color: colors.supplementary.black, marginBottom: spacing.medium, paddingTop: 0 }),
        [`& .${classes.confirmationText}`]: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: spacing.small, '&:last-child': {
                marginBottom: spacing.large,
            }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.large, '&:last-child': {
                    marginBottom: spacing.xl,
                } }) }),
    },
    itemText: {},
    confirmationText: {},
    title: Object.assign(Object.assign({}, theme.typography.medium_regular), { color: colors.grey.grey6, [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.large_regular) }),
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    button: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.medium,
        },
    },
}));
export default useStyles;
