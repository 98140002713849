import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from 'next/link';
import Icon from '../Icon/Icon';
import useStyles from './SiteUsp.styles';
import clsx from 'clsx';
const SiteUsp = (props) => {
    const { title, icon, link, messageColor, iconColor, carouselIndex } = props;
    const { classes } = useStyles();
    const messageColorClass = messageColor ? classes[messageColor.toUpperCase()] : '';
    const iconColorClass = iconColor ? classes[iconColor.toUpperCase()] : '';
    return (_jsxs("div", { className: classes.container, "data-testid": title, "data-index": carouselIndex, "data-type": "SiteUspGallery", children: [icon && _jsx(Icon, { icon: icon.toLowerCase(), className: clsx(classes.icon, iconColorClass) }), title && (link === null || link === void 0 ? void 0 : link.url) ? (_jsx(Link, { href: link.url, legacyBehavior: true, children: _jsx("a", { className: clsx(classes.linkWrapper, messageColorClass), children: _jsx("div", { className: classes.uspHeaderText, children: title }) }) })) : (_jsx("div", { className: clsx(classes.uspHeaderText, messageColorClass), children: title }))] }));
};
export default SiteUsp;
