import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    buttonContainer: {
        width: 'fit-content',
        textAlign: theme.isFlyingFlowers ? 'center' : 'left',
    },
    roundButton: {
        '&.MuiFab-root': {
            height: '60px',
            width: '60px',
            backgroundColor: colors.supplementary.white,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
            borderRadius: '50% !important',
            border: `1px solid ${theme.isFlyingFlowers ? colors.wildExotic[400] : colors.primary.gold5}`,
            zIndex: 20,
            '&:hover': {
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.primary.gold1,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:focus': {
                backgroundColor: theme.isFlyingFlowers ? colors.black[100] : colors.primary.gold1,
                boxShadow: theme.isFlyingFlowers
                    ? '0px 0px 0px 2px #008BE9;'
                    : '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
            },
            '&:active': {
                boxShadow: theme.isFlyingFlowers ? '0px 1px 2px rgba(0, 0, 0, 0.48);' : 'none',
            },
            '&:disabled': {
                backgroundColor: theme.isFlyingFlowers ? colors.black[200] : colors.supplementary.white,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
                borderColor: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey8,
                '& .MuiIcon-root': { color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey8 },
            },
            [`&.${classes.small}`]: {
                width: '32px',
                height: '32px',
                minHeight: '32px',
                '& .MuiIcon-root': {
                    fontSize: '14px',
                },
            },
        },
    },
    label: Object.assign({}, theme.typography.xxs_bold),
    labelAlignSide: {
        paddingLeft: spacing.xs,
        color: theme.isFlyingFlowers ? colors.black[700] : undefined,
    },
    labelAlignBottom: {
        display: 'block',
        textAlign: 'center',
        paddingTop: `${spacing.xs} !important`,
    },
    icon: {
        color: theme.isFlyingFlowers ? colors.wildExotic[400] : colors.primary.gold5,
    },
    rippleClass: {
        color: theme.isFlyingFlowers ? colors.supplementary.white : colors.primary.gold2,
    },
    noRipple: {
        color: colors.supplementary.white,
    },
    disabledIcon: {
        color: theme.isFlyingFlowers ? colors.black[600] : undefined,
    },
    small: {},
}));
export default useStyles;
