import { jsx as _jsx } from "react/jsx-runtime";
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './SiteNotice.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const SiteNotice = (props) => {
    const { content, noticeColor } = props;
    const { classes } = useStyles({ noticeColor });
    useLoaded(props);
    return (_jsx("div", { className: classes.container, children: _jsx("div", { className: classes.inner, children: _jsx(RawHtml, { className: classes.siteNotice, content: content }) }) }));
};
export default SiteNotice;
