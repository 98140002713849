var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from 'react';
import useStyles from './OrderConfirmation.styles';
import Link from '../Link/Link';
import format from 'date-fns/format';
import clsx from 'clsx';
import Button from '../Button/Button';
import RoundButton from '../RoundButton/RoundButton';
import OrderConfirmationGiftSummary from './OrderConfirmationGiftSummary/OrderConfirmationGiftSummary';
import ConfirmationRegistration from '../ConfirmationRegistration/ConfirmationRegistration';
import { SiteContext } from '../../utils/common';
import RemindersSummary from '../RemindersSummary/RemindersSummary';
import Alert from '../Alert/Alert';
import ConfirmationSignIn from '../ConfirmationSignIn/ConfirmationSignIn';
import { Box } from '@mui/material';
const _ = require('lodash');
const checkoutSteps = [{ heading: 'Your contact information' }, { heading: 'Billing details' }, { heading: 'Payment' }];
const getConsignmentReminders = (order, occasions) => {
    var _a, _b;
    const consignmentReminders = [];
    const filteredConsignments = (_b = (_a = order === null || order === void 0 ? void 0 : order.consignments) === null || _a === void 0 ? void 0 : _a.filter((item) => !(item === null || item === void 0 ? void 0 : item.isDigital))) === null || _b === void 0 ? void 0 : _b.filter((consignment) => {
        return consignment.items.filter((item) => { var _a; return !((_a = item === null || item === void 0 ? void 0 : item.lineItem) === null || _a === void 0 ? void 0 : _a.deceasedName); });
    });
    filteredConsignments === null || filteredConsignments === void 0 ? void 0 : filteredConsignments.forEach((consignment) => {
        consignment.items.forEach((item) => {
            var _a, _b, _c, _d, _e, _f;
            const occasionIsActive = (_a = occasions === null || occasions === void 0 ? void 0 : occasions.find((occasion) => { var _a; return occasion.label.toLowerCase() === ((_a = item === null || item === void 0 ? void 0 : item.lineItem.occasion) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })) === null || _a === void 0 ? void 0 : _a.occasionReminders;
            const { isActive, isPredeterminedDate, preDeterminedOccasionDate } = occasionIsActive || {};
            if (!!isActive) {
                consignmentReminders.push({
                    firstName: (_c = (_b = item === null || item === void 0 ? void 0 : item.recipient) === null || _b === void 0 ? void 0 : _b.firstName) !== null && _c !== void 0 ? _c : '',
                    lastName: (_e = (_d = item === null || item === void 0 ? void 0 : item.recipient) === null || _d === void 0 ? void 0 : _d.lastName) !== null && _e !== void 0 ? _e : '',
                    date: isPredeterminedDate && preDeterminedOccasionDate
                        ? preDeterminedOccasionDate
                        : (item === null || item === void 0 ? void 0 : item.lineItem.deliveryDate) || '',
                    occasion: (_f = item === null || item === void 0 ? void 0 : item.lineItem.occasion) !== null && _f !== void 0 ? _f : '',
                });
            }
        });
    });
    return _.uniqWith(consignmentReminders, _.isEqual);
};
const OrderConfirmation = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { classes } = useStyles();
    const { isFlyingFlowers, getCustomer, occasions } = useContext(SiteContext);
    const { order, onSubmit, handleSaveOccasionReminder, success, getAvailableReminders, remindersToDisplay, onSignIn, handleGeneratePassword, saveUserJourneyAnalytics, } = props;
    const containerRef = React.useRef(null);
    const isShowReminderSummaryCard = (_b = (_a = order === null || order === void 0 ? void 0 : order.consignments) === null || _a === void 0 ? void 0 : _a.filter((consignment) => !(consignment === null || consignment === void 0 ? void 0 : consignment.isDigital))) === null || _b === void 0 ? void 0 : _b.some((consignment) => {
        var _a;
        return (_a = consignment === null || consignment === void 0 ? void 0 : consignment.items) === null || _a === void 0 ? void 0 : _a.some((item) => !item.lineItem.deceasedName);
    });
    const createdAt = order === null || order === void 0 ? void 0 : order.createdAt;
    const email = (_c = order === null || order === void 0 ? void 0 : order.billingAddress) === null || _c === void 0 ? void 0 : _c.email;
    useEffect(() => {
        var _a, _b;
        const remindersData = getConsignmentReminders(order, occasions);
        const getReminders = (reminders) => __awaiter(void 0, void 0, void 0, function* () {
            if (getAvailableReminders)
                yield getAvailableReminders(reminders);
        });
        if (!!(remindersData === null || remindersData === void 0 ? void 0 : remindersData.length) && !!((_b = (_a = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id)) {
            getReminders(remindersData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [occasions, JSON.stringify(order), (_e = (_d = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _d === void 0 ? void 0 : _d.customer) === null || _e === void 0 ? void 0 : _e.id]);
    if (order === null || order === void 0 ? void 0 : order.orderNumber) {
        const { billingAddress, customerCanRegister } = order;
        const orderNumbers = (_f = order === null || order === void 0 ? void 0 : order.consignments) === null || _f === void 0 ? void 0 : _f.map((consignment) => consignment.orderNumber);
        const successMessage = success === 'occasion-reminders' ? (_jsx(_Fragment, { children: _jsxs("p", { children: ["Your reminder has been successfully set. ", _jsx(Link, { url: "/account", label: "Visit your account" }), " to see all your reminders."] }) })) : ('');
        if (!!success) {
            const offsetTop = containerRef.current ? containerRef.current.offsetTop - 300 : 0;
            window.scrollTo({ top: offsetTop });
        }
        return (_jsxs("div", { className: classes.orderConfirmationContainer, "data-testid": "order-confirmation", ref: containerRef, children: [!!success && _jsx(Alert, { type: "alertSuccess", message: successMessage, className: classes.alertMessage }), _jsx(Box, { sx: { display: { zero: 'none', xs: 'none', md: 'block' } }, children: _jsx("div", { className: classes.controls, children: _jsx(RoundButton, { ariaLabel: "print", label: "Print order", icon: "print", onClick: () => window.print() }) }) }), _jsx("h1", { className: classes.heading, children: isFlyingFlowers ? 'Order Placed' : 'Thank you for the order' }), _jsxs("p", { children: ["A copy of your order details has been sent to ", billingAddress.email, " "] }), _jsxs("div", { className: classes.contentContainer, children: [_jsxs("div", { className: classes.orderSummary, children: [_jsx("div", { className: clsx(classes.active, classes.attached), children: _jsxs("div", { className: classes.orderSummaryHeading, children: [_jsx("div", { className: clsx(classes.sectionTitle, classes.noMargin), children: "Order summary" }), _jsx("div", { children: _jsx(Link, { className: classes.viewDetails, label: "View full order details", onClick: () => {
                                                        var _a;
                                                        (_a = document.getElementById('checkout-gift-summary')) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                                                    }, noFollow: true }) })] }) }), _jsx("div", { className: clsx(classes.active, classes.fullSpacing), children: _jsxs("ul", { className: classes.definitionList, children: [_jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Order number:" }), _jsx("div", { className: classes.orderNumbers, children: orderNumbers.map((orderNumber, index) => (_jsx(Link, { className: classes.viewDetails, label: `${orderNumber}`, onClick: () => {
                                                                var _a;
                                                                (_a = document.getElementById(`order_${orderNumber}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                                                            }, noFollow: true }, index))) })] }), createdAt && (_jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Date placed:" }), _jsx("div", { children: format(new Date(createdAt), 'EEEE do MMMM yyy') })] })), _jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Placed by:" }), _jsx("div", { children: `${billingAddress.firstName} ${billingAddress.lastName}`.trim() })] }), _jsxs("li", { children: [_jsx("div", { className: classes.highlight, children: "Order total:" }), _jsx("div", { className: classes.highlight, children: order.totalPrice.formatted })] })] }) })] }), customerCanRegister && order.id && (_jsx("div", { children: _jsx(ConfirmationRegistration, { orderId: order.id, onSubmit: onSubmit, consignmentReminders: getConsignmentReminders(order, occasions), getAvailableReminders: getAvailableReminders, handleSaveOccasionReminder: handleSaveOccasionReminder, remindersToDisplay: remindersToDisplay, saveUserJourneyAnalytics: saveUserJourneyAnalytics }) })), !customerCanRegister && order.id && !((_h = (_g = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _g === void 0 ? void 0 : _g.customer) === null || _h === void 0 ? void 0 : _h.id) && email && (_jsx("div", { children: _jsx(ConfirmationSignIn, { orderId: order.id, onSignIn: onSignIn, consignmentReminders: getConsignmentReminders(order, occasions), getAvailableReminders: getAvailableReminders, handleSaveOccasionReminder: handleSaveOccasionReminder, remindersToDisplay: remindersToDisplay, email: email, handleGeneratePassword: handleGeneratePassword, saveUserJourneyAnalytics: saveUserJourneyAnalytics }) })), !!((_k = (_j = getCustomer === null || getCustomer === void 0 ? void 0 : getCustomer.data) === null || _j === void 0 ? void 0 : _j.customer) === null || _k === void 0 ? void 0 : _k.id) &&
                            !!(remindersToDisplay === null || remindersToDisplay === void 0 ? void 0 : remindersToDisplay.length) &&
                            isShowReminderSummaryCard &&
                            !success && (_jsx(RemindersSummary, { handleSaveOccasionReminder: handleSaveOccasionReminder, occasionRemindersLists: remindersToDisplay })), _jsx("div", { id: "checkout-gift-summary", children: _jsx(OrderConfirmationGiftSummary, { order: order }) })] }), _jsx(Button, { title: "Continue shopping", fullWidth: true, variant: "secondary", href: "/", icon: isFlyingFlowers ? 'shopping_basket' : undefined })] }));
    }
    else {
        return _jsx("div", { children: "Sorry we Couldn't find the order" });
    }
};
export default OrderConfirmation;
