import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        gridTemplateColumns: 'unset',
        gridRowGap: 'unset',
        gridColumnGap: 0,
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: spacing.medium,
        },
    },
    noRemindersTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), (theme.isFlyingFlowers ? theme.typographySpacing.small_bold_lg : theme.typographySpacing.small_bold_sm)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, textAlign: theme.isFlyingFlowers ? 'center' : 'initial', marginBottom: theme.isFlyingFlowers ? spacing.xxs : 'initial', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.medium_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.small_bold_lg : theme.typographySpacing.medium_bold_sm)), { textAlign: 'initial', marginBottom: theme.isFlyingFlowers ? spacing.xs : 'initial' }) }),
    noReminderBody: Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : 'initial', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, theme.typography.small_regular), (theme.isFlyingFlowers ? theme.typographySpacing.small_regular_lg : theme.typographySpacing.none)), { [theme.breakpoints.up('md')]: {
            textAlign: 'initial',
        } }),
}));
export default useStyles;
