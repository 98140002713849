import { jsx as _jsx } from "react/jsx-runtime";
import Skeleton from 'react-loading-skeleton';
import useStyles from './SkeletonExperiment.styles';
const Box = ({ children, css }) => {
    const { classes } = useStyles({ css }); // Pass the css object directly to useStyles
    return _jsx("div", { className: classes.experimentSkeleton, children: children });
};
const SkeletonExperiment = (props) => {
    const { css } = props;
    return (_jsx(Box, { css: css, children: _jsx(Skeleton, { height: "100%" }) }));
};
export default SkeletonExperiment;
