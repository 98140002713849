import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import AddressCardGallery from '../AddressCardGallery/AddressCardGallery';
import RoundButton from '../RoundButton/RoundButton';
import useStyles from './AddressBook.styles';
import Alert from '../Alert/Alert';
import { useRouter } from 'next/router';
const AddressBook = (props) => {
    const { classes } = useStyles();
    const { savedAddresses, success, removeAddressItem } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const router = useRouter();
    const successMessage = success === 'add-new-customer-address'
        ? 'Address has been saved'
        : success === 'delete-address' || success === 'edit-address'
            ? 'Your address book has been updated'
            : '';
    const addNewAddress = () => {
        router.push('/account/address-book/add-new-contact-address');
    };
    return (_jsxs(_Fragment, { children: [!!successMessage && _jsx(Alert, { type: "alertSuccess", message: successMessage, className: classes.alertMessage }), _jsxs("div", { className: classes.addressBookContainer, children: [_jsx("h1", { children: "Address book" }), _jsx("div", { className: classes.addressBookContentContainer, children: _jsx("div", { className: classes.addressContent, children: _jsx("p", { className: classes.message, children: (savedAddresses === null || savedAddresses === void 0 ? void 0 : savedAddresses.length)
                                    ? 'Manage your contacts and addresses here.'
                                    : 'Manage your contacts and addresses here, though it doesn’t look like you have saved any yet. Why not add one now - you could start with your normal billing address, or perhaps the delivery address of someone special?' }) }) }), _jsx("div", { className: classes.addAddressContainer, children: _jsx(RoundButton, { ariaLabel: "Add new address", icon: "add", label: "Add new address", onClick: addNewAddress, labelAlign: isFlyingFlowers ? 'bottom' : 'side' }) }), _jsx("div", { children: _jsx(AddressCardGallery, { savedAddresses: savedAddresses, removeAddressItem: removeAddressItem }) })] })] }));
};
export default AddressBook;
