import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import { processImageUrl } from '../../utils/image';
import useStyles from './FloristSpecialismContent.styles';
import { Box } from '@mui/material';
import Image from '../Image/Image';
const FloristSpecialismContent = (props) => {
    const { image, icon, title } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.floristSpecialismContainer, children: [_jsx(Box, { sx: { display: { zero: 'none', xs: 'none', md: 'block' } }, children: _jsxs("div", { className: classes.floristSpecialismContentContainer, children: [(image === null || image === void 0 ? void 0 : image.url) && (_jsx(Image, { className: classes.image, imageUrl: processImageUrl(image === null || image === void 0 ? void 0 : image.url, { w: 384, aspect: '16:10' }, ['$poi-square$']), altText: (image === null || image === void 0 ? void 0 : image.altText) || title || '', loading: "lazy" })), _jsxs("div", { className: classes.floristImageOverlay, children: [icon && _jsx(Icon, { icon: icon, fontSize: "small", className: classes.floristIcon }), title && _jsx("h3", { className: classes.floristSpecialismTitle, children: title })] })] }) }), _jsx(Box, { sx: { display: { sm: 'block', md: 'none' } }, children: _jsx("div", { className: classes.floristSpecialismContainer, children: _jsxs("div", { className: classes.floristSpecialsmTextContainer, children: [icon && _jsx(Icon, { icon: icon, fontSize: "small", className: classes.floristIcon }), title && _jsx("h3", { className: classes.floristSpecialismTitle, children: title })] }) }) })] }));
};
export default FloristSpecialismContent;
