import { makeStyles } from 'tss-react/mui';
import { colors, hideScrollbars, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    title: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), { color: colors.supplementary.black }), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg) }),
    alphabetList: Object.assign(Object.assign({ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }, hideScrollbars), { marginBottom: spacing.medium, [theme.breakpoints.up('sm')]: {
            flexFlow: 'row wrap',
            marginBottom: spacing.large,
        }, '& li': Object.assign(Object.assign({ border: `1px solid ${colors.grey.grey4}`, marginRight: '12px', marginBottom: '12px' }, theme.typography.medium_bold), { backgroundColor: colors.supplementary.white, padding: '0px !important' }) }),
    activeAlphabet: {
        backgroundColor: `${colors.primary.gold1} !important`,
    },
    disableAlphabet: {
        pointerEvents: 'none',
        border: `1px solid ${colors.grey.grey2} !important`,
        color: colors.grey.grey8,
    },
    isNotInView: {
        display: 'none !important',
    },
    alphabetButton: {
        padding: '10px 18px !important',
        cursor: 'pointer',
    },
}));
export default useStyles;
