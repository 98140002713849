import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import useStyles from './MegaNavMobile.styles';
import ProductCard from '../ProductCard/ProductCard';
const MobileNavProducts = (props) => {
    const { classes } = useStyles();
    const { productsList, isDigital, setBadgeLineHeight } = props;
    return (_jsx("ul", { className: clsx(classes.productCards, isDigital ? classes.idpProductsList : ''), children: productsList === null || productsList === void 0 ? void 0 : productsList.slice(0, 2).map((product, index) => (_jsx("li", { className: classes.productCard, children: _jsx(ProductCard, { product: product, cardDisplayType: "SIMPLE", hideBorder: true, index: index, setHeight: setBadgeLineHeight, isFromMegaNavMobile: true }) }, `${product.name}_${product.sku}`))) }));
};
export default MobileNavProducts;
