import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Form, Formik } from 'formik';
import useStyles from '../Checkout.styles';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { SiteContext } from '../../../utils/common';
import Select from '../../Select/Select';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTheme } from '@mui/material';
const validationOccasionSchemaConfig = Yup.object().shape({
    occasion: Yup.string().required('Please enter an occasion'),
});
const EditOccasion = ({ updateCheckout, setOpen, isOpen, initialValues }) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const { occasions = [] } = useContext(SiteContext);
    const theme = useTheme();
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    return (_jsx(Formik, { enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationOccasionSchemaConfig,
        onSubmit: updateCheckout, children: ({ handleChange, values, resetForm, isSubmitting }) => {
            const handleClose = () => {
                setOpen(false);
                resetForm();
            };
            return (_jsx(Modal, { open: isOpen, setOpen: setOpen, onClose: handleClose, title: "Select Occasion", fullScreen: !isLargerViewport, maxWidth: "md", children: _jsxs(Form, { className: clsx(classes.updateCheckoutForm, classes.occasionSelect), children: [_jsx(Select, { name: "occasion", label: "Occasion", placeholder: "Choose an occasion", items: occasions.map((occasion) => ({ value: occasion.label, label: occasion.label })), required: true, fullWidth: true, onChange: handleChange, value: values === null || values === void 0 ? void 0 : values.occasion }), _jsx("div", { className: classes.footer, children: _jsxs("div", { className: classes.occasionButtonGroup, children: [_jsx(Button, { onClick: handleClose, title: "Cancel", variant: "tertiary", thin: true, icon: isFlyingFlowers ? 'close' : '' }), _jsx(Button, { isSubmit: true, disabled: isSubmitting, title: "Save occasion", variant: "primary", thin: true, icon: isFlyingFlowers ? 'check' : '' })] }) })] }) }));
        } }));
};
export default EditOccasion;
