import AnalyticsContext from 'context/AnalyticsContext'
import { useContext } from 'react'

/** Custom hook for saving the analytics to know the location where the user has provided the personal message. */
export const useSendPersonalMessageLocationToAnalytics = () => {
  const analytics = useContext(AnalyticsContext)
  const sendPersonalMessageLocationToAnalytics = (location) => {
    analytics.personalMessageLocation(location)
  }
  return { sendPersonalMessageLocationToAnalytics }
}
