import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Image from '../Image/Image';
import useStyles from './ContentBlock.styles';
import { processImageUrl } from '../../utils/image';
const ContentBlock = (props) => {
    const { image, description, classNames, title } = props;
    const { classes } = useStyles();
    const maxImageWidth = props.maxImageWidth || 224;
    return (_jsxs("div", { className: clsx(classes.boxContainer, classNames === null || classNames === void 0 ? void 0 : classNames.container), children: [image && (_jsx("div", { children: _jsx(Image, { className: clsx(classes.image, classNames === null || classNames === void 0 ? void 0 : classNames.imageContent), imageUrl: processImageUrl(image.url, { w: maxImageWidth }, ['$poi-square$']), altText: image.altText, width: maxImageWidth, height: maxImageWidth, loading: "lazy" }) })), _jsxs("div", { className: clsx(classes.body, classNames === null || classNames === void 0 ? void 0 : classNames.messageContent), children: [title && _jsx("span", { className: classNames === null || classNames === void 0 ? void 0 : classNames.title, children: title }), description && _jsx("span", { className: clsx(classes.description, classNames === null || classNames === void 0 ? void 0 : classNames.description), children: description })] })] }));
};
export default ContentBlock;
