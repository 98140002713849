import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        backgroundColor: colors.grey.grey1,
        display: 'flex',
        alignItems: 'center',
        padding: '14px 20px',
        [`& .${classes.deliveryPassText}, & .${classes.linkText}`]: Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none),
        marginBottom: spacing.small,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xs,
        },
    },
    deliveryPassText: {},
    icon: {
        marginRight: '12px',
    },
    linkText: Object.assign(Object.assign({ color: colors.primary.gold5 }, link(colors.primary.gold4)), { marginLeft: spacing.xs }),
}));
export default useStyles;
