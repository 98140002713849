import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import format from 'date-fns/format';
import { filterSelectedDates, SiteContext } from '../../utils/common';
import qs from 'qs';
import DatePicker from '../DatePicker/DatePicker';
const DateSearchModal = (props) => {
    const { isOpen, setOpen, postalCode, city, county } = props;
    const { getFormattedAddress, calendarDates, isFlyingFlowers = false, deliverySettings } = useContext(SiteContext);
    const router = useRouter();
    const submitForm = (date) => {
        const qsObj = { date: format(date, 'yyyy-MM-dd'), postalCode, city, county };
        router
            .push(`/search?${qs.stringify(qsObj, { encodeValuesOnly: true })}`)
            .then(() => { })
            .catch(() => { });
    };
    const { bankHolidays, availableSundays } = calendarDates || {};
    const filterDate = useCallback((date) => {
        return filterSelectedDates(date, isFlyingFlowers, bankHolidays, availableSundays, deliverySettings);
    }, [availableSundays, bankHolidays, deliverySettings, isFlyingFlowers]);
    return !getFormattedAddress ? null : (_jsx(DatePicker, { minDate: new Date(), isOpen: isOpen, setOpen: setOpen, datePickerTitle: "Choose your delivery date", setValue: (date) => submitForm(date), hideInput: true, confirmButtonLabel: "Search for available gifts", hideCancelButton: true, filterDate: filterDate }));
};
export default DateSearchModal;
