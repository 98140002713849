import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    pageHeaderSkeletonCard: {
        height: theme.isFlyingFlowers ? 500 : 538,
        [theme.breakpoints.up('sm')]: {
            '@media(orientation: landscape)': {
                height: theme.isFlyingFlowers ? 588 : 509,
            },
            '@media(orientation: portrait)': {
                height: theme.isFlyingFlowers ? 520 : 425,
            },
        },
        [theme.breakpoints.up('md')]: {
            height: theme.isFlyingFlowers ? 700 : 509,
            marginBottom: spacing.large,
        },
        [theme.breakpoints.up('lg')]: {
            height: theme.isFlyingFlowers ? 712 : 638,
            marginBottom: spacing.large,
        },
        [theme.breakpoints.up('xl')]: {
            height: theme.isFlyingFlowers ? 940 : 860,
            marginBottom: spacing.large,
        },
        backgroundColor: colors.grey.grey1,
        marginBottom: spacing.xl,
    },
}));
export default useStyles;
