import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    addressBookContainer: {
        width: '100%',
        '& h1': Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), theme.typographySpacing.xl_regular_sm), { textAlign: theme.isFlyingFlowers ? 'center' : 'unset', textTransform: theme.isFlyingFlowers ? 'uppercase' : 'unset', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), theme.typographySpacing.xxxl_sm) }),
    },
    addressBookContentContainer: {
        display: theme.isFlyingFlowers ? 'block' : 'grid',
        gridTemplateColumns: theme.isFlyingFlowers ? 'unset' : '100%',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: theme.isFlyingFlowers ? 'unset' : '50% 50%',
            width: theme.isFlyingFlowers ? '100%' : '',
        },
    },
    addressContent: {
        [`& .${classes.message}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_lg), { textAlign: theme.isFlyingFlowers ? 'center' : 'left', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_lg), color: colors.grey.grey6 }),
    },
    message: {},
    savedAddressTitle: {},
    noAddress: {},
    body: {},
    buttonContainer: {},
    addAddressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: theme.isFlyingFlowers ? 'center' : 'flex-start',
        marginBottom: '50px',
        [`& .${classes.buttonContainer}`]: {
            textAlign: 'center',
        },
    },
    addressesSection: {
        [`& .${classes.savedAddressTitle}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_regular_lg) }),
        [`& .${classes.noAddress}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm),
        [`& .${classes.body}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { color: colors.grey.grey6 }),
    },
    alertMessage: {
        marginBottom: spacing.large,
    },
}));
export default useStyles;
