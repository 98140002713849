declare global {
  interface Window {
    dataLayer: any
  }
}

const ifLogos = [
  '384x384',
  '48x48',
  '72x72',
  '96x96',
  '192x192',
  '16x16',
  '32x32',
  '512x512',
  '70x70',
  '144x144',
  '150x150',
  '310x310',
]

export const faviconObjects = ifLogos.map((value) => ({
  rel: 'icon',
  sizes: value,
  logoName: `favicon-${value}`,
}))
faviconObjects.push({
  rel: 'icon',
  sizes: '',
  logoName: 'favicon.ico',
})

const ifAppleLogos = ['60x60', '76x76', '120x120', '152x152', '180x180']

export const faviconAppleObjects = ifAppleLogos.map((value) => ({
  rel: 'apple-touch-icon',
  sizes: value,
  logoName: `favicon-${value}`,
}))

faviconAppleObjects.push(
  {
    rel: 'apple-touch-icon',
    sizes: '',
    logoName: 'apple-touch-icon',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '',
    logoName: 'safari-pinned-tab.svg',
  }
)
export type AlgoliaQueryType = 'PDP' | 'PLP' | 'Search' | 'Autocomplete' | 'GuidedNav'
export type ProductOrderFrom = { productName: string; productOrdered: string; lineItem: string }
export const PRODUCTS_ORDERED_FROM_STORAGE_KEY = 'products_order_from'
