import { gql } from '@apollo/client'

const fragmentCategoryWithAncestors = gql`
  fragment FragmentCategoryWithAncestors on Category {
    id
    key
    name
    url
    ancestors {
      id
      key
      name
      url
    }
  }
`
const fragmentCategory = gql`
  fragment FragmentCategory on Category {
    id
    key
    name
    description
    metaDescription
  }
`
const fragmentBaseProduct = gql`
  fragment FragmentBaseProduct on Product {
    sku
    name
    url
    isOverseas
    isDropship
    isInternational
    price {
      formatted
      centAmount
    }
    images {
      url
    }
    description
    attributes {
      name
      value
    }
    variants {
      isMaster
      attributes {
        name
        value
      }
    }
  }
`

const fragmentComponent = gql`
  fragment FragmentComponent on Component {
    uiType
    meta {
      deliveryId
      deliveryKey
    }
    data
  }
`

export const GET_OUTLINE_RENDER_GRAPH = gql`
  query ($input: OutlineRenderGraphInput!) {
    outlineRenderGraph(input: $input) {
      lang
      currency
      site {
        deliverySettings {
          sameDayCutoff
          deliveryHours
          deliveryCharge
          collectionHours
        }
        occasions {
          label
          roseId
          isPostcodeChecker
          occasionReminders {
            isActive
            isPredeterminedDate
            preDeterminedOccasionDate
            occasionIcon
            categoryLinkTitle
            occasionCategoryUrl
          }
        }
        trustpilotBusinessUnitId
        lang
        locale
        currency
        shorthand
        countryCode
        calendarDates
        overseasCountryCode
        canonicalHostname
        imageUrlPrefix
        customerSupportEmail
        isKlarnaEnabled
      }
      page {
        components {
          header {
            ...FragmentComponent
          }
          footer {
            ...FragmentComponent
          }
        }
      }
      urlInfo {
        canonical
        alternate {
          hreflang
          href
        }
      }
    }
  }
  ${fragmentComponent}
`

export const GET_OUTLINE_PRIVACY_POLICY_RENDER_GRAPH = gql`
  query ($input: OutlineRenderGraphInput!) {
    outlineRenderGraph(input: $input) {
      lang
      currency
      site {
        deliverySettings {
          sameDayCutoff
          deliveryHours
          deliveryCharge
          collectionHours
        }
        occasions {
          label
          roseId
          isPostcodeChecker
          occasionReminders {
            isActive
            isPredeterminedDate
            preDeterminedOccasionDate
            occasionIcon
            categoryLinkTitle
            occasionCategoryUrl
          }
        }
        trustpilotBusinessUnitId
        lang
        locale
        currency
        shorthand
        countryCode
        overseasCountryCode
        privacyPolicy {
          version
          content
        }
        canonicalHostname
        imageUrlPrefix
        customerSupportEmail
      }
      page {
        components {
          header {
            ...FragmentComponent
          }
          footer {
            ...FragmentComponent
          }
        }
      }
      urlInfo {
        canonical
        alternate {
          hreflang
          href
        }
      }
    }
  }
  ${fragmentComponent}
`

export const GET_RENDER_GRAPH = gql`
  query ($input: RenderGraphInput!) {
    renderGraph(input: $input) {
      lang
      currency
      site {
        deliverySettings {
          sameDayCutoff
          deliveryHours
          deliveryCharge
          collectionHours
        }
        occasions {
          label
          roseId
          isPostcodeChecker
          isProductPageOccasion
          occasionReminders {
            isActive
            isPredeterminedDate
            preDeterminedOccasionDate
            occasionIcon
            categoryLinkTitle
            occasionCategoryUrl
          }
        }
        trustpilotBusinessUnitId
        lang
        locale
        currency
        shorthand
        countryCode
        overseasCountryCode
        canonicalHostname
        imageUrlPrefix
        customerSupportEmail
        calendarDates
        isKlarnaEnabled
      }
      page {
        centreContent
        seo {
          title
          metaDescription
          robotsControl
          lastModified
        }
        components {
          header {
            ...FragmentComponent
          }
          body {
            ...FragmentComponent
          }
          footer {
            ...FragmentComponent
          }
        }
      }
      subject {
        ... on ProductSubject {
          product {
            id
            name
            description
            metaDescription
            includeProductInSitemap
            categories {
              ...FragmentCategoryWithAncestors
            }
          }
        }
        ... on CategorySubject {
          category {
            ...FragmentCategory
          }
        }
      }
      urlInfo {
        canonical
        alternate {
          hreflang
          href
        }
      }
    }
  }
  ${fragmentCategory}
  ${fragmentCategoryWithAncestors}
  ${fragmentComponent}
`

export const GET_COMPONENT = gql`
  query ($input: ComponentCriteriaInput!) {
    component(input: $input) {
      uiType
      meta {
        deliveryId
        deliveryKey
      }
      data
    }
  }
`

const fragmentAddress = gql`
  fragment FragmentAddress on Address {
    id
    firstName
    lastName
    addressLine1
    addressLine2
    addressLine3
    postalCode
    city
    county
    country
    phone
    mobile
    email
    isDefaultAddress
    deliveryInstructionNote
    deliveryInstructionOption
  }
`

const fragmentBaseOrder = gql`
  fragment FragmentBaseOrder on BaseOrder {
    marketing {
      online
      post
      email
      sms
    }
    subtotal {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    deliveryPassType
    deliveryPassDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalDeliveryPassDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    deliveryCost {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalPrice {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalItems
    consignments {
      id
      isMaxQuantity
      isDigital
      isClickAndCollect
      cutOff {
        secondsRemaining
        day
        isInPast
      }
      rotation
      delivery {
        date
        address {
          addressLine1
          addressLine2
          addressLine3
          postalCode
          city
          county
          country
          id
        }
        serviceCode
        serviceDescription
        serviceType
        charge {
          formatted
        }
      }
      collection {
        date
        address {
          rotationWeight
          executingMemberNo
          latitude
          longitude
          distance
          openingTime
          closingTime
          countyName
          postCode
          countryType
          name
          addLine2
          town
          phoneNo
          custLatitude
          custLongitude
        }
      }
      items {
        lineItem {
          id
          sku
          productId
          product {
            key
            name
            countryCode
            isInternational
            isOverseas
            categoryNames
            isDropship
          }
          name
          url
          price {
            centAmount
            fractionDigits
            currencyCode
            formatted
          }
          quantity
          images {
            url
          }
          occasion
          cardMessage
          deliveryDate
          deliveryInstructionOption
          deliveryInstructionNote
          specialInstructions
          deceasedName
          funeralServiceDate
          funeralServiceTime
          promotionalText
          attributes {
            name
            value
          }
          category {
            name
            ancestors {
              name
            }
          }
        }
        finishingTouchLineItems {
          id
          sku
          productId
          isGiftItem
          product {
            key
            name
            categoryNames
          }
          name
          url
          price {
            centAmount
            fractionDigits
            currencyCode
            formatted
          }
          quantity
          images {
            url
          }
          category {
            name
            ancestors {
              name
            }
          }
        }
        recipient {
          firstName
          lastName
          phone
          isFuneralDirector
        }
      }
    }
    billingAddress {
      ...FragmentAddress
    }
    couponCode
    couponDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
  }
  ${fragmentAddress}
`
/*
This should be using BaseOrder but theres seems to be a bug in ApolloClient that its only
bringing back the props outside the fragment when it processes the response even though
all the values can be seen in Network tab. It works for Cart so suspect its related to
passing in inputs which cart query doesnt do.

  fragment FragmentOrder on Order {
    customerCanRegister
    orderNumber
    createdAt
    ...FragmentBaseOrder
  }
  ${fragmentBaseOrder}
*/
const fragmentOrder = gql`
  fragment FragmentOrder on Order {
    customerCanRegister
    orderNumber
    orderStatus
    createdAt
    isFarEye
    isClickAndCollect
    orderStatusMessage
    orderStatusIconColor
    orderStatusStep
    marketing {
      online
      post
      email
      sms
    }
    subtotal {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    deliveryPassType
    deliveryPassDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalDeliveryPassDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    deliveryCost {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalPrice {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    totalItems
    consignments {
      id
      isMaxQuantity
      isDigital
      isClickAndCollect
      collection {
        date
        address {
          distance
          openingTime
          closingTime
          countyName
          postCode
          name
          addLine2
          town
          phoneNo
        }
        standardRuleId
      }
      cutOff {
        secondsRemaining
        day
        isInPast
      }
      orderTracking {
        statusEvent
        statusDateTime
        deliveryReason
        deliveryNote
        image
        trackingUrl
      }
      orderNumber
      orderStatus
      rotation
      delivery {
        date
        address {
          addressLine1
          addressLine2
          addressLine3
          postalCode
          city
          county
          country
        }
        serviceDescription
        charge {
          formatted
        }
      }
      collection {
        date
        address {
          rotationWeight
          executingMemberNo
          latitude
          longitude
          distance
          openingTime
          closingTime
          countyName
          postCode
          countryType
          name
          addLine2
          town
          phoneNo
          custLatitude
          custLongitude
        }
      }
      items {
        lineItem {
          id
          attributes {
            name
            value
          }
          sku
          productId
          product {
            key
            name
            categoryNames
          }
          name
          url
          price {
            centAmount
            fractionDigits
            currencyCode
            formatted
          }
          quantity
          images {
            url
          }
          occasion
          cardMessage
          deliveryInstructionOption
          deliveryInstructionNote
          specialInstructions
          deliveryDate
          deceasedName
          funeralServiceDate
          funeralServiceTime
          promotionalText
          category {
            name
            ancestors {
              name
            }
          }
        }
        finishingTouchLineItems {
          id
          sku
          productId
          product {
            key
            name
            categoryNames
          }
          name
          url
          price {
            centAmount
            fractionDigits
            currencyCode
            formatted
          }
          quantity
          images {
            url
          }
          category {
            name
            ancestors {
              name
            }
          }
        }
        recipient {
          firstName
          lastName
          phone
        }
      }
    }
    billingAddress {
      ...FragmentAddress
    }
    couponCode
    couponDiscount {
      centAmount
      fractionDigits
      currencyCode
      formatted
    }
    isLegacy
  }
  ${fragmentAddress}
`

export const GET_ORDER = gql`
  query ($input: String!) {
    order(id: $input) {
      id
      ...FragmentOrder
    }
  }
  ${fragmentOrder}
`
export const GET_HISTORIC_ORDERS = gql`
  query ($input: HistoricOrdersInput) {
    historicOrders(input: $input) {
      orders {
        ...FragmentOrder
      }
      isLastPage
    }
  }
  ${fragmentOrder}
`

const fragmentCart = gql`
  fragment FragmentCart on Cart {
    id
    maxCheckoutStep
    worldpayRedirectUrl
    paymentMethod
    unavailableItems {
      lineItemId
      name
      checkoutError
    }
    showMarketingPreferences
    cutOff {
      secondsRemaining
      day
      isInPast
    }
    ...FragmentBaseOrder
  }
  ${fragmentBaseOrder}
`

export const GET_CART = gql`
  query ($input: UpdatePaymentDetailsInput) {
    cart(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const GET_TOTAL_CONSIGNMENT_COUNT = gql`
  query {
    cart {
      totalConsignments
    }
  }
`

export const ADD_TO_CART = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      addedLineItemId
      consignmentLimitReached
      hasFinishingTouches
      cart {
        ...FragmentCart
      }
    }
  }
  ${fragmentCart}
`

export const CHECKOUT_STEP_1 = gql`
  mutation setCheckoutStep1($input: CheckoutStep1Input!) {
    setCheckoutStep1(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const CHECKOUT_STEP_2 = gql`
  mutation setCheckoutStep2($input: CheckoutStep2Input!) {
    setCheckoutStep2(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const COMPLETE_ORDER = gql`
  mutation completeOrder($input: CompleteOrderInput) {
    completeOrder(input: $input) {
      id
      ...FragmentOrder
    }
  }
  ${fragmentOrder}
`

export const SAVE_CARD_MESSAGE = gql`
  mutation saveCardMessage($input: UpdateLineItemCardMessageInput) {
    updateLineItemCardMessage(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const ADD_PROMO_CODE = gql`
  mutation addPromotionalCode($input: AddPromotionalCodeInput!) {
    addPromotionalCode(input: $input) {
      ...FragmentCart
      isSignIn
      isNewCustomer
    }
  }
  ${fragmentCart}
`

export const REMOVE_PROMO_CODE = gql`
  mutation removePromotionalCode {
    removePromotionalCode {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const RECHECK_CART_AVAILABILITY = gql`
  mutation recheckCartAvailability {
    recheckCartAvailability {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const UPDATE_LINE_ITEM_DELIVERY_DETAILS = gql`
  mutation updateLineItemDeliveryDetails($input: UpdateLineItemDeliveryDetailsInput!) {
    updateLineItemDeliveryDetails(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const GET_CUSTOMER = gql`
  query {
    customer {
      id
      firstName
      lastName
      customerNumber
      contactNumber
      email
      defaultShippingAddress {
        ...FragmentAddress
      }
      defaultBillingAddress {
        ...FragmentAddress
      }
      shippingAddresses {
        ...FragmentAddress
      }
      billingAddresses {
        ...FragmentAddress
      }
      optInEmail
      optInPost
      optInOnline
      optInSms
      marketingLastUpdatedAt
      deliveryPass {
        product {
          ...FragmentBaseProduct
        }
        type
        expiryDate
        isRenewable
        availableToPurchase {
          ...FragmentBaseProduct
        }
      }
    }
  }
  ${fragmentAddress}
  ${fragmentBaseProduct}
`

export const FORMAT_ADDRESS = gql`
  query ($input: FormatAddressInput!) {
    formatAddress(input: $input) {
      addressLine1
      addressLine2
      addressLine3
      city
      postalCode
      county
      country
    }
  }
`

export const IS_PRODUCT_FULFILLABLE = gql`
  query ($input: IsProductFulfillableInput!) {
    isProductFulfillable(input: $input)
  }
`

export const FORMATTED_ADDRESS_AND_PRODUCT_AVAILABILITY = gql`
  query ($input: FormattedAddressAndProductAvailabilityInput!) {
    formattedAddressAndProductAvailability(input: $input) {
      address {
        addressLine1
        addressLine2
        addressLine3
        city
        postalCode
        county
        country
      }
      availability {
        date
        deliveryServiceTypes {
          serviceCode
          ruleId
        }
      }
    }
  }
`

export const PRODUCT_AVAILABILITY = gql`
  query ($input: ProductAvailabilityInput!) {
    productAvailability(input: $input) {
      date
      deliveryServiceTypes {
        serviceCode
        ruleId
      }
    }
  }
`
export const GET_CC_PRODUCT_AVAILABILITY = gql`
  query ($input: CCProductAvailabilityInput) {
    getCCProductAvailability(input: $input) {
      ccAddress {
        rotationWeight
        executingMemberNo
        latitude
        longitude
        distance
        openingTime
        closingTime
        countyName
        postCode
        countryType
        name
        addLine2
        town
        phoneNo
      }
    }
  }
`

export const TOKEN_VALIDITY = gql`
  query ($token: String!) {
    isPasswordTokenValid(token: $token)
  }
`

export const SAVE_OCCASION = gql`
  mutation saveOccasion($input: UpdateLineItemOccasionInput) {
    updateLineItemOccasion(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const GENERATE_TOKEN = gql`
  mutation generateToken($input: GeneratePasswordTokenInput) {
    generatePasswordToken(input: $input)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      id
    }
  }
`

export const GET_DELIVERY_OPTIONS = gql`
  query ($input: DeliveryOptionsInput!) {
    deliveryOptions(input: $input) {
      title
      price {
        centAmount
        currencyCode
        fractionDigits
        formatted
      }
      serviceCode
      serviceType
      standardRuleId
      standardDeliveryCharge
      optionalRuleId
      optionalDeliveryCharge
      isIncludedInDeliveryPass
      isCourierOption
      deliveryPassPromotionText
      deliveryPassPromotionType
    }
  }
`

export const GET_ADDRESS_COUNTIES = gql`
  query ($input: GetAddressCountiesInput!) {
    getAddressCounties(input: $input)
  }
`

export const GET_ADDRESS_TOWNS = gql`
  query ($input: GetAddressTownsInput!) {
    getAddressTowns(input: $input) {
      locationName
      postalCode
    }
  }
`

export const UPDATE_CONTACT_NUMBER = gql`
  mutation ($input: UpdateContactNumberInput!) {
    updateContactNumber(input: $input) {
      firstName
      lastName
      email
      customerNumber
      contactNumber
      defaultBillingAddress {
        ...FragmentAddress
      }
    }
  }
  ${fragmentAddress}
`
export const UPDATE_NAME = gql`
  mutation ($input: UpdateNameInput) {
    updateName(input: $input) {
      firstName
      lastName
      email
      customerNumber
      contactNumber
      defaultBillingAddress {
        ...FragmentAddress
      }
    }
  }
  ${fragmentAddress}
`
export const UPDATE_BILLING_ADDRESS = gql`
  mutation ($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      firstName
      lastName
      email
      customerNumber
      contactNumber
      defaultBillingAddress {
        ...FragmentAddress
      }
    }
  }
  ${fragmentAddress}
`
export const UPDATE_CUSTOMER_PREFERENCES = gql`
  mutation ($input: UpdateMarketingPreferencesInput!) {
    updateMarketingPreferences(input: $input) {
      id
      optInEmail
      optInOnline
      optInPost
      optInSms
      marketingLastUpdatedAt
    }
  }
`

export const GIFT_FINDER_ROTATIONS = gql`
  query ($input: GiftFinderRotationSpecialIndicatorInput) {
    giftFinderRotations(input: $input) {
      rotation
      specialIndicators
    }
  }
`

export const GET_FINISHING_TOUCHES = gql`
  query ($input: String!) {
    finishingTouches(lineItemId: $input) {
      categoryName
      products {
        ...FragmentBaseProduct
      }
    }
  }
  ${fragmentBaseProduct}
`

export const SET_FINISHING_TOUCH_QUANTITY = gql`
  mutation ($input: SetFinishingTouchQuantityInput!) {
    setFinishingTouchQuantity(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const REMOVE_FROM_CART = gql`
  mutation ($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`
export const GET_HISTORIC_ORDER = gql`
  query ($input: HistoricOrderInput!) {
    historicOrder(input: $input) {
      ...FragmentOrder
    }
  }
  ${fragmentOrder}
`

export const PRIVACY_REQUEST_VERIFICATION_EMAIL = gql`
  mutation ($input: PrivacyHubRequestVerificationEmailInput!) {
    privacyHubRequestVerificationEmail(input: $input)
  }
`

export const PRIVACY_HUB_FETCH_PREFERENCES = gql`
  query ($input: PrivacyHubFetchPreferencesInput!) {
    privacyHubFetchPreferences(input: $input) {
      preferences {
        email
        sms
        post
        digitalMarketing
      }
      emailAddress
    }
  }
`

export const PRIVACY_HUB_SAVE_PREFERENCES = gql`
  mutation ($input: PrivacyHubSavePreferencesInput!) {
    privacyHubSavePreferences(input: $input)
  }
`
export const NEWSLETTER_SIGNUP = gql`
  mutation ($input: NewsLetterSubscriptionInput) {
    newsLetterSubscription(input: $input)
  }
`

export const ADD_DELIVERY_PASS_TO_CART = gql`
  mutation ($input: AddDeliveryPassToCartInput!) {
    addDeliveryPassToCart(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`

export const GET_DIGITAL_PRODUCTS = gql`
  query {
    digitalProducts {
      ...FragmentBaseProduct
    }
  }
  ${fragmentBaseProduct}
`
export const UPDATE_EMAIL_ADDRESS = gql`
  mutation ($input: UpdateEmailInput) {
    updateEmail(input: $input) {
      firstName
      lastName
      email
      customerNumber
      contactNumber
      defaultBillingAddress {
        ...FragmentAddress
      }
    }
  }
  ${fragmentAddress}
`
export const ADD_NEW_ADDRESS = gql`
  mutation ($input: AddAddressInput) {
    addAddress(input: $input) {
      addressId
      customer {
        id
        firstName
        lastName
        customerNumber
        contactNumber
        email
        defaultShippingAddress {
          ...FragmentAddress
        }
        shippingAddresses {
          ...FragmentAddress
        }
      }
    }
  }
  ${fragmentAddress}
`
export const DELETE_ADDRESS = gql`
  mutation ($input: RemoveAddressInput!) {
    removeAddress(input: $input) {
      id
    }
  }
`
export const GET_CUSTOMER_ADDRESS_ID = gql`
  query ($input: String!) {
    myAccountAddress(id: $input) {
      id
      addressLine1
      addressLine2
      addressLine3
      postalCode
      city
      county
      country
      phone
      mobile
      email
      firstName
      lastName
      deliveryInstructionOption
      deliveryInstructionNote
      isDefaultAddress
    }
  }
`
export const EDIT_CONTACT_ADDRESS = gql`
  mutation ($input: UpdateAddressInput) {
    updateAddress(input: $input) {
      id
    }
  }
`
export const SAVE_OCCASION_REMINDERS = gql`
  mutation addCustomerOccasionReminders($input: AddCustomerOccasionRemindersInput!) {
    addCustomerOccasionReminders(input: $input) {
      id
    }
  }
`

export const EDIT_OCCASION_REMINDER = gql`
  mutation updateCustomerOccasionReminder($input: OccasionReminderInput!) {
    updateCustomerOccasionReminder(input: $input) {
      id
    }
  }
`

export const DELETE_OCCASION_REMINDER = gql`
  mutation deleteCustomerOccasionReminder($input: String) {
    deleteCustomerOccasionReminder(id: $input) {
      id
    }
  }
`
export const GET_CUSTOMER_AVAILABLE_REMINDER_MONTHS = gql`
  query {
    getOccasionReminderAvailableMonths {
      availableMonths
      totalCount
      currentActiveMonth
    }
  }
`
export const GET_MY_ACCOUNT_REMINDERS_LIST = gql`
  query getMyOccasionReminders($input: RemindersInput) {
    getMyOccasionReminders(input: $input) {
      occasionReminders {
        id
        firstName
        lastName
        date
        occasion
      }
      isLastPage
      count
    }
  }
`
export const GET_EXISTS_REMINDERS_IN_ORDER_CONFIRMATION = gql`
  query occasionRemindersExist($input: AddCustomerOccasionRemindersInput) {
    occasionRemindersExist(input: $input) {
      occasionReminders {
        firstName
        lastName
        date
        occasion
        isAlreadyExist
      }
    }
  }
`

export const GET_ALL_COUNTRIES_LIST = gql`
  query {
    getAllCountriesList {
      countriesList {
        url
        label
      }
    }
  }
`
export const GET_DELIVERY_OPTIONS_OR_ALATERNATE_PRODUCTS = gql`
  query ($input: DeliveryOptionsOrAlternateProductAvailabiltyInput!) {
    getDeliveryOptionsOrAlternateProducts(input: $input) {
      totalProductsCount
      deliveryOptions {
        title
        price {
          centAmount
          currencyCode
          fractionDigits
          formatted
        }
        serviceCode
        serviceType
        standardRuleId
        standardDeliveryCharge
        optionalRuleId
        optionalDeliveryCharge
        isIncludedInDeliveryPass
        isCourierOption
        deliveryPassPromotionText
        deliveryPassPromotionType
      }
      productSuggestions {
        name
        sku
        slug
        url
        price {
          centAmount
          currencyCode
          fractionDigits
          formatted
        }
        images {
          url
          altText
          label
          badge {
            variant
            line1
            line2
            image {
              url
              altText
            }
            altText
            icon {
              url
              altText
            }
          }
          ffSalesBadge {
            backgroundColor
            contentLine1
            contentLine2
            largeFontCheck
          }
          secondaryBadge {
            title
            variant
          }
        }
      }
    }
  }
`
export const CHANGE_PAYMETHOD_TYPE = gql`
  mutation setPaymentMethod($input: SetPaymentMethodInput!) {
    setPaymentMethod(input: $input) {
      ...FragmentCart
    }
  }
  ${fragmentCart}
`
export const CHECKOUT_DELIVERY_PASS_PROMO = gql`
  query ($input: [CheckOutDeliveryPassPromotionInput!]) {
    checkOutDeliveryPassPromotion(input: $input) {
      deliveryPassPromotionText
      deliveryPassPromotionType
    }
  }
`
