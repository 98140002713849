var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Popper, CircularProgress, useTheme, TextField, Autocomplete } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import axios from 'axios';
import clsx from 'clsx';
import { FormikContext, useField } from 'formik';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _without from 'lodash/without';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { billingAddressCountries } from '../../utils/billingAddressCountries';
import { CountryCodes, ExperianAltDatasetMapping, getCountryFromAlpha2Code, getCountryFromAlpha3Code, showValidationMeta, SiteContext, } from '../../utils/common';
import AddressForm from '../AddressForm/AddressForm';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import useStyles from './AddressLookup.styles';
const AddressLookupWrapper = (props) => {
    // check if formik is available
    const formikContext = useContext(FormikContext);
    return !!formikContext ? (_jsx(FormikAddressLookup, Object.assign({}, props, { formikContext: formikContext }))) : (_jsx(AddressLookup, Object.assign({}, props)));
};
const FormikAddressLookup = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const validation = showValidationMeta(meta);
    return _jsx(AddressLookup, Object.assign({ setAddressValue: helpers.setValue }, field, props, validation));
};
const AddressLookup = (_a) => {
    var _b, _c, _d, _e;
    var { id, name, label, fullWidth, maxResults, required, placeholder, value, completed, completedDefault, disabled, state, fieldContainer = '', formikContext, addressFields, hideRequiredOptional, setAddressValue, onChange, onBlur, onClick, onFocus, provideManualAddress, setManualAddressChosen, handleAddressFormatCall, handleSaveManualAddressCallback, handleClearAddress, manualAddressTitle, className, boundariesElement, initialValue, countryCode, addressLookupOnRender, countryList, isFromPCC, setCurrentStep, hasVariants, hasClickAndCollectDeliveryType, getAddressLatLongDetails } = _a, textInputProps = __rest(_a, ["id", "name", "label", "fullWidth", "maxResults", "required", "placeholder", "value", "completed", "completedDefault", "disabled", "state", "fieldContainer", "formikContext", "addressFields", "hideRequiredOptional", "setAddressValue", "onChange", "onBlur", "onClick", "onFocus", "provideManualAddress", "setManualAddressChosen", "handleAddressFormatCall", "handleSaveManualAddressCallback", "handleClearAddress", "manualAddressTitle", "className", "boundariesElement", "initialValue", "countryCode", "addressLookupOnRender", "countryList", "isFromPCC", "setCurrentStep", "hasVariants", "hasClickAndCollectDeliveryType", "getAddressLatLongDetails"]);
    const [height, setHeight] = useState();
    const { classes } = useStyles({ height });
    const containerRef = React.useRef(null);
    const addressRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const [addressResponse, setAddressResponse] = useState([]);
    const [resultsOpen, setResultsOpen] = useState(false);
    const [optionSelectedState, setOptionSelectedState] = useState(false);
    const optionSelected = formikContext ? formikContext.values.optionSelected : optionSelectedState;
    const setOptionSelected = (isSelected) => formikContext ? formikContext.setFieldValue('optionSelected', isSelected) : setOptionSelectedState(isSelected);
    const [loading, setLoading] = useState(false);
    const authTokenHeader = {
        headers: {
            'Auth-Token': process.env.NEXT_PUBLIC_ADDRESS_LOOKUP_AUTH_TOKEN,
        },
    };
    const fieldId = id || name;
    const theme = useTheme();
    const { countryCode: siteCountryCode } = useContext(SiteContext);
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const [manualAddressOpen, setManualAddressOpen] = useState(false);
    const [isManualAddress, setManualAddress] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [onLoadCheck, setOnLoadCheck] = useState(true);
    const fieldError = formikContext === null || formikContext === void 0 ? void 0 : formikContext.errors[fieldId];
    const { [fieldId]: fieldIdValue = '', originalAddressSelection } = (_b = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) !== null && _b !== void 0 ? _b : {};
    const showFieldError = fieldError && ((formikContext === null || formikContext === void 0 ? void 0 : formikContext.touched[fieldId]) || (fieldContainer && (formikContext === null || formikContext === void 0 ? void 0 : formikContext.touched[fieldContainer])));
    const componentHasRendered = useRef(false);
    const handleAddressFormat = (addressResponse) => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        const { suggestion, format } = addressResponse;
        try {
            setLoading(true);
            const addressFields = yield handleAddressFormatCall(suggestion, format);
            if (addressFields) {
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(textFieldName('shouldValidatePostCode'), ((_f = getCountryFromAlpha3Code(countryCode)) === null || _f === void 0 ? void 0 : _f.name) === CountryCodes.UNITED_KINDOM);
                Object.entries(addressFields).forEach(([addressField, addressValue]) => {
                    const locatedFieldName = fieldContainer ? `${fieldContainer}.${addressField}` : addressField;
                    formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(locatedFieldName, addressValue);
                    formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(locatedFieldName, true);
                    if (fieldContainer) {
                        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(fieldContainer, true);
                    }
                });
                setAddressValue ? setAddressValue(suggestion) : onChange && onChange(suggestion);
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('originalAddressSelection', suggestion);
                hasClickAndCollectDeliveryType && (getAddressLatLongDetails === null || getAddressLatLongDetails === void 0 ? void 0 : getAddressLatLongDetails(addressFields));
                if (isFromPCC && setCurrentStep)
                    setCurrentStep();
            }
            else {
                setInputValue('');
                throw new Error('Address formatting call empty');
            }
        }
        catch (error) {
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldError(fieldId, 'Error selecting address');
        }
        finally {
            setLoading(false);
            setResultsOpen(false);
            setAddressResponse([]);
        }
    });
    const requestAddressResponse = (query) => __awaiter(void 0, void 0, void 0, function* () {
        var _g;
        const alpha3CountryCode = countryCode || getCountryFromAlpha2Code(siteCountryCode || CountryCodes.UNITED_KINDOM).codeAlpha3;
        const altDataset = ExperianAltDatasetMapping[(_g = getCountryFromAlpha3Code(alpha3CountryCode)) === null || _g === void 0 ? void 0 : _g.codeAlpha2];
        const queryInput = query && query.replace(/&/, '');
        try {
            const response = yield axios.get(`${process.env.NEXT_PUBLIC_ADDRESS_LOOKUP_URL}?query=${encodeURIComponent(queryInput)}${altDataset ? `&dataset=${altDataset}` : ''}&take=${maxResults || 100}&country=${alpha3CountryCode}`, authTokenHeader);
            const results = _get(response, 'data.results', []);
            if (initialValue && query === initialValue && results.length) {
                setOptionSelected(true);
                handleAddressFormat(results[0]);
            }
            else if (results.length) {
                setAddressResponse(results);
                setResultsOpen(true);
            }
        }
        catch (error) {
            console.error('Error with Address lookup', error);
        }
    });
    const debouncedLookup = useMemo(() => _debounce((query) => requestAddressResponse(query), 300), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]);
    useEffect(() => {
        return () => {
            debouncedLookup.cancel();
        };
    }, [debouncedLookup]);
    useEffect(() => {
        var _a, _b;
        if ((_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.showManualAddress) {
            (_b = addressRef === null || addressRef === void 0 ? void 0 : addressRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
        }
    }, [(_c = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _c === void 0 ? void 0 : _c.showManualAddress]);
    useEffect(() => {
        const cleanValue = value ? value.replace(/(\r\n|\n|\r)/gm, ' ') : '';
        if (!isManualAddress && typeof value == 'string' && cleanValue.length > 2 && !optionSelected) {
            debouncedLookup(cleanValue);
        }
        else if (optionSelected) {
            setResultsOpen(false);
        }
        else if (!cleanValue) {
            setAddressResponse([]);
        }
        if (!componentHasRendered.current) {
            componentHasRendered.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, optionSelected]);
    useEffect(() => {
        var _a;
        if (onLoadCheck) {
            onLoadCheck && setOnLoadCheck(false);
            return;
        }
        const isValid = optionSelected && originalAddressSelection;
        if (isValid) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldError(fieldId, '');
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.validateForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsOpen, originalAddressSelection, optionSelected, fieldContainer, fieldIdValue]);
    useEffect(() => {
        setInputValue('');
    }, [countryCode]);
    const textFieldName = (addressLine) => (fieldContainer ? `${fieldContainer}.${addressLine}` : addressLine);
    const ManualAddress = () => {
        const addressResultCount = addressResponse.length;
        const message = addressResultCount
            ? addressResultCount > 1
                ? `Searching ${addressResultCount} addresses`
                : `Searching 1 address`
            : 'Search for an address';
        return (_jsxs("div", { className: clsx(classes.manualAddress, addressResponse.length && classes.resultsShown), children: [message, " or", ' ', _jsx("button", { className: clsx(classes.manualEntryButton), onClick: handleOpenManualAddress, children: "enter manually" })] }));
    };
    const clearHiddenFields = (ignoreCountry) => {
        addressFields.forEach((fieldName) => {
            if (fieldName === 'country' && ((countryList && countryList.length === 1) || ignoreCountry)) {
                return;
            }
            const locatedFieldName = fieldContainer ? `${fieldContainer}.${fieldName}` : fieldName;
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(locatedFieldName, '', false);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(locatedFieldName, false, false);
            if (fieldContainer) {
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(fieldContainer, false, false);
            }
        });
        handleClearAddress && handleClearAddress();
    };
    const handleAddressSelection = (addressResponse) => {
        setManualAddressChosen && setManualAddressChosen(false);
        if (addressResponse) {
            setOptionSelected(true);
            handleAddressFormat(addressResponse);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldError(fieldId, '');
        }
        else {
            clearHiddenFields();
            setAddressResponse([]);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(fieldId, '');
        }
    };
    const handleOnFocus = (e) => {
        setResultsOpen(true);
        onFocus && onFocus(e);
    };
    const getPropertyValues = (currentObject) => {
        return currentObject ? (fieldContainer ? currentObject[fieldContainer] : currentObject) : [];
    };
    const initiateManualAddressCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        if (handleSaveManualAddressCallback) {
            try {
                setLoading(true);
                yield handleSaveManualAddressCallback();
            }
            catch (error) {
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldError(fieldId, 'Error saving manual address');
            }
            finally {
                setLoading(false);
            }
        }
        setManualAddressOpen(false);
        setManualAddress(true);
        setResultsOpen(false);
        if (isFromPCC && setCurrentStep)
            setCurrentStep();
    });
    const handleSaveManualAddress = () => {
        const touchedObject = {};
        let fieldNamesMapped = [];
        const formikErrors = getPropertyValues(formikContext === null || formikContext === void 0 ? void 0 : formikContext.errors);
        const formikValues = getPropertyValues(formikContext === null || formikContext === void 0 ? void 0 : formikContext.values);
        addressFields.forEach((fieldName) => {
            _set(touchedObject, `${fieldContainer ? `${fieldContainer}.` : ''}${fieldName}`, true);
            fieldNamesMapped.push(fieldName);
        });
        formikContext === null || formikContext === void 0 ? void 0 : formikContext.setTouched(touchedObject, true);
        const addressErrors = formikErrors
            ? Object.entries(formikErrors).filter(([key]) => fieldNamesMapped.includes(key))
            : [];
        if (isFromPCC) {
            fieldNamesMapped = _without(fieldNamesMapped, 'county', 'country') || [];
        }
        if (!addressErrors.length) {
            const addressFieldList = fieldNamesMapped
                .map((fieldValue) => {
                var _a;
                return fieldValue === 'country'
                    ? (_a = getCountryFromAlpha2Code(formikValues[fieldValue])) === null || _a === void 0 ? void 0 : _a.name
                    : formikValues[fieldValue];
            })
                .filter((item) => !!item)
                .join(', ');
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('originalAddressSelection', addressFieldList);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue(fieldId, addressFieldList);
            setInputValue(addressFieldList);
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('showManualAddress', false);
            initiateManualAddressCallback();
            hasClickAndCollectDeliveryType && (getAddressLatLongDetails === null || getAddressLatLongDetails === void 0 ? void 0 : getAddressLatLongDetails(formikContext === null || formikContext === void 0 ? void 0 : formikContext.values));
            setTimeout(() => {
                var _a;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, 300);
        }
    };
    const handleOpenManualAddress = () => {
        setManualAddressChosen && setManualAddressChosen(true);
        setResultsOpen(false);
        if (isFromPCC) {
            formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('showManualAddress', true);
        }
        else {
            setManualAddressOpen(true);
        }
    };
    const handleCancelManualAddress = () => {
        setManualAddressOpen(false);
        setManualAddressChosen && setManualAddressChosen(false);
        setManualAddress(false);
        setResultsOpen(false);
    };
    const handleTextFieldChange = (e) => {
        setOptionSelected(false);
        setManualAddress(false);
        onChange && onChange(e);
        setInputValue(e.target.value);
    };
    useEffect(() => {
        var _a;
        const modalContent = document.querySelector('.MuiDialogContent-root');
        const modalContentHeight = modalContent === null || modalContent === void 0 ? void 0 : modalContent.clientHeight;
        if (!hasVariants && modalContentHeight && resultsOpen && !((_a = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _a === void 0 ? void 0 : _a.showManualAddress)) {
            let scale = isLargerViewport ? 0.33 : null;
            if (scale)
                setHeight(modalContentHeight * scale);
        }
        const outsideClick = (event) => {
            var _a;
            if (resultsOpen && !((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                setResultsOpen(false);
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(name, true);
            }
        };
        document.body.addEventListener('click', outsideClick);
        return () => {
            document.body.removeEventListener('click', outsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsOpen]);
    useEffect(() => {
        const outsideFocus = (event) => {
            var _a;
            if (resultsOpen && event.relatedTarget && !((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
                setResultsOpen(false);
                formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldTouched(name, true);
            }
        };
        const ref = containerRef.current;
        if (isLargerViewport) {
            ref === null || ref === void 0 ? void 0 : ref.addEventListener('focusout', outsideFocus);
            return () => {
                ref === null || ref === void 0 ? void 0 : ref.removeEventListener('focusout', outsideFocus);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef, resultsOpen]);
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: manualAddressOpen, onClose: handleCancelManualAddress, keepMounted: false, setOpen: () => setManualAddressOpen(true), title: manualAddressTitle ? manualAddressTitle : 'Recipient address', disablePortal: false, fullScreen: !isLargerViewport, children: _jsx(AddressForm, { fieldName: fieldContainer, handleSave: handleSaveManualAddress, handleCancel: handleCancelManualAddress, handleCountryChange: () => clearHiddenFields(true), countryList: countryList || billingAddressCountries, loading: loading }) }), _jsxs("div", { className: clsx(classes.textFieldWrapper, fullWidth ? classes.fullWidth : ''), children: [((_d = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _d === void 0 ? void 0 : _d.showManualAddress) ? (_jsxs("div", { ref: isFromPCC ? null : addressRef, children: [!isFromPCC ? _jsx("div", { className: classes.recipientAddressLabel, children: "Recipient address" }) : null, _jsx(AddressForm, { fieldName: fieldContainer, handleSave: handleSaveManualAddress, handleCancel: () => {
                                    formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('isManualAddress', false);
                                    formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue('showManualAddress', false);
                                }, handleCountryChange: () => clearHiddenFields(true), countryList: countryList || billingAddressCountries, loading: loading })] })) : (_jsx("div", Object.assign({ ref: containerRef, className: clsx(classes.textField, state === 'error' || !!showFieldError ? classes.error : '', state === 'success' ? classes.success : '', disabled || loading ? classes.disabled : '', completed ? classes.completed : '', value ? classes.touched : '') }, (completed && { role: 'button', onClick }), { children: completed ? (_jsxs(_Fragment, { children: [_jsx("span", { className: classes.textContent, children: value || completedDefault }), _jsx(Icon, { icon: "create", fontSize: "inherit", className: classes.editIcon })] })) : (_jsx(Autocomplete, { id: fieldId, open: resultsOpen, value: value || inputValue, freeSolo: true, onChange: (_, v) => {
                                handleAddressSelection(v);
                            }, onFocus: handleOnFocus, getOptionLabel: (option) => option.suggestion || value || '', loading: loading, disabled: loading, options: addressResponse, classes: {
                                popupIndicator: classes.hasPopupIcon,
                                root: classes.root,
                                endAdornment: classes.endAdornment,
                            }, filterOptions: (x) => x, clearOnBlur: true, PopperComponent: (props) => (_jsx(Popper, Object.assign({}, props, { className: clsx(classes.popper, (provideManualAddress || addressResponse.length) && classes.popperOpen, className !== null && className !== void 0 ? className : ''), disablePortal: true, placement: isLargerViewport ? 'top' : 'bottom', modifiers: [
                                    { name: 'flip', enabled: true },
                                    { name: 'preventOverflow', enabled: true, boundariesElement: boundariesElement || 'viewport' },
                                ], anchorEl: containerRef.current }))), PaperComponent: ({ children }) => (_jsxs(Paper, { className: classes.paper, square: true, children: [children, !!provideManualAddress && _jsx(ManualAddress, {})] })), noOptionsText: "No addresses were found", renderOption: (props, option, { inputValue }) => {
                                const matches = match(option.suggestion, inputValue);
                                const parts = parse(option.suggestion, matches);
                                const _a = props, { key } = _a, rest = __rest(_a, ["key"]);
                                return (_jsx("li", Object.assign({}, rest, { className: classes.option, children: _jsx("div", { children: parts.map((part, index) => (_jsx("span", { style: { fontWeight: part.highlight ? 700 : 400 }, children: part.text }, index))) }) }), key));
                            }, renderInput: (params) => {
                                return (_jsxs("div", { className: classes.textContainer, children: [_jsxs("label", { className: classes.label, htmlFor: fieldId, children: [`${label} `, hideRequiredOptional ? '' : required ? '(Required)' : '(Optional)'] }), _jsx(TextField, Object.assign({ inputRef: inputRef, placeholder: placeholder, onChange: (e) => {
                                                handleTextFieldChange(e);
                                            }, onFocus: handleOnFocus }, params, { fullWidth: true, value: value, variant: "standard", InputProps: Object.assign(Object.assign({}, params.InputProps), { autoComplete: 'off', 'aria-autocomplete': 'list', 'aria-haspopup': 'true', disableUnderline: true, disabled: loading, endAdornment: loading && !manualAddressOpen ? (_jsx(CircularProgress, { size: 20 })) : (_jsx(React.Fragment, { children: params.InputProps.endAdornment })) }) }))] }));
                            } })) }))), showFieldError && !((_e = formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) === null || _e === void 0 ? void 0 : _e.showManualAddress) && (_jsx("span", { className: classes.errorMessage, children: fieldError.toString() }))] })] }));
};
export default AddressLookupWrapper;
