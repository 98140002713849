import { makeStyles } from 'tss-react/mui';
import { addWidthInner, link, widthOuter } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    header: {
        backgroundColor: colors.supplementary.white,
        borderBottom: `1px solid ${colors.grey.grey2}`, // same as FF black[200]
        [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, widthOuter), { position: 'relative', top: 0 }),
    },
    container: addWidthInner(theme, {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'right',
        marginBottom: theme.isFlyingFlowers ? undefined : '-1px',
        paddingTop: theme.isFlyingFlowers ? '11px' : undefined,
        paddingBottom: theme.isFlyingFlowers ? '11px' : undefined,
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.isFlyingFlowers ? '16px' : undefined,
            paddingBottom: theme.isFlyingFlowers ? '16px' : undefined,
        },
    }),
    logo: {
        width: '108px',
        [theme.breakpoints.up('md')]: {
            width: '160px',
        },
    },
    content: {},
    title: Object.assign(Object.assign({}, theme.typography.small_bold), { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 0, marginBottom: '4px', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: '12px' }) }),
    icon: { display: 'inline-block', marginRight: spacing.xs, lineHeight: '0rem' },
    link: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)), { color: theme.isFlyingFlowers ? undefined : colors.primary.gold5 }),
}));
export default useStyles;
