import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    contetLinkGalleryContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : undefined }, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.large_bold)), (theme.isFlyingFlowers && theme.typographySpacing.xl_regular_sm)), { marginBottom: !theme.isFlyingFlowers ? '28px' : undefined, color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (!theme.isFlyingFlowers && theme.typography.xl_bold)), { marginBottom: theme.isFlyingFlowers ? spacing.xl : '36px' }) }),
    description: {
        marginBottom: spacing.large,
        '$ p': Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold) }),
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
    },
    textCentered: {
        textAlign: 'center',
    },
    contentGalleryGrid: {
        gridTemplateColumns: 'repeat(1,1fr)',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2,1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3,1fr)',
        },
        '& > div': {
            maxWidth: '335px',
            minWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: '384px',
                margin: 0,
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '520px',
                margin: 0,
            },
        },
    },
}));
export default useStyles;
