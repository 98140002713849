import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    content: {
        flexBasis: '50%',
        '&:not(:last-child)': {
            [theme.breakpoints.down('xl')]: {
                marginBottom: spacing.small,
            },
            [theme.breakpoints.up('md')]: {
                marginRight: spacing.small,
            },
        },
    },
    floristSearchContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    floristSearchInput: {
        '& input[type=text]': {
            height: '42px !important',
        },
        '& label': {
            display: 'none',
        },
    },
    floristSearchInputContainer: {
        width: '100%',
    },
    searchButton: {
        marginLeft: 0,
        marginTop: '18px',
        [theme.breakpoints.up('md')]: {
            marginLeft: spacing.small,
            marginTop: 'initial',
        },
    },
    searchForm: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
    },
    error: {
        marginBottom: spacing.small,
    },
}));
export default useStyles;
