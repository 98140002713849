import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    headingSection: {
        //...contentBlockStyles(theme).headingSection,
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
        '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
        '& $message, & p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
        '& p': Object.assign(Object.assign({ marginBottom: spacing.small }, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({ marginBottom: spacing.medium }, theme.typography.medium_regular), '&:last-child': {
                marginBottom: 0,
            } }),
    },
    title: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.medium_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_lg : theme.typographySpacing.none)), { marginBottom: theme.isFlyingFlowers ? undefined : spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), (theme.isFlyingFlowers ? theme.typographySpacing.xxl_lg : theme.typographySpacing.none)), { marginBottom: theme.isFlyingFlowers ? undefined : spacing.large }) }),
    productGrid: {
        flexGrow: 1,
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    message: {},
    gallery: {
        textAlign: 'center',
    },
    error: { marginBottom: spacing.small },
}));
export default useStyles;
