import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    gridContainer: {
        display: 'grid',
        gap: spacing.medium,
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    gridItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    loadMoreButton: {
        '&.MuiButtonBase-root': {
            paddingLeft: '30.5px !important',
            paddingRight: '30.5px !important',
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    loadMore: {
        textAlign: 'center',
        marginTop: spacing.large,
    },
}));
export default useStyles;
