import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import useStyles from './Banner.styles';
import GenericContent from '../GenericContent/GenericContent';
import { useTheme } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
/**
 * Banner - Design System v2.10.2 - Molecules / Promos
 *
 * * Slim promo with image bg: m-promo_fullwidth_slim_text-on-image
 */
const Banner = (props) => {
    var _a;
    const { minHeight = 'MEDIUM', headingTag = 'H3', font } = props;
    const theme = useTheme();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const isMobile = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, [isMobile]);
    const { classes } = useStyles({ links: (_a = props === null || props === void 0 ? void 0 : props.links) === null || _a === void 0 ? void 0 : _a.length });
    const fontClass = font === 'SOHNE' ? classes.fontSohne : undefined;
    return (_jsx(GenericContent, Object.assign({}, props, { headingTag: headingTag, classNames: classes, textAlignment: "LEFT", linkStyle: "BUTTON", minHeight: minHeight, fullWidthImage: true, isMobileDevice: isMobileDevice })));
};
export default Banner;
