import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_WIDTH_INNER = 520;
const useStyles = makeStyles()(() => ({
    box: {
        border: `1px solid ${colors.supplementary.black}`,
        '& h2': {
            marginBottom: 0,
        },
    },
    signInInnerContainer: { maxWidth: MAX_WIDTH_INNER, margin: `0 auto` },
    signInHeader: {
        borderBottom: `1px solid ${colors.supplementary.black}`,
        padding: spacing.medium,
    },
    signInForm: {
        padding: spacing.medium,
    },
    input: { marginBottom: spacing.small },
    buttons: {},
}));
export default useStyles;
