var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import clsx from 'clsx';
import { FormikContext, useField } from 'formik';
import { useContext } from 'react';
import { showValidationMeta } from '../../utils/common';
import useStyles from './CheckCircle.styles';
import CheckCircleIcon from './CheckCircleIcon';
const CheckCircleWrappper = (props) => {
    // check if formik is available
    return !!useContext(FormikContext) ? _jsx(FormikCheckCircle, Object.assign({}, props)) : _jsx(CheckCircle, Object.assign({}, props));
};
const FormikCheckCircle = (props) => {
    const [field, meta] = useField({ name: props.name, type: props.type || 'radio', value: props.value });
    const validation = showValidationMeta(meta);
    return _jsx(CheckCircle, Object.assign({}, field, props, validation));
};
const CheckCircle = (props) => {
    const { classes } = useStyles();
    // separate props that are not passed through to text input field
    const { className, fullWidth, label, state, errorMessage, type, labelPlacement, controlAlignment } = props, inputProps = __rest(props, ["className", "fullWidth", "label", "state", "errorMessage", "type", "labelPlacement", "controlAlignment"]);
    const { id, name, disabled } = props;
    const Component = type === 'checkbox' ? Checkbox : Radio;
    return (_jsxs("div", { className: clsx(className, fullWidth && classes.fullWidth), children: [_jsx(FormControlLabel, { className: clsx(classes.checkCircle, fullWidth && classes.fullWidth, state === 'error' && classes.error, controlAlignment && controlAlignment !== 'center' && classes[controlAlignment]), label: label, labelPlacement: labelPlacement, disabled: disabled, control: _jsx(Component, Object.assign({ edge: "start", icon: _jsx(CheckCircleIcon, { fontSize: "inherit", disabled: disabled, error: state === 'error' }), checkedIcon: _jsx(CheckCircleIcon, { fontSize: "inherit", checked: !disabled, disabled: disabled, error: state === 'error' }), disabled: disabled, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true }, inputProps, { id: id || name })) }), errorMessage && _jsx("span", { className: classes.errorMessage, children: errorMessage })] }));
};
export default CheckCircleWrappper;
