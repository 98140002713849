import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing } from '../../styles/jss/variables';
const MAX_CARD_HEIGHT = 332;
const useStyles = makeStyles()((theme, _params, classes) => ({
    blogArticleCard: {
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        borderRadius: theme.isFlyingFlowers ? '0px' : undefined,
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : undefined,
        '&:active': {
            boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.12), 0px 8px 20px rgba(0, 0, 0, 0.04)',
        },
        '&:focus': {
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    textContainer: {
        padding: spacing.small,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    title: Object.assign(Object.assign(Object.assign({ display: 'inline', textAlign: theme.isFlyingFlowers ? 'center' : 'left' }, theme.typography.medium_bold), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : theme.typographySpacing.medium_bold_sm)), { paddingTop: '0', color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, theme.typography.large_bold), (theme.isFlyingFlowers ? theme.typographySpacing.large_bold_sm : undefined)), '& span:first-of-type': {
            marginRight: spacing.xxs,
        }, [`.${classes.alignLeft} &`]: {
            textAlign: 'left',
        } }),
    inspirationTitle: Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.medium_bold) }),
    link: Object.assign(Object.assign({}, link()), { textDecoration: 'none' }),
    categories: Object.assign(Object.assign({}, theme.typography.xxs_regular), { textDecoration: theme.isFlyingFlowers ? 'underline' : undefined, backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : undefined, border: theme.isFlyingFlowers ? `1px solid ${colors.supplementary.black}` : undefined, borderRadius: theme.isFlyingFlowers ? '8px' : undefined, marginBottom: `${spacing.xs} !important`, padding: `10px ${spacing.small} !important`, [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.small_regular) }),
    topContainer: {
        display: 'flex',
        justifyContent: theme.isFlyingFlowers ? 'center' : 'space-between',
        marginBottom: theme.isFlyingFlowers ? spacing.xs : undefined,
        [`.${classes.alignLeft} &`]: {
            justifyContent: 'space-between',
        },
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& ul': {
            marginBottom: '2px',
            justifyContent: theme.isFlyingFlowers ? 'center' : undefined,
        },
        [`.${classes.halfWidth} &`]: {
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: '69%',
            },
        },
        [`.${classes.alignLeft} &`]: {
            '& ul': {
                justifyContent: 'flex-start',
            },
        },
    },
    dateContainer: Object.assign(Object.assign({}, theme.typography.xxs_regular), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, paddingTop: spacing.xxs, [theme.breakpoints.up('sm')]: Object.assign({}, theme.typography.small_regular) }),
    image: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    blogImageContainer: {
        height: `${MAX_CARD_HEIGHT}px!important`,
        width: '100%',
    },
    imageContainer: {
        height: '100% !important',
        '& div': {
            height: '100% !important',
        },
    },
    dateTopContainer: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            [`.${classes.halfWidth} &`]: {
                display: 'block',
            },
        },
    },
    dateBottomContainer: {
        textAlign: theme.isFlyingFlowers ? 'center' : 'left',
        color: theme.isFlyingFlowers ? colors.black[700] : undefined,
        [theme.breakpoints.up('lg')]: {
            [`.${classes.halfWidth} &`]: {
                display: 'none',
            },
        },
        [`.${classes.alignLeft} &`]: {
            textAlign: 'left',
        },
    },
    halfWidth: {},
    alignLeft: {},
    description: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: spacing.small, textAlign: theme.isFlyingFlowers ? 'center' : undefined, [`.${classes.alignLeft} &`]: {
            textAlign: 'left',
        } }),
    readMoreLink: {
        color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold5,
        display: 'inline-flex',
        alignItems: 'center',
        '& .MuiIcon-root': {
            color: theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold5,
        },
    },
    latestArticleTitle: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), { textAlign: theme.isFlyingFlowers ? 'center' : 'initial' }), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    latestArticleCategories: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : theme.typography.small_regular)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, textDecoration: theme.isFlyingFlowers ? 'underline' : undefined, backgroundColor: theme.isFlyingFlowers ? colors.supplementary.white : undefined, border: theme.isFlyingFlowers ? `1px solid ${colors.supplementary.black}` : undefined, borderRadius: theme.isFlyingFlowers ? '8px' : undefined, marginBottom: `${spacing.xs} !important`, padding: `10px ${spacing.small} !important` }),
    articleListingDateContainer: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxs_regular : theme.typography.small_regular)),
    readMoreText: {
        textDecoration: theme.isFlyingFlowers ? 'underline' : 'none',
    },
}));
export default useStyles;
