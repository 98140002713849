import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import useStyles from '../GuidedNavigation/PCC.styles';
import Modal from '../Modal/Modal';
import PccModal from '../PCCModal/PCCModal';
import qs from 'qs';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import clsx from 'clsx';
import { useTheme } from '@mui/material';
const submitFormInitialValues = {
    date: null,
    address: '',
    occasion: '',
    city: '',
    county: '',
    postalCode: '',
    country: '',
    isManualAddress: false,
    shouldValidatePostCode: true,
    addressLine2: '',
    addressLine1: '',
};
const PCCModalWrapper = (props) => {
    const { isOpen, setOpen, countriesList, showInternationalLink, setShowInternationalLink, trackPostCodeCheckerDate, trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion, submitPostCodeCheckerComplete, trackEnterManualAddressClick, locationInternationalDelivery, } = props;
    const theme = useTheme();
    const router = useRouter();
    const { classes } = useStyles();
    const [info, setInfo] = useState(submitFormInitialValues);
    const [isShowDatePicker, setIsShowDatePicker] = useState(false);
    const [showStepTitle, setShowStepTitle] = useState('When do you want your gift?');
    const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const submitForm = (values) => {
        sessionStorage.setItem('guidedNavigationValues', JSON.stringify(values));
        const { date, postalCode, city, county, occasion, addressLine2 } = values;
        submitPostCodeCheckerComplete === null || submitPostCodeCheckerComplete === void 0 ? void 0 : submitPostCodeCheckerComplete();
        setOpen(false);
        // Reset form data when submitted
        setInfo(submitFormInitialValues);
        const qsObj = {
            date: date ? format(date, 'yyyy-MM-dd') : undefined,
            postalCode,
            city,
            county,
            addressLine2,
            occasion,
            isSameDay: !!date && isSameDay(date, new Date()),
        };
        router.push(`/search?${qs.stringify(qsObj, { encodeValuesOnly: true })}`);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const modalTitle = (_jsx("span", { className: clsx(classes.modalTitle, showInternationalLink ? classes.customizeTitle : ''), children: _jsx("span", { children: showStepTitle }) }));
    return isOpen ? (_jsx(Modal, { open: isOpen, className: clsx(classes.modal, showInternationalLink ? classes.internationalModal : ''), maxWidth: "md", fullScreen: !isLargerViewport, setOpen: setOpen, noPadding: !isLargerViewport, title: modalTitle, isFromPccOccasion: !!(showStepTitle === null || showStepTitle === void 0 ? void 0 : showStepTitle.includes('occasion')), isMobileDatePicker: isShowDatePicker, onClose: handleClose, children: _jsx(PccModal, { info: info, setInfo: setInfo, submitForm: submitForm, onCancel: setOpen, isOpen: isOpen, setStepTitle: setShowStepTitle, setIsShowDatePicker: setIsShowDatePicker, isShowDayPicker: isShowDatePicker, setShowInternationalLink: setShowInternationalLink, showInternationalLink: showInternationalLink, countriesList: countriesList, trackPostCodeCheckerDate: trackPostCodeCheckerDate, trackPostCodeCheckerAddress: trackPostCodeCheckerAddress, trackPostCodeCheckerManualAddress: trackPostCodeCheckerManualAddress, trackPostCodeCheckerOccasion: trackPostCodeCheckerOccasion, trackEnterManualAddressClick: trackEnterManualAddressClick, locationInternationalDelivery: locationInternationalDelivery }) })) : null;
};
export default PCCModalWrapper;
