import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, startTransition, useCallback, useMemo } from 'react';
export const CustomPaginationContext = createContext({});
export const CustomPaginationProvider = (props) => {
    const { setLength, length, children, setPageNo, pageNo } = props;
    const handleLoadMore = useCallback(() => {
        startTransition(() => {
            if (setPageNo && typeof pageNo === 'number') {
                setPageNo(pageNo + 1);
            }
            setLength(length + 12);
        });
    }, [length, setLength, setPageNo, pageNo]);
    const handleLoadAll = useCallback((length) => {
        startTransition(() => {
            setLength(length || 1000);
        });
    }, [setLength]);
    const paginationValue = useMemo(() => ({
        loadAll: handleLoadAll,
        loadMore: handleLoadMore,
    }), [handleLoadAll, handleLoadMore]);
    return _jsx(CustomPaginationContext.Provider, { value: paginationValue, children: children });
};
