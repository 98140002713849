import { makeStyles } from 'tss-react/mui';
import { link } from './utils';
import { colors, spacing } from './variables';
const confirmationStyles = makeStyles()((theme, props) => {
    // Extracted width logic into a variable
    const buttonWidth = theme.isFlyingFlowers ? '100%' : (props === null || props === void 0 ? void 0 : props.ConfirmationSignIn) ? '287px' : 'initial';
    return {
        container: {
            border: `1px solid ${colors.supplementary.black}`,
            marginBottom: spacing.xl,
            padding: spacing.medium,
            '& h1': Object.assign(Object.assign({}, theme.typography.large_bold), { textTransform: 'unset', [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: '14px' }) }),
            [theme.breakpoints.up('md')]: {
                padding: spacing.xl,
                marginBottom: spacing.medium,
            },
        },
        error: {
            marginBottom: spacing.small,
        },
        content: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg),
        buttons: {
            marginBottom: spacing.medium,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
            '& button': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: buttonWidth, // Use the extracted width value here
                },
            },
        },
        link: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }, (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), theme.typography.xxs_regular), { marginRight: 0, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), { marginRight: spacing.large }) }),
        reminderMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }),
    };
});
export default confirmationStyles;
