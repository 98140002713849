import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import Select from '../Select/Select';
import TextField from '../TextField/TextField';
import useStyles from './DeliveryInstructions.style';
import { replaceThreeDots } from '../../utils/common';
const instructions = [
    'Press to select',
    'Funeral order',
    'Leave in the porch',
    'Leave at the back of the property',
    'Please be patient',
    'Please knock loudly',
    'Other',
];
const maxLength = 90;
const DeliveryInstructions = (props) => {
    const { isAddNewAddress } = props;
    const { classes } = useStyles();
    const formikContext = useContext(FormikContext);
    const setFieldValue = formikContext === null || formikContext === void 0 ? void 0 : formikContext.setFieldValue;
    const { deliveryInstructionOption = '', deliveryInstructionNote = '' } = (formikContext === null || formikContext === void 0 ? void 0 : formikContext.values) || {};
    useEffect(() => {
        if (deliveryInstructionOption === 'Other' ||
            deliveryInstructionOption === 'Press to select' ||
            !deliveryInstructionOption) {
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue('deliveryInstructionNote', '', false);
        }
        else {
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue('deliveryInstructionNote', `${replaceThreeDots(deliveryInstructionOption)} - `, false);
        }
    }, [deliveryInstructionOption, setFieldValue]);
    useEffect(() => {
        if (!!deliveryInstructionNote) {
            const note = replaceThreeDots(deliveryInstructionNote);
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue('deliveryInstructionNote', note, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryInstructionNote]);
    const handleDeliveryInstruction = (e) => {
        if (!!deliveryInstructionOption && deliveryInstructionOption !== 'Press to select') {
            const note = replaceThreeDots(e.target.value);
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue('deliveryInstructionNote', note, false);
        }
    };
    return (_jsxs("div", { className: clsx(classes.container, isAddNewAddress && classes.addressContiner), children: [!isAddNewAddress && (_jsxs(_Fragment, { children: [_jsx("h4", { children: "Optional delivery instructions" }), _jsx("p", { children: "(Used if recipient is not in at the time of delivery)" })] })), _jsx("div", { className: classes.input, children: _jsx(Select, { name: "deliveryInstructionOption", label: "Delivery instructions", placeholder: "Press to select", defaultValue: "Press to select", fullWidth: true, items: instructions.map((instruction) => ({ label: instruction, value: instruction })) }) }), !!deliveryInstructionOption && deliveryInstructionOption !== 'Press to select' && (_jsxs("div", { className: classes.revealContainer, children: [_jsx(Box, { component: "p", sx: { display: { zero: 'block', md: 'none' } }, children: "We can\u2019t always follow these instructions, but we will do our best to when we can." }), _jsxs("div", { className: classes.input, children: [_jsxs("span", { className: classes.characterCount, children: [deliveryInstructionNote.length, " / ", maxLength] }), _jsx(TextField, { className: classes.textarea, name: "deliveryInstructionNote", label: "", placeholder: "Write your instructions here...", multiline: true, rows: 7, hideRequiredOptional: true, fullWidth: true, trimOnBlur: true, onChange: handleDeliveryInstruction, value: replaceThreeDots(deliveryInstructionNote), inputProps: {
                                    maxLength,
                                } })] }), _jsx("p", { className: clsx(classes.instructionsDesktop, isAddNewAddress && classes.instruction), children: "We can\u2019t always follow these instructions, but we will do our best to when we can." })] }))] }));
};
export default DeliveryInstructions;
