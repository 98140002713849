import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
        },
    },
    heading: {
        'h1&,h2&,h3&,h4&,div&': Object.assign(Object.assign(Object.assign({ textAlign: 'left', color: colors.primary.gold4 }, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), '& span': {
                color: colors.supplementary.white,
            } }),
    },
    message: {
        'p&': Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { textAlign: 'left', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
    },
    linkContainer: {
        width: '100%',
    },
}));
export default useStyles;
