import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    occasionContainer: {
        flex: 1,
        marginBottom: spacing.large,
        [theme.breakpoints.up('sm')]: {
            marginLeft: '98px',
            marginRight: '114px',
        },
        [theme.breakpoints.up('md')]: {
            width: '85%',
            margin: '0 auto 45px 136px',
            padding: 0,
        },
    },
    occasionList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    occasionItem: {
        width: '33%',
        color: theme.isFlyingFlowers ? colors.black[600] : colors.grey.grey6,
    },
    selectDropdown: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.isFlyingFlowers ? `${spacing.small} ${spacing.medium}` : '14px 20px',
    },
}));
export default useStyles;
