import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowForwardIos as ArrowForwardIosIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Link from '../Link/Link';
import useStyles from './Accordian.styles';
const Accordian = ({ linkGroups, className }) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const handleShowPreferences = (event) => {
        event.preventDefault();
        setTimeout(() => {
            var _a;
            (_a = window === null || window === void 0 ? void 0 : window.OneTrust) === null || _a === void 0 ? void 0 : _a.ToggleInfoDisplay();
        }, 100);
    };
    return (_jsx("div", { className: clsx(classes.accordianContainer, className !== null && className !== void 0 ? className : ''), children: linkGroups.map((linkGroup, index) => (_jsxs(Accordion, { classes: { root: classes.accordianRoot, expanded: classes.accordianExpanded }, children: [_jsx(AccordionSummary, { classes: {
                        root: classes.accordianSummaryRoot,
                        expanded: classes.accordianSummaryExpanded,
                        content: classes.accordianSummaryContent,
                        expandIconWrapper: classes.accordianSummaryExpandIcon,
                    }, expandIcon: isFlyingFlowers ? _jsx(ExpandMoreIcon, {}) : _jsx(ArrowForwardIosIcon, {}), "aria-controls": `panel${index}-content`, id: `panel${index}-header`, children: _jsx("span", { children: linkGroup.titleLink.label }) }), _jsx(AccordionDetails, { classes: { root: classes.accordianDetailsRoot }, children: _jsx("ul", { children: linkGroup.links.map((link, index) => (_jsx("li", { children: link.label === 'Cookie Preferences' || link.label === 'Manage Preferences' ? (_jsx("a", { href: link.url, className: clsx(classes.cookiePreferenceLink), onClick: handleShowPreferences, children: "Manage Preferences" })) : (_jsx(Link, Object.assign({}, link))) }, `${index}${link.label}`))) }) })] }, linkGroup.titleLink.label))) }));
};
export default Accordian;
