import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ProductCard from '../../components/ProductCard/ProductCard';
import clsx from 'clsx';
import useStyles from './ProductGrid.styles';
import { calculatePLPCardSecondaryBadgeHeight, calculateSearchCardSecondaryBadge, handleSecondaryBadgeHeight, } from '../../utils/common';
import { useMediaQuery } from 'react-responsive';
import SpotlightBanner from '../SpotlightBanner/SpotlightBanner';
import { Grid, useTheme } from '@mui/material';
const ProductGrid = ({ breakpoints, products, className, cardDisplayType, noCardBorder, highlightTextMatch, addToBasket, isFromSearchCard, spotlightBanner, sendSpotlightBannerAnalytics, totalResultsCount, onProductClick, showSkeleton, setShowSkeleton, }) => {
    const { classes } = useStyles();
    const { extraSmall = 12, small = 6, medium = 4, large = 3 } = breakpoints || {};
    const [lengthToSplit, setLengthToSplit] = useState();
    const [device, setDevice] = useState();
    const theme = useTheme();
    const tablet = useMediaQuery({ query: `(max-width: ${theme.breakpoints.values.md}px)` });
    const desktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
    const [screenWidth, setScreenWidth] = useState();
    const handleBadgeHeight = useCallback(() => {
        const { split, device, screenWidthVal } = handleSecondaryBadgeHeight(desktop, tablet, isFromSearchCard);
        setLengthToSplit(split);
        setDevice(device);
        setScreenWidth(screenWidthVal);
    }, [desktop, tablet, isFromSearchCard]);
    useEffect(() => {
        var _a, _b;
        if (navigator.userAgent.includes('Mac') &&
            'ontouchend' in document &&
            (((_a = window === null || window === void 0 ? void 0 : window.screen) === null || _a === void 0 ? void 0 : _a.width) === 810 || ((_b = window === null || window === void 0 ? void 0 : window.screen) === null || _b === void 0 ? void 0 : _b.width) === 1024)) {
            handleBadgeHeight();
        }
    }, [handleBadgeHeight]);
    useEffect(() => {
        if (!lengthToSplit) {
            handleBadgeHeight();
        }
        window === null || window === void 0 ? void 0 : window.addEventListener('resize', handleBadgeHeight);
        return () => window === null || window === void 0 ? void 0 : window.removeEventListener('resize', handleBadgeHeight);
    }, [handleBadgeHeight, lengthToSplit]);
    useEffect(() => {
        if (showSkeleton && setShowSkeleton) {
            setShowSkeleton(false);
        }
    }, [showSkeleton, setShowSkeleton]);
    const productsList = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        let productsData = [];
        if (lengthToSplit) {
            for (let i = 0; i < products.length; i = i + lengthToSplit) {
                let sortedProducts;
                let lengthOfTtile;
                const hasBadge = (_a = products.slice(i, i + lengthToSplit)) === null || _a === void 0 ? void 0 : _a.some((product) => { var _a; return !!((_a = product.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge); }); // check is secondary badge is in the cards row
                const secondaryBadgeProducts = (_b = products
                    .slice(i, i + lengthToSplit)) === null || _b === void 0 ? void 0 : _b.filter((products) => { var _a; return !!((_a = products === null || products === void 0 ? void 0 : products.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge); });
                if (!!secondaryBadgeProducts && (secondaryBadgeProducts === null || secondaryBadgeProducts === void 0 ? void 0 : secondaryBadgeProducts.length) > 1) {
                    /** identify max lenght of a secondary badge */
                    sortedProducts = secondaryBadgeProducts.sort((a, b) => {
                        var _a, _b, _c, _d;
                        if (!!((_b = (_a = a === null || a === void 0 ? void 0 : a.images[0]) === null || _a === void 0 ? void 0 : _a.secondaryBadge) === null || _b === void 0 ? void 0 : _b.title) && !!((_d = (_c = b === null || b === void 0 ? void 0 : b.images[0]) === null || _c === void 0 ? void 0 : _c.secondaryBadge) === null || _d === void 0 ? void 0 : _d.title)) {
                            return a.images[0].secondaryBadge.title.length < b.images[0].secondaryBadge.title.length ? 1 : -1;
                        }
                        else
                            return 1;
                    });
                }
                else
                    sortedProducts = [...secondaryBadgeProducts];
                if (!!(sortedProducts === null || sortedProducts === void 0 ? void 0 : sortedProducts.length)) {
                    lengthOfTtile = isFromSearchCard
                        ? (_f = (_e = (_d = (_c = sortedProducts[0]) === null || _c === void 0 ? void 0 : _c.images[0]) === null || _d === void 0 ? void 0 : _d.secondaryBadge) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.split(' ').join('').length
                        : (_k = (_j = (_h = (_g = sortedProducts[0]) === null || _g === void 0 ? void 0 : _g.images[0]) === null || _h === void 0 ? void 0 : _h.secondaryBadge) === null || _j === void 0 ? void 0 : _j.title) === null || _k === void 0 ? void 0 : _k.length;
                    if (isFromSearchCard && device && lengthOfTtile) {
                        lengthOfTtile = calculateSearchCardSecondaryBadge(device, lengthOfTtile);
                    }
                    else if (device && lengthOfTtile) {
                        lengthOfTtile = calculatePLPCardSecondaryBadgeHeight(device, lengthOfTtile);
                    }
                }
                for (let j = i; j < i + lengthToSplit; j++) {
                    if (j < products.length) {
                        productsData.push(Object.assign(Object.assign({}, products[j]), { hasProductBadge: hasBadge, heightOfBadge: lengthOfTtile }));
                    }
                }
            }
        }
        return productsData;
    }, [device, lengthToSplit, products, isFromSearchCard]);
    const SpotLightBannerWrapper = ({ index }) => {
        const isLargerViewport = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.lg}px)` });
        const isLargeTablet = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.md}px)` });
        const isTablet = useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
        const productsLength = productsList ? productsList.length : 0;
        const totalResultsLength = totalResultsCount ? totalResultsCount : 0;
        const tabletPosition = ((spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) && (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) * 2) || -1;
        const largeTabletPosition = ((spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) && (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) * 3) || -1;
        const largeViewportPosition = ((spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) && (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition) * 4) || -1;
        const isValidMobilePosition = (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.mobilePosition) && totalResultsLength >= (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.mobilePosition);
        const isValidTabletPosition = tabletPosition && totalResultsLength >= tabletPosition;
        const isValidLargeTabletPosition = largeTabletPosition && totalResultsLength >= largeTabletPosition;
        const isValidLargeViewPortPosition = largeViewportPosition && totalResultsLength >= largeViewportPosition;
        let spotlightPosition; // For Mobile
        if (isLargerViewport) {
            if (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition)
                spotlightPosition = isValidLargeViewPortPosition ? largeViewportPosition : totalResultsLength;
            else
                spotlightPosition = productsLength >= 8 ? 8 : -1;
        }
        else if (isLargeTablet) {
            if (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition)
                spotlightPosition = isValidLargeTabletPosition ? largeTabletPosition : totalResultsLength;
            else
                spotlightPosition = productsLength >= 6 ? 6 : -1;
        }
        else if (isTablet) {
            if (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.desktopPosition)
                spotlightPosition = isValidTabletPosition ? tabletPosition : totalResultsLength;
            else
                spotlightPosition = productsLength >= 4 ? 4 : -1;
        }
        else if (spotlightBanner === null || spotlightBanner === void 0 ? void 0 : spotlightBanner.mobilePosition) {
            spotlightPosition = isValidMobilePosition ? spotlightBanner.mobilePosition : totalResultsLength;
        }
        else
            spotlightPosition = productsLength >= 5 ? 5 : -1;
        return spotlightPosition === index ? (_jsx(SpotlightBanner, Object.assign({}, spotlightBanner, { sendSpotlightBannerAnalytics: sendSpotlightBannerAnalytics }))) : (_jsx(_Fragment, {}));
    };
    return (_jsxs(Grid, { className: clsx(classes.productGrid, className !== null && className !== void 0 ? className : ''), container: true, spacing: 3, direction: "row", children: [productsList === null || productsList === void 0 ? void 0 : productsList.map((product, index) => {
                return (_jsxs(React.Fragment, { children: [!!spotlightBanner ? _jsx(SpotLightBannerWrapper, { index: index }) : '', _jsx(Grid, { item: true, xs: extraSmall, sm: small, md: medium, lg: large, className: cardDisplayType === 'DELIVERY_PASS' ? classes.deliveryPassGridItem : classes.gridItem, children: _jsx(ProductCard, { product: product, highlightTextMatch: highlightTextMatch, imageCarousel: true, cardDisplayType: cardDisplayType, hideBorder: noCardBorder, addToBasket: addToBasket, index: index, setHeight: handleBadgeHeight, isFromSearchCard: isFromSearchCard, onProductClick: onProductClick }) })] }, `${index}${product.name}`));
            }), !!spotlightBanner ? _jsx(SpotLightBannerWrapper, { index: (productsList === null || productsList === void 0 ? void 0 : productsList.length) || -1 }) : ''] }));
};
export default ProductGrid;
