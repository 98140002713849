import { makeStyles } from 'tss-react/mui';
import { colors, fontWeights, spacing } from '../../styles/jss/variables';
import { link } from '../../styles/jss/utils';
const useStyles = makeStyles()((theme) => ({
    reminderDescription: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
    forgottenLink: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), link(theme.isFlyingFlowers ? undefined : colors.primary.gold4)),
    privacyLinkContainer: {
        marginBottom: spacing.medium,
    },
    privacyLinks: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { color: colors.grey.grey6, fontWeight: fontWeights.regular }),
    privacyLink: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))),
    passwordContentContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            padding: '0 96px 0 96px',
        },
    },
    forgotPasswordModal: {
        '& header': Object.assign(Object.assign(Object.assign({ padding: 0 }, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({ paddingLeft: '96px' }, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default useStyles;
