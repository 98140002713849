import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import NextLink from 'next/link';
import Link from '../Link/Link';
import ProductImage from '../ProductImage/ProductImage';
import useStyles, { MAX_CARD_WIDTH, cardWidths, doubleCardImageWidths, singleCardImageWidths, } from './SpotlightNavigationCard.styles';
import { spotlightNavigationBackgroundClass } from '../../styles/jss/utils';
import { processImageUrl } from '../../utils/image';
const Wrapper = (props) => {
    const { cardLink, links } = props;
    const { children } = props;
    if (!!cardLink && !(links === null || links === void 0 ? void 0 : links.length)) {
        return (_jsx(NextLink, { href: cardLink, passHref: true, legacyBehavior: true, children: children }));
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
const SpotlightNavigationCard = (props) => {
    var _a;
    const { image, promoText, backgroundColor, links, className, columnWidth, cardLink } = props;
    const { bgClass } = spotlightNavigationBackgroundClass(backgroundColor);
    const { classes } = useStyles({ backgroundColor });
    // Data for GA4 click event
    const imageAltText = (_a = image === null || image === void 0 ? void 0 : image.altText) !== null && _a !== void 0 ? _a : '';
    const analyticsItem = {
        column_width: columnWidth === '2' ? 'Double' : 'Single',
    };
    return (_jsx(Wrapper, { cardLink: cardLink, links: links, children: _jsxs("div", { className: clsx(className, columnWidth === '2' ? classes.doubleColumnCard : classes.productCard, classes.imageContainer), "data-testid": "SpotlightNavigationProductCard", "data-item": JSON.stringify(analyticsItem), "data-type": "SpotlightNavigationProductCard", id: "spot-light", children: [!!(image === null || image === void 0 ? void 0 : image.url) && (_jsx(ProductImage, { altText: imageAltText, imageUrl: processImageUrl(image === null || image === void 0 ? void 0 : image.url, {}, ['$poi-square$']), maxWidth: columnWidth === '2' ? cardWidths.CARD.xl : MAX_CARD_WIDTH, imageWidths: columnWidth === '2' ? doubleCardImageWidths : singleCardImageWidths, isFromSpotlightNavigation: true, loading: "lazy" })), (!!promoText || !!(links === null || links === void 0 ? void 0 : links.length)) && (_jsx("div", { className: clsx(classes.topSectionContainer, columnWidth === '1' ? classes.singleTopSectionContainer : '', bgClass), children: _jsxs(_Fragment, { children: [promoText && (_jsx("p", { className: clsx(classes.promoText, !(links === null || links === void 0 ? void 0 : links.length) ? classes.removeMarginForPromoText : ''), "data-testid": promoText, children: promoText })), links === null || links === void 0 ? void 0 : links.map((link) => (_jsx("p", { className: clsx(classes.simpleGalleryLink, columnWidth === '1' ? classes.singleCardSimpleGallaryLink : ''), "data-testid": link.label, children: _jsx(Link, Object.assign({}, link)) }, link.label)))] }) }))] }) }));
};
export default SpotlightNavigationCard;
