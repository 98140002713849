import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
import clsx from 'clsx';
import useStyles from './CheckCircleIcon.styles';
const CheckCircleIcon = (props) => {
    const { checked, disabled, error, fontSize = 'medium' } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: clsx(classes.root, {
            [classes.checked]: checked && !disabled,
            [classes.error]: error && !disabled,
            [classes.disabled]: disabled,
        }), children: [_jsx(CheckCircleUncheckedIcon, { fontSize: fontSize, className: classes.layerCircle }), _jsx(CheckCircleCheckedIcon, { fontSize: fontSize, className: classes.layerChecked }), _jsx(CheckCircleDisabledIcon, { fontSize: fontSize, className: classes.layerDisabled })] }));
};
const CheckCircleUncheckedIcon = (props) => (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 32 32" }, props, { children: _jsx("circle", { cx: "16", cy: "16", r: "15.5" }) })));
const CheckCircleCheckedIcon = (props) => (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 32 32" }, props, { children: _jsx("path", { d: "M10.9987 0.332031C5.1107 0.332031 0.332031 5.1107 0.332031 10.9987C0.332031 16.8867 5.1107 21.6654 10.9987 21.6654C16.8867 21.6654 21.6654 16.8867 21.6654 10.9987C21.6654 5.1107 16.8867 0.332031 10.9987 0.332031ZM8.86537 16.332L3.53203 10.9987L5.03603 9.4947L8.86537 13.3134L16.9614 5.21736L18.4654 6.73203L8.86537 16.332Z", fill: "black", transform: "translate(5,5)" }) })));
const CheckCircleDisabledIcon = (props) => (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 32 32" }, props, { children: _jsx("path", { d: "M15.3982 7.73229H14.3315V5.59896C14.3315 2.65496 11.9422 0.265625 8.99818 0.265625C6.05418 0.265625 3.66484 2.65496 3.66484 5.59896V7.73229H2.59818C1.42484 7.73229 0.464844 8.69229 0.464844 9.86562V20.5323C0.464844 21.7056 1.42484 22.6656 2.59818 22.6656H15.3982C16.5715 22.6656 17.5315 21.7056 17.5315 20.5323V9.86562C17.5315 8.69229 16.5715 7.73229 15.3982 7.73229ZM8.99818 17.3323C7.82484 17.3323 6.86484 16.3723 6.86484 15.199C6.86484 14.0256 7.82484 13.0656 8.99818 13.0656C10.1715 13.0656 11.1315 14.0256 11.1315 15.199C11.1315 16.3723 10.1715 17.3323 8.99818 17.3323ZM12.3048 7.73229H5.69151V5.59896C5.69151 3.77496 7.17418 2.29229 8.99818 2.29229C10.8222 2.29229 12.3048 3.77496 12.3048 5.59896V7.73229Z", fill: "#828282", transform: "translate(7,4)" }) })));
export default CheckCircleIcon;
