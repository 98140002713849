import { SiteContext } from './common';
import { useContext, useEffect, useRef, useState } from 'react';
const CONVERT_READY_RETRY_MS = 100;
const CONVERT_READY_TIMEOUT_MS = 500;
// Accessing convert experiment directly
// export const MOVE_DELIVERY_INSTRUCTIONS_EXPERIMENT_ID = '1004614'
// export const MOVE_DELIVERY_INSTRUCTIONS_SHOW_VARIANT_ID = '10041610'
// export const MOVE_DELIVERY_INSTRUCTIONS_HIDE_VARIANT_ID = '10041611'
// Accessing convert experiment via launch darkly feature flag
// export const FF_MOVE_DELIVERY_INSTRUCTIONS = 'move-delivery-instructions-v2'
// export const FF_MOVE_DELIVERY_INSTRUCTIONS_SHOW_VARIANT_ID = 'show'
// export const FF_MOVE_DELIVERY_INSTRUCTIONS_HIDE_VARIANT_ID = 'hide'
const DEFAULT_VARIANT = '0';
// Helper hook, could be replaced with https://usehooks-ts.com/react-hook/use-interval
function useInterval(callback, delay) {
    const savedCallback = useRef(callback);
    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        function tick() {
            var _a;
            (_a = savedCallback.current) === null || _a === void 0 ? void 0 : _a.call(savedCallback);
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
// Helper hook, could be replaced with https://usehooks-ts.com/react-hook/use-timeout
function useTimeout(callback, delay) {
    const savedCallback = useRef(callback);
    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        function timeout() {
            var _a;
            (_a = savedCallback.current) === null || _a === void 0 ? void 0 : _a.call(savedCallback);
        }
        if (delay !== null) {
            const id = setTimeout(timeout, delay);
            return () => clearTimeout(id);
        }
    }, [delay]);
}
/**
 * Pushes experiment id to runExperiment array.
 * The Convert configuration needs to have the Site Area configured to include the JS Condition
 * '(window.runExperiment && window.runExperiment.includes('XXXX'))` where XXXX is the experiment id
 *
 * This hook will return the variant number as a string once the isExperimentReady flag is enabled and null until then.
 * If AB Testing is disabled it will return '0' immediately.
 *
 * Uses useInterval and useTimeout to also delay running the experiment until Convert script has loaded,
 * it will fallback to '0' if the timeout is reached.
 *
 * @param experimentId
 * @param isExperimentReady
 * @returns
 */
export function useExperiment(experimentId, isExperimentReady = true, fallback = DEFAULT_VARIANT) {
    var _a;
    const isClientSide = typeof window !== 'undefined';
    const { featureFlags } = useContext(SiteContext);
    const { isABTestingEnabled } = featureFlags || {};
    // Added to override the default for Amplience visualisation
    const defaultVariant = (_a = (featureFlags && featureFlags[`experiment-default-${experimentId}`])) !== null && _a !== void 0 ? _a : fallback;
    const [variant, setVariant] = useState(isABTestingEnabled === false || !isExperimentReady ? defaultVariant : null);
    // Make sure convert is ready
    const [isConvertReady, setIsConvertReady] = useState(false);
    const [isConvertTimedOut, setIsConvertTimedOut] = useState(false);
    // If Convert isn't ready then keep checking until the timeout is reached
    useInterval(() => { var _a, _b; return !!((_b = (_a = window === null || window === void 0 ? void 0 : window.convert) === null || _a === void 0 ? void 0 : _a.currentData) === null || _b === void 0 ? void 0 : _b.experiments) && setIsConvertReady(true); }, !isConvertReady && !isConvertTimedOut && isABTestingEnabled !== false && isClientSide
        ? CONVERT_READY_RETRY_MS
        : null);
    useTimeout(() => setIsConvertTimedOut(true), !isConvertReady ? CONVERT_READY_TIMEOUT_MS : null);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (isABTestingEnabled && isConvertReady && isExperimentReady) {
            console.log('Running Experiment', { experimentId });
            window.runExperiment = window.runExperiment || [];
            window.runExperiment.push(experimentId);
            window._conv_q = (window === null || window === void 0 ? void 0 : window._conv_q) || [];
            (_a = window === null || window === void 0 ? void 0 : window._conv_q) === null || _a === void 0 ? void 0 : _a.push(['executeExperiment', experimentId]);
            const variationId = (_e = (_d = (_c = (_b = window.convert) === null || _b === void 0 ? void 0 : _b.currentData) === null || _c === void 0 ? void 0 : _c.experiments) === null || _d === void 0 ? void 0 : _d[experimentId]) === null || _e === void 0 ? void 0 : _e.variation_id;
            if (variationId) {
                console.log('Experiment Result', { experimentId, variationId });
                setVariant(`${variationId}`);
            }
            else {
                console.log('Experiment Missing, returning default', defaultVariant);
                setVariant(defaultVariant);
            }
        }
        else if (isABTestingEnabled === false) {
            console.log('Experiment Disabled, returning default', defaultVariant);
            setVariant(defaultVariant);
        }
        else if (isConvertTimedOut) {
            console.log('Experiment Timed Out, returning default', defaultVariant);
            setVariant(defaultVariant);
        }
    }, [experimentId, isABTestingEnabled, isExperimentReady, isConvertReady, isConvertTimedOut, defaultVariant]);
    return variant;
}
/**
 * Wrapper around useExperiment that retrieves the experiment and variant ids from a feature flag object if available,
 * otherwise passes them through without altering them
 *
 * e.g. Feature flag in LaunchDarkly should be set to test-experiment = { "experiment_id": "1234", "0": "456", "1": "567" }
 * Either experimentFeatureFlag can be "test-experiment" or "1234"
 * If using the id "1234" then it will not match a feature flag and will return the variant id "456" or "567"
 * If using the feature flag key "test-experiment" then it will find the matching variant id and return "0" or "1"
 * Returns null before the experiment has run and "0" when disabled or timed out
 *
 * @param experimentFeatureFlag
 * @param isExperimentReady
 * @returns
 */
export function useFeatureFlagExperiment(experimentFeatureFlag, isExperimentReady = true, fallback) {
    var _a;
    const { featureFlags } = useContext(SiteContext);
    const featureFlag = featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags[experimentFeatureFlag];
    const experimentId = (featureFlag === null || featureFlag === void 0 ? void 0 : featureFlag.experiment_id) || experimentFeatureFlag;
    const variationId = useExperiment(experimentId, isExperimentReady, fallback);
    const variation = variationId === null
        ? null
        : (featureFlag && ((_a = Object.entries(featureFlag).find((entry) => entry[1] === variationId)) === null || _a === void 0 ? void 0 : _a[0])) || variationId;
    return variation;
}
/**
 * Hook to get a Feature Flag value from SiteContext
 *
 * @param featureFlag
 * @returns
 */
export function useFeatureFlag(featureFlag) {
    var _a;
    const { featureFlags } = useContext(SiteContext);
    return (_a = featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags[featureFlag]) !== null && _a !== void 0 ? _a : null;
}
