import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useBackgroundClass } from '../../styles/jss/utils';
import { processImageUrl } from '../../utils/image';
import ResponsiveImage from '../Image/Image';
import useStyles from './ContentItem.styles';
const ContentItem = (props) => {
    const { image, message } = props; // To make sure we handle any nulls from graphQL
    const { classes } = useStyles();
    const { bgClass } = useBackgroundClass();
    const sizes = [
        {
            breakpoint: 'sm',
            query: 'max-width',
            width: '100vw',
        },
        {
            breakpoint: 'md',
            query: 'max-width',
            width: '100vw',
        },
        {
            width: '500px',
        },
    ];
    return (_jsxs("div", { className: clsx(classes.container, bgClass), children: [_jsx("div", { className: classes.imageContainer, children: !!image && (_jsx(ResponsiveImage, { altText: image.altText || '', imageUrl: processImageUrl(image.url, { w: 500 }, ['$poi-square$']), className: classes.image, width: 500, height: 500, loading: "lazy", sizes: sizes })) }), _jsx("div", { className: classes.textContent, children: _jsx("div", { className: classes.topSectionContainer, children: !!message && _jsx("p", { className: classes.message, children: message }) }) })] }));
};
export default ContentItem;
