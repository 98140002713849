var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TrustpilotWidget from '../TrustpilotWidget/TrustpilotWidget';
import useStyles from './FFHomeHeroBannerGallery.styles';
import Carousel from '../Carousel/Carousel';
import RoundButton from '../RoundButton/RoundButton';
import clsx from 'clsx';
import GenericContent from '../GenericContent/GenericContent';
import FFHeroBannerStyles from '../FFHomeHeroBanner/FFHomeHeroBanner.styles';
import { useCalculateTransitionTime } from '../../utils/utilityCustomHooks';
import { useLoaded } from '../MainContent/useComponentLoading';
import { useTheme } from '@mui/material';
const CustomButtonGroup = ({ next, previous, height }) => {
    const { classes } = useStyles({ height });
    return (_jsxs("div", { className: classes.customButtonGroup, children: [_jsx(RoundButton, { className: classes.leftButton, ariaLabel: 'chevron left arrow', icon: 'chevron_left', onClick: previous }), _jsx(RoundButton, { ariaLabel: 'chevron right arrow', icon: 'chevron_right', onClick: next, className: classes.rightButton })] }));
};
const FFHomeHeroBannerGallery = (props) => {
    const { ffHomeHeroBannersList, showTrustpilotWidget, trustpilotBackground, trustPilotDarknessLevel, transitionDelay, } = props;
    const [isSubHeading, setIsSubHeading] = useState(false);
    const [isSubMessageTitle, setIsSubMessageTitle] = useState(false);
    const [contentHeight, setContentHeight] = useState(600);
    const hasMessage = ffHomeHeroBannersList.some((banner) => !!banner.message);
    const [linksCount, setLinksCount] = useState(0);
    const { classes } = useStyles({ linksCount, hasMessage });
    const { classes: ffHomerBannerClasses } = FFHeroBannerStyles({ isSubHeading, isSubMessageTitle });
    const [isLoaded, setIsLoaded] = useState(false);
    const [elementsList, setElelementsList] = useState([]);
    const [trustpilotDarkness, setTrustpilotDarkness] = useState('rgba(255,255,255,100%)');
    const carouselRef = React.createRef();
    const elRefs = React.useRef([]);
    const theme = useTheme();
    const { sm, md, xl } = theme.breakpoints.values;
    elRefs.current = Array(ffHomeHeroBannersList.length)
        .fill('')
        .map((_, i) => elRefs.current[i] || React.createRef());
    const updatedSylesCallback = useCallback(() => {
        let elementHeight = 600;
        if ((elementsList === null || elementsList === void 0 ? void 0 : elementsList.length) && isLoaded) {
            elementsList === null || elementsList === void 0 ? void 0 : elementsList.forEach((elRefChild) => {
                elRefChild.offsetParent.childNodes.forEach((childNode) => {
                    var _a;
                    if ((childNode === null || childNode === void 0 ? void 0 : childNode.getAttribute('aria-hidden')) === 'false' || (childNode === null || childNode === void 0 ? void 0 : childNode.getAttribute('ariaHidden')) === 'false') {
                        (_a = childNode === null || childNode === void 0 ? void 0 : childNode.childNodes) === null || _a === void 0 ? void 0 : _a.forEach((childNode) => {
                            var _a;
                            (_a = childNode === null || childNode === void 0 ? void 0 : childNode.childNodes) === null || _a === void 0 ? void 0 : _a.forEach((childNd) => {
                                if (!!childNd.getAttribute('id')) {
                                    elementHeight = childNd.offsetHeight;
                                }
                            });
                        });
                    }
                });
            });
            setContentHeight(elementHeight);
        }
    }, [isLoaded, elementsList]);
    const height = useMemo(() => contentHeight, [contentHeight]);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: xl },
            items: 1,
        },
        small: {
            breakpoint: { max: xl - 1, min: md },
            items: 1,
        },
        tablet: {
            breakpoint: { max: md - 1, min: sm },
            items: 1,
        },
        mobile: {
            breakpoint: { max: sm - 1, min: 0 },
            items: 1,
        },
    };
    const moveToNextSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next(1);
    };
    const moveToPreviouSlide = () => {
        var _a;
        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.previous(1);
    };
    const calculateMaxLiHeight = useCallback(() => {
        let max = 0;
        const isSubHeadingExists = ffHomeHeroBannersList.some((banner) => !!banner.subheading);
        const isSubMessageTitleExists = ffHomeHeroBannersList.some((banner) => !!banner.subMessageTitle);
        ffHomeHeroBannersList.forEach((banner) => {
            var _a;
            if (banner.links && banner.links.length > max) {
                max = (_a = banner.links) === null || _a === void 0 ? void 0 : _a.length;
            }
        });
        setIsSubHeading(isSubHeadingExists);
        setIsSubMessageTitle(isSubMessageTitleExists);
        setLinksCount(max);
        updatedSylesCallback();
    }, [ffHomeHeroBannersList, updatedSylesCallback]);
    useEffect(() => {
        calculateMaxLiHeight();
    }, [calculateMaxLiHeight]);
    useEffect(() => {
        var _a, _b;
        if ((_b = (_a = carouselRef === null || carouselRef === void 0 ? void 0 : carouselRef.current) === null || _a === void 0 ? void 0 : _a.containerRef) === null || _b === void 0 ? void 0 : _b.current) {
            setIsLoaded(true);
        }
        window.addEventListener('orientationchange', calculateMaxLiHeight);
        window.addEventListener('resize', calculateMaxLiHeight);
        return () => {
            window.removeEventListener('orientationchange', calculateMaxLiHeight);
            window.removeEventListener('resize', calculateMaxLiHeight);
        };
    }, [calculateMaxLiHeight, carouselRef]);
    useEffect(() => {
        const trustPilotDarknessAttributes = typeof trustPilotDarknessLevel === 'number' ? `rgba(255,255,255, ${trustPilotDarknessLevel}%)` : null;
        trustPilotDarknessAttributes && setTrustpilotDarkness(trustPilotDarknessAttributes);
    }, [trustPilotDarknessLevel]);
    useEffect(() => {
        var _a;
        if (isLoaded) {
            let eleMenets = [];
            (_a = elRefs.current) === null || _a === void 0 ? void 0 : _a.forEach((elRefChild) => {
                if (elRefChild.offsetParent) {
                    eleMenets.push(elRefChild.offsetParent);
                }
            });
            setElelementsList(eleMenets);
        }
    }, [isLoaded]);
    const delayInSeconds = useCalculateTransitionTime(transitionDelay);
    useLoaded(props, isLoaded);
    return (_jsxs(_Fragment, { children: [_jsx(Carousel, { afterChange: () => updatedSylesCallback(), arrows: false, autoPlay: isLoaded, autoPlaySpeed: delayInSeconds, carouselContainerClass: clsx(classes.carousel, showTrustpilotWidget ? classes.truspilotContainer : ''), customButtonGroup: _jsx(CustomButtonGroup, { next: moveToNextSlide, previous: moveToPreviouSlide, height: height / 2 }), infinite: true, itemClass: classes.carouselItem, partialVisbile: false, ref: carouselRef, renderDotsOutside: true, responsive: responsive, showDots: true, dotListClass: classes.carouselDotList, sliderClass: classes.slider, slidesToSlide: 1, ssr: false, swipeable: true, children: ffHomeHeroBannersList.map((homeHeroBanner, index) => {
                    const heading = typeof homeHeroBanner.heading === 'string' && homeHeroBanner.heading.trim()
                        ? homeHeroBanner.heading.split('/').map((line, index) => (_jsx("span", { className: clsx(ffHomerBannerClasses.headingLine, homeHeroBanner.headingColor && ffHomerBannerClasses[homeHeroBanner.headingColor], homeHeroBanner.contentPosition === 'CENTER' ? classes.centerHeading : ''), children: line.trim() }, `ff_home_hero_banner_gallery_heading_${index}`)))
                        : homeHeroBanner.heading;
                    const { message } = homeHeroBanner, props = __rest(homeHeroBanner, ["message"]);
                    return (_jsx(React.Fragment, { children: _jsx("div", { ref: (ref) => ref && elRefs.current.push(ref), children: _jsx(GenericContent, Object.assign({}, props, { heading: heading, classNames: ffHomerBannerClasses, headingLevel: "NONE", linkStyle: "BUTTON", textAlignment: "LEFT", mobileContentPosition: "OVER", minHeight: "LARGE", isFFHeroImage: true, fullWidthImage: true, isSubMessageTitleExists: isSubMessageTitle, isFromFFHomeHeroGallery: true, linksCount: linksCount, message: message, ffGalleryIndex: index })) }) }, `ff_home_hero_banner_gallery_${index}`));
                }) }), showTrustpilotWidget && (_jsx("div", { className: classes.outsideShowTrustpilotWidget, style: { backgroundColor: trustpilotBackground === 'BLACK' ? 'transparent' : trustpilotDarkness }, children: _jsx(TrustpilotWidget, { template: "MICRO_STAR", noPointer: true }) }))] }));
};
export default FFHomeHeroBannerGallery;
