import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    passwordField: {
        marginBottom: spacing.small,
        '& .MuiIconButton-root': {
            color: colors.supplementary.black,
            top: '-10px',
            right: '-10px',
        },
    },
}));
export default useStyles;
