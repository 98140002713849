import { makeStyles } from 'tss-react/mui';
import { colors, spacing, statusColorsInterflora } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, backgroundColor: theme.isFlyingFlowers ? colors.plushBlush[400] : colors.supplementary.white, width: `104px`, height: theme.isFlyingFlowers ? '104px' : undefined, padding: `${spacing.xxs} ${spacing.xs}`, [theme.breakpoints.up('md')]: {
            width: `240px`,
            padding: spacing.small,
            height: theme.isFlyingFlowers ? '240px' : undefined,
        } }, (theme.isFlyingFlowers && {
        maskImage: 'url("/images/roundels/roundel-1.svg")',
        WebkitMaskImage: 'url("/images/roundels/roundel-1.svg")',
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    })), { cursor: 'default' }),
    containerSmall: {
        [theme.breakpoints.up('md')]: {
            width: `120px`,
            height: theme.isFlyingFlowers ? '120px' : undefined,
            padding: `${spacing.xs} ${spacing.small}`,
            [`& .${classes.image}`]: {
                width: theme.isFlyingFlowers ? '59px' : undefined,
                maxHeight: theme.isFlyingFlowers ? '48px' : undefined,
            },
            [`& .${classes.textLine1}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), { display: '-webkit-box', '-webkit-box-orient': 'vertical', maxHeight: '76px', overflow: 'hidden' }),
            [`& .${classes.textLine2}`]: Object.assign(Object.assign({}, theme.typography.xxs_regular), { '&:not(:last-child)': {
                    marginBottom: spacing.xs,
                } }),
            [`&.${classes.LAVENDER_LARGE} .${classes.textLine1}`]: Object.assign({}, theme.typography.large_bold),
            [`&.${classes.FF_MEDIUM} .${classes.textLine1}`]: Object.assign({}, theme.typography.large_bold),
            [`&.${classes.icon}`]: {
                width: '20px',
                height: '19px',
            },
        },
    },
    image: {
        width: theme.isFlyingFlowers ? '59px' : '100%',
        maxHeight: theme.isFlyingFlowers ? '48px' : undefined,
        objectFit: theme.isFlyingFlowers ? 'cover' : undefined,
        '.react-multi-carousel-list .react-multi-carousel-item &': {
            position: 'relative',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.isFlyingFlowers ? '148px' : undefined,
            maxHeight: theme.isFlyingFlowers ? '120px' : undefined,
        },
    },
    textLine1: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, (theme.isFlyingFlowers ? theme.typography.small_bold : theme.typography.medium_bold)), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold), display: '-webkit-box', '-webkit-box-orient': 'vertical', maxHeight: '130px', overflow: 'hidden' }),
    textLine2: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), (!theme.isFlyingFlowers && theme.typographySpacing.xxs_regular_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.large_regular : theme.typography.medium_regular)), (!theme.isFlyingFlowers && theme.typographySpacing.medium_regular_sm)), '&:last-child': {
            marginBottom: '0px',
        } }),
    LAVENDER: {
        [`& .${classes.textLine1}`]: {
            color: '#5F588F',
        },
    },
    LAVENDER_LARGE: {
        [`& .${classes.textLine1}`]: Object.assign(Object.assign({ color: '#5F588F' }, theme.typography.large_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    },
    STANDARD: {
        [`& .${classes.textLine1}`]: {
            color: '#5F588F',
        },
    },
    PINK: {
        [`& .${classes.textLine1}`]: {
            color: colors.Pink,
        },
    },
    RED: {
        [`& .${classes.textLine1}`]: {
            color: statusColorsInterflora.error.base,
        },
    },
    PURPLE: {
        [`& .${classes.textLine1}`]: {
            color: colors.Purple,
        },
    },
    LILAC: {
        [`& .${classes.textLine1}`]: {
            color: colors.Lilac,
        },
    },
    PEACH: {
        [`& .${classes.textLine1}`]: {
            color: colors.Peach,
        },
    },
    YELLOW: {
        [`& .${classes.textLine1}`]: {
            color: colors.Yellow,
        },
    },
    GREEN: {
        [`& .${classes.textLine1}`]: {
            color: colors.Green,
        },
    },
    FF_SMALL: {
        [`& .${classes.textLine1}`]: {
            [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_bold),
        },
    },
    FF_MEDIUM: {
        [`& .${classes.textLine1}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
    },
    FF_LARGE: {
        [`& .${classes.textLine1}`]: Object.assign({}, theme.typography.large_bold),
    },
    FF_YELLOW_DEFAULT: {
        backgroundColor: colors.sharpYellow[400],
        [`& .${classes.textLine2}`]: {
            [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular),
        },
    },
    FF_YELLOW_XL: {
        backgroundColor: colors.sharpYellow[400],
        [`& .${classes.textLine1}`]: Object.assign({}, theme.typography.xl_bold),
        [`& .${classes.textLine2}`]: {
            [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular),
        },
    },
    DEEP_ROSE: {
        width: '128px',
        padding: `10px ${spacing.small}`,
        [`& .${classes.textLine1}`]: {
            color: '#97203B',
            [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_bold),
        },
    },
    FF_GREY: {
        backgroundColor: colors.black[600],
        [`& .${classes.textLine1}`]: {
            color: colors.supplementary.white,
        },
    },
    icon: {
        height: '19px !important',
        width: '20px',
        [theme.breakpoints.up('md')]: {
            height: '31px !important',
            width: '33px',
        },
    },
    FF_SHARP_YELLOW: {
        backgroundColor: colors.sharpYellow[400],
        [`& .${classes.textLine1},& .${classes.textLine2}`]: {
            color: colors.black[700],
        },
    },
    FF_LAZY_LIME: {
        backgroundColor: colors.lazyLime[400],
        [`& .${classes.textLine1},& .${classes.textLine2}`]: {
            color: colors.black[700],
        },
    },
    FF_PLUSH_BLUSH: {
        backgroundColor: colors.plushBlush[400],
        [`& .${classes.textLine1},& .${classes.textLine2}`]: {
            color: colors.black[700],
        },
    },
}));
export default useStyles;
