import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing, statusColorsInterflora } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    pccStepContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        border: theme.isFlyingFlowers ? `1px solid ${colors.black[700]}` : `2px solid ${colors.grey.grey3}`,
        borderRadius: theme.isFlyingFlowers ? '4px' : 'unset',
        marginBottom: spacing.small,
        padding: `${spacing.xs} ${spacing.small}`,
        cursor: 'pointer',
    },
    label: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey7, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none) }),
    linkText: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), link(theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none) }),
    selectedValueText: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.none), { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '75%', textAlign: 'right', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey7, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { width: '85%' }) }),
    occasionSelectedValue: {
        width: '55%',
        [theme.breakpoints.up('md')]: {
            width: '68%',
        },
    },
    greenBorder: {
        border: theme.isFlyingFlowers ? 'none' : `2px solid ${statusColorsInterflora.success.base}`,
        backgroundColor: theme.isFlyingFlowers ? colors.lazyLime[300] : colors.grey.grey1,
    },
    greenTextLabel: {
        color: theme.isFlyingFlowers ? colors.black[700] : statusColorsInterflora.success.base,
    },
    pccStepSelectButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
}));
export default useStyles;
