var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import _get from 'lodash/get';
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import Modal from '../Modal/Modal';
const PreloadedModalPopup = (props) => {
    var _a;
    const { componentId, componentKey, ComponentProps, Component } = props, modalProps = __rest(props, ["componentId", "componentKey", "ComponentProps", "Component"]);
    const siteContext = useContext(SiteContext);
    const component = _get(siteContext.preloadedComponents, componentId || componentKey || '');
    return (_jsx(Modal, Object.assign({}, modalProps, { title: ((_a = component === null || component === void 0 ? void 0 : component.data) === null || _a === void 0 ? void 0 : _a.title) || modalProps.title, children: (component === null || component === void 0 ? void 0 : component.data) ? (_jsx(Component, Object.assign({}, component === null || component === void 0 ? void 0 : component.data, ComponentProps))) : (_jsx("p", { children: "The information you requested could not be retrieved. Please try again later." })) })));
};
export default PreloadedModalPopup;
