import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer, useEffect, useRef } from 'react';
import TextField from '../TextField/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useStyles from './PasswordField.styles';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';
import { default as _get } from 'lodash/get';
import { default as _isEqual } from 'lodash/isEqual';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_REQUIREMENTS"] = "SET_REQUIREMENTS";
    ActionTypes["SET_SHOW_PASSWORD"] = "SET_SHOW_PASSWORD";
})(ActionTypes || (ActionTypes = {}));
export const passwordValidationSchema = Yup.string()
    .test('character-length', 'Please enter at least 8 characters, less than 50', (value) => !!value && value.length >= 8 && value.length <= 49)
    .matches(/\d/, 'Please enter at least 1 number')
    .matches(/[A-Z]/, 'Please enter at least 1 uppercase character')
    .matches(/[a-z]/, 'Please enter at least 1 lowercase character');
const initialState = {
    requirements: [
        {
            label: 'at least 8 characters, less than 50',
            status: '',
        },
        {
            label: 'at least 1 number',
            status: '',
        },
        {
            label: 'at least 1 uppercase character',
            status: '',
        },
        {
            label: 'at least 1 lowercase character',
            status: '',
        },
    ],
    showPassword: false,
};
const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.SET_REQUIREMENTS:
            return Object.assign(Object.assign({}, state), { requirements: action.payload });
        case ActionTypes.SET_SHOW_PASSWORD:
            return Object.assign(Object.assign({}, state), { showPassword: action.payload });
        default:
            throw new Error('Action type not identified');
    }
};
const PasswordField = ({ id, name, label, placeholder, newPassword, signUp, fullWidth, required }) => {
    const formikContext = useFormikContext();
    const [state, dispatch] = useReducer(reducer, initialState);
    const hasChanged = useRef(false);
    const prevRequirementState = useRef(state.requirements);
    const { classes } = useStyles();
    const handleShowPassword = () => {
        dispatch({ type: ActionTypes.SET_SHOW_PASSWORD, payload: !state.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        const value = _get(formikContext === null || formikContext === void 0 ? void 0 : formikContext.values, name);
        const touched = _get(formikContext === null || formikContext === void 0 ? void 0 : formikContext.touched, name);
        const fieldErrors = _get(formikContext === null || formikContext === void 0 ? void 0 : formikContext.errors, name, []);
        if (!newPassword)
            return;
        if (!hasChanged.current && value) {
            hasChanged.current = true;
        }
        else if (hasChanged.current || (!value && fieldErrors.length && touched)) {
            const requirements = state.requirements.map((requirement) => ({
                label: requirement.label,
                status: fieldErrors.some((error) => error.includes(requirement.label)) || !value ? 'error' : 'success',
            }));
            if (!_isEqual(prevRequirementState.current, requirements)) {
                prevRequirementState.current = requirements;
                dispatch({ type: ActionTypes.SET_REQUIREMENTS, payload: requirements });
            }
        }
    }, [formikContext === null || formikContext === void 0 ? void 0 : formikContext.errors, formikContext === null || formikContext === void 0 ? void 0 : formikContext.values, formikContext === null || formikContext === void 0 ? void 0 : formikContext.touched, name, newPassword, state.requirements]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.passwordField, children: _jsx(TextField, { id: id, name: id || name, label: label, required: required, fullWidth: fullWidth, placeholder: placeholder, hideError: newPassword, type: state.showPassword ? 'text' : 'password', endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleShowPassword, onMouseDown: handleMouseDownPassword, size: "large", children: state.showPassword ? _jsx(Visibility, {}) : _jsx(VisibilityOff, {}) }) }) }) }), newPassword && _jsx(PasswordRequirements, { signUp: signUp, requirements: state.requirements })] }));
};
export default PasswordField;
