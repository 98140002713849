var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useNumericMenu, useRefinementList, useSortBy, } from 'react-instantsearch';
import { SiteContext } from '../../utils/common';
import CheckCircle from '../CheckCircle/CheckCircle';
import useStyles from './FilterFacet.styles';
import { interactionResponse } from '../../utils/awaitInteractionResponse';
export const FilterFacet = (props) => {
    const { items, refine, title, attribute, defaultOpen, selectedFilter = [], setSelectedFilter, facetDetails, setSelectedAttribute, isFromMobile, } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    const [isOpen, setOpen] = useState(defaultOpen);
    useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen]);
    const handleFiltersCheck = (e, item) => {
        // Send analytics event
        facetDetails === null || facetDetails === void 0 ? void 0 : facetDetails(attribute, item.label);
        if (!isFlyingFlowers) {
            const fiterIndex = selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.indexOf(item.label);
            if (fiterIndex === -1) {
                setSelectedFilter === null || setSelectedFilter === void 0 ? void 0 : setSelectedFilter([...selectedFilter, item.label]);
            }
            else {
                selectedFilter.splice(fiterIndex, 1); // removing from array when uncheck the item
                setSelectedFilter && setSelectedFilter([...selectedFilter]);
            }
        }
        e.preventDefault();
        refine === null || refine === void 0 ? void 0 : refine(item.value);
    };
    const handleFilterFacet = (e) => {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        setOpen(!isOpen);
        if (items && !isOpen) {
            if (isFromMobile) {
                (_a = document === null || document === void 0 ? void 0 : document.getElementById(`${attribute}-${items.length - 1}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                setSelectedAttribute === null || setSelectedAttribute === void 0 ? void 0 : setSelectedAttribute(attribute);
            }
        }
    };
    return (_jsx("div", { className: classes.filterFacet, children: _jsxs(Accordion, { expanded: isOpen, classes: {
                root: classes.accordianRoot,
            }, children: [_jsx(AccordionSummary, { classes: {
                        root: classes.summaryRoot,
                        // Need to get back: Test this still works correctly
                        expandIconWrapper: classes.expandMore,
                    }, "aria-controls": `panel-content-${attribute}`, id: `panel-header-${attribute}`, onClick: (event) => {
                        handleFilterFacet(event);
                        setOpen(!isOpen);
                    }, expandIcon: isFlyingFlowers || attribute !== 'sortBy' ? (_jsx(Box, { sx: { display: { zero: 'none', xs: 'none', md: 'block' } }, children: _jsx(ExpandMore, {}) })) : undefined, children: _jsx("div", { className: classes.filterHeadings, children: title }) }), _jsx(AccordionDetails, { classes: {
                        root: classes.detailsRoot,
                    }, children: _jsx("ul", { children: items &&
                            items.map((item, i) => {
                                const id = `${attribute}-${item.value[0]}-${i}`;
                                const elemId = `${attribute}-${i}`;
                                const singleOrLastItem = i === items.length - 1;
                                if (attribute === 'occasions' && (item.label === 'PCC Other' || item.label === 'PDP Other')) {
                                    return null;
                                }
                                return (_jsxs("li", { id: singleOrLastItem ? elemId : '', children: [_jsx("label", { htmlFor: id, children: item.label === 'true' ? 'Yes' : item.label === 'false' ? 'No' : item.label }), _jsxs("div", { className: classes.checkboxContainer, children: [!!item.count && _jsxs("span", { className: classes.total, children: ["(", item.count, ")"] }), _jsx(CheckCircle, { name: id, checked: item.isRefined, value: item.value, type: "checkbox", id: id, onClick: (event) => __awaiter(void 0, void 0, void 0, function* () {
                                                        handleFiltersCheck(event, item);
                                                        yield interactionResponse();
                                                    }) })] })] }, id));
                            }) }) })] }) }));
};
export const FilterNumericList = (props) => {
    const data = useNumericMenu({
        attribute: props.attribute,
        items: props.items,
    });
    return _jsx(FilterFacet, Object.assign({}, props, data));
};
export const FilterRefinementList = (props) => {
    const data = useRefinementList({ attribute: props.attribute });
    return _jsx(FilterFacet, Object.assign({}, props, data));
};
export const SortByList = (props) => {
    const data = useSortBy({ items: props.items });
    const items = data.options.map(({ label, value }) => ({ label, value, isRefined: value === data.currentRefinement }));
    return _jsx(FilterFacet, Object.assign({}, props, data, { items: items }));
};
