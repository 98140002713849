import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import ProductGrid from '../../components/ProductGrid/ProductGrid';
import { SiteContext } from '../../utils/common';
import Link from '../Link/Link';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './ProductGallery.styles';
import ProductGalleryCarousel from './ProductGalleryCarousel/ProductGalleryCarousel';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
const ProductGallery = (props) => {
    var _a;
    const { title, body, products, galleryDisplayType, linksAfter, headingTag } = props;
    const { addToBasket, cardDisplayType = 'CONCISE' } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const { classes } = useStyles();
    const router = useRouter();
    const isDeliveryPassCardType = cardDisplayType === 'DELIVERY_PASS';
    const isCarouselGalleryDisplay = galleryDisplayType === 'CAROUSEL';
    const isGridGalleryDisplay = galleryDisplayType === 'GRID';
    const isPdpPage = (_a = router === null || router === void 0 ? void 0 : router.asPath) === null || _a === void 0 ? void 0 : _a.includes('/product');
    const shouldContentLeftAligned = isPdpPage && isCarouselGalleryDisplay;
    const HeadingTag = headingTag ? headingTag.toLowerCase() : 'h3';
    return (_jsxs("div", { className: clsx(classes.productGallery, isDeliveryPassCardType ? classes.deliveryPass : '', shouldContentLeftAligned ? classes.carouselProductGallery : ''), children: [_jsxs("div", { className: clsx(isGridGalleryDisplay ? classes.headingSection : ''), children: [title && (_jsx(HeadingTag, { className: clsx(classes.title, isCarouselGalleryDisplay ? classes.carouselTitle : ''), children: title })), _jsx(RawHtml, { className: clsx(classes.message, isCarouselGalleryDisplay ? classes.carouselMessage : ''), content: body })] }), !!(products === null || products === void 0 ? void 0 : products.length) && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: { zero: 'none', lg: 'block' } }, children: [isGridGalleryDisplay && (_jsx(ProductGrid, { breakpoints: {
                                    small: 12,
                                    medium: 4,
                                    large: 3,
                                }, products: products, cardDisplayType: cardDisplayType, addToBasket: addToBasket })), isCarouselGalleryDisplay && (_jsx(ProductGalleryCarousel, { products,
                                cardDisplayType }))] }), _jsx(Box, { sx: { display: { xs: 'block', lg: 'none' } }, children: isDeliveryPassCardType ? (_jsx(ProductGrid, { breakpoints: {
                                small: 12,
                                medium: 4,
                                large: 3,
                            }, products: products, cardDisplayType: cardDisplayType, addToBasket: addToBasket })) : (_jsx(ProductGalleryCarousel, { products,
                            cardDisplayType })) })] })), linksAfter && (_jsx("div", { className: classes.linkContainer, children: linksAfter.map((link) => (_jsx(Link, Object.assign({ button: true, variant: isFlyingFlowers ? 'tertiaryDark' : 'secondary', thin: true }, link, { className: classes.link, icon: isFlyingFlowers ? 'refresh' : undefined }), `product_gallery_${link.label}`))) }))] }));
};
export default ProductGallery;
