import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
// Designs used for Interflora - https://www.figma.com/file/TLHrK1x4xxUOwVgiQ7McFD/DS_version-2.13.3?node-id=21%3A1077
// Designs used for FF - https://www.figma.com/file/sStbIo2VTXH7Tbs27KZU21/FF-Weekly-Outputs?node-id=692%3A28
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        //  maxWidth: '335px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        margin: `0 auto ${spacing.small}`,
        backgroundColor: theme.isFlyingFlowers ? colors.plushBlush[400] : colors.supplementary.white,
        '& .react-multi-carousel-dot-list': {
            paddingTop: `${spacing.small} !important`,
        },
    },
    contentImage: {
        width: '100%',
        overflow: 'hidden',
    },
    titleContainer: {
        backgroundColor: theme.isFlyingFlowers ? colors.plushBlush[400] : 'none',
        padding: theme.isFlyingFlowers ? `${spacing.small} 12px 3px` : `${spacing.small} 12px 0`,
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers
                ? `${spacing.medium} ${spacing.medium} 18px`
                : `${spacing.medium} ${spacing.medium} ${spacing.small} `,
            backgroundColor: theme.isFlyingFlowers ? undefined : colors.supplementary.white,
        },
    },
    contentTitle: Object.assign(Object.assign(Object.assign({ textAlign: 'center' }, theme.typography.medium_bold), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_lg : theme.typographySpacing.medium_bold_sm)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), (theme.isFlyingFlowers ? theme.typographySpacing.large_bold_lg : theme.typographySpacing.large_bold_sm)) }),
    contentDescription: Object.assign(Object.assign(Object.assign({ textAlign: 'center' }, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_sm : theme.typographySpacing.small_regular_sm)), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (!theme.isFlyingFlowers && theme.typography.medium_regular)), (!theme.isFlyingFlowers && theme.typographySpacing.medium_regular_sm)) }),
}));
export default useStyles;
