import { makeStyles } from 'tss-react/mui';
import { spacing } from './variables';
export const rawContentBlockStyles = (theme) => ({
    headingSection: {
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.xl,
        },
        '& h3': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
        '& $message, & p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
        '& p': {
            marginBottom: spacing.small,
            [theme.breakpoints.up('md')]: {
                marginBottom: spacing.medium,
            },
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
});
const contentBlockStyles = makeStyles()(rawContentBlockStyles);
export default contentBlockStyles;
