import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    addressCardContainer: {
        border: theme.isFlyingFlowers ? `1px solid ${colors.black[600]}` : undefined,
        borderRadius: theme.isFlyingFlowers ? '4px' : undefined,
        width: '100%',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
        [theme.breakpoints.up('md')]: {
            width: '516px',
            minHeight: '176px',
        },
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
        background: colors.supplementary.white,
        padding: spacing.medium,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [`& .${classes.addressNameTitle}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none),
        [`& .${classes.address}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: colors.grey.grey6, display: 'inline-block' }),
        [`& .${classes.defaultAddressLabel}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.none), { color: colors.grey.grey6 }),
        '&:last-child': {
            marginBottom: '12px',
        },
        '&:first-child': {
            marginTop: '12px',
        },
    },
    addressContainer: {
        marginLeft: spacing.xs,
        [theme.breakpoints.up('md')]: {
            marginLeft: spacing.small,
        },
    },
    addressNameTitle: {},
    defaultAddressLabel: {},
    address: {},
    tickboxSection: {
        input: { marginBottom: spacing.small },
    },
    moreInfo: Object.assign(Object.assign({}, theme.typography.xxs_bold), { marginBottom: 0, display: 'flex', '& .MuiIcon-root': {
            fontSize: '24px',
        } }),
    moreInfoDefaultAddr: {
        marginTop: '8px',
    },
    moreInfoLabel: {
        paddingLeft: '6px',
    },
}));
export default useStyles;
