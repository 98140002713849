import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Head from 'next/head';
import { useContext, useEffect, useRef, useState } from 'react';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './KlarnaPlacement.styles';
import { SiteContext } from '../../utils/common';
import clsx from 'clsx';
/**
 * Adding Readonly prop to fix the sonar issue "Mark the props of the component as read-only.
 * You should use TypeScript’s utility type Readonly to make your functional component props read-only
 */
function KlarnaPlacement(props) {
    const { classes } = useStyles();
    const { shorthand } = useContext(SiteContext);
    const { price = '', saveKlarnaLearnMoreAnalytics } = props;
    const [isKlarnaRendered, setIsKlarnaRendered] = useState(false);
    const klarnaTimerRef = useRef(null);
    const klrnaPrice = price + '';
    const dataLocale = shorthand === 'ie' ? 'en-IE' : 'en-GB';
    const productionCheck = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX;
    const klaranaDataClientId = typeof window === 'undefined' ? process.env.KLARNA_DATA_CLIENT_ID : process.env.NEXT_PUBLIC_KLARNA_DATA_CLIENT_ID;
    const dataEnv = productionCheck === 'prd' ? 'production' : 'playground';
    const klarnaPlacementSnippet = `
    <klarna-placement
      data-key=credit-promotion-badge
      data-locale=${dataLocale}
      data-purchase-amount=${price}
    ></klarna-placement>`;
    useEffect(() => {
        if (!isKlarnaRendered) {
            klarnaTimerRef.current = setInterval(() => {
                var _a;
                const isKlarnaRendered = document === null || document === void 0 ? void 0 : document.querySelector('klarna-placement');
                const isKlarnaWindowAvailable = !!((_a = window === null || window === void 0 ? void 0 : window.Klarna) === null || _a === void 0 ? void 0 : _a.OnsiteMessaging);
                if (isKlarnaRendered && isKlarnaWindowAvailable) {
                    setIsKlarnaRendered(true);
                    if (klarnaTimerRef.current)
                        clearInterval(klarnaTimerRef.current);
                }
            }, 500);
        }
        return () => {
            if (klarnaTimerRef.current)
                clearInterval(klarnaTimerRef.current);
        };
    }, [isKlarnaRendered]);
    useEffect(() => {
        var _a, _b, _c;
        if (isKlarnaRendered) {
            const handleKlarnaLearnMoreAnalytics = () => {
                saveKlarnaLearnMoreAnalytics === null || saveKlarnaLearnMoreAnalytics === void 0 ? void 0 : saveKlarnaLearnMoreAnalytics();
            };
            (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.Klarna) === null || _a === void 0 ? void 0 : _a.OnsiteMessaging) === null || _b === void 0 ? void 0 : _b.on) === null || _c === void 0 ? void 0 : _c.call(_b, 'informationalModalOpened', handleKlarnaLearnMoreAnalytics);
            return () => {
                var _a, _b, _c;
                (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.Klarna) === null || _a === void 0 ? void 0 : _a.OnsiteMessaging) === null || _b === void 0 ? void 0 : _b.off) === null || _c === void 0 ? void 0 : _c.call(_b, 'informationalModalOpened', handleKlarnaLearnMoreAnalytics);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isKlarnaRendered, klrnaPrice]);
    return (_jsxs(_Fragment, { children: [_jsx(Head, { children: _jsx("script", { async: true, "data-environment": dataEnv, src: "https://js.klarna.com/web-sdk/v1/klarna.js", "data-client-id": `${klaranaDataClientId}` }) }), _jsx(RawHtml, { content: klarnaPlacementSnippet, className: clsx(classes.klarnaContent, classes.KlarnaPlacementContainer) })] }));
}
export default KlarnaPlacement;
