import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteContext } from '../../utils/common';
import ReminderCard from '../ReminderCard/ReminderCard';
import useStyles from './ReminderCardGallery.styles';
const ReminderCardGallery = (props) => {
    const { reminders, editReminder, deleteReminder, setReminderStatusInfo, setHasScrolled } = props;
    const { classes } = useStyles();
    const { isFlyingFlowers } = useContext(SiteContext);
    return (_jsx("div", { children: !!(reminders === null || reminders === void 0 ? void 0 : reminders.length) ? (_jsx(_Fragment, { children: _jsx("div", { className: classes.container, children: reminders.map((reminder, index) => (_jsx(ReminderCard, { reminder: Object.assign({}, reminder), deleteReminder: deleteReminder, editReminder: editReminder, setReminderStatusInfo: setReminderStatusInfo, setHasScrolled: setHasScrolled }, index))) }) })) : (_jsxs("div", { children: [_jsx("h3", { className: classes.noRemindersTitle, children: isFlyingFlowers
                        ? `Looks like you haven’t set up any reminders yet...now\'s the time! You could add your bestie\'s birthday for starters?`
                        : 'You have no reminders set yet' }), _jsx("p", { className: classes.noReminderBody, children: isFlyingFlowers ? 'When you add a reminder you’ll see it here' : 'But when you do, they will appear here!' })] })) }));
};
export default ReminderCardGallery;
