import { makeStyles } from 'tss-react/mui';
import { addWidthInner, widthOuter } from '../../styles/jss/utils';
import { colors, fontSizes, hideScrollbars, spacing } from '../../styles/jss/variables';
// Need to get back jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const useStyles = makeStyles()((theme) => ({
    breadcrumbContent: Object.assign(Object.assign({}, widthOuter), { backgroundColor: colors.supplementary.white, borderBottom: theme.isFlyingFlowers ? 'none' : `1px solid ${colors.grey.grey2}` }),
    container: addWidthInner(theme, Object.assign(Object.assign({ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }, hideScrollbars), { paddingTop: `4px`, paddingBottom: `4px`, [theme.breakpoints.up('md')]: {
            paddingTop: `12px`,
            paddingBottom: `12px`,
        } })),
    arrowIcon: {
        margin: spacing.xxs,
        color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
        '& .material-icons': {
            fontSize: fontSizes.small,
        },
        [theme.breakpoints.up('md')]: {
            margin: `${spacing.xs} ${spacing.xxs} ${spacing.xxs} ${spacing.xxs}`,
        },
    },
}));
export default useStyles;
