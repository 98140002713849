import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    modal: {
        padding: spacing.medium,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            maxWidth: '100%!important',
            maxHeight: 'none!important',
            margin: '0!important',
            borderRadius: 0,
        },
        [theme.breakpoints.up('md')]: {
            padding: spacing.large,
            minWidth: '500px',
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial',
        },
    },
    noPadding: {
        [`& .${classes.modal}`]: {
            padding: 0,
        },
        [`& .${classes.header}`]: {
            padding: `${spacing.medium} ${spacing.medium} 0 ${spacing.medium}`,
        },
    },
    header: {
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing.small,
        flex: '0 0 auto',
        '& h3': Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.medium_regular), { margin: `0 ${spacing.small} 0 0`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular) }),
    },
    headerTitle: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.medium_regular), { margin: `0 ${spacing.small} 0 0`, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular) }),
    noTitleHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: spacing.small,
        alignItems: 'center',
        padding: spacing.small,
        paddingBottom: 0,
        paddingTop: 0,
        '& button': {
            zIndex: 5,
            position: 'relative',
        },
    },
    contentContainer: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        padding: '0!important',
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { marginBottom: spacing.large }), '&:last-child': {
                marginBottom: 0,
            } }),
    },
    imageGalleryHeader: {
        paddingRight: 0,
        marginBottom: spacing.small,
        '@media (orientation: landscape)': {
            marginBottom: '12px',
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            paddingRight: spacing.xs,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 'intial',
        },
    },
    stickyHeader: {
        backgroundColor: colors.black[200],
        alignItems: 'normal',
    },
    stickyHeaderContainer: {
        padding: 0,
    },
    stickyContentContainer: {
        padding: `0 ${spacing.medium}!important`,
    },
    stickyButtonContainer: {
        padding: spacing.small,
        paddingLeft: '10px',
        '& button.MuiFab-root': {
            height: '40px',
            width: '40px',
            '& .MuiFab-label': {
                height: '40px',
                width: '40px',
                [theme.breakpoints.up('sm')]: {
                    width: '60px',
                    height: '60px',
                },
            },
            [theme.breakpoints.up('sm')]: {
                height: '60px',
                width: '60px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            padding: `22px ${spacing.medium}`,
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: '18px',
        },
    },
    pccModalContainer: {
        '& header': {
            marginBottom: 40,
            [theme.breakpoints.up('md')]: {
                marginBottom: `${spacing.large} !important`,
            },
        },
    },
    pccDatepicker: {
        [theme.breakpoints.down('xl')]: {
            marginBottom: '0 !important',
        },
    },
    deliveryOptionsStickyBtn: {
        '& button.MuiFab-root': {
            width: '60px',
            height: '60px',
            '& .MuiFab-label': {
                width: '60px',
                height: '60px',
            },
        },
        padding: '16px 20px 16px 0 !important',
        [theme.breakpoints.up('md')]: {
            padding: '32px 32px 32px 0 !important',
        },
    },
    stickyHeaderBorder: {
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
    },
    mediaGalleryHeader: {
        marginBottom: spacing.medium,
    },
}));
export default useStyles;
