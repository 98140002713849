import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    productGrid: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    deliveryPassGridItem: {
        display: 'flex',
        flexGrow: 0,
        maxWidth: '407px',
        flexBasis: '407px !important',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
            paddingBottom: '12px',
        },
        '&:last-child': {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '12px',
            },
        },
    },
}));
export default useStyles;
