var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import * as Yup from 'yup';
import { calculateTextLength, cardMessageValidationRegEx, errorMessages, getAttribute, priceFormatPartial, replaceThreeDots, SiteContext, } from '../../../utils/common';
import Button from '../../Button/Button';
import { default as TextField } from '../../TextField/TextField';
import useStyles from '../ProductDetailPurchase.styles';
import Icon from '../../Icon/Icon';
import Modal from '../../Modal/Modal';
import StyleVariantInformation from '../../StyleVariantInformation/StyleVariantInformation';
import { useFeatureFlagExperiment } from '../../../utils/useExperiment';
import { flag_uk_pdp_open_pricing } from '../../../utils/featureFlags';
import { useTheme, InputAdornment } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
const MAX_OPEN_PRICE = 499;
const SPECIAL_INSTRUCTION_MAX_LENGTH = 100;
export const validationConfig = Yup.object().shape({
    specialInstructions: Yup.string().trim().matches(cardMessageValidationRegEx, errorMessages().unsupportedCharacters),
    openPrice: Yup.string().when(['hasOpenPrice', 'minOpenPrice'], {
        is: (hasOpenPrice) => hasOpenPrice,
        then: Yup.string()
            .test('Minimum price validation', '', (value, context) => {
            var _a;
            const openPriceValue = value ? parseInt(value) : '';
            const minPrice = (_a = context === null || context === void 0 ? void 0 : context.parent.minOpenPrice) !== null && _a !== void 0 ? _a : 0;
            if (!openPriceValue)
                return context === null || context === void 0 ? void 0 : context.createError({ message: `Enter your price`, path: 'openPrice' });
            else if (openPriceValue < minPrice)
                return context === null || context === void 0 ? void 0 : context.createError({ message: `Minimum price not met (£${minPrice})`, path: 'openPrice' });
            return true;
        })
            .test('Maximum price validation', `Maximum price £${MAX_OPEN_PRICE}`, (value) => {
            const openPriceValue = parseInt(value !== null && value !== void 0 ? value : '0');
            return openPriceValue <= MAX_OPEN_PRICE;
        })
            .required(errorMessages('Enter your price').required),
    }),
    hasOpenPrice: Yup.boolean(),
    minOpenPrice: Yup.number(),
});
export const initialValues = {
    variantColor: '',
    variantSize: '',
    specialInstructions: '',
    variantStyle: '',
    hasOpenPrice: false,
    minOpenPrice: 0,
    openPrice: '',
};
const VariantSelection = (_a) => {
    var { classes, price, promotionalText, displayOpenPrice } = _a, props = __rest(_a, ["classes", "price", "promotionalText", "displayOpenPrice"]);
    const [field] = useField(props);
    return (_jsxs("label", { className: clsx(classes === null || classes === void 0 ? void 0 : classes.variantLabel, field.checked ? classes === null || classes === void 0 ? void 0 : classes.variantLabelChecked : ''), tabIndex: 0, children: [_jsx("input", Object.assign({}, field, { type: "radio", style: { display: 'none' } })), _jsx("span", { children: props.value }), price && !displayOpenPrice && _jsx("span", { children: priceFormatPartial(price) })] }));
};
const PurchaseVariant = (props) => {
    var _a, _b, _c, _d;
    const { step, currentStep, setCurrentStep, product, setVariant, hasFloristChoice, setIsVariantChange, productVariantStyleInformation, hasOpenPrice, errors, isSubmitting, sendOpenPriceAnalytics, } = props;
    const { isFlyingFlowers, shorthand } = useContext(SiteContext);
    const { classes } = useStyles();
    const [focusSpecialInstructions, setFocusSpecialInstructions] = useState(false);
    const theme = useTheme();
    const { values, setFieldValue } = useFormikContext();
    const [instructionsLength, setInstructionsLength] = useState(0);
    const [isVariantStyleInfoOpen, setIsVariantStyleInfoOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` });
    const prevErrorRef = useRef('');
    const pricingVariant = useFeatureFlagExperiment(flag_uk_pdp_open_pricing === null || flag_uk_pdp_open_pricing === void 0 ? void 0 : flag_uk_pdp_open_pricing.key, !!hasOpenPrice, flag_uk_pdp_open_pricing.non_open_pricing);
    const [showOpenPricingVariant, setShowOpenPricingVariant] = useState(pricingVariant && pricingVariant !== '0' && pricingVariant !== (flag_uk_pdp_open_pricing === null || flag_uk_pdp_open_pricing === void 0 ? void 0 : flag_uk_pdp_open_pricing.non_open_pricing));
    useEffect(() => {
        setShowOpenPricingVariant(pricingVariant && pricingVariant !== '0' && pricingVariant !== (flag_uk_pdp_open_pricing === null || flag_uk_pdp_open_pricing === void 0 ? void 0 : flag_uk_pdp_open_pricing.non_open_pricing));
    }, [pricingVariant]);
    const displayOpenPrice = useMemo(() => showOpenPricingVariant && shorthand === 'uk' && hasOpenPrice, [showOpenPricingVariant, shorthand, hasOpenPrice]);
    let buttonTitle = 'Who this gift is for';
    if (isFlyingFlowers) {
        buttonTitle = 'Delivery address';
    }
    else if (product.isFuneral) {
        buttonTitle = 'Who this tribute is for';
    }
    // Get all colors
    const colors = (_a = product.variants) === null || _a === void 0 ? void 0 : _a.reduce((acc, variant) => {
        const variantColor = getAttribute('variantColor', variant.attributes);
        return !variantColor || acc.includes(variantColor) ? acc : [...acc, variantColor];
    }, []);
    const hasColors = colors.length > 1;
    // Get the variants with the current color
    const matchedColorVariants = product.variants.filter((v) => getAttribute('variantColor', v.attributes) === (values.variantColor || undefined));
    // Get sizes with the current color
    let sizes = matchedColorVariants.reduce((acc, variant) => {
        const variantSize = getAttribute('variantSize', variant.attributes);
        const openPrice = getAttribute('openPrice', variant.attributes);
        return !variantSize || acc.some((item) => item.size === variantSize)
            ? acc
            : [...acc, { size: variantSize, price: variant.price, openPrice }];
    }, []);
    const hasSizes = sizes.length > 1;
    sizes = useMemo(() => {
        if (!showOpenPricingVariant) {
            return sizes.filter((size) => !size.openPrice);
        }
        return sizes;
    }, [showOpenPricingVariant, sizes]);
    //Get Styles with current color and size
    const matchedSizeVariants = matchedColorVariants.filter((v) => getAttribute('variantSize', v.attributes) === (values.variantSize || undefined));
    //Get styles with color and style or only color or only size
    const matchedStyleVariants = sizes.length > 0 ? matchedSizeVariants : matchedColorVariants;
    const styles = matchedStyleVariants.reduce((acc, variant) => {
        const variantStyle = getAttribute('variantStyle', variant.attributes);
        return !variantStyle || acc.some((item) => item === variantStyle) ? acc : [...acc, variantStyle];
    }, []);
    const hasStyles = styles.length > 1;
    // Update the variant when it changes in Formik
    // if we don't find a matching variant switch to using master
    const variant = (_c = (_b = product.variants) === null || _b === void 0 ? void 0 : _b.find((v) => (!hasColors || getAttribute('variantColor', v.attributes) === (values.variantColor || undefined)) &&
        (!hasSizes || getAttribute('variantSize', v.attributes) === (values.variantSize || undefined)) &&
        (!hasStyles || getAttribute('variantStyle', v.attributes) === (values.variantStyle || undefined)))) !== null && _c !== void 0 ? _c : product.variants.find((v) => v.isMaster);
    const handleSpecialInstruction = (event) => {
        const specialInstruction = replaceThreeDots(event.target.value);
        const charLength = calculateTextLength(specialInstruction);
        if (charLength <= SPECIAL_INSTRUCTION_MAX_LENGTH) {
            setInstructionsLength(charLength);
            setFieldValue('specialInstructions', specialInstruction);
        }
        else
            return;
    };
    const minOpenPrice = useMemo(() => (displayOpenPrice ? parseInt(priceFormatPartial(variant === null || variant === void 0 ? void 0 : variant.price).slice(1)) : ''), [displayOpenPrice, variant === null || variant === void 0 ? void 0 : variant.price]);
    useEffect(() => {
        setIsVariantChange === null || setIsVariantChange === void 0 ? void 0 : setIsVariantChange(false);
        setVariant === null || setVariant === void 0 ? void 0 : setVariant((current) => {
            if (!hasOpenPrice) {
                setFieldValue('openPrice', '');
            }
            if ((current === null || current === void 0 ? void 0 : current.sku) !== (variant === null || variant === void 0 ? void 0 : variant.sku)) {
                return variant;
            }
            else
                return current;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant, setVariant]);
    useEffect(() => {
        setFieldValue('hasOpenPrice', displayOpenPrice);
        setFieldValue('minOpenPrice', minOpenPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayOpenPrice, hasOpenPrice, minOpenPrice]);
    const showStepCount = !isFlyingFlowers ? `${step}.` : '';
    const titleVariant = () => {
        if (hasSizes)
            return 'size';
        if (hasColors)
            return 'colour';
        return hasStyles ? 'style' : '';
    };
    const hasVariant = hasSizes || hasColors || hasStyles;
    const flyingFlowersTitle = `${hasVariant ? 'Choose the' : ''} ${titleVariant()}`;
    const title = `${showStepCount} ${!hasColors && !hasSizes && !hasStyles && hasFloristChoice
        ? 'Do you have any special requests?'
        : !isFlyingFlowers
            ? `What ${titleVariant()} would you like?`
            : flyingFlowersTitle}`;
    const stepContentWithEditIcon = currentStep > step ? (_jsxs("div", { className: classes.completed, children: [hasSizes && _jsx(TextField, { name: "variantSize", fullWidth: true, completed: true, onClick: () => setCurrentStep(step) }), hasColors && _jsx(TextField, { name: "variantColor", fullWidth: true, completed: true, onClick: () => setCurrentStep(step) }), hasStyles && _jsx(TextField, { name: "variantStyle", fullWidth: true, completed: true, onClick: () => setCurrentStep(step) }), hasFloristChoice && (_jsx(TextField, { name: "specialInstructions", fullWidth: true, completed: true, onClick: () => {
                    handleSpecialInstructionEdit();
                    setCurrentStep(step);
                }, value: (values === null || values === void 0 ? void 0 : values.specialInstructions) || '(No special request entered)' }))] })) : null;
    const handleSpecialInstructionEdit = () => {
        setFocusSpecialInstructions(true);
    };
    const showVariantStyleInfo = () => {
        setIsVariantStyleInfoOpen(true);
    };
    useEffect(() => {
        setIsMobile(isMobileDevice);
    }, [isMobileDevice]);
    useEffect(() => {
        var _a, _b, _c;
        if (isSubmitting &&
            (errors === null || errors === void 0 ? void 0 : errors.openPrice) &&
            currentStep === step &&
            sendOpenPriceAnalytics &&
            variant &&
            prevErrorRef.current !== (errors === null || errors === void 0 ? void 0 : errors.openPrice)) {
            // checks for errors.openPrice.includes contains any one of the following
            prevErrorRef.current = errors.openPrice;
            if ((_a = errors.openPrice) === null || _a === void 0 ? void 0 : _a.includes('Min')) {
                const min_open_price = values.openPrice;
                sendOpenPriceAnalytics(variant === null || variant === void 0 ? void 0 : variant.sku, min_open_price, 'MINIMUM');
            }
            else if ((_b = errors.openPrice) === null || _b === void 0 ? void 0 : _b.includes('Max')) {
                const max_open_price = values.openPrice;
                sendOpenPriceAnalytics(variant === null || variant === void 0 ? void 0 : variant.sku, max_open_price, 'MAXIMUM');
            }
            else if ((_c = errors.openPrice) === null || _c === void 0 ? void 0 : _c.includes('Enter your price')) {
                const price_error = 'blank';
                sendOpenPriceAnalytics(variant === null || variant === void 0 ? void 0 : variant.sku, price_error, 'EMPTY');
            }
        }
    }, [currentStep, errors === null || errors === void 0 ? void 0 : errors.openPrice, isSubmitting, sendOpenPriceAnalytics, step, values.openPrice, variant]);
    return (_jsxs("div", { children: [_jsx(Modal, { open: isVariantStyleInfoOpen, className: clsx(classes.styleVariantModal), maxWidth: "lg", setOpen: showVariantStyleInfo, onClose: () => setIsVariantStyleInfoOpen(false), title: productVariantStyleInformation === null || productVariantStyleInformation === void 0 ? void 0 : productVariantStyleInformation.modalTitle, children: _jsx(StyleVariantInformation, { styleVariantInformationItems: productVariantStyleInformation === null || productVariantStyleInformation === void 0 ? void 0 : productVariantStyleInformation.items }) }), _jsx("div", { className: clsx(classes.heading, currentStep < step && classes.pending), children: title }), currentStep === step ? (_jsxs(_Fragment, { children: [hasSizes && pricingVariant && (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.variantLabelContainer, children: sizes.map(({ size, price, openPrice }) => (_jsx(VariantSelection, { name: "variantSize", type: "radio", value: size, classes: classes, price: price, promotionalText: product === null || product === void 0 ? void 0 : product.promotionalText, displayOpenPrice: openPrice }, size))) }), displayOpenPrice && (_jsx("div", { className: classes.openPriceInput, children: _jsx(TextField, { name: "openPrice", label: "", placeholder: `Minimum ${priceFormatPartial(variant === null || variant === void 0 ? void 0 : variant.price)}`, autoFocus: true, required: true, fullWidth: true, trimOnBlur: true, hideRequiredOptional: true, inputProps: {
                                        inputMode: isMobile ? 'numeric' : 'none',
                                    }, startAdornment: values.openPrice ? (_jsx(InputAdornment, { position: "start", className: classes.currency, children: "\u00A3" })) : null, onInput: (e) => {
                                        const target = e.target;
                                        target.value = target.value
                                            .replace(/\D/g, '') // Remove non-digit characters
                                            .replace(/^0/, '') // Remove leading '0'
                                            .replace(/\./g, ''); // Remove periods
                                    } }) }))] })), hasColors && (_jsxs(_Fragment, { children: [hasSizes && _jsx("div", { className: classes.subHeading, children: "Choose colour" }), _jsx("div", { className: classes.variantLabelContainer, children: colors.map((color) => (_jsx(VariantSelection, { name: "variantColor", type: "radio", value: color, classes: classes }, color))) })] })), hasStyles && (_jsxs(_Fragment, { children: [(hasColors || hasSizes) && _jsx("div", { className: classes.subHeading, children: "Choose style" }), _jsxs("div", { className: clsx(classes.variantLabelContainer, classes.variantStyleContainer), children: [styles.map((style) => (_jsx(VariantSelection, { name: "variantStyle", type: "radio", value: style, classes: classes }, style))), productVariantStyleInformation && ((_d = productVariantStyleInformation === null || productVariantStyleInformation === void 0 ? void 0 : productVariantStyleInformation.items) === null || _d === void 0 ? void 0 : _d.length) > 0 ? (_jsx(Icon, { icon: "info_outline", onClick: showVariantStyleInfo, className: classes.icon })) : null] })] })), hasFloristChoice && (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx(classes.specialInstructionsTitle, hasVariant && classes.specialInstructionsSeparator), children: "Is there anything else you would like the florist to know about your gift?" }), _jsxs("div", { className: classes.specialInstructionContainer, children: [_jsx(TextField, { name: "specialInstructions", label: "", placeholder: "e.g. Loves roses, 50th birthday", multiline: true, fullWidth: true, trimOnBlur: true, hideRequiredOptional: true, rows: 5, onChange: handleSpecialInstruction, className: classes.specialInstructions, inputProps: {
                                            maxLength: SPECIAL_INSTRUCTION_MAX_LENGTH,
                                        }, autoFocus: focusSpecialInstructions }), _jsxs("p", { className: classes.characterCount, children: [instructionsLength, " / ", SPECIAL_INSTRUCTION_MAX_LENGTH] })] }), !!instructionsLength && (_jsx("div", { className: classes.specialInstructionsMessage, children: "We'll pass your comments onto the florist. Every bouquet is created to order using the freshest seasonal flowers, so your florist will do their upmost to take your comments on board." }))] })), _jsx("div", { className: classes.next, children: _jsx(Button, { variant: "primary", title: `Next: ${buttonTitle}`, thinOnMobile: true, fullWidth: true, isSubmit: true, icon: isFlyingFlowers ? 'shopping_basket' : undefined }) })] })) : (stepContentWithEditIcon)] }));
};
export default PurchaseVariant;
